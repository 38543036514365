import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking

interface Person {
  key: string;
  name: string;
  myTarget: string;
  teamTarget: string;
  buffer: string;
  manager: string;
}
@Component({
  selector: 'app-objective-performance',
  templateUrl: './objective-performance.component.html',
  styleUrls: ['./objective-performance.component.css']
})
export class ObjectivePerformanceComponent  {
  // validateForm!: FormGroup;

  // submitForm(): void {
  //   for (const i in this.validateForm.controls) {
  //     this.validateForm.controls[i].markAsDirty();
  //     this.validateForm.controls[i].updateValueAndValidity();
  //   }
  // }
  formatOne = (percent: number) => `${percent} Days`;
  formatTwo = () => `25% vs 20%`;
  isVisible = false;  
  i= 0;
  editId: string | null = null;
  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfData: Person[] = [
    {
      key: '1',
      name: 'Sales through channel partner',
      myTarget: '10 Cr',
      teamTarget: '11 Cr',
      buffer: '1 Cr',
      manager: "Mayank Vora"
    },
    {
      key: '2',
      name: 'Direct sales for server',
      myTarget: '10 Cr',
      teamTarget: '11 Cr',
      buffer: '1 Cr',
      manager: "Harish P"
    },
    {
      key: '3',
      name: 'Sales for code',
      myTarget: '10 Cr',
      teamTarget: '11 Cr',
      buffer: '1 Cr', 
      manager: "Kayan V"
    }
  ];
  dateFormat = 'dd/MM/yyyy';
  current = 0;
  contentIndex = 0;
  listOfNoOfOption = [
    { label: 'FY-2020-21', value: 1 },
    { label: 'FY-2021-22', value: 2 },
    { label: 'FY-2022-23', value: 3 }, 
  ];
  listOfMonthsOfOption=[
    { label: 'Jan', value: 1 },
    { label: 'Feb', value: 2 },
    { label: 'Mar', value: 3 }, 
  ]; 
   
  constructor(public fb: FormBuilder) { }
 
// modal action fuctions
showModal(): void {
  this.isVisible = true;
}

handleOk(): void {
  // console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  // console.log('Button cancel clicked!');
  this.isVisible = false;
}
requiredChange(e){

}
}
 







