<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <!-- <div class="text-center">
                            <h1>Hello Mayank Vora,</h1>
                        <p>Your objectives for the year 20-21 is set up. Review the objectives, modify weightage in consultation with your manager. Once objectives are reviewed, set up the initiatives for the month and proceed with your daily activities.</p>
                        </div> -->

                        <div class="stepwizard mt-4" >
                            <div class="stepwizard-row setup-panel text-center">
                                <div class="wizard-step tab-first" >
                                    <a class="active btn btn-default" [routerLink]="['/maximizer-dashboard/basic-details']" routerLinkActive="active" >
                                      <div class="iconBox"><img class="icon" src="../../../assets/images/icon/Group471.svg" alt="" sizes="" ></div>
                                       <span> Basic Details</span> 
                                    </a>
                                </div>
                                <div class="wizard-step tab-second" >
                                    <a class="btn btn-default" [routerLink]="['/maximizer-dashboard/settings']" routerLinkActive="active" >
                                      <div class="iconBox"><img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="" > </div>  
                                        <span>Settings</span>
                                        <!-- <p>(26th Aug, 2021 - 25th Sept 2021)</p> -->
                                    </a>
                                </div> 
                                <div class="wizard-step tab-second" >
                                    <a class="btn btn-default" [routerLink]="['/maximizer-dashboard/order-history']" routerLinkActive="active" >
                                      <div class="iconBox"><img class="icon" src="../../../assets/images/icon/Group474.svg" alt="" sizes="" ></div>   
                                        <span>Ordre History</span>
                                        <!-- <p>(26th Aug, 2021 - 25th Sept 2021)</p> -->
                                    </a>
                                </div> 
                                 
                            </div>
                        </div>
                        <!-- <div class="col-md-12 text-center">
                            <span class="p-5">Approval Pending</span> <span class="p-5">Completed</span>
                        </div> -->
                        <div class="row setup-content" > 
                           <div class="col-md-8 offset-1">
                            <form nz-form  [formGroup]="validateForm" [nzLayout]="'vertical'"  (ngSubmit)="submitForm()">
                                <nz-form-item>
                                    <nz-form-label [nzSm]="8" [nzXs]="24"  nzRequired nzFor="name">Name</nz-form-label>
                                    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please Enter Name!">
                                      <input nz-input formControlName="name" placeholder="Enter Name" id="name" />
                                    </nz-form-control>
                                  </nz-form-item>
                                  <nz-form-item>
                                    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="legalEntity">Legal Entity</nz-form-label>
                                    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please Enter Legal Entity!">
                                      <input nz-input formControlName="legalEntity" placeholder="Enter Legal Entity" id="legalEntity" />
                                    </nz-form-control>
                                  </nz-form-item>
                                  <nz-form-item>
                                    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="contact">Contact</nz-form-label>
                                    <nz-form-control [nzSm]="14" [nzXs]="24"  nzErrorTip="Please Enter Contact Details!">
                                      <input nz-input formControlName="contact" placeholder="Enter Contact Details"  id="contact" />
                                    </nz-form-control>
                                  </nz-form-item>
                                  <nz-form-item>
                                    <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="phone">Phone</nz-form-label>
                                    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please Enter Phone No.!">
                                      <input nz-input formControlName="phone" placeholder="Enter Phone No." id="phone" />
                                    </nz-form-control>
                                  </nz-form-item>
                                <nz-form-item>
                                  <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="email">E-mail</nz-form-label>
                                  <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
                                    <input nz-input formControlName="email" placeholder="Enter email Id"  id="email" />
                                  </nz-form-control>
                                </nz-form-item>   
                                <nz-form-item>
                                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="website" nzRequired>Website</nz-form-label>
                                  <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input website!">
                                    <input nz-input id="website" placeholder="Enter website URL" formControlName="website" placeholder="website" />
                                  </nz-form-control>
                                </nz-form-item> 
                                <nz-form-item  class="register-area">  
                                    <nz-form-control [nzOffset]="7" [nzSpan]="24">
                                        <button nz-button nzType="primary" (click)="resetForm()">Cancel</button> 
                                         <button nz-button nzType="primary" >Save</button>
                                  </nz-form-control> 
                                </nz-form-item>
                                <!-- <nz-form-item>
                                    <nz-form-control [nzOffset]="7" [nzSpan]="12">
                                      <button nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
                                      <button nz-button (click)="resetForm($event)">Reset</button>
                                    </nz-form-control>
                                  </nz-form-item> -->
                              </form>
                           </div> 
                        </div>
                           
                    </div>
                </div>
            </div>
           </div>
    </div>