import {Component, OnInit} from '@angular/core';
import {
  Assessment,
  AssessmentActivityAttempt,
  Activity,
  ActivityQuestion,
  Competency,
  ActivityFeedbackQuestion,
  PsychometricAssessment,
  AssessmentAttempts,
  AssessmentRetrieveRequest,
  AssessmentAttemptsRetrieveRequest,
  AssessmentActivityAttemptsRetrieveRequest,
  ActivityRetrieveRequest,
  ActivityQuestionsRetrieveRequest,
  CompetencyRetrieveRequest,
  AssessmentActivityQuestionAttemptsGroupRetrieveRequest,
  ActivityFeedbackQuestionsRetrieveRequest,
  AssessmentActivityFeedbackAttemptsGroupRetrieveRequest,
  PsychometricAssessmentGroupRetrieveRequest,
  AssessmentActivityQuestionAttemptGroup,
  AssessmentActivityFeedbackAttemptGroup
} from 'src/app/app.models';
import {AppService} from 'src/app/app.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-employee-report',
  templateUrl: './employee-report.component.html',
  styleUrls: ['./employee-report.component.css']
})
export class EmployeeReportComponent implements OnInit {
  totalScoreMap = new Map();
  loggedInUserDetail: any;
  empData: any;
  assessments: Assessment[] = [];
  assessment_attempt_ids: Array<number> = [];
  assessment_ids: Array<number> = [];
  assessmentAttempt: AssessmentAttempts[] = [];
  assessmentActivityAttempt: AssessmentActivityAttempt[] = [];
  activity: Activity[] = [];
  assessment_activity_ids: Array<number> = [];
  assessmentQuestionData: ActivityQuestion[] = [];
  competenciesData: Competency[] = [];
  competenciesFeedbackData: Competency[] = [];
  psychometricCompetenciesData: Competency[] = [];
  question_competencies_ids: Array<number> = [];
  feedback_competencies_ids: Array<number> = [];
  psychometric_competencies_ids: Array<number> = [];
  assessment_activity_attempt_id: Array<number> = [];
  assessment_activityQuestionAttempt: AssessmentActivityQuestionAttemptGroup[] = [];
  activityFeedbackQuestion: ActivityFeedbackQuestion[] = [];
  assessmentFeedbackAttempt: AssessmentActivityFeedbackAttemptGroup[] = [];
  psychometricAssessment: PsychometricAssessment[] = [];
  questionGroup: any;
  feedbackGroup: any;
  totalOfQuestionGroup: any;
  totalOfFeedbackGroup: any;
  activtyIdGroup: Array<number> = [];
  totalOfPsychometric: any;
  finalActivityGroup: any;

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.getLoggedInUserDetail().subscribe((res) => {
      this.loggedInUserDetail = res;
      this.empData = this.loggedInUserDetail?.employee;
      this.getAssessments();
      this.getAssessmentAttempts();
    });
  }

  getAssessments() {
    let a = new AssessmentRetrieveRequest();
    a.emp_id = this.empData?.id;
    a.per_page = 55;
    this.appService.getAssessments(a).subscribe((res) => {
      this.assessments = res['items'];

      for (var i = 0; i < this.assessments.length; i++) {
        this.assessment_ids.push(this.assessments[i].id);
      }
      this.getActivity();

    });
  }

  getAssessmentAttempts() {
    let r = new AssessmentAttemptsRetrieveRequest();
    r.per_page = 40;
    r.emp_id = this.empData?.id;
    this.appService.getAssessmentAttemps(r).subscribe(res => {
      this.assessmentAttempt = res['items'];
      //console.log("GET ASSESSMENT ATTEPTS",this.assessmentAttempt)
      for (var i = 0; i < this.assessmentAttempt.length; i++) {
        this.assessment_attempt_ids.push(this.assessmentAttempt[i].id);
      }
      this.getAssessmentActivityAttemps();

    });
  }

  getAssessmentActivityAttemps() {
    let act = new AssessmentActivityAttemptsRetrieveRequest();
    act.per_page = 45;
    act.assessment_attempt_ids = this.assessment_attempt_ids;
    this.appService.getAssessmentActivityAttemptsByAssessmentAttemptId(act).subscribe((res) => {
      this.assessmentActivityAttempt = res['items'];
      //console.log("ASSESSMENT ACTIVITY ATTEMPTs",this.assessmentActivityAttempt)
      for (var i = 0; i < this.assessmentActivityAttempt.length; i++) {
        this.assessment_activity_ids.push(this.assessmentActivityAttempt[i].assessment_activity_id);
        this.assessment_activity_attempt_id.push(this.assessmentActivityAttempt[i].id);
      }

      this.getActivityQuestion();

    });
  }

  getActivity() {
    let aa = new ActivityRetrieveRequest();
    aa.per_page = 100;
    aa.assessment_ids = this.assessment_ids;
    this.appService.getActivities(aa).subscribe((res) => {
      this.activity = res['items'];
      //console.log("MY ACTIVITY DATA",this.activity)
    });
  }

  getActivityQuestion() {
    let qq = new ActivityQuestionsRetrieveRequest();
    qq.assessment_activity_ids = this.assessment_activity_ids;
    this.appService.getActivityQuestionsByActivityId(qq).subscribe((res) => {
      this.assessmentQuestionData = res['items'];
      for (var i = 0; i < this.assessmentQuestionData.length; i++) {
        this.question_competencies_ids.push(this.assessmentQuestionData[i]['question_competency_id']);
      }

      this.getQuestionCompetencies();
    });

  }

  getQuestionCompetencies() {
    let cc = new CompetencyRetrieveRequest();
    cc.ids = this.question_competencies_ids;
    this.appService.getCompetencies(cc).subscribe((res) => {

      this.competenciesData = res['items'];
      this.assessmentActivityQuestionAttempt();
    });
  }

  assessmentActivityQuestionAttempt() {
    let qa = new AssessmentActivityQuestionAttemptsGroupRetrieveRequest();
    qa.assessment_activity_attempt_id = this.assessment_activity_attempt_id;
    this.appService.getAssessmentActivityQuestionAttemptsGroup(qa).subscribe((res) => {
      this.assessment_activityQuestionAttempt = res[0]['items'];
      this.questionGroup = res[1]['group'];
      this.totalOfQuestionGroup = res[1]['group'][this.questionGroup.length - 1]['total'];
      for (var i = 0; i < this.totalOfQuestionGroup.length; i++) {
        this.activtyIdGroup.push(this.totalOfQuestionGroup[i]['assessment_activity_id']);
      }
      this.get360FeedbackQuestion();
    });

  }

  get360FeedbackQuestion() {
    let ff = new ActivityFeedbackQuestionsRetrieveRequest();
    ff.assessment_activity_ids = this.assessment_activity_ids;
    ff.per_page = 40;
    this.appService.getActivityFeedbackQuestionsByActivityId(ff).subscribe((res) => {
      this.activityFeedbackQuestion = res['items'];
      for (var i = 0; i < this.activityFeedbackQuestion.length; i++) {
        this.feedback_competencies_ids.push(this.activityFeedbackQuestion[i]['question_competency_id']);
      }
      this.get360FeedbackQuestionCompetencies();
    });
  }

  get360FeedbackQuestionCompetencies() {
    let cc = new CompetencyRetrieveRequest();
    cc.ids = this.feedback_competencies_ids;
    this.appService.getCompetencies(cc).subscribe((res) => {
      this.competenciesFeedbackData = res['items'];
      this.getAssessmentFeedbackAttempt();

    });
  }

  getAssessmentFeedbackAttempt() {

    let fq = new AssessmentActivityFeedbackAttemptsGroupRetrieveRequest();
    fq.assessment_activity_attempt_id = this.assessment_activity_attempt_id;
    this.appService.getAssessmentActivityFeedbackAttemptsGroup(fq).subscribe((res) => {
      this.assessmentFeedbackAttempt = res[0]['items'];
      this.feedbackGroup = res[1]['group'];
      this.totalOfFeedbackGroup = res[1]['group'][this.feedbackGroup.length - 1]['total'];
      for (var i = 0; i < this.totalOfFeedbackGroup; i++) {
        this.activtyIdGroup.push(this.totalOfFeedbackGroup[i]['assessment_activity_id']);
      }
      this.getPsychometric();
    });
  }

  getPsychometric() {
    let pp = new PsychometricAssessmentGroupRetrieveRequest();
    pp.employee_ids = this.empData?.id.toString();
    this.appService.getPsychometricAnalysysGroup(pp).subscribe((res) => {
      this.psychometricAssessment = res[0]['items'];
      this.totalOfPsychometric = res[1]['group'];
      for (var i = 0; i < this.totalOfPsychometric.length; i++) {
        this.activtyIdGroup.push(this.totalOfPsychometric[i]['assessment_activity_id']);
      }

      this.getFinalActivity();
    });

  }

  getFinalActivity() {
    let a = new ActivityRetrieveRequest();
    a.ids = this.activtyIdGroup;
    this.appService.getActivities(a).subscribe((result) => {
      this.finalActivityGroup = result['items'];

      this.totalScoreCalculation();
    });

  }

  totalScoreCalculation() {

    for (var p = 0; p < this.totalOfQuestionGroup.length; p++) {
      for (var q = 0; q < this.finalActivityGroup.length; q++) {
        if (this.totalOfQuestionGroup[p]['assessment_activity_id'] == this.finalActivityGroup[q]['id']) {
          if (this.totalScoreMap.has(this.finalActivityGroup[q]['assessment_id'])) {
            var score = 0;
            score = (this.totalScoreMap.get(this.finalActivityGroup[q]['assessment_id']) + this.totalOfQuestionGroup[p]['score']);
            this.totalScoreMap.set(this.finalActivityGroup[q]['assessment_id'], score);
          } else {
            this.totalScoreMap.set(this.finalActivityGroup[q]['assessment_id'], this.totalOfQuestionGroup[p]['score']);

          }

        }

      }
    }
    for (var p = 0; p < this.totalOfFeedbackGroup.length; p++) {
      for (var q = 0; q < this.finalActivityGroup.length; q++) {
        if (this.totalOfFeedbackGroup[p]['assessment_activity_id'] == this.finalActivityGroup[q]['id']) {
          if (this.totalScoreMap.has(this.finalActivityGroup[q]['assessment_id'])) {
            var score = 0;
            score = (this.totalScoreMap.get(this.finalActivityGroup[q]['assessment_id']) + this.totalOfFeedbackGroup[p]['score']);
            this.totalScoreMap.set(this.finalActivityGroup[q]['assessment_id'], score);
          } else {
            this.totalScoreMap.set(this.finalActivityGroup[q]['assessment_id'], this.totalOfFeedbackGroup[p]['score']);

          }

        }

      }
    }

    for (var p = 0; p < this.totalOfPsychometric.length; p++) {
      for (var q = 0; q < this.finalActivityGroup.length; q++) {
        if (this.totalOfPsychometric[p]['assessment_activity_id'] == this.finalActivityGroup[q]['id']) {
          if (this.totalScoreMap.has(this.finalActivityGroup[q]['assessment_id'])) {
            var score = 0;
            score = (this.totalScoreMap.get(this.finalActivityGroup[q]['assessment_id']) + this.totalOfPsychometric[p]['score']);
            this.totalScoreMap.set(this.finalActivityGroup[q]['assessment_id'], score);
          } else {
            this.totalScoreMap.set(this.finalActivityGroup[q]['assessment_id'], this.totalOfPsychometric[p]['score']);

          }

        }

      }
    }

  }

  public openPDF(): void {
    html2canvas(document.getElementById('contentToConvert')).then(function(canvas) {
      canvas.getContext('2d');
      var HTML_Width = canvas.width;
      var HTML_Height = canvas.height;
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      // console.log(canvas.height + '  ' + canvas.width);


      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage();
        let margin = -(PDF_Height * i) + (top_left_margin * 4);
        if (i > 1) {
          margin = margin + i * 8;
        }
        // console.log(top_left_margin);
        // console.log(top_left_margin);
        // console.log(-(PDF_Height * i) + (top_left_margin * 4));
        pdf.addImage(imgData, 'JPG', top_left_margin, margin, canvas_image_width, canvas_image_height);

      }

      pdf.save('HTML-Document.pdf');
    });
  }


}
