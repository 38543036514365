<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <h3 class="pb-4 text-center">Quick 360 Report</h3>
            <nz-table #rowSelectionTable nzShowSizeChanger nzBordered [nzLoading]="loading" [nzData]="quickData"
                 (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Activities</th>
                    <!-- <th>Report</th> -->
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rowSelectionTable.data;let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{ item?.activity_title }}</td>
                    <td>
                        <a class="mr-3 report-icon icon" role="button" nzTooltipTitle="Download Reports" nzTooltipPlacement="top" nz-tooltip (click)="downloadReport(item)"><i class="ri-dashboard-fill"></i></a>
                        <a class="mr-2 icon" role="button" nzTooltipTitle="View Details" nzTooltipPlacement="top" nz-tooltip (click)="viewQuick(item)"><i class="fa fa-eye" aria-hidden="true"></i></a>
                        <a class="report-icon" nzTooltipTitle="Dashboard" nzTooltipPlacement="top" nz-tooltip (click)="goDashboard(item)"> <i
                        class="ri-pie-chart-box-line grayIcon mr-2"></i></a>
                    </td>
                    <!-- <td class="text-center">
                        <button class="ml-3" nz-button nzSize="medium" nzType="primary" (click)="viewQuick()">View</button>
                    </td> -->
                    <!-- <td lass="text-center">
                      <a class="mr-2" [routerLink]="['/learning-assessment/dashboard', assessment.id]"><i class="ri-dashboard-fill"></i></a>
                      <a class="mr-2" [routerLink]="['/learning-assessment/activity-report', assessment.id]"><i class="ri-file-chart-line"></i></a>
                      <a class="mr-2" role="button" nzTooltipTitle="Download Reports" nzTooltipPlacement="top" nz-tooltip (click)="downloadReport(assessment)" *ngIf="assessment.launched=='yes' && loggedInUserDetail?.user_type == 'tenant_admin' || loggedInUserDetail?.user_type == 'admin'"><i class="fa fa-download remove"></i></a>
                    </td>
                    <td class="text-center">
                      <a class="mr-2" (click)="launch_assessment(assessment)" class="mr-2" *ngIf="assessment.launched=='no' || assessment.launched==null; else launched" >Not Launched <i class="ri-play-circle-fill grayIcon"></i></a>
                      <ng-template #launched>
                        <a class="mr-2" *ngIf="assessment.launched=='yes' && assessment.status=='new';else closed">Launched <i class="ri-rocket-2-line grayIcon"></i></a>
                        <ng-template #closed>
                          Closed
                        </ng-template>
  
                      </ng-template>
                    </td> -->
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <nz-modal [(nzVisible)]="openPrematureModalPopup" nzTitle="Premature Closure "
    (nzOnCancel)="prematureHandleModalCancel()" (nzOnOk)="prematureHandleModalOk()" nzOkText="Save" nzOkType="primary"  [nzOkDisabled]="!prematureForm.valid">
    <form nz-form [nzLayout]="'vertical'" [formGroup]="prematureForm">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="p_id" nzNoColon="false">Reasons
        </nz-form-label>
        <nz-form-control nzErrorTip="Please Select a Reason!">
          <nz-input-group nzSize="large">
            <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Reason" formControlName="p_id">
              <nz-option *ngFor="let item of prematureOptionList" [nzLabel]="item.category_name" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>Comment</nz-form-label>
        <nz-form-control nzErrorTip="Please write something here!">
          <textarea formControlName="comment" nz-input rows="4" placeholder="write some comments."
            class="answerTextArea"></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-modal> -->
  
  
  <!-- Download Reports popup start  -->
  <nz-modal [(nzVisible)]="openDetails" nzTitle="Activity Details"
    (nzOnCancel)="detailsCancel()" (nzOnOk)="detailsOk()" nzOkText="Ok" nzOkType="primary">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12 mb-3 text-center">
          <h4>Assessment -  <span>{{details?.assessment_name}}</span></h4>
        </div>
          <div class="col-lg-6">
            <p class="mb-1"><b>Activity</b></p>
            <p>{{details?.activity_title}}</p>
            <p class="mb-1"><b>Feedback Roles</b></p>
            <p class="mb-0" *ngFor="let e of details?.feedback_roles;let i = index">{{i+1}}. {{e}}</p>
          </div>
        <div class="col-lg-6">
          <p class="mb-1"><b>Start Date</b></p>
          <p>{{details?.start_date}}</p>
          <p class="mb-1"><b>End Date</b></p>
          <p>{{details?.end_date}}</p>
        </div>
      </div>
    </div>
  </nz-modal>
  <!-- Download Reports popup end  -->
  
  <!-- <nz-modal [(nzVisible)]="openDeadlineModalPopup" nzTitle="Deadline Extend" (nzOnCancel)="deadlineExtedModalCancel()"
    (nzOnOk)="deadlineExtedModalOk()" nzOkText="Save" nzOkType="primary" [nzOkDisabled]="!deadlineForm.valid">
  
    <form nz-form [nzLayout]="'vertical'" [formGroup]="deadlineForm">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="d_id" nzNoColon="false">Reasons
        </nz-form-label>
        <nz-form-control nzErrorTip="Please Select a Reason!">
          <nz-input-group nzSize="large">
            <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Reason" formControlName="d_id">
              <nz-option *ngFor="let item of deadlineOptionList" [nzLabel]="item.category_name" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>Choose End Date and Time <span *ngIf="myZoneAndTime?.zone">
            ({{myZoneAndTime.zone.country.country_code}} - {{myZoneAndTime.zone.timezone_code}})</span></nz-form-label>
        <nz-form-control nzErrorTip="Please input New End Date!">
          <nz-date-picker nzShowTime nzFormat="yyyy-MM-dd HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 10 }"
            [nzSize]="'large'" formControlName="extend_date" >
          </nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>Comment</nz-form-label>
        <nz-form-control nzErrorTip="Please write something here!">
          <textarea formControlName="comment" nz-input rows="4" placeholder="write some comments."
            class="answerTextArea"></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div *ngIf="alreadyAdded.length>0">
      <h5>Deadline Extended History</h5>
      <div *ngFor="let data of alreadyAdded;let i=index">
        {{i+1}} :
        <span>Extended Due to : {{data?.category.category_name}} , Comment : {{data?.comment}}</span>
      </div>
    </div>
  </nz-modal> -->
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
    <p style="color: white" *ngIf="!isReport">Please Wait... </p>
    <p style="color: white" *ngIf="isReport">Generating Report. Please Wait... </p>
  </ngx-spinner>