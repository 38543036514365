import {Component, OnInit} from '@angular/core';
import {StrategicPerspective, SPRetrieveRequest} from 'src/app/app.models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-strategic-perspectives',
  templateUrl: './strategic-perspectives.component.html',
  styleUrls: ['./strategic-perspectives.component.css']
})

export class StrategicPerspectivesComponent implements OnInit {
  total = 1;
  // These are the SPs which are displayed in the list screen.
  sps: StrategicPerspective[] = [];
  // This is the sp being edited.
  spId: number;

  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  strategicPerspectiveForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  isActiveValue: string;
  action = null;
  spFormValues: any;
  // state required for the auto complete
  functionOptionList: Function[] = [];
  functionOptionListSearchChange$ = new BehaviorSubject('');
  isFunctionOptionListLoading = false;

  constructor(private fb: FormBuilder,
              private appService: AppService,
              private message: NzMessageService,
              private modal: NzModalService) {
  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(entityId: number): void {
    this.spId = entityId;
    this.getSPById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getSPById(id) {
    this.appService.get('/api/strategic-perspectives/' + id).subscribe(res => {
      this.strategicPerspectiveForm.patchValue(res);
      this.spFormValues = res;
      this.strategicPerspectiveForm.get('is_active').setValue(this.spFormValues.is_active == 'yes');
    });
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;

    // Create the location on the server.
    if (this.strategicPerspectiveForm.status === 'VALID') {
      let data = {
        'title': this.strategicPerspectiveForm.value.title,
        'description': this.strategicPerspectiveForm.value.description,
        'short_code': this.strategicPerspectiveForm.value.short_code,
        'is_active': this.strategicPerspectiveForm.value.is_active ? 'yes' : 'no',
        // formData.append("is_active", this.employeeForm.get('is_active').value ? 'yes' : 'no');
      };

      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createStrategicPerspective(data);
        message = 'Strategic Perspective "' + this.strategicPerspectiveForm.controls['title'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateStrategicPerspective(data, this.spId);
        message = 'Strategic Perspective "' + this.strategicPerspectiveForm.controls['title'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  resetCreateModal(): void {
    this.strategicPerspectiveForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      short_code: [null],
      is_active: ['yes'],
    });
  }

  // onChangeStatus($event){
  //   this.isActiveValue = $event;
  // }

  handleModalCancel(): void {
    this.isFormVisible = false;
  }

  submitForm(): void {
    for (const i in this.strategicPerspectiveForm.controls) {
      this.strategicPerspectiveForm.controls[i].markAsDirty();
      this.strategicPerspectiveForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.resetCreateModal();

    // const optionList = (functionName: string) => {
    //   this.isFunctionOptionListLoading = true;
    //   let rr = new FunctionRetrieveRequest(functionName)
    //   return this.appService.getFunctions(rr)
    //     .pipe(
    //       map((res: any) => {
    //         return res['items'];
    //       })
    //     );
    // }

    // const optionList$: Observable<Function[]> = this.functionOptionListSearchChange$
    //   .asObservable()
    //   .pipe(debounceTime(500))
    //   .pipe(switchMap(optionList));
    // optionList$.subscribe(data => {
    //   this.functionOptionList = data;
    //   this.isFunctionOptionListLoading = false;
    // });

  }

  public remove(entity: StrategicPerspective) {
    const index: number = this.sps.indexOf(entity);

    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.title + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteStrategicPerspective(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new SPRetrieveRequest('');
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;

    this.appService.getStrategicPerspectives(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.sps = data['items'];
    });
  }


}
