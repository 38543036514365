<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">

<!--             Add competancy modal-->
                        <nz-modal nzWidth="800px" [(nzVisible)]="isFormVisible" nzTitle="{{action | titlecase}} Competency"
                          (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalSubmit()" nzOkText="Save" nzOkType="primary"
                          [nzOkLoading]="isFormLoading" [nzOkDisabled]="!competencyForm.valid || !radioValue">
                          <form nz-form [nzLayout]="'vertical'" [formGroup]="competencyForm" (ngSubmit)="submitForm()">

                            <!-- Input For Level Name -->
                            <nz-form-item>
                              <nz-form-label nzRequired nzFor="competency_name" nzNoColon="false">Level Name</nz-form-label>
                              <nz-form-control nzErrorTip="Please input Level name!">
                                <nz-input-group nzSize="large">
                                  <input noWhitespace formControlName="competency_name" nz-input nzSize="large"
                                    placeholder="Level Name" OnlyCharacter/>
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                            <!-- Input for level name is ends here -->

                            <!-- Input for is Active -->
                            <!-- <nz-form-item>
                              <nz-form-control nzErrorTip="Please input Level name!">
                                <nz-input-group nzSize="large">
                                  <a>
                                    <label class="question-checkbox" nz-checkbox formControlName="is_active">Active</label>
                                  </a>
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item> -->
                            <!-- Input for Is active ends here -->

                            <!-- Input for showing horizontal radxio buttons -->
                            <nz-form-item>
                              <nz-form-label nzRequired nzFor="level_name" nzNoColon="false">Select Level</nz-form-label>
                              <nz-form-control nzErrorTip="Please Select Level name!">
                                <nz-radio-group [(ngModel)]="radioValue" [ngModelOptions]="{standalone: true}">
                                  <label nz-radio *ngFor="let radioFiled of radioFileds; let i=index" (click)="levelChanged(i)"
                                    [nzValue]="radioFiled.id">{{radioFiled.level_name}}</label>
                                </nz-radio-group>
                              </nz-form-control>
                            </nz-form-item>
                            <!-- Input radio button ends here -->

                            <!-- Input for select the parent level -->
                            <nz-form-item>
                              <nz-form-label nzRequired nzFor="parent_id" nzNoColon="false">Parent Level
                              </nz-form-label>
                              <nz-form-control nzErrorTip="Please Select a Parent Level!">
                                <nz-input-group nzSize="large">
                                  <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Competencies"
                                    formControlName="parent_id">
                                    <nz-option *ngFor="let item of competancyOptionList" [nzLabel]="item.competency_name"
                                      [nzValue]="item.id"></nz-option>
                                  </nz-select>
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                            <!-- Parent level eds here -->

                            <!-- Input select for selecting keywords -->
                            <div class="row" formArrayName="description">
                              <div class="col-lg-12 labl">
                                <nz-form-item>
                                  <nz-form-label nzRequired nzFor="description" nzNoColon="false">Description</nz-form-label>
                                </nz-form-item>
                              </div>
                              <ng-container *ngFor="let desc of competencyForm.controls.description['controls']; let k=index" [formGroupName]="k">
                                <div class="col-lg-11">
                                  <nz-form-item>
                                    <!-- <nz-form-label nzRequired nzFor="description" nzNoColon="false">Description</nz-form-label> -->
                                    <nz-form-control nzErrorTip="Please input Description!">
                                      <nz-input-group nzSize="large">
                                        <!-- <input formControlName="description" nz-input nzSize="large"
                                          placeholder="Description"/> -->
                                          <textarea formControlName="description" nz-input nzSize="large"
                                          placeholder="Description"></textarea>
                                      </nz-input-group>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div>
                                <div class="col-lg-1">
                                  <a *ngIf="competencyForm.controls.description?.length > 1" title="Delete" class="mr-2  delete-icon"><i
                                    class="ri-delete-bin-6-fill" (click)="removeDesc(k, desc['controls'])"></i></a>
                                </div>
                              </ng-container>
                              <div class="col-lg-11 new-add text-right">
                                <a title="Add" class="add-btn ml-3" (click)="addNewDesc()"><i class="ri-add-circle-fill"></i></a>
                              </div>
                            </div>
                            <!-- Input for keyword ends here -->

                            <!-- Input select for selecting keywords -->
                            <nz-form-item>
                              <nz-form-label nzFor="keywords" nzNoColon="false">Keywords</nz-form-label>
                              <nz-form-control>
                                <nz-input-group nzSize="large">
                                  <!-- <input formControlName="keywords" nz-input nzSize="large" placeholder="Name"/> -->
                                  <!-- <nz-select [(ngModel)]="tagValue" nzSize="large" nzMode="tags" [ngModelOptions]="{standalone: true}"
                                    nzPlaceHolder="Please Add Some Keywords"></nz-select> -->
                                  <input noWhitespace nz-input nzSize="large" [(ngModel)]="inputTag" (blur)="handleInputConfirm()"
                                    (keydown.enter)="handleInputConfirm()" placeholder="Keywords"
                                    [ngModelOptions]="{standalone: true}" />
                                    <br />
                                  <nz-tag *ngFor="let tag of tagValue; let i = index" nzMode="closeable" [nzColor]="'purple'"
                                    (nzOnClose)="handleClose(tag)">{{ sliceTagName(tag) }}</nz-tag>
                                </nz-input-group>

                              </nz-form-control>
                            </nz-form-item>
                            <!-- Input for keyword ends here -->


                          </form>
                        </nz-modal>
<!--             Add competancy modal ends here-->
            <h3 class="py-4 text-center">List of Competencies</h3>
       
                <div class="d-flex justify-content-between align-items-center">

                  <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
                  <div>
                    <button nz-button nzType="primary" (click)="showImportModal()">
                      <span>Import</span>
                    </button>
                  </div>
                </div>  
            <nz-table #expandTable [nzData]="listOfMapData" [nzLoading]="loading" nzTableLayout="fixed">
              <thead>
              <tr>
                <th nzWidth="70%">Competency</th>
                <th nzWidth="20%">Level</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let data of expandTable.data">
                <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
                  <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                    <td
                      [nzIndentSize]="item.level! * 20"
                      [nzShowExpand]="!!item.children"
                      [(nzExpand)]="item.expand"
                      (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)"
                    >
                      {{ item.title }}
                    </td>
                    <td>{{ item.mydata.level_name}} </td>
                    <td>
                      <a (click)="showEditModal(item.mydata.id)"><i class="ri-pencil-fill grayIcon"></i></a>
                      <nz-divider nzType="vertical"> |</nz-divider>
                      <a (click)="remove(item.mydata)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              </tbody>
            </nz-table>
            <nz-modal [(nzVisible)]="isImportFormVisible" nzTitle="Bulk Import" (nzOnCancel)="handleModalCancel()"
            (nzOnOk)="handleImportModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
            nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

           <a href="javascript:void(0);" (click)="openInstructionPdf()">Click here to see Instructions</a> <br><br>



            <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
              class="mr-3">
              Sample CSV Download
            </button>
            <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
              <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
            </nz-upload>
          </nz-modal>

            <!--            <div>-->
            <!--              <nz-tree #treeCom [nzData]="processCompData" nzShowLine (nzClick)="activeNode($event)"-->
            <!--                       [nzAsyncData]="true"-->
            <!--                       (nzDblClick)="openFolder($event)"-->
            <!--                       [nzTreeTemplate]="nzTreeTemplate"></nz-tree>-->

            <!--              <ng-template #nzTreeTemplate let-node let-origin="origin">-->
            <!--      <span class="custom-node">-->
            <!--        <span *ngIf="!node.isLeaf" (contextmenu)="contextMenu($event, menu)">-->
            <!--          <i nz-icon [nzType]="node.isExpanded ? 'folder-open' : 'folder'" (click)="openFolder(node)"></i>-->
            <!--          <span class="folder-name">{{ node.title }}</span>-->
            <!--          <span class="folder-desc">created by {{ origin.author | lowercase }}</span>-->
            <!--        </span>-->
            <!--        <span *ngIf="node.isLeaf" (contextmenu)="contextMenu($event, menu)">-->
            <!--          <i nz-icon nzType="file"></i>-->
            <!--          <span class="file-name">{{ node.title }}</span>-->
            <!--          <span class="file-desc">modified by {{ origin.author | lowercase }}</span>-->
            <!--        </span>-->
            <!--      </span>-->
            <!--              </ng-template>-->
            <!--              <nz-dropdown-menu #menu="nzDropdownMenu">-->
            <!--                <ul nz-menu>-->
            <!--                  <li nz-menu-item (click)="selectDropdown()">Action 1</li>-->
            <!--                  <li nz-menu-item (click)="selectDropdown()">Action 2</li>-->
            <!--                </ul>-->
            <!--              </nz-dropdown-menu>-->
            <!--            </div>-->
            <!--            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"-->
            <!--              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"-->
            <!--              [nzData]="competencies">-->
            <!--              <thead>-->
            <!--                <tr>-->
            <!--                  &lt;!&ndash; <th nzColumnKey="id" [nzSortFn]="true">Id</th>-->
            <!--                    <th nzColumnKey="competencyName" [nzSortFn]="true">Competency Name</th>-->
            <!--                    <th nzColumnKey="competencyCat" [nzSortFn]="true">Competency Category</th>-->
            <!--                    <th nzColumnKey="behaviourName" [nzSortFn]="true">Behaviour Name</th>-->
            <!--                    <th nzColumnKey="keywords" [nzSortFn]="true">Keywords</th> &ndash;&gt;-->

            <!--                  <th>Name</th>-->
            <!--                  <th>parent Name</th>-->
            <!--                  <th>Keywords</th>-->
            <!--                  <th>Action</th>-->
            <!--                </tr>-->
            <!--              </thead>-->
            <!--              <tbody>-->
            <!--                <tr *ngFor="let comp of competencies">-->
            <!--                  <td>{{ comp.competency_name }}</td>-->
            <!--                  <td>{{ comp.parent_name }}</td>-->
            <!--                  <td>{{ comp.keywords }}</td>-->
            <!--                  <td>-->
            <!--                    <a (click)="showEditModal(comp.id)"><i class="ri-pencil-fill grayIcon"></i></a>-->
            <!--                    <nz-divider nzType="vertical"> | </nz-divider>-->
            <!--                    <a (click)="remove(comp)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--              </tbody>-->
            <!--            </nz-table>-->
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
