<!-- <div id="content-page" class="content-page">
    <div class="container" nz-row>
        <p nz-col [nzSpan]="24" class="heading-text">{{ campaignDetail.campaign_name }} - Campaign Dashboard</p>
        <div nz-col [nzSpan]="24">
            <form nz-form [nzLayout]="'vertical'" [formGroup]="filterForm" (ngSubmit)="submitFilterForm()">
                <div nz-row [nzGutter]="16" nzAlign="middle">
                    <div nz-col [nzSpan]="6">
                        <nz-form-item>
                            <nz-form-label nzRequired>From Date</nz-form-label>
                            <nz-form-control nzErrorTip="Please input Start Date!">
                                <nz-date-picker [nzSize]="'large'" [nzFormat]="dateFormat" formControlName="from_date">
                                </nz-date-picker>

                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="6">
                        <nz-form-item>
                            <nz-form-label nzRequired>To Date</nz-form-label>
                            <nz-form-control nzErrorTip="Please input End Date!">
                                <nz-date-picker [nzSize]="'large'" [nzFormat]="dateFormat" formControlName="to_date">
                                </nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="6">
                        <nz-form-item>
                            <nz-form-label nzRequired>Transaction Type</nz-form-label>
                            <nz-form-control nzErrorTip="Please input Transaction Type!">
                                <nz-input-group>
                                    <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Transaction Type"
                                        formControlName="share_type">
                                        <nz-option *ngFor="let item of listOfTransactionType" [nzLabel]="item.label"
                                            [nzValue]="item.value"></nz-option>
                                    </nz-select>
                                </nz-input-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col [nzSpan]="6">
                        <button nz-button nzType="primary" class="mr-3">
                           
                            Go
                        </button>

                    
                    </div>
                </div>
            </form>
        </div>
        <div nz-col [nzSpan]="24" class="mt-3 pt-3">
            <div class="iq-card">
                <div class="iq-card-body">
                    <p class="heading-text">Transaction Count</p>
                    <div class="text-center">
                        <nz-radio-group (ngModelChange)="levelChange($event, 'Transaction Count')"
                            [(ngModel)]="CountLevel" nzSize="large">
                            <ng-container *ngFor="let level of levelOptions; let i = index">
                                <label nz-radio-button [nzValue]="i+1">{{campaignDetail.level_names[i]}}</label>
                            </ng-container>
                        </nz-radio-group>
                    </div>
                    <div id="pieChartTransactionCount" style="width: 100%; height: 400px"></div>
                  
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="24" class="mt-3 pt-3">
            <div class="iq-card">
                <div class="iq-card-body">
                    <p class="heading-text">Transaction Trend</p>
                    <div nz-row>
                        <div nz-col nzSpan="20" class="text-center">

                            <nz-radio-group (ngModelChange)="levelChange($event, 'Transaction Trend')"
                                [(ngModel)]="TrendLevel" nzSize="large">
                                <ng-container *ngFor="let level of levelOptions; let i = index">
                                    <label nz-radio-button [nzValue]="i+1">{{campaignDetail.level_names[i]}}</label>
                                </ng-container>
                            </nz-radio-group>

                        </div>
                        <div nz-col nzSpan="4" class="text-right">
                            <nz-radio-group (ngModelChange)="timePeriodChange($event)"
                                [(ngModel)]="timePeriod" nzSize="large">
                                <label nz-radio-button nzValue="daily">Daily</label>
                                <label nz-radio-button nzValue="monthly">Monthly</label>
                            </nz-radio-group>
                        </div>
                    </div>

                    <div id="barChartTransactionTrend" style="width: 100%; height: 400px"></div>
                   
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="24" class="mt-3 pt-3">
            <div class="iq-card">
                <div class="iq-card-body">
                    <p class="heading-text">Employee Participation</p>
                    <div class="text-center">
                        <nz-radio-group (ngModelChange)="levelChange($event, 'Employee Participation')"
                            [(ngModel)]="ParticipationLevel" nzSize="large">
                            <ng-container *ngFor="let level of levelOptions; let i = index">
                                <label nz-radio-button [nzValue]="i+1">{{campaignDetail.level_names[i]}}</label>
                            </ng-container>
                        </nz-radio-group>
                    </div>
                    <div id="pieChartEmployeeParticipation" style="width: 100%; height: 400px"></div>
                   
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="24" class="mt-3 pt-3" *ngIf="dashboardDataTops">
            <div class="iq-card">
                <div class="iq-card-body">
                    <div nz-row nzGutter="8">
                        <div nz-col nzSpan="8" *ngIf="dashboardDataTops.top_participants">
                            <nz-list nzBordered nzSize="small">
                                <nz-list-header>Top Participations</nz-list-header>
                                <nz-list-item *ngFor="let item of dashboardDataTops.top_participants">
                                    <nz-list-item-meta
                                    [nzAvatar]="item.profile_pic"
                                        >
                                        <nz-list-item-meta-title>
                                            {{ item.emp_name }}
                                        </nz-list-item-meta-title>
                                    </nz-list-item-meta>
                                    {{ item.count }}
                                </nz-list-item>
                            </nz-list>
                        </div>
                        <div nz-col nzSpan="8" *ngIf="dashboardDataTops.top_displayers">
                            <nz-list nzBordered nzSize="small">
                                <nz-list-header>Top Displayers</nz-list-header>
                                <nz-list-item *ngFor="let item of dashboardDataTops.top_displayers">
                                    <nz-list-item-meta
                                    [nzAvatar]="item.profile_pic"
                                        >
                                        <nz-list-item-meta-title>
                                            {{ item.emp_name }}
                                        </nz-list-item-meta-title>
                                    </nz-list-item-meta>
                                    {{ item.count }}
                                </nz-list-item>
                            </nz-list>
                        </div>
                        <div nz-col nzSpan="8" *ngIf="dashboardDataTops.top_observers">
                            <nz-list nzBordered nzSize="small">
                                <nz-list-header>Top Observers</nz-list-header>
                                <nz-list-item *ngFor="let item of dashboardDataTops.top_observers">
                                    <nz-list-item-meta
                                        [nzAvatar]="item.profile_pic"
                                        >
                                        <nz-list-item-meta-title>
                                            {{ item.emp_name }}
                                        </nz-list-item-meta-title>
                                    </nz-list-item-meta>
                                    {{ item.count }}
                                </nz-list-item>
                            </nz-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="content-page" class="content-page">
    <div class="text-center" style="padding: 50px;" *ngIf="isLoading">
        <span style="font-size: 20px;">
            <i nz-icon nzType="loading" class="loading-icon"></i>
            Loading..
        </span>
    </div>
    <div class="container" *ngIf="!isLoading">
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">

                        <p nz-col class="heading-text">{{campaignDetail?.campaign_name}} - Campaign Dashboard</p>

                        <div class="row justify-content-center">
                            <div class="col-sm-2">
                                <div class="card bg-light mb-3 card1" style="max-width: 18rem; height: 150px;">
                                    <div class="card-body">
                                        <h5 class="card-title">Total Participants</h5>
                                        &nbsp;
                                        <p class="card-text text-center"
                                            style="font-size: xx-large; font-weight: bold;align-items: center;">
                                            {{transactionCounts?.no_of_participants}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card bg-light mb-3 card2" style="max-width: 18rem; height: 150px;">
                                    <div class="card-body">
                                        <h5 class="card-title">Total Transactions</h5>
                                        &nbsp;
                                        <p class="card-text text-center"
                                            style="font-size: xx-large; font-weight: bold;align-items: center;">
                                            {{transactionCounts?.no_of_transactions}}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-2">
                                <div class="card bg-light mb-3 card3" style="max-width: 18rem; height: 150px;">
                                    <div class="card-body">
                                        <h5 class="card-title">Total Self Transactions</h5>
                                        <p class="card-text text-center"
                                            style="font-size: xx-large; font-weight: bold;align-items: center;">
                                            {{transactionCounts?.no_of_self_transactions}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card bg-light mb-3 card4" style="max-width: 18rem; height: 150px;">
                                    <div class="card-body">
                                        <h5 class="card-title">Total Share With Peer Transactions</h5>
                                        <p class="card-text text-center"
                                            style="font-size: xx-large; font-weight: bold;align-items: center;">
                                            {{transactionCounts?.no_of_sharing_transactions}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="card bg-light mb-3 card5" style="max-width: 18rem; height: 150px;">
                                    <div class="card-body">
                                        <h5 class="card-title">Total Seek From Peer Transactions</h5>
                                        <p class="card-text text-center"
                                            style="font-size: xx-large; font-weight: bold;align-items: center;">
                                            {{transactionCounts?.no_of_seeking_transactions}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="iq-card chart1">
                    <div class="iq-card-body">
                        <div class="wrapper-class">
                            <p nz-col class="heading-text">Monthly Transaction Trends</p>
                            <div id="chart1" style="width: 100%; height: 430px"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6">
                <div class="iq-card chart2">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Displayed Behaviours</p>

                        <div class="text-center">
                            <nz-radio-group [(ngModel)]="level" (ngModelChange)="levelChange()">
                                <ng-container>
                                    <label nz-radio-button nzValue=0>Behaviour</label>
                                    <label nz-radio-button nzValue=1>Nano Behaviour</label>
                                </ng-container>
                            </nz-radio-group>

                              <!-- <div class="text-center">
                                <nz-radio-group (ngModelChange)="levelChange($event, 'Transaction Count')"
                                    [(ngModel)]="CountLevel" nzSize="large">
                                    <ng-container *ngFor="let level of levelOptions; let i = index">
                                        <label nz-radio-button [nzValue]="i+1">{{campaignDetail.level_names[i]}}</label>
                                    </ng-container>
                                </nz-radio-group>
                            </div> -->
                        </div>

                        <div id="chart2" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!isChartEnable">
            <div class="col-sm-4 col-lg-4">
                <div class="iq-card chart3">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Top Displayers</p>
                        <div id="chart3" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="iq-card chart4">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Top Observers</p>
                        <div id="chart4" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="iq-card chart5">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Top Participants</p>
                        <div id="chart5" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row-sm-12" *ngIf="!isChartEnable">
            <div class="iq-card chart6">
                <div class="iq-card-body">
                    <div style="float:right">
                        <a (click)="openGroupingModal()"><span>Group By <i class="ri-arrow-up-down-fill"></i></span>
                        </a>
                        &nbsp;
                    </div>
                    <p nz-col class="heading-text">{{selectedFilter.label}} Wise Displayed Behaviours</p>
                    <div id="chart6" style="width: 100%; height: 400px"></div>
                </div>
            </div>
        </div>

        <nz-modal [(nzVisible)]="isGroupingModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Group By"
            (nzOnCancel)="handleGroupCancel()" (nzOnOk)="handleGroupOk()" nzOkText="Apply">
            <div class="row">
                <a *ngFor="let group of groupingList" (click)="checkedChoice(group)">
                    <label nz-checkbox (change)="checkedChoice(group)" [(ngModel)]="group.is_select"
                        [ngModelOptions]="{standalone: true}">{{group.label}}</label>
                </a>
            </div>
        </nz-modal>

        <div class="row-sm-12">
            <div class="iq-card chart7">
                <div class="iq-card-body">
                    <p nz-col class="heading-text">Behaviours Transactions By Employees</p>
                    <div id="chart7" style="width: 100%; height: 400px"></div>
                </div>
            </div>
        </div>
    </div>
</div>