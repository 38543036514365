import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { RoleObjectiveRetrieveRequest, ObjectiveMasterRetrieveRequest, APRetrieveRequest, ManagerRoleRetrieveRequest } from './../../../app.models';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Component, OnInit,  ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
export interface TreeNodeInterface {
  id: number,
  // completion_percent?: number,
  // is_active?: String,
  // role_id?: number,
  // role_obj_group_id?: number,
  sp_id?: number,
  ap_id?: number,
  objective_id?: number,
  // sp_name?: String,
  weightage?: number
  type: string;
  key: string;
  name: string;
  // sPWeightage?: string;
  // aPWeightage?: string;
  // objWeightage?: string;
  uom?: string;
  isProjectBased?: string;
  parent_objective_role_id?: number;
  manager_role_objective_name?: string;
  // managerObjective?: string;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
}
@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.css']
})
export class ObjectiveComponent implements OnInit {
  dateFormat = 'dd-MM-yyyy';
  action = "create";
  loading = false;
  isFormLoading = false;
  current = 0;
  contentIndex = 0;
  objectiveId: any;
  isVisible = false;
  isApModal = false;
  isObjectiveModal = false;
  isSpModal = false;
  public show: boolean = false;
  // public isShowTbl: boolean = false;
  objectiveDateForm!: FormGroup;
  roleObjectiveListForm!: FormGroup;
  spForm!: FormGroup;
  apForm!: FormGroup;
  objectiveForm!: FormGroup;
  public role_id;
  public role_name;
  public roleObjectiveList;
  roleObjectiveGroupsSpList: any;
  public apList;
  public apId;
  public objctiveMasterList: any;
  public uomList: any;
  treeData: TreeNodeInterface[];
  clickedRowDetails: any;
  roleObjectiveId: any;
  managerRoleObjectiveList: any;
  public dropdownObjectiveSet: any;
  public selectedObjectiveSetDetails: any;
  constructor(private fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  // poup Module

  showNewObjectiveModal(): void {
    this.isObjectiveModal = true;
  }
  objectiveHandleCancel(): void {
    this.isObjectiveModal = false;
  }

  showAPWheightageModal(): void {
    this.isApModal = true;
  }
  // apHandleSubmit(): void {
  //   console.log('Button ok clicked!');
  //   this.isApModal = false;
  // }
  apHandleCancel(): void {
    this.isApModal = false;
  }

  // showSPWeightageModal(): void {
  //   this.isSpModal = true;
  // }

  spHandleCancel(): void {
    this.isSpModal = false;
  }

  //tree table related

  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: true, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }


    //date picker
    @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;

    disabledStartDate = (current: Date): boolean => {
      // if (!current || !this.assessmentForm.get("end_date").value) {
      //   return false;
      // }
      // return start_date.getTime() > (new Date(this.assessmentForm.get("end_date").value)).getTime();
      return differenceInCalendarDays(current, new Date()) < 0;
    };

    disabledEndDate = (end_date: Date): boolean => {
      if (!end_date || !this.objectiveDateForm.get("start_date").value) {
        return false;
      }
      return end_date.getTime() <= (new Date(this.objectiveDateForm.get("start_date").value)).getTime();
    };

    handleStartOpenChange(open: boolean): void {
      if (!open) {
        this.endDatePicker.open();
      }
      // console.log('handleStartOpenChange', open);
    }

    handleEndOpenChange(open: boolean): void {
      // console.log('handleEndOpenChange', open);
    }
  ngOnInit(): void {
    this.objectiveDateForm = this.fb.group({
      start_date: [null, Validators.compose([Validators.required])],
      end_date: [null, Validators.compose([Validators.required])]
    });
    this.roleObjectiveListForm = this.fb.group({
      role_objective: [null, [Validators.required]]
    });
    this.objectiveForm = this.fb.group({
      objective_id: [null, [Validators.required]], //objective_id
      weightage: [null, [Validators.required]],
      uom: [null, [Validators.required]],
      is_project_based: [null],
      role_objective_id: [null],
      parent_objective_role_id: [null],
      display_uom: [{value: null, disabled: true}],
    });
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.role_id = parseInt(params['id']);
        // this.action = params['action'];
        // if (this.action === "edit") {
        this.getRoleById(this.role_id);
        this.getRoleObjectiveGroupsByRoleId(this.role_id);
        // }
      }
    });

    this.spForm = this.fb.group({
      weightage: [null, [Validators.required]]
    });

    this.apForm = this.fb.group({
      ap_id: [null, [Validators.required]],
      weightage: [null, [Validators.required]]
    });

    this.onValueChanges();
    // this.onApDropdownChange();
  }
  toggle() {
    this.show = !this.show;
  }
  resetForm(): void {
    this.objectiveDateForm.reset();
  }

  submitForm() {
    for (const i in this.objectiveDateForm.controls) {
      this.objectiveDateForm.controls[i].markAsDirty();
      this.objectiveDateForm.controls[i].updateValueAndValidity();
    }
    // Create the Setting on the server.
    if (this.objectiveDateForm.valid) {
      // let image_file = this.objectiveDateForm.controls['image_id'].value[0].file;
      // console.log(this.objectiveDateForm.value);
      let start_date = this.datepipe.transform(this.objectiveDateForm.value.start_date, 'yyyy-MM-dd');
      let end_date = this.datepipe.transform(this.objectiveDateForm.value.end_date, 'yyyy-MM-dd');

      let data = {
        start_date: start_date,
        end_date: end_date,
        role_id: this.role_id
      }
      let subscriber = null;
      let message = null;
      if (this.action === "create") {
        subscriber = this.appService.createRoleObjective(data);
        message = 'Objective"' + this.objectiveDateForm.controls['start_date'].value + '" has been added.';
      }
      // else if (this.action === 'edit') {
      //   subscriber = this.appService.updateMaximiserSettings(formData, this.settingId);
      //   message = 'Setting "' + this.objectiveDateForm.controls['company_name'].value + '" has been updated.';
      // }

      subscriber.subscribe(res => {
        this.isFormLoading = false;
        this.roleObjectiveListForm.get('role_objective').setValue(res.id);
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.getRoleObjectiveGroupsByRoleId(this.role_id);
        this.objectiveDateForm.reset();
        // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);
        // this.current += 1;
        // this.changeContent();

      }, error => {
        this.isFormLoading = false;

        // let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  async getRoleById(id) {
    await this.appService.getRoleById(id).subscribe(res => {
      // console.log("dropdownObjectiveSet", res);
      this.dropdownObjectiveSet = res;
      this.role_id = res.id;
      this.role_name = res.role_name;
      // this.spForm.patchValue(res);
    })
  }

  // get dropdown list
  async getRoleObjectiveGroupsByRoleId(id) {
    let rr = new RoleObjectiveRetrieveRequest;
    rr.role_id = id;
    rr.sort_order = 'asc';
    await this.appService.getRoleObjective(rr).subscribe((res) => {
      this.roleObjectiveList = res['items'];
      // console.log("roleObjectiveList:", this.roleObjectiveList);

    })
  }
  // get Ap dropdown list for ap model
  async getApList() {
    // let apList = await this.appService.getAnnualPriorities().toPromise();
    // this.apList = apList['items'];
    let rr = new APRetrieveRequest();
    rr.sp_id = this.clickedRowDetails.sp_id;
    await this.appService.getAnnualPriorities(rr).subscribe((res: any) => {
      // console.log(res);
      this.apList = res['items'];
    })
  }
  onValueChanges(): void {
    this.roleObjectiveListForm.get('role_objective').valueChanges.subscribe(val => {
      this.roleObjectiveId = val;
      // console.log("val", val);
      this.getTreeTableData(val);
    });

  }
  getTreeTableData(id) {
    this.treeData = [];
    this.appService.getRoleObjectiveGroup(id).subscribe((res: any) => {
      // console.log("Tree Actual date:", res);
      this.selectedObjectiveSetDetails = res;
      this.roleObjectiveGroupsSpList = res.role_obj_group_sps;
           this.roleObjectiveGroupsSpList.forEach((s: any, i) => {
        let level_1 = (i + 1).toString();
        this.treeData.push({
          type: "sp",
          key: level_1,
          id: s.id,
          sp_id: s.sp_id,
          name: s.name,
          weightage: s.weightage,
        });
        if (s.role_sp_aps) {
          if (s.role_sp_aps.length > 0) {
            this.treeData[i].children = [];
            s.role_sp_aps.forEach((a: any, j) => {
              let level_2 = (level_1 + "-" + (j + 1));
              this.treeData[i]['children'].push({
                type: "ap",
                key: level_2,
                id: a.id,
                ap_id: a.ap_id,
                name: a.name,
                weightage: a.weightage,
              });
              if (a.role_objectives) {
                if (a.role_objectives.length > 0) {
                  this.treeData[i]['children'][j].children = [];
                  a.role_objectives.forEach((o: any, k) => {
                    let level_3 = (level_2 + "-" + (k + 1));
                    this.treeData[i]['children'][j]['children'].push({
                      type: "obj",
                      key: level_3,
                      id: o.id,
                      objective_id: o.objective_id,
                      name: o.name,
                      weightage: o.weightage,
                      uom: o.uom,
                      isProjectBased: o.is_project_based,
                      manager_role_objective_name: o.manager_role_objective_name,
                      parent_objective_role_id: o.parent_objective_role_id,

                    });
                  });
                }
              }
            });
          }
        }
      });
      this.treeData.forEach(item => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });
      // console.log("treeData", this.treeData);
      // this.isShowTbl = !this.isShowTbl;
    });
  }

 async openAddModal(data) {
    this.clickedRowDetails = data;
    switch (data.type) {
      case "sp":
        this.apForm.reset();
        this.isApModal = true;
        this.getApList();
        break;
      case "ap":
        this.objectiveForm.reset();
        this.isObjectiveModal = true;
        // this.getApList();
        await this.getUOMList();
        await this.getObjectiveMasterGroupsById(this.clickedRowDetails.ap_id);
        await this.getManagerRoleObjective();
        // console.log("clickedRowDetails", this.clickedRowDetails);
        this.objectiveForm.get('display_uom').setValue('%');

        break;
      default:
        break;
    }
  }
  async openEditModal(data) {
    this.clickedRowDetails = data;
    switch (data.type) {
      case "sp":
        this.spForm.reset();
        this.isSpModal = true;
        this.spForm.patchValue(data);
        break;
      case "ap":
        this.apForm.reset();
        this.isApModal = true;
        await this.getApList();
        await this.apForm.patchValue(data);
        break;
      case "obj":
        this.objectiveForm.reset();
        this.isObjectiveModal = true;
        await this.getUOMList();
        await this.getObjectiveMasterGroupsById(this.clickedRowDetails?.parent?.ap_id);
        // console.log("obj edit:", data);
        await this.getManagerRoleObjective();
        await this.objectiveForm.patchValue(data);
        await this.objectiveForm.get('objective_id').setValue(data.objective_id);
        await this.objectiveForm.get('is_project_based').setValue(data.isProjectBased == 'yes');
        await this.objectiveForm.get('parent_objective_role_id').setValue(data.parent_objective_role_id);
        await this.objectiveForm.get('display_uom').setValue(data.uom);
        break;
      default:
        break;
    }
  }

  spEditHandleSubmit(): void {
    this.isFormLoading = true;
    // Create the SP Update on the server.
    if (this.spForm.status === 'VALID') {
      let data = {
        "weightage": this.spForm.value.weightage
      }

      let subscriber = null;
      let message = null;

      subscriber = this.appService.updateRoleStrategicPerspective(data, this.clickedRowDetails.id);
      message = 'SP "' + this.clickedRowDetails.name + '" has been updated.';

      subscriber.subscribe(res => {
        this.isSpModal = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        // this.isShowTbl = !this.isShowTbl;
        this.getTreeTableData(this.roleObjectiveId);
        // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

      }, error => {
        this.isSpModal = false;
        this.isFormLoading = false;

        // let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }

  }

  apHandleSubmit(): void {
    this.isFormLoading = true;
    // Create & Update AP on the server.
    if (this.apForm.status === 'VALID') {


      let subscriber = null;
      let message = null;
      if (this.clickedRowDetails.type === "sp") {
        let createData = {
          "ap_id": this.apForm.value.ap_id,
          "weightage": this.apForm.value.weightage,
          "role_sp_id": this.clickedRowDetails.id,
          "role_obj_group_id": this.roleObjectiveId,
        }
        subscriber = this.appService.createRoleSpAp(createData);
        message = 'Annual Priorities has been added.';
      } else if (this.clickedRowDetails.type === "ap") {
        let updateData = {
          "ap_id": this.apForm.value.ap_id,
          "weightage": this.apForm.value.weightage,
        }
        subscriber = this.appService.updateRoleSpAp(updateData, this.clickedRowDetails.id);
        message = 'Annual Priorities has been updated.';
      }
      subscriber.subscribe(res => {
        this.isApModal = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        // this.isShowTbl = !this.isShowTbl;
        this.getTreeTableData(this.roleObjectiveId);
        // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);
      }, error => {
        this.isApModal = false;
        this.isFormLoading = false;

        // let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }
  }

  objectiveHandleSubmit(): void {
    this.isFormLoading = true;
    // Create & Update Objective on the server.
    if (this.objectiveForm.status === 'VALID') {
      let subscriber = null;
      let message = null;

      if (this.clickedRowDetails.type === "ap") {
        let createData = {
          "objective_id": this.objectiveForm.value.objective_id,
          "weightage": this.objectiveForm.value.weightage,
          "role_sp_ap_id": this.clickedRowDetails.id,
          "role_obj_group_id": this.roleObjectiveId,
          "uom": this.objectiveForm.value.uom,
          "is_project_based": this.objectiveForm.value.is_project_based ? 'yes' : 'no',
          "parent_objective_role_id": this.objectiveForm.value.parent_objective_role_id,
        }
        subscriber = this.appService.createRoleSpApObjective(createData);
        message = 'Objective has been added.';
      }
      else if (this.clickedRowDetails.type === "obj") {
        let updateData = {
          "objective_id": this.objectiveForm.value.objective_id,
          "weightage": this.objectiveForm.value.weightage,
          "uom": this.objectiveForm.value.uom,
          "is_project_based": this.objectiveForm.value.is_project_based ? 'yes' : 'no',
          "parent_objective_role_id": this.objectiveForm.value.parent_objective_role_id,
        }
        subscriber = this.appService.updateRoleSpApObjective(updateData, this.clickedRowDetails.id);
        message = 'Objective has been updated.';
      }
      subscriber.subscribe(res => {
        this.isObjectiveModal = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        // this.isShowTbl = !this.isShowTbl;
        this.getTreeTableData(this.roleObjectiveId);
      }, error => {
        this.isObjectiveModal = false;
        this.isFormLoading = false;

        // let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }
  }
  // get objective dropdown list
  async getObjectiveMasterGroupsById(id) {
    let rr = new ObjectiveMasterRetrieveRequest;
    rr.ap_id = id;
    await this.appService.getObjectiveMasters(rr).subscribe((res) => {
      this.objctiveMasterList = res['items'];
      // console.log("objctiveMasterList", this.objctiveMasterList);
      // console.log("AP clickedRowDetails", this.clickedRowDetails);

    })
  }

  public remove(rowDetail) {
    this.modal.confirm({
      nzTitle: 'Confirm Delete',
      nzContent: 'Are you sure, you want to delete ' + rowDetail.name+'?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          let subscriber = null;
          if (rowDetail.type === "ap") {
            subscriber = this.appService.deleteRoleSpAp(rowDetail.id);
          } else if (rowDetail.type === "obj") {
            subscriber = this.appService.deleteRoleSpApObjective(rowDetail.id);
          }
          subscriber.subscribe(res => {

            this.message.create('success', 'Record Deleted', {
              nzDuration: 5000
            });
            // this.isShowTbl = !this.isShowTbl;
            this.getTreeTableData(this.roleObjectiveId);
            resolve(res);
          }, error => {

            // let errorMessage = error.error.message;

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });
            reject();
          })

        }).catch(() => console.log('Oops errors!'))
    });
  }
  Save() {
    // this.appService.updateRoleObjectiveGroup(data, this.roleObjectiveId).subscribe((res) => {
    //   console.log("tree date saved:", res);
    // })
    // this.router.navigate(['/role']);
      let subscriber = null;
      let message = null;
      let data = {
        "status": "ready to propogate"
      }
      subscriber = this.appService.updateRoleObjectiveGroup(data, this.roleObjectiveId);
      message = 'Status has been updated.';

      subscriber.subscribe(res => {
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.router.navigate(['/role']);

      }, error => {

        // let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
  }
  // get UOM dropdown list for ap model
  getUOMList() {
    this.appService.get('/api/list-of-values').subscribe((res) => {
      this.uomList = res['items'];
      // console.log("uom: ", this.uomList);
    })
  }

  async getManagerRoleObjective() {
    let rr = new ManagerRoleRetrieveRequest;
    rr.role_obj_group_id = this.roleObjectiveId; // tree id
    if (this.clickedRowDetails.type === "ap")
      rr.ap_id = this.clickedRowDetails?.ap_id;
    else
      rr.ap_id = this.clickedRowDetails?.parent?.ap_id;

    rr.manager_id = this.dropdownObjectiveSet?.parent_role_id;
    await this.appService.getManagerRoles(rr).subscribe((res) => {
      this.managerRoleObjectiveList = res['manager_role_objectives'];
      this.managerRoleObjectiveList;
      // console.log("managerRoleObjectiveList: ", this.managerRoleObjectiveList);
    })
  }

}
