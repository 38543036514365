<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="row">
              <div class="col-md-12">
                <a class="back-btn" nz-button nzType="link" (click)="back()">
                  <i class="ri-arrow-go-back-fill"></i>
                </a>
              </div>
            </div>
            <h3 class="pb-4 text-center">Assessment</h3>
            <a *ngIf="loggedInUserDetail?.employee?.role?.allow_learning_assessment_creation === 'yes'" class="add-btn"
              routerLink="learning-assessment-form" title="Create New"><i class="ri-add-circle-fill"></i></a>
            <nz-table  #sortTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" [nzData]="assessments"
              (nzQueryParams)="onQueryParamsChange($event)">
              <thead>
                <tr>
                  <th>Assessment Title</th>
                  <th [nzSortFn]="sortStartDateFn">Start Date</th>
                  <th [nzSortFn]="sortEndDateFn">End Date</th>
                  <th>Activities</th>
                  <!-- <th>Participants</th> -->
                  <th>Actions</th>
                  <th class="report">Reports</th>
                  <th nzColumnKey="status2" [nzFilters]="listOfFilter"
                  [nzFilterFn]="true">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let assessment of sortTable.data">
                  <td>{{ assessment.title }}</td>
                  <td>{{ assessment.start_date }}</td>
                  <td>{{ assessment.end_date }}</td>
                  <td class="text-center"><a role="button"     class="mr-2" title="Activities" nzTooltipTitle="Activities"  nzTooltipPlacement="top" nz-tooltip (click)="activityAdminView(assessment)"><i
                        class="ri-list-settings-fill grayIcon"></i></a></td>
                  <!-- <td class="text-center"><a><i class="ri-group-fill grayIcon"></i></a></td> -->
                  <td class="text-center">
                    <a  class="mr-2" nzTooltipTitle="Edit"  nzTooltipPlacement="top" nz-tooltip
                      [routerLink]="['/learning-assessment/learning-assessment-form/edit', assessment.id]"><i
                        class="ri-edit-fill grayIcon"></i></a>
                    <a  nzTooltipTitle="Delete"  nzTooltipPlacement="top" nz-tooltip role="button"   class="mr-2" (click)="remove(assessment)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                    <a class="mr-2"   nzTooltipTitle="Extend"  nzTooltipPlacement="top" nz-tooltip [routerLink]="['/learning-assessment/deadline-extend', assessment.id]" *ngIf="assessment.launched=='yes' && assessment.status=='new'">
                      <i class="ri-time-line"></i>
                    </a>
                  <a class="mr-2"  role="button"  nzTooltipTitle="Close"  nzTooltipPlacement="top" nz-tooltip (click)="openPrematureModal(assessment)" *ngIf="assessment.launched=='yes' || assessment.status == 'premature_closure'">
                    <i class="ri-close-circle-fill"></i></a>

                  </td>
                  <td lass="text-center">
                    <a class="mr-2" nzTooltipTitle="Dashboard"  nzTooltipPlacement="top" nz-tooltip [routerLink]="['/learning-assessment/dashboard', assessment.id]"><i class="ri-dashboard-fill"></i></a>
                    <a class="mr-2" nzTooltipTitle="Publish Reports"  nzTooltipPlacement="top" nz-tooltip [routerLink]="['/learning-assessment/activity-report', assessment.id]"><i class="ri-file-copy-2-line"></i></a>
                    <a class="mr-2" role="button" nzTooltipTitle="Download Reports" nzTooltipPlacement="top" nz-tooltip (click)="downloadReport(assessment)" *ngIf="assessment.launched=='yes'"><i class="fa fa-download remove"></i></a>
                  </td>
                  <td class="text-center">
                    <a class="mr-2" (click)="launch_assessment(assessment)" class="mr-2" *ngIf="assessment.launched=='no' || assessment.launched==null; else launched" >Not Launched <i class="ri-play-circle-fill grayIcon"></i></a>
                    <ng-template #launched>
                      <a class="mr-2" *ngIf="assessment.launched=='yes' && assessment.status=='new';else closed">Launched <i class="ri-rocket-2-line grayIcon"></i></a>
                      <ng-template #closed>
                        Closed
                      </ng-template>

                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="openPrematureModalPopup" nzTitle="Premature Closure "
  (nzOnCancel)="prematureHandleModalCancel()" (nzOnOk)="prematureHandleModalOk()" nzOkText="Save" nzOkType="primary"  [nzOkDisabled]="!prematureForm.valid">
  <form nz-form [nzLayout]="'vertical'" [formGroup]="prematureForm">
    <nz-form-item>
      <nz-form-label nzRequired nzFor="p_id" nzNoColon="false">Reasons
      </nz-form-label>
      <nz-form-control nzErrorTip="Please Select a Reason!">
        <nz-input-group nzSize="large">
          <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Reason" formControlName="p_id">
            <nz-option *ngFor="let item of prematureOptionList" [nzLabel]="item.category_name" [nzValue]="item.id"></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>Comment</nz-form-label>
      <nz-form-control nzErrorTip="Please write something here!">
        <textarea formControlName="comment" nz-input rows="4" placeholder="write some comments."
          class="answerTextArea"></textarea>
      </nz-form-control>
    </nz-form-item>

  </form>





</nz-modal>


<!-- Download Reports popup start  -->
<nz-modal [(nzVisible)]="openDownloadReports" nzTitle="Download Reports"
  (nzOnCancel)="downloadModalCancel()" (nzOnOk)="downloadModalOk()" nzOkText="Ok" nzOkType="primary">
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-6">
        <h6 class="mb-2">Reports for employee Score</h6>
        <button class="ml-3" [nzLoading]="downloading" nz-button nzSize="medium" nzType="primary" (click)="downloadScore()"><i class="fa fa-download remove" style="color:white"></i> Download Score</button>
      </div>
      <div class="col-lg-6">
        <h6 class="mb-2">Reports for employee Status</h6>
        <button class="ml-3" [nzLoading]="isDownloadStatus" nz-button nzSize="medium" nzType="primary" (click)="downloadAssStatus()"><i class="fa fa-download remove" style="color:white" *ngIf="!isDownloadStatus"></i> Download Status</button>
      </div>
    </div>
  </div>
</nz-modal>
<!-- Download Reports popup end  -->

<!-- <nz-modal [(nzVisible)]="openDeadlineModalPopup" nzTitle="Deadline Extend" (nzOnCancel)="deadlineExtedModalCancel()"
  (nzOnOk)="deadlineExtedModalOk()" nzOkText="Save" nzOkType="primary" [nzOkDisabled]="!deadlineForm.valid">

  <form nz-form [nzLayout]="'vertical'" [formGroup]="deadlineForm">
    <nz-form-item>
      <nz-form-label nzRequired nzFor="d_id" nzNoColon="false">Reasons
      </nz-form-label>
      <nz-form-control nzErrorTip="Please Select a Reason!">
        <nz-input-group nzSize="large">
          <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Reason" formControlName="d_id">
            <nz-option *ngFor="let item of deadlineOptionList" [nzLabel]="item.category_name" [nzValue]="item.id"></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>Choose End Date and Time <span *ngIf="myZoneAndTime?.zone">
          ({{myZoneAndTime.zone.country.country_code}} - {{myZoneAndTime.zone.timezone_code}})</span></nz-form-label>
      <nz-form-control nzErrorTip="Please input New End Date!">
        <nz-date-picker nzShowTime nzFormat="yyyy-MM-dd HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 10 }"
          [nzSize]="'large'" formControlName="extend_date" >
        </nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>Comment</nz-form-label>
      <nz-form-control nzErrorTip="Please write something here!">
        <textarea formControlName="comment" nz-input rows="4" placeholder="write some comments."
          class="answerTextArea"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
  <div *ngIf="alreadyAdded.length>0">
    <h5>Deadline Extended History</h5>
    <div *ngFor="let data of alreadyAdded;let i=index">
      {{i+1}} :
      <span>Extended Due to : {{data?.category.category_name}} , Comment : {{data?.comment}}</span>
    </div>
  </div>
</nz-modal> -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Generating Report. Please Wait... </p>
</ngx-spinner>
