<div class="wrapper">
    <app-left-menu-panel></app-left-menu-panel>
    <app-top-nav-bar1></app-top-nav-bar1>
    <!-- <app-right-online-list></app-right-online-list> -->

    <router-outlet></router-outlet>
   
   

</div>
 <app-footer></app-footer>
