import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppService } from './../../app.service';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private appService : AppService,
    private router : Router,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(`Request for ${req.urlWithParams} started...`);
    // console.log("out",req)
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log(`Request for ${req.urlWithParams} completed...`);
          // console.log("in",req)
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(
          `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
        );


        if(error.status === 401){
          console.log("error logged : ", error.status);
          this.handleAuthError();
        }
        // debugger;
        return throwError(error);
      })
    );
  }

  // Added By Althaf
  private handleAuthError() {
    sessionStorage.removeItem('me');
    sessionStorage.removeItem('accountAccessToken');
      this.router.navigate(['/login']);
  }
  // Ends
}
