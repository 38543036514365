import { Component, OnInit } from '@angular/core';
import { Employee, EmployeePii, EmployeePiiRetrieveRequest, EmployeeRetrieveRequest } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router } from '@angular/router';
@Component({
  selector: 'app-pii',
  templateUrl: './pii.component.html',
  styleUrls: ['./pii.component.css']
})
export class PiiComponent implements OnInit {
  total = 1;
  loading = false;
  pageSize = 100;
  pageIndex = 1;
  piiRequests:any
  PiiEmployees:EmployeePii[]=[]
  PiiEmployeeIds:Array<number>=[]
  Employees:Employee[]=[]

  constructor( public appService: AppService, private router: Router) { }

  ngOnInit(): void {

  }
  ngAfterViewInit() {

  }



  onQueryParamsChange(params: NzTableQueryParams): void {

    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    let rr=new  EmployeePiiRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "created_on";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    //rr.admin_approved='otp_verified'
    this.appService.getPiiPresentByEmployee(rr).subscribe((res)=>{
      this.loading = false;
      this.PiiEmployees=res['items']
      this.total = res['_meta']["total_items"];
      //console.log("PIi Data",this.PiiEmployees)
      // for(var i=0;i<this.PiiEmployees.length;i++){
      //   this.PiiEmployeeIds.push(this.PiiEmployees[i]['emp_id'])
      // }
      // if(!this.loading){
      //   this.getEmployeeDetails()
      // }

    })


  }
  // getEmployeeDetails(){

  //   let ee=new EmployeeRetrieveRequest()
  //   ee.ids=this.PiiEmployeeIds
  //   this.appService.getEmployees(ee).subscribe((res)=>{
  //     this.Employees=res['items']
  //     console.log("EMP DETAILS",this.Employees)
  //   })
  // }


  deleteUser(emp_id){
    Swal.fire({
      title: 'Do you want make the user data anonymous?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.appService.getEmployeeById(emp_id).subscribe((res)=>{
          // console.log("Employee Details Full",res)
          let user_id=res['user_id']
          let action='delete'
          // console.log("userId",user_id)
          this.appService.set_pii_anonymize(user_id,action).subscribe((res)=>{
            if(res['Action'] === 'Deleted'){
              Swal.fire('Deleted!', '', 'success');

            }else
            {
              Swal.fire('', 'Something Wrong', 'warning');
            }
          })
        })
      }
    })

  }

  deactivateUser(emp_id){
    Swal.fire({
      title: 'Do you want to Deactivate this user?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Deactivate',
    }).then((result) => {
      if (result.isConfirmed) {
        this.appService.getEmployeeById(emp_id).subscribe((res)=>{
          // console.log("Employee Details Full",res)
          let user_id=res['user_id']
          let action='deactivate'
          // console.log("userId",user_id)
          this.appService.set_pii_anonymize(user_id,action).subscribe((res)=>{
            // console.log("set_pii_anonymize",res['Action'])
            if(res['Action']=='Deactivated'){
              Swal.fire('Deactivated!', '', 'success')
            }else
            {
              Swal.fire('', 'Something Wrong', 'warning')
            }
          })
        })
      }
    })

  }
  back() {
    this.router.navigate(['/']);
  }


}
