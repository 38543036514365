
<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div  class="  col-sm-12">  
                           <h3 class="py-4 text-center">Initiatives</h3>  
                           <!-- search dropdown  -->
                            <!-- <div class="col-md-4 offset-md-4 text-center">
                              <nz-form-item> 
                                  <nz-form-control nzErrorTip="Please input Types!">
                                      <nz-input-group class="form-group">
                                          <nz-select
                                              [nzSize]="'large'"
                                              nzPlaceHolder="Please select Month"
                                              [nzOptions]="listOfNoOfOption"    
                                              >
                                          </nz-select>
                                      </nz-input-group>
                                  </nz-form-control>
                              </nz-form-item>
                            </div> -->
                            <div class="col-md-4">
                                <h3> <i nz-icon class="mb-4"  [routerLink]="['/maximizer-dashboard/setup-initiatives']" routerLinkActive="router-link-active"   nzType="plus-circle" nzTheme="fill"></i></h3>
                            </div>
                         <nz-table  nzBordered [nzData]="EmpInitiativeGroupsList" [nzFrontPagination]="true">
                                  <thead>
                                    <tr>
                                      <th>Employee Name</th>
                                      <th>Role</th>
                                      <th>Status</th>
                                      <th>Initiatives For</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let data of EmpInitiativeGroupsList" class="editable-row">
                                      <td>{{ data.employee.display_name }} </td>
                                      <td>{{ data.employee.role.role_name }}</td>
                                      <td>{{ data.status }}</td>
                                      <td>{{ data.initiative_for_month }}</td>
                                      <td> 
                                        <!-- (click)="showModal()"  [routerLink]="['/maximizer-dashboard/setup-initiatives']"-->
                                          <a *ngIf="data.is_self && data.status != 'completed'" [routerLink]="['/maximizer-dashboard/detail-initiatives']" [queryParams]="{ id: data.id, isSelf: data.is_self, status: data.status}"   ><i class="ri-pencil-fill grayIcon"></i></a>  
                                          <a *ngIf="!data.is_self || data.status == 'completed'" [routerLink]="['/maximizer-dashboard/detail-initiatives']" [queryParams]="{ id: data.id, isSelf: data.is_self, status: data.status}" > <i class="ri-eye-fill grayIcon"></i></a>
                                          <!-- <a nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteRow(data.key)"><i nz-icon nzType="delete" nzTheme="fill"></i></a> -->
                                      </td>
                                    </tr>
                                  </tbody>
                                </nz-table> 
                                <nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Objectives" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                  <nz-form-item> 
                                      <nz-form-control nzErrorTip="Please input Types!">
                                          <nz-input-group class="form-group">
                                              <nz-select
                                                  [nzSize]="'large'"
                                                  nzPlaceHolder="Please select"
                                                  [nzOptions]="listOfNoOfOption" >
                                              </nz-select>
                                          </nz-input-group>
                                      </nz-form-control>
                                  </nz-form-item>
                                  <nz-form-item> 
                                      <nz-form-control nzErrorTip="Please input Types!">
                                          <nz-input-group class="form-group">
                                              <nz-select
                                                  [nzSize]="'large'"
                                                  nzPlaceHolder="Please select"
                                                  [nzOptions]="listOfNoOfOption" >
                                              </nz-select>
                                          </nz-input-group>
                                      </nz-form-control>
                                  </nz-form-item>
                                  
                              </nz-modal>
                                <!-- <nz-form-item>
                                  <nz-form-control>
                                      <button nz-button nzType="primary"
                                         (click)="next()">Next</button>
                                  </nz-form-control>
                              </nz-form-item> -->
                      
                      </div>
</div> 
</div> 
</div> 
</div> 
</div> 
</div>