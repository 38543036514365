import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import  differenceInCalendarDays  from 'date-fns/differenceInCalendarDays';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AnnualPriority, APRetrieveRequest, StrategicPerspective, SPRetrieveRequest } from 'src/app/app.models'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-annual-priority',
  templateUrl: './annual-priority.component.html',
  styleUrls: ['./annual-priority.component.css']
})
export class AnnualPriorityComponent implements OnInit {
  dateFormat = 'dd-MM-yyyy';
  total = 1;
  // These are the APs which are displayed in the list screen.
  aps: AnnualPriority[] = [];
  // This is the AP being edited.
  apId: number;
  // loading indictor for the table
  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  annualPriorityForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;

  // state required for the auto complete
  spOptionList: StrategicPerspective[] = [];
  spOptionListSearchChange$ = new BehaviorSubject('');
  isSPOptionListLoading = false;

  constructor(private fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService,
    private datepipe: DatePipe) { }

  onSPOptionListSearch(value: string): void {
    this.isSPOptionListLoading = true;
    this.spOptionListSearchChange$.next(value);
  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(entityId: number): void {
    this.apId = entityId;
    this.getAPById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getAPById(id) {
    this.appService.get('/api/annual-priorities/' + id).subscribe(res => {
      this.annualPriorityForm.patchValue(res);
    })
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;

    // Create the location on the server.
    if (this.annualPriorityForm.status === 'VALID') {
      let start_date = this.datepipe.transform(this.annualPriorityForm.value.start_date, 'yyyy-MM-dd');
      let end_date = this.datepipe.transform(this.annualPriorityForm.value.end_date, 'yyyy-MM-dd');
      // console.log(start_date);
      let data = {
        "title": this.annualPriorityForm.value.title,
        "sp_id": this.annualPriorityForm.value.sp_id,
        "description": this.annualPriorityForm.value.description,
        "start_date": start_date,
        "end_date": end_date,
        //"is_active": this.annualPriorityForm.value.isActive,
        "short_code": this.annualPriorityForm.value.short_code
      }

      let subscriber = null;
      let message = null;
      if (this.action === "create") {
        subscriber = this.appService.createAnnualPriority(data);
        message = 'Annual Priority "' + this.annualPriorityForm.controls['title'].value + '" has been added.';
      }
      else if (this.action === 'edit') {
        subscriber = this.appService.updateAnnualPriority(data, this.apId);
        message = 'Annual Priority "' + this.annualPriorityForm.controls['title'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
  }

  resetCreateModal(): void {
    this.annualPriorityForm = this.fb.group({
      title: [null, [Validators.required]],
      sp_id: [null, [Validators.required]],
      description: [null],
      short_code: [null],
      isActive: [null],
      start_date: [null],
      end_date: [null],
    });
  }

  submitForm(): void {
    for (const i in this.annualPriorityForm.controls) {
      this.annualPriorityForm.controls[i].markAsDirty();
      this.annualPriorityForm.controls[i].updateValueAndValidity();
    }
  }

  //date picker
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;

  disabledStartDate = (current: Date): boolean => {
    // if (!current || !this.assessmentForm.get("end_date").value) {
    //   return false;
    // }
    // return start_date.getTime() > (new Date(this.assessmentForm.get("end_date").value)).getTime();
    return differenceInCalendarDays(current, new Date()) < 0;
  };

  disabledEndDate = (end_date: Date): boolean => {
    if (!end_date || !this.annualPriorityForm.get("start_date").value) {
      return false;
    }
    return end_date.getTime() <= (new Date(this.annualPriorityForm.get("start_date").value)).getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
    // console.log('handleStartOpenChange', open);
  }

  handleEndOpenChange(open: boolean): void {
    // console.log('handleEndOpenChange', open);
  }
  ngOnInit(): void {
    this.resetCreateModal();
    const optionList = (spTitle: string) => {
      this.isSPOptionListLoading = true;
      let rr = new SPRetrieveRequest(spTitle)
      return this.appService.getStrategicPerspectives(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    }

    const optionList$: Observable<StrategicPerspective[]> = this.spOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.spOptionList = data;
      this.isSPOptionListLoading = false;
    });
  }

  public remove(entity: AnnualPriority) {
    const index: number = this.aps.indexOf(entity);

    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.title+'?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteAnnualPriority(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            })

          }).catch(() =>
           console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new APRetrieveRequest('')
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "created_on";
    rr.per_page = pageSize;
    rr.page = pageIndex;

    this.appService.getAnnualPriorities(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.aps = data['items'];
    });
  }

}
