import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-wise-drill-down',
  templateUrl: './activity-wise-drill-down.component.html',
  styleUrls: ['./activity-wise-drill-down.component.css']
})
export class ActivityWiseDrillDownComponent implements OnInit {
  // EnterpriseOutcome = () => `25% vs 20%`;
  EnterpriseOutcome = '';
  public isShow:boolean = true;
  panels = [
    {
      active: true,
      disabled: false,
      name: 'This is panel header 1',
      customStyle: {
        background: '#f7f7f7',
        'border-radius': '4px',
        'margin-bottom': '24px',
        border: '0px'
      }
    },
 
    {
      active: false,
      disabled: false,
      name: 'This is panel header 3',
      customStyle: {
        background: '#f7f7f7',
        'border-radius': '4px',
        'margin-bottom': '24px',
        border: '0px'
      }
    }
  ];  
  constructor() { }

  ngOnInit(): void {
  }
  
  toggle() {
    this.isShow = !this.isShow;
  }
}

