import { ActivatedRoute,  Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

export interface TreeNodeInterface {
  id: number,
  sp_id?: number,
  ap_id?: number,
  objective_id?: number,
  weightage?: number,
  uom?: string;
  target?: string;
  type: string;
  key: string;
  name: string;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
}
@Component({
  selector: 'app-setup-objectives',
  templateUrl: './setup-objectives.component.html',
  styleUrls: ['./setup-objectives.component.css']
})
export class SetupObjectivesComponent implements OnInit {
  empObjectiveGroupId: number;
  EmpObjectiveGroupsList = [];
  EmpObjectiveGroupsSpList = [];
  isObjectiveModal = false;
  objectiveForm!: FormGroup;
  empObjectiveListForm!: FormGroup;
  empObjectiveForm!: FormGroup;
  treeData: TreeNodeInterface[];
  empObjectiveId;
  clickedRowDetails;
  isFormLoading = false;
  isShowAction = false;
  // isShowApproveBtn= false;
  isSelf: boolean;
  status: string;
  isShowAcceptDefineTargetBtn: boolean;
  isShowEditActions: boolean;
  isShowApproveBtn: boolean;
  isShowBackBtn: boolean;
  //tree table related
  loggedInUserDetail: any;
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};


  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: true, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }
  constructor(public fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    // get drop down list
    // this.getEmpObjectiveList(3); remove
    // this.getEmpObjectiveGroupsByEmpId(3);
    this.loggedInUserDetail = this.appService.getMe();
    this.activatedRoute.params.subscribe(params => {
      this.empObjectiveGroupId = parseInt(params['id']);
      // console.log(this.empObjectiveGroupId);
      this.getTreeTableData(this.empObjectiveGroupId);
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['self'] && params['status']) {
        this.isSelf = (params['self'] === 'true');
        this.status = params['status'];
        // console.log(this.isSelf);
      }
    });
    this.objectiveForm = this.fb.group({
      objective_id: [null, [Validators.required]], //objective_id
      weightage: [null, [Validators.required]],
    });
    this.empObjectiveListForm = this.fb.group({
      emp_obj_id: [null]
    });
    this.empObjectiveForm = this.fb.group({
      weightage: [null, [Validators.required]],
      target: [null, [Validators.required]],
      uom: { value: null, disabled: true }
    });
    // this.onValueChanges();
    // this.checkStatus();
    // this.viewActionBtn();
    this.editActionBtn();
  }

  // modal action fuctions
  showModal(data): void {
    this.clickedRowDetails = data;
    // console.log(this.clickedRowDetails);
    this.empObjectiveForm.reset();
    this.isObjectiveModal = true;
    this.empObjectiveForm.patchValue(data);
  }
  objectiveHandleCancel(): void {
    this.isObjectiveModal = false;
  }
  // start
  objectiveHandleSubmit(): void {
    this.isFormLoading = true;
    // Create & Update Objective on the server.
    if (this.empObjectiveForm.status === 'VALID') {
      let subscriber = null;
      let message = null;
      let updateData = {
        "weightage": this.empObjectiveForm.value.weightage,
        "target": this.empObjectiveForm.value.target,
        // "uom": this.empObjectiveForm.value.uom,
      }

      subscriber = this.appService.updateEmployeeObjective(updateData, this.clickedRowDetails.id);
      message = 'Objective has been updated.';

      subscriber.subscribe(res => {
        this.isObjectiveModal = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        // this.isShowTbl = !this.isShowTbl;
        this.getTreeTableData(this.empObjectiveGroupId);
      }, error => {
        this.isObjectiveModal = false;
        this.isFormLoading = false;

        // let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  async getEmpObjectiveGroupsByEmpId(id) {
    // let rr = new EmployeeObjectiveRetrieveRequest;
    // rr.emp_id = [id];
    // rr.sort_order = 'asc';
    await this.appService.getEmployeeObjectiveGroupsById(id).subscribe((res) => {
      this.EmpObjectiveGroupsList = res['items'];
      // console.log("list:", this.EmpObjectiveGroupsList);
      this.getTreeTableData(this.empObjectiveGroupId);

    })
  }
  // search dropdown
  onValueChanges(): void {
    this.empObjectiveListForm.get('emp_obj_id').valueChanges.subscribe(val => {
      this.empObjectiveId = val;
      this.getTreeTableData(val);

      // console.log(this.empObjectiveId);
    });
  }

  getTreeTableData(id) {
    this.treeData = [];
    this.appService.getEmployeeObjectiveGroupsById(id).subscribe((res: any) => {
      // console.log("EmpObjectiveGroupsList: ", this.EmpObjectiveGroupsList = res);
      res.status === "accepted" ? this.isShowAction = true : this.isShowAction = false;
      this.EmpObjectiveGroupsSpList = res.emp_obj_group_sps;
      // console.log("EmpObjectiveGroupsSpList:", this.EmpObjectiveGroupsSpList);

      this.EmpObjectiveGroupsSpList.forEach((s: any, i) => {
        let level_1 = (i + 1).toString();
        this.treeData.push({
          type: "sp",
          key: level_1,
          id: s.id,
          sp_id: s.sp_id,
          name: s.name,
          weightage: s.weightage,
        });
        if (s.role_sp_aps) {
          if (s.role_sp_aps.length > 0) {
            this.treeData[i].children = [];
            s.role_sp_aps.forEach((a: any, j) => {
              let level_2 = (level_1 + "-" + (j + 1));
              this.treeData[i]['children'].push({
                type: "ap",
                key: level_2,
                id: a.id,
                ap_id: a.ap_id,
                name: a.name,
                weightage: a.weightage,
              });
              if (a.emp_objs) {
                if (a.emp_objs.length > 0) {
                  this.treeData[i]['children'][j].children = [];
                  a.emp_objs.forEach((o: any, k) => {
                    let level_3 = (level_2 + "-" + (k + 1));
                    this.treeData[i]['children'][j]['children'].push({
                      type: "obj",
                      key: level_3,
                      id: o.id,
                      // objective_id: o.objective_id,
                      name: o.name,
                      weightage: o.weightage,
                      uom: o.uom,
                      target: o.target,
                    });
                  });
                }
              }
            });
          }
        }
      });
      this.treeData.forEach(item => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });
      // console.log("treeData", this.treeData);
      // this.isShowTbl = !this.isShowTbl;
    });
  }
  acceptDefineTarget() {
    let data;
    if (this.isSelf) {
      data = { status: "accepted" } // self: Approved
    } else {
      data = { status: "approved" }   // non-self: Approved
    }
    // let data = {"status": "accepted" }   // non-self: Approved
    let subscriber = null;
    let message = null;

    subscriber = this.appService.updateEmployeeObjectiveGroups(data, this.empObjectiveGroupId);
    message = 'Employee Status  "' + data.status + '" has been updated.';

    subscriber.subscribe(res => {
      this.isFormLoading = false;

      this.message.create('success', message, {
        nzDuration: 5000
      });
       if (!this.isSelf) {
       this.router.navigate(['/maximizer-dashboard/objectives']);
      }else{
        this.isShowAcceptDefineTargetBtn = false;
      this.isShowEditActions = true;
      }
    }, error => {
      this.isFormLoading = false;

      // let errorMessage = error.error.message;


      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });

  }
  save() {
    let data
    if (this.loggedInUserDetail.employee.reporting_manager != null)
      data = { "status": "sent for approval" }
    else
      data = { "status": "approved" }

    // non-self: "hidden"
    let subscriber = null;
    let message = null;

    subscriber = this.appService.updateEmployeeObjectiveGroups(data, this.empObjectiveGroupId);
    message = 'Employee Status  "' + data.status + '" has been updated.';

    subscriber.subscribe(res => {
      this.isFormLoading = false;

      this.message.create('success', message, {
        nzDuration: 5000
      });
      this.router.navigate(['/maximizer-dashboard/objectives']);
    }, error => {
      this.isFormLoading = false;

      // let errorMessage = error.error.message;


      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });

  }

  //  visibility checks related
  // <!-- status draft >isSelf true> accept and define target = accepfted > edit screen> -->
  // <!-- false draff> isself true > show edit direct -->
  // <!-- isself false > status sent for appoval > approved  with no edit action  -->
  // <!-- issel false > status No set for app> view button with back btn> -->

  editActionBtn() {
    if (this.isSelf && this.status == 'draft') {
      this.isShowAcceptDefineTargetBtn = true;
    } else if (this.isSelf && this.status != 'draft') {
      // (data.isSelf && this.status != 'draft')
      this.isShowEditActions = true;
    } else if (!this.isSelf && this.status == 'sent for approval') {
      this.isShowApproveBtn = true;
    } else {
      // (!this.isSelf && this.status != 'sent for approval')
      this.isShowBackBtn = true;
    }
  }
}







