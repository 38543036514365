<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tab design start -->
            <div class="col-sm-12 col-lg-12">
              <div class="iq-card">
                <div class="iq-card-body">
                  <h3 class="py-4 text-center">List of Tenants</h3>
                  
                  <a class="add-btn" routerLink="tenant-detail" title="Create New"><i class="ri-add-circle-fill"></i></a> 
                  
                  
            <nz-modal
            [(nzVisible)]="isFormVisible"
            nzTitle="View Tenant"
            (nzOnCancel)="handleModalCancel()"
            (nzOnOk)="handleModalSubmit()"
            nzOkType="primary"
            (ngSubmit)="submitForm()"]>
          <div class="activity-card">
            <h4 class="my-3 text-center">{{tenant_name}}</h4>
            <h6 class="my-3 text-center">{{created_on}}</h6>
           <nz-divider nzType="horizontal"></nz-divider>
           <div class="row">
            <div class="col-md-6">
              <div class="mx-4">
                <h5 class="my-3">Tenant Details</h5>
            <div nz-col nzSpan="8" class="activity-no text-right">{{tenant_name}}</div>
            </div>
            </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mx-4">
                  <h5 class="my-3">Module Details</h5>
              <div nz-col nzSpan="8" class="activity-no text-right">{{tenant_name}}</div>
              </div>
              </div>
              </div>
          </div>
      
          </nz-modal>
                          
                  <nz-table
                  nzBordered
                  nzShowSizeChanger
                  [nzFrontPagination]="false"
                  [nzLoading]="false"
                  [nzTotal]="total"
                  [nzPageSize]="pageSize"
                  [nzPageIndex]="pageIndex"
                  (nzQueryParams)="onQueryParamsChange($event)"
                  [nzData]="tenants"
            
                >
                <thead>    
                  <tr>               
                    <th>Tenant Name</th>
                    <th>Created On</th>
                    <th>Action</th>
                  </tr>
                </thead>
              <tbody>
                <ng-container *ngFor="let tenant of tenants">
                  <tr *ngIf="tenant.tenant_name != 'exlygenze_master'">
                  
                  <td>{{ tenant.tenant_name }}</td>
                  <td>{{ tenant.created_on }}</td>
                  <td>
                    <a (click)="showCreateModal(tenant)"><i class="ri-menu-unfold-fill grayIcon" ></i></a>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a class="mr-2" [routerLink]="'tenant-detail/'+tenant.id"><i class="ri-pencil-fill grayIcon"></i></a>
                    <nz-divider nzType="vertical"></nz-divider>
                    
                    <a  (click)="remove(tenant)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>

                  </td>
                </tr>
                </ng-container>
              </tbody>
                </nz-table>
            

                </div>
              </div>
            </div>
        </div>        
    </div>
</div>