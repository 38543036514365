import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RoleRetrieveRequest } from 'src/app/app.models';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

interface Configurable {
  width: number;
  default: string;
  fill_by: string;
  fill_when: string;
  placeholder: string;
  option_source: string;
  options_table: string;
  options_table_key: string;
  options_api: string;
  options_api_key: string;
  options: string;
  required: boolean;
}

@Component({
  selector: 'app-form-add',
  templateUrl: './form-add.component.html',
  styleUrls: ['./form-add.component.css']
})
export class FormAddComponent implements OnInit, OnDestroy {
  private sub: any;

  form: any = {
    id: 0,
    name: 'Untitled',
    who_review: 'manager',
    frequency: 1,
    schemas: [],
    status: 'active',
  };

  loading = false;
  isVisible = false;
  modalIndex: number;
  modalTitle: string;
  configurable: Configurable;
  disableEdit = false;
  isPreviewVisible = false;
  previewModelTitle: string;

  role_options: any[] = [
    {
      value: 'employee',
      label: 'Employee'
    },
    {
      value: 'manager',
      label: 'Manager'
    },
    {
      value: 'tenant_admin',
      label: 'Admin'
    }
  ];
  dynamicTables: any = [
    {
      label: 'Departments',
      value: 'department'
    },
    {
      label: 'Competency',
      value: 'competency'
    },
    {
      label: 'Employees',
      value: 'employee'
    },
  ];
  dynamicKeys: any = [];

  constructor(
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loading = true;
    this.sub = this.route.params.subscribe(params => {
      if(params['id'] > 0){
        this.appService.getFormById(params['id']).subscribe((form: any) => {
          this.form.id = form.id;
          this.form.name = form.name;
          this.form.who_review = form.who_review;
          this.form.frequency = form.frequency;
          this.form.schemas = form.schemas;
          this.form.status = form.status;

          this.appService.getFormLaunchesByFormId(form.id).subscribe((launches: any) => {
            for(const launch of launches){
              if(launch.status == 'launched'){
                this.disableEdit = true;
                break;
              }
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message,
              allowOutsideClick: false,
            });
            this.spinner.hide();
            this.loading = false;
          });

          this.spinner.hide();
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }else{
        this.addColumn();
        this.spinner.hide();
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.form.schemas, event.previousIndex, event.currentIndex);
  }

  showModal(index): void {
    this.modalIndex = index;
    this.modalTitle = this.form.schemas[index].label;
    this.configurable = this.form.schemas[index].configurable;
    if(this.configurable.option_source === 'table'){
      this.getFields(this.configurable.options_table);
    }
    this.isVisible = true;
  }

  handleOk(): void {
    this.form.schemas[this.modalIndex].configurable = this.configurable;
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showPreview(): void {
    this.previewModelTitle = this.form.name;
    this.isPreviewVisible = true;
  }

  previewHandleOk(): void {
    this.isPreviewVisible = false;
  }

  previewHandleCancel(): void {
    this.isPreviewVisible = false;
  }

  uid() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  addColumn(): void {
    this.form.schemas.push({
      id: 'col_'+this.uid(),
      type: '',
      label: '',
      configurable: {
        width: '',
        default: '',
        fill_by: 'employee',
        fill_when: 'immediate',
        placeholder: '',
        option_source: '',
        options_table: '',
        options_table_key: '',
        options_api: '',
        options_api_key: '',
        options: '',
        required: false,
      },
      explanation: '',
    });
  }

  sortField(index, direction): void {
    let newIndex = index + direction;
    while(index < 0){
      index += this.form.schemas.length;
    }
    while(newIndex < 0){
      newIndex += this.form.schemas.length;
    }
    if(newIndex >= this.form.schemas.length){
      newIndex = 0;
    }
    this.form.schemas.splice(newIndex, 0, this.form.schemas.splice(index, 1)[0]);
  }

  removeSchema(index): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Please confirm before deleting this field',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if(result.value){
        this.form.schemas.splice(index, 1);
      }
    });
  }

  getFields(table): void {
    this.appService.getFields(table).subscribe(res => {
      this.dynamicKeys = res;
    });
  }

  saveSchema(): void {
    if(!this.form.name){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please enter the form name, it\'s a required field',
      });
      return;
    }
    // if(!this.form.who_review){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Required!',
    //     confirmButtonText: 'Close',
    //     text: 'Please choose who review the form, it\'s a required field',
    //   });
    //   return;
    // }

    if(!this.form.frequency){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please enter submission frequency, it\'s a required field',
      });
      return;
    }

    if(!this.form.schemas.length){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please add some fields to your form',
      });
      return;
    }

    let dupLabels = [];
    for(const schema of this.form.schemas){
      if(!schema.label){
        Swal.fire({
          icon: 'error',
          title: 'Required!',
          confirmButtonText: 'Close',
          text: 'Please enter the field label, it\'s a required field',
        });
        return;
      }
      if(!schema.type){
        Swal.fire({
          icon: 'error',
          title: 'Required!',
          confirmButtonText: 'Close',
          text: 'Please enter the field type, it\'s a required field',
        });
        return;
      }
      // if(dupLabels.indexOf(schema.label.toLowerCase()) !== -1){
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Duplicate!',
      //     confirmButtonText: 'Close',
      //     text: 'Duplicate column label found, please change it before saving',
      //   });
      //   return;
      // }
      // dupLabels.push(schema.label.toLowerCase());
      if(schema.type == 'select'){
        if(!schema.configurable.option_source){
          Swal.fire({
            icon: 'error',
            title: 'Required!',
            confirmButtonText: 'Close',
            text: 'Please choose option source, it\'s a required field',
          });
          return;
        }
        if(schema.configurable.option_source == 'static' && !schema.configurable.options){
          Swal.fire({
            icon: 'error',
            title: 'Required!',
            confirmButtonText: 'Close',
            text: 'Please choose options, it\'s a required field',
          });
          return;
        }
        if(schema.configurable.option_source == 'table' && (!schema.configurable.options_table || !schema.configurable.options_table_key)){
          Swal.fire({
            icon: 'error',
            title: 'Required!',
            confirmButtonText: 'Close',
            text: 'Please choose table & table column, it\'s a required field',
          });
          return;
        }
        if(schema.configurable.option_source == 'api' && (!schema.configurable.options_api || !schema.configurable.options_api_key)){
          Swal.fire({
            icon: 'error',
            title: 'Required!',
            confirmButtonText: 'Close',
            text: 'Please enter api url & api key, it\'s a required field',
          });
          return;
        }
      }
    }

    this.spinner.show();
    if(!this.form.id){
      this.appService.createForm(this.form).subscribe();
    }else{
      this.appService.updateForm(this.form, this.form.id).subscribe();
    }
    this.spinner.hide();
    this.router.navigate(['forms']);
  }

}
