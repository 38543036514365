import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { from } from 'rxjs';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';


interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
  disabled: boolean;
}

@Component({
  selector: 'app-employee-popup',
  templateUrl: './employee-popup.component.html',
  styleUrls: ['./employee-popup.component.css']
})
export class EmployeePopupComponent implements OnInit {
  @Input() employees: any;
  @Input() getAllemployees: any;
  @Input() selectedEmployee: any;
  @Output() setEmployeesForLA = new EventEmitter<any>();
  @Input() IsSelectOne = false;
  @Input() isNotSelect = false;
  @Input() isShowBs = false;
  @Input() isManagerMaxCount;
  @Input() isPeerMaxCount;
  @Input() isSuboMaxCount;
  @Input() isShowSelf = false;
  isAllChecked = true;
  listOfCurrentPageData: ReadonlyArray<ItemData> = [];
  setOfCheckedId = new Set<number>();
  isEmployeeTableLoading: boolean = false;
  loggedInUserDetail: any;
  searchValue = '';
  visible = false;
  constructor(private appService: AppService) { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.loggedInUserDetail = this.appService.getMe();
    // console.log('getAllemployees ', this.getAllemployees);
    // console.log('employees ', this.employees);
    // console.log('Logged in employee: ',this.loggedInUserDetail);

    if (this.isShowSelf == false) {
      this.employees = this.employees?.filter((f) => f.id !== this.loggedInUserDetail.employee.id);
    }
    this.employees?.forEach(element => {
      element.disabled = false;
    });
    if (this.isShowSelf == false) {
      this.getAllemployees = this.getAllemployees?.filter((f) => f.id !== this.loggedInUserDetail.employee.id);
    }

    this.isEmployeeTableLoading = true;
    if (this.employees?.length) {
      this.isEmployeeTableLoading = false;
      // console.log("employeeeeeeeee",this.employees)
    } else {
      this.isEmployeeTableLoading = true;
      setTimeout(() => {
        this.isEmployeeTableLoading = false;
      }, 3000)
    }
    // console.log('selectedEmployeeinPopUp ', this.selectedEmployee);

    if (this.selectedEmployee?.length) {
      
      if (this.isNotSelect) {
        this.setOfCheckedId = new Set<number>();
      }

      this.selectedEmployee.forEach(element => {
        this.setOfCheckedId.add(element);
      })
      // console.log('iDs',this.setOfCheckedId)
      this.emitUpdatedSelectedEmployee();
    } else {

      // this.isAllChecked = true;
      this.employees?.forEach(element => {
        if (element.id === this.loggedInUserDetail.employee.id) {
          return;
        }
        if (!this.IsSelectOne && !this.isNotSelect) {
          this.setOfCheckedId.add(element.id);
        }
      });
      this.emitUpdatedSelectedEmployee();
    }
  }

  reset(): void {
    this.searchValue = '';
    this.employees = this.getAllemployees;
    this.visible = false;
  }

  search(): void {
    this.visible = false;
    this.employees = this.getAllemployees.filter((item) => item.display_name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
  }

  //
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  emitUpdatedSelectedEmployee() {
    this.setEmployeesForLA.emit(this.setOfCheckedId);
  }

  onItemChecked(id: number, checked: boolean): void {
    // console.log()
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
    this.emitUpdatedSelectedEmployee();
  }

  onAllChecked(value: boolean): void {
    if (this.IsSelectOne) {
      Swal.fire({
        icon: 'warning',
        text: "Please select one employee"
      });
      this.isAllChecked = false;
      // console.log('isAllChecked',this.isAllChecked)
      return;
    }
    this.employees.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
    this.emitUpdatedSelectedEmployee();
  }

  onCurrentPageDataChange($event: ReadonlyArray<ItemData>): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.isAllChecked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id));
    let checkedEmp = Array.from(this.setOfCheckedId)
    if (this.IsSelectOne) {
      if (checkedEmp?.length != 0) {
        this.employees?.forEach((element) => {
          if (element.id == checkedEmp[0]) {
            element.disabled = false;
          } else {
            element.disabled = true;
          }
        });
      } else {
        this.employees?.forEach(element => {
          element.disabled = false;
        });
      }
    }

    // if(this.IsSelectOne){
    // let checkedEmp = Array.from(this.setOfCheckedId)
    // if(checkedEmp?.length != 0){
    //   if(this.isManagerMaxCount != undefined || this.isManagerMaxCount != null){
    //     if(checkedEmp?.length == this.isManagerMaxCount){
    // this.employees?.forEach((element) => {
    //   if(checkedEmp.includes(element.id)){
    //     element.disabled = false;
    //   }else{
    //     element.disabled = true;
    //   }
    // });
    //     }else{
    //       this.employees?.forEach(element => {
    //         element.disabled = false;
    //       });
    //     }
    //   }

    //   if(this.isPeerMaxCount != undefined || this.isPeerMaxCount != null){
    //     if(checkedEmp?.length == this.isPeerMaxCount){
    //       this.employees?.forEach((element) => {
    //         if(checkedEmp.includes(element.id)){
    //           element.disabled = false;
    //         }else{
    //           element.disabled = true;
    //         }
    //       });
    //     }else{
    //       this.employees?.forEach(element => {
    //         element.disabled = false;
    //       });
    //     }
    //   }

    //   if(this.isSuboMaxCount != undefined || this.isSuboMaxCount != null){
    //     if(checkedEmp?.length == this.isSuboMaxCount){
    //       this.employees?.forEach((element) => {
    //         if(checkedEmp.includes(element.id)){
    //           element.disabled = false;
    //         }else{
    //           element.disabled = true;
    //         }
    //       });
    //     }else{
    //       this.employees?.forEach(element => {
    //         element.disabled = false;
    //       });
    //     }
    //   }
  }
}
