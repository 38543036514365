<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="row">
              <div class="col-sm-12">
                <a class="back-btn" nz-button nzType="link" (click)="back()">
                  <i class="ri-arrow-go-back-fill"></i>
                </a>
              </div>
            </div>
            <div class="stepwizard mb-0">
              <div class="stepwizard-row setup-panel text-center">
                <div class="wizard-step tab-second" [ngClass]="{'active' : contentIndex == 0}">
                  <a class="btn btn-default" (click)="tabChange(0)">
                    <i class="ri-draft-line text-warning"></i><span>Dashboard</span>
                  </a>
                </div>
              </div>
            </div>
            
          </div>
          </div>
          <div class="row">
            <div class="col-lg-7">
              <div class="iq-card open-card">
                <div class="iq-card-body">
                  <div class="mt-2">
                    <h4 class="text-center">Open tickets</h4>
                    <div id="chartdiv"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="iq-card open-card">
                <div class="iq-card-body">
                <div class="col-lg-12 mt-2">
                  <h4 class="text-center mb-5">Tickets created</h4>
                  <div class="row">
                      <div class="col-lg-8 text-center" nz-form [nzLayout]="'vertical'" [formGroup]="filterForm">
                      <nz-form-item>
                        <nz-form-label nzRequired nzFor="Date (From – To)">Date (From – To)</nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Date (From – To)!">
                          <nz-range-picker nzSize="medium" nzFormat="dd-MM-yyyy" formControlName="from_to_date"
                            [nzDisabledDate]="disabledDate"></nz-range-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-1">
                      <button nz-button [disabled]="!filterForm.valid" nzSize="medium" nzType="primary" class="ml-3 m-top"
                        (click)="searchTickets()">
                        Search
                      </button>
                    </div>
                    <div class="col-lg-3">
                      <a class="groupby" *ngIf="loggedInUserDetail?.user_type =='espl_support'" (click)="ModalOpen()"><span>Filter<i
                            class="ri-arrow-up-down-fill"></i></span></a>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-3" *ngIf="barChartView && barChart?.length !=0">
                    <div id="chartdiv1"></div>
                  </div>
                  <div class="col-lg-12 text-center mt-3" *ngIf="barChart?.length ==0">
                    <h4>No data available</h4>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
      </div>
      <nz-modal [(nzVisible)]="filterModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Apply Filter"
        (nzOnCancel)="handleFilterCancel()" (nzOnOk)="filterApply()" nzOkText="Apply" nzCancelText="Reset">
        <form nz-form [nzLayout]="'vertical'" [formGroup]="popupForm">
          <div class="row">
            <div class="col-lg-12">
              <nz-form-item>
                <nz-form-label nzFor="Status">Status</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Status!">
                  <nz-select nzAllowClear nzPlaceHolder="Select Status" formControlName="status_id">
                    <ng-container *ngFor="let item of refrence_data">
                      <nz-option *ngIf="item.key == 'TicketStatus' " [nzLabel]="item.value" [nzValue]="item.id">
                      </nz-option>
                    </ng-container>
  
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-12">
              <nz-form-item>
                <nz-form-label nzFor="Severity">Severity</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Severity!">
                  <nz-select nzAllowClear nzPlaceHolder="Select Severity" formControlName="severity_ids">
                    <ng-container  *ngFor="let severity of refrence_data">
                      <nz-option *ngIf="severity.key == 'TicketSeverity'" [nzLabel]="severity.value" [nzValue]="severity.id">
                      </nz-option>
                      </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-12">
              <nz-form-item>
                <nz-form-label nzFor="Module">Module</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Module!">
                    <nz-select  nzAllowClear nzPlaceHolder="Select Module"
                        formControlName="module_ids">
                        <ng-container  *ngFor="let module of refrence_data">
                        <nz-option *ngIf="module.key == 'Module'" [nzLabel]="module.value" [nzValue]="module.id">
                        </nz-option>
                        </ng-container>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            </div>
            <div class="col-lg-12">
              <nz-form-item>
                <nz-form-label nzFor="Priority">Priority</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Priority!">
                  <nz-select nzAllowClear nzPlaceHolder="Select Priority" formControlName="priority_ids">
                    <ng-container  *ngFor="let priority of refrence_data">
                      <nz-option *ngIf="priority.key == 'TicketPriority'" [nzLabel]="priority.value" [nzValue]="priority.id">
                      </nz-option>
                      </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-12">
              <nz-form-item>
                <nz-form-label nzFor="Resolution Type">Resolution Type</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Resolution Type!">
                  <nz-select nzAllowClear nzPlaceHolder="Select Resolution Type" formControlName="resolution_code_id">
                    <ng-container  *ngFor="let reso of refrence_data">
                      <nz-option *ngIf="reso.key == 'ResCode'" [nzLabel]="reso.value" [nzValue]="reso.id">
                      </nz-option>
                      </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-12">
              <nz-form-item>
                <nz-form-label nzFor="Root Cause">Root Cause</nz-form-label>
                <nz-form-control nzErrorTip="Please Enter Root Cause!">
                  <nz-select nzAllowClear nzPlaceHolder="Select Root Cause" formControlName="root_cause_code_id">
                    <ng-container  *ngFor="let root of refrence_data">
                      <nz-option *ngIf="root.key == 'RootCause'"  [nzLabel]="root.value" [nzValue]="root.id">
                      </nz-option>
                      </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </nz-modal>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Please Wait... </p>
</ngx-spinner>