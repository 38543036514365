import { environment } from 'src/environments/environment';
import { Injectable, NgZone } from '@angular/core';
// import { auth } from 'firebase/app';
// import { AngularFireAuth } from "@angular/fire/auth";
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";

export interface User {
    uid: string;
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
 }

@Injectable({
  providedIn: 'root'
})

export class NgAuthService {
    userState: any;
    apiEndPoint = environment.apiEndPoint;
    constructor(
      public http: HttpClient,
      // public afs: AngularFirestore,
      // public afAuth: AngularFireAuth,
      public router: Router,
      public ngZone: NgZone
    ) {
      // this.afAuth.authState.subscribe(user => {
      //   if (user) {
      //     this.userState = user;
      //     sessionStorage.setItem('user', JSON.stringify(this.userState));
      //     JSON.parse(sessionStorage.getItem('user'));
      //   } else {
      //     sessionStorage.setItem('user', null);
      //     JSON.parse(sessionStorage.getItem('user'));
      //   }
      // })
    }



    get isLoggedIn(): boolean {
      const user = JSON.parse(sessionStorage.getItem('user'));
      return (user !== null && user.emailVerified !== false) ? true : false;
    }

    // GoogleAuth() {
    //   return this.AuthLogin(new auth.GoogleAuthProvider());
    // }
    // MsAuth(){
    //   return this.AuthLogin(new auth.OAuthProvider('microsoft.com'));
    // }

    // AuthLogin(provider) {
    //   return this.afAuth.signInWithPopup(provider)
    //   .then((result) => {
    //      this.ngZone.run(() => {
    //         this.router.navigate(['dashboard']);
    //         return this.http.post(this.apiEndPoint + '/api/verify/', JSON.stringify(result.user));
    //       })
    //     this.SetUserData(result.user);
    //     console.log(result)
    //   }).catch((error) => {
    //     window.alert(error)
    //   })
    // }


    // SetUserData(user) {
    //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    //   const userState: User = {
    //     uid: user.uid,
    //     email: user.email,
    //     displayName: user.displayName,
    //     photoURL: user.photoURL,
    //     emailVerified: user.emailVerified
    //   }
    //   return userRef.set(userState, {
    //     merge: true
    //   })
    // }

    // SignOut() {
    //   return this.afAuth.signOut().then(() => {
    //     sessionStorage.removeItem('user');
    //     this.router.navigate(['sign-in']);
    //   })
    // }
}
