import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, transition, animate, style } from '@angular/animations'

@Component({
  selector: 'app-admin-dash',
  templateUrl: './admin-dash.component.html',
  styleUrls: ['./admin-dash.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(0%)'}),
        animate('700ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('700ms ease-out', style({transform: 'translateY(0%)'}))
      ])
    ])
  ]
})

export class AdminDashComponent implements OnInit {

  // fy: String;
  // month: String;
  // employeeName: String;
  // form: FormGroup;
  // years: any = ['FY 20-21', 'FY 20-22'];
  // months: any = ['January', 'February', 'March', 'April', 'May', 'June'];
  // chkBoxStatus: Boolean;

  loggedInUserDetail: any;
  employeeInitiatives: any = null;
  employeeObjectives: any = null;
  isEmployeeDetailsLoading: boolean = false;

  togglePanel: any = {};
  togglePanel2:any={};
  constructor(
    // private fb: FormBuilder,
    public appService: AppService
  ) { }

  async ngOnInit(){
    this.isEmployeeDetailsLoading = true;
    this.loggedInUserDetail = this.appService.getMe();
    // console.log('loggedUserDetail', this.loggedInUserDetail);
    let res = await this.appService.getEmployeeObjectivesForDashboard(this.loggedInUserDetail.employee.id).toPromise();
    if (res['data'].initiatives.length > 0) {
      this.employeeInitiatives = res['data'].initiatives;
    }
    if (res['data'].objectives.length > 0) {
      this.employeeObjectives = res['data'].objectives;
    }
    this.isEmployeeDetailsLoading = false; 
    // console.log('employeeObjectives', this.employeeObjectives);
    // console.log('employeeInitiatives', this.employeeInitiatives);


    // this.form = this.fb.group({
    //   fy: [null, Validators.required],
    //   month: [null, Validators.required],
    //   employeeName: [null, Validators.required]
    // });
  }

  convInt(d){
    // console.log(d)
    return Number.parseInt(d);
  }

  // submitForm() {
  //   for (const i in this.form.controls) {
  //     if (this.form.controls.hasOwnProperty(i)) {
  //       this.form.controls[i].markAsDirty();
  //       this.form.controls[i].updateValueAndValidity();
  //     }
  //   }
  //   this.fy = this.form.get('fy').value;
  //   this.month = this.form.get('month').value;
  //   this.employeeName = this.form.get('fy').value;
  // }

  // showFirstDown(i){
  //   this.showFirstDrillDown= String(!this.showFirstDrillDown)
  // }
  // showSecondDown(){
  //   this.showSecondDrillDown=!this.showSecondDrillDown
  // }

}

