import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from '../../app.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ajna-redirection',
  templateUrl: './ajna-redirection.component.html',
  styleUrls: ['./ajna-redirection.component.css']
})
export class AjnaRedirectionComponent implements OnInit {
  selectedType: string;

  constructor(
    private spinner: NgxSpinnerService, 
    private appService: AppService, 
    public router: Router, 
    private location: Location,
    // private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // console.log('AJNA REDIRECTION');
    this.selectedType = this.location.path().replace('/', '');
    this.spinner.show();
    this.appService.get<any>('/api/rdrct_to_ajna').subscribe(res => {
      // console.log('response', res);
      if (res.status == 'success') {
        // console.log('success');
        if (res.data.length > 0) {
          if(this.selectedType == 'ajna-redirection-dashboard'){
            window.open('http://3.131.158.143/#/pages/login/?token=' + res.data, '_blank');
          }
          if(this.selectedType == 'ajna-redirection-xx'){
            window.open('http://3.131.158.143/#/configurations/videoStream/?token=' + res.data, '_blank');
          }
          
        } 
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
      }
    });
    this.router.navigate(['/']);
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

}
