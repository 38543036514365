<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
  
                    <div class="iq-card-body">
                        <a class="back-btn" nz-button="nz-button" nzType="link" (click)="back()">
                            <i class="ri-arrow-go-back-fill"></i>
                        </a>
                        <p nz-col="nz-col" class="heading-text"><span class="heading-text" *ngIf="activityName">{{activityName}} - </span>Quick 360 Dashboard</p>
                        <!-- <ng-container *ngIf="!isLoading2 && !isLoading1 && !isLoading4 && !isLoadingP">
                          <button *ngIf="!downloading" nz-button="nz-button" nzType="primary" (click)="getPDF()">Export</button>
                          <button *ngIf="downloading" [disabled]="true" nz-button="nz-button" nzType="primary" (click)="getPDF()">
                            <i nz-icon="nz-icon" nzType="loading" class="loading-icon"></i>
                            Exporting...</button>
                        </ng-container> -->
                    </div>
  
                    <div class="row justify-content-center" *ngIf="!enableChart">
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card1" style="max-width: 20rem;height: 6.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Total Participants</h5>
                            <h5 class="card-title textcolor pt-2"><b>{{cardsData?.total_participant?cardsData?.total_participant:0}}</b></h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card2" style="max-width: 20rem;height: 6.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Enrolled</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsData?.enrolled?.number?cardsData?.enrolled?.number:0}}</b></h5>
                            <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsData?.enrolled?.percentage?cardsData?.enrolled?.percentage:0}}%</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card3" style="max-width: 20rem;height: 6.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Not Started</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsData?.not_started?.number?cardsData?.not_started?.number:0}}</b>
                            </h5>
                            <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsData?.not_started?.percentage?cardsData?.not_started?.percentage:0}}%</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card4" style="max-width: 20rem;height: 6.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">In-Process</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsData?.in_progress?.number?cardsData?.in_progress?.number:0}}</b>
                            </h5>
                            <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsData?.in_progress?.percentage?cardsData?.in_progress?.percentage:0}}%</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card5" style="max-width: 20rem;height: 6.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Completed</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsData?.completed?.number?cardsData?.completed?.number:0}}</b>
                            </h5>
                            <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsData?.completed?.percentage?cardsData?.completed?.percentage:0}}%</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center" *ngIf="enableChart">
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card1" style="max-width: 20rem;height: 7.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Total Stakeholders</h5>
                            <h5 class="card-title textcolor pt-2"><b>{{cardsDataUser?.total_stakeholder?cardsDataUser?.total_stakeholder:0}}</b></h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card3" style="max-width: 20rem;height: 7.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Min Attempts by a Stakeholder</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsDataUser?.min_attempt?.number?cardsDataUser?.min_attempt?.number:0}}</b>
                            </h5>
                            <!-- <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsDataUser?.min_attempt?.percentage?cardsDataUser?.min_attempt?.percentage:0}}%</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card2" style="max-width: 20rem;height: 7.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Max Attempts by a Stakeholder</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsDataUser?.max_attempt?.number?cardsDataUser?.max_attempt?.number:0}}</b></h5>
                            <!-- <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsDataUser?.max_attempt?.percentage?cardsDataUser?.max_attempt?.percentage:0}}%</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card4" style="max-width: 20rem;height: 7.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Feedback Pending</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsDataUser?.pending?.number?cardsDataUser?.pending?.number:0}} Stakeholder(s)</b>
                            </h5>
                            <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsDataUser?.pending?.percentage?cardsDataUser?.pending?.percentage:0}}%</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 text-center">
                        <div class="card shadow bg-light mb-3 card5" style="max-width: 20rem;height: 7.5rem;">
                          <div class="card-body">
                            <h5 class="textcolor">Feedback Given</h5>
                            <h5 class="card-title textcolor mb-0"><b>{{cardsDataUser?.completed?.number?cardsDataUser?.completed?.number:0}} Stakeholder(s)</b>
                            </h5>
                            <p class="card-text" style="text-align: center;font-size:16px;font-weight:500">
                              {{cardsDataUser?.completed?.percentage?cardsDataUser?.completed?.percentage:0}}%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-6" *ngIf="false">
                <div class="iq-card chart3">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Leaderboard</p>
                        <div id="chart3" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-12" *ngIf="!enableChart">
              <div class="iq-card chart6">
                  <div class="iq-card-body">
                      <div class="col-lg-12">
                        <div class="d-flex filter-right">
                          <a (click)="openFeedBackTypeModal()"><span>Feedback As <i class="ri-menu-unfold-fill"></i></span></a>
                          &nbsp;
                          <br>
                          <a (click)="openGroupingModal()"><span>Group By <i class="ri-arrow-up-down-fill"></i></span> </a>
                          &nbsp;
                          <a (click)="openFilterModal()"><span>Filter <i class="ri-filter-2-line"></i></span></a>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <p nz-col class="heading-text">Quick 360 Degree Activity Status <span *ngIf="type" class="heading-text">({{type}})</span></p>
                      </div>
                      <div *ngIf="!chart1Data?.isNoData && !isLoading4" id="chart1" style="width: 100%; height: 400px"></div>
                      <div class="text-center" style="padding: 50px;height: 300px;width: 100%;"  *ngIf="chart1Data?.isNoData && !isLoading4">
                        <span style="font-size: 20px;" ><i nz-icon style="font-size: 20px;" nzType="frown" nzTheme="outline"></i> No Data With Selected Filters.</span>
                      </div>
                      <div class="text-center" style="padding: 50px;height: 300px;width: 100%;" *ngIf="isLoading4">
                        <span style="font-size: 20px;" *ngIf="isLoading4"><i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading..</span>
                      </div>
                  </div>
              </div>
            </div>
            <!-- <div class="col-sm-4 col-lg-4">
                <div class="iq-card chart4">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Top Observers</p>
                        <div id="chart4" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="iq-card chart5">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Top Participants</p>
                        <div id="chart5" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card chart6">
                    <div class="iq-card-body">
                        <div style="float:right">
                            <a (click)="openGroupingModal()"><span>Group By <i class="ri-arrow-up-down-fill"></i></span>
                            </a>
                            &nbsp;
                        </div>
                        <p nz-col class="heading-text">{{selectedFilter.label}} Wise Displayed Behaviours</p>
                        <div id="chart1" style="width: 100%; height: 400px"></div>
                    </div>
                </div>
            </div>
        </div> -->
        <nz-modal [(nzVisible)]="isGroupingModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Group By"
            (nzOnCancel)="handleGroupCancel()" (nzOnOk)="handleGroupOk()" nzOkText="Apply">
            <div class="row">
                <a *ngFor="let group of groupingList" (click)="checkedChoice(group)">
                    <label nz-checkbox (change)="checkedChoice(group)" [(ngModel)]="group.is_select"
                        [ngModelOptions]="{standalone: true}">{{group.label}}</label>
                </a>
            </div>
        </nz-modal>
        <nz-modal [(nzVisible)]="isFilterModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Select Filter"
        (nzOnCancel)="handleFilterCancel()" (nzOnOk)="handleFilterOk()" nzOkText="Apply">
        <form nz-form [nzLayout]="'vertical'" [formGroup]="dLForm" (ngSubmit)="handleFilterOk()">
          <!-- Input for Business Unit select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="business_unit_ids" nzNoColon="false">Business unit</nz-form-label>
            <nz-form-control nzErrorTip="Business Unit is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                  nzPlaceHolder="Select Business Unit" formControlName="business_unit_ids">
                  <ng-container *ngFor="let item of bUOptionList">
                    <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->
  
          <!-- Input for Location select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="location_ids" nzNoColon="false">Locations</nz-form-label>
            <nz-form-control nzErrorTip="Location is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" (nzOpenChange)="onLocationOpened($event)"
                  [nzDisabled]="!dLForm.controls.business_unit_ids?.value" nzAllowClear *ngIf="isFilterModalOpen"
                  nzMode="multiple" nzPlaceHolder="Select Locatiion" formControlName="location_ids">
  
                  <ng-container *ngFor="let item of locationOptionList">
                    <nz-option *ngIf="!isLocationOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->
  
          <!-- Input for Function select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="function_ids" nzNoColon="false">Functions</nz-form-label>
            <nz-form-control nzErrorTip="Function is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" (nzOpenChange)="onFunctionOpened($event)"
                  [nzDisabled]="!dLForm.controls.business_unit_ids?.value" nzAllowClear *ngIf="isFilterModalOpen"
                  nzMode="multiple" nzPlaceHolder="Select Function" formControlName="function_ids">
  
                  <ng-container *ngFor="let item of functionOptionList">
                    <nz-option *ngIf="!isFunctionOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->
  
          <!-- Input for Department select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="department_ids" nzNoColon="false">Departments</nz-form-label>
            <nz-form-control nzErrorTip="Department is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" (nzOpenChange)="onDepartmentOpened($event)"
                  [nzDisabled]="!dLForm.controls.function_ids?.value" nzAllowClear *ngIf="isFilterModalOpen"
                  nzMode="multiple" nzPlaceHolder="Select Department" formControlName="department_ids">
                  <ng-container *ngFor="let item of departmentOptionList">
                    <nz-option *ngIf="!isDepartmentOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->
  
  
          <!-- Input for Roles select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="role_ids" nzNoColon="false">Roles</nz-form-label>
            <nz-form-control nzErrorTip="Role is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                  nzPlaceHolder="Select Roles" formControlName="role_ids">
                  <nz-option *ngFor="let item of roleListOption" [nzLabel]="item.role_name" [nzValue]="item.id">
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->
  
  
          <!-- Input for Grades select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="grade_ids" nzNoColon="false">Grades</nz-form-label>
            <nz-form-control nzErrorTip="Grade is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                  nzPlaceHolder="Select Grade" formControlName="grade_ids">
                  <nz-option *ngFor="let item of gradeListOption" [nzLabel]="item.grade_name" [nzValue]="item.id">
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Grades select box ends here -->
  
        </form>
        </nz-modal>

        <nz-modal [(nzVisible)]="FeedBackTypeModal" nzClosable="true" nzMaskClosable="false" nzTitle="Select Feedback Role Type"
        (nzOnCancel)="openFeedBackTypeModalCancel()" (nzOnOk)="openFeedBackTypeModalOk()" nzOkText="Apply">
        <form nz-form [nzLayout]="'vertical'" [formGroup]="fLForm" (ngSubmit)=" openFeedBackTypeModalOk()">
          <nz-form-item>
            <nz-form-control nzErrorTip="Feedback Type is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'"  (nzOpenChange)="onFeedbackTypeChange($event)"
                  [nzDisabled]= nzAllowClear *ngIf="FeedBackTypeModal"
                  [multiple]="false" nzPlaceHolder="Select Feedback Type" formControlName="type">
  
                  <ng-container *ngFor="let item of feedBackList">
                    <nz-option [nzLabel]="item.name" [nzValue]="item.name">
                    </nz-option>
                  </ng-container>
                  <!-- <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option> -->
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </form>
        </nz-modal>




        <div class="row" *ngIf="enableChart">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card chart7">
                    <div class="iq-card-body">
                        <p nz-col class="heading-text">Stakeholders Attempts</p>
                        <div *ngIf="!chart2Data?.isNoData && !isLoading3" id="baloonChart" style="width: 100%; height: 400px"></div>
                        <div class="text-center" style="padding: 50px;height: 300px;width: 100%;"  *ngIf="chart2Data?.isNoData && !isLoading3">
                          <span style="font-size: 20px;" ><i nz-icon style="font-size: 20px;" nzType="frown" nzTheme="outline"></i> No Data</span>
                        </div>
                        <div class="text-center" style="padding: 50px;height: 300px;width: 100%;" *ngIf="isLoading3">
                          <span style="font-size: 20px;" *ngIf="isLoading3"><i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading..</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>