import { Component} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppSettings, Settings } from './app.settings';
import {AppService} from 'src/app/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'exlygenze-web';
  
  public settings: Settings;
  constructor(public appSettings:AppSettings,
    private activatedRoute: ActivatedRoute,
    public router:Router,public appService: AppService){
    this.settings = this.appSettings.settings;
    // window.addEventListener("beforeunload", () => localStorage.removeItem('dupaccountAccessToken'));
    // window.addEventListener("beforeunload", () => localStorage.removeItem('dupme'));
    // window.addEventListener("beforeunload", () => localStorage.removeItem('duptokenexpiry'));
  }
  type
  ngOnInit(): void {

    let spltData = window.location.href.split("=");
    const filtrData = spltData.filter(em=>(em == "mailer"))
    // console.log('URL',filtrData)
    if(filtrData.length != 0){
      localStorage.setItem('mailerUrl',window.location.href)
    }
    // let getloginKey = sessionStorage.getItem('keylogin')
    // let tokenExpiry = sessionStorage.getItem('tokenexpiry')?sessionStorage.getItem('tokenexpiry'):localStorage.getItem('duptokenexpiry');
    // let accountAccessToken = sessionStorage.getItem('accountAccessToken')?localStorage.getItem('dupaccountAccessToken'):localStorage.getItem('dupaccountAccessToken');
    // if (getloginKey == null || getloginKey == undefined && tokenExpiry == null || tokenExpiry == undefined || tokenExpiry == 'undefined' || accountAccessToken == null || accountAccessToken == undefined || accountAccessToken == 'undefined') {
    //     this.appService.navigateTopreLogin();
    // }
    // console.log('URL',localStorage.getItem('mailerUrl'));
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          window.scrollTo(0,0);
        }); 
      }            
    });    
  }

}
