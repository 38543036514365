<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
              <span>Create New</span>
            </button> -->
            <h3 class="py-4 text-center">Location</h3>
  
                <div class="d-flex justify-content-between align-items-center">
               
                  <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle"
                    nzTheme="fill"></i></h3>
                  <div>
                    <button nz-button nzType="primary" (click)="showImportModal()">
                      <span>Import</span>
                    </button>
                 </div>
                </div>
            <nz-modal nzMaskClosable="false" [(nzVisible)]="isFormVisible" nzTitle="{{action | titlecase}} Location" (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleModalSubmit()" nzOkText="Save" nzOkType="primary" [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!locationForm.valid">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="locationForm" (ngSubmit)="submitForm()">
                <!-- Capture the location name -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="name" nzNoColon="false">Location Name</nz-form-label>
                  <nz-form-control nzErrorTip="Location name is mandatory.">
                    <nz-input-group nzSize="large">
                      <input noWhitespace formControlName="name" nz-input nzSize="large" placeholder="Location Name" (keypress)="alphaNumberOnly($event)"/>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <!-- Capture the bu -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="BU" nzNoColon="false">Business Unit</nz-form-label>
                  <nz-form-control nzErrorTip="Business Unit is mandatory.">
                    <nz-input-group >
                      <nz-select nzAllowClear nzSize="large"   nz-tooltip="Select Business Unit" nzMode="multiple" formControlName="business_unit_ids">
                        <nz-option *ngFor="let o of buOptionList" [nzValue]="o.id" [nzLabel]="o.name"></nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </form>
              <!-- {{locationForm.value | json}} -->
            </nz-modal>
            <nz-modal [(nzVisible)]="isImportFormVisible" nzTitle="Bulk Import" (nzOnCancel)="handleModalCancel()"
            (nzOnOk)="handleImportModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
            nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

            <h4 style="font-weight: 500;">Instructions</h4>
            <ul>
              <li>Add the name of the location unit  which you want to be configured onto the platform</li>
              <li>Remember to use the same name in other templates wherever location unit name is required</li>
            </ul>

            <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
              class="mr-3">
              Sample CSV Download
            </button>
            <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
              <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
            </nz-upload>
          </nz-modal>
            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"
              [nzData]="locations" [nzScroll]="{ x: '150px' }">
              <thead>
                <tr>
                  <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                  <th nzColumnKey="name" [nzSortFn]="true">Name</th>
                  <th nzColumnKey="business_unit_id" [nzSortFn]="true">Business Unit</th> -->

                  <th>Name</th>
                  <th>Business Unit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let location of locations">

                  <td>{{ location.name }}</td>
                  <td>
                    <ng-container *ngFor="let bUs of location.business_units">
                      <a class="mr-2" [routerLink]="['/business-unit']"
                        [queryParams]="{id: bUs.business_unit.id}">{{ bUs.business_unit.name }},</a>
                    </ng-container>

                  </td>
                  <td>
                    <a (click)="showEditModal(location.id)"><i class="ri-pencil-fill grayIcon"></i></a>
                    <nz-divider nzType="vertical"> | </nz-divider>
                    <a (click)="remove(location)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
