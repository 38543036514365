import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-submissions',
  templateUrl: './form-submissions.component.html',
  styleUrls: ['./form-submissions.component.css']
})
export class FormSubmissionsComponent implements OnInit {

  form: any = {};
  loading = false;
  submissions: any = [];

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    public appService: AppService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loading = true;
    this.appService.getTeamSubmissions().subscribe((submissions: any) => {
      this.submissions = submissions;
      this.spinner.hide();
      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }


}
