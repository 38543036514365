import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {CompetencyRetrieveRequest, DesiredLevelRetrieveRequest, GradeRetrieveRequest, RoleRetrieveRequest} from 'src/app/app.models';
import {AppService} from 'src/app/app.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {NzMessageService} from 'ng-zorro-antd/message';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import {
  ActivityRetrieveRequest,
  BusinessUnit,
  BusinessUnitRetrieveRequest,
  Department,
  DepartmentRetrieveRequest,
  Employee,
  EmployeeRetrieveRequest,
  FunctionRetrieveRequest,
  LocationRetrieveRequest,
} from 'src/app/app.models';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {string} from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-desired-level',
  templateUrl: './desired-level.component.html',
  styleUrls: ['./desired-level.component.css']
})
export class DesiredLevelComponent implements OnInit {

  total = 1;
  loading = true;
  pageSize = 100;
  pageIndex = 1;
  visible = true;

  dLForm: FormGroup;
  isFormVisible = false;
  isFormLoading = false;

  action = '';
  competancyOptionList = [];
  roleListOption = [];
  gradeListOption = [];
  isAllChecked = false;
  listOfCurrentPageData: ReadonlyArray<any> = [];
  setOfCheckedId = new Set<number>();
  selectedCompetancy: any;
  competencies: any;
  desiredlevel: any;
  desiredLevelOptionList: any;
  levelId: any;
  levelName: any;
  desiredLevelId: number;

  functionOptionList: Function[] = [];
  isFunctionOptionListLoading = false;

  departmentOptionList: Department[] = [];
  isDepartmentOptionListLoading = false;

  locationOptionList: Location[] = [];
  isLocationOptionListLoading = false;

  bUOptionList: BusinessUnit[] = [];
  isBusinessUnitOptionListLoading = false;

  modifiedDesiredlevel: any;
  functionWithoutFilter: any;
  competenciesWithoutFilter: any;

  false_desired: boolean = false;
  maximumDesiredScore: number = 0;
  isErrorModalVisible: boolean = false;
  ErrorModalData: any;
  ErrorModalMessage: string;
  ErrorModalTitle: string;

  constructor(
    private fb: FormBuilder, private spinner: NgxSpinnerService,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) {
  }

  ngOnInit(): void {

    this.appService.getSettings(['maximum-desired-score']).subscribe(value => {

      if (value['items'].length > 0) {
        this.maximumDesiredScore = parseInt(value['items'][0]['value'], 10);
        // console.log('thismaximumDesiredScore', this.maximumDesiredScore);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Maximum desired score is not set'
        });

      }
    }, error => {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });
    });

    this.resetCreateModal();
    // Calling API to get BussinessUnit
    this.getBUs();
    // Calling API to get Function
    this.getFunctionWithoutFilter();
    // Calling API to get competancy
    this.getCompetencyWithoutFilter();
    // Calling API for Role in dropdown
    this.getRoleList();
    // Calling API for Grade in dropdown
    this.getGradeList();
    // Calling API for Competancy in dropdown
    this.setCompetancyAndLevelList();
    // Calling API for Role in pupulate with name s in table listing
    // this.populateTable();

  }

  // get Role Without Filter
  getFunctionWithoutFilter() {
    this.appService.getFunctions().subscribe((res) => {
      this.functionWithoutFilter = res['items'];
    });
  }

  // get Role Without Filter
  getCompetencyWithoutFilter() {
    this.appService.getCompetencies().subscribe((res) => {
      this.competenciesWithoutFilter = res['items'];
    });
  }


  // Populate Listing table
  populateTable() {


    // this.modifiedDesiredlevel = {...this.desiredlevel};
  }


  // Get Bussiness Unit
  getBUs() {
    let rr = new BusinessUnitRetrieveRequest();
    rr.per_page = null;
    this.appService.getBusinessUnits(rr).subscribe((res) => {
      this.bUOptionList = res['items'];
    });
  }

  // get Locations
  getLocations(businessUnitId) {
    this.isLocationOptionListLoading = true;
    let rr = new LocationRetrieveRequest();
    rr.business_unit_ids = businessUnitId;
    rr.per_page = null;
    this.appService.getLocations(rr).subscribe((res) => {
      this.locationOptionList = res['items'];
      this.isLocationOptionListLoading = false;
    });
  }

  // Get Functions
  getFunctions(businessUnitId) {
    this.isFunctionOptionListLoading = true;
    let rr = new FunctionRetrieveRequest();
    rr.business_unit_ids = businessUnitId;
    rr.per_page = null;
    this.appService.getFunctions(rr).subscribe((res) => {
      this.functionOptionList = res['items'];
      this.isFunctionOptionListLoading = false;
    });
  }

  // Get Functions
  getDepartments(functionId) {
    this.isDepartmentOptionListLoading = true;
    let rr = new DepartmentRetrieveRequest();
    rr.function_ids = functionId;
    rr.per_page = null;
    this.appService.getDepartments(rr).subscribe((res) => {
      this.departmentOptionList = res['items'];
      this.isDepartmentOptionListLoading = false;
    });
  }

  onLocationOpened(isOpened) {
    if (!isOpened) {
      return;
    }
    this.getLocations(this.dLForm.value.business_unit_id);
  }

  onFunctionOpened(isOpened) {
    if (!isOpened) {
      return;
    }
    this.getFunctions(this.dLForm.value.business_unit_id);
  }

  onDepartmentOpened(isOpened) {
    if (!isOpened) {
      return;
    }
    this.getDepartments(this.dLForm.value.function_id);
  }


  // while clicking cancel button in modal
  handleModalCancel() {
    this.isFormVisible = false;
  }

  // While Click Ok button in modal
  handleModalSubmit() {
    this.spinner.show();
    this.submitForm();
    this.isFormLoading = true;
    if (this.dLForm.valid) {
      let data = {
        'filter_title': this.dLForm.value.filter_title,
        'business_unit_id': this.dLForm.value.business_unit_id != null ? this.dLForm.value.business_unit_id : [],
        'location_id': this.dLForm.value.location_id != null ? this.dLForm.value.location_id : [],
        'function_id': this.dLForm.value.function_id != null ? this.dLForm.value.function_id : [],
        'department_id': this.dLForm.value.department_id != null ? this.dLForm.value.department_id : [],
        'role_id': this.dLForm.value.role_id != null ? this.dLForm.value.role_id : [],
        'employee_grade_id': this.dLForm.value.employee_grade_id != null ? this.dLForm.value.employee_grade_id : [],
        'competency_id': this.dLForm.value.competency_id != null ? this.dLForm.value.competency_id : [],
        'desired_level_value': this.dLForm.value.desired_level_value,
        // 'status': true
      };

      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createDesiredLevel(data);
        message = 'Please wait few minutes to complete this task \nCheck inbox/Notification to conform completion';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateDesiredLevel(data, this.desiredLevelId);
        message = 'Please wait few minutes to complete this task \nCheck inbox/Notification to conform completion';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });


        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, null, null, null, null, []);
        this.spinner.hide();
      }, error => {
        // console.log('error message', error);
        this.isFormVisible = false;
        this.isFormLoading = false;
        this.spinner.hide();
        // let errorMessage = error.error.message;
        // let errorMessage = error.error.message;
        this.isErrorModalVisible = true;
        let errorData;
        if (error?.error) {
          errorData = JSON.parse(error?.error?.message);
          // console.log('errorData', errorData);
        }

        if (errorData?.type === 2) {
          this.ErrorModalData = {key: errorData.data};
          this.ErrorModalMessage = errorData.message;
        } else if (errorData?.type === 1) {
          this.ErrorModalData = errorData.data;
          this.ErrorModalTitle = this.ErrorModalData.title;
          delete this.ErrorModalData.title;
          this.ErrorModalMessage = errorData.message;

        } else {
          this.ErrorModalData = errorData.data;
          this.ErrorModalMessage = errorData.message;
          this.ErrorModalTitle = errorData.message;
        }
        // this.message.create('error', errorMessage, {
        //   nzDuration: 5000
        // });

      });

    }
    this.isFormVisible = false;
  }

  // Showing modal when click + button
  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(dlId: number): void {
    this.getDesiredLevelById(dlId);
    this.desiredLevelId = dlId;
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getDesiredLevelById(desiredLevelId: number) {
    this.appService.get<any>('/api/desired-level/' + desiredLevelId).subscribe(res => {
      // this.dLForm.patchValue(res);
      this.onLocationOpened(true);
      this.onFunctionOpened(true);
      this.onDepartmentOpened(true);
      // this.dLForm.patchValue(res)
      // this.dLForm.patchValue(res);
      this.populateCreateModal(res);
    });
  }

  // resetting Form/initialisizing new form
  populateCreateModal(res): void {
    this.dLForm = this.fb.group({
      filter_title: [res.filter_title, [Validators.required]],
      function_id: [res.function_id,],
      business_unit_id: [res.business_unit_id,],
      location_id: [res.location_id,],
      department_id: [res.department_id,],
      role_id: [res.role_id,],
      employee_grade_id: [res.employee_grade_id,],
      competency_id: [res.competency_id,],
      desired_level_value: [res.desired_level_value, [Validators.required, Validators.min(0), Validators.max(this.maximumDesiredScore)]],
      // status: [true]
    });
  }

  remove(desiredlevel) {
    const index: number = this.desiredlevel.indexOf(desiredlevel);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete "' + desiredlevel.filter_title + '" ?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteDesiredLevel(desiredlevel.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, null, null, null, null, []);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }
  }

  // Setting functions from API
  async setFunctionList() {
    const rr = new FunctionRetrieveRequest();
    rr.per_page = null;
    await this.appService.getFunctions(rr).subscribe((data) => {
      this.functionOptionList = data['items'];
    });
  }

  // Setting Roles from API
  getRoleList() {
    const rr = new RoleRetrieveRequest();
    rr.per_page = null;
    this.appService.getRoles(rr).subscribe(data => {
      this.roleListOption = data['items'];
    });
  }

  // Setting Grades from API
  getGradeList() {
    const rr = new GradeRetrieveRequest();
    rr.per_page = null;
    this.appService.getGrades(rr).subscribe(data => {
      this.gradeListOption = data['items'];
    });
  }

  // Setting Competancy from API
  async setCompetancyAndLevelList() {

    await this.appService.getLevelSettings().subscribe((res) => {
      if (res['items'].length) {
        let levels = res['items'];
        levels = levels.filter(element => element.is_select).map(item => ({id: item.id, level_name: item.level_name}));
        this.levelId = levels[0].id;
        this.levelName = levels[0].level_name;
        const rr = new CompetencyRetrieveRequest();
        rr.per_page = null;
        this.appService.getCompetencies(rr).subscribe((data) => {
          this.competancyOptionList = data['items'];
          this.competancyOptionList = this.competancyOptionList.filter(element => element.level_id === this.levelId);
        });
      }
    });

  }


  // resetting Form/initialisizing new form
  resetCreateModal(): void {
    this.dLForm = this.fb.group({
      filter_title: [null, [Validators.required]],
      business_unit_id: [null,],
      location_id: [null,],
      function_id: [null,],
      department_id: [null,],
      role_id: [null,],
      employee_grade_id: [null,],
      competency_id: [null,],
      desired_level_value: [null, [Validators.required, Validators.min(0), Validators.max(this.maximumDesiredScore)]],
      // status: [true]
    });
  }

  // table dats for behaviour
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: ReadonlyArray<any>): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.isAllChecked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
  }

  //

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null, null, null, null, null, null, null, []);
  }

  // Loading Desired Level data from server
  loadDataFromServer(pageIndex: number, pageSize: number,
                     sortField: string | null, sortOrder: string | null,
                     functionId: Array<number> | [],
                     roleId: Array<number> | [],
                     employeeGradeId: Array<number> | [],
                     competencyLevelId: Array<number> | [],
                     buId: Array<number> | [],
                     locationId: Array<number> | [],
                     depId: Array<number> | [],
                     ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new DesiredLevelRetrieveRequest();
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;

    rr.function_id = functionId;
    rr.role_id = roleId;
    rr.employee_grade_id = employeeGradeId;
    rr.competency_id = competencyLevelId;
    rr.business_unit_id = buId,
      rr.location_id = locationId,
      rr.department_id = depId,
      rr.ids = ids;

    this.appService.getDesiredLevel(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.desiredlevel = data['items'];
      this.desiredlevel.forEach((element, i) => {
        // console.log('ww', element);
        if (element.status === false) {
          this.false_desired = true;
          Swal.fire('', 'Creation of Desired Score is in progress. Once complete you will be notified, until then you wont be able to add/delete/edit the desired score settings.', 'warning');

        }
        // For Role Names
        let mappedVal = this.roleListOption.filter(roleElement => element.role_id.indexOf(roleElement.id) !== -1).map(item => item.role_name);
        this.getSpliceOrNot(mappedVal);
        this.desiredlevel[i]['role_names'] = mappedVal;

        // For Function Names
        mappedVal = this.functionWithoutFilter.filter(functionElement => element.function_id.indexOf(functionElement.id) !== -1).map(item => item.name);
        this.getSpliceOrNot(mappedVal);
        this.desiredlevel[i]['function_names'] = mappedVal;

        // For Grade Names
        mappedVal = this.gradeListOption.filter(gradeElement => element.employee_grade_id.indexOf(gradeElement.id) !== -1).map(item => item.grade_name);
        this.getSpliceOrNot(mappedVal);
        this.desiredlevel[i]['grade_names'] = mappedVal;

        // For Competency Names
        mappedVal = this.competenciesWithoutFilter.filter(competencyElement => element.competency_id.indexOf(competencyElement.id) !== -1).map(item => item.competency_name);
        this.getSpliceOrNot(mappedVal);
        this.desiredlevel[i]['competency_names'] = mappedVal;

      });
    });
  }

  // Loading desired level data ends here

  // While submitting the form/ok button
  submitForm() {
    for (const i in this.dLForm.controls) {
      this.dLForm.controls[i].markAsDirty();
      this.dLForm.controls[i].updateValueAndValidity();
    }

  }

  // splice

  getSpliceOrNot(mappedVal) {
    if (mappedVal.length > 2) {
      mappedVal.splice(2);
      mappedVal.push('...');
    }
    mappedVal.join(', ');
  }

  ErrorModalhandleCancel() {

    this.isErrorModalVisible = false;

  }
}
