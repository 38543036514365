import {Component, OnInit} from '@angular/core';
import {AppService} from 'src/app/app.service';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgxSpinnerService} from "ngx-spinner";
import {NgAuthService} from "../../ng-auth.service";
import {NzButtonSize} from 'ng-zorro-antd/button';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import { AuthService } from '../../auth.service';


@Component({
  selector: 'app-prelogin' + 'nz-demo-button-size',
  templateUrl: './prelogin.component.html',
  styleUrls: ['./prelogin.component.css'],

})
export class PreloginComponent implements OnInit {
  public loginForm: FormGroup
  stage_message: string = "start_up"
  token: any
  jwt_token: any
  size: NzButtonSize = 'large';

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public appService: AppService,
    private spinner: NgxSpinnerService,
    public ngAuthService: NgAuthService,
    private authService: AuthService,
    private notification :NzNotificationService,
  ) {
  }


  private clearAppData(): void {
    sessionStorage.clear();
    localStorage.clear();
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  ngOnInit(): void {
    this.clearAppData();

    this.loginForm = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, this.stage_message === 'email_verified' ?? Validators.compose([Validators.required])],
      otp: [null, this.stage_message === 'email_available' ?? Validators.compose([Validators.required])],
      password1: [null, this.stage_message === 'password_reset' ?? Validators.compose([Validators.required])],
      password2: [null, this.stage_message === 'password_reset' ?? Validators.compose([Validators.required])],
      // corporate_id: [null],
      remember_me: false
    });

    this.notification.create(
      'info',
      'info',
      'Please press Ctrl- in case you wish to align the app dimensions as per your screen resolution.',
      {
        nzDuration: 3000,
        nzStyle: {
          width: '300px',

        },
      }
    );

  }

  async signIn(): Promise<void> {
    await this.authService.signIn();
  }

  slides = [
    // {label: "Image Title", image: "../../../assets/images/login/img1.png"},
    {label: "Image Title", image: "../../../assets/images/login/img1.png"},
    {label: "Image Title", image: "../../../assets/images/login/img2.png"},
    // {label: "Image Title", image: "../../../assets/images/login/Group626.png"},
    // {label: "Image Title", image: "../../../assets/images/login/Group626.png"},
  ];

  effect = 'scrollx';

  goTologin() {
    sessionStorage.setItem('keylogin', 'yes')
    this.router.navigate(['/login']);
  }
}
