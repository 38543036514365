<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <div nz-col [nzSpan]="24" >
                <a class="back-btn" nz-button nzType="link" (click)="back()">
                    <i class="ri-arrow-go-back-fill" ></i>
                </a>
            </div>
              <h3 class="py-4 text-center">PII Requests</h3>
  
              <nz-table nzBordered nzShowSizeChanger  [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" [nzData]="PiiEmployees"
              (nzQueryParams)="onQueryParamsChange($event)">
                <thead>
                <tr>
                  <th>Employee Id</th>  
                  <th>Requested Resource</th>
                  <th>Browser</th>
                  <th>Source IP</th>
                  <th>Delete</th>
                  <th>Deactivate</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let pii of PiiEmployees">
                  <td>{{pii?.emp_id}}</td>
                  <td>{{pii?.req_resource }}</td>
                  <td>{{pii?.browser}}</td>
                  <td>{{pii?.src_ip}}</td>
                  <td class="text-center">
                    <div *ngIf="pii?.is_deleted=='no'; else deleteElseBlock">
                      <button nz-button [nzType]="'primary'" (click)="deleteUser(pii?.emp_id)"><span>Delete</span>
                      </button>
                    </div>
                     <ng-template #deleteElseBlock>
                        Deleted
                       </ng-template>
                  </td>
                  <td class="text-center">
                    <div *ngIf="pii?.is_deactivated=='no'; else deactivateElseBlock">
                      <button nz-button [nzType]="'primary'" (click)="deactivateUser(pii?.emp_id)"><span>Deactivate</span>
                      </button>
                    </div>
                    <ng-template #deactivateElseBlock>
                      Deactivated
                     </ng-template>
                  </td>
                  
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  