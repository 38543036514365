<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <h3 class="pb-4 text-center">Continuous 360 Report</h3>
              <div class="col-lg-12 text-right mb-3">
                <button  nz-button  nz-tooltip nzTooltipTitle="Download Excel Report"
                nzTooltipPlacement="bottomLeft" nzType="primary" (click)="downloadExcel()"><i nz-icon nzType="download"
                    ></i> Download Report
                </button>
              </div>
              <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="campaignList"
              (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
              <thead>
                <tr>
                  <th [(nzChecked)]="isAllChecked" (nzCheckedChange)="onAllChecked($event)"></th>
                  <th>Campaign Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Levels</th>
                  <th>Created By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of rowSelectionTable.data">
                  <td [nzChecked]="setofCheckedCampaigns.has(item.id)" (nzCheckedChange)="onItemChecked(item.id, $event)"></td>
                  <td>{{item.campaign_name | titlecase}}</td>
                  <td>{{item.start_date }}</td>
                  <td>{{item.end_date }}</td>
                  <td>
                    <nz-breadcrumb nzSeparator=">">
                      <ng-container *ngFor="let ln of item.level_names">
                        <nz-breadcrumb-item>
                          {{ln}}
                        </nz-breadcrumb-item>
                      </ng-container>
                    </nz-breadcrumb>
                  </td>
                  <td>{{item.created_by_name | titlecase}}</td>
                  <td>
                    <a title="Dashboard" [routerLink]="['/behaviour-transactions/campaign-dashboard', item.id]"
                    [queryParams]="{ levels: item.no_of_levels }"> <i
                      class="ri-pie-chart-box-line grayIcon mr-2"></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
    <p style="color: white" *ngIf="loading">Please Wait... </p>
    <p style="color: white" *ngIf="!loading">Generating Report. Please Wait... </p>
  </ngx-spinner>