import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import { Form, FormRetrieveRequest } from 'src/app/app.models';

interface DataItem {
  name: string;
  status: string;
  frequency: string;
};

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css']
})
export class FormListComponent implements OnInit {

  forms: Form[];

  page = 1;
  total = 1;
  limit = 100;
  loading = false;
  sortOrder = 'desc';
  sortField = 'created_on';

  sortNameFn = (a: DataItem, b: DataItem) => a.name.localeCompare(b.name);
  sortStatusFn = (a: DataItem, b: DataItem) => a.status.localeCompare(b.status);
  sortFrequencyFn = (a: DataItem, b: DataItem) => a.frequency.localeCompare(b.frequency);

  constructor(
    private router: Router,
    public appService: AppService,
    private message: NzMessageService,
    private spinner: NgxSpinnerService
  ) { }

  loadForms(): void {
    this.spinner.show();
    this.loading = true;
    let rr = new FormRetrieveRequest('');
    rr.page = this.page || 1;
    rr.per_page = this.limit || 100;
    rr.sort_order = this.sortOrder || 'desc';
    rr.order_by = this.sortField || 'created_on';

    this.appService.getForms(rr).subscribe((data: any) => {
      this.total = data['_meta']['total_items'];
      this.forms = data['items'];
      this.spinner.hide();
      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.loadForms();
  }

  removeForm(index, form): void {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm before deleting the form ${form.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if(result.value){
        this.appService.deleteForm(form.id).subscribe((res: any) => {
          this.loadForms();
          this.message.create('success', 'Form Deleted', {
            nzDuration: 5000
          });
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sortField = (currentSort && currentSort.key) || null;
    this.sortOrder = (currentSort && currentSort.value) || null;
    this.limit = pageSize;
    this.page = pageIndex;
    this.loadForms();
  }

  toggleStatus(form): void {
    let statusMsg = 'Form Activated';
    let confirmButtonText = 'Yes, activate it!';
    let text = `Please confirm before activating the form ${form.name}`;
    if(form.status === 'active'){
      statusMsg = 'Form InActivated';
      confirmButtonText = 'Yes, inactivate it!';
      text = `Please confirm before inactivating the form ${form.name}`;
    }
    Swal.fire({
      text: text,
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if(result.value){
        this.spinner.show();
        let data = { status: form.status === 'active' ? 'inactive' : 'active' };
        this.appService.updateStatus(data, form.id).subscribe((res: any) => {
          this.loadForms();
          this.message.create('success', statusMsg, {
            nzDuration: 5000
          });
          this.spinner.hide();
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }
    });
  }

}
