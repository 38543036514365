import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NgAuthService } from "./ng-auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    public ngAuthService: NgAuthService,
) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let dupToken = localStorage.getItem("dupaccountAccessToken")
      if (sessionStorage.getItem("accountAccessToken")?sessionStorage.getItem("accountAccessToken"):dupToken && sessionStorage.getItem("accountAccessToken")?sessionStorage.getItem("accountAccessToken"):dupToken != null) {
        return true;
      }
      else if(this.ngAuthService.isLoggedIn !== true) {
        return true;
      }
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let dupToken = localStorage.getItem("dupaccountAccessToken")
      if (sessionStorage.getItem("accountAccessToken")?sessionStorage.getItem("accountAccessToken"):dupToken && sessionStorage.getItem("accountAccessToken")?sessionStorage.getItem("accountAccessToken"):dupToken != null) {
        return true;
      }
      else if(this.ngAuthService.isLoggedIn !== true) {
        return true;
      }
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
  }

}
