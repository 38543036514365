<nz-table class="tbl" #rowSelectionTable nzShowSizeChanger [nzData]="employees" [nzLoading]="isEmployeeTableLoading"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" [nzScroll]="{ x: '1100px' }">
  <thead>
    <tr>
      <th [(nzChecked)]="isAllChecked" (nzCheckedChange)="onAllChecked($event)" [nzDisabled]="IsSelectOne"></th>
      <th>Employee ID</th>
      <th>Employee Name<nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue?.length > 0" [nzDropdownMenu]="menu">
        <i nz-icon style="font-size: 20px;" nzType="search" nz-tooltip nzTooltipTitle="Search Employee"></i>
      </nz-filter-trigger></th>
      <th>Business Unit</th>
      <th>Designation</th>
      <th>Location</th>
      <th>Department</th>
      <th>Function</th>
      <th>Grade</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)" [nzDisabled]="data.disabled"></td>
      <td>{{data?.employee_id}}</td>
      <td>{{data.first_name}} {{data.last_name}}</td>
      <td>{{data?.business_unit?.name}}</td>
      <td>{{data?.designation}}</td>
      <td>{{data?.location?.name}}</td>
      <td>{{data?.department?.name}}</td>
      <td>{{data?.department?.function?.name}}</td>
      <td>{{data?.grade?.grade_name}}</td>
    </tr>
  </tbody>
</nz-table>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Search</button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
