import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameQuotesTrimmer'
})
export class NameQuotesTrimmerPipe implements PipeTransform {

  transform(title: string): string {
    return title.split('"').join('');
  }

}
