<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <!-- <div class="col-sm-12">
            <p class="text-center"><strong>Assessment Center List</strong></p>
          </div> -->
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="row">
              <div class="col-md-12">
                <a class="back-btn" nz-button nzType="link" (click)="back()">
                  <i class="ri-arrow-go-back-fill"></i>
                </a>
              </div>
            </div>
            <!-- <a class="add-btn" routerLink="assessment-form" title="Create New"><i class="ri-add-circle-fill"></i></a> -->
            <h3 class="pb-4 text-center">Assessment Center List</h3>
            <!-- <h3  class="plusIcon" *ngIf="loggedInUserDetail?.employee?.role?.allow_assessment_centre_creation === 'yes'"> <i
                nz-icon class="mb-4" routerLink="assessment-form" nzType="plus-circle" nzTheme="fill"></i></h3> -->
            <a *ngIf="loggedInUserDetail?.employee?.role?.allow_assessment_centre_creation === 'yes'" class="add-btn"
              routerLink="assessment-form" title="Create New"><i class="ri-add-circle-fill"></i></a>
            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" [nzData]="assessments"
              (nzQueryParams)="onQueryParamsChange($event)">
              <thead>
                <tr>
                  <!-- <th nzColumnKey="assessment-title" [nzSortFn]="true">Assessment Title</th>
                  <th nzColumnKey="start-date" [nzSortFn]="true">Start Date</th>
                  <th nzColumnKey="end-date" [nzSortFn]="true">End Date</th> -->
                  <th>Assessment Title</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th class="text-center">Activities</th>
                  <!-- <th>Participants</th> -->
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let assessment of assessments">
                  <td>{{ assessment.title }}</td>
                  <td>{{ assessment.start_date }}</td>
                  <td>{{ assessment.end_date }}</td>
                  <td class="text-center">
                    <a (click)="adminView(assessment)"><i class="ri-list-settings-fill grayIcon"></i></a>
                  </td>
                  <!-- <td class="text-center"><a><i class="ri-group-fill grayIcon"></i></a></td> -->
                  <td class="text-center">
                    <ng-container *ngIf="loggedInUserDetail.employee.role.allow_assessment_centre_creation == 'yes'">

                      <a class="mr-2" title="Edit"
                        [routerLink]="['/assessment-center/assessment-form/edit', assessment.id]"><i
                          class="ri-edit-fill grayIcon"></i></a>

                      <a title="Delete" (click)="remove(assessment)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>

                    </ng-container>

                    <!-- <ng-container>

                      <button class="ml-3" nz-button nzType="primary" (click)="startAssessment(assessment)"
                        *ngIf="assessment.is_assessor == 'no'">{{assessment.attempt_id ? "View" : "Enroll"}}</button>

                      <button class="ml-3" nz-button nzType="primary" (click)="assess(assessment)"
                        *ngIf="assessment.is_assessor == 'yes'">Assess</button>

                    </ng-container> -->

                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>