import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quadrantTransform'
})
export class QuadrantTransformPipe implements PipeTransform {
  transform(quadrant: string): string {
    const [performance, potential] = quadrant.split(',');
    return `${performance.trim()} Performance, ${potential.trim()} Potential`;
  }
}
