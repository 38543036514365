
<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
<div  class="  col-sm-12">  
    <div class="col-md-12">
      <h3 class="py-4 text-center">YTD Performance by AP</h3>
    </div>

    <div class="col-md-16">
        <nz-card [nzBordered]="true" > 
            <form nz-form [nzLayout]="'inline'" >
                <!-- [formGroup]="validateForm" (ngSubmit)="submitForm()" --> 
                 
            <nz-form-item class="w-25"> 
                <nz-form-control nzErrorTip="Please input Types!">
                    <nz-input-group >
                        <nz-select nzSize="large"
                            nzPlaceHolder="Please select FY"
                            [nzOptions]="listOfNoOfOption" >
                        </nz-select>
                        <span> (1st Apr 2020 - 14th Oct 2020)</span>
                    </nz-input-group>
                </nz-form-control>
                
            </nz-form-item>
            <!-- <nz-form-item>
                <nz-form-control nzErrorTip="Please input Types!">
                    <nz-input-group >
                        <nz-select
                            nzPlaceHolder="Please select Month"
                            [nzOptions]="listOfMonthsOfOption" >
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> -->
            <nz-form-item class="w-25">
                <nz-form-control nzErrorTip="Please input Types!">
                    <nz-input-group nzSize="large" >
                        <input nzSize="large" type="text" nz-input placeholder="Enter Name">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item class="w-25">
                <nz-form-control>
                    <button nzSize="large" nz-button nzType="primary"
                       >Applay</button>
                </nz-form-control>
            </nz-form-item>

            </form>
          </nz-card> 
    </div>

    <div class="row ml-2 mt-4">
      <div class="col-md-4">
          <nz-form-item>
              <nz-form-control>
                <a [routerLink]="['/maximizer-dashboard/monthly-objective-performance']" routerLinkActive="router-link-active" >
                  <label nz-checkbox>Monthly Objective Performance</label></a>
              </nz-form-control>
            </nz-form-item> 
      </div>
      <div class="col-md-4">
          <nz-form-item>
              <nz-form-control>
               <a [routerLink]="['/maximizer-dashboard/ytd-performance']" routerLinkActive="router-link-active" >
                <label nz-checkbox [ngModel]="true">YTD Performance by AP</label>
              </a>
              </nz-form-control>
            </nz-form-item> 
      </div>
      <div class="col-md-4">   
          <nz-form-item>
          <nz-form-control>
            <a [routerLink]="['/maximizer-dashboard/ytd-objective-performance']" routerLinkActive="router-link-active" >
              <label nz-checkbox>YTD Objective Performance</label>
            </a>
          </nz-form-control>
        </nz-form-item>
      </div> 
  </div>
    <div class="row">
        <nz-card [nzBordered]="false" [nzBodyStyle]="{padding: '10px'}">
          <div class="row">
            <div class="col-md-6">
                <nz-card class="cardPink" [nzBordered]="false" [nzBodyStyle]="{padding: '18px'}">
                    <div class="row">
                        <div class="col-md-9 leftText">
                            <p>The tile shows the performance of objective O1 for the selected month and employee. 25% vs 20% indicates that 25% target for the month is achieved vs 20% effort.</p>
                        </div>
                        <div class="col-md-3 text-center ">
                            <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                <nz-progress
                                [nzWidth]="60"
                                nz-tooltip
                                nzTitle="3 done / 3 in progress / 4 to do"
                                nzType="circle"
                                [nzPercent]="60 "
                                [nzSuccessPercent]="30"
                              ></nz-progress>
                              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                              </a>
                              <span class="cardNumber">01</span>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                </ul>
                              </nz-dropdown-menu>
                              </nz-card> 
                        </div>
                    </div>
                </nz-card>
            </div>
            <div class="col-md-3">
                <div class="mb-3  ">The tile color code is:</div>
                <div><span class="tileBox pink"></span> Less than 60%</div>
                <div><span class="tileBox yellow"></span> 60% to 80%</div>
                <div><span class="tileBox green"></span> More than 80%</div>
            </div>
            <div class="col-md-3"> 
            <!-- <nz-divider nzType="vertical" ></nz-divider> -->
                <div class="mb-3">The circle color code is:</div>
                <div><span class="tileBox pink"></span> Target Achieved >= Effort</div>
                <div><span class="tileBox green"></span>Target Achieved < Effort</div> 
            </div>
          </div>
        </nz-card>
    </div>

    <div class="row">  
      <div class="col-md-12">
        <h5 class="text-center my-3">Overall SP Performance</h5>
        <div class="progress">
          <div class="progress-bar pink" style="width:20%"> 20%
          </div>
          <div class="progress-bar yellow"  style="width:40%"> 40%
          </div>
          <div class="progress-bar green" style="width:40%"> 40%
          </div> 
        </div>   
    </div>
    <div class="col-md-12">
      <h5 class="text-center my-3">Overall AP Performance</h5>
      <div class="progress">
        <div class="progress-bar pink" style="width:20%"> 20%
        </div>
        <div class="progress-bar yellow"  style="width:40%"> 40%
        </div>
        <div class="progress-bar green" style="width:40%"> 40%
        </div> 
      </div> 
    </div>
      <nz-table #basicTable [nzBordered]="true" class="my-4" >
        <thead>
          <tr>
              <th></th>
            <th>Enterprise Outcome</th>
            <th>Organization Stakeholder</th>
            <th>Business Capability</th>
            <th>Ways of Working</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr> 
              <td>Support in acquiring customers</td>
              <td>
                <nz-card class="circleProgress" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                    <nz-progress
                    [nzFormat]="formatTwo"
                    [nzWidth]="100"
                    nz-tooltip
                    nzTitle="3 done / 3 in progress / 4 to do"
                    nzType="circle"
                    [nzPercent]="60 "
                    [nzSuccessPercent]="30"
                  ></nz-progress>
                  <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                    <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                  </a>
                  <span class="cardNumber">01</span>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                      <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                      <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                      <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                    </ul>
                  </nz-dropdown-menu>
                  </nz-card> 
                  100% 
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
          </tr>
          <tr>
            <td>Appointing & Managing Intermediaries</td>
            <td></td>
            <td>     
              <nz-card class="circleProgress green" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
              <nz-progress
              [nzWidth]="100"
              nz-tooltip
              nzTitle="3 done / 3 in progress / 4 to do"
              nzType="circle"
              [nzPercent]="60 "
              [nzSuccessPercent]="30"
            ></nz-progress>
            <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
              <i nz-icon nzType="caret-down" nzTheme="outline"></i>
            </a>
            <span class="cardNumber">01</span>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
              </ul>
            </nz-dropdown-menu>
            </nz-card> 
            80% 
          </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Developing Business Processes</td>
            <td></td>
            <td></td>
            <td> 
              <nz-card class="circleProgress yellow" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                <nz-progress
                [nzWidth]="100"
                nz-tooltip
                nzTitle="3 done / 3 in progress / 4 to do"
                nzType="circle"
                [nzPercent]="60 "
                [nzSuccessPercent]="30"
              ></nz-progress>
              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
              </a>
              <span class="cardNumber">01</span>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                </ul>
              </nz-dropdown-menu>
              </nz-card> 
              40%
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Implementing Business Processes</td>
            <td></td>
            <td></td>
            <td> 
              <nz-card class="circleProgress yellow" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                <nz-progress
                [nzWidth]="100"
                nz-tooltip
                nzTitle="3 done / 3 in progress / 4 to do"
                nzType="circle"
                [nzPercent]="60 "
                [nzSuccessPercent]="30"
              ></nz-progress>
              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
              </a>
              <span class="cardNumber">01</span>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                </ul>
              </nz-dropdown-menu>
              </nz-card> 
              10%
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Developing / Implementing content collatorals</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <nz-card class="circleProgress green" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                <nz-progress
                [nzWidth]="100"
                nz-tooltip
                nzTitle="3 done / 3 in progress / 4 to do"
                nzType="circle"
                [nzPercent]="60 "
                [nzSuccessPercent]="30"
              ></nz-progress>
              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
              </a>
              <span class="cardNumber">01</span>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                </ul>
              </nz-dropdown-menu>
              </nz-card> 
              5% 
            </td>
            <td></td>
          </tr>
        </tbody>
      </nz-table>

    


</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>