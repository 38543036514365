import {Component, OnInit} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {isPlatformBrowser} from '@angular/common';
import {Inject, NgZone, PLATFORM_ID} from '@angular/core';
import {AppService} from 'src/app/app.service';
import {
  CDTopsRetrieveRequest,
  CDTxnTrendRetrieveRequest,
  CDTxnTrendRetrieveFilterRequest,
  CDTxnTrendRetrieveLevelNameRequest
} from 'src/app/app.models';
import {ActivatedRoute} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import Swal from 'sweetalert2/dist/sweetalert2.js';


am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

@Component({
  selector: 'app-campaign-dashboard',
  templateUrl: './campaign-dashboard.component.html',
  styleUrls: ['./campaign-dashboard.component.css']
})
export class CampaignDashboardComponent implements OnInit {

  campaignId: any;
  campaignDetail: any;
  levelOptions = [];
  startDate: any;
  endDate: any;
  shareType: String = 'all';
  timePeriod: string = 'daily';
  storageAccount: String;
  level: number = 0;

  transactionCounts: any;
  selectedFilter: any = {'label': 'Department', 'value': 'department', 'is_select': true};

  transTrend: any;
  dispBehav: any;
  topParticipants: any;
  catBehavs: any;
  behavEmp: any;

  isLoading: boolean;
  isloadingTTrends: boolean;
  isloadingDBehav: boolean;
  isLoadingTop: boolean;
  isLoadingCatBehav: boolean;
  isLoadingBehavEmp: boolean;

  groupingList = [
    {'label': 'Bussiness Unit', 'value': 'bussiness_unit', 'is_select': false},
    {'label': 'Location', 'value': 'location', 'is_select': false},
    {'label': 'Function', 'value': 'function', 'is_select': false},
    {'label': 'Department', 'value': 'department', 'is_select': true},
    {'label': 'Role', 'value': 'role', 'is_select': false},
    {'label': 'Grade', 'value': 'grade', 'is_select': false},
  ];
  selectedGroupingIndex: any;
  assessmentGroupBy = [];
  isGroupingModalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
    private appService: AppService,
    public activatedRoute: ActivatedRoute,
    public message: NzMessageService,
  ) {
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  isChartEnable = false;
  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.campaignId = params['id'];
    });
    this.isLoading = true;
    this.storageAccount = this.appService.getMe().tenant.storage_url;
    let loggedInUserDetail = this.appService.getMe();
    if(loggedInUserDetail.user_type === 'tenant_admin' || loggedInUserDetail.user_type === 'admin'){
      this.isChartEnable = false;
    }else{
      this.isChartEnable = true;
    }

    this.appService.getCampaign(this.campaignId).subscribe(async res => {
      this.campaignDetail = res;
      this.levelOptions = this.campaignDetail.level_names;
      this.startDate = this.campaignDetail.start_date;
      this.endDate = this.campaignDetail.end_date;

      await this.getCount(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length);
      await this.getTransTrends(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length);
      await this.getDispBehav(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length, 'Behaviour');
      await this.getTops(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId);
      await this.getDeptWiseBehav(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length);
      await this.getBehavDispByEmp(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length);
    }, error => {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });
    });
  }

  async getCount(shareType, fromDate, toDate, campaignId, timePeriod, level) {
    let rr = new CDTxnTrendRetrieveRequest;
    rr.share_type = shareType;
    rr.from_date = fromDate;
    rr.to_date = toDate;
    rr.campaign_id = campaignId;
    rr.time_period = timePeriod;
    rr.level = level;

    let res = await this.appService.getCDParticipationCount(rr).toPromise();
    this.transactionCounts = res['data'];
    // console.log('getCount', this.transactionCounts);
    this.isLoading = false;
  }

  async getTransTrends(shareType, fromDate, toDate, campaignId, timePeriod, level) {
    this.isloadingTTrends = true;
    let rr = new CDTxnTrendRetrieveRequest;
    rr.share_type = shareType;
    rr.from_date = fromDate;
    rr.to_date = toDate;
    rr.campaign_id = campaignId;
    rr.time_period = timePeriod;
    rr.level = level;

    let res = await this.appService.getCDTxnTrendXy(rr).toPromise();
    this.transTrend = res;
    // console.log('getTransTrend', this.transTrend);
    this.getChartTransTrends(this.transTrend['data'], this.transTrend['x'], this.transTrend['y'], this.startDate, this.endDate);
  }

  getChartTransTrends(data, x, y, sdate, edate) {
    this.isloadingTTrends = false;
    am4core.ready(function() {

      let chart = am4core.create('chart1', am4charts.XYChart);

      // Add data
      chart.data = data;

      // Set input format for the dates
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = y;
      series.dataFields.dateX = x;
      series.tooltipText = '{' + y + '}';
      series.strokeWidth = 2;
      series.minBulletDistance = 15;

      // Drop-shaped tooltips
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.strokeOpacity = 0;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.label.minWidth = 40;
      series.tooltip.label.minHeight = 40;
      series.tooltip.label.textAlign = 'middle';
      series.tooltip.label.textValign = 'middle';

      // Make bullets grow on hover
      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color('#fff');

      let bullethover = bullet.states.create('hover');
      bullethover.properties.scale = 1.3;

      // Make a panning cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'panXY';
      chart.cursor.xAxis = dateAxis;
      chart.cursor.snapToSeries = series;

      chart.events.on('ready', function() {
        dateAxis.zoomToDates(
          new Date(sdate.slice(0, 4), sdate.slice(6, 7), sdate.slice(8, 10)),
          new Date(edate.slice(0, 4), edate.slice(6, 7), edate.slice(8, 10))
        );
      });

    });

  }

  async getDispBehav(shareType, fromDate, toDate, campaignId, timePeriod, level, level_name) {
    this.isloadingDBehav = true;
    let rr = new CDTxnTrendRetrieveLevelNameRequest;
    rr.share_type = shareType;
    rr.from_date = fromDate;
    rr.to_date = toDate;
    rr.campaign_id = campaignId;
    rr.time_period = timePeriod;
    rr.level = level;
    rr.level_name = level_name;

    let res = await this.appService.getCDTxnTrendWchart(rr).toPromise();
    this.dispBehav = res;
    // console.log('getDispBehav', this.dispBehav);
    this.getChartDispBehav(this.dispBehav['data'], this.dispBehav['x'], this.dispBehav['y']);
  }

  getChartDispBehav(data, value, category) {
    let chart = am4core.create('chart2', am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = data;
    chart.radius = am4core.percent(70);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = value;
    series.dataFields.category = category;

    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = true;
    series.slices.template.inert = true;
    series.alignLabels = false;

    series.labels['truncate'] = true;
    series.labels.template.maxWidth = 130;
    series.labels.template.wrap = true;
    series.labels.template.fontSize = 9.5;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    // chart.legend = new am4charts.Legend();
  }

  async getTops(shareType, fromDate, toDate, campaignId) {
    this.isLoadingTop = true;
    let rr = new CDTopsRetrieveRequest;
    rr.share_type = shareType;
    rr.from_date = fromDate;
    rr.to_date = toDate;
    rr.campaign_id = campaignId;
    await this.appService.getCDTops(rr).subscribe(res => {

      this.topParticipants = {
        'top_displayers': [],
        'top_observers': [],
        'top_participants': []
      };
      for (var _i = 3; _i >= 0; _i--) {
        if (res['top_displayers'][_i]) {
          this.topParticipants['top_displayers'].push({
            count: res['top_displayers'][_i].count,
            emp_id: res['top_displayers'][_i].emp_id,
            emp_name: res['top_displayers'][_i].emp_name,
            profile_pic: this.storageAccount + res['top_displayers'][_i].profile_pic
          });
        }
        if (res['top_observers'][_i]) {
          this.topParticipants['top_observers'].push({
            count: res['top_observers'][_i].count,
            emp_id: res['top_observers'][_i].emp_id,
            emp_name: res['top_observers'][_i].emp_name,
            profile_pic: this.storageAccount + res['top_observers'][_i].profile_pic
          });
        }
        if (res['top_participants'][_i]) {
          this.topParticipants['top_participants'].push({
            count: res['top_participants'][_i].count,
            emp_id: res['top_participants'][_i].emp_id,
            emp_name: res['top_participants'][_i].emp_name,
            profile_pic: this.storageAccount + res['top_participants'][_i].profile_pic
          });
        }
      }
      // console.log('getTops', this.topParticipants);
      this.isLoadingTop = false;
      this.getChartTopDisp(this.topParticipants['top_displayers'], 'count', 'emp_name', 'profile_pic');
      this.getChartTopObservers(this.topParticipants['top_observers'], 'count', 'emp_name', 'profile_pic');
      this.getChartTopParticipants(this.topParticipants['top_participants'], 'count', 'emp_name', 'profile_pic');
    });
  }

  getChartTopDisp(data, steps, name, href) {
    let chart = am4core.create('chart3', am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 40;

    chart.data = data;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = name;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dx = -40;
    categoryAxis.renderer.minWidth = 120;
    categoryAxis.renderer.tooltip.dx = -40;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.fillOpacity = 0.3;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.baseGrid.strokeOpacity = 0;
    valueAxis.renderer.labels.template.dy = 20;

    let series = chart.series.push(new am4charts.ColumnSeries);
    series.dataFields.valueX = steps;
    series.dataFields.categoryY = name;
    series.tooltipText = '{valueX.value}';
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.dy = -30;
    series.columnsContainer.zIndex = 100;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;

    let columnTemplate = series.columns.template;
    columnTemplate.height = am4core.percent(30);
    columnTemplate.maxHeight = 30;
    columnTemplate.column.cornerRadius(60, 10, 60, 10);
    columnTemplate.strokeOpacity = 0;

    series.heatRules.push({
      target: columnTemplate,
      property: 'fill',
      dataField: 'valueX',
      min: am4core.color('#e5dc36'),
      max: am4core.color('#5faa46')
    });
    series.mainContainer.mask = undefined;

    let cursor = new am4charts.XYCursor();
    chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = 'none';

    let bullet = columnTemplate.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 20;
    bullet.valign = 'middle';
    bullet.align = 'left';
    bullet.isMeasured = true;
    bullet.interactionsEnabled = false;
    bullet.horizontalCenter = 'right';
    bullet.interactionsEnabled = false;

    let hoverState = bullet.states.create('hover');
    let outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add('radius', function(radius, target) {
      let circleBullet = target.parent;
      return circleBullet['circle'].pixelRadius + 10;
    });

    let image = bullet.createChild(am4core.Image);
    image.width = 40;
    image.height = 40;
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = href;

    image.adapter.add('mask', function(mask, target) {
      let circleBullet = target.parent;
      return circleBullet['circle'];
    });

    let previousBullet;
    chart.cursor.events.on('cursorpositionchanged', function(event) {
      let dataItem = series.tooltipDataItem;

      if (dataItem['column']) {
        let bullet = dataItem['column'].children.getIndex(1);

        if (previousBullet && previousBullet != bullet) {
          previousBullet.isHover = false;
        }

        if (previousBullet != bullet) {

          let hs = bullet.states.getKey('hover');
          hs.properties.dx = dataItem['column'].pixelWidth;
          bullet.isHover = true;

          previousBullet = bullet;
        }
      }
    });
  }

  getChartTopObservers(data, steps, name, href) {
    let chart = am4core.create('chart4', am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 40;

    chart.data = data;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = name;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dx = -40;
    categoryAxis.renderer.minWidth = 120;
    categoryAxis.renderer.tooltip.dx = -40;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.fillOpacity = 0.3;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.baseGrid.strokeOpacity = 0;
    valueAxis.renderer.labels.template.dy = 20;

    let series = chart.series.push(new am4charts.ColumnSeries);
    series.dataFields.valueX = steps;
    series.dataFields.categoryY = name;
    series.tooltipText = '{valueX.value}';
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.dy = -30;
    series.columnsContainer.zIndex = 100;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;

    let columnTemplate = series.columns.template;
    columnTemplate.height = am4core.percent(30);
    columnTemplate.maxHeight = 30;
    columnTemplate.column.cornerRadius(60, 10, 60, 10);
    columnTemplate.strokeOpacity = 0;

    series.heatRules.push({
      target: columnTemplate,
      property: 'fill',
      dataField: 'valueX',
      min: am4core.color('#e5dc36'),
      max: am4core.color('#5faa46')
    });
    series.mainContainer.mask = undefined;

    let cursor = new am4charts.XYCursor();
    chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = 'none';

    let bullet = columnTemplate.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 20;
    bullet.valign = 'middle';
    bullet.align = 'left';
    bullet.isMeasured = true;
    bullet.interactionsEnabled = false;
    bullet.horizontalCenter = 'right';
    bullet.interactionsEnabled = false;

    let hoverState = bullet.states.create('hover');
    let outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add('radius', function(radius, target) {
      let circleBullet = target.parent;
      return circleBullet['circle'].pixelRadius + 10;
    });

    let image = bullet.createChild(am4core.Image);
    image.width = 40;
    image.height = 40;
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = href;

    image.adapter.add('mask', function(mask, target) {
      let circleBullet = target.parent;
      return circleBullet['circle'];
    });

    let previousBullet;
    chart.cursor.events.on('cursorpositionchanged', function(event) {
      let dataItem = series.tooltipDataItem;

      if (dataItem['column']) {
        let bullet = dataItem['column'].children.getIndex(1);

        if (previousBullet && previousBullet != bullet) {
          previousBullet.isHover = false;
        }

        if (previousBullet != bullet) {

          let hs = bullet.states.getKey('hover');
          hs.properties.dx = dataItem['column'].pixelWidth;
          bullet.isHover = true;

          previousBullet = bullet;
        }
      }
    });
  }

  getChartTopParticipants(data, steps, name, href) {
    let chart = am4core.create('chart5', am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 40;

    chart.data = data;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = name;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dx = -40;
    categoryAxis.renderer.minWidth = 120;
    categoryAxis.renderer.tooltip.dx = -40;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.fillOpacity = 0.3;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.baseGrid.strokeOpacity = 0;
    valueAxis.renderer.labels.template.dy = 20;

    let series = chart.series.push(new am4charts.ColumnSeries);
    series.dataFields.valueX = steps;
    series.dataFields.categoryY = name;
    series.tooltipText = '{valueX.value}';
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.dy = -30;
    series.columnsContainer.zIndex = 100;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;

    let columnTemplate = series.columns.template;
    columnTemplate.height = am4core.percent(30);
    columnTemplate.maxHeight = 30;
    columnTemplate.column.cornerRadius(60, 10, 60, 10);
    columnTemplate.strokeOpacity = 0;

    series.heatRules.push({
      target: columnTemplate,
      property: 'fill',
      dataField: 'valueX',
      min: am4core.color('#e5dc36'),
      max: am4core.color('#5faa46')
    });
    series.mainContainer.mask = undefined;

    let cursor = new am4charts.XYCursor();
    chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = 'none';

    let bullet = columnTemplate.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 20;
    bullet.valign = 'middle';
    bullet.align = 'left';
    bullet.isMeasured = true;
    bullet.interactionsEnabled = false;
    bullet.horizontalCenter = 'right';
    bullet.interactionsEnabled = false;

    let hoverState = bullet.states.create('hover');
    let outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add('radius', function(radius, target) {
      let circleBullet = target.parent;
      return circleBullet['circle'].pixelRadius + 10;
    });

    let image = bullet.createChild(am4core.Image);
    image.width = 40;
    image.height = 40;
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = href;

    image.adapter.add('mask', function(mask, target) {
      let circleBullet = target.parent;
      return circleBullet['circle'];
    });

    let previousBullet;
    chart.cursor.events.on('cursorpositionchanged', function(event) {
      let dataItem = series.tooltipDataItem;

      if (dataItem['column']) {
        let bullet = dataItem['column'].children.getIndex(1);

        if (previousBullet && previousBullet != bullet) {
          previousBullet.isHover = false;
        }

        if (previousBullet != bullet) {

          let hs = bullet.states.getKey('hover');
          hs.properties.dx = dataItem['column'].pixelWidth;
          bullet.isHover = true;

          previousBullet = bullet;
        }
      }
    });
  }

  async getDeptWiseBehav(shareType, fromDate, toDate, campaignId, timePeriod, level) {
    this.isLoadingCatBehav = false;
    let rr = new CDTxnTrendRetrieveFilterRequest;
    rr.share_type = shareType;
    rr.from_date = fromDate;
    rr.to_date = toDate;
    rr.campaign_id = campaignId;
    rr.time_period = timePeriod;
    rr.level = level;
    rr.filter = this.selectedFilter['value'];

    let res = await this.appService.getCDTxnTrendDchart(rr).toPromise();
    this.catBehavs = res;
    // console.log('getDeptWiseBehav', this.catBehavs);
    this.getChartDeptWiseBehav(this.catBehavs['data'], this.selectedFilter['label']);
    // this.appService.getCDTxnTrendDchart(rr).subscribe(res => {
    //   console.log('getDeptWiseBehav', res);
    //   this.catBehavs = res;
    //   // this.getChartDeptWiseBehav(res['data'], this.);
    //   this.isLoadingCatBehav = true;
    // });
  }

  getChartDeptWiseBehav(d, pieLabel) {
    let data = d;

    let chart = am4core.create('chart6', am4core.Container);
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);
    chart.layout = 'horizontal';

    /**
     * Column chart
     */

      // Create chart instance
    let columnChart = chart.createChild(am4charts.XYChart);

    // Create axes
    let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

    // Create series
    let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = 'value';
    columnSeries.dataFields.categoryY = 'category';
    columnSeries.columns.template.strokeWidth = 0;

    let labelXY = columnChart.chartContainer.createChild(am4core.Label);
    labelXY.text = 'Displayed Behaviours';
    labelXY.align = 'center';
    labelXY.fontWeight = '500';
    labelXY.fontSize = 20;

    /**
     * Pie chart
     */

      // Create chart instance
    let pieChart = chart.createChild(am4charts.PieChart);
    pieChart.data = data;
    pieChart.innerRadius = am4core.percent(50);

    let labelPie = pieChart.chartContainer.createChild(am4core.Label);
    labelPie.text = pieLabel;
    labelPie.align = 'center';
    labelPie.fontWeight = '500';
    labelPie.fontSize = 20;

    // Add and configure Series
    let pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.labels.template.disabled = true;

    // Set up labels
    let label1 = pieChart.seriesContainer.createChild(am4core.Label);
    label1.text = '';
    label1.horizontalCenter = 'middle';
    label1.fontSize = 35;
    label1.fontWeight = '600';
    label1.dy = -30;

    let label2 = pieChart.seriesContainer.createChild(am4core.Label);
    label2.text = '';
    label2.horizontalCenter = 'middle';
    label2.fontSize = 12;
    label2.dy = 20;

    // Auto-select first slice on load
    pieChart.events.on('ready', function(ev) {
      pieSeries.slices.getIndex(0).isActive = true;
    });

    // Set up toggling events
    pieSeries.slices.template.events.on('toggled', function(ev) {
      if (ev.target?.isActive) {

        // Untoggle other slices
        pieSeries.slices.each(function(slice) {
          if (slice != ev.target) {
            slice.isActive = false;
          }
        });

        // Update column chart
        columnSeries.appeared = false;
        columnChart.data = ev.target.dataItem.dataContext['breakdown'];
        columnSeries.fill = ev.target.fill;
        columnSeries.reinit();

        // Update labels
        label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, '#.\'%\'');
        label1.fill = ev.target.fill;

        label2.text = ev.target.dataItem['category'];
      }
    });
  }

  async getBehavDispByEmp(shareType, fromDate, toDate, campaignId, timePeriod, level) {
    this.isLoadingBehavEmp = true;
    let rr = new CDTxnTrendRetrieveRequest;
    rr.share_type = shareType;
    rr.from_date = fromDate;
    rr.to_date = toDate;
    rr.campaign_id = campaignId;
    rr.time_period = timePeriod;
    rr.level = level;

    let res = await this.appService.getCDTxnTrendBchart(rr).toPromise();
    this.behavEmp = res;
    // console.log('getBehavDispByEmp', this.behavEmp);
    this.getChartBehavDispByEmp(this.behavEmp['data']);
  }

  getChartBehavDispByEmp(data) {
    let chart = am4core.create('chart7', am4plugins_forceDirected.ForceDirectedTree);
    chart.legend = new am4charts.Legend();
    let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    networkSeries.data = data;
    networkSeries.dataFields.linkWith = 'linkWith';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'name';
    networkSeries.dataFields.value = 'value';
    networkSeries.dataFields.children = 'children';
    networkSeries.nodes.template.tooltipText = '{name}';
    networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.nodes.template.label.text = '{name}';
    networkSeries.fontSize = 8;
    networkSeries.maxLevels = 2;
    networkSeries.maxRadius = am4core.percent(6);
    networkSeries.manyBodyStrength = -16;
    networkSeries.nodes.template.label.hideOversized = true;
    networkSeries.nodes.template.label.truncate = true;
  }

  checkedChoice(group) {
    this.groupingList.forEach(element => {
      if (element['value'] == group.value) {
        element['is_select'] = true;
      } else {
        element['is_select'] = false;
      }
    });
  }

  handleGroupCancel() {
    this.isGroupingModalOpen = false;
  }

  handleGroupOk() {
    this.isGroupingModalOpen = false;
    this.groupingList.forEach(element => {
      if (element['is_select'] == true) {
        this.selectedFilter = element;
        this.getDeptWiseBehav(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length);
      }
    });
  }

  openGroupingModal() {
    this.isGroupingModalOpen = true;
  }

  async levelChange() {
    // console.log('level', this.level);
    // console.log(this.campaignDetail.level_names[this.level]);
    await this.getDispBehav(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length, this.campaignDetail.level_names[this.level]);
  }

}
