import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';
import {debounceTime, switchMap, map} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ProjectRetrieveRequest, Activity, CalenderActivitiesRetrieveRequest} from '../../../app.models';
import {DatePipe} from '@angular/common';
import {AppService} from 'src/app/app.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, ViewChild} from '@angular/core';
import {CalendarOptions} from '@fullcalendar/angular'; // useful for typechecking
import {EmployeeInitiativeRetrieveRequest, EmployeeRetrieveRequest} from 'src/app/app.models';
import {BehaviorSubject} from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  date = Date;
  action = null;
  public calendarEl: HTMLElement;
  isFormLoading = false;
  isProjectFormLoading = false;
  isVisible = false;
  activityForm: FormGroup;
  searchForm: FormGroup;
  projectForm: FormGroup;
  initiativeOptionList: any;
  EmployeeInitiativesList: any;
  EmployeeProjectList = [];
  startDate: string;
  endDate: string;
  activityId: number;
  loggedInUserDetail: any;
  activityList: any;
  isMilestoneChecked: boolean = null;
  public eventlist: boolean = false;
  public editActivityDetails: any;
  public selectedInitiativeObj: any;
  public selectedInitiative: number;
  public selectedEmpIds = null;
  isVisibleProjectModal = false;
  public popupTitle: string = 'Create';
  public popupBtnText: string = 'Save';
  // calendarOptions: CalendarOptions = {
  //   headerToolbar: {
  //     center: 'dayGridMonth,timeGridFourDay' // buttons for switching between views
  //   }
  // };
  calendarOptions: CalendarOptions = {
    customButtons: {
      myCustomButton: {
        text: 'Activity',
        click: this.customButton.bind(this)
        // click: function() {
        //   this.eventlist == !this.eventList;
        // }
      }
    },
    fixedWeekCount: false,
    initialView: 'listWeek',
    // weekends=false,
    // defaultView='dayGridMonth',
    // themeSystem: 'standart',
    navLinks: true,
    editable: true,
    eventDurationEditable: true,
    // eventAdd: true,

    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,dayGridDay,list',
      // right: 'dayGridMonth,dayGridWeek,dayGridDay,list,myCustomButton',
    },
    // validRange: {
    //   start: '2019-12-1',
    //   end: '2020-12-31'
    // },
    // plugins="calendarPlugins",
    // initialView: 'dayGridMonth',

    dateClick: this.handleDateClick.bind(this), // bind is important!
    eventClick: this.handleEventClick.bind(this),
    // eventClick: function(arg){
    //   console.log(arg) ;
    //   this.newtitle= arg.event.title;
    // },
    //  events:[]
    // events: [
    //   { title: 'event 1', date: 'Sat, 10 Dec 2020 02:49:09 GMT', color: 'red', allDay: false },
    //   { title: 'event 2', date: '2020-12-12', color: 'blue', allDay: false }
    // ]
    // events: 'https://fullcalendar.io/demo-events.json'
  };
  listOfNoOfOption = [
    {label: 'FY-2020-21', value: 1},
    {label: 'FY-2021-22', value: 2},
    {label: 'FY-2022-23', value: 3},
  ];
  listOfProjectOfOption = [
    {label: 'Project 1', value: 1},
    {label: 'Project 2', value: 2},
    {label: 'Project 3', value: 3},
  ];
  // state required for the auto complete
  empOptionList = [];
  empOptionListSearchChange$ = new BehaviorSubject('');
  isEmpOptionListLoading = false;

  constructor(private fb: FormBuilder,
              private appService: AppService,
              private message: NzMessageService,
              private modal: NzModalService,
              private datepipe: DatePipe) {
  }

  //date picker
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;


  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
    // console.log('handleStartOpenChange', open);
  }

  handleEndOpenChange(open: boolean): void {
    // console.log('handleEndOpenChange', open);
  }

  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    this.resetCreateModal();
    this.getActivityList();
    this.getListOfEmps();
    this.initSearchForm();
    this.initProjectForm();
  }

  customButton(t) {
    this.eventlist = !this.eventlist;
  }

  // add event btn
  handleDateClick(arg) {
    // alert('date click! ' + arg.dateStr);
    let testTitle = arg.titleStr;
    let testStart = arg.dateStr;
    // console.log(arg);
    // this.activityForm.get("title").setValue('testtitle');
    // this.activityForm.get("start_date").setValue('teststart');
    this.activityForm.reset();
    this.isVisible = true;
    this.action = 'create';
    this.activityForm.patchValue({
      title: testTitle,
      start_date: testStart,
    });
    // this.isVisible = true;
    //   eventClick:function(arg){
    // this.isVisible = true;
    // this.activityForm.get("title").setValue(arg.event.title);
    // this.activityForm.get("start_date").setValue(arg.event.start);
    //    alert(arg.event.title)
    //    alert(arg.event.start)
    //    this.testValue= arg.event.title;
    //  },
  }

  // modal action
  showModal(): void {
    this.activityForm.reset();
    this.action = 'create';
    this.isVisible = true;
    this.onProjectChange();
  }

  async showEditModal() {
    this.isVisible = true;
    await this.activityForm.get('emp_initiative_id').setValue(this.editActivityDetails.emp_initiative_id);
    // this.selectedInitiative = this.editActivityDetails.emp_initiative_id;
    this.onChange(this.editActivityDetails.start_date, 'sd');
    this.onInitiativeChange();
    // console.log('edit', this.editActivityDetails);
    this.activityId = this.editActivityDetails.id;
    this.action = 'edit';
    // await this.onProjectChange();
    await this.activityForm.patchValue(this.editActivityDetails);
    let milestone = this.editActivityDetails.is_milestone == 'yes' ? true : false;
    await this.activityForm.get('is_milestone').setValue(milestone);
    //  let sd= this.datepipe.transform(this.selectedInitiativeObj.start_date, "yyyy-MM-dd", "IST");
    //  await this.activityForm.get('start_date').setValue(sd);
    await this.onProjectChange();
  }

  submitForm(): void {
    for (const i in this.activityForm.controls) {
      this.activityForm.controls[i].markAsDirty();
      this.activityForm.controls[i].updateValueAndValidity();
    }
  }

  resetCreateModal(): void {
    this.activityForm = this.fb.group({
      emp_id: [null],
      emp_initiative_id: [null, [Validators.required]],
      title: [null, [Validators.required]],
      description: [null],
      start_date: [null, [Validators.required]],
      end_date: [null, [Validators.required]],
      is_complete: [null],
      uom: [{value: null, disabled: true}],
      target_achieved: [null],
      // target_achieved: new FormControl(null),
      is_milestone: [null],
      // is_milestone: [{value: null, disabled: true}],
      project_id: [null],
    });
  }

  initSearchForm() {
    this.searchForm = this.fb.group({
      emp_ids: [null, [Validators.required]],
    });
  }

  handleModalSubmit(): void {
    // this.isVisible = false;
    this.submitForm();
    this.isFormLoading = true;

    // Create Activity on the server.
    if (this.activityForm.status === 'VALID') {

      let startDate = this.datepipe.transform(this.activityForm.value.start_date, 'yyyy-MM-dd  HH:mm:ss', 'GMT');
      let endDate = this.datepipe.transform(this.activityForm.value.end_date, 'yyyy-MM-dd HH:mm:ss', 'GMT');

      let data = {
        'emp_id': this.loggedInUserDetail.employee.id,
        'title': this.activityForm.value.title,
        'start_date': startDate,
        'end_date': endDate,
        'emp_initiative_id': this.activityForm.value.emp_initiative_id,
        'description': this.activityForm.value.description,
        'is_milestone': this.activityForm.value.is_milestone ? 'yes' : 'no',
        'project_id': this.activityForm.value.project_id,
        'target_achieved': this.activityForm.value.target_achieved,
        'uom': this.activityForm.value.uom,
      };
      // console.log('activityForm', data);

      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createActivities(data);
        message = 'Activity "' + this.activityForm.controls['title'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateActivities(data, this.activityId);
        message = 'Activity "' + this.activityForm.controls['title'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        // get All Activity
        this.getActivityList();
        // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);
        // this.getProjectDetails();
      }, error => {
        this.isVisible = false;
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }

  }

  handleModalCancel(): void {
    this.isVisible = false;
  }

  // on start date changes
  onChange(date: string, type: string): void {
    type == 'sd' ? this.startDate = date : this.endDate = date;
    this.startDate = this.datepipe.transform(this.startDate, 'yyyy-MM-dd');
    this.endDate = this.datepipe.transform(this.endDate, 'yyyy-MM-dd');
    // console.log('onChange: ', this.endDate, this.startDate);
    if (this.startDate !== null) {
      this.getActivitiesByDateRange();
      this.getEmployeeProjectList();
    }
  }

  async getActivitiesByDateRange() {
    let rr = new EmployeeInitiativeRetrieveRequest();
    rr.emp_id = this.loggedInUserDetail?.employee?.id;
    rr.start_date = this.startDate;
    // rr.end_date = this.startDate; // need to sent same startDate here in endDate
    rr.sort_order = 'asc';
    await this.appService.getEmployeeInitiatives(rr).subscribe((res) => {
      this.EmployeeInitiativesList = res['items'];
      // console.log('res', res);
    });
  }

  async getEmployeeProjectList() {
    let rr = new ProjectRetrieveRequest();
    // rr.is_complete = 'no';
    rr.emp_ids = [this.loggedInUserDetail.employee.id];
    await this.appService.getProjects(rr).subscribe((res) => {
      this.EmployeeProjectList = res['items'];
      // console.log('res', res);
    });
  }

  // /api/activities/
  getActivityList() {
    let rr = new CalenderActivitiesRetrieveRequest();
    if (this.selectedEmpIds != null) {
      rr.emp_ids = [this.selectedEmpIds],
        rr.per_page = null;
    } else {
      rr.emp_ids = [this.loggedInUserDetail.employee.id];
      rr.per_page = null;
    }

    this.appService.getCalenderActivitiesByGroup(rr).subscribe((res) => {
      this.activityList = res['items'];
      // console.log('actual data', this.activityList);
      let events = [];
      this.activityList.forEach(element => {
        events.push(
          {
            title: element.title,
            date: this.datepipe.transform(element.start_date, 'yyyy-MM-dd HH:mm:ss'),
            // this.datePipe.transform(this.time_log_start, "yyyy-MM-dd HH:mm:ss", "IST")
            color: element.initiative_colour,
            id: element.id,
            activityData: element,
            allDay: false,
            isChecked: element.is_milestone == 'no' ? false : true,
          },
        );
      });
      this.calendarOptions.events = events;
      // console.log('calendarOptions', this.calendarOptions.events);
    });
  }

  async onInitiativeChange() {
    // this.activityForm.get('uom').setValue(this.EmployeeInitiativesList[0].uom);
    this.selectedInitiative = this.activityForm.get('emp_initiative_id').value;
    if (this.selectedInitiative != null) {
      await this.appService.getEmployeeInitiativesByEmpId(this.selectedInitiative).subscribe(res => {
        this.selectedInitiativeObj = res;
        // console.log('selectedInitiativeObj', this.selectedInitiativeObj);
        this.activityForm.get('uom').setValue(this.selectedInitiativeObj.uom);
      });
    }
  }

  handleEventClick(arg) {
    this.editActivityDetails = arg.event._def.extendedProps.activityData;
    // console.log("editActivityDetails:", arg.event._def.extendedProps.activityData);
    this.showEditModal();
  }

  onMilestoneChange() {
    let isChecked = this.activityForm.get('is_milestone').value;
    let isProjectSelected = this.activityForm.get('project_id').value;
    // console.log(isChecked, isProjectSelected);
    if (isChecked == true && isProjectSelected === null) {
      this.isMilestoneChecked = true;
    } else {
      this.isMilestoneChecked = false;
    }
  }

  onProjectChange() {
    this.onMilestoneChange();
  }

  public remove(id) {
    this.modal.confirm({
      nzTitle: 'Confirm Delete',
      nzContent: 'Are you sure, you want to delete?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          let subscriber = null;

          subscriber = this.appService.deleteActivity(this.activityId);
          subscriber.subscribe(res => {

            this.message.create('success', 'Record Deleted', {
              nzDuration: 5000
            });
            this.isVisible = false;
            this.getActivityList();
            resolve(res);
          }, error => {


            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });

            reject();
          });

        }).catch(() => console.log('Oops errors!'))
    });
  }

  // Search employee related
  onEmpOptionListSearch(value: string): void {
    this.isEmpOptionListLoading = true;
    this.empOptionListSearchChange$.next(value);
  }

  getListOfEmps() {
    // tslint:disable:no-any
    const optionList = () => {
      this.isEmpOptionListLoading = true;
      let rr = new EmployeeRetrieveRequest();
      rr.reporting_manager = this.loggedInUserDetail.employee.id;
      return this.appService.getEmployees(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };
    const optionList$ = this.empOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.empOptionList = data;
      // console.log('EmployeeList:', this.empOptionList);
      this.isEmpOptionListLoading = false;
    });
  }

  submitSearchForm() {
    this.selectedEmpIds = this.searchForm.get('emp_ids').value;
    this.getActivityList();
  }

  clearSearchForm() {
    this.selectedEmpIds = null;
    this.searchForm.reset();
    this.getActivityList();
  }

  // create project related
  initProjectForm() {
    this.projectForm = this.fb.group({
      // emp_id
      project_name: [null],
      start_date: [null],
      expected_end_date: [null],
      actual_end_date: [null],
      approval_description: [null],
      is_complete: [null],
      status: [null],
      // emp_initiative_id: [null]
    });
  }

  submitProjectForm(): void {
    for (const i in this.projectForm.controls) {
      this.projectForm.controls[i].markAsDirty();
      this.projectForm.controls[i].updateValueAndValidity();
    }
  }

  showProjectModal() {
    this.isVisibleProjectModal = true;
  }

  handleProjectModalCancel() {
    this.isVisibleProjectModal = false;
  }

  handleProjectModalSubmit(): void {
    this.submitForm();
    this.isProjectFormLoading = true;
    // Create the business unit on the server.
    if (this.projectForm.status === 'VALID') {

      let startDate = this.datepipe.transform(this.projectForm.value.start_date, 'yyyy-MM-dd  HH:mm:ss');
      let expectedEndDate = this.datepipe.transform(this.projectForm.value.expected_end_date, 'yyyy-MM-dd HH:mm:ss');
      // let status;
      // if (this.projectForm.value.status === 1)
      //   status= 'Approved'
      //  else if (this.projectForm.value.status === 2)
      //   status= 'Rejected'
      //   else status= 'Approval Pending'
      let subscriber = null;
      let message = null;

      if (this.action === 'create') {
        let data = {
          'emp_id': this.loggedInUserDetail.employee.id,
          'project_name': this.projectForm.value.project_name,
          'start_date': startDate,
          'expected_end_date': expectedEndDate,
        };
        subscriber = this.appService.createProject(data);
        message = 'Project "' + this.projectForm.controls['project_name'].value + '" has been added.';
      }

      subscriber.subscribe(res => {
        this.isVisibleProjectModal = false;
        this.isProjectFormLoading = false;
        this.message.create('success', message, {
          nzDuration: 5000
        });

        // set the created project
        let createdProjectDetail = res;
        this.getEmployeeProjectList();
        this.activityForm.get('project_id').setValue(createdProjectDetail.id);

      }, error => {
        this.isVisibleProjectModal = false;
        this.isProjectFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }
}
