import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ThreeSixtyDegreeFeedbackList, ThreeSixtyDegreeFeedbackListRetrieveRequest} from 'src/app/app.models';
import {AppService} from 'src/app/app.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-premium-three-sixty-feedback',
  templateUrl: './premium-three-sixty-feedback.component.html',
  styleUrls: ['./premium-three-sixty-feedback.component.css']
})
export class PremiumThreeSixtyFeedbackComponent implements OnInit {
  public feedbackInvites = [];
  total = 1;
  loading = true;
  pageSize = 100;
  pageIndex = 1;
  loggedInUserDetail;
  feedbackListing: ThreeSixtyDegreeFeedbackList[];
  alertMessage: any;
  myZoneAndTime: any;

  constructor(private appService: AppService, private router: Router, private modal: NzModalService,private datePipe: DatePipe,) { }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.getMyTime()

    setTimeout(() => {
      this.getThreeSixtyListing();
    }, 2000);

  }


  getMyTime() {
    this.appService.getMyTime().subscribe(res => {
      this.myZoneAndTime = res;
      let date = this.datePipe.transform(this.myZoneAndTime.time, "yyyy-MM-dd")
    })
  }

  getThreeSixtyListing() {
    let rr = this.retriveRequest();
    this.appService.getThreeSixtyDegreeFeedbackListing(rr).subscribe(res => {
      this.loading = true;
      this.feedbackListing = res['items'];
      // console.log('FeedbackListing', this.feedbackListing);
      res['items'].forEach(element => {
        if (element['assessment_activity']) {
          if (element['assessment_activity']['activity_type'] === 'premium_360') {

            let listingData = {
              id: element.id,
              activity_title: element['assessment_activity']['activity_title'],
              assessment_title: element['assessment_activity']['assessment']['title'],
              start_date: element['assessment_activity']['start_date'],
              end_date: element['assessment_activity']['end_date'],
              participant: element['assessment_activity_attempt']['employee']['display_name'],
              stakeholder: this.loggedInUserDetail['employee']['display_name'],
              activity_attempt_id: element['assessment_activity_attempt_id'],
              activity_id: element['assessment_activity']['id'],
              assessment_attempt_id: element['assessment_activity_attempt']['assessment_attempt_id'],
              status: element['status'],
              feedback_as: element['feedback_as'],
              assessment_activity: element['assessment_activity']['assessment'],
              premium_360_multiple_attempt:element['premium_360_multiple_attempt']
            };
            this.feedbackInvites.push(listingData);
          }
        }
      });
      // console.log('Feedback invites', this.feedbackInvites);
      this.loading = false;
    }, error => {

    });

  }

  retriveRequest() {
    let rr = new ThreeSixtyDegreeFeedbackListRetrieveRequest();
    rr.feedback_given_by_id = this.loggedInUserDetail['employee']['id'];
    return rr;
  }

  onQueryParamsChange(event) {
    // console.log(event);
  }
  giveFeedback(invite){
    this.router.navigate(['learning-assessment/premium-360-attempt/' + invite.id], { queryParams: { 'activity-id': invite.activity_id, 'activity-attempt-id': invite.activity_attempt_id, 'assessment-attempt-id': invite.assessment_attempt_id } });
  }

    // start_date & end_date related validation for give feedback for stakeholders
    isActiveDateRange(sd, ed) {

      let date = this.datePipe.transform(this.myZoneAndTime.time, "yyyy-MM-dd HH:mm:ss")
      let startDate = this.datePipe.transform(sd, "yyyy-MM-dd HH:mm:ss")
      let endDate = this.datePipe.transform(ed, "yyyy-MM-dd HH:mm:ss")

      if (endDate >= date && startDate <= date) {
        return true;
      } else {
        return false;
      }
    }

    viewPremiumFeedback(invite){
      // console.log(invite)
      this.router.navigate(['learning-assessment/premium-three-sixty-feedback-view/' + invite.activity_attempt_id], {queryParams: { 'activity-id': invite.activity_id} });
    }

}
