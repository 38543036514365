import {AppService} from 'src/app/app.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { NotificationRetrieveRequest} from 'src/app/app.models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
interface ItemData {
  id: number;
  notification: string;
  percentage: number;
  time: string;
  end_rating: string;
  explanation: string;
}
@Component({
  selector: 'app-my-nudges',
  templateUrl: './my-nudges.component.html',
  styleUrls: ['./my-nudges.component.css']
})
export class MyNudgesComponent implements OnInit {

  constructor(private router: Router, private appService: AppService) { }
  tblData = [];
  listOfCurrentPageData: ReadonlyArray<ItemData> = [];
  loggedInUserDetail: any;
  loading = false;
  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.getNotification();
  }

  getNotification() {
    this.loading = true;
    let rr = new NotificationRetrieveRequest();
    rr.per_page = null;
    rr.app_sent = 'yes';
    rr.emp_id = [this.loggedInUserDetail.employee.id];
    this.appService.getNotification(rr).subscribe(res => {
      this.tblData = res['items'];
      this.tblData.forEach((element,i) => {
        element.slno = i+1;
      });
      this.loading = false;

      // this.notificationDetails[0] = {...this.notificationDetails[0], is_app_read: "no"}
      // this.isAnyNotificationUnRead = this.notificationDetails?.some(element => element['is_app_read'] == 'no');
    },err=>{
      this.loading = false;
    });
  }

  onCurrentPageDataChange($event: ReadonlyArray<ItemData>): void {
    this.listOfCurrentPageData = $event;
  }

}
