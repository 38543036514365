import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {AppService} from 'src/app/app.service';
import {array} from '@amcharts/amcharts4/core';
import {FormControl} from '@angular/forms';
import {UserActivityModel} from 'src/app/app.models';
import {Observable, of} from 'rxjs';
import {startWith, map} from 'rxjs/operators';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css']
})
export class ActivityLogComponent implements OnInit {
  activityData: Array<UserActivityModel> = [];
  activities: Observable<Array<any>>;
  pageSize = 100;
  pageIndex = 1;
  userId;
  userType;
  usersList = [];
  filterControl = new FormControl();
  inputValue: any;

  constructor(
    private appService: AppService
  ) {
  }

  ngOnInit(): void {
    this.fetchActivities();
    this.fetchUsersList();
    this.subscribeToFilterControlChanges();
  }

  fetchActivities() {
    this.userType = this.fetchCurrUserType();
    if (this.userType == 'tenant_admin') {
      this.fetchAllUserActivity();
    } else {
      this.fetchUserActivity();
    }
  }

  fetchCurrUserType() {
    if (sessionStorage.getItem("me")) {
      let userDetails = JSON.parse(decodeURIComponent(atob(sessionStorage.getItem("me"))));
      this.userId = userDetails.user_id;
      return userDetails.user_type;
    }
    return '';
  }

  fetchAllUserActivity() {
    this.appService.getAllActivity().subscribe((resp) => {
      if (resp) {
        this.activityData = resp;
      }
    });
  }

  fetchUserActivity() {
    this.appService.getUserActivity().subscribe((resp) => {
      if (resp) {
        this.activityData = resp;
        //  console.log("data", resp, this.activityData);
      }
    });
  }


  fetchSelectedUserActivity(num: number) {
    this.appService.getSelectedUserActivity(num).subscribe((resp) => {
      if (resp) {
        this.activityData = resp;
        //  console.log("data", resp, this.activityData);
      }
    });
  }

  subscribeToFilterControlChanges() {
    this.activities = this.filterControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string) {
    const filterValue = this._normalizeValue(value);
    return this.usersList?.filter(data => this._normalizeValue(data.user_name).includes(filterValue));
  }

  private _normalizeValue(value: string) {
    return value.toString().toLowerCase().replace(/\s/g, '');
  }

  onUserSelect(user) {
    // console.log(user)
    if (user.user_id) {
      this.fetchSelectedUserActivity(user.user_id);
    }
  }

  fetchUsersList() {
    this.appService.getUsersList().subscribe(resp => {
      if (resp) {
        this.usersList = resp;
      }
    })
  }

  onKeyUp(event) {
    if (event?.key == "Backspace" && this.filterControl?.value == "") {
      this.fetchActivities();
      this.closeAutoCompleteOptions();
    }
  }

  closeAutoCompleteOptions() {
    // this.autocomplete.closePanel();
  }

  optionSelectionChange(data) {
    this.onUserSelect(data);
  }

  onInput($event: Event) {

  }
}
