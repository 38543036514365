import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {environment} from 'src/environments/environment';
import {map, debounceTime, switchMap} from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  BusinessUnit,
  BusinessUnitRetrieveRequest,
  LocationRetrieveRequest,
  FunctionRetrieveRequest,
  DepartmentRetrieveRequest,
  Department,
  GradeRetrieveRequest,
  EmployeeRetrieveRequest,
  Grade,
  Employee,
} from 'src/app/app.models';
import {DatePipe} from '@angular/common';
import {AppService} from 'src/app/app.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {NzTabPosition, NzTabsCanDeactivateFn} from 'ng-zorro-antd/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {BehaviorSubject, Observable} from 'rxjs';
import {NzModalService} from 'ng-zorro-antd/modal';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  // storage account url
  storageAccount: String;
  // upload logo related
  file: any;
  loading = false;
  avatarUrl?: string;
  // fileList: NzUploadFile[] = [];
  // beforeUpload = (file: NzUploadFile): boolean => {
  //   this.fileList = this.fileList.concat(file);
  //   return false;
  // };
  employeeForm: FormGroup;
  pIForm: FormGroup;
  cPForm: FormGroup;
  oIForm: FormGroup;
  profileForm: FormGroup;
  pIIForm: FormGroup;
  nzTabPosition: NzTabPosition = 'left';
  selectedIndex = 0;
  roleOptionList: any;
  loggedInUserDetail: any;
  action = 'create';
  isFormLoading = false;
  isPiiStoreRequestLoading = false;
  employeeFormEditDetails: any;
  confirmedPass: boolean = true;

  // profile modal
  isProfileFormLoading = false;
  isVisibleProfileModal = false;
  piiDeleteButton = true;
  piiDownloadButton = true;
  piiOtpbox = false;
  piiStatus = false;
  piiStatusMsg: any;

  timeZoneOptionList = [
    {name: 'Kolkata', value: 'kol'},
    {name: 'berlin', value: 'ber'},
    {name: 'Uk', value: 'uk'},
    {name: 'USA', value: 'usa'},
  ];
  timeZoneForm!: FormGroup;
  dateFormat = 'yyyy';
  today = new Date();

  // mockOSSData = {
  //   dir: 'user-dir/',
  //   expire: '1577811661',
  //   host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
  //   accessId: 'c2hhb2RhaG9uZw==',
  //   policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
  //   signature: 'ZGFob25nc2hhbw=='
  // };

  // state required for the auto complete
  employeeOptionList: Employee[] = [];
  employeeOptionListSearchChange$ = new BehaviorSubject('');
  isEmployeeOptionListLoading = false;
  timeZones: any;
  userTimeZoneData: any;

  onEmployeeOptionListSearch(value: string): void {
    this.isEmployeeOptionListLoading = true;
    this.employeeOptionListSearchChange$.next(value);
  }

  functionOptionList: Function[] = [];
  functionOptionListSearchChange$ = new BehaviorSubject('');
  isFunctionOptionListLoading = false;

  onFunctionOptionListSearch(value: string): void {
    this.isFunctionOptionListLoading = true;
    this.functionOptionListSearchChange$.next(value);
  }

  departmentOptionList: Department[] = [];
  departmentOptionListSearchChange$ = new BehaviorSubject('');
  isDepartmentOptionListLoading = false;

  onDepartmentOptionListSearch(value: string): void {
    this.isDepartmentOptionListLoading = true;
    this.departmentOptionListSearchChange$.next(value);
  }

  locationOptionList: Location[] = [];
  locationOptionListSearchChange$ = new BehaviorSubject('');
  isLocationOptionListLoading = false;

  onLocationOptionListSearch(value: string): void {
    this.isLocationOptionListLoading = true;
    this.locationOptionListSearchChange$.next(value);
  }

  bUOptionList: BusinessUnit[] = [];
  businessUnitOptionListSearchChange$ = new BehaviorSubject('');
  isBusinessUnitOptionListLoading = false;

  onBusinessUnitOptionListSearch(value: string): void {
    this.isBusinessUnitOptionListLoading = true;
    this.businessUnitOptionListSearchChange$.next(value);
  }

  gradeOptionList: Grade[] = [];
  gradeOptionListSearchChange$ = new BehaviorSubject('');
  isGradeOptionListLoading = false;

  onGradeOptionListSearch(value: string): void {
    this.isGradeOptionListLoading = true;
    this.gradeOptionListSearchChange$.next(value);
  }

  constructor(
    public fb: FormBuilder,
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public message: NzMessageService,
    public datepipe: DatePipe,
    public router: Router,
    private modal: NzModalService
  ) {
  }

  ngOnInit(): void {
    // this.initEmployeeForm();
    this.initPiForm();
    this.initCpForm();
    this.initOiForm();
    this.initTimeZoneForm();
    this.initProfileForm();
    this.getBUs();
    this.getLocations();
    this.getFunctions();
    this.getGrades();
    this.getDepartments();
    this.getEmployees();
    this.getRoleList();
    this.getTimeZonedata();
    this.loggedInUserDetail = this.appService.getMe();
    // console.log('The looged in me : ', this.loggedInUserDetail);
    this.appService.getMyTime().subscribe(res => {
      // console.log('My Time IS : ', res);

    });
    this.editProfile();
    this.initpIIForm();
    this.getPIIPresentByEmployeeId();
    // this.activatedRoute.params.subscribe(params => {
    //   if (params['id']) {
    //     this.employeeId = params['id'];
    //     this.action = "edit";
    //     this.getEmployeeById(this.employeeId);
    //   }
    // });
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
  }

  ngAfterViewInit(): void {
    if (this.loggedInUserDetail === null) {
      // this.loggedInUserDetail = this.appService.getLoggedInUserDetail();
      this.appService.getLoggedInUserDetail().subscribe((res) => {
        this.loggedInUserDetail = res;
        this.getEmployeeById();
      });
    }

    this.cPForm.get('confirm_password').valueChanges.subscribe((value) => {
      this.confirmedPass = true;
    });
    //console.log("Loginnedu user",this.loggedInUserDetail)
  }

  initTimeZoneForm() {
    this.timeZoneForm = this.fb.group({
      time_zone: [null, [Validators.required]]
    });
  }

  initProfileForm() {
    this.profileForm = this.fb.group({
      profile_pic: [null, Validators.required],
    });
  }

  // Personal Information Form
  initPiForm() {
    this.pIForm = this.fb.group({
      first_name: [null, [Validators.required]],
      middle_name: [null],
      last_name: [null, [Validators.required]],
      display_name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      dob: [null],
      // employee_id: [null] //NA
      gender: [null, [Validators.required]],
    });
  }

  // Change Password Form
  initCpForm() {
    this.cPForm = this.fb.group({
      // username: [null, [Validators.required]],
      // password: [null, [Validators.required]],
      old_password: [null, [Validators.required, Validators.minLength(8)]],
      new_password: [null, [Validators.required, Validators.minLength(8)]],
      confirm_password: [null, [Validators.required, Validators.minLength(8)]],
    });
  }

  // Organization Information Form
  initOiForm() {
    this.oIForm = this.fb.group({
      joining_date: [null, [Validators.required]],
      grade_id: [null, [Validators.required]],
      department_id: [null, [Validators.required]],
      function_id: [null, [Validators.required]],
      location_id: [null, [Validators.required]],
      business_unit_id: [null, [Validators.required]],
      reporting_manager: [null],
      is_manager: [null],
      is_department_head: [null],
      is_active: [null],
      is_functional_head: [null],
      is_bu_head: [null],
      is_admin: [null],
      // profile_pic: [null],
      // username: [null, [Validators.required]],
      // about_me: [null], //NA
      role_id: [null],
      designation: [null, [Validators.required]],
    });
  }

  // need to remove
  initEmployeeForm() {
    this.employeeForm = this.fb.group({
      // first_name: [null, [Validators.required]],
      // middle_name: [null],
      // last_name: [null, [Validators.required]],
      // display_name: [null, [Validators.required]],
      // designation: [null, [Validators.required]],
      // dob: [null],
      // gender: [null, [Validators.required]],
      // joining_date: [null, [Validators.required]],
      // grade_id: [null, [Validators.required]],
      // department_id: [null, [Validators.required]],
      // function_id: [null, [Validators.required]],
      // location_id: [null, [Validators.required]],
      // business_unit_id: [null, [Validators.required]],
      // reporting_manager: [null],
      // is_manager: [null],
      // is_department_head: [null],
      // is_active: [null],
      // is_functional_head: [null],
      // is_bu_head: [null],
      // is_admin: [null],
      // profile_pic: [null],
      // username: [null, [Validators.required]],
      // password: [null],
      // email: [null, [Validators.required, Validators.email]],
      // about_me: [null],
      // role_id: [null]
    });
  }

  resetForm(): void {
    this.employeeForm.reset();
  }

  initpIIForm() {
    this.pIIForm = this.fb.group({
      otp: [null, [Validators.required, Validators.minLength(8)]],
    });
  }

  // Forms submit Related
  pIFormSubmit() {
    for (const i in this.pIForm.controls) {
      this.pIForm.controls[i].markAsDirty();
      this.pIForm.controls[i].updateValueAndValidity();
    }
    this.isFormLoading = true;
    // Create the on server.
    if (this.pIForm.status === 'VALID') {
      let dob = this.datepipe.transform(this.pIForm.value.dob, 'yyyy-MM-dd');
      let formData: any = new FormData();
      formData.append('first_name', this.pIForm.get('first_name').value);
      formData.append('last_name', this.pIForm.get('last_name').value);
      if (this.pIForm.get('middle_name').value){
        formData.append('middle_name', this.pIForm.get('middle_name').value);
      }
      formData.append('display_name', this.pIForm.get('display_name').value);
      formData.append('gender', this.pIForm.get('gender').value);
      formData.append('dob', dob);
      formData.append('email', this.pIForm.get('email').value);

      let subscriber = null;
      let message = null;
      if (this.action === 'edit') {
        subscriber = this.appService.updateEmployee(
          formData,
          this.loggedInUserDetail?.employee?.id
        );
        message =
          'Employee "' +
          this.pIForm.controls['display_name'].value +
          '" has been updated.';
      }

      subscriber.subscribe(
        (res) => {
          this.isFormLoading = false;

          this.message.create('success', message, {
            nzDuration: 5000,
          });
          // this.router.navigate(['/employee']);
          if (this.action === 'edit') {
            sessionStorage.setItem('isReloadGetMe', 'yes');
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        },
        (error) => {
          this.isFormLoading = false;

          // let errorMessage = environment.production? error.error.error: error.error.message;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        }
      );
    }
  }

  cPFormSubmit() {
    for (const i in this.cPForm.controls) {
      this.cPForm.controls[i].markAsDirty();
      this.cPForm.controls[i].updateValueAndValidity();
    }
    this.isFormLoading = true;
    // Create the location on the server.
    if (this.cPForm.status === 'VALID') {
      this.confirmedPass = true;
      if (
        this.cPForm.get('new_password').value !==
        this.cPForm.get('confirm_password').value
      ) {
        this.isFormLoading = false;
        this.confirmedPass = false;
        return;
      }
      let formData: any = new FormData();
      // formData.append("password", this.cPForm.get('password').value);
      formData.append('old_password', this.cPForm.get('old_password').value);
      formData.append('new_password', this.cPForm.get('new_password').value);
      formData.append(
        'confirm_password',
        this.cPForm.get('confirm_password').value
      );

      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateEmployee(
        formData,
        this.loggedInUserDetail?.employee?.id
      );
      message = 'Employee password has been updated.';

      subscriber.subscribe(
        (res) => {
          this.isFormLoading = false;

          this.message.create('success', message, {
            nzDuration: 5000,
          });
          // this.router.navigate(['/employee']);
        },
        (error) => {
          this.isFormLoading = false;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        }
      );
    }
  }

  oIFormSubmit() {
    for (const i in this.oIForm.controls) {
      this.oIForm.controls[i].markAsDirty();
      this.oIForm.controls[i].updateValueAndValidity();
    }
    this.isFormLoading = true;
    // Create the location on the server.
    if (this.oIForm.status === 'VALID') {
      let jd = this.datepipe.transform(
        this.oIForm.value.joining_date,
        'yyyy-MM-dd'
      );
      let formData: any = new FormData();
      formData.append('designation', this.oIForm.get('designation').value);
      formData.append('grade_id', this.oIForm.get('grade_id').value);
      formData.append('joining_date', jd);
      formData.append('department_id', this.oIForm.get('department_id').value);
      formData.append('function_id', this.oIForm.get('function_id').value);
      formData.append('location_id', this.oIForm.get('location_id').value);
      formData.append(
        'business_unit_id',
        this.oIForm.get('business_unit_id').value
      );
      if (this.oIForm.get('reporting_manager').value) {
        formData.append(
          'reporting_manager',
          this.oIForm.get('reporting_manager').value
        );
      }
      formData.append(
        'is_manager',
        this.oIForm.get('is_manager').value ? 'yes' : 'no'
      );
      formData.append(
        'is_department_head',
        this.oIForm.get('is_department_head').value ? 'yes' : 'no'
      );
      formData.append(
        'is_active',
        this.oIForm.get('is_active').value ? 'yes' : 'no'
      );
      formData.append(
        'is_functional_head',
        this.oIForm.get('is_functional_head').value ? 'yes' : 'no'
      );
      formData.append(
        'is_bu_head',
        this.oIForm.get('is_bu_head').value ? 'yes' : 'no'
      );
      formData.append(
        'is_admin',
        this.oIForm.get('is_admin').value ? 'yes' : 'no'
      );
      formData.append('about_me', this.oIForm.get('about_me').value);

      let subscriber = null;
      let message = null;
      if (this.action === 'edit') {
        subscriber = this.appService.updateEmployee(
          formData,
          this.loggedInUserDetail?.employee?.id
        );
        message =
          'Employee "' +
          this.oIForm.controls['display_name'].value +
          '" has been updated.';
      }

      subscriber.subscribe(
        (res) => {
          this.isFormLoading = false;

          this.message.create('success', message, {
            nzDuration: 5000,
          });
          // this.router.navigate(['/employee']);
        },
        (error) => {
          this.isFormLoading = false;

          //let errorMessage = environment.production ? error.error.error: error.error.message;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        }
      );
    }
  }

  getTimeZonedata() {
    const data = {
      country_id: [],
      timezone_code: []
    };
    this.appService.getTimeZones(data).subscribe(res => {
      this.timeZones = res['items'];
    });
  }

  submitTimeZoneForm() {
    this.isFormLoading = true;
    if (this.timeZoneForm.valid) {
      let subscriber = null;
      let message = null;
      const data = {
        user_id: this.loggedInUserDetail?.id,
        timezone_id: this.timeZoneForm.value.time_zone
      };

      if (!this.userTimeZoneData?.id) {
        subscriber = this.appService.createEmployeeTimeZone(data);
        message = 'Time Zone has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateEmployeeTimeZone(
          data,
          this.loggedInUserDetail.id
        );
        message = 'Time Zone has been updated.';
      }

      subscriber.subscribe(
        (res) => {
          // console.log('after update result : ', res);

          this.isFormLoading = false;
          this.message.create('success', message, {
            nzDuration: 5000,
          });
        },
        (error) => {
          this.isFormLoading = false;
          // let errorMessage = environment.production ? error.error.error: error.error.message;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        }
      );

    }
  }

  // common submit for all tabs
  submitForm() {
    for (const i in this.pIForm.controls) {
      this.pIForm.controls[i].markAsDirty();
      this.pIForm.controls[i].updateValueAndValidity();
    }
    this.isFormLoading = true;
    // Create the location on the server.
    if (this.pIForm.status === 'VALID') {
      let dob = this.datepipe.transform(this.pIForm.value.dob, 'yyyy-MM-dd');
      if (dob == null) {
        dob = '';
      }
      let jd = this.datepipe.transform(
        this.oIForm.value.joining_date,
        'yyyy-MM-dd'
      );
      let formData: any = new FormData();
      formData.append('first_name', this.pIForm.get('first_name').value);
      formData.append('last_name', this.pIForm.get('last_name').value);
      if (this.pIForm.get('middle_name').value){
        formData.append('middle_name', this.pIForm.get('middle_name').value);
      }
      formData.append('display_name', this.pIForm.get('display_name').value);
      formData.append('gender', this.pIForm.get('gender').value);
      formData.append('dob', dob);
      formData.append('email', this.pIForm.get('email').value);

      // formData.append("password", this.cPForm.get('password').value);
      // New Password Form
      formData.append('old_password', this.cPForm.get('old_password').value);
      formData.append('new_password', this.cPForm.get('new_password').value);
      formData.append(
        'confirm_password',
        this.cPForm.get('confirm_password').value
      );

      formData.append('designation', this.oIForm.get('designation').value);
      formData.append('grade_id', this.oIForm.get('grade_id').value);
      formData.append('joining_date', jd);
      formData.append('department_id', this.oIForm.get('department_id').value);
      formData.append('function_id', this.oIForm.get('function_id').value);
      formData.append('location_id', this.oIForm.get('location_id').value);
      formData.append(
        'business_unit_id',
        this.oIForm.get('business_unit_id').value
      );
      if (this.oIForm.get('reporting_manager').value) {
        formData.append(
          'reporting_manager',
          this.oIForm.get('reporting_manager').value
        );
      }
      formData.append(
        'is_manager',
        this.oIForm.get('is_manager').value ? 'yes' : 'no'
      );
      formData.append(
        'is_department_head',
        this.oIForm.get('is_department_head').value ? 'yes' : 'no'
      );
      formData.append(
        'is_active',
        this.oIForm.get('is_active').value ? 'yes' : 'no'
      );
      formData.append(
        'is_functional_head',
        this.oIForm.get('is_functional_head').value ? 'yes' : 'no'
      );
      formData.append(
        'is_bu_head',
        this.oIForm.get('is_bu_head').value ? 'yes' : 'no'
      );
      formData.append(
        'is_admin',
        this.oIForm.get('is_admin').value ? 'yes' : 'no'
      );
      formData.append('about_me', this.oIForm.get('about_me').value);
      // formData.append("password", this.oIForm.get('password').value);
      formData.append('username', this.oIForm.get('username').value);
      formData.append('role_id', this.oIForm.get('role_id').value);


      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createEmployee(formData);
        message =
          'Employee "' +
          this.pIForm.controls['display_name'].value +
          '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateEmployee(
          formData,
          this.loggedInUserDetail.employee.id
        );
        message =
          'Employee "' +
          this.pIForm.controls['display_name'].value +
          '" has been updated.';
      }

      subscriber.subscribe(
        (res) => {
          this.isFormLoading = false;

          this.message.create('success', message, {
            nzDuration: 5000,
          });
          // this.router.navigate(['/employee']);
          if (this.action === 'edit') {
            sessionStorage.setItem('isReloadGetMe', 'yes');
            window.location.reload();
          }
        },
        (error) => {
          this.isFormLoading = false;

          //let errorMessage = environment.production? error.error.error: error.error.message;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        }
      );
    }
  }

  pIIFormSubmit() {
    this.isFormLoading = true;
    if (this.pIIForm.status === 'VALID') {

      // console.log(this.pIIForm.get('otp').value);
      this.appService.verifyPiiOtp(this.pIIForm.get('otp').value).subscribe((res) => {
          // console.log('Verification Result', res);
          if (res['status'] == 'otp_correct') {
            this.piiStatus = true;
            this.piiDeleteButton = false;
            this.piiDownloadButton = false;
            this.piiOtpbox = false;
            this.piiStatusMsg = 'Waiting for Admin Approval';
            this.isFormLoading = false;
          }
        },
        (error) => {
          if (error.error.status == 'otp_expired' || error.error.status == 'otp_wrong') {
            this.isFormLoading = false;
          }
          {
            Swal.fire('', error.error.message, 'warning');
            this.piiStatus = false;
            this.piiDeleteButton = false;
            this.piiDownloadButton = false;
            this.piiOtpbox = true;
          }
        }
      );

    }
  }

  editProfile() {
    this.action = 'edit';
    this.getEmployeeById();
    this.pIForm.enable();
    this.cPForm.enable();
    this.oIForm.disable();
  }

  // profile Modal related
  profileModalHandleCancel() {
    this.isVisibleProfileModal = false;
  }

  showProfileModal() {
    this.isVisibleProfileModal = true;
    this.profileForm.reset();
    this.avatarUrl = this.storageAccount + this.employeeFormEditDetails?.profile_pic?.original_image_url;
  }

  handleModalSubmit() {
    for (const i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsDirty();
      this.profileForm.controls[i].updateValueAndValidity();
    }
    this.isProfileFormLoading = true;
    // Create the on server.
    // if (this.profileForm.status === 'VALID') {
    let formData: any = new FormData();
    //image upload code
    //  this.fileList.forEach((file: any) => {
    //   formData.append('profile_pic', file);
    // });
    formData.append('profile_pic', this.file);
    let subscriber = null;
    let message = null;
    subscriber = this.appService.updateEmployee(
      formData,
      this.loggedInUserDetail?.employee?.id
    );
    message = 'Employee profile picture has been updated.';

    subscriber.subscribe(
      (res) => {
        this.isProfileFormLoading = false;
        this.message.create('success', message, {
          nzDuration: 5000,
        });
        this.isVisibleProfileModal = false;
        this.getEmployeeById();
      },
      (error) => {
        this.isProfileFormLoading = false;
        this.isVisibleProfileModal = false;

        //let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      }
    );
    // }
  }

  // getlist of DropDowns
  setDisplayName() {
    let displayName =
      this.pIForm.get('first_name').value +
      ' ' +
      this.pIForm.get('last_name').value;
    this.pIForm.get('display_name').setValue(displayName);
  }

  getEmployeeById() {
    let id = this.loggedInUserDetail?.employee?.id;
    this.appService.getEmployeeById(id).subscribe((result: any) => {
      // console.log('Thegbet employee for edit result : ', result);

      this.employeeFormEditDetails = result;
      this.pIForm.patchValue(result);
      this.cPForm.patchValue(result);
      this.oIForm.patchValue(result);
      // let fullName= result.first_name +' '+ result.last_name;
      // this.cPForm.get('username').setValue(result.user.username);
      // this.cPForm.get('password').setValue(result.password);

      this.pIForm.get('email').setValue(result.user.email);
      // this.employeeForm.get('about_me').setValue(result.user.about_me);
      this.pIForm.get('display_name').setValue(result.display_name);
      this.oIForm.get('is_manager').setValue(result.is_manager == 'yes');
      this.oIForm
        .get('is_department_head')
        .setValue(result.is_department_head == 'yes');
      this.oIForm.get('is_active').setValue(result.is_active == 'yes');
      this.oIForm
        .get('is_functional_head')
        .setValue(result.is_functional_head == 'yes');
      this.oIForm.get('is_bu_head').setValue(result.is_bu_head == 'yes');
      this.oIForm.get('is_admin').setValue(result.is_admin == 'yes');
      this.avatarUrl = this.storageAccount + result?.profile_pic?.original_image_url;


      // Api call for getting time zone of a user
      this.appService.getEmployeeTimeZoneById(this.loggedInUserDetail?.id).subscribe((res: any) => {
        // console.log('employee Zone is : ', res);

        this.userTimeZoneData = res;
        this.timeZoneForm.setValue({time_zone: res.timezone_id});
      });
      // Ends
    });
  }

  getBUs() {
    const optionList = (functionName: string) => {
      this.isBusinessUnitOptionListLoading = true;
      let rr = new BusinessUnitRetrieveRequest(functionName);
      rr.per_page = null;
      return this.appService.getBusinessUnits(rr).pipe(
        map((res: any) => {
          return res['items'];
        })
      );
    };
    const optionList$: Observable<BusinessUnit[]> =
      this.businessUnitOptionListSearchChange$
        .asObservable()
        .pipe(debounceTime(500))
        .pipe(switchMap(optionList));
    optionList$.subscribe((data) => {
      this.bUOptionList = data;
      this.isBusinessUnitOptionListLoading = false;
    });
  }

  getLocations() {
    const optionList = (functionName: string) => {
      this.isLocationOptionListLoading = true;
      let rr = new LocationRetrieveRequest(functionName);
      rr.per_page = null;
      rr.business_unit_ids = [this.oIForm.get('business_unit_id').value];
      return this.appService.getLocations(rr).pipe(
        map((res: any) => {
          return res['items'];
        })
      );
    };
    const optionList$: Observable<Location[]> =
      this.locationOptionListSearchChange$
        .asObservable()
        .pipe(debounceTime(500))
        .pipe(switchMap(optionList));
    optionList$.subscribe((data) => {
      this.locationOptionList = data;
      this.isLocationOptionListLoading = false;
    });
  }

  getFunctions() {
    const optionList = (functionName: string) => {
      this.isFunctionOptionListLoading = true;
      let rr = new FunctionRetrieveRequest(functionName);
      rr.per_page = null;
      rr.business_unit_ids = [this.oIForm.get('business_unit_id').value];
      return this.appService.getFunctions(rr).pipe(
        map((res: any) => {
          return res['items'];
        })
      );
    };

    const optionList$: Observable<Function[]> =
      this.functionOptionListSearchChange$
        .asObservable()
        .pipe(debounceTime(500))
        .pipe(switchMap(optionList));
    optionList$.subscribe((data) => {
      this.functionOptionList = data;
      this.isFunctionOptionListLoading = false;
    });
  }

  getDepartments() {
    const optionList = (functionName: string) => {
      this.isDepartmentOptionListLoading = true;
      let rr = new DepartmentRetrieveRequest(functionName);
      rr.per_page = null;
      rr.function_ids = [this.oIForm.get('function_id').value];
      return this.appService.getDepartments(rr).pipe(
        map((res: any) => {
          return res['items'];
        })
      );
    };
    const optionList$: Observable<Department[]> =
      this.departmentOptionListSearchChange$
        .asObservable()
        .pipe(debounceTime(500))
        .pipe(switchMap(optionList));
    optionList$.subscribe((data) => {
      this.departmentOptionList = data;
      this.isDepartmentOptionListLoading = false;
    });
  }

  getGrades() {
    const optionList = (functionName: string) => {
      this.isGradeOptionListLoading = true;
      let rr = new GradeRetrieveRequest(functionName);
      rr.per_page = null;
      rr.business_unit_ids = [this.oIForm.get('business_unit_id').value];
      return this.appService.getGrades(rr).pipe(
        map((res: any) => {
          return res['items'];
        })
      );
    };
    const optionList$: Observable<Grade[]> = this.gradeOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe((data) => {
      this.gradeOptionList = data;
      this.isGradeOptionListLoading = false;
    });
  }

  getEmployees() {
    const optionList = (functionName: string) => {
      this.isEmployeeOptionListLoading = true;
      let rr = new EmployeeRetrieveRequest(functionName);
      rr.per_page = null;
      return this.appService.getEmployees(rr).pipe(
        map((res: any) => {
          return res['items'];
        })
      );
    };

    const optionList$: Observable<Employee[]> =
      this.employeeOptionListSearchChange$
        .asObservable()
        .pipe(debounceTime(500))
        .pipe(switchMap(optionList));
    optionList$.subscribe((data) => {
      this.employeeOptionList = data;
      this.isEmployeeOptionListLoading = false;
    });
  }

  // RoleOptionList
  getRoleList() {
    this.appService.getRoles().subscribe((res) => {
      this.roleOptionList = res['items'];
    });
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
    this.file = file;
    // return false;
    return true;
    // return new Observable((observer: Observer<boolean>) => {
    //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //   if (!isJpgOrPng) {
    //     this.msg.error('You can only upload JPG file!');
    //     observer.complete();
    //     return;
    //   }
    //   const isLt2M = file.size! / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.msg.error('Image must smaller than 2MB!');
    //     observer.complete();
    //     return;
    //   }
    //   observer.next(isJpgOrPng && isLt2M);
    //   observer.complete();
    // });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }): void {
    this.getBase64(info.file!.originFileObj!, (img: string) => {
      this.loading = false;
      this.avatarUrl = img;
    });
  }

  getPIIPresentByEmployeeId() {
    this.appService.getEmployeePiiData().subscribe((res) => {
      // console.log('Employee PII DATA', res);
      if (res[0]['is_deactivated'] == 'yes') {
        this.piiStatusMsg = 'Account Deactivated by Admin';
        this.piiStatus = true;
        this.piiDeleteButton = false;
        this.piiDownloadButton = false;
        this.piiOtpbox = false;
      } else if (res[0]['is_deleted'] == 'yes') {
        this.piiStatusMsg = 'Your Data is Anonymous Now';
        this.piiStatus = true;
        this.piiDeleteButton = false;
        this.piiDownloadButton = false;
        this.piiOtpbox = false;
      } else if (res[0]['admin_approved'] == 'requested') {
        //this.piiStatusMsg='Waiting for Admin Approval'
        //this.piiStatus=true;
        this.piiDeleteButton = true;
        this.piiDownloadButton = true;
        this.piiOtpbox = false;
      } else if (res[0]['admin_approved'] == 'otp_verified') {
        this.piiStatusMsg = 'Waiting for Admin Approval';
        this.piiStatus = true;
        this.piiDeleteButton = false;
        this.piiDownloadButton = false;
        this.piiOtpbox = false;
      }
    });
  }

  piiStoreRequest() {
    this.isPiiStoreRequestLoading = true;
    this.appService.storePiiData().subscribe((res) => {
      // console.log(res);
      this.piiRequestOtp();
      this.isPiiStoreRequestLoading = false;
    });
  }

  piiRequestOtp() {
    Swal.fire('', 'OTP Sent to email successfully', 'success');
    this.piiDeleteButton = false;
    this.piiOtpbox = true;
    this.piiDownloadButton = false;
    this.appService.getPiiOtp().subscribe((res) => {
      // console.log('OTP GET', res);
    });

  }

  public downloadPiiData(): void {
    html2canvas(document.getElementById('contentToConvert')).then(function(canvas) {
      canvas.getContext('2d');
      var HTML_Width = canvas.width;
      var HTML_Height = canvas.height;
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      // console.log(canvas.height + '  ' + canvas.width);


      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage();
        let margin = -(PDF_Height * i) + (top_left_margin * 4);
        if (i > 1) {
          margin = margin + i * 8;
        }
        // console.log(top_left_margin);
        // console.log(top_left_margin);
        // console.log(-(PDF_Height * i) + (top_left_margin * 4));
        pdf.addImage(imgData, 'JPG', top_left_margin, margin, canvas_image_width, canvas_image_height);

      }
      pdf.save('HTML-Document.pdf');
    });
  }

  resetProfileImage() {
    this.avatarUrl = '';
    this.profileForm.reset();
  }

  canDeactivate: NzTabsCanDeactivateFn = (fromIndex: number, toIndex: number) => {
    return this.confirm(fromIndex);
    // switch (fromIndex) {
    //   // case 0:
    //   //   return toIndex === 1;
    //   // case 1:
    //   //   return Promise.resolve(toIndex === 2);
    //   // case 2:
    //   //   return this.confirm();
    //   default:
    // }
  };

  public confirm(fromIndex): Observable<boolean> {
    return new Observable(observer => {
      this.modal.confirm({
        nzTitle: 'Are you sure you want to leave this tab?',
        nzOnOk: () => {
          switch (fromIndex) {
            case 0:
              this.getEmployeeById();
              break;
            case 1:
              this.cPForm.reset();
              break;
            case 2:
              this.getEmployeeById();
              break;
            case 3:
              this.getEmployeeById();
              break;
            case 4:
              this.getEmployeeById();
              break;
            default:
              break;
          }
          observer.next(true);
          observer.complete();
        },
        nzOnCancel: () => {
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  differenceInCalendarDays(current, this.today) > 0;
  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
}
