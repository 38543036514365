<!-- <p>tags works!</p> -->
<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
                <nz-modal
              [(nzVisible)]="isFormVisible"
              nzTitle="{{action | titlecase}} Tags"
              (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleModalSubmit()"
              nzOkText="{{btnName}}"
              nzOkType="primary"
              [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!tagsForm.valid"
            >
              <form
                nz-form
                [nzLayout]="'vertical'"
                [formGroup]="tagsForm"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="name" nzNoColon="false"
                    >Tag Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please input name!">
                      <input
                        formControlName="tag_name"
                        nz-input
                        nzSize="large"
                        placeholder="Tags Name"
                        noWhitespace OnlyCharacter
                      />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired nzFor="name" nzNoColon="false"
                      >Tag description</nz-form-label>
                    <nz-form-control nzErrorTip="Please input name!">
                        <textarea nz-input placeholder="Tag description" formControlName="description" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired nzFor="name" nzNoColon="false"
                      >Tag Status</nz-form-label>
                    <nz-form-control nzErrorTip="Please input name!">
                        <nz-radio-group formControlName="status">
                            <label nz-radio nzValue="Active">Active</label>
                            <label nz-radio nzValue="Inactive">Inactive</label>
                          </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
              </form>
            </nz-modal>
            <h3 class="py-4 text-center">Tags</h3>
            <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
            <nz-table
            nzBordered
            nzShowSizeChanger
            [nzFrontPagination]="false"
            [nzLoading]="loading"
            [nzTotal]="total"
            [nzPageSize]="pageSize"
            [nzPageIndex]="pageIndex"
            (nzQueryParams)="onQueryParamsChange($event)"
            [nzData]="tagsData"
          >
            <thead>
              <tr>
                <!-- sorting disabled  -->
                <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                <th nzColumnKey="name" [nzSortFn]="true">Name</th> -->
                
                <th>Tag Name</th>
                <th>Tag description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tag of tags">
                
                <td>{{ tag.tag_name }}</td>
                <td>{{ tag.description }}</td>
                <td>{{ tag.status }}</td>
                <td>
                  <a (click)="showEditModal(tag)"><i class="ri-pencil-fill grayIcon" ></i></a>
                  <nz-divider nzType="vertical"> | </nz-divider>
                  <a (click)="remove(tag)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                </td>
              </tr>
            </tbody>
          </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>