<div id="content-page" class="content-page">
  <div class="container">
    <!-- <div class="nz-demo-typography-title">
        <h3 nz-typography>List of Annual Priorities</h3></div> -->
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
            <h3 class="py-4">List of Employees</h3>
            <!-- <div class="row my-4">
                <div class="col-md-4">
                  <h3 class="text-left plusIcon">
                    <i nz-icon class="mb-4" routerLink="employee-detail" nzType="plus-circle" nzTheme="fill"></i></h3>
                    <button nz-button nzType="primary" (click)="importModal()">
                      <span>Import</span>
                    </button>
                  </div>
                  <div class="col-md-4"></div>
              </div> -->
            <nz-modal [(nzVisible)]="isFormVisible" nzTitle="Bulk {{modalUploadTitle}}" (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
              nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

              <h4 style="font-weight: 500;">Instructions</h4>
              <ul>
                <li>
                  Copy relevant data from other templates (BU < location etc) and fill the sheet accurately to avoid any
                    upload errors </li>
                <li>
                  Dates are entered in (yyyy-mm-dd)
                </li>
              </ul>

              <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
                class="mr-3">
                Sample Excel Download
              </button>
              <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
              </nz-upload>
              <!-- <button
                nz-button
                [nzLimit]=1
                [nzFileType]="csv"
                [nzType]="'primary'"
                [nzLoading]="fileUploading"
                (click)="handleUpload()"
                [disabled]="fileList.length == 0"
                style="margin-top: 16px"
              >
                {{ fileUploading ? 'fileUploading' : 'Start Upload' }}
              </button> -->
            </nz-modal>

            <!-- Employe Modal -->
            <nz-modal nzWidth="900px" [(nzVisible)]="iEmployeeModalOpen" nzClosable="true" nzMaskClosable="false"
              nzTitle="Select Employees" (nzOnCancel)="handleEmployeeCancel()" (nzOnOk)="handleEmployeeOk()"
              nzOkText="Send">
              <ng-container *ngIf="!isEmployeeLoading">
              <app-employee-popup [employees]="notSendedEmployees" [getAllemployees]="notSendedEmployees"  [IsSelectOne]="IsSelectOne" (setEmployeesForLA)=setEmployeesForLA($event)></app-employee-popup>
              <!-- Heyooo -->
              </ng-container >
            </nz-modal>
            <!-- Employee Modal Ends Here -->
            <div class="d-flex justify-content-between align-items-center">



              <a class="add-btn" routerLink="employee-detail" title="Create New"><i class="ri-add-circle-fill"></i></a>

                <!-- <div>
                <input nzSize="small" type="text" nz-input placeholder="Search Display Name" [(ngModel)]="searchValue" /></div>
               <div style="margin-left:2%;"> <a  (click)="searchServer()" ><i nz-icon style="font-size: 20px;" nzType="search" nz-tooltip nzTooltipTitle="Search Employee"></i></a>
                <button nz-button nzSize="small" (click)="reset()">Reset</button></div> &nbsp;
           -->

                 <div style="display: flex;
    width: 68%;
    align-items: center;justify-content:end">
                  <button *ngIf="!task_id" style="margin-right: 10px" nz-button nzType="primary" (click)="download_excel_employee()">
                    <span>Employee Data Dump  </span>
                  </button>
                  <div *ngIf="task_id" class="mt-2 mr-1">
                    {{percent}} %
                  </div>
                  <div *ngIf="task_id" style="width:30%; margin-right:10px">
                    <nz-progress
                    [nzShowInfo]="false"
                    [nzPercent]="percent"
                    [nzStrokeColor]="{ '0%': '#7030A0', '100%': '#CE3A79' }"
                    nzSize="small" [nzStatus]="'active'"
                    class="progress-bar-custom2">
                  </nz-progress>
                  </div>
                  
                   <button nz-button nzType="primary" (click)="showImportModal('Update')">
                     <span>Update</span>
                   </button>


                   <button style="margin-left: 10px" nz-button nzType="primary" (click)="showImportModal('Import')">
                     <span>Import</span>
                   </button>
                &nbsp;
                <button nz-button nzType="default" (click)="openEmployeeModal()">
                  <span>Welcome Email</span>
                </button>
              </div>
            </div>



            <nz-table #selectionTable  nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"
              [nzData]="listOfData" [nzScroll]="{ x: '1500px' }">
              <!-- <nz-table #selectionTable nzShowSizeChanger [nzData]="listOfData" [nzLoading]="loading" -->
              <!-- (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" [nzScroll]="{ x: '1500px' }"> -->
              <thead>
                <tr>

                  <th nzLeft style="width: 100px; min-width: 100px;">Profile</th>
                  <th class="searchfilter" nzLeft nzCustomFilter [nzSortFn]="sortDisplayNameFn" [nzFilterMultiple]="multiKey">
                    <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue?.length > 0" [nzDropdownMenu]="menu">
                      <i nz-icon nzType="search" nz-tooltip nzTooltipTitle="Search Employee"></i>
                    </nz-filter-trigger>
                    Display Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                  <th class="searchfilter" [nzSortFn]="sortEmailFn" nzCustomFilter [nzFilterMultiple]="multiKey">Email</th>
                  <th [nzSortFn]="sortEmpIdFn">Emp-ID</th>
                  <th [nzSortFn]="sortBSFn">Business Unit</th>
                  <th [nzSortFn]="sortFunFn">Function</th>
                  <th [nzSortFn]="sortLocFn">Location</th>
                  <th [nzSortFn]="sortDeptFn">Department</th>
                  <th [nzSortFn]="sortRoleFn">Role</th>
                  <th [nzSortFn]="sortGradeFn">Grade</th>
                  <th nzRight style="width: 100px; min-width: 100px;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let emp of selectionTable.data">
                  <td nzLeft style="width: 100px; min-width: 100px;">
                    <!-- {{ emp?.profile_pic?.original_image_url}} -->
                    <!-- <nz-avatar  nzSize="large" nzIcon="user" [nzSrc]="'ImageUrlSanitizer(emp?.profile_pic?.original_image_url)'"></nz-avatar> -->
                    <!-- <img [src]="emp?.profile_pic?.thumb_image_url_1" class="img-fluid rounded-circle mr-3 " alt="test" srcset=""> -->
                    <nz-avatar *ngIf="emp?.profile_pic?.thumb_image_url_1" [nzSize]="64"
                      [nzSrc]="storageAccount + emp?.profile_pic?.thumb_image_url_1"></nz-avatar>
                    <nz-avatar *ngIf="!emp?.profile_pic?.thumb_image_url_1" [nzSize]="64" nzIcon="user"></nz-avatar>

                  </td>
                  <td nzLeft>{{emp.display_name}}</td>
                  <td>{{emp.first_name}}</td>
                  <td>{{emp.last_name}}</td>
                  <td>{{emp.email}}</td>
                  <td>{{emp.employee_id}}</td>
                  <td>{{emp?.business_unit?emp?.business_unit:"--"}}</td>
                  <td>{{emp.function?emp.function:"--"}}</td>
                  <td>{{emp.location?emp.location:"--"}}</td>
                  <td>{{emp.department ? emp.department : "--"}}</td>
                  <td>{{emp.role ? emp.role : "--"}}</td>
                  <td>{{emp.grade?emp.grade:"--"}}</td>
                  <!-- <td>{{ emp.id }}</td>
                    <td>{{ emp.first_name }}</td>
                    <td>{{ emp.middle_name }}</td>
                    <td>{{ emp.last_name }}</td>
                    <td>{{ emp.display_name }}</td>
                    <td>{{ emp.designation }}</td>
                    <td>{{ emp.dob }}</td>
                    <td>
                      <a [routerLink]="['/grade']" [queryParams]="{id: emp.grade_id}">{{ emp.grade.grade_name }}</a>
                    </td>
                    <td>{{ emp.user_id }}</td> -->
                  <td nzRight style="width: 100px; min-width: 100px;">
                    <a class="mr-2" [routerLink]="'employee-detail/'+emp.id"> <i
                        class="ri-pencil-fill grayIcon"></i></a>
                    <a (click)="remove(emp)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                  </td>

                </tr>
              </tbody>
            </nz-table>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <div class="ant-table-filter-dropdown">
                <div class="search-box">
                  <input type="text" nz-input placeholder="Search Display Name" [(ngModel)]="searchValue" />
                  <button nz-button nzSize="small" nzType="primary" (click)="searchServer()" class="search-button">Search</button>
                  <button nz-button nzSize="small" (click)="reset()">Reset</button>
                </div>
              </div>
            </nz-dropdown-menu>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner *ngIf="isEmployeeLoading" bdColor="rgb(0,0,0)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Please Wait... Employee List Loading {{pPage}}/{{pTotal}} </p>
</ngx-spinner>
