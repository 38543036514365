import { Component, OnInit } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Ticket, TicketRetrieveRequest, ReferenceDataRetrieveRequest, ReferenceData } from 'src/app/app.models';

@Component({
  selector: 'app-tickets-dashboard',
  templateUrl: './tickets-dashboard.component.html',
  styleUrls: ['./tickets-dashboard.component.css']
})
export class TicketsDashboardComponent implements OnInit {
  today = new Date();
  SeverityList = [{value:'Low',name:'Low'},{value:'Medium',name:'Medium'},{value:'High',name:'High'}];
  PriorityList = [{value:'Low',name:'Low'},{value:'Medium',name:'Medium'},{value:'High',name:'High'}];
  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;
  constructor(private appService: AppService,
    private spinner: NgxSpinnerService,
    private datePipe :DatePipe,
    public router: Router, private fb: FormBuilder,
  ) { }

  filterForm: FormGroup;
  popupForm: FormGroup;
  refrence_data
  loggedInUserDetail
  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();

    // ReferenceData start
    let rr = new ReferenceDataRetrieveRequest()
    rr.status_type = null;
    rr.per_page = null;
    this.appService.getReferenceData(rr).subscribe(data => {
      this.refrence_data = data['items'];
    });
    // ReferenceData End
    this.filterForm = this.fb.group({
      from_to_date: [null, [Validators.required]],
    });
    this.popupForm = this.fb.group({
      status_id: [null],
      priority_ids: [null],
      module_ids: [null],
      severity_ids: [null],
      resolution_code_id: [null],
      root_cause_code_id: [null],
    });
    this.getTicketPieChart()
  }

  contentIndex = 0;
  printedChart: boolean = false;
  tabChange(no) {
    this.contentIndex = no;
    if (this.contentIndex == 1) {
      this.printedChart = false;
    }
  }

  back() {
    this.router.navigate(['tickets']);
  }

  getTicketPieChart() {
    this.spinner.show();
    this.appService.getTicketPieChart().subscribe((res) => {
      // console.log('ress', res)
      res.forEach(item => {
        item.category = item.status+' '+'('+item.count+')';
        item.value = item.count
      });
      this.spinner.hide();
      let root = am5.Root.new("chartdiv");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          endAngle: 270
        })
      );

      // Create series
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
      let series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: "value",
          categoryField: "category",
          endAngle: 270
        })
      );

      series.states.create("hidden", {
        endAngle: -90
      });

      // Set data
      // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
      series.data.setAll(res);

      series.appear(1000, 100);
    },err=>{
      this.spinner.hide();
    });
  }
  barChartView = false;
  barChart
  getTicketBarChart() {
    this.spinner.show();
    let payload = this.filter;
    this.appService.getTicketBarChart(payload).subscribe((res:any) => {
      this.barChart = res
      if(res['length'] !=0){
      this.barChartView = true;
      setTimeout(() => {
        res.forEach(element => {
            element.value = element.ticket_count
          });
        /* Chart code */
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        let root1 = am5.Root.new("chartdiv1");
  
  
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root1.setThemes([
          am5themes_Animated.new(root1)
        ]);
  
  
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart1 = root1.container.children.push(am5xy.XYChart.new(root1, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX"
        }));
  
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart1.set("cursor", am5xy.XYCursor.new(root1, {}));
        cursor.lineY.set("visible", false);
  
  
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xRenderer = am5xy.AxisRendererX.new(root1, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
          rotation: -90,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 15
        });
  
        let xAxis = chart1.xAxes.push(am5xy.CategoryAxis.new(root1, {
          maxDeviation: 0.3,
          categoryField: "date",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root1, {})
        }));
  
        let yAxis = chart1.yAxes.push(am5xy.ValueAxis.new(root1, {
          maxDeviation: 0.3,
          renderer: am5xy.AxisRendererY.new(root1, {})
        }));

        xAxis.children.push(
          am5.Label.new(root1, {
            text: "Date Range",
            fontSize:16,
            x: am5.p50,
            centerX:am5.p50,
          })
        );
        yAxis.children.unshift(
          am5.Label.new(root1, {
            rotation: -90,
            fontSize:16,
            text: "No of Tickets",
            y: am5.p50,
            centerX: am5.p50
          })
        );
  
        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series1 = chart1.series.push(am5xy.ColumnSeries.new(root1, {
          name: "Series 1",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          sequencedInterpolation: true,
          categoryXField: "date",
          tooltip: am5.Tooltip.new(root1, {
            labelText: "{valueY}"
          })
        }));
  
        series1.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
        series1.columns.template.adapters.add("fill", function (fill, target: any) {
          return chart1.get("colors").getIndex(series1.columns.indexOf(target));
        });
  
        series1.columns.template.adapters.add("stroke", function (stroke, target: any) {
          return chart1.get("colors").getIndex(series1.columns.indexOf(target));
        });
  
  
        // Set data
        let data = this.barChart;
  
        xAxis.data.setAll(data);
        series1.data.setAll(data);
  
  
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series1.appear(1000);
        chart1.appear(1000, 100);
        this.spinner.hide();
        return () => {root1.dispose();};
      },0);
      }else{
          this.spinner.hide();
        }
    },err=>{
      this.spinner.hide();
    });
  }
  filter
  searchTickets() {
    this.barChart = [];
    if(this.filterForm.valid){
      this.filter = { "start_date": this.datePipe.transform(this.filterForm.value?.from_to_date[0], 'yyyy-MM-dd'+' '+'00:00:00'), 
                    "end_date": this.datePipe.transform(this.filterForm.value?.from_to_date[1], 'yyyy-MM-dd'+' '+'23:59:00'), 
                    "status_id": [this.popupForm.value.status_id]?[this.popupForm.value.status_id]:[], 
                    "priority_ids": [this.popupForm.value.priority_ids]?[this.popupForm.value.priority_ids]:[],
                    "module_ids": [this.popupForm.value.module_ids]?[this.popupForm.value.module_ids]:[], 
                    "severity_ids": [this.popupForm.value.severity_ids]?[this.popupForm.value.severity_ids]:[], 
                    "resolution_code_id": [this.popupForm.value.resolution_code_id]?[this.popupForm.value.resolution_code_id]:[], 
                    "root_cause_code_id": [this.popupForm.value.root_cause_code_id]?[this.popupForm.value.root_cause_code_id]:[] 
                  }
    // console.log(this.filter)

    this.getTicketBarChart()
    }else{
      this.spinner.hide();
      Swal.fire({
        icon: 'warning',
        text: "Please select start and end date!"
      });
      return;
    }
    
  }
  filterModalOpen = false;
  ModalOpen(){
    this.filterModalOpen = true;
  }
  handleFilterCancel(){
    this.filterModalOpen = false;
    this.popupForm.reset();
  }
  filterApply(){
    if(this.filterForm.valid){
      this.filterModalOpen = false;
      this.searchTickets();
      this.popupForm.reset();
    }else{
      Swal.fire({
        icon: 'warning',
        text: "Please select start and end date!"
      });
      return;
    }
  }

}
