<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <!-- new tab design start -->
      <div class="col-sm-12 col-lg-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div nz-col [nzSpan]="24" >
              <a class="back-btn" nz-button nzType="link" (click)="back()">
                  <i class="ri-arrow-go-back-fill" ></i>
              </a>
          </div>
            <form nz-form [nzLayout]="'vertical'" [formGroup]="employeeForm" (ngSubmit)="submitForm()">
              <div class="row">
                <div class="col-md-6">
                  <div class="mx-4">
                    <h4 class="my-3">Empoyee Details</h4>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="name">First Name
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter First Name!">
                        <input nz-input (blur)="setDisplayName()" formControlName="first_name" nzSize="large"
                          placeholder="First Name" id="first_name" OnlyCharacter/>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="Middle Name">Middle Name
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Middle Name!">
                        <input nz-input formControlName="middle_name" nzSize="large" placeholder="Enter Middle Name"
                          id="middle_name" OnlyCharacter/>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Last Name">Last Name
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Last Name!">
                        <input (blur)="setDisplayName()" nz-input formControlName="last_name" nzSize="large"
                          placeholder="Enter Last Name" id="last_name" OnlyCharacter/>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Display Name">Display Name
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Display Name!">
                        <input nz-input formControlName="display_name" nzSize="large" placeholder="Enter Display Name"
                          id="display_name" OnlyCharacter (keypress)="alphaNumberOnly($event)"/>
                      </nz-form-control>
                    </nz-form-item>


                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Employee ID">Employee ID
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Enter Employee ID!">
                        <input nz-input formControlName="employee_id" nzSize="large" placeholder="Enter Employee ID"
                          id="employee_id" OnlySpcl/>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Designation">Designation
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Enter Designation!">
                        <input nz-input formControlName="designation" nzSize="large" placeholder="Enter Designation"
                          id="designation" OnlyCharacter/>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="website">Birth of Year
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please input DOB!">
                        <nz-date-picker nzSize="large" nzMode="year" formControlName="dob" id="dob"
                          [nzFormat]="dateFormat" [nzDisabledDate]="disabledDate">
                        </nz-date-picker>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="Gender" nzRequired>Gender
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Gender!">
                        <nz-select nzSize="large" formControlName="gender">
                          <nz-option nzValue="Male" nzLabel="Male"></nz-option>
                          <nz-option nzValue="Female" nzLabel="Female"></nz-option>
                          <nz-option nzValue="Not Disclosed" nzLabel="Not Disclosed"></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="joining_date" nzRequired>Joining Date
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please input Joining Date!">
                        <nz-date-picker nzSize="large" formControlName="joining_date" id="joining_date"
                          [nzFormat]="joinDateFormat" #endDatePicker [nzDisabledDate]="disabledEndDate"
                          (nzOnOpenChange)="handleEndOpenChange($event)" [nzDisabledDate]="disabledDate"
                          >
                        </nz-date-picker>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="Business Unit Id" nzRequired>Business Unit
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Business Unit!">
                        <!-- <nz-select  nzSize="large" nzAllowClear
                                                    nzPlaceHolder="Select Business Unit"
                                                    formControlName="business_unit_id">
                                                    <nz-option *ngFor="let item of bUOptionList"
                                                        [nzLabel]="item.name"
                                                        [nzValue]="item.id"></nz-option>
                                                </nz-select> -->
                        <nz-select placeholder="Select Business Unit" nzSize="large" nzAllowClear nzShowSearch
                          nzServerSearch (ngModelChange)="onBUChange($event)" formControlName="business_unit_id"
                          (nzOnSearch)="onBusinessUnitOptionListSearch($event)"
                          (nzBlur)="getLocations(); getFunctions(); getGrades()">
                          <ng-container *ngFor="let o of bUOptionList">
                            <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                            </nz-option>
                          </ng-container>
                          <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="location Id" nzRequired>Location
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Location!">
                        <!-- <nz-select  nzSize="large" nzAllowClear
                                                    nzPlaceHolder="Select Location"
                                                    formControlName="location_id">
                                                    <nz-option *ngFor="let item of locationOptionList"
                                                        [nzLabel]="item.name"
                                                        [nzValue]="item.id"></nz-option>
                                                </nz-select> -->

                        <nz-select placeholder="Select Location" nzSize="large" nzAllowClear nzShowSearch nzServerSearch
                          (ngModelChange)="onLocationChange($event)"
                          [nzDisabled]="!employeeForm['controls'].business_unit_id.value" formControlName="location_id"
                          (nzOnSearch)="onLocationOptionListSearch($event)">
                          <ng-container *ngFor="let o of locationOptionList">
                            <nz-option *ngIf="!isLocationOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                            </nz-option>
                          </ng-container>
                          <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="function_id" nzRequired>Function
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Function!">
                        <!-- <nz-select  nzSize="large" nzAllowClear
                                                    nzPlaceHolder="Select Function"
                                                    formControlName="function_id">
                                                    <nz-option *ngFor="let item of functionOptionList"
                                                        [nzLabel]="item.name"
                                                        [nzValue]="item.id"></nz-option>
                                                </nz-select> -->
                        <nz-select placeholder="Select Function" nzSize="large" nzAllowClear nzShowSearch nzServerSearch
                          formControlName="function_id" (ngModelChange)="onFunctionChange($event)"
                          [nzDisabled]="!employeeForm.controls.location_id.value"
                          (nzOnSearch)="onFunctionOptionListSearch($event)" (nzBlur)="getDepartments()">
                          <ng-container *ngFor="let o of functionOptionList">
                            <nz-option *ngIf="!isFunctionOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                            </nz-option>
                          </ng-container>
                          <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="department_id" nzRequired>Department
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Department!">
                        <!-- <nz-select  nzSize="large" nzAllowClear
                                                    nzPlaceHolder="Select Department"
                                                    formControlName="department_id">
                                                    <nz-option *ngFor="let item of departmentOptionList"
                                                        [nzLabel]="item.name"
                                                        [nzValue]="item.id"></nz-option>
                                                </nz-select> -->
                        <nz-select placeholder="Select Department" nzSize="large" nzAllowClear nzShowSearch
                          nzServerSearch (ngModelChange)="onDepartmentChange($event)"
                          [nzDisabled]="!employeeForm.controls.function_id.value" formControlName="department_id"
                          (nzOnSearch)="onDepartmentOptionListSearch($event)">
                          <ng-container *ngFor="let o of departmentOptionList">
                            <nz-option *ngIf="!isDepartmentOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                            </nz-option>
                          </ng-container>
                          <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="grade_id" nzRequired>Grade
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Grade!">
                        <!-- <nz-select  nzSize="large" nzAllowClear
                                                    nzPlaceHolder="Select Competencies"
                                                    formControlName="grade_id">
                                                    <nz-option *ngFor="let item of gradeOptionList"
                                                        [nzLabel]="item.grade_name"
                                                        [nzValue]="item.id"></nz-option>
                                                </nz-select> -->
                        <nz-select placeholder="Select Grade" nzSize="large" nzAllowClear nzShowSearch nzServerSearch
                          formControlName="grade_id" [nzDisabled]="!employeeForm.controls.department_id.value"
                          (nzOnSearch)="onGradeOptionListSearch($event)">
                          <ng-container *ngFor="let o of gradeOptionList">
                            <nz-option *ngIf="!isGradeOptionListLoading" [nzValue]="o.id" [nzLabel]="o.grade_name">
                            </nz-option>
                          </ng-container>
                          <nz-option *ngIf="isGradeOptionListLoading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="role_id"  nzRequired>Role
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Select Role!">
                        <nz-select placeholder="Select Role" nzSize="large" nzAllowClear nzShowSearch nzServerSearch
                          formControlName="role_id">
                          <ng-container *ngFor="let o of roleOptionList">
                            <nz-option [nzValue]="o.id" [nzLabel]="o.role_name">
                            </nz-option>
                          </ng-container>
                          <!-- <nz-option *ngIf="isRoleOptionListLoading" nzDisabled
                                                        nzCustomContent>
                                                        <i nz-icon nzType="loading" class="loading-icon"></i>
                                                        Loading Data...
                                                    </nz-option> -->
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="Reporting Manager"  >Reporting Manager
                      </nz-form-label>
                      <nz-form-control  nzErrorTip="Please Enter Reporting Manager!">
                        <nz-input-group class="form-group d-flex drop">
                            <nz-select style="width: 25rem !important;" nzPlaceHolder="Select Reporting Manager "
                            nzShowSearch
                                [nzServerSearch]="true"
                                (focusout)="hide($event)"
                                formControlName="reporting_manager"
                                (nzOnSearch)="onSearch($event)" [nzLoading]="listLoading"
                               >
                               <ng-container *ngFor="let emp of employeeOptionList">
                                <nz-option *ngIf="!listLoading"
                                    [nzLabel]="emp['display_name']" [nzValue]="emp['id']"></nz-option>
                              </ng-container>

                                    <nz-option *ngIf="listLoading" nzDisabled nzCustomContent>
                                        <span nz-icon nzType="loading" class="loading-icon"></span>
                                        Loading Data...
                                      </nz-option>
                            </nz-select>
                            <button class="ml-2" nz-button type="button"   nzType="primary" [nzLoading]="listLoading" (click)="onSearchButtonClick()" nzShape="circle"><span nz-icon nzType="search"></span></button>

                            <!-- <button type="button" class="ml-2"  [nzSize]="'medium'" nz-button
                            nzType="primary">
                            Select Subordinate
                            </button> -->
                        </nz-input-group>
                    </nz-form-control>
                    </nz-form-item>


                    <nz-form-item *ngIf="variable_filter_field?.length != 0" formArrayName="variable_filter_field">
                      <ng-container *ngFor="let item of variable_filter_field.controls; let k=index" [formGroupName]="k">
                        <nz-form-label>Select {{item.value.filter_name}}
                        </nz-form-label>
                        <nz-form-control  nzErrorTip="Please Select {{item.value.filter_name}}!" >
                          <nz-input-group class="form-group d-flex drop">
                            <nz-select style="width: 25rem !important;" nzPlaceHolder="Search & Select {{item.value.filter_name}}"
                                       nzShowSearch  (nzOnSearch)="onFilterSearch($event,item.value)"
                                       [nzServerSearch]="true"
                                       formControlName="employee_id"
                                      [nzLoading]="employeeVariableFilterlistLoading[item.value.filter_name]"
                            >
                              <nz-option
                                nzLabel="Select {{item.value.filter_name}}" [nzValue]=""></nz-option>
                              <ng-container *ngFor="let emp of employeeFilterOptionList[item.value.filter_name]">

                                <nz-option *ngIf="!employeeVariableFilterlistLoading[item.value.filter_name]"
                                           [nzLabel]="emp.first_name + ' ' + emp.last_name + ' ' + '('+ emp?.user?.email +')'"  [nzValue]="emp['id']"></nz-option>
                              </ng-container>

                              <nz-option *ngIf="employeeVariableFilterlistLoading[item.value.filter_name]" nzDisabled nzCustomContent>
                                <span nz-icon nzType="loading" class="loading-icon"></span>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                            <button class="ml-2" nz-button type="button"   nzType="primary" [nzLoading]="employeeVariableFilterlistLoading[item.value.filter_name]" (click)="onVariableFilterSearchButtonClick(item,k)" nzShape="circle"><span nz-icon nzType="search"></span></button>

                            <!-- <button type="button" class="ml-2"  [nzSize]="'medium'" nz-button
                            nzType="primary">
                            Select Subordinate
                            </button> -->
                          </nz-input-group>
                        </nz-form-control>

                      </ng-container>
                    </nz-form-item>


                    <nz-form-item *ngIf="variable_filter_select?.length != 0" formArrayName="variable_filter_select">
                      <ng-container *ngFor="let item of variable_filter_select.controls; let k=index" [formGroupName]="k">
                        <nz-form-label>is {{item.value.filter_name}} ?
                        </nz-form-label>
                          <nz-form-control class="mb-3">
                            <label  formControlName="status" nz-checkbox >{{item.value.filter_name}}</label>
                          </nz-form-control>
                      </ng-container>
                    </nz-form-item>

                    <nz-form-item *ngIf="userType === 'tenant_admin'">
                    <!-- <nz-form-item> -->
                      <nz-form-control nzErrorTip="Please Select Admin Or Not">
                        <label nz-checkbox (ngModelChange)="isAdminClicked()" [nzDisabled]="isAdminOnSame" formControlName="is_admin">Sub Admin</label>
                        <button *ngIf="loggedInUserDetail?.user_type === 'tenant_admin' && !isAdminOnSame" nz-button [nzType]="'default'"
                        type="button" (click)="isAdminClicked()" class="mr-3">
                        <i class="ri-eye-fill"></i>
                      </button>
                      </nz-form-control>
                    </nz-form-item>
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Select Active!">
                          <label nz-checkbox formControlName="is_active">Active</label>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Select Manager!">
                          <label nz-checkbox formControlName="is_manager">Manager</label>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Select Department Head!">
                          <label nz-checkbox formControlName="is_department_head">Department
                            Head</label>
                        </nz-form-control>
                      </nz-form-item>

                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Select Functional Head!">
                          <label nz-checkbox formControlName="is_functional_head">Functional
                            Head</label>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Select BU H ead!">
                          <label nz-checkbox formControlName="is_bu_head">BU Head</label>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                                            <nz-form-control nzErrorTip="Please Select Admin!">
                                                <label nz-checkbox formControlName="is_admin">Admin</label>
                                            </nz-form-control>
                                        </nz-form-item>
                      <!-- <nz-form-item>
                                            <nz-form-label nzRequired nzFor="User Id">User Id
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter User Id!">
                                                <input nz-input formControlName="user_id" nzSize="large"
                                                    placeholder="User Id" id="user_id" />
                                            </nz-form-control>
                                        </nz-form-item> -->
                      <!-- <nz-form-item>
                                            <nz-form-label nzFor="Profile Picture">Profile Picture
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please upload Profile Picture!">
                                                <nz-upload nzSize="large" formControlName="profile_pic" ngDefaultControl
                                                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                                                    <a nz-button nzType="primary"><i nz-icon nzType="upload"></i>Upload
                                                        Profile Picture</a>
                                                </nz-upload>
                                            </nz-form-control>
                                        </nz-form-item> -->

                      <nz-input-group>
                        <div nz-row [nzGutter]="8">
                          <div nz-col nzSpan="7">
                            <nz-form-item>
                              <nz-form-label>Profile Picture</nz-form-label>
                              <nz-form-control nzErrorTip="Please upload Profile Picture!">
                                <nz-input-group [nzSize]="'large'">
                                  <nz-upload class="avatar-uploader" nzName="avatar" nzListType="picture-card"
                                    [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
                                    (nzChange)="handleChange($event)">
                                    <ng-container *ngIf="!avatarUrl">
                                      <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
                                      <div class="ant-upload-text">Upload</div>
                                    </ng-container>
                                    <img *ngIf="avatarUrl" [src]="avatarUrl" class="imagePreviewBox" />
                                  </nz-upload>
                                </nz-input-group>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </nz-input-group>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mx-4">
                    <h4 class="my-3">User Details</h4>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="username">Username
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Username!">
                        <input nz-input formControlName="username" nzSize="large" placeholder="Username"
                          id="username" OnlySpcl/>
                      </nz-form-control>
                    </nz-form-item>
                    <!-- <nz-form-item>
                                              <nz-form-label  nzFor="name">Password
                                              </nz-form-label>
                                              <nz-form-control nzErrorTip="Please Enter Password!">
                                                  <input nz-input formControlName="password" type="password"
                                                      nzSize="large" placeholder="Password" autocomplete="new-password" id="password" />
                                                      <span class="d-block mt-1">Enter a new password to modify the existing one</span>
                                              </nz-form-control>
                                          </nz-form-item> -->
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Email">Email
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Email!">
                        <input nz-input formControlName="email" type="email" nzSize="large" placeholder="Email"
                          id="email" />
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                      <nz-form-label nzFor="Login Type" nzRequired>Web Login Type</nz-form-label>
                      <nz-form-control>
                        <nz-select nzSize="large" formControlName="web_login_type">
                          <nz-option nzValue="internal" nzLabel="Internal"></nz-option>
                          <nz-option nzValue="sso" nzLabel="SSO"></nz-option>
                          <nz-option nzValue="both" nzLabel="Internal & SSO"></nz-option>

                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzFor="Login Type" nzRequired>Mobile Login Type</nz-form-label>
                      <nz-form-control>
                        <nz-select nzSize="large" formControlName="mobile_login_type">
                          <nz-option nzValue="internal" nzLabel="Internal"></nz-option>
                          <nz-option nzValue="sso" nzLabel="SSO"></nz-option>
                          <nz-option nzValue="both" nzLabel="Internal & SSO"></nz-option>

                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                      <nz-form-label nzFor="About Me">About Me
                      </nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter About Me!">
                        <textarea nz-input formControlName="about_me" nzSize="large" placeholder="About Me"
                          id="about_me"></textarea>
                        <!-- <input nz-input formControlName="about_me" type="text" nzSize="large"
                                                    placeholder="about_me" id="about_me" /> -->
                      </nz-form-control>
                    </nz-form-item>
                    <!-- {{employeeForm.value | json}} -->
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12 mt-4 text-center">
                  <nz-form-item>
                    <nz-form-control>
                      <button nz-button nzSize="large" nzType="default" (click)="resetForm()">Reset</button>
                      <!-- <button class="ml-2" nz-button nzSize="large" nzType="primary"
                        [disabled]="!employeeForm.valid">Save</button> -->
                      <button class="ml-2" nz-button nzSize="large" nzType="primary" [disabled]="!employeeForm.valid || isFormLoading">
                        <i nz-icon [nzType]="isFormLoading && employeeForm.valid ? 'loading' : ''"></i>
                        <!-- <button class="ml-2" nz-button nzSize="large" nzType="primary">
                          <i nz-icon [nzType]="isFormLoading && employeeForm.valid ? 'loading' : ''"></i> -->
                        Save
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>

            </form>


            <nz-modal [(nzVisible)]="isFilterModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Select Filter"
              (nzOnCancel)="handleFilterCancel()" (nzOnOk)="handleFilterOk()" nzOkText="Apply">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="dLForm" (ngSubmit)="handleFilterOk()">



                <!-- Input for Business Unit select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="business_unit_ids_filter" nzNoColon="false">Business unit</nz-form-label>
                  <nz-form-control nzErrorTip="Business Unit is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                        nzPlaceHolder="Select Business Unit" formControlName="business_unit_ids_filter">
                        <ng-container *ngFor="let item of bUOptionList">
                          <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->

                <!-- Input for Location select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="location_ids_filter" nzNoColon="false">Locations</nz-form-label>
                  <nz-form-control nzErrorTip="Location is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" (nzOpenChange)="onLocationOpened($event)"
                        [nzDisabled]="!dLForm.controls.business_unit_ids_filter?.value" nzAllowClear *ngIf="isFilterModalOpen"
                        nzMode="multiple" nzPlaceHolder="Select Location" formControlName="location_ids_filter">

                        <ng-container *ngFor="let item of locationOptionList">
                          <nz-option *ngIf="!isLocationOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->

                <!-- Input for Function select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="function_ids_filter" nzNoColon="false">Functions</nz-form-label>
                  <nz-form-control nzErrorTip="Function is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" (nzOpenChange)="onFunctionOpened($event)"
                        [nzDisabled]="!dLForm.controls.business_unit_ids_filter?.value" nzAllowClear *ngIf="isFilterModalOpen"
                        nzMode="multiple" nzPlaceHolder="Select Function" formControlName="function_ids_filter">

                        <ng-container *ngFor="let item of functionOptionList">
                          <nz-option *ngIf="!isFunctionOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->

                <!-- Input for Department select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="department_ids_filter" nzNoColon="false">Departments</nz-form-label>
                  <nz-form-control nzErrorTip="Department is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" (nzOpenChange)="onDepartmentOpened($event)"
                        [nzDisabled]="!dLForm.controls.function_ids_filter?.value" nzAllowClear *ngIf="isFilterModalOpen"
                        nzMode="multiple" nzPlaceHolder="Select Department" formControlName="department_ids_filter">
                        <ng-container *ngFor="let item of departmentOptionList">
                          <nz-option *ngIf="!isDepartmentOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->


                <!-- Input for Roles select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="role_ids_filter" nzNoColon="false">Roles</nz-form-label>
                  <nz-form-control nzErrorTip="Role is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                        nzPlaceHolder="Select Roles" formControlName="role_ids_filter">
                        <nz-option *ngFor="let item of roleListOption" [nzLabel]="item.role_name" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->


                <!-- Input for Grades select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="grade_ids_filter" nzNoColon="false">Grades</nz-form-label>
                  <nz-form-control nzErrorTip="Grade is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple" (nzOpenChange)="onGradeOpened($event)"
                      [nzDisabled]="!dLForm.controls.business_unit_ids_filter?.value"
                        nzPlaceHolder="Select Grade" formControlName="grade_ids_filter">
                        <nz-option *ngFor="let item of gradeListOption" [nzLabel]="item.grade_name" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Grades select box ends here -->

              </form>
            </nz-modal>

                <!-- Modal for employees -->
<!--                <nz-modal nzWidth="900px" [(nzVisible)]="isEmployeeModalOpen" nzClosable="true" nzMaskClosable="false"-->
<!--                nzTitle="Select Employees" (nzOnCancel)="handleCancel()" [nzOkDisabled]="buttonDisabled?.length == 0"  (nzOnOk)="handleOk()" nzOkText="Ok">-->
<!--                <ng-container *ngIf="!isEmployeeOptionListLoading">-->
<!--                  <app-employee-popup [employees]="employeeOptionList" [isShowSelf]="isShowSelf" [getAllemployees]="allEmployeeList" [selectedEmployee]="selectedOrDirectProceedEmployees"-->
<!--                  (setEmployeesForLA)=setEmployeesForLA($event) [IsSelectOne]="IsSelectOne"></app-employee-popup>-->
<!--                </ng-container>-->
<!--                <div class="col-lg-12 text-center" style="padding: 20px;" *ngIf="isEmployeeOptionListLoading">-->
<!--                    <span style="font-size: 17px;" *ngIf="isEmployeeOptionListLoading"><i nz-icon nzType="loading" class="loading-icon"></i>-->
<!--                      Please Wait...</span>-->
<!--                </div>-->
<!--              </nz-modal>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgb(0,0,0)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Please Wait... </p>
</ngx-spinner>
