<!-- Sign in Start -->
<section class="sign-in-page">
  <!-- <div id="container-inside">
        <div id="circle-small"></div>
        <div id="circle-medium"></div>
        <div id="circle-large"></div>
        <div id="circle-xlarge"></div>
        <div id="circle-xxlarge"></div>
    </div> -->

  <!-- <img src="../../../assets/images/logos/logo-client.png" class="clientLogo" alt="Client Logo">   -->
  <div class="container p-0">
    <div class="row no-gutters">
      <div class="col-md-6 text-center pt-5">
        <div class="sign-in-detail text-white">
          <a class="sign-in-logo1" href="#"><img src="../../../assets/images/logos/ESPL_PNG_03.png" class="img-fluid" alt="logo" width="160" height="160"></a>

          <div style="width: 100%; height: 90%">
            <nz-carousel [nzEffect]="effect" nzAutoPlay>
              <div nz-carousel-content *ngFor="let s of slides" style="margin-top: 100px">
                <img [src]="s.image" class="img-fluid" alt="logo">
                <div> <h4 class="mb-5 imgLabel text-center">Deliver Performance</h4> </div>
              </div>
            </nz-carousel>
          </div>


          <!-- <div class="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
                <div class="item">
                    <img src="../../../assets/images/login/1.png" class="img-fluid mb-4" alt="logo">
                    <h4 class="mb-1 text-white">Find new friends</h4>
                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
                <div class="item">
                    <img src="../../../assets/images/login/2.png" class="img-fluid mb-4" alt="logo">
                    <h4 class="mb-1 text-white">Connect with the world</h4>
                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
                <div class="item">
                    <img src="../../../assets/images/login/3.png" class="img-fluid mb-4" alt="logo">
                    <h4 class="mb-1 text-white">Create new events</h4>
                    <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
            </div> -->
        </div>
      </div>
      <div class="col-md-4 bg-purple text-white pt-5">
        <div class="sign-in-from">
          <h3 class="text-white mb-4 text-center"><img width="200" src="../../../assets/images/TruLead Logo.png"></h3>
          <!-- <h1 class="mb-0 text-white">Sign in</h1> -->

          <a>
            <button class='two' (click)="goTologin()"
              [style.background-image]="'url('+ '../../../assets/images/PikPng.com_envelope-icon-png_2733531.png'+')'">
              Sign in with Username</button>
          </a>

          <!-- on click event for google and apple sso authentication is removed since microsoft sso is only needed -->

          <button class='one' [disabled]="true"
            [style.background-image]="'url('+'../../../assets/images/wp2860498-google-logo-black-background.jpg'+')'">Sign In with Google</button>
            <button class='three' [style.background-image]="'url('+ '../../../assets/images/msbackgr.png'+')'"
                        (click)="signIn()">Sign In with Microsoft</button>
          <button class='four' [disabled]="true" [style.background-image]="'url('+'../../../assets/images/applebckg.png'+')'">Sign In with Apple</button>

        </div>
      </div>
    </div>
  </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<nz-modal nzMaskClosable="true"  >

</nz-modal>

<!-- Sign in END -->