<!-- <footer class="bg-white iq-footer">
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-6">
            <ul class="list-inline mb-0">
               <li class="list-inline-item"><a href="privacy-policy.html">Privacy Policy</a></li>
               <li class="list-inline-item"><a href="terms-of-service.html">Terms of Use</a></li>
            </ul>
         </div>
         <div class="col-lg-6 text-right">

            Copyright 2020 <a href="#">SocialV</a> All Rights Reserved.
         </div>
      </div>
   </div>
</footer> -->

<footer >

      <div class="row" style="margin-bottom: 10px;">

            <div class="col-lg-12 text-right">
               V05.10.23
            </div>

   </div>
</footer>

