import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-behaviour-transactions',
  templateUrl: './behaviour-transactions.component.html',
  styleUrls: ['./behaviour-transactions.component.css']
})
export class BehaviourTransactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
