<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
                <nz-modal
              [(nzVisible)]="isFormVisible"
              nzTitle="{{action | titlecase}} Features"
              (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleModalSubmit()"
              nzOkText="{{btnName}}"
              nzOkType="primary"
              [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!featuresForm.valid"
            >
            <form nz-form [nzLayout]="'vertical'" [formGroup]="featuresForm">

                <!-- Input For Title Name -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="title" nzNoColon="false">Title Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please input Title name!">
                    <nz-input-group nzSize="large">
                      <input noWhitespace formControlName="title" nz-input nzSize="large"
                        placeholder="Title Name" OnlyCharacter/>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <!-- Input select for selecting keywords -->
                <nz-form-item>
                  <nz-form-label nzFor="keywords" nzNoColon="false">Keywords</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzSize="large">
                      <input noWhitespace nz-input nzSize="large" [(ngModel)]="inputTag" (blur)="handleInputConfirm()"
                        (keydown.enter)="handleInputConfirm()" placeholder="Keywords"
                        [ngModelOptions]="{standalone: true}" />
                        <br />
                      <nz-tag *ngFor="let tag of tagValue; let i = index" nzMode="closeable" [nzColor]="'purple'"
                        (nzOnClose)="handleClose(tag)">{{ sliceTagName(tag) }}</nz-tag>
                    </nz-input-group>

                  </nz-form-control>
                </nz-form-item>
                <!-- Input for keyword ends here -->
                <nz-form-item>
                  <nz-form-label nzFor="description" nzNoColon="false">Description</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzSize="large">
                      <textarea rows="3" nz-input formControlName="description" ></textarea>
                    </nz-input-group>

                  </nz-form-control>
                </nz-form-item>

              </form>
            </nz-modal>
            <h3 class="py-4 text-center">Features</h3>
            <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
            <nz-table #tableData
            nzBordered
            [nzLoading]="loading"
            [nzPageSize]="pageSize"
            [nzData]="featuresData"
          >
            <thead>
              <tr>
                <th>Title Name</th>
                <th>Keywords</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let feature of tableData.data">
                <td>{{ feature.title }}</td>
                <td>{{ feature.keyword?feature.keyword:'N/A' }}</td>
                <td>{{ feature.description?feature.description:'N/A' }}</td>
                <td>
                  <a (click)="showEditModal(feature)"><i class="ri-pencil-fill grayIcon" ></i></a>
                  <nz-divider nzType="vertical"> | </nz-divider>
                  <a (click)="remove(feature)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                </td>
              </tr>
            </tbody>
          </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>