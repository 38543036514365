<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <!-- new tabe design start -->
      <div class="col-sm-12 col-lg-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-2 text-center">Initiatives</h3>
            <h5 class="py-2 text-center"> {{empInitiativeGroupList?.start_date | date:'dd MMM' }} {{empInitiativeGroupList?.end_date ? ' - ' : '' }} {{empInitiativeGroupList?.end_date | date:'dd MMM'}}</h5>
             <!-- EmpInitGroups -->
            <nz-modal [(nzVisible)]="isVisibleCreateObjectiveModal" nzTitle="Create Initiative"
              (nzOnCancel)="createObjectiveHandleCancel()" (nzOnOk)="createObjectiveHandleSubmit()" nzOkText="Save" nzOkType="primary" [nzOkLoading]="isFormLoading" [nzOkDisabled]="!employeeInitiativeForm.valid">
              <form nz-form [formGroup]="employeeInitiativeForm" [nzLayout]="'vertical'">

                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Initiative" nzNoColon="false">Initiative
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please input Initiative!">
                    <nz-input-group nzSize="large">
                      <input   type="text" formControlName="title" nz-input nzSize="large" placeholder="Enter here" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Weightage" nzNoColon="false">Weightage</nz-form-label>
                  <nz-form-control nzErrorTip="Please input Weightage!">
                    <!-- <nz-input-group nzSize="large" nzAddOnAfter="%">
                      <input type="text" formControlName="weightage" nz-input  nzSize="large" placeholder="Enter here" />
                    </nz-input-group> -->
                    <nz-input-group nzSize="large" nzAddOnAfter="%">
                    <nz-input-number [nzSize]="'large'"  formControlName="weightage"
                        [nzMin]="1" [nzMax]="100" [nzStep]="1" [nzPrecision]="0">
                      </nz-input-number>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Target" nzNoColon="false">Target</nz-form-label>
                  <nz-form-control nzErrorTip="Please input Target!">
                    <!-- <nz-input-group nzSize="large" nzAddOnAfter="Cr">
                      <input type="text" formControlName="target" nz-input nzSize="large" placeholder="Enter here" />
                    </nz-input-group> -->
                    <nz-input-group nzSize="large"  nzCompact>
                      <nz-input-number [nzSize]="'large'"  formControlName="target" style="width:90%;" 
                      [nzMin]="1" [nzMax]="100" [nzStep]="1" [nzPrecision]="0">
                      <!-- <input type="text" nz-input formControlName="target" style="width: 90%;" /> -->
                      <input type="text" class="disableField" nz-input formControlName="uom" style="width:10%;"  />
                     </nz-input-number>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Estimated Effort" nzNoColon="false">Estimated Effort</nz-form-label>
                  <nz-form-control nzErrorTip="Please input Estimated Effort!">
                    <nz-input-group nzSize="large" nzAddOnAfter="Hrs">
                      <input type="text" nz-input formControlName="estimated_efforts_req" nzSize="large" placeholder="Enter here" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor=" Select Initiative Color" nzNoColon="false"> Select Initiative Color
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please input  Select Initiative Color!">
                    <div class="row">
                      <!-- <div class="col-md-1">
                        <div class="circle red"></div>
                      </div>
                      <div class="col-md-1">
                        <div class="circle yellow"></div>
                      </div>
                      <div class="col-md-1">
                        <div class="circle orange"></div>
                      </div>
                      <div class="col-md-1">
                        <div class="circle blue"></div>
                      </div>
                      <div class="col-md-1">
                        <div class="circle green"></div>
                      </div>
                      <div class="col-md-1">
                        <div class="circle pink"></div>
                      </div> -->
                      <div class="col-md-4">
                        <!-- Or Enter Code [value]="colorYellow"  [(colorPicker)]="colorYellow"-->
                        <input type="text" (ngModelChange)="onChangeInitiativeColor()"  formControlName="color_code" nz-input nzSize="large" />
                      </div>
                      <div class="col-md-2">
                        <div class="box"
                        [(colorPicker)]="colorCode"
                            (colorPickerChange)="employeeInitiativeForm.controls.color_code.setValue($event)"
                            [style.background]="colorCode"
                            [cpPresetColors]="['#D84C4C', '#F6B22A', '#6AC445', '#26247B', '#F26325', 'EC008C']"
                            ></div>
                      </div>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </form>

            </nz-modal>
            <!-- <div class="row"> -->
              <!-- search dropdown  -->
              <!-- <div class="col-md-4 text-right">
                <h4 class="mt-1">Select the Month</h4>
              </div>
              <div class="col-md-4 text-center">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please Select Month!">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzPlaceHolder="Please select" [nzOptions]="listOfNoOfOption">
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-4">
                <h4 class="mt-1">26th Aug- 25th Sep</h4>
              </div>  -->

              <!-- <a (click)="showModal()"><i nz-icon nzType="edit" nzTheme="outline"></i> --> 
            <!-- </div> -->

            <!-- tree related models        -->

            <nz-modal [(nzVisible)]="isVisibleEditInitiativeModal" nzTitle="Create Initiatives" (nzOnCancel)="editInitiativeHandleCancel()"
            (nzOnOk)="editInitiativeHandleSubmit()">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input Types!">
                <nz-input-group>
                  <nz-select [nzSize]="'large'" nzPlaceHolder="Please select" [nzOptions]="listOfNoOfOption">
                  </nz-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input Types!">
                <nz-input-group>
                  <nz-select [nzSize]="'large'" nzPlaceHolder="Please select" [nzOptions]="listOfNoOfOption">
                  </nz-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>

          </nz-modal> 
            <!-- tree table view  -->
            <div class="row">
              <div class="col-md-12">
                <ng-container *ngIf="treeData">
                  <nz-table class="initiativeTbl mt-5" nzBordered #expandTable [nzData]="treeData" nzTableLayout="fixed"
                    [nzFrontPagination]="false" [nzShowPagination]="false">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Objective Weightage</th>
                        <th>Objective Target</th>
                        <th>Initiative Weightage</th>
                        <th>Initiative Target</th>
                        <th>Estimated Effort</th>
                        <th *ngIf="isSelf && status != 'completed'">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let data of expandTable.data">
                        <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
                          <tr *ngIf="(item.parent && item.parent.expand) || !item.parent" >
                            <td [nzIndentSize]="item.level! * 20" [nzShowExpand]="!!item.children"
                              [(nzExpand)]="item.expand"
                              (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)">
                              <span nz-tooltip [nzTooltipTitle]="item.name | titlecase"> {{ item.name | titlecase}}</span>
                            </td>
                            <td>{{ item.type=='obj'? (item.weightage + ' %'): '' }}</td>
                            <td>{{ item.type=='obj'? (item.target +' '+ item.uom | titlecase): '' }}</td>
                            <td>{{ item.type=='in'? (item.weightage + ' %') : ''}}</td>
                            <td>{{ item.type=='in'? (item.target +' '+ item.uom | titlecase)  : '' }}  </td>
                            <td>{{ item.type=='in'? (item.estimated_effort + ' Hrs'): ''}}</td> 
                            <td *ngIf="isSelf && status != 'completed'"> 
                              <a *ngIf="item.type === 'obj'" class="mr-2" (click)="showCreateObjectiveModal(item)">
                                <i class="ri-add-fill grayIcon"></i>
                              </a>
                              <a *ngIf="item.type === 'in'" class="mr-2" (click)="showEditInitiativeModal(item)">
                                <i class="ri-pencil-fill grayIcon"></i>
                              </a>
                              <a *ngIf="item.type === 'in'" (click)="remove(item)">
                                <i class="ri-delete-bin-6-fill grayIcon"></i>
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </tbody>
                  </nz-table>
                </ng-container>
              </div>
             <!-- <div class="col-md-12 mt-2">
              <h6>Max Effort Possible: {{empInitiativeGroupList.max_effort}}</h6>  
              <h6>Total Effort Estimated: {{this.totalEffortEstimated }}</h6>
             </div> -->
             <!-- <a nz-popconfirm nzPopconfirmTitle="Are you sure?" [nzPopconfirmShowArrow]="false">Delete</a> -->
              <div *ngIf="treeData" class="col-md-12 text-center mt-5" >
                <nz-form-item>
                  <nz-form-control>
                    <button *ngIf="isSelf && status != 'completed'" (click)="checkTotalEffortEstimated()" nz-button nzType="primary"  >Save</button> 
                    <button *ngIf="!isSelf  || status == 'completed'" [routerLink]="['/maximizer-dashboard/initiatives']" nz-button nzType="primary" >Back</button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>