import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DataService } from "./data.service";
import { NzTableModule } from 'ng-zorro-antd/table';
import { NoWhitespaceDirective } from './directives/no-whitespace.directive';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';
import { EmployeePopupComponent } from './components/employee-popup/employee-popup.component';
import { ChartAmcchartComponent } from './components/chart-amcchart/chart-amcchart.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { AjnaRedirectionComponent } from './ajna-redirection/ajna-redirection.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SecondToDurationPipe } from './pipes/second-to-duration.pipe';

@NgModule({
  declarations: [DateAgoPipe, NoWhitespaceDirective, EmployeePopupComponent, ChartAmcchartComponent, SecondToDurationPipe],
  imports: [
    CommonModule,
    NzTableModule,
    NzDropDownModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzCardModule,
    NzButtonModule,
    NzFormModule,
    NzModalModule,
    NzInputModule,NzNotificationModule,
    NzToolTipModule,
  ],
    exports: [
        DateAgoPipe,
        NoWhitespaceDirective, NoWhitespaceDirective,
        EmployeePopupComponent,
        ChartAmcchartComponent, SecondToDurationPipe,
    ],
})
export class SharedModule { }
