import { Injectable } from '@angular/core';

import { Cell, Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';

import Swal from 'sweetalert2/dist/sweetalert2.js';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelGeneratorService {

  constructor() { }

  numToAlpha(num) {

    let alpha = '';

    for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
      alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
    }

    return alpha;
  }

  colorNineBoxCell(cell: Cell, color, border, count) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    };
    cell.font = { color: { argb: "FFFFFFFF" }, bold: true };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    if (border != {}) {
      cell.border = border;
    }
    if (count > 0) {
      cell.value = count;
    }
  }

  setNineBox(worksheet: Worksheet, count) {
    let c;

    worksheet.getCell('A6').value = 'Potential';
    worksheet.getCell('A6').alignment = { horizontal: 'right' };

    worksheet.getRow(5).getCell('B').value = 'High';
    worksheet.getRow(5).getCell('B').alignment = { horizontal: 'right' };

    c = count.filter(elm => elm.x == 'Low' && elm.y == 'High');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('C5'), 'FFF4C430', { left: { style: 'thin' }, top: { style: 'thin' } }, c);

    c = count.filter(elm => elm.x == 'Medium' && elm.y == 'High');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('D5'), 'FF5DBE24', { top: { style: 'thin' } }, c);

    c = count.filter(elm => elm.x == 'High' && elm.y == 'High');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('E5'), 'FF0B7D03', { top: { style: 'thin' }, right: { style: 'thin' } }, c);

    worksheet.getRow(6).getCell('B').value = 'Medium';
    worksheet.getRow(6).getCell('B').alignment = { horizontal: 'right' };

    c = count.filter(elm => elm.x == 'Low' && elm.y == 'Medium');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('C6'), 'FFE17A2D', { left: { style: 'thin' } }, c);

    c = count.filter(elm => elm.x == 'Medium' && elm.y == 'Medium');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('D6'), 'FFF4C430', {}, c);

    c = count.filter(elm => elm.x == 'High' && elm.y == 'Medium');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('E6'), 'FF5DBE24', { right: { style: 'thin' } }, c);

    worksheet.getRow(7).getCell('B').value = 'Low';
    worksheet.getRow(7).getCell('B').alignment = { horizontal: 'right' };

    c = count.filter(elm => elm.x == 'Low' && elm.y == 'Low');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('C7'), 'FFCA0101', { left: { style: 'thin' }, bottom: { style: 'thin' } }, c);

    c = count.filter(elm => elm.x == 'Medium' && elm.y == 'Low');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('D7'), 'FFE17A2D', { bottom: { style: 'thin' } }, c);

    c = count.filter(elm => elm.x == 'High' && elm.y == 'Low');
    c = c.length == 1 ? c[0].count : 0;
    this.colorNineBoxCell(worksheet.getCell('E7'), 'FFF4C430', { bottom: { style: 'thin' } }, c);

    worksheet.getRow(8).getCell('C').value = 'Low';
    worksheet.getRow(8).getCell('D').value = 'Medium';
    worksheet.getRow(8).getCell('E').value = 'High';

    worksheet.getCell('D9').value = 'Performance';
    worksheet.getCell('D9').alignment = { horizontal: 'center' };

  }

  colorCell(cell: Cell, level) {
    if (level == 'High') {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0B7D03' }
      };
      cell.font = { color: { argb: "FFFFFFFF" }, bold: true };
      // cell.alignment = { horizontal: 'center', vertical: 'middle' };
    } else if (level == 'Medium') {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF4C430' }
      };
      cell.font = { color: { argb: "FFFFFFFF" }, bold: true };
      // cell.alignment = { horizontal: 'center', vertical: 'middle' };
    } else if (level == 'Low') {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCA0101' }
      };
      cell.font = { color: { argb: "FFFFFFFF" }, bold: true };
      // cell.alignment = { horizontal: 'center', vertical: 'middle' };
    }
  }

  addDataToBox(worksheet: Worksheet, header, data) {
    worksheet.mergeCells('A12:F12');
    worksheet.getCell('A12:F12').value = 'Employee Details';
    worksheet.getCell('A12:F12').alignment = { horizontal: 'center' };
    worksheet.getCell('A12:F12').font = { size: 13, bold: true };

    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, index) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { size: 12, bold: true };
      if (index == 1) {
        worksheet.getColumn(index).width = 12;
      } else {
        worksheet.getColumn(index).width = header[index - 1].length < 20 ? 20 : header[index - 1].length;
      }
    });

    let row = 14;
    data.forEach(element => {
      worksheet.getRow(row).getCell('A').value = element.SrNo;
      worksheet.getRow(row).getCell('A').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('B').value = element.EmployeeId;
      worksheet.getRow(row).getCell('B').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('C').value = element.EmployeeName;
      worksheet.getRow(row).getCell('C').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('D').value = element.Department;
      worksheet.getRow(row).getCell('D').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('E').value = element.Designation;
      worksheet.getRow(row).getCell('E').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('F').value = element.BusinessUnit;
      worksheet.getRow(row).getCell('F').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('G').value = element.Location;
      worksheet.getRow(row).getCell('G').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('H').value = element.Grade;
      worksheet.getRow(row).getCell('H').border = { left: { style: 'thin' }, right: { style: 'thin' } };

      worksheet.getRow(row).getCell('I').value = element.Potential;
      worksheet.getRow(row).getCell('I').border = { left: { style: 'thin' }, right: { style: 'thin' } };
      this.colorCell(worksheet.getRow(row).getCell('I'), element.Potential);

      worksheet.getRow(row).getCell('J').value = element.Performance;
      worksheet.getRow(row).getCell('J').border = { left: { style: 'thin' }, right: { style: 'thin' } };
      this.colorCell(worksheet.getRow(row).getCell('J'), element.Performance);

      if (row == data.length + 13) {
        worksheet.getRow(row).getCell('A').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('B').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('C').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('D').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('E').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('F').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('G').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('H').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('I').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getRow(row).getCell('J').border = { bottom: { style: 'thin' }, right: { style: 'thin' } };
      }

      row += 1;
    });
  }

  generateExcelFile(excelFileName,description, sheetName, header, data, count, title) {

    console.log(count,'count')
    console.log(data,'data')

    /* Create workbook and worksheet */
    const workbook = new Workbook();
    workbook.creator = 'Exlygenze';
    workbook.lastModifiedBy = 'Exlygenze';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);

    /* Excel body part */
    worksheet.mergeCells('A1:J2');
    worksheet.getCell('A1:J2').value = `Nine Box Report-${title}`;
    worksheet.getCell('A1:J2').alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell('A1:J2').font = { size: 15, bold: true };
    worksheet.getCell('A1:J2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    /* Description Excel body part */
    if(description !== ""){
      worksheet.mergeCells('A3:J3');
      worksheet.getCell('A3:J3').value = `Description-${description}`;
      worksheet.getCell('A3:J3').alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell('A3:J3').font = { size: 12};
      worksheet.getCell('A3:J3').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    }

    this.setNineBox(worksheet, count);
    this.addDataToBox(worksheet, header, data);

    /*Save Excel File*/
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }
generateExcelFIleForAssessmentScore(data){
  // console.log("DATA", data)
const workbook = new Workbook()
workbook.creator = 'Exlygenze';
workbook.lastModifiedBy = 'Exlygenze';
workbook.created = new Date();
workbook.modified = new Date();
for (const sheet of Object.keys(data)){
  if (sheet != 'Feedback Headers' && sheet != 'Score Headers'){


  const worksheet = workbook.addWorksheet(sheet);
  let sheet_data = data[sheet];
  let headigRowNo = 1
  let colNo = 0
  if (sheet == 'Assessment Score'){
    for (const header of data['Score Headers']){

      // console.log("HEADER", header)
      // console.log("COL", this.numToAlpha(colNo))
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).value = header;
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).font = { bold: true };
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).fill = { type:"pattern" ,pattern:"darkDown", fgColor: { argb: 'adb9ca' }, bgColor: { argb: 'adb9ca' } };

      worksheet.getColumn(this.numToAlpha(colNo)).width = 20;
      this.colorCell(worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)), header);
      colNo +=1
    }
  }
  else{
    for (const header of data['Feedback Headers']){

      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).value = header;
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).font = { bold: true };
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      worksheet.getRow(headigRowNo).getCell(this.numToAlpha(colNo)).fill = { type:"pattern" ,pattern:"darkDown", fgColor: { argb: 'adb9ca' }, bgColor: { argb: 'adb9ca' } };
      worksheet.getColumn(this.numToAlpha(colNo)).width = 20;
      colNo +=1
    }
  }
  let rowNo = 1;
  // Writing Main Data
  for (const row of sheet_data){
    colNo = 0
    rowNo += 1
    if (sheet == 'Assessment Score'){
      for (const header of data['Score Headers']){
        // console.log("ROW DATA", row[header])
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).value = row[header];
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        colNo +=1
      }
    }
    else{
      for (const header of data['Feedback Headers']){
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).value = row[header];
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        colNo +=1
      }
    }


  }}
}


let excelFileName = 'Assessment Score Report'
 /*Save Excel File*/
 workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
  const blob = new Blob([data], { type: EXCEL_TYPE });
  fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
});

}

generateExcelPsychometricBulkUpload(excelFileName, sheetName, header,sampleData){
    /* Create workbook and worksheet */
    const workbook = new Workbook();
    workbook.creator = 'Exlygenze';
    workbook.lastModifiedBy = 'Exlygenze';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);
    function autoAdjustColumnWidth(worksheet) {
      worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v.toString().length);
        column.width = Math.max(...lengths.filter(v => typeof v === 'number')) + 2;
      });
    }

    const headerRow = worksheet.addRow(header);
    headerRow.values = header;
    headerRow.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
    });
    worksheet.getRow(2).values = sampleData;

    autoAdjustColumnWidth(worksheet)
    /*Save Excel File*/
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });

}
  generateExcelFileForGapAnalysis(excelFileName, sheetName, header, data, assessmntTitles) {
    /* Create workbook and worksheet */
    const workbook = new Workbook();
    workbook.creator = 'Exlygenze';
    workbook.lastModifiedBy = 'Exlygenze';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);

    /* Main Body */

    /* Main Heading */
    let colCount = (header.length * 3) + 3;
    let lastCol = this.numToAlpha(colCount - 1);
    // console.log(lastCol);
    worksheet.mergeCells(`A1:${lastCol}1`);
    worksheet.getCell(`A1:${lastCol}1`).value = `Competency Scores - ${assessmntTitles}`;
    worksheet.getCell(`A1:${lastCol}1`).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(`A1:${lastCol}1`).font = { size: 12, bold: true };
    worksheet.getCell(`A1:${lastCol}1`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    /* Main Heading */

    /* Second Heading */
    let i = 3;
    let j = 0;
    while (i <= (colCount - 1) && j < header.length) {
      let startCol = this.numToAlpha(i);
      i = i + 2;
      let endCol = this.numToAlpha(i);
      worksheet.mergeCells(`${startCol}2:${endCol}2`);
      worksheet.getCell(`${startCol}2:${endCol}2`).value = header[j];
      worksheet.getCell(`${startCol}2:${endCol}2`).font = { bold: true };
      worksheet.getCell(`${startCol}2:${endCol}2`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${startCol}2:${endCol}2`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      i += 1;
      j += 1;
    }
    /* Second Heading */

    /* Third Heading */
    let rowNo = 3;
    worksheet.getRow(rowNo).getCell('A').value = 'SI No';
    worksheet.getRow(rowNo).getCell('A').font = { bold: true };
    worksheet.getRow(rowNo).getCell('A').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getColumn('A').width = 5;

    worksheet.getRow(rowNo).getCell('B').value = 'Employee Id';
    worksheet.getRow(rowNo).getCell('B').font = { bold: true };
    worksheet.getRow(rowNo).getCell('B').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getColumn('B').width = 15;

    worksheet.getRow(rowNo).getCell('C').value = 'Employee Name';
    worksheet.getRow(rowNo).getCell('C').font = { bold: true };
    worksheet.getRow(rowNo).getCell('C').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getColumn('C').width = 20;
    let colNo = 3;
    while (colNo <= (colCount - 1)) {
      ['Desired Score', 'Actual Score', 'Gap'].forEach(element => {
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).value = element;
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).font = { bold: true };
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).alignment = { horizontal: 'right' };
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        if (element == 'Desired Score') {
          worksheet.getColumn(this.numToAlpha(colNo)).width = 15;
        }
        if (element == 'Actual Score') {
          worksheet.getColumn(this.numToAlpha(colNo)).width = 15;
        }
        colNo += 1;
      });
    }
    /* Third Heading */

    /* Main Data */
    rowNo = 4;
    data.forEach(element => {
      let colNo = 0;
      element.forEach(elm => {
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).value = elm;
        worksheet.getRow(rowNo).getCell(this.numToAlpha(colNo)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        colNo += 1;
      });
      rowNo += 1;
    });
    /* Main Data */

    /* Main Body */

    /*Save Excel File*/
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  generateExcelFileFor360BulkInvitation(excelFileName, sheetName, data, header, maxCount) {
    // console.log(maxCount);
    /* Create workbook and worksheet */
    const workbook = new Workbook();
    workbook.creator = 'Exlygenze';
    workbook.lastModifiedBy = 'Exlygenze';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);


    const headerRow = worksheet.addRow(header);
    headerRow.values = header;
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, index) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { size: 12, bold: true };
      if (index == 1) {
        worksheet.getColumn(index).width = 5;
      } else {
        worksheet.getColumn(index).width = header[index - 1].length < 20 ? 20 : header[index - 1].length;
      }
    });

    let row = 2;
    data.forEach((element, index) => {
      let fullname = element.first_name +" "+ element.last_name
      worksheet.mergeCells(`A${row}:A${row + (maxCount - 1)}`);
      worksheet.getCell(`A${row}:A${row + (maxCount - 1)}`).value = index;
      worksheet.getCell(`A${row}:A${row + (maxCount - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };

      worksheet.mergeCells(`B${row}:B${row + (maxCount - 1)}`);
      worksheet.getCell(`B${row}:B${row + (maxCount - 1)}`).value = fullname;
      worksheet.getCell(`B${row}:B${row + (maxCount - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };

      worksheet.mergeCells(`C${row}:C${row + (maxCount - 1)}`);
      worksheet.getCell(`C${row}:C${row + (maxCount - 1)}`).value = element.employee_id;
      worksheet.getCell(`C${row}:C${row + (maxCount - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };
      row = row + maxCount;
    });

    /*Save Excel File*/
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  readExcelFileFromBuffer(file, sheetName, callback) {
    const workbook = new Workbook();
    let result = [];
    workbook.xlsx.load(file).then(data => {
      var worksheet = workbook.getWorksheet(sheetName);
      if (worksheet) {
        for (var i = 1; i <= worksheet.actualRowCount; i++) {
          var row = worksheet.getRow(i).values;
          result.push(row)
        }
        callback(null, result);
      } else {
        callback(Error('Unknown sheet name'));
      }
    }).catch(err => {
      if (err) {
        // console.log(err);
        callback(Error('Unsupported file type'));
      }
    });
  }

  generateExcelFileFor360BulkInviteStatus(excelFileName, sheetName, data, header) {
    /* Create workbook and worksheet */
    const workbook = new Workbook();
    workbook.creator = 'Exlygenze';
    workbook.lastModifiedBy = 'Exlygenze';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);

    const headerRow = worksheet.addRow(header);
    headerRow.values = header;
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, index) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { size: 12, bold: true };
      if (index == 1) {
        worksheet.getColumn(index).width = 5;
      } else if (index >= 10) {
        worksheet.getColumn(index).width = 35;
      }
      else {
        worksheet.getColumn(index).width = header[index - 1].length < 20 ? 20 : header[index - 1].length;
      }
    });

    /* Main Body */
    let row = 2;
    data.forEach((element, index) => {
      let m = element.manager ? element.manager.length : 0;
      let p = element.peer ? element.peer.length : 0;
      let s = element.subordinate ? element.subordinate.length : 0;
      let max = Math.max(m, p, s) == 0 ? 1 : Math.max(m, p, s);
      let col = 2;

      worksheet.mergeCells(`A${row}:A${row + (max - 1)}`);
      worksheet.getCell(`A${row}:A${row + (max - 1)}`).value = index + 1;
      worksheet.getCell(`A${row}:A${row + (max - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };

      worksheet.mergeCells(`B${row}:B${row + (max - 1)}`);
      worksheet.getCell(`B${row}:B${row + (max - 1)}`).value = element.employee_name;
      worksheet.getCell(`B${row}:B${row + (max - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };

      worksheet.mergeCells(`C${row}:C${row + (max - 1)}`);
      worksheet.getCell(`C${row}:C${row + (max - 1)}`).value = element.employee_id;
      worksheet.getCell(`C${row}:C${row + (max - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };

      let r = row
      let managerStatus = [];
      if (element.manager) {
        col += 1;
        let c1 = this.numToAlpha(col);
        col += 1;
        let c2 = this.numToAlpha(col);
        element.manager.forEach(item => {
          worksheet.getCell(`${c1}${r}`).value = item.employee_name;
          worksheet.getCell(`${c2}${r}`).value = item.employee_id;
          managerStatus.push(item);
          r = r + 1;
        });
      }

      r = row
      let peerStatus = []
      if (element.peer) {
        col += 1;
        let c1 = this.numToAlpha(col);
        col += 1;
        let c2 = this.numToAlpha(col);
        element.peer.forEach(item => {
          worksheet.getCell(`${c1}${r}`).value = item.employee_name;
          worksheet.getCell(`${c2}${r}`).value = item.employee_id;
          peerStatus.push(item);
          r = r + 1;
        });
      }

      r = row
      let subordinateStatus = []
      if (element.subordinate) {
        col += 1;
        let c1 = this.numToAlpha(col);
        col += 1;
        let c2 = this.numToAlpha(col);
        element.subordinate.forEach(item => {
          worksheet.getCell(`${c1}${r}`).value = item.employee_name;
          worksheet.getCell(`${c2}${r}`).value = item.employee_id;
          subordinateStatus.push(item);
          r = r + 1;
        });
      }

      col += 1;
      let c1 = this.numToAlpha(col);
      worksheet.mergeCells(`${c1}${row}:${c1}${row + (max - 1)}`);

      r = row
      managerStatus.forEach(item => {
        if (item.status != 'Manager OK') {
          worksheet.getCell(`${c1}${r}`).value = worksheet.getCell(`${c1}${r}`).value == null ? item?.employee_id + '-' + item?.status : worksheet.getCell(`${c1}${r}`).value + '\n' + item?.employee_id + '-' + item?.status;
        }
        r = r + 1;
      });

      r = row
      peerStatus.forEach(item => {
        if (item.status != 'Peer OK') {
          worksheet.getCell(`${c1}${r}`).value = worksheet.getCell(`${c1}${r}`).value == null ? item?.employee_id + '-' + item?.status : worksheet.getCell(`${c1}${r}`).value + '\n' + item?.employee_id + '-' + item?.status;
        }
        r = r + 1;
      });

      r = row
      subordinateStatus.forEach(item => {
        if (item.status != 'Subordinate OK') {
          worksheet.getCell(`${c1}${r}`).value = worksheet.getCell(`${c1}${r}`).value == null ? item?.employee_id + '-' + item?.status : worksheet.getCell(`${c1}${r}`).value + '\n' + item?.employee_id + '-' + item?.status;
        }
        r = r + 1;
      });
      worksheet.getCell(`${c1}${r}`).alignment = { wrapText: true };
      col += 1;
      let c2 = this.numToAlpha(col);
      worksheet.mergeCells(`${c2}${row}:${c2}${row + (max - 1)}`);
      if (element.status != 'OK') {
        worksheet.getCell(`${c2}${row}:${c2}${row + (max - 1)}`).value = element.status;
      }
      worksheet.getCell(`${c2}${row}:${c2}${row + (max - 1)}`).alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getCell(`${c2}${row}`).alignment = { wrapText: true };

      row = row + max;

    });

    /* Main Body */

    /*Save Excel File*/
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });

  }

  // Excel Generation for Employee AssessmentStatus
  filterVariableKey = [];
  generateExcelAssessmentStatus(result,variableFilters,title){
    const workbook = new Workbook();
      const sheet = Object.keys(result)
      let downloadAssessStatus = result
      sheet.forEach((sheetName,i) => {
        let worksheet = workbook.addWorksheet(sheetName);
        worksheet.state = 'visible';

        function autoAdjustColumnWidth(worksheet) {
          worksheet.columns.forEach(column => {
            const lengths = column.values.map(v => v.toString().length);
            column.width = Math.max(...lengths.filter(v => typeof v === 'number')) + 2;
          });
        }

        function celSetBorder(cell, style = 'thin') {
          cell.border = {
            top: { style },
            bottom: { style },
            left: { style },
            right: { style },
          }
        }

        function cellUpdateBorder(cell, border, style) {
          cell.border = {
            ...cell.border,
            [border]: { style },
          }
        }

        if (sheet[i] == sheetName) {

          var keys = ["Employee ID","Employee First Name","Employee Last Name","Employee Email ID","BU", "Function",
          "Location","Department","Role","Grade","Manager ID","Manager First Name","Manager Last Name","Manager Email ID"]
          var getVariableKey  = []
            downloadAssessStatus[sheetName].forEach((tt,k) => {
              const data = Object.keys(downloadAssessStatus[sheetName][k].employee_details)
              getVariableKey.push(...data)
            });
            getVariableKey = [... new Set(getVariableKey)]
            this.filterVariableKey = getVariableKey.filter(item=>(item != "BU" && item != "Department" && item != "Employee_First_Name" && item != "Employee_ID" && item != "Employee_Last_Name" && item != "Function" && item != "Grade" && item != "Locaion" && item != "Manager_First_Name " && item != "Manager_ID" && item != "Manager_Last_Name" && item != "Manager_email_id" && item != "Role" && item != "employee_email_id"))

          if(sheetName == "Assessment Status"){
            if(this.filterVariableKey.length != 0){
              this.filterVariableKey.forEach(ky => {
                keys.push(ky)
              });
            }else{
              if(variableFilters){
                variableFilters.forEach(kv => {
                  keys.push(kv)
                });
              }
            }
            const setKeyAss = Object.keys(downloadAssessStatus[sheetName][0])
            setKeyAss.forEach(setAss => {
              keys.push(setAss)
            });
            const setKeyActivities = downloadAssessStatus[sheetName][0].Activities;
            setKeyActivities.forEach(setActi => {
              keys.push(setActi['title'])
            });
            keys = keys.filter(item=>(item != "Activities"))
          }else{
            //const setKey = Object.keys(downloadAssessStatus[sheetName][0])
            if(this.filterVariableKey.length != 0){
              this.filterVariableKey.forEach(ky => {
                keys.push(ky)
              });
            }else{
              if(variableFilters){
                variableFilters.forEach(kv => {
                  keys.push(kv)
                });
              }
            }
            const setKey = ['360 Degree Assessment Role', 'Feedback Stake Holder Name', '360 Degree Assessment Status']
            setKey.forEach(set => {
              keys.push(set)
            });
          }
          // console.log('filterKey',keys)
          keys = keys.filter(item=>(item != "employee_details"))
          let rowNum = 0;
          let row = worksheet.getRow(rowNum++);
          row = worksheet.getRow(rowNum++);
          let colNum = 1;
          colNum = 1
          keys.forEach(k => {
            const cell = row.getCell(colNum);
            cell.value = k
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.font = { bold: true }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'adb9ca' },
              bgColor: { argb: 'adb9ca' }
            }

            celSetBorder(cell, "thin")
            colNum++
          })

          downloadAssessStatus[sheetName].forEach(data => {
            if(sheetName == "Assessment Status"){
              data.Activities.forEach(element => {
                data[element.title] = element.status
              });
            }
            data['Employee ID'] = data.employee_details.Employee_ID;
            data['Employee First Name'] = data.employee_details.Employee_First_Name;
            data['Employee Last Name'] = data.employee_details.Employee_Last_Name;
            data['Employee Email ID'] = data.employee_details.employee_email_id;
            data['BU'] = data.employee_details.BU;
            data['Location'] = data.employee_details.Locaion;
            data['Department'] = data.employee_details.Department;
            data['Role'] = data.employee_details.Role;
            data['Grade'] = data.employee_details.Grade;
            data['Function'] = data.employee_details.Function;
            data['Manager ID'] = data.employee_details.Manager_ID;
            data['Manager First Name'] = data.employee_details["Manager_First_Name "];
            data['Manager Last Name'] = data.employee_details.Manager_Last_Name;
            data['Manager Email ID'] = data.employee_details.Manager_email_id;
            if(this.filterVariableKey.length != 0){
              this.filterVariableKey.forEach(kk => {
                data[kk] = data.employee_details[kk]?data.employee_details[kk]:'-';
              });
            }
            // else{
            //   if(variableFilters){
            //     variableFilters.forEach(ke => {
            //       data[ke] = 'No';
            //     });
            //   }
            // }
            // console.log('DATA',data)

            colNum = 1
            row = worksheet.getRow(rowNum++);
            keys.forEach(k => {
              const cell = row.getCell(colNum)
              cell.value = data[k];
              celSetBorder(cell)
              colNum++
            })
          })
        }
        autoAdjustColumnWidth(worksheet)
        });

        workbook.xlsx.writeBuffer().then(function (data) {
          var fileName='Assessment_Status_Report_'+title+'.xlsx'
          const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          fs.saveAs(blob, fileName);
        });
  }

  // generateExcel360DegreeFeedback Attempt Data
  generateExcel360DegreeFeedback(item,activityAttemptDetail,assessmentActivityScoreList,bUOptionList,locationOptionList,roleListOption,gradeListOption,allEmployees){
    const workbook = new Workbook();
      const sheet = 'Sheet'
      // let downloadAssessStatus = result
      // sheet.forEach((sheetName,i) => {
        let worksheet = workbook.addWorksheet(sheet);
        worksheet.state = 'visible';

        function autoAdjustColumnWidth(worksheet) {
          worksheet.columns.forEach(column => {
            const lengths = column.values.map(v => v.toString().length);
            column.width = Math.max(...lengths.filter(v => typeof v === 'number')) + 2;
          });
        }

        function celSetBorder(cell, style = 'thin') {
          cell.border = {
            top: { style },
            bottom: { style },
            left: { style },
            right: { style },
          }
        }

        function cellUpdateBorder(cell, border, style) {
          cell.border = {
            ...cell.border,
            [border]: { style },
          }
        }

        worksheet.mergeCells('A1:J2');
        worksheet.getCell('A1:J2').value = `Activity - ${activityAttemptDetail?.activity_detail?.assessment?.title}`;
        worksheet.getCell('A1:J2').alignment = { horizontal: 'left', vertical: 'middle' };
        worksheet.getCell('A1:J2').font = { size: 15, bold: true };
        worksheet.getCell('A1:J2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        var keys = ["Assessee Name","Role","Stakeholder Name","Stakeholder Business Unit","Stakeholder Location", "Stakeholder Department",
        "Stakeholder Role","Stakeholder Grade","Question","Response"]


          keys = keys.filter(item=>(item != "employee_details"))
          let rowNum = 2;
          let row = worksheet.getRow(rowNum++);
          row = worksheet.getRow(rowNum++);
          let colNum = 1;
          colNum = 1
          keys.forEach(k => {
            const cell = row.getCell(colNum);
            cell.value = k
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.font = { bold: true }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'c5e0b4' },
              bgColor: { argb: 'c5e0b4' }
            }

            celSetBorder(cell, "thin")
            colNum++
          })
          let data = item;
          data.forEach(element => {
            element.forEach((e,i) => {
              let assName = assessmentActivityScoreList.filter(ee=>(ee.id == e.assessment_activity_attempt_id))
              e['Assessee Name'] = assName[0]?.assessment_attempt?.employee?.employee_name;
              e['Role'] = e.activity_attempt_feedback_invitation?.feedback_as?e.activity_attempt_feedback_invitation?.feedback_as:'Self';
              e['Stakeholder Name'] = e.activity_attempt_feedback_invitation?.feedback_given_by_name;
              let employeeDetails = allEmployees?.items.filter(item=>(item.id == e.activity_attempt_feedback_invitation?.feedback_given_by_id))
              let business_unit = bUOptionList.filter(item=>(item.id == employeeDetails[0]?.business_unit_id))
              let location = locationOptionList.filter(item=>(item.id == employeeDetails[0]?.location_id))
              let role = roleListOption.filter(item=>(item.id == employeeDetails[0]?.role_id))
              let grade = gradeListOption.filter(item=>(item.id == employeeDetails[0]?.grade_id))
              e['Stakeholder Business Unit'] = business_unit[0]?.name;
              e['Stakeholder Location'] = location[0]?.name;
              e['Stakeholder Department'] = employeeDetails[0]?.department?.name;
              e['Stakeholder Role'] = role[0]?.role_name;
              e['Stakeholder Grade'] = grade[0]?.grade_name;
              e['Question'] = e.question?.question_text?.replace(/<(.|\n)*?>/g, ' ');
              if(e.question?.answer_type == 'MCQ_MULTIPLE'){
                e['Response'] = e.answer_text[0]?.choice_text;
              }else{
                e['Response'] = e.answer_text;
              }
              colNum = 1
              row = worksheet.getRow(rowNum++);
              keys.forEach(k => {
                const cell = row.getCell(colNum)
                cell.value = e[k];
                celSetBorder(cell)
                colNum++
              })
            });
          })
        // }
        autoAdjustColumnWidth(worksheet)
        // });

        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          fs.saveAs(blob, "360 Degree Feedback Report.xlsx");
        });
  }
}
