import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {NzUploadFile, NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Tenant, TenantRetrieveRequest,  } from 'src/app/app.models';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import {NzModalService} from 'ng-zorro-antd/modal';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import Swal from 'sweetalert2/dist/sweetalert2.js';



@Component({
    selector: 'app-tenant',
    templateUrl: './tenant.component.html',
    styleUrls: ['./tenant.component.css']
  })

  export class TenantComponent implements OnInit {
      loading = true;
      pageSize = 100;
      pageIndex = 1;
      total = 1;
      tenants: Tenant[] = [];
      tenant_name: string;
      enable_learning_assessment: string  = 'disabled';
      enable_assessment_centre: string  = 'disabled';

      enable_labelled_content : string= 'disabled';
      enable_profit_maximiser: string = 'disabled';
      enable_behaviour_transaction : string = 'disabled';
      enable_survey : string= 'disabled' ;
      enable_gap_analysis : string = 'disabled';
      enable_9box: string = 'disabled';
      enable_360 : string = 'disabled';
      enable_learnig_reinforcement : string= 'disabled' ;
      enable_premium_360: string= 'disabled' ;
      storage_provider : string ;
      storage_url : string ;

      aws_access_key : string ;
      aws_secret_key : string ;
      aws_bucket : string ;
      aws_region_host: string ;
      aws_region_name : string ;

      azure_account_connection_string : string ;
      azure_container_name : string ;

      db_connection_string : string ;
      created_on: string;
      enable_hrapp : string = 'disabled';
      enable_lms : string = 'disabled';
      smtp_hostname: string ;
      smtp_username : string ;
      smtp_password : string ;
      subdomain : string ;
      tenant_admin_email : string ;
      tenant_admin_email_notifiction : string ;
      business_days: number ;

      tenantForm!: FormGroup;
      action = null;
      isFormVisible = false;
      isFormLoading = false;



      ngOnInit(): void {

        }

    constructor(private fb: FormBuilder,
        private appService: AppService,
        private message: NzMessageService,
        private modal: NzModalService) { }



    resetCreateModal(): void {

    }


    showCreateModal(entity: Tenant): void {
      // this.resetCreateModal();
      this.action = 'create';
      this.isFormVisible = true;

      this.tenant_name = entity.tenant_name;
      this.created_on = entity.created_on;
      if (entity.enable_learning_assessment == 'yes'){ this.enable_learning_assessment = 'enabled'};
      if (entity.enable_assessment_centre == 'yes'){ this.enable_assessment_centre = 'enabled'}
      if (entity.enable_labelled_content == 'yes'){ this.enable_labelled_content = 'enabled'}
      if (entity.enable_profit_maximiser == 'yes'){ this.enable_profit_maximiser = 'enabled'}
      if (entity.enable_behaviour_transaction == 'yes'){ this.enable_behaviour_transaction = 'enabled'}
      if (entity.enable_survey == 'yes'){ this.enable_survey = 'enabled'}
      if (entity.enable_gap_analysis == 'yes'){ this.enable_gap_analysis = 'enabled'}
      if (entity.enable_9box == 'yes'){ this.enable_9box = 'enabled'}
      if (entity.enable_360 == 'yes'){ this.enable_360 = 'enabled'}
      if (entity.enable_learning_reinforcement == 'yes'){ this.enable_learnig_reinforcement = 'enabled'}
      if (entity.enable_premium_360 == 'yes'){ this.enable_premium_360 = 'enabled'}
      if (entity.enable_hrapp == 'yes'){ this.enable_hrapp = 'enabled'}
      if (entity.enable_lms == 'yes'){ this.enable_lms = 'enabled'}
      this.storage_provider = entity.storage_provider;
      this.storage_url = entity.storage_url;
      this.aws_access_key = entity.aws_access_key;
      this.aws_secret_key= entity.aws_secret_key;
      this.aws_bucket= entity.aws_bucket;
      this.aws_region_host = entity.aws_region_host;
      this.azure_account_connection_string = entity.azure_account_connection_string;
      this.azure_container_name = entity.azure_container_name;
      this.db_connection_string = entity.db_connection_string;
      this.smtp_hostname = entity.smtp_hostname;
      this.smtp_username = entity.smtp_username;
      this.smtp_password = entity.smtp_password;
      this.subdomain = entity.subdomain;
      this.business_days = entity.business_days;
      this.tenant_admin_email = entity.tenant_admin_email;
      this.tenant_admin_email_notifiction = entity.tenant_admin_email_notifiction;



    }

    handleModalSubmit(): void {}
    handleModalCancel(): void {
      this.isFormVisible = false;
    }


  submitForm(): void {}


  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new TenantRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "asc";
    rr.order_by = sortField ? sortField : "tenant_name";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    this.appService.getTenants(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.tenants = data['items'];

    });
  }

  public remove(entity: Tenant) {
    const index: number = this.tenants.indexOf(entity);

    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.tenant_name + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteTenant(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;


              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }


  }
