<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
              <span>Create New</span>
            </button> -->
            <nz-modal [(nzVisible)]="isFormVisible" nzTitle="{{action | titlecase}} Desired Score"   [nzOkDisabled]="!dLForm.valid || false_desired"
              (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalSubmit()" nzOkText="Save" nzOkType="primary"
              [nzOkLoading]="isFormLoading">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="dLForm" (ngSubmit)="submitForm()">


                <!-- Input for Competancy select box -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="filter_title" nzNoColon="false">Title</nz-form-label>
                  <nz-form-control nzErrorTip="Filter title is mandatory.">
                    <nz-input-group nzSize="large">
                      <input formControlName="filter_title" OnlyCharacter nz-input nzSize="large" placeholder="Filter Title" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Competancy select box ends here -->

                <!-- Input for Business Unit select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="business_unit_id" nzNoColon="false">Business unit</nz-form-label>
                  <nz-form-control nzErrorTip="Business Unit is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFormVisible" nzMode="multiple"
                        nzPlaceHolder="Select Business Unit" formControlName="business_unit_id">
                        <ng-container *ngFor="let item of bUOptionList">
                          <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->

                <!-- Input for Location select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="location_id" nzNoColon="false">Locations</nz-form-label>
                  <nz-form-control nzErrorTip="Location is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" (nzOpenChange)="onLocationOpened($event)" [nzDisabled]="!dLForm.controls.business_unit_id?.value" nzAllowClear
                        *ngIf="isFormVisible" nzMode="multiple" nzPlaceHolder="Select Location"
                        formControlName="location_id">

                        <ng-container *ngFor="let item of locationOptionList">
                          <nz-option *ngIf="!isLocationOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->

                <!-- Input for Function select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="function_id" nzNoColon="false">Functions</nz-form-label>
                  <nz-form-control nzErrorTip="Function is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" (nzOpenChange)="onFunctionOpened($event)" [nzDisabled]="!dLForm.controls.business_unit_id?.value" nzAllowClear
                        *ngIf="isFormVisible" nzMode="multiple" nzPlaceHolder="Select Function"
                        formControlName="function_id">

                        <ng-container *ngFor="let item of functionOptionList">
                          <nz-option *ngIf="!isFunctionOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->

                <!-- Input for Department select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="department_id" nzNoColon="false">Departments</nz-form-label>
                  <nz-form-control nzErrorTip="Department is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" (nzOpenChange)="onDepartmentOpened($event)" [nzDisabled]="!dLForm.controls.function_id?.value" nzAllowClear
                        *ngIf="isFormVisible" nzMode="multiple" nzPlaceHolder="Select Department"
                        formControlName="department_id">
                        <ng-container *ngFor="let item of departmentOptionList">
                          <nz-option *ngIf="!isDepartmentOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->


                <!-- Input for Roles select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="role_id" nzNoColon="false">Roles</nz-form-label>
                  <nz-form-control nzErrorTip="Role is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFormVisible" nzMode="multiple"
                        nzPlaceHolder="Select Roles" formControlName="role_id">
                        <nz-option *ngFor="let item of roleListOption" [nzLabel]="item.role_name" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Roles select box ends here -->


                <!-- Input for Grades select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="employee_grade_id" nzNoColon="false">Grades</nz-form-label>
                  <nz-form-control nzErrorTip="Grade is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFormVisible" nzMode="multiple"
                        nzPlaceHolder="Select Grade" formControlName="employee_grade_id">
                        <nz-option *ngFor="let item of gradeListOption" [nzLabel]="item.grade_name" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Grades select box ends here -->


                <!-- Input for Competancy select box -->
                <nz-form-item>
                  <nz-form-label  nzFor="competency_id" nzNoColon="false">{{levelName}}</nz-form-label>
                  <nz-form-control nzErrorTip="Competancy is mandatory.">
                    <nz-input-group>
                      <nz-select [nzSize]="'large'" nzAllowClear nzMode="multiple" nzPlaceHolder="Select Competencies"
                        *ngIf="isFormVisible" formControlName="competency_id">
                        <nz-option *ngFor="let item of competancyOptionList" [nzLabel]="item.competency_name"
                          [nzValue]="item.id"></nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Competancy select box ends here -->

                <!-- Input for Competancy select box -->
                <nz-form-item>
                  <nz-form-label  nzRequired nzFor="desired_level_value" nzNoColon="false">Desired Score</nz-form-label>
                  <nz-form-control nzErrorTip="Desired Score value is mandatory.">
                    <nz-input-group nzSize="large">
                      <input formControlName="desired_level_value" nz-input nzSize="large" type="number"
                        placeholder="Desired Score Value" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- Input for Competancy select box ends here -->

              </form>
            </nz-modal>
            <h3 class="py-4 text-center">Desired Score</h3>
            <h3 class="plusIcon"> <i *ngIf="!false_desired" nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle"
                nzTheme="fill"></i></h3>

            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"
              [nzData]="desiredlevel">
              <thead>
                <tr>
                  <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                      <th nzColumnKey="competencyName" [nzSortFn]="true">Competency Name</th>
                      <th nzColumnKey="competencyCat" [nzSortFn]="true">Competency Category</th>
                      <th nzColumnKey="behaviourName" [nzSortFn]="true">Behaviour Name</th>
                      <th nzColumnKey="keywords" [nzSortFn]="true">Keywords</th> -->

                  <th>Sl No.</th>
                  <th>Title</th>
                  <th>{{levelName}}</th>
                  <th>Desired Score</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let dLevel of desiredlevel; let i=index">
                  <td>{{i+1}}</td>
                  <td>{{ dLevel.filter_title }}</td>
                  <td>{{ dLevel.competency_names }}</td>
                  <td>{{ dLevel.desired_level_value }}</td>
                  <td>
                    <a (click)="showEditModal(dLevel.id)"><i class="ri-pencil-fill grayIcon"></i></a>
                    <nz-divider nzType="vertical"> | </nz-divider>
                    <a (click)="remove(dLevel)" *ngIf="!false_desired"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>


<nz-modal nzTitle="Error..!" [nzOkText]="null"   nzModalType="default"  [(nzVisible)]="isErrorModalVisible" (nzOnCancel)="ErrorModalhandleCancel()"  >
  <ng-container *nzModalContent>
    <b style="color: red; font-size: large">{{ErrorModalMessage}}</b>
    <br><br>
    Following are the conflicting filter
    <li *ngFor="let item of ErrorModalData | keyvalue">
      <b>{{item.key}}</b> : <b>{{item.value}}</b>
    </li>
  </ng-container>
</nz-modal>
