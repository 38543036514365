import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-lms-redirection',
  templateUrl: './lms-redirection.component.html',
  styleUrls: ['./lms-redirection.component.css']
})
export class LmsRedirectionComponent implements OnInit {

  constructor(private appservice: AppService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    // console.log('REDIRECTIING TO LMS');
    this.spinner.show();
    this.appservice.get<any>('/api/rdrct_to_lms').subscribe(res=>{
      // console.log('response', res);
      if (res.status == 'success') {
        // console.log('success');
        this.appservice.http.post<any>('https://truleadlmsredirect.truleadai.com/save', res).subscribe((data) => {
          // console.log('result', data)
          if (data.status == 'success') {
            window.location.href = 'https://truleadlmsredirect.truleadai.com/redirect/' + data.id;
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 5000);
        });
      }
    });
  }

}
