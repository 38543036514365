import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmployeeObjectiveGroupsRetrieveRequest } from 'src/app/app.models';
// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import getISOWeek from 'date-fns/getISOWeek';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';


interface Person {
  key: string;
  name: string;
  role: string;
  status: string;
  objectivesFor: string;
  description: string;
}
@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.css']
})
export class ObjectivesComponent implements OnInit {
  date = null;
  isEnglish = false;
  EmpObjectiveGroupsList: any;
  startDate: string;
  endDate: string;
  isVisible = false;  
  i= 0;
  editId: string | null = null;
  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: "Launch the products as per timeline"
    },
    {
      key: '2',
      name: 'Jim Green',
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: "Launch the products as per timeline"
    },
    {
      key: '3',
      name: 'Joe Black',
      role: 'Designer',
      status: 'Approved',
      objectivesFor: 'May-June',
      description: "Launch the products as per timeline"
    }
  ];
  // dateFormat = 'dd/MM/yyyy';
  dateForm!: FormGroup; 
  listOfNoOfOption = [
    { label: 'For 1 Apri-2020', value: 1 },
    { label: 'For 1 May-2020', value: 2 },
    { label: 'For 1 June-2020', value: 3 },
    { label: 'For 1 July-2020', value: 4 },
  ];
  constructor(public fb: FormBuilder,
    private datepipe: DatePipe,
    private appService: AppService,
    private router: Router) { }

  ngOnInit(): void { 
    this.dateForm = this.fb.group({
      start_date: [null],
      end_date: [null], 
    }); 
    // display table on Init 
    this.getEmpObjectiveGroupsByDate();
  } 
// modal action fuctions
showModal(): void {
  this.isVisible = true;
} 
handleOk(): void { 
  this.isVisible = false;
} 
handleCancel(): void { 
  this.isVisible = false;
}
// on date model change
// onChange(date: string, type: string): void {
//   type == 'sd' ? this.startDate = date : this.endDate = date;
//   this.startDate = this.datepipe.transform(this.startDate, "yyyy-MM-dd");
//   this.endDate = this.datepipe.transform(this.endDate, 'yyyy-MM-dd');
//   console.log('onChange: ', this.endDate, this.startDate); 
//   if (this.startDate !== null && this.endDate !== null) { 
//     this.getEmpObjectiveGroupsByDate();
//   }  
// } 

getEmpObjectiveGroupsByDate(){
  // let rr = new EmployeeObjectiveGroupsRetrieveRequest();
  // rr.start_date = this.startDate;
  // rr.end_date = this.endDate;
  // rr.sort_order = 'asc';
   this.appService.getEmployeeObjectiveGroups().subscribe((res) => {
    this.EmpObjectiveGroupsList = res['emp_objective_groups'];
    // console.log("EmpObjectiveGroupsList", res);
  })
}

// <!-- status draft >isSelf true> accept and define target = accepfted > edit screen> --> 
// <!-- false draff> isself true > show edit direct --> 
// <!-- isself false > status sent for appoval > approved  with no edit action  -->
// <!-- issel false > status No set for app> view button with back btn> -->
// Objectives table Action  
editActionBtn(data){
if (data.is_self && data.status == 'draft') {
  let isShowAcceptDefineTargetBtn = true;
} else {
  // (data.is_self && data.status != 'draft') 
  let isShowEditActions = true;
}  
// if (data.is_self && data.status === 'accepted') { 
//   this.router.navigate(['/maximizer-dashboard/setup-objectives/' +data.employee_objective_group_id], { queryParams: {   'self': data.is_self }});   
// }
}
viewActionBtn(data){
  if(!data.is_self && data.status == 'sent for approval'){
    let isShowApproveBtn = true;
  }else{
    // (!data.is_self && data.status != 'sent for approval')
    let isShowBackBtn = true;
  }
}
 
}
 






