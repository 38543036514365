import { Directive, ElementRef, HostListener, Renderer2, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[numsplOnly]'
})

export class NumSplDirective implements OnInit {
  @Input() enableNumSplOnly: string;
  constructor(private el: ElementRef,) {

  }
  ngOnInit() {

  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    let e = <KeyboardEvent>event;
    if ([8, 9, 13, 27, 46].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39) || (e.keyCode == 191)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number or / and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event']) onkeyup(event: KeyboardEvent) {
    let e = <KeyboardEvent>event;

    let numberRegEx = /^[0-9- ]+$/;
    let val = String(this.el.nativeElement.value);

    if (!numberRegEx.test(this.el.nativeElement.value)) {
      this.el.nativeElement.value = "";
      event.preventDefault();
    } else if (val.length <= 11) {
      if (val && e.keyCode != 8) {
        this.el.nativeElement.value = this.el.nativeElement.value;
        // this.el.nativeElement.value = this.el.nativeElement.value.replace(/(\d{1})\-?(\d{3})\-?(\d{3})\-?(\d{4}).*/, '$1 $2-$3-$4');
      }
    //   else if (val[0] !== '1' && e.keyCode != 8) {
    //     this.el.nativeElement.value = this.el.nativeElement.value.replace(/(\d{3})\-?(\d{3})\-?(\d{4}).*/, '$1-$2-$3');
    //   }
    }
  }
}

