import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-terms-and-conitions',
  templateUrl: './terms-and-conitions.component.html',
  styleUrls: ['./terms-and-conitions.component.css']
})
export class TermsAndConitionsComponent implements OnInit {
  @Input() userName: any;
  termsHtml = '';
  key = ['terms-of-service'];

constructor(public fb: FormBuilder,
  public appService: AppService,
  public router: Router,
  public message: NzMessageService,
  public datepipe: DatePipe,) { }

ngOnInit(): void {

  this.appService.getPublicSettings(this.key, this.userName).subscribe(res => {
    this.termsHtml = res['items'][0].value
  })
}

}
function input(arg0: string) {
  throw new Error('Function not implemented.');
}

