import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EmployeeInitiativesGroupsRetrieveRequest } from 'src/app/app.models';
import { ColorPickerService } from 'ngx-color-picker';
 export interface TreeNodeInterface {
  id: number;
  objective_id?: number;
  initiative_id?: number;
  initiative_group_id?: number;
  emp_obj_group_id?: number;
  weightage?: number;
  type: string;
  key: string;
  name: string;
  isProjectBased?: string;
  target?: number;
  uom?: string;
  estimated_effort?: number;
  start_date?: string;
  end_date?: string;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
}

@Component({
  selector: 'app-detail-initiatives',
  templateUrl: './detail-initiatives.component.html',
  styleUrls: ['./detail-initiatives.component.css']
})
export class DetailInitiativesComponent implements OnInit {
// tree table declaration
  treeData: TreeNodeInterface[];
  clickedRowDetails: any;
  empInitiativeGroupId: number;
// roleObjectiveId: any;
  EmpInitGroups: any;
  empObjectiveGroupsList: any;
  empInitiativeGroupList: any;
  isVisibleEditInitiativeModal = false;
  isVisibleCreateObjectiveModal= false;
  isFormLoading= false;
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  dateFormat = 'dd/MM/yyyy';
  current = 0;
  contentIndex = 0;
  loggedInUserDetail: any;
  result: any;
  isSelf: boolean;
  status: string;
  listOfNoOfOption = [
    { label: 'For 1 Apri-2020', value: 1 },
    { label: 'For 1 May-2020', value: 2 },
    { label: 'For 1 June-2020', value: 3 },
    { label: 'For 1 July-2020', value: 4 },
  ];
  totalEffortEstimated:number;
// tree realted
collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
  if (!$event) {
    if (data.children) {
      data.children.forEach(d => {
        const target = array.find(a => a.key === d.key)!;
        target.expand = false;
        this.collapse(array, target, false);
      });
    } else {
      return;
    }
  }
}
convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
  const stack: TreeNodeInterface[] = [];
  const array: TreeNodeInterface[] = [];
  const hashMap = {};
  stack.push({ ...root, level: 0, expand: true });

  while (stack.length !== 0) {
    const node = stack.pop()!;
    this.visitNode(node, hashMap, array);
    if (node.children) {
      for (let i = node.children.length - 1; i >= 0; i--) {
        stack.push({ ...node.children[i], level: node.level! + 1, expand: true, parent: node });
      }
    }
  }
  return array;
}
visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
  if (!hashMap[node.key]) {
    hashMap[node.key] = true;
    array.push(node);
  }
}
listOfQuestionTypeOption = [
    { label: 'MCQ', value: 'MCQ' },
    { label: 'Subjective', value: 'Subjective' }
];
colorCode: string;
selectedMonth: string;
selectedYear: string;
  employeeInitiativeForm!: FormGroup;
  constructor(public fb: FormBuilder,
    private modal: NzModalService,
    private appService: AppService,
    private message: NzMessageService,
    // public datepipe: DatePipe
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private  cpService: ColorPickerService,
    ) { }

  ngOnInit(): void {
    //get user details
   this.loggedInUserDetail = this.appService.getMe();
  //  console.log("loggedInUserDetail:", this.loggedInUserDetail);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['sm'] && params['sy']) {
        this.selectedMonth = params['sm'];
        this.selectedYear = params['sy'];
        this.isSelf= true;
      // console.log("SY & SM:",this.selectedMonth + this.selectedYear);
      }else {
        this.empInitiativeGroupId= parseInt( params['id']);
        this.isSelf = (params['isSelf'] === 'true');
        this.status= params['status'];
        // console.log("isSelf:", this.isSelf);
        // console.log("empInitiativeGroupId", this.empInitiativeGroupId);
      }
    });

    // this.activatedRoute.params.subscribe(params => {
    //   this.empInitiativeGroupId = parseInt(params['id']);
    //   // console.log(this.empInitiativeGroupId);
    //   this.getTreeTableData(this.empInitiativeGroupId);
    // });
    // this.getEmployeeInitiativeList();
    this.getEmpInitiativeGroupsByDate();
    this.employeeInitiativeForm = this.fb.group({
      emp_id:[null],
      emp_objective_id:[null],
      title:[null, [Validators.required]],
      weightage:[null, [Validators.required]],
      target:[null, [Validators.required]],
      // start_date:[null],
      // end_date:[null],
      uom: [null],
      estimated_efforts_req:[null, [Validators.required]],
      color_code:[null],
    });

  }

  createFormGroup = (): FormGroup => {
    const formBuilder = new FormBuilder();
    return formBuilder.group({
      listOfSelectedActivityValue: [
        null,
        Validators.compose([Validators.required])
      ]
    });
  }

  activitySubmitForm(e) {
    // console.log(e);
  }
// Objective Modal Related
showCreateObjectiveModal(data): void {
  this.employeeInitiativeForm.reset();
  this.clickedRowDetails = data;
  // console.log("clickedadd", data);
  // console.log(this.clickedRowDetails);
  this.isVisibleCreateObjectiveModal = true;
  this.employeeInitiativeForm.controls.color_code.setValue(this.colorCode);
  this.employeeInitiativeForm.get('uom').setValue(this.clickedRowDetails.uom);
// console.log(this.clickedRowDetails.uom);
this.onChangeInitiativeColor();
}
showEditInitiativeModal(data){
  this.clickedRowDetails = data;
  // console.log("clickedEdit", data);
  this.employeeInitiativeForm.reset();
  this.isVisibleCreateObjectiveModal = true;
  this.getEmployeeInitiativeByEmpId();
  this.employeeInitiativeForm.get('uom').setValue(this.clickedRowDetails.uom);
  // console.log("edit:",this.clickedRowDetails.uom);
  this.onChangeInitiativeColor();
}

createObjectiveHandleSubmit(): void {
  this.isFormLoading = true;
  // Create & Update Objective on the server.
  if (this.employeeInitiativeForm.status === 'VALID') {
    let subscriber = null;
    let message = null;

    if (this.clickedRowDetails.type === "obj") {
      let createData = {
        "emp_id": this.loggedInUserDetail?.employee?.id,
        "emp_objective_id": this.clickedRowDetails.id,
        "emp_initiative_group_id": this.empInitiativeGroupId,
        "title": this.employeeInitiativeForm.value.title,
        "weightage": this.employeeInitiativeForm.value.weightage,
        "target": this.employeeInitiativeForm.value.target,
        "estimated_efforts_req": this.employeeInitiativeForm.value.estimated_efforts_req,
        "color_code": this.employeeInitiativeForm.value.color_code,
        "uom": this.employeeInitiativeForm.value.uom,
        // "start_date": this.empInitiativeGroupList?.start_date,
        // "end_date": this.empInitiativeGroupList?.end_date,
      }
      subscriber = this.appService.createEmployeeInitiatives(createData);
      message = 'Initiative has been added.';
    }
    else if (this.clickedRowDetails.type === "in") {
      let updateData = {
        "emp_id": this.loggedInUserDetail?.employee?.id,
        "emp_initiative_group_id": this.empInitiativeGroupId,
        "initiative_id": this.clickedRowDetails.initiative_id,
        "title": this.employeeInitiativeForm.value.title,
        "weightage": this.employeeInitiativeForm.value.weightage,
        "target": this.employeeInitiativeForm.value.target,
        "estimated_efforts_req": this.employeeInitiativeForm.value.estimated_efforts_req,
        "color_code": this.employeeInitiativeForm.value.color_code,
        "uom": this.employeeInitiativeForm.value.uom,
      }
      subscriber = this.appService.updateEmployeeInitiative(updateData, this.clickedRowDetails?.initiative_id);
      message = 'Initiative has been updated.';
    }
    subscriber.subscribe(res => {
      this.isVisibleCreateObjectiveModal = false;
      this.isFormLoading = false;

      this.message.create('success', message, {
        nzDuration: 5000
      });
    // Get Tree table Details
      this.getEmpInitiativeGroupsByDate()
    }, error => {
      this.isVisibleCreateObjectiveModal = false;
      this.isFormLoading = false;

      // let errorMessage = error.error.message;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });

  }



}
createObjectiveHandleCancel(): void {
  this.isVisibleCreateObjectiveModal = false;
}

// Edit Initiative Modal Related
// showEditInitiativeModal(): void {
//   this.isVisibleEditInitiativeModal = true;
// }
editInitiativeHandleSubmit(): void {
  this.isVisibleEditInitiativeModal = false;
}
editInitiativeHandleCancel(): void {
  this.isVisibleEditInitiativeModal = false;
}

  //tree table related
  getTreeTableData() {
    this.totalEffortEstimated= null;
    this.treeData = [];
    // console.log("tree tbl data", this.empObjectiveGroupsList);
     this.empObjectiveGroupsList.forEach((s: any, i) => {
        let level_1 = (i + 1).toString();
        this.treeData.push({
          type: "obj",
          key: level_1,
          id: s.id,
          // objective_id: s.id,
          emp_obj_group_id: s.emp_obj_group_id,
          name: s.objective_name,
          weightage: s.weightage,
          target: s.target,
          // start_date: s.start_date,
          // end_date: s.end_date,
          uom: s.uom,
        });
        if (s.initiatives) {
          if (s.initiatives.length > 0) {
            this.treeData[i].children = [];
            s.initiatives.forEach((a: any, j) => {
              let level_2 = (level_1 + "-" + (j + 1));
              this.treeData[i]['children'].push({
                type: "in",
                key: level_2,
                id: a.id,
                initiative_id: a?.initiative_id,
                name: a.initiative_title,
                weightage: a.initiative_weightage,
                target: a.initiative_target,
                uom: a.uom,
                estimated_effort: a.initiative_estimated_effort,
              });
                this.totalEffortEstimated +=  + parseInt(a.initiative_estimated_effort);
            });
          }
        }
      });
      this.treeData.forEach(item => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });
      // console.log("treeData", this.treeData);
      // this.isShowTbl = !this.isShowTbl;

  }

  // Get Tree table Details
  getEmpInitiativeGroupsByDate(){
    let rr = new EmployeeInitiativesGroupsRetrieveRequest();
    rr.emp_id = this.loggedInUserDetail?.employee?.id;
    rr.month =  this.selectedMonth;
    rr.year = this.selectedYear;
    rr.emp_initiative_group_id= this.empInitiativeGroupId;
    // rr.sort_order = 'asc';
    //  this.appService.getEmployeeInitiativesGroups(rr).subscribe((res) => {
    //   this.empObjectiveGroupsList = res['employee_objectives'];
    //   this.empInitiativeGroupList =res['emp_initiative_group'];
    //   console.log("empObjectiveGroupsList", this.empObjectiveGroupsList);
    //   console.log("empInitiativeGroupList", this.empInitiativeGroupList);
    //   this.getTreeTableData();
    // })

    let subscriber = null;
    let message = null;

    subscriber = this.appService.getEmployeeInitiativesGroups(rr);
    message = 'Initiatives found.';

    subscriber.subscribe(res => {
      this.isFormLoading = false;

      // this.message.create('success', message, {
      //   nzDuration: 5000
      // });

      this.empObjectiveGroupsList = res['employee_objectives'];
      this.empInitiativeGroupList =res['emp_initiative_group'];
      // console.log("empObjectiveGroupsList", this.empObjectiveGroupsList);
      // console.log("empInitiativeGroupList", this.empInitiativeGroupList);
      this.empInitiativeGroupId = res['emp_initiative_group']['initiative_group_id'];
      this.getTreeTableData();
    }, error => {
      this.isFormLoading = false;

      // let errorMessage = error.error.message;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });
      this.router.navigate(['/maximizer-dashboard/setup-initiatives']);
    });





  }
  // Get employee Inittiative by EmpId
 async getEmployeeInitiativeByEmpId(){
  await this.appService.getEmployeeInitiativesByEmpId(this.clickedRowDetails?.initiative_id).subscribe((res) => {
      this.result =  res;
      this.employeeInitiativeForm.patchValue(this.result);
      // console.log("employeeInitiativeForm", res);
      this.colorCode= this.result.color_code;
    })
  }
  public remove(rowDetail) {
    // console.log("clickedDel", rowDetail);
    this.modal.confirm({
      nzTitle: 'Confirm Delete',
      nzContent: 'Are you sure, you want to delete ' + rowDetail?.name+'?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          let subscriber = null;
            subscriber = this.appService.deleteEmployeeInitiative(rowDetail.initiative_id);

          subscriber.subscribe(res => {
            this.message.create('success', 'Record Deleted', {
              nzDuration: 5000
            });
           // Get Tree table Details
           this.getEmpInitiativeGroupsByDate();
            resolve(res);
          }, error => {
            // let errorMessage = error.error.message;

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });
            reject();
          })

        }).catch(() => console.log('Oops errors!'))
    });
  }

  getEmployeeInitiativeList(){
    this.appService.get('/api/employee-initiatives-groups').subscribe((res) => {
      this.EmpInitGroups = res['items'];
      // console.log("EmpInitGroups", res);
    })
  }
  save(){
    // /api/employee-initiatives-groups/3
    let data = { "status": "completed" }

    // non-self: "hidden"
    let subscriber = null;
    let message = null;

    subscriber = this.appService.updateEmployeeInitiativesGroup(data, this.empInitiativeGroupId);
    message = 'Employee Status  "' + data.status + '" has been updated.';

    subscriber.subscribe(res => {
      this.isFormLoading = false;

      this.message.create('success', message, {
        nzDuration: 5000
      });
      this.router.navigate(['/maximizer-dashboard/initiatives']);
    }, error => {
      this.isFormLoading = false;
      // let errorMessage = error.error.message;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });
    });

  }
  checkTotalEffortEstimated(){
    if (this.totalEffortEstimated > this.empInitiativeGroupList.max_effort) {
      this.modal.confirm({
        nzTitle: 'Max Effort Alert',
        nzContent: 'Max Effort Possible: ' + this.empInitiativeGroupList.max_effort+
        '<br>Total Effort Estimated: '  +this.totalEffortEstimated+
        '<br><br>Total effort estimated is more than max effort possible. Are you sure you want to continue ?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {
            this.save();
            setTimeout(Math.random() > 0.3 ? resolve : reject, 500);
          }).catch(() => console.log('Oops errors!'))
      });
    } else {
      this.save();
    }
  }
  onChangeInitiativeColor(){
    this.colorCode= this.employeeInitiativeForm.get('color_code').value;
  }
}







