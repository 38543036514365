

<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
 <div class="row">  
    <div class="col-md-12 text-center">
      <h3 class="py-2">Initiatives</h3>
      <h5>Initiatives for September is not defined, setup initiatives OR copy from previous month</h5>
    </div>
    <form class="w-100" nz-form [formGroup]="dateForm" [nzLayout]="'inline'" > 
    <div class="d-block col-md-6 offset-md-3 text-center"> 
      <nz-form-item >  
          <nz-form-control nzErrorTip="Please Select Month!"> 
              <nz-form-label nzFor=" Select the Month:" nzNoColon="false"> Select the Month</nz-form-label>
              <nz-input-group>
                  <nz-select   nzSize="large"
                     formControlName="month"
                      nzPlaceHolder="Please select"
                      [nzOptions]="listOfMonths" >
                  </nz-select>
              </nz-input-group>
          </nz-form-control>
      </nz-form-item>
      <nz-form-item > 
        <nz-form-control nzErrorTip="Please Select Year!"> 
            <nz-form-label nzFor=" Select the Year:" nzNoColon="false"> Select the Year</nz-form-label>
            <nz-input-group>
                <nz-select  nzSize="large" 
                    formControlName="year"
                    nzPlaceHolder="Please select"
                    [nzOptions]="listOfYears" >
                </nz-select>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item> 
    </div>
    <div class="d-block col-md-12 text-center"  >
        <!-- [disabled]="!dateForm.valid" -->
        <h3> <i nz-icon class="m-2"  (click)="submitDateForm()"  nzType="plus-circle" nzTheme="fill"></i>
            <i nz-icon class="m-2" nzType="copy" nzTheme="fill"></i>
        </h3>
    </div>
    </form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>