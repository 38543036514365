<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-md-12 text-center">
                                    <h3 class="py-2 ">Setup Up Objectives </h3>
                                    <p>Review your objectives, accept them & proceed to modify weightages /define
                                        targets
                                    </p>
                                </div>
                            </div>
                            <!--   <div class="col-md-6 offset-md-3 text-center">
                                <div class="searchSection">
                                    <form nz-form [nzLayout]="'vertical'" [formGroup]="empObjectiveListForm">
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please input Types!">
                                                <nz-input-group class="form-group"> 
                                                    <nz-select (change)="onValueChanges()" formControlName="emp_obj_id"
                                                        [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Role">
                                                        <nz-option *ngFor="let item of EmpObjectiveGroupsList"
                                                            [nzLabel]="item.created_on" [nzValue]="item.id">
                                                        </nz-option>
                                                    </nz-select>

                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </form>
                                </div>   <div class="searchStatus">{{ EmpObjectiveGroupsList.status}}</div> 
                            </div>-->
                        </div>

                        <!-- objective modal  -->
                        <nz-modal [(nzVisible)]="isObjectiveModal" nzTitle="Edit Objectives"
                            (nzOnCancel)="objectiveHandleCancel()" (nzOnOk)="objectiveHandleSubmit()" nzOkText="Update"
                            [nzOkLoading]="isFormLoading" [nzOkDisabled]="!empObjectiveForm.valid">
                            <form nz-form [nzLayout]="'vertical'" [formGroup]="empObjectiveForm">
                                <nz-form-item>
                                    <nz-form-label nzRequired>Weightage</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input weightage!">
                                        <nz-input-group nzSize="large" nzAddOnAfter="%">
                                            <input formControlName="weightage" nz-input nzSize="large"
                                                placeholder="weightage" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Target</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input target!">
                                        <!-- <nz-input-group nzSize="large" nzAddOnAfter="Cr">
                                    <input formControlName="target" nz-input nzSize="large" placeholder="target" />
                                  </nz-input-group> -->
                                        <nz-input-group nzSize="large" nzCompact>
                                            <input type="text" nz-input formControlName="target" style="width: 87%;" />
                                            <input type="text" class="titleCase" nz-input formControlName="uom"
                                                style="width:13%;" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </form>
                        </nz-modal>
                        <!-- *ngIf="empObjectiveId  !==  null" -->
                        <div class="row my-5">
                            <div class="col-md-12">
                                <ng-container *ngIf="treeData">
                                    <nz-table class="setupUpObjectivesTbl" nzBordered #expandTable [nzData]="treeData"
                                        nzTableLayout="fixed" [nzFrontPagination]="false" [nzShowPagination]="false">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>SP Weightage</th>
                                                <th>AP Weightage</th>
                                                <th>Objective Weightage</th>
                                                <th>UOM</th>
                                                <th>Objective Target</th>
                                                <th *ngIf="isShowEditActions">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let data of expandTable.data">
                                                <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
                                                    <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                                                        <td [nzIndentSize]="item.level! * 20"
                                                            [nzShowExpand]="!!item.children" [(nzExpand)]="item.expand"
                                                            (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)">
                                                            <span nz-tooltip [nzTooltipTitle]="item.name | titlecase">
                                                                {{ item.name | titlecase}}</span>
                                                        </td>
                                                        <td>{{ item.type=='sp'? (item.weightage + ' %'): '' }}</td>
                                                        <td>{{ item.type=='ap'? (item.weightage + ' %'): '' }}</td>
                                                        <td>{{ item.type=='obj'? (item.weightage + ' %'): ''}}</td>
                                                        <td><span *ngIf="item.uom != null">{{ item.type=='obj'? (item.uom | titlecase): ''}}</span></td>
                                                        <td><span *ngIf="item.target != null">{{ item.type=='obj'? (item.target + ' ' + item.uom |
                                                            titlecase): ''}} </span>
                                                        </td>
                                                        <td *ngIf="isShowEditActions">
                                                            <a *ngIf="item.type == 'obj'" class="mr-2"
                                                                (click)="showModal(item)">
                                                                <i class="ri-pencil-fill grayIcon"></i>
                                                            </a>
                                                            <!-- <a *ngIf="item.type !== 'obj'" class="mr-2" (click)="openAddModal(item)">
                                                  <i class="grayIcon" nz-icon nzType="plus" nzTheme="outline"></i>
                                                </a> 
                                                <a *ngIf="item.type !== 'sp'" (click)="remove(item)">
                                                  <i class="grayIcon" nz-icon nzType="delete" nzTheme="outline"></i>
                                                </a> -->
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </tbody>
                                    </nz-table>
                                </ng-container>
                            </div>
                            <!-- <div class="col-md-12 text-center">
                                  <nz-form-item>
                                    <nz-form-control>
                                      <a [routerLink]="['/role']">
                                        <button (click)="Save()" nz-button nzType="primary">Save</button>
                                      </a>
                                    </nz-form-control>
                                  </nz-form-item>
                                </div> -->

                            <!-- <div class="col-md-12 text-center mt-3">
                                        <a nz-button  *ngIf="!isShowAction && !isSelf"  
                                        (click)="acceptDefineTarget()" nzType="primary" > 
                                        {{ isSelf ? 'Accept & Define Targets' : 'Approve' }} </a>
                                        <a nz-button *ngIf="isShowAction" (click)="save()" nzType="primary" >Save</a> 
                                        <a nz-button *ngIf="isShowApproveBtn" (click)="save()" nzType="primary" >Approve</a> 
                                    </div>  -->
                            <div class="col-md-12 text-center mt-3">
                                <a nz-button *ngIf="isShowAcceptDefineTargetBtn" (click)="acceptDefineTarget()"
                                    nzType="primary">Accept & Define Targets</a>
                                <a nz-button *ngIf="isShowApproveBtn" (click)="acceptDefineTarget()"
                                    nzType="primary">Approve</a>
                                <a nz-button *ngIf="isShowEditActions" (click)="save()" nzType="primary">Save</a>
                                <a nz-button *ngIf="isShowBackBtn" [routerLink]="['/maximizer-dashboard/objectives']"
                                    routerLinkActive="router-link-active" nzType="primary">Back</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>