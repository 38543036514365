<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <!-- new tabe design start -->
      <div class="col-sm-12 col-lg-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="stepwizard mt-4 ">
              <div class="stepwizard-row setup-panel text-center">
                <div class="wizard-step tab-second">
                  <a class="btn btn-default" [ngClass]="{'active' : contentIndex == 1}">
                    <div class="iconBox">
                      <!-- <i class="ri-list-unordered text-danger1 icon"></i> -->
                      <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                    </div>
                    <span>Role Objective Details</span>
                  </a>
                </div>
              </div>
            </div>

            <!-- <h3 class="text-center m-4">Objective Set ({{roleObjectiveList?.length}}) for Role - {{role_name}}</h3> -->
            <!-- ap form -->
            <nz-modal [(nzVisible)]="isApModal" nzTitle="Select Annual Priorities and its Weightage"
              (nzOnCancel)="apHandleCancel()" (nzOnOk)="apHandleSubmit()" nzOkText="Add" nzOkType="primary"
              [nzOkLoading]="isFormLoading" [nzOkDisabled]="!apForm.valid">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="apForm">
                <nz-form-item>
                  <nz-form-label nzRequired>Annual Priorities</nz-form-label>
                  <nz-form-control nzErrorTip="Please input Annual Priority!">
                    <nz-input-group nzSize="large">
                      <nz-select nzSize="large" formControlName="ap_id" nzAllowClear
                        placeholder="Select Annual Priority">
                        <nz-option *ngFor="let item of apList" [nzLabel]="item.title" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired>Weightage</nz-form-label>
                  <nz-form-control nzErrorTip="Please input weightage!">
                    <!-- <nz-input-group nzSize="large"> -->
                      <!-- <input formControlName="weightage" nz-input nzSize="large" placeholder="weightage" /> -->
                      <nz-input-number [nzSize]="'large'" class="w-100" formControlName="weightage"
                        [nzMin]="0" [nzMax]="100" [nzStep]="1">
                      </nz-input-number>
                    <!-- </nz-input-group> -->
                  </nz-form-control>
                </nz-form-item>
              </form>
            </nz-modal>
            <!-- sp form -->
            <nz-modal [(nzVisible)]="isSpModal" nzTitle="Enter Strategic Perspective Weightage"
              (nzOnCancel)="spHandleCancel()" (nzOnOk)="spEditHandleSubmit()" nzOkText="Save" nzOkType="primary"
              [nzOkLoading]="isFormLoading" [nzOkDisabled]="!spForm.valid">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="spForm">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please input weightage!">
                    <nz-input-group nzSize="large">
                      <!-- <input formControlName="weightage" nz-input nzSize="large" placeholder="weightage" /> -->
                      <nz-input-number [nzSize]="'large'" class="w-100"  formControlName="weightage"
                    [nzMin]="0" [nzMax]="100" [nzStep]="1"></nz-input-number> 
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </form>
            </nz-modal>
            <!-- obj form -->
            <nz-modal [(nzVisible)]="isObjectiveModal" nzTitle="Create New Objective"
              (nzOnCancel)="objectiveHandleCancel()" nzOkText="Add" nzOkType="primary" [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!objectiveForm.valid" (nzOnOk)="objectiveHandleSubmit()">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="objectiveForm">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Objective" nzNoColon="false">Objective
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select Objective!">
                    <nz-input-group nzSize="large">
                      <nz-select nzSize="large" formControlName="objective_id" nzAllowClear placeholder="name">
                        <nz-option *ngFor="let item of objctiveMasterList" [nzLabel]="item.title" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Weightage" nzNoColon="false">Weightage</nz-form-label>
                  <nz-form-control nzErrorTip="Please input Weightage!">
                    <!-- <nz-input-group nzSize="large" nzAddOnAfter="%">
                      <input type="text" formControlName="weightage" nz-input nzSize="large" placeholder="Enter here" />
                    </nz-input-group> -->
                    <nz-input-group nzSize="large"  nzCompact>
                      <!-- <input type="text" nz-input formControlName="weightage" style="width: 87%;" /> -->
                      <nz-input-number [nzSize]="'large'"  style="width: 87%;" formControlName="weightage"
                    [nzMin]="0" [nzMax]="100" [nzStep]="1"></nz-input-number> 
                      <input type="text" class="titleCase" nz-input formControlName="display_uom" style="width:13%;" /> 
                    </nz-input-group> 
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Unit of Measurement (UOM)" nzNoColon="false">Unit of Measurement
                    (UOM)
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please input Unit of Measurement (UOM) !">
                    <nz-input-group nzSize="large">
                      <!-- <nz-select nzSize="large">
                        <nz-option nzValue="Unit of Measurement (UOM) 1" nzLabel="Unit of Measurement (UOM) 1">
                        </nz-option>
                        <nz-option nzValue="Unit of Measurement (UOM) 2" nzLabel="Unit of Measurement (UOM) 2">
                        </nz-option>
                      </nz-select> -->
                      <nz-select nzSize="large" formControlName="uom" nzAllowClear placeholder="UOM">
                        <nz-option *ngFor="let item of uomList" [nzLabel]="item.display" [nzValue]="item.value">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <!-- <nz-form-label nzRequired nzFor="Manager Objective" nzNoColon="false">Is Project Based</nz-form-label> -->
                  <nz-form-control nzErrorTip="Please input Is Project Based!">
                    <nz-input-group nzSize="large">
                      <label formControlName="is_project_based" nz-checkbox>Is Project Based</label>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- <nz-form-item>
                  <nz-form-label nzRequired nzFor="role_name" nzNoColon="false">Manager Role</nz-form-label>
                  <nz-form-control nzErrorTip="Please input manager role!">
                    <nz-input-group nzSize="large">
                      <nz-select nzSize="large" nzAllowClear nzPlaceHolder="Select Manager Role"
                      formControlName="role_objective_id">
                      <nz-option *ngFor="let item of managerRolesList" [nzLabel]="item.role_name" [nzValue]="item.parent_role_id">
                      </nz-option>
                    </nz-select>
                  </nz-input-group>
                  </nz-form-control>
                </nz-form-item> -->
                <nz-form-item>
                  <nz-form-label nzFor="Manager Objective" nzNoColon="false">Manager Objective
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please Select Manager Objective!">
                    <nz-input-group nzSize="large">
                      <!-- <input type="text" nz-input nzSize="large" placeholder="Enter here" /> -->
                      <nz-select nzSize="large" nzAllowClear nzPlaceHolder="Select Manager Objective"
                      formControlName="parent_objective_role_id">
                      <nz-option *ngFor="let item of managerRoleObjectiveList" [nzLabel]="item.objective_name" [nzValue]="item.parent_objective_role_id">
                      </nz-option>
                    </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- {{objectiveForm.value | json}} -->
              </form>

            </nz-modal>
            <div class="setup-content mt-4">
              <div class="row">
                <div class="col-md-4 offset-md-4 text-center">
                  <!-- <h5 class="my-2">   -->
                    <!-- <span *ngIf="roleObjectiveList.length == 0" >Create New Objective Set</span>  -->
                    <!-- <span *ngIf="roleObjectiveList.length > 0"> Select the period for which objective set is being defined</span> -->
                  <!-- <div>Available Objective Sets ({{roleObjectiveList.length}})</div>
                  </h5> -->
                  <div class="row">
                    <div class="col-md-8">
                      <div class="text-left my-2"><strong>Available Objective Sets ({{roleObjectiveList?.length}})</strong></div>
                    <form class="dropDownForm"   nz-form [nzLayout]="'vertical'" [formGroup]="roleObjectiveListForm">
                      <nz-select  (change)="onValueChanges()" nzSize="large" nzAllowClear nzPlaceHolder="Select Existing Objective Set"
                        formControlName="role_objective">
                        <nz-option *ngFor="let item of roleObjectiveList" [nzLabel]="item.name" [nzValue]="item.id">
                        </nz-option>
                      </nz-select> 
                    </form> 
                    </div>
                    <div class="col-md-4 plusBotton">
                      <h3 (click)="toggle()" class="searchIcon1"><i class="plusIcon" nz-icon nzType="plus-circle"
                        nzTheme="fill"></i></h3>
                    </div>
                    <div class="col-md-12 text-center">
                      <div *ngIf="selectedObjectiveSetDetails?.status" class="text-left my-2"><strong>Status: {{selectedObjectiveSetDetails?.status | titlecase}}</strong></div>
                    </div>
                  </div> 

                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-md-8 offset-md-2">
                    <form *ngIf="show" nz-form [nzLayout]="'vertical'" [formGroup]="objectiveDateForm"
                      (ngSubmit)="submitForm()">
                      <div class="row mt-5">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                          <nz-form-item>
                            <nz-form-label nzFor="website" nzRequired>From
                            </nz-form-label>
                            <nz-form-control>
                              <nz-date-picker formControlName="start_date" 
                              [nzFormat]="dateFormat" 
                              [nzDisabledDate]="disabledStartDate"
                              (nzOnOpenChange)="handleStartOpenChange($event)"
                              nzSize="large"></nz-date-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div class="col-md-4">
                          <nz-form-item>
                            <nz-form-label nzFor="website" nzRequired>To
                            </nz-form-label>
                            <nz-form-control>
                              <nz-date-picker 
                              formControlName="end_date" 
                              nzSize="large"
                              [nzFormat]="dateFormat"
                              #endDatePicker
                              [nzDisabledDate]="disabledEndDate"
                              (nzOnOpenChange)="handleEndOpenChange($event)"></nz-date-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-12 text-center">
                          <nz-form-item>
                            <nz-form-control>
                              <button nz-button [disabled]="!objectiveDateForm.valid" [ngClass]="{'bg-light text-muted' : !objectiveDateForm.valid }" nzType="primary">Save </button>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- <div class="row mt-5" *ngIf="isShowTbl"> -->
                  
                </div>
                <div class="row my-5">
                  <div class="col-md-12">
                    <ng-container *ngIf="treeData">
                      <nz-table nzBordered #expandTable [nzData]="treeData" nzTableLayout="fixed"
                        [nzFrontPagination]="false" [nzShowPagination]="false">
                        <thead>
                          <tr>
                            <th></th>
                            <th>SP Weightage</th>
                            <th>AP Weightage</th>
                            <th>Objective Weightage</th>
                            <th>UOM</th>
                            <th>Is Project Based</th>
                            <th>Manager Objective</th>
                            <th *ngIf="selectedObjectiveSetDetails?.status != 'propogated'">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let data of expandTable.data">
                            <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
                              <tr *ngIf="(item.parent && item.parent.expand) || !item.parent" >
                                <td [nzIndentSize]="item.level! * 20" [nzShowExpand]="!!item.children"
                                  [(nzExpand)]="item.expand"
                                  (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)">
                                  <span nz-tooltip [nzTooltipTitle]="item.name | titlecase"> {{ item.name | titlecase}}</span>
                                </td>
                                
                                <td>{{ item.type=='sp'? (item.weightage+ ' %'): '' }}</td>
                                <td>{{ item.type=='ap'? (item.weightage+ ' %'): '' }}</td>
                                <td>{{ item.type=='obj'? (item.weightage+ ' %'): ''}}</td>
                                <td>{{ item.type=='obj'? (item.uom | titlecase) : '' }} </td>
                                <td>{{ item.type=='obj'? (item.isProjectBased | titlecase) : ''}}</td>
                                <td>{{ item.type=='obj'? (item.manager_role_objective_name | titlecase) : ''}} </td>
                                <td *ngIf="selectedObjectiveSetDetails?.status != 'propogated'">
                                  <a *ngIf="item.type !== 'obj'" class="mr-2" (click)="openAddModal(item)">
                                    <i class="ri-add-fill grayIcon"></i>
                                  </a>
                                  <a class="mr-2" (click)="openEditModal(item)">
                                    <i class="ri-pencil-fill grayIcon" ></i>
                                  </a>
                                  <a *ngIf="item.type !== 'sp'" (click)="remove(item)">
                                    <i class="ri-delete-bin-6-fill grayIcon" ></i>
                                  </a>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </nz-table>
                    </ng-container>
                  </div>
                  <div *ngIf="treeData" class="col-md-12 ">
                    <nz-form-item class="text-center mt-4">
                      <nz-form-control>
                        <!-- <a [routerLink]="['/role']"> -->
                          <button *ngIf="selectedObjectiveSetDetails?.status != 'propogated'" (click)="Save()"  nz-button nzType="primary">Save</button>
                        <!-- </a> -->
                        <button *ngIf="selectedObjectiveSetDetails?.status == 'propogated'" [routerLink]="['/role']" nz-button nzType="primary" >Back</button>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>
                <!-- <div class="row text-center">
                  <div class="col-md-12 text-center">
                    <nz-form-item>
                      <nz-form-control>
                        <a [routerLink]="['/role']">
                          <button nz-button nzType="primary">Save</button>
                        </a>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>