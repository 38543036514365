import { Injectable, NgZone } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import * as AESJS from 'aes-js';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { environment } from '../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authenticated: boolean = false;
  private apiEndPoint = environment.apiEndPoint;

  constructor(private msalService: MsalService, public http: HttpClient, public ngZone: NgZone, public router: Router, private appService: AppService) {
    const accounts = this.msalService.instance.getAllAccounts();
    this.authenticated = accounts.length > 0;
    if (this.authenticated) {
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    // this.getUser(accounts[0]);
  }

  async signIn(): Promise<void> {
    const result = await this.msalService.loginPopup(environment.azure).toPromise().catch((error) => {
      console.log(error);
      alert('LOGIN FAILED');
    });

    if (result) {
      this.msalService.instance.setActiveAccount(result.account);
      this.authenticated = true;
      await this.getUser(result.account);
    }
  }

  async signOut(): Promise<void> {
    this.appService.removeUserToken().subscribe(async (res) => {
      this.authenticated = false;
      sessionStorage.removeItem('me');
      sessionStorage.removeItem('accountAccessToken');
      sessionStorage.removeItem('expires_at');
      sessionStorage.removeItem('Cookies');
      this.msalService.logout().toPromise().then();
      window.sessionStorage.clear();
      this.router.navigate(['/prelogin']);
    });
  }

  private encrypt(text: string, token: string, cipherIV: string) {
    const key = AESJS.utils.utf8.toBytes(token);
    const iv = AESJS.utils.utf8.toBytes(cipherIV);
    const aesCbc = new AESJS.ModeOfOperation.cbc(key, iv);
    const dataBytes = AESJS.utils.utf8.toBytes(text);
    const paddedData = AESJS.padding.pkcs7.pad(dataBytes);
    const encryptedBytes = aesCbc.encrypt(paddedData);
    const encryptedHex = AESJS.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  }

  private getUser(authResult: any): Promise<void> {
    if (!this.authenticated) return undefined;

    var token = 'mysecretpassword';
    var cipherIV = 'dc1461d223f65cc1';

    let encryptedEmail = this.encrypt(authResult.username, token, cipherIV);
    this.http.post(this.apiEndPoint + '/api/login_with_aad', { data: encryptedEmail }).subscribe((res: any) => {
      if (res.status == 'success') {
        this.ngZone.run(() => {
          sessionStorage.setItem('accountAccessToken', res['token']);
          sessionStorage.setItem('tokenexpiry', res['token_expiry']);
          this.appService.getLoggedInUserDetail().subscribe(res => {
            sessionStorage.setItem('me', btoa(JSON.stringify(res)));
            this.router.navigate(['/']);
          });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error in logging in, Please try again later!',
        });
      }
    }, err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error in logging in, Please try again later!',
      });
    });
  }

}
File
