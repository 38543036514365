import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-wise-drill-down-ap',
  templateUrl: './team-wise-drill-down-ap.component.html',
  styleUrls: ['./team-wise-drill-down-ap.component.css']
})
export class TeamWiseDrillDownApComponent implements OnInit {
  EnterpriseOutcome = '';
  constructor() { }

  ngOnInit(): void {
  }

}
