<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
            <div class="iq-card">
              <div class="iq-card-body">
                <div nz-col [nzSpan]="24" >
                    <a class="back-btn" nz-button nzType="link" (click)="back()">
                        <i class="ri-arrow-go-back-fill" ></i>
                    </a>
                </div>
                <form nz-form [nzLayout]="'vertical'" [formGroup]="tenantForm" (ngSubmit)="submitForm()">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mx-4">
                          <h4 class="my-3">Tenant Details</h4>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="tenant_name">Tenant Name
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Tenant Name!">
                              <input nz-input formControlName="tenant_name" nzSize="large"
                                placeholder="Tenant Name" id="tenant_name" OnlyCharacter/>
                            </nz-form-control>
                          </nz-form-item>

                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="Email">Tenant Admin Email
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Email!">
                              <input nz-input formControlName="tenant_admin_email" type="email" nzSize="large" placeholder="Email"
                                id="tenant_admin_email" />
                            </nz-form-control>
                          </nz-form-item>

                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="Email">Tenant Admin Notification Email
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Email!">
                              <input nz-input formControlName="tenant_admin_email_notifiction" type="email" nzSize="large" placeholder="Email"
                                id="tenant_admin_email_notifiction" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="aws_access_key">AWS Access Key
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Access Key!">
                              <input nz-input formControlName="aws_access_key" nzSize="large" placeholder="Access Key"
                                id="aws_access_key" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="aws_bucket">AWS Bucket
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter AWS Bucket!">
                              <input nz-input formControlName="aws_bucket" nzSize="large" placeholder="AWS Bucket"
                                id="aws_bucket" />
                            </nz-form-control>
                          </nz-form-item>
                        
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="aws_region_host">AWS Region Host
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter AWS Regioin Host!">
                              <input nz-input formControlName="aws_region_host" nzSize="large" placeholder="AWS Region Host"
                                id="aws_region_host" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="aws_region_name">AWS Region Name
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter AWS Region Name!">
                              <input nz-input formControlName="aws_region_name" nzSize="large" placeholder="AWS Region Name"
                                id="aws_region_name" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="aws_secret_key">AWS Secret Key
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter AWS Secret Key!">
                              <input nz-input formControlName="aws_secret_key" nzSize="large" placeholder="AWS Secret Key"
                                id="aws_secret_key" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label  nzFor="azure_account_connection_string">Azure Connection String
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Azure Connection String!">
                              <input nz-input formControlName="azure_account_connection_string" nzSize="large" placeholder="Azure Connection String"
                                id="azure_account_connection_string" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label   nzFor="azure_container_name">Azure Container Name
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Azure Container Name!">
                              <input nz-input formControlName="azure_container_name" nzSize="large" placeholder="Azure Container Name"
                                id="azure_container_name" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="db_connection_string">DB Connection String
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter DB Connection String!">
                              <input nz-input formControlName="db_connection_string" nzSize="large" placeholder="DB Connection String"
                                id="db_connection_string" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="storage_provider">Storage Provider
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Storage Provider!">
                              <input nz-input formControlName="storage_provider" nzSize="large" placeholder="Storage Provider"
                                id="storage_provider" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="storage_url">Storage URL
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Storage URL!">
                              <input nz-input formControlName="storage_url" nzSize="large" placeholder="Storage URL"
                                id="storage_url" />
                            </nz-form-control>
                          </nz-form-item>
                         
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="smtp_hostname">SMTP Hostname
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter SMTP Hostname!">
                              <input nz-input formControlName="smtp_hostname" nzSize="large" placeholder="SMTP Hostname"
                                id="smtp_hostname" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="smtp_username">SMTP Username
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter SMTP Username!">
                              <input nz-input formControlName="smtp_username" nzSize="large" placeholder="SMTP Username"
                                id="smtp_username" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="smtp_password">SMTP Password
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter SMTP Password!">
                              <input nz-input formControlName="smtp_password" type="password" nzSize="large" placeholder="SMTP Password"
                                id="smtp_password" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="subdomain">SMTP Password
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter SMTP Password!">
                              <input nz-input formControlName="subdomain"  nzSize="large" placeholder="SMTP Password"
                                id="subdomain" />
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="business_days">No. of Business Days</nz-form-label>
                            <nz-form-control nzErrorTip="Please input No. of Business Days!">
                              <nz-input-group>
                                <nz-input-number [nzSize]="'large'" formControlName="business_days"
                                  [nzMin]="1" [nzMax]="7" [nzStep]="1"
                                  [nzPrecision]="0">
                                </nz-input-number>
                              </nz-input-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div> 
                      <div class="col-md-6">
                        <div class="mx-4">
                          <h4 class="my-3">Module Details</h4>
                          <nz-form-item>
                            <nz-form-label  nzFor="enable_assessment_centre" nzRequired>Assessment Centre    </nz-form-label>
                              <nz-form-control nzErrorTip="Please Select an Option!">
                                <nz-radio-group   nzSize="large" formControlName="enable_assessment_centre">
                                  <label nz-radio nzValue="yes">Enable</label>
                                  <label nz-radio nzValue="no">Disable</label>
                                </nz-radio-group>
                              </nz-form-control> 
                          </nz-form-item>
                     
                          <nz-form-item>
                            <nz-form-label nzFor="enable_labelled_content" nzRequired>Labelled Content
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_labelled_content">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_learning_assessment" nzRequired>Learning Assessment
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_learning_assessment">
                                <label nz-radio nzValue="yes" >Enable</label>
                                <label nz-radio nzValue="no">Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_profit_maximiser" nzRequired>Profit Maximiser 
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group  nzSize="large" formControlName="enable_profit_maximiser">
                                <label nz-radio nzValue="yes" >Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group >
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_behaviour_transaction" nzRequired>Behaviour Transaction 
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_behaviour_transaction">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_gap_analysis" nzRequired>Gap Analysis 
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_gap_analysis">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_9box" nzRequired>9 Box 
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_9box">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_survey" nzRequired>Survey 
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_survey">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_360" nzRequired>360 Feedback
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_360">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_premium_360" nzRequired>Premium 360 Feedback
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_premium_360">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_learnig_reinforcement" nzRequired>Learning Reinforcement
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_learnig_reinforcement">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_hrapp" nzRequired>HR APP
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_hrapp">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                          <nz-form-item>
                            <nz-form-label nzFor="enable_lms" nzRequired>LMS
                            </nz-form-label>
                            <nz-form-control nzErrorTip="Please Select an Option!">
                              <nz-radio-group nzSize="large" formControlName="enable_lms">
                                <label nz-radio nzValue="yes">Enable</label>
                                <label nz-radio nzValue="no" >Disable</label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>    
                    </div> 
                    <div class="row">
                      <div class="col-md-12 mt-4 text-center">
                        <nz-form-item>
                          <nz-form-control>
                            <button nz-button nzSize="large" nzType="default" (click)="resetForm()">Reset</button>
                            <!-- <button class="ml-2" nz-button nzSize="large" nzType="primary"
                              [disabled]="!employeeForm.valid">Save</button> -->
                            <button class="ml-2" nz-button nzSize="large" nzType="primary" [disabled]="!tenantForm.valid || isFormLoading">
                              <i nz-icon [nzType]="isFormLoading && tenantForm.valid ? 'loading' : ''"></i>
                              Save
                            </button>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                </form>        
                
              </div>
            </div>
        </div>    
      </div>
    </div>
</div>    