import { SharedModule } from './shared/shared.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { CampaignDetailsFormComponent } from './pages/behaviour-transactions/campaign-details-form/campaign-details-form.component';
import { TransactionGridComponent } from './pages/behaviour-transactions/transaction-grid/transaction-grid.component';
import { ListOfTransactionsComponent } from './pages/behaviour-transactions/list-of-transactions/list-of-transactions.component';
import { ListOfCampaignsComponent } from './pages/behaviour-transactions/list-of-campaigns/list-of-campaigns.component';
import { BehaviourTransactionsComponent } from './pages/behaviour-transactions/behaviour-transactions.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ProfileComponent } from './pages/profile/profile.component';
import { ActivityWiseDrillDownComponent } from './pages/maximizer-dashboard/objective-performance/activity-wise-drill-down/activity-wise-drill-down.component';
import { ReportComponent } from './pages/maximizer-dashboard/report/report.component';
import { TeamWiseDrillDownApComponent } from './pages/maximizer-dashboard/objective-performance/team-wise-drill-down-ap/team-wise-drill-down-ap.component';
import { TeamWiseDrillDownComponent } from './pages/maximizer-dashboard/objective-performance/team-wise-drill-down/team-wise-drill-down.component';
import { InitiativesDrillDownComponent } from './pages/maximizer-dashboard/objective-performance/initiatives-drill-down/initiatives-drill-down.component';
import { ObjectiveByMonthComponent } from './pages/maximizer-dashboard/objective-performance/objective-by-month/objective-by-month.component';
import { ActivityComponent } from './pages/maximizer-dashboard/activity/activity.component';
import { ObjectiveComponent } from './pages/role/objective/objective.component';
import { enIN } from 'date-fns/locale';
import { SettingsComponent } from './pages/settings/settings.component';
import { OrderHistoryComponent } from './pages/maximizer-setup/order-history/order-history.component';
import { ObjectivePerformanceComponent } from './pages/maximizer-dashboard/objective-performance/objective-performance.component';
import { ProjectsComponent } from './pages/maximizer-dashboard/projects/projects.component';
import { MaximizerDashboardComponent } from './pages/maximizer-dashboard/maximizer-dashboard.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FooterComponent } from './themes/components/footer/footer.component';
import { AppInterceptor } from './themes/utils/app-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppSettings } from './app.settings';
import { TopNavBar1Component } from './themes/components/top-nav-bar1/top-nav-bar1.component';
import { LeftMenuPanelComponent } from './themes/components/left-menu-panel/left-menu-panel.component';
import { RightOnlineListComponent } from './themes/components/right-online-list/right-online-list.component';
import { RoutingTestComponent } from './pages/routing-test/routing-test.component';
import { LoginComponent } from './shared/login/login.component';
import { BusinessUnitComponent } from './pages/business-unit/business-unit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_DATE_LOCALE, NZ_I18N, en_GB } from 'ng-zorro-antd/i18n';
import { registerLocaleData, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { LocationComponent } from './pages/location/location.component';
import { AssessmentCenterComponent } from './pages/assessment-center/assessment-center.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FunctionComponent } from './pages/function/function.component';
import { DepartmentComponent } from './pages/department/department.component';
import { StrategicPerspectivesComponent } from './pages/strategic-perspectives/strategic-perspectives.component';
import { AnnualPriorityComponent } from './pages/annual-priority/annual-priority.component';
import { ObjectiveMasterComponent } from './pages/objective-master/objective-master.component';
import { ObjectivesComponent } from './pages/maximizer-dashboard/objectives/objectives.component';
import { SetupObjectivesComponent } from './pages/maximizer-dashboard/setup-objectives/setup-objectives.component';
import { InitiativesComponent } from './pages/maximizer-dashboard/initiatives/initiatives.component';
import { SetupInitiativesComponent } from './pages/maximizer-dashboard/setup-initiatives/setup-initiatives.component';
import { DetailInitiativesComponent } from './pages/maximizer-dashboard/detail-initiatives/detail-initiatives.component';
import { ObjectiveTargetAnalysisComponent } from './pages/maximizer-dashboard/objective-target-analysis/objective-target-analysis.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
// import { FullCalendarModule } from '@fullcalendar/angular/lib/fullcalendar.module';
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
// import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { MonthlyObjectivePerformanceComponent } from './pages/maximizer-dashboard/objective-performance/monthly-objective-performance/monthly-objective-performance.component';
import { YtdPerformanceComponent } from './pages/maximizer-dashboard/objective-performance/ytd-performance/ytd-performance.component';
import { YtdObjectivePerformanceComponent } from './pages/maximizer-dashboard/objective-performance/ytd-objective-performance/ytd-objective-performance.component';
import { MaximizerSetupComponent } from './pages/maximizer-setup/maximizer-setup.component';
import { RoleComponent } from './pages/role/role.component';
import { BasicDetailsComponent } from './pages/maximizer-setup/basic-details/basic-details.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { GradeComponent } from './pages/grade/grade.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EmployeeComponent } from './pages/employee/employee.component';
import { CompetencyComponent } from './pages/competency/competency.component';
import { EmployeeDetailComponent } from './pages/employee/employee-detail/employee-detail.component';
import { LearningAssessmentComponent } from './pages/learning-assessment/learning-assessment.component';
import { LabelledContentComponent } from './pages/labelled-content/labelled-content.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CampaignDashboardComponent } from './pages/behaviour-transactions/campaign-dashboard/campaign-dashboard.component';
import { QuillModule } from 'ngx-quill';
import { DesiredLevelComponent } from './pages/desired-level/desired-level.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AutocompleteOffDirective} from './shared/directives/autocomplete-off.directive';
import {ActivityLogComponent} from './pages/activity-log/activity-log.component';
import {NzAutocompleteModule} from 'ng-zorro-antd/auto-complete';
import {NzSpaceModule} from 'ng-zorro-antd/space';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { NgAuthService } from './ng-auth.service';
import { PreloginComponent } from './shared/prelogin/prelogin.component';
import { EmployeeReportComponent } from './pages/employee-report/employee-report.component';
import { PiiComponent } from './pages/pii/pii.component';
import { TermsAndConitionsComponent } from './pages/terms-and-conitions/terms-and-conitions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { TruleadRedirectionComponent } from './shared/trulead-redirection/trulead-redirection.component';
import { HrappRedirectionComponent } from './shared/hrapp-redirection/hrapp-redirection.component';
import { LmsRedirectionComponent } from './shared/lms-redirection/lms-redirection.component';
import { AdminDashComponent } from './pages/admin-dash/admin-dash.component';
import { CharacterDirective } from '../app/shared/directives/character.directive';
import { NumSplDirective } from '../app/shared/directives/numbers-only.directive';
import { SpclonlyDirective } from '../app/shared/directives/speclonly.directive';
import { PremiumThreeSixtyFeedbackComponent } from './pages/premium-three-sixty-feedback/premium-three-sixty-feedback.component';
import { TagsComponent } from './pages/tags/tags.component';
import { TenantComponent } from './pages/tenant/tenant.component';
import { TenantDetailComponent } from './pages/tenant/tenant-detail/tenant-detail.component';

import { BehaviourStructureComponent } from './pages/behaviour-structure/behaviour-structure.component';
import { LeadIndicationComponent } from './pages/lead-indication/lead-indication.component';
import { LagIndicationComponent } from './pages/lag-indication/lag-indication.component';
import { CurrentIndicationComponent } from './pages/current-indication/current-indication.component';
import { FeaturesComponent } from './pages/features/features.component';
import { TalentCardFormComponent } from './pages/talent-card-form/talent-card-form.component';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { TalentcardListComponent } from './pages/talentcard-list/talentcard-list.component';
import { TicketsDashboardComponent } from './pages/tickets-dashboard/tickets-dashboard.component';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { QuickThreeSixtyReportComponent } from './pages/reports/quick-three-sixty-report/quick-three-sixty-report.component';

let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance = msalInstance ?? new PublicClientApplication({
    auth: {
      clientId: environment.azure.appId,
      redirectUri: environment.azure.redirectUri,
      postLogoutRedirectUri: environment.azure.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }
  });
  return msalInstance;
}
import { ContinuousThreeSixtyComponent } from './pages/reports/continuous-three-sixty/continuous-three-sixty.component';
import { QuickThreeSixtyDashboardComponent } from './pages/reports/quick-three-sixty-dashboard/quick-three-sixty-dashboard.component';
import { LrEnhancementComponent } from './pages/lr-enhancement/lr-enhancement.component';
import { MyNudgesComponent } from './pages/my-nudges/my-nudges.component';
import {AjnaRedirectionComponent} from './shared/ajna-redirection/ajna-redirection.component'
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FormListModule} from "./pages/form/form-list.module";
import {FormListComponent} from "./pages/form/form-list.component";
import {FormViewComponent} from "./pages/form/view/form-view.component";
import {LaunchCreateComponent} from "./pages/form/launch/create/launch-create.component";
import {FormLaunchComponent} from "./pages/form/launch/form-launch.component";
import {FormAddComponent} from "./pages/form/add/form-add.component";
import {FormSubmissionComponent} from "./pages/form/submission/form-submission.component";
import {FormSubmissionsComponent} from "./pages/form/submissions/form-submissions.component";
import {FormMyComponent} from "./pages/form/my/form-my.component";
import {FormApprovalComponent} from "./pages/form/approval/form-approval.component";
import {FormTeamComponent} from "./pages/form/team/form-team.component";
import {FormDashboardComponent} from "./pages/form/dashboard/form-dashboard.component";
import {FormReportComponent} from "./pages/form/report/form-report.component";
import {PipesModule} from "./themes/pipes/pipes.module";
 registerLocaleData(en);

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  listPlugin,
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    FooterComponent,
    TopNavBar1Component,
    LeftMenuPanelComponent,
    RightOnlineListComponent,
    RoutingTestComponent,
    BusinessUnitComponent,
    LocationComponent,
    FunctionComponent,
    DepartmentComponent,
    GradeComponent,
    EmployeeComponent,
    CompetencyComponent,
    AssessmentCenterComponent,
    LearningAssessmentComponent,
    LabelledContentComponent,
    StrategicPerspectivesComponent,
    AnnualPriorityComponent,
    ObjectiveMasterComponent,
    LoginComponent,
    MaximizerDashboardComponent,
    ObjectivesComponent,
    SetupObjectivesComponent,
    InitiativesComponent,
    SetupInitiativesComponent,
    DetailInitiativesComponent,
    ProjectsComponent,
    ObjectiveTargetAnalysisComponent,
    ObjectivePerformanceComponent,
    MonthlyObjectivePerformanceComponent,
    YtdPerformanceComponent,
    YtdObjectivePerformanceComponent,
    MaximizerSetupComponent,
    RoleComponent,
    BasicDetailsComponent,
    OrderHistoryComponent,
    EmployeeDetailComponent,
    ObjectiveComponent,
    ActivityComponent,
    ObjectiveByMonthComponent,
    InitiativesDrillDownComponent,
    TeamWiseDrillDownComponent,
    TeamWiseDrillDownApComponent,
    ReportComponent,
    ActivityWiseDrillDownComponent,
    SettingsComponent,
    ProfileComponent,
    // Behaviour Trasaction start
    CampaignDashboardComponent,
    BehaviourTransactionsComponent,
    ListOfCampaignsComponent,
    ListOfTransactionsComponent,
    TransactionGridComponent,
    CampaignDetailsFormComponent,
    DesiredLevelComponent,
    AutocompleteOffDirective,
    ActivityLogComponent,
    PreloginComponent,
    EmployeeReportComponent,
    PiiComponent,
    TermsAndConitionsComponent,
    PrivacyPolicyComponent,
    // Behaviour Trasaction ends
    TruleadRedirectionComponent,
    HrappRedirectionComponent,
    LmsRedirectionComponent,
    AdminDashComponent,
    CharacterDirective,
    SpclonlyDirective,
    NumSplDirective,
    TagsComponent,
    TenantComponent,
    TenantDetailComponent,
    PremiumThreeSixtyFeedbackComponent,
    TagsComponent,
    BehaviourStructureComponent,
    LeadIndicationComponent,
    LagIndicationComponent,
    CurrentIndicationComponent,
    FeaturesComponent,
    TalentCardFormComponent,
    TicketsComponent,
    TalentcardListComponent,
    TicketsDashboardComponent,
    QuickThreeSixtyReportComponent,
    ContinuousThreeSixtyComponent,
    QuickThreeSixtyDashboardComponent,
    LrEnhancementComponent,
    MyNudgesComponent,
    AjnaRedirectionComponent,
    FormListComponent,
    FormAddComponent,
    FormViewComponent,
    FormSubmissionComponent,
    FormSubmissionsComponent,
    FormLaunchComponent,
    LaunchCreateComponent,
    FormMyComponent,
    FormTeamComponent,
    FormApprovalComponent,
    FormDashboardComponent,
    FormReportComponent,

  ],
    imports: [
        // AngularFireModule.initializeApp(environment.firebase),
        // AngularFireAuthModule,
        // AngularFirestoreModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NzTableModule,
        NzFormModule,
        NzModalModule,
        NzInputModule,
        NzTagModule,
        NzButtonModule,
        NzMessageModule,
        NzSelectModule,
        NzStepsModule,
        NzDatePickerModule,
        NzCheckboxModule,
        NzIconModule,
        NzCarouselModule,
        NzCardModule,
        NzProgressModule,
        NzDropDownModule,
        NzRateModule,
        NzGridModule,
        NzListModule,
        NzInputNumberModule,
        NzDividerModule,
        NzUploadModule,
        NzRadioModule,
        ColorPickerModule,
        NzAlertModule,
        FullCalendarModule,
        NzCollapseModule,
        NzPaginationModule,
        NzTabsModule,
        NzBadgeModule,
        NzToolTipModule,
        NzPopoverModule,
        NzAvatarModule,
        NzCommentModule,
        NzSwitchModule,
        NzBreadCrumbModule,
        NzTimePickerModule,
        NzSpinModule,
        QuillModule.forRoot(),
        NgxSpinnerModule,
        NzAutocompleteModule,
        NzSpaceModule,
        NzPopconfirmModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        // Added for Form Module by Surajit Pramanik
        DragDropModule,
        FormListModule,
        PipesModule,
        // End Added for Form Module by Surajit Pramanik
    ],
  providers: [
    AppSettings,
    NgAuthService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_GB },
    // { provide: NZ_DATE_LOCALE, useValue: enIN }
    NzMessageService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
