<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="iq-card bgTransparent">
          <div class="iq-card-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="profilePicBox">
                  <!-- <img *ngIf="!employeeFormEditDetails?.profile_pic?.original_image_url" src="../../../assets/images/icon/entrepreneur-593358_1920-1@2x.png" alt="Profile Picture"> -->
                  <nz-avatar *ngIf="!employeeFormEditDetails?.profile_pic?.original_image_url" [nzSize]="152"
                    nzIcon="user"></nz-avatar>
                  <nz-avatar *ngIf="employeeFormEditDetails?.profile_pic?.original_image_url" [nzSize]="152"
                    [nzSrc]="storageAccount+employeeFormEditDetails?.profile_pic?.original_image_url"></nz-avatar>

                  <!-- <img *ngIf="employeeFormEditDetails?.profile_pic?.original_image_url" [src]="employeeFormEditDetails?.profile_pic?.original_image_url" alt="Profile Picture"> -->

                  <span> <a (click)="showProfileModal()"> <i nz-icon class="editIcon" nzType="edit"
                        nzTheme="outline"></i></a></span>
                  <div class="title">
                    {{ employeeFormEditDetails?.display_name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Profile Modal  -->
      <nz-modal [(nzVisible)]="isVisibleProfileModal" nzTitle="Update Profile Picture" (nzOnOk)="handleModalSubmit()"
        (nzOnCancel)="profileModalHandleCancel()" nzOkText="Update" nzOkType="primary"
        [nzOkLoading]="isProfileFormLoading" [nzOkDisabled]="!avatarUrl">
        <form nz-form [nzLayout]="'vertical'" [formGroup]="profileForm">
          <!-- <nz-form-item>
          <nz-form-control nzErrorTip="Please upload Profile Picture!">
            <nz-upload nzSize="large" formControlName="profile_pic" ngDefaultControl [(nzFileList)]="fileList"
              [nzBeforeUpload]="beforeUpload">
              <a nz-button nzType="primary"><i nz-icon nzType="upload"></i>Upload
                Profile Picture</a>
            </nz-upload>
          </nz-form-control>
        </nz-form-item> -->
          <nz-input-group>
            <div nz-row [nzGutter]="8">
              <div nz-col nzSpan="7">
                <nz-form-item>
                  <nz-form-label>Profile Picture</nz-form-label>
                  <nz-form-control nzErrorTip="Please upload Profile Picture!">
                    <nz-input-group [nzSize]="'large'">
                      <nz-upload class="avatar-uploader" nzName="avatar" formControlName="profile_pic"
                        nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
                        (nzChange)="handleChange($event)">
                        <ng-container *ngIf="!avatarUrl">
                          <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
                          <div class="ant-upload-text">Upload</div>
                        </ng-container>
                        <img *ngIf="avatarUrl" [src]="avatarUrl" class="imagePreviewBox" />
                      </nz-upload>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <a data-toggle="tooltip" data-placement="right" title="Reset Profile Picture" (click)="resetProfileImage()"><i class="ri-restart-line"></i></a>
              </div>
            </div>
          </nz-input-group>
        </form>
        <!-- {{profileForm.value | json}}  -->
      </nz-modal>


      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <nz-tabset [nzTabPosition]="nzTabPosition" [nzCanDeactivate]="canDeactivate">
              <!--tab Personal Information  -->
              <nz-tab nzTitle="Personal Information">
                <div class="pl-4">
                  <div class="row">
                    <div class="col-md-6">
                      <h4 class="">About</h4>
                    </div>
                    <div class="col-md-6 text-right">
                      <a (click)="editProfile()" class="iconBox d-none"> <i nz-icon class="grayIcon" nzType="edit"
                          nzTheme="outline"></i></a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <nz-divider></nz-divider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <form id="personalInfoForm" nz-form [nzLayout]="'vertical'" [formGroup]="pIForm">
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter First Name!">First Name</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" (blur)="setDisplayName()" placeholder="First Name"
                              formControlName="first_name" type="text" id="first_name">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Middle Name!">Middle Name</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" placeholder="Middle Name" formControlName="middle_name"
                              type="text" id="middle_name">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Last Name!">Last Name</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" (blur)="setDisplayName()" placeholder="Last Name"
                              class="Last Name" formControlName="last_name" type="text" id="last_name">
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Employee Id!">Employee Id</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Employee Id" formControlName="employee_id" type="text" id="employee_id">
                        </nz-form-control>
                      </nz-form-item> -->
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Email id!">Email id</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" placeholder="Email id" formControlName="email" type="text"
                              id="email">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-divider></nz-divider>
                        <nz-form-item>
                          <nz-form-label nzFor="website">Birth of Year
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please input DOB!">
                            <nz-date-picker nzSize="large" nzMode="year" formControlName="dob" id="dob"
                              [nzFormat]="dateFormat" [nzDisabledDate]="disabledDate">
                            </nz-date-picker>
                          </nz-form-control>
                          <!-- <nz-form-label [nzSpan]="5" nzFor="Please Enter Date of Birth!">Date of Birth</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <nz-date-picker nzSize="large" placeholder="Enter Date of Birth" nz-input
                              formControlName="dob" type="text" id="dob"></nz-date-picker>
                          </nz-form-control> -->
                        </nz-form-item>
                        <!-- <nz-divider></nz-divider> -->

                        <nz-form-item>
                          <nz-form-label nzFor="Gender" nzRequired>Gender
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Gender!">
                            <nz-select nzSize="large" formControlName="gender">
                              <nz-option nzValue="Male" nzLabel="Male"></nz-option>
                              <nz-option nzValue="Female" nzLabel="Female"></nz-option>
                              <nz-option nzValue="Not Disclosed" nzLabel="Not Disclosed"></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-divider></nz-divider> -->
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Display Name!">Display Name</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" placeholder="Display Name" formControlName="display_name"
                              type="text" id="display_name">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <button nz-button nzSize="large" class="custBtn" (click)="pIFormSubmit()"
                            [disabled]="!pIForm.valid || isFormLoading" nzType="primary">
                            <i nz-icon [nzType]="isFormLoading && pIForm.valid ? 'loading' : ''"></i>
                            Save Changes
                          </button>
                        </nz-form-item>
                      </form>
                      <!-- {{pIForm.value | json}} -->
                    </div>
                  </div>
                </div>
              </nz-tab>
              <!-- tab Change Password  -->
              <nz-tab nzTitle="Change Password">
                <div class="pl-4">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="">Change Password</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <nz-divider></nz-divider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <form id="changePasswordform" [formGroup]="cPForm" nz-form [nzLayout]="'vertical'">
                        <!-- <nz-form-item>
                        <nz-form-label nzRequired nzFor="username">Username
                        </nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Username!">
                            <input nz-input formControlName="username" nzSize="large"
                                placeholder="Username" id="username" />
                        </nz-form-control>
                    </nz-form-item> -->
                        <!-- <nz-form-item>
                          <nz-form-label nzFor="name">Password
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Enter Password!">
                            <input nz-input formControlName="password" type="password" nzSize="large"
                              placeholder="Password" id="password" />
                            <span class="d-block mt-1">Enter a new password to modify the existing one</span>
                          </nz-form-control>
                        </nz-form-item> -->
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Old Password!">Old Password</nz-form-label>
                          <nz-form-control [nzSpan]="19" nzErrorTip="Old Password is required and have 8 characters">
                            <input type="password" nz-input nzSize="large" formControlName="old_password"
                              placeholder="Old Password" id="old_password">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter New Password!">New Password</nz-form-label>
                          <nz-form-control [nzSpan]="19" nzErrorTip="New Password is required and have 8 characters">
                            <input type="password" nz-input nzSize="large" placeholder="New Password" formControlName="new_password" id="new_password">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Confirm Password!">Confirm Password
                          </nz-form-label>
                          <nz-form-control [nzSpan]="19"
                            nzErrorTip="Confirm password is required and have 8 characters">
                            <input type="password" nz-input  nzSize="large" placeholder="Confirm Password"
                              formControlName="confirm_password"id="confirm_password">
                          </nz-form-control>
                          <label style="color:red; font-size: 14px;" *ngIf="!confirmedPass">Password Not Matching</label>
                        </nz-form-item>
                        <nz-form-item>
                          <button nz-button nzSize="large" class="custBtn" (click)="cPFormSubmit()"
                            [disabled]="!cPForm.valid || isFormLoading" nzType="primary">
                            <i nz-icon [nzType]="isFormLoading && cPForm.valid ? 'loading' : ''"></i>
                            <!-- <i nz-icon [nzType]="isFormLoading ? 'loading' : ''"></i> -->
                            Save Changes
                          </button>
                        </nz-form-item>
                      </form>
                      <!-- {{cPForm.value | json}} -->
                    </div>
                  </div>
                </div>
              </nz-tab>
              <!-- tab Organization Information  -->
              <nz-tab nzTitle="Organization Information">
                <div class="pl-4">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="">Organization Information</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <nz-divider></nz-divider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <form id="organizationInfoForm" [formGroup]="oIForm" nz-form [nzLayout]="'vertical'">
                        <!-- <nz-form-item >
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Business Unit!">Business Unit</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Business Unit" name="business_unit" type="text" id="business_unit">
                        </nz-form-control>
                      </nz-form-item> -->
                        <nz-form-item>
                          <nz-form-label nzFor="Business Unit Id" nzRequired>Business Unit
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Business Unit!">
                            <!-- <nz-select  nzSize="large" nzAllowClear
                                nzPlaceHolder="Select Business Unit"
                                formControlName="business_unit_id">
                                <nz-option *ngFor="let item of bUOptionList"
                                    [nzLabel]="item.name"
                                    [nzValue]="item.id"></nz-option>
                            </nz-select> -->
                            <nz-select placeholder="Select Business Unit" nzSize="large" nzAllowClear nzShowSearch
                              nzServerSearch formControlName="business_unit_id"
                              (nzOnSearch)="onBusinessUnitOptionListSearch($event)"
                              (nzBlur)="getLocations(); getFunctions(); getGrades()">
                              <ng-container *ngFor="let o of bUOptionList">
                                <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                                </nz-option>
                              </ng-container>
                              <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Function!">Function</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Function" name="function" type="text" id="function">
                        </nz-form-control>
                      </nz-form-item> -->
                        <nz-form-item>
                          <nz-form-label nzFor="location Id" nzRequired>Location
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Location!">
                            <!-- <nz-select  nzSize="large" nzAllowClear
                                nzPlaceHolder="Select Location"
                                formControlName="location_id">
                                <nz-option *ngFor="let item of locationOptionList"
                                    [nzLabel]="item.name"
                                    [nzValue]="item.id"></nz-option>
                            </nz-select> -->
                            <nz-select placeholder="Select Location" nzSize="large" nzAllowClear nzShowSearch
                              nzServerSearch formControlName="location_id"
                              (nzOnSearch)="onLocationOptionListSearch($event)">
                              <ng-container *ngFor="let o of locationOptionList">
                                <nz-option *ngIf="!isLocationOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                                </nz-option>
                              </ng-container>
                              <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label nzFor="function_id" nzRequired>Function
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Function!">
                            <nz-select placeholder="Select Function" nzSize="large" nzAllowClear nzShowSearch
                              nzServerSearch formControlName="function_id"
                              (nzOnSearch)="onFunctionOptionListSearch($event)" (nzBlur)="getDepartments()">
                              <ng-container *ngFor="let o of functionOptionList">
                                <nz-option *ngIf="!isFunctionOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                                </nz-option>
                              </ng-container>
                              <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Department!">Department</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Department" name="department" type="text" id="department">
                        </nz-form-control>
                      </nz-form-item>  -->
                        <nz-form-item>
                          <nz-form-label nzFor="department_id" nzRequired>Department
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Department!">
                            <nz-select placeholder="Select Department" nzSize="large" nzAllowClear nzShowSearch
                              nzServerSearch formControlName="department_id"
                              (nzOnSearch)="onDepartmentOptionListSearch($event)">
                              <ng-container *ngFor="let o of departmentOptionList">
                                <nz-option *ngIf="!isDepartmentOptionListLoading" [nzValue]="o.id" [nzLabel]="o.name">
                                </nz-option>
                              </ng-container>
                              <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Grade!">Grade</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Grade" name="grade" type="text" id="grade">
                        </nz-form-control>
                      </nz-form-item>  -->
                        <nz-form-item>
                          <nz-form-label nzFor="grade_id" nzRequired>Grade
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Grade!">
                            <nz-select placeholder="Select Grade" nzSize="large" nzAllowClear nzShowSearch
                              nzServerSearch formControlName="grade_id" (nzOnSearch)="onGradeOptionListSearch($event)">
                              <ng-container *ngFor="let o of gradeOptionList">
                                <nz-option *ngIf="!isGradeOptionListLoading" [nzValue]="o.id" [nzLabel]="o.grade_name">
                                </nz-option>
                              </ng-container>
                              <nz-option *ngIf="isGradeOptionListLoading" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Designation!">Designation</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" placeholder="Designation" formControlName="designation"
                              type="text" id="designation">
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter Date of Joining!">Date of Joining
                          </nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <!-- <input nzSize="large" nz-input name="doj" type="text" id="doj"> -->
                            <nz-date-picker nzSize="large" nzPlaceHolder="Date of Joining" nz-input
                              formControlName="joining_date" type="text" id="doj"></nz-date-picker>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label nzFor="role_id">Role
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Select Role!">
                            <nz-select placeholder="Select Role" nzSize="large" nzAllowClear nzShowSearch nzServerSearch
                              formControlName="role_id">
                              <ng-container *ngFor="let o of roleOptionList">
                                <nz-option [nzValue]="o.id" [nzLabel]="o.role_name">
                                </nz-option>
                              </ng-container>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Manager!">Manager</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Manager" name="manager" type="text" id="manager">
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Reporting Manager!">Reporting Manager</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Reporting Manager" name="reporting_manager" type="text" id="reporting_manager">
                        </nz-form-control>
                      </nz-form-item> -->
                        <nz-form-item>
                          <nz-form-label nzFor="Reporting Manager">Reporting Manager
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please Enter Reporting Manager!">
                            <nz-select placeholder="Reporting Manager" nzSize="large" nzAllowClear nzShowSearch
                              nzServerSearch formControlName="reporting_manager"
                              (nzOnSearch)="onEmployeeOptionListSearch($event)">
                              <ng-container *ngFor="let o of employeeOptionList">
                                <nz-option *ngIf="!isEmployeeOptionListLoading" [nzValue]="o.id"
                                  [nzLabel]="o.display_name">
                                </nz-option>
                              </ng-container>
                              <nz-option *ngIf="isEmployeeOptionListLoading" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                        <!-- <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Functional Head!">Functional Head</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Functional Head" name="functional_head" type="text" id="functional_head">
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item>
                        <nz-form-label [nzSpan]="5" nzFor="Please Enter Department Head!">Department Head</nz-form-label>
                        <nz-form-control [nzSpan]="19">
                          <input nz-input nzSize="large" placeholder="Department Head" name="department_head" type="text" id="department_head">
                        </nz-form-control>
                      </nz-form-item> -->
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please Select Active!">
                            <label nz-checkbox formControlName="is_active">Active</label>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please Select Manager!">
                            <label nz-checkbox formControlName="is_manager">Manager</label>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please Select Department Head!">
                            <label nz-checkbox formControlName="is_department_head">Department
                              Head</label>
                          </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please Select Functional Head!">
                            <label nz-checkbox formControlName="is_functional_head">Functional
                              Head</label>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please Select BU H ead!">
                            <label nz-checkbox formControlName="is_bu_head">BU Head</label>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please Select Admin!">
                            <label nz-checkbox formControlName="is_admin">Admin</label>
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <button class="custBtn" nz-button nzSize="large" (click)="oIFormSubmit()"
                            [disabled]="!oIForm.valid" nzType="primary">
                            <i nz-icon [nzType]="isFormLoading && oIForm.valid ? 'loading' : ''"></i>
                            Save Changes
                          </button>
                        </nz-form-item>
                        <!-- {{oIForm.value | json}} -->
                      </form>
                    </div>
                  </div>
                </div>
              </nz-tab>

              <!-- tab Change Time Zone  -->
              <nz-tab nzTitle="Change Time Zone">
                <div class="pl-4">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="">Change Time Zone</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <nz-divider></nz-divider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="timeZoneForm" nz-form [nzLayout]="'vertical'">
                        <nz-form-item>
                          <nz-form-label nzRequired nzFor="time_zone" nzNoColon="false">Time Zone</nz-form-label>
                          <nz-form-control nzErrorTip="Time Zone is mandatory.">
                            <nz-input-group>
                              <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Time Zone" formControlName="time_zone">
                                <nz-option *ngFor="let zone of timeZones" [nzLabel]="zone?.country?.country + '(' + zone?.timezone_code + ')'"
                                  [nzValue]="zone.id"></nz-option>
                              </nz-select>
                            </nz-input-group>
                          </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                          <button class="custBtn" nz-button nzSize="large" (click)="submitTimeZoneForm()"
                            [disabled]="!timeZoneForm.valid || isFormLoading" nzType="primary">
                            <i nz-icon [nzType]="isFormLoading && timeZoneForm.valid ? 'loading' : ''"></i>
                            Save Changes
                          </button>
                        </nz-form-item>
                      </form>
                      <!-- {{cPForm.value | json}} -->
                    </div>
                  </div>
                </div>
              </nz-tab>
              <!-- tab Time Zone  -->

              <!-- PII Data Request  -->
              <nz-tab nzTitle="PII Data Request" *ngIf="loggedInUserDetail?.user_type != 'tenant_admin'">
                <div class="pl-4">
                  <div class="row">
                    <div class="col-md-6">
                      <h4>Personally Identifiable Information </h4>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <nz-divider></nz-divider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-striped" id="contentToConvert">
                        <tbody>
                        <tr>
                            <th>First Name</th>
                            <td>{{loggedInUserDetail?.employee.first_name}}</td>

                        </tr>
                        <tr>
                          <th>Middle Name</th>
                          <td>{{loggedInUserDetail?.employee.middle_name}}</td>
                      </tr>

                        <tr>
                            <th>Last Name</th>
                            <td>{{loggedInUserDetail?.employee.last_name}}</td>
                        </tr>
                        <tr>
                          <th>Display Name</th>
                          <td>{{loggedInUserDetail?.employee.display_name}}</td>
                      </tr>
                        <tr>
                            <th>DOB</th>
                            <td>{{loggedInUserDetail?.employee.dob}}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{{loggedInUserDetail?.employee.user.email}}</td>
                        </tr>


                        </tbody>
                    </table>
                        <form id="personalInfoForm" nz-form [nzLayout]="'vertical'" [formGroup]="pIIForm">
                        <nz-form-item *ngIf="piiOtpbox">
                          <nz-divider></nz-divider>
                          <nz-form-label [nzSpan]="5" nzFor="Please Enter OTP Here">OTP</nz-form-label>
                          <nz-form-control [nzSpan]="19">
                            <input nz-input nzSize="large" placeholder="Enter OTP" formControlName="otp" type="text"
                              id="otp">
                          </nz-form-control>
                          <nz-form-control [nzSpan]="19">
                              <button nz-button nzSize="large" class="custBtn"
                               nzType="primary" (click)="pIIFormSubmit()" [disabled]="isFormLoading">
                               <i nz-icon [nzType]="isFormLoading ? 'loading' : ''"></i>
                               Submit OTP
                              </button>
                          </nz-form-control>
                          <nz-divider></nz-divider>
                        </nz-form-item>
                        </form>
                        <nz-table>
                          <td class="text-center" *ngIf="piiDeleteButton">
                            <button nz-button [nzType]="'primary'" (click)="piiStoreRequest()" [disabled]="isPiiStoreRequestLoading">
                              <i nz-icon [nzType]="isPiiStoreRequestLoading ? 'loading' : ''"></i>
                              Delete PII
                            </button>
                          </td>
                          <td *ngIf="piiDownloadButton">
                            <button nz-button [nzType]="'primary'" (click)="downloadPiiData()">
                              Download PII
                            </button>
                          </td>

                        </nz-table>

                      <nz-divider></nz-divider>
                        <div class="alert alert-info" role="alert" *ngIf="piiStatus">
                          {{piiStatusMsg}}
                        </div>
                        <nz-divider></nz-divider>

                    </div>
                  </div>
                </div>
              </nz-tab>
              <!-- PII Data Request  -->
            </nz-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
