import { RouterModule } from '@angular/router';
import { AuthGuard } from './../../auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormListComponent } from './form-list.component';
import { FormMyComponent } from './my/form-my.component';
import { FormTeamComponent } from './team/form-team.component';
import { FormAddComponent } from './add/form-add.component';
import { FormViewComponent } from './view/form-view.component';
import { FormSubmissionComponent } from './submission/form-submission.component';
import { FormSubmissionsComponent } from './submissions/form-submissions.component';
import { FormLaunchComponent } from './launch/form-launch.component';
import { LaunchCreateComponent } from './launch/create/launch-create.component';
import { FormApprovalComponent } from './approval/form-approval.component';
import { FormDashboardComponent } from './dashboard/form-dashboard.component';
import { FormReportComponent } from './report/form-report.component';

export const routes = [
  { path: '', component: FormListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'add', component: FormAddComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'mine', component: FormMyComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'teams', component: FormTeamComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'edit/:id', component: FormAddComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'view/:id', component: FormViewComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'teams-submissions', component: FormSubmissionsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'submissions/:launch', component: FormSubmissionsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'submission/:launch', component: FormSubmissionComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'approval/:id', component: FormApprovalComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'launches', component: FormLaunchComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'launches/create', component: LaunchCreateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'launches/edit/:id', component: LaunchCreateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'launches/:id/dashboard', component: FormDashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'launches/:id/report', component: FormReportComponent, pathMatch: 'full', canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class FormListModule { }
