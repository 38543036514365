<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div class="d-flex">
                <button class="mr-2" nz-button nzType="primary" (click)="showPreview()">Preview</button>
                <button nz-button nzType="primary" (click)="saveSchema()" [disabled]="disableEdit">Save</button>
              </div>
            </div>

            <nz-modal nzWidth="1200" [(nzVisible)]="isPreviewVisible" nzTitle="Preview for {{ previewModelTitle }}" (nzOnCancel)="previewHandleCancel()" (nzOnOk)="previewHandleOk()">
              <nz-table #submissionsTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="['']" [nzScroll]="{ x: '300px' }">
                <thead>
                  <tr>
                    <ng-container *ngFor="let col of form.schemas">
                      <th class="text-center" style="width:{{col.configurable.width || 100}}px; border-left: 1px solid #fff;">
                        {{ col.label }}
                      </th>
                    </ng-container>
                    <th nzRight class="text-center text-center" style="width:100px">Actions</th>
                  </tr>
                </thead>
              </nz-table>
            </nz-modal>


            <nz-modal [(nzVisible)]="isVisible" nzTitle="Configuration for {{ modalTitle }}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
              <ng-container *nzModalContent>
                <ng-container *ngIf="form.schemas[modalIndex].type === 'text'">
                  <div class="form-group">
                    <label>Default Value:</label>
                    <input type="text" nz-input placeholder="Default Value" [(ngModel)]="configurable.default" [disabled]="disableEdit" />
                  </div>
                </ng-container>

                <ng-container *ngIf="form.schemas[modalIndex].type === 'url'">
                  <div class="form-group">
                    <label>Default Value:</label>
                    <input type="url" nz-input placeholder="Default Value" [(ngModel)]="configurable.default" [disabled]="disableEdit" />
                  </div>
                </ng-container>

                <ng-container *ngIf="form.schemas[modalIndex].type === 'date'">
                  <div class="form-group">
                    <label>Default Value:</label>
                    <nz-date-picker [nzPlaceHolder]="'Default Value'" [(ngModel)]="configurable.default" [disabled]="disableEdit"></nz-date-picker>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.schemas[modalIndex].type === 'number'">
                  <div class="form-group">
                    <label>Default Value:</label>
                    <nz-input-number [nzPlaceHolder]="'Default Value'" [(ngModel)]="configurable.default" [disabled]="disableEdit" class="w-100"></nz-input-number>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.schemas[modalIndex].type === 'select'">
                  <div class="form-group">
                    <label>Default Value:</label>
                    <input type="text" nz-input placeholder="Default Value" [(ngModel)]="configurable.default" [disabled]="disableEdit" />
                  </div>

                  <div class="form-group">
                    <label>Choose Option Source:</label>
                    <nz-select [nzPlaceHolder]="'Choose Data Source...'" [(ngModel)]="configurable.option_source" [disabled]="disableEdit">
                      <nz-option nzLabel="Static Options" nzValue="static"></nz-option>
                      <nz-option nzLabel="From Table" nzValue="table"></nz-option>
                      <nz-option nzLabel="From API" nzValue="api"></nz-option>
                    </nz-select>
                  </div>

                  <ng-container *ngIf="configurable.option_source === 'static'">
                    <div class="form-group">
                      <label>Static Options:</label>
                      <textarea nz-input placeholder="Options" [(ngModel)]="configurable.options" [disabled]="disableEdit"></textarea>
                      <small class="form-text text-muted">Add options seperated by comma</small>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="configurable.option_source === 'table'">
                    <div class="form-group">
                      <label>Table for Options:</label>
                      <nz-select [nzPlaceHolder]="'Choose a Table...'" [(ngModel)]="configurable.options_table" (ngModelChange)="getFields(configurable.options_table)" [disabled]="disableEdit">
                        <nz-option *ngFor="let table of dynamicTables;" [nzLabel]="table.label" [nzValue]="table.value"></nz-option>
                      </nz-select>
                    </div>
                    <ng-container *ngIf="configurable.options_table !== ''">
                      <div class="form-group">
                        <label>Column from Table:</label>
                        <nz-select [nzPlaceHolder]="'Choose a Column...'" [(ngModel)]="configurable.options_table_key" [disabled]="disableEdit">
                          <nz-option *ngFor="let value of dynamicKeys;" [nzLabel]="value" [nzValue]="value"></nz-option>
                        </nz-select>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="configurable.option_source === 'api'">
                    <div class="form-group">
                      <label>API URL:</label>
                      <input type="url" nz-input placeholder="API URL" [(ngModel)]="configurable.options_api" [disabled]="disableEdit" />
                    </div>
                    <ng-container *ngIf="configurable.options_api !== ''">
                      <div class="form-group">
                        <label>Key for Options:</label>
                        <input type="text" nz-input placeholder="Option Key" [(ngModel)]="configurable.options_api_key" [disabled]="disableEdit" />
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="form.schemas[modalIndex].type === 'autoInc'">
                  <div class="form-group">
                    <label>Start From:</label>
                    <nz-input-number [(ngModel)]="configurable.default" [nzPlaceHolder]="'Start From'" class="w-100" [disabled]="disableEdit"></nz-input-number>
                  </div>
                </ng-container>

                <div class="form-group">
                  <label>Field Width (in Pixel):</label>
                  <nz-input-number [nzMin]="0" [(ngModel)]="configurable.width" [nzPlaceHolder]="'Field Width'" [disabled]="disableEdit" class="w-100"></nz-input-number>
                </div>

                <ng-container *ngIf="form.schemas[modalIndex].type !== 'autoInc'">
                  <div class="form-group">
                    <label>Placeholder:</label>
                    <input type="text" nz-input placeholder="Placeholder" [(ngModel)]="configurable.placeholder" [disabled]="disableEdit" />
                  </div>
                </ng-container>

                <div class="form-group">
                  <label>Required:</label>&nbsp;
                  <nz-switch [(ngModel)]="configurable.required"></nz-switch>
                </div>
              </ng-container>
            </nz-modal>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Form Name<strong class="text-danger">*</strong>:</label>
                  <input type="text" nz-input placeholder="Form Name" [(ngModel)]="form.name" [disabled]="disableEdit" />
                </div>
              </div>
              <!-- <div class="col-sm-4">
                <div class="form-group">
                  <label>Reviewer:<strong class="text-danger">*</strong></label>
                  <nz-select [nzPlaceHolder]="'Choose Who Review...'" [(ngModel)]="form.who_review" [disabled]="disableEdit">
                    <nz-option *ngFor="let option of role_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Submission Frequency<strong class="text-danger">*</strong>:</label>
                  <nz-input-group nzSuffix="Months" class="p-0" style="border:none">
                    <nz-input-number [nzMin]="0" [nzPlaceHolder]="'Submission Frequency'" [(ngModel)]="form.frequency" [disabled]="disableEdit" class="w-100"></nz-input-number>
                  </nz-input-group>
                </div>
              </div>
            </div>

            <nz-table #schemaTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="['']">
              <thead>
                <tr>
                  <th width="25%" class="text-center">Label & Type</th>
                  <th width="45%" class="text-center">Configurable</th>
                  <th width="20%" class="text-center">Explanation<p class="small m-0">for internal consumption</p></th>
                  <th width="10%" class="text-center text-center">Actions</th>
                </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                <tr *ngFor="let schema of form.schemas; let i = index;" cdkDrag>
                  <td class="align-top">
                    <div class="form-group">
                      <label>Label<strong class="text-danger">*</strong>:</label>
                      <input type="text" nz-input placeholder="Label" [(ngModel)]="schema.label" [disabled]="disableEdit" />
                    </div>
                    <div class="form-group">
                      <label>Type<strong class="text-danger">*</strong>:</label>
                      <nz-select [nzPlaceHolder]="'Choose Type...'" [(ngModel)]="schema.type" [disabled]="disableEdit">
                        <nz-option nzLabel="Text" nzValue="text"></nz-option>
                        <nz-option nzLabel="URL" nzValue="url"></nz-option>
                        <nz-option nzLabel="Date" nzValue="date"></nz-option>
                        <nz-option nzLabel="Number" nzValue="number"></nz-option>
                        <nz-option nzLabel="Dropdown" nzValue="select"></nz-option>
                        <nz-option nzLabel="Increment" nzValue="autoInc"></nz-option>
                      </nz-select>
                    </div>
                  </td>

                  <td class="align-top">
                    <div class="form-group">
                      <label>Filled By:</label>
                      <nz-select [nzPlaceHolder]="'Choose Who Fill...'" [(ngModel)]="schema.configurable.fill_by" [disabled]="disableEdit">
                        <nz-option *ngFor="let option of role_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                      </nz-select>
                    </div>
                    <div class="form-group">
                      <label>When to Fill:</label>
                      <nz-select [nzPlaceHolder]="'Choose When Fill...'" [(ngModel)]="schema.configurable.fill_when" [disabled]="disableEdit">
                        <nz-option nzLabel="Immediate" nzValue="immediate"></nz-option>
                        <nz-option nzLabel="During Review" nzValue="during_review"></nz-option>
                        <nz-option nzLabel="After Approval" nzValue="after_approval"></nz-option>
                        <nz-option nzLabel="After Rejection" nzValue="after_rejection"></nz-option>
                      </nz-select>
                    </div>

                    <div class="text-right" *ngIf="schema.type">
                      <a class="small" (click)="showModal(i)">more...</a>
                    </div>
                  </td>

                  <td class="align-top">
                    <textarea rows="6" nz-input [(ngModel)]="schema.explanation" placeholder="Explanation" [disabled]="disableEdit"></textarea>
                  </td>

                  <td class="align-top text-center">
                    <a class="mr-2" (click)="sortField(i, -1)" [class.disabled]="disableEdit">
                      <i class="ri-arrow-up-s-line grayIcon"></i>
                    </a>
                    <a class="mr-2" (click)="sortField(i, +1)" [class.disabled]="disableEdit">
                      <i class="ri-arrow-down-s-line grayIcon"></i>
                    </a>
                    <a (click)="removeSchema(i)" [class.disabled]="disableEdit">
                      <i class="ri-delete-bin-6-fill text-danger"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
            <a class="add-btn" (click)="addColumn()" title="Add Field" [class.disabled]="disableEdit">
              <i class="ri-add-circle-fill"></i>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
