<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">Team's IDP Forms</h3>

            <!-- <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms/teams" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div></div>
            </div> -->

            <nz-table #submissionsTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="submissions" [nzScroll]="{ x: '300px' }">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Status</th>
                  <th class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let submission of submissions; let i = index;">
                  <td>{{ submission.employee.first_name }} {{ submission.employee.last_name }}</td>
                  <td>{{ submission.employee.role.role_name }}</td>
                  <td>{{ submission.employee.designation }}</td>
                  <td>{{ submission.employee.department.name }}</td>
                  <td>{{ submission.status2 ? submission.status2.toUpperCase() : submission.status.toUpperCase() }}</td>
                  <td class="text-center">
                    <a routerLink="/forms/approval/{{ submission.id }}" title="View">
                      <i class="ri-eye-fill grayIcon"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
