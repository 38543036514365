import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgxSpinnerService} from 'ngx-spinner';
import * as FileSaver from 'file-saver';
import {CampaignsRetrieveRequest} from 'src/app/app.models';
@Component({
  selector: 'app-continuous-three-sixty',
  templateUrl: './continuous-three-sixty.component.html',
  styleUrls: ['./continuous-three-sixty.component.css']
})
export class ContinuousThreeSixtyComponent implements OnInit {

  constructor(private appService: AppService,
    private spinner: NgxSpinnerService
  ) {
  }

  loggedInUserDetail: any;
  campaignList: any;
  isAllChecked = false;
  reportOkDisabled=true;
  popup = false;
  loading = false;
  listOfCampaigns:  ReadonlyArray<any> = [];
  setofCheckedCampaigns=new Set<number>();
  selected:Array<number>=[];
  campaigsData: any;

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.getCampaigns();
  }

    // Get List of Campaigns
    getCampaigns() {
      this.loading = true;
      this.spinner.show();
      let rr = new CampaignsRetrieveRequest;
      if(this.loggedInUserDetail.user_type == 'tenant_admin' || this.loggedInUserDetail.user_type == 'admin'){
        // console.log('Admin');
      }else{
        rr.emp_id = this.loggedInUserDetail.employee.id?this.loggedInUserDetail.employee.id:null
        rr.report = "menu"
      }
      this.appService.getCampaigns(rr).subscribe(res => {
        this.campaignList = res['items'];
        this.loading = false;
        this.spinner.hide();
      },err=>{
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong, Please try again...',
        })
      })
    }

  onAllChecked(e) {
    this.listOfCampaigns.forEach((item) => {
     
        this.updateCheckedSet(item.id, e)
   
    }
    );
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChange($event: ReadonlyArray<any>): void {
    this.listOfCampaigns = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if(this.selected.length!=0){
      this.isAllChecked = this.listOfCampaigns.every((item) =>
      this.setofCheckedCampaigns.has(item.id));
    }
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setofCheckedCampaigns.add(id);
      this.selected.push(id);
      
    } else {
      this.setofCheckedCampaigns.delete(id);
      this.selected.splice(this.selected.indexOf(id),1);
      
    }
  }
  downloadExcel(){
    if(this.selected.length!=0){
      let data={
        campaign_ids:this.selected
      }
      this.spinner.show();
      this.popup=false;
      this.selected=[];
      this.appService.getTransactionReport(data).subscribe((res :Blob)=>{
        var file = new Blob([res], { type: 'application/octet-stream' })
        // var fileURL = URL.createObjectURL(file);
        FileSaver.saveAs(file, 'continuous-360-report.xlsx');
        // window.open(fileURL);
        this.spinner.hide();
      }
      ,error=>{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong ',
        })
        this.spinner.hide();
      }
      )
    }else{
      Swal.fire({
        icon: 'warning',
        text: 'Please Select Campaigns',
      })
    }
  }

}
