import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleCaseUnderscore' })
export class TitleCasePipeUnderScore implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Split the string into words
    const words = value.split('_');

    // Capitalize each word and join them back with spaces
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return capitalizedWords.join(' ');
  }
}
