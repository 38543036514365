import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameQuotesTrimmerPipe } from "./name-quotes-trimmer.pipe";
import { TitleCasePipe } from "./titlecase.pipe"
import { TitleCasePipeUnderScore } from "./titlecaseunderscore.pipe"
import {QuadrantTransformPipe} from "./quadrantSpliter.pipe";

@NgModule({
  declarations: [NameQuotesTrimmerPipe,TitleCasePipe,TitleCasePipeUnderScore,QuadrantTransformPipe],
  exports:[
    NameQuotesTrimmerPipe,
    TitleCasePipe,
    TitleCasePipeUnderScore,
    QuadrantTransformPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
