import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
interface ItemData {
  id: number;
  level: string;
  percentage: number;
  start_rating: string;
  end_rating: string;
  explanation: string;
}
@Component({
  selector: 'app-lr-enhancement',
  templateUrl: './lr-enhancement.component.html',
  styleUrls: ['./lr-enhancement.component.css']
})
export class LrEnhancementComponent implements OnInit {
  tblData = [];
  enhancementForm!:FormGroup;
  total = 10;
  pageIndex = 1;
  pageSize = 100;
  loading = false;
  isFormLoading = false;
  action = 'create';
  lR_ID;
  icon = '<='
  icon1 = '<-->'
  listOfCurrentPageData: ReadonlyArray<ItemData> = [];
  loggedInUserDetail:any;
  constructor(public fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) {

  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    // console.log('loggedInUserDetail',this.loggedInUserDetail)
    this.LRenhancementForm();
    this.getAllEnhancement();
  }
  LRenhancementForm(){
    this.enhancementForm = this.fb.group({
      percentage:[null,[Validators.required]],
      start_rating:[null],
      end_rating:[null],
      level:[null,[Validators.required]],
      explanation:[null],
    });
  }

  onCurrentPageDataChange($event: ReadonlyArray<ItemData>): void {
    this.listOfCurrentPageData = $event;
  }

  getAllEnhancement(){
    this.loading = true;
    this.appService.getAllEnhancement().subscribe(data => {
      this.loading = false;
      this.tblData = data['data'];
      this.tblData.forEach(element => {
        element.Loader = false;
      });
    },err=>{
      this.loading = false;
      this.tblData = [];
    });
  }

  isFormVisible = false;
  showCreateModal(){
    this.action = 'create';
    this.isFormVisible = true;
    this.LRenhancementForm();
  }

  showEditModal(e){
    // console.log('e',e)
    this.action = 'edit';
    this.lR_ID = e.id;
    this.isFormVisible = true;
    this.enhancementForm.patchValue(e)
  }
  remove(e){
    e.Loader = true;
    this.appService.deleteEnhancementLevel(e.id).subscribe(data => {
      e.Loader = false;
      this.message.create('success', 'Deleted Successfully', {
        nzDuration: 5000
      });
      this.getAllEnhancement();
    },err=>{
      e.Loader = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.error.message
      });
    });
  }

  handleModalCancel(){
    this.isFormVisible = false;
  }

  handleModalSubmit(){
    this.submitLR();
    // this.isFormVisible = false;
  }

  // Allow numbers Only
  numberOnly(e:any) {
    e = e || window.event;
   var bad = /[^\s\d&-]/i,
       key = String.fromCharCode(e.keyCode || e.which);
   if (e.which !== 0 && e.charCode !== 0 && bad.test(key)) {
       e.returnValue = false;
       if (e.preventDefault) {
           e.preventDefault();
       }
   }

  }

  alphaNumberOnly (e: any) {  // Accept only letters and numbers
    e = e || window.event;
   var bad = /[^\sa-z\d&-.]/i,
       key = String.fromCharCode(e.keyCode || e.which);
   if (e.which !== 0 && e.charCode !== 0 && bad.test(key)) {
       e.returnValue = false;
       if (e.preventDefault) {
           e.preventDefault();
       }
   }
 }

 submitLR(){
   if(this.enhancementForm.valid){
    this.isFormLoading = true;
    // if(this.loggedInUserDetail.tenant.report_point_scale < parseFloat(this.enhancementForm.value.end_rating)){
      // Swal.fire({
      //   icon: 'warning',
      //   // title: 'Oops...',
      //   text: 'Maximum Rating should be '+this.loggedInUserDetail.tenant.report_point_scale
      // });
      // this.isFormLoading = false;
      // return;
    // }
    if(this.enhancementForm.value.percentage === 0){
      Swal.fire({
        icon: 'warning',
        // title: 'Oops...',
        text: 'Percentage cannot be zero'
      });
      this.isFormLoading = false;
      return;
    }
     let payload = {
            "percentage": parseFloat(this.enhancementForm.value.percentage),
            "start_rating": this.enhancementForm.value.start_rating,
            "end_rating": parseFloat(this.enhancementForm.value.end_rating),
            "level": this.enhancementForm.value.level,
            "explanation": this.enhancementForm.value.explanation,
        }
        let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createLR_Enhancement(payload);
        message = 'LR Enhancement "' + this.enhancementForm.controls['level'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateLR_Enhancement(payload, this.lR_ID);
        message = 'LR Enhancement "' + this.enhancementForm.controls['level'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.enhancementForm.reset();
        this.getAllEnhancement();
        // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

      }, error => {
        this.isFormVisible = true;
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
   }
 }

}
