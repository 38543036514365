import {Component, OnInit} from '@angular/core';
import  {BusinessUnitRetrieveRequest, Location, LocationRetrieveRequest} from 'src/app/app.models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from 'src/app/app.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzUploadFile} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  total = 1;
  isImportFormVisible = false;
  fileUploading = false;
  downloading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  // These are the Locations which are displayed in the list screen.
  locations: Location[] = [];
  // This is the Location being edited.
  locationId: number;
  // loading indictor for the table
  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  locationForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;

  // state required for the auto complete
  buOptionList: string[] = [];
  buOptionListSearchChange$ = new BehaviorSubject('');
  // selectedBuId?: number;
  isBuOptionListLoading = false;

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private appService: AppService,
              private message: NzMessageService,
              private modal: NzModalService) {
  }

  onBuOptionListSearch(value: string): void {
    this.isBuOptionListLoading = true;
    this.buOptionListSearchChange$.next(value);
  }

  showCreateModal(): void {
    this.locationForm.reset();
    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(entityId: number): void {
    this.locationForm.reset();
    this.resetCreateModal();
    this.locationId = entityId;
    this.getLocationById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getLocationById(id) {
    this.appService.get('/api/locations/' + id).subscribe((res: any) => {

      let business_unit_ids = [];
      res.business_units.forEach(e => {
        business_unit_ids.push(e.business_unit.id);
      });
      res.business_unit_ids = business_unit_ids;
      this.locationForm.patchValue(res);

    });
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;

    // Create the location on the server.
    if (this.locationForm.status === 'VALID') {
      let data = {
        'name': this.locationForm.value.name,
        'business_unit_ids': this.locationForm.value.business_unit_ids
      };

      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createLocation(data);
        message = 'Location "' + this.locationForm.controls['name'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateLocation(data, this.locationId);
        message = 'Location "' + this.locationForm.controls['name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.locationForm.reset();
        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

      }, error => {
        this.isFormVisible = true;
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  handleModalCancel(): void {
    this.locationForm.reset();
    this.isFormVisible = false;
    this.isImportFormVisible =false;
    this.fileList = [];
  }

  resetCreateModal(): void {
    this.locationForm = this.fb.group({
      name: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9\\s&-]+$")]],
      business_unit_ids: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    for (const i in this.locationForm.controls) {
      this.locationForm.controls[i].markAsDirty();
      this.locationForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.resetCreateModal();

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['id']) {
        this.showEditModal(queryParams['id']);
      }
    });

    // tslint:disable:no-any
    // const getBuOptionList = (buName: string) => {
    //   let rr = new BusinessUnitRetrieveRequest(buName);
    //   rr.per_page = null;
    //   return this.appService.getBusinessUnits(rr)
    //     .pipe(
    //       map((res: any) => {
    //         return res['items'];
    //       })
    //     );
    // };
    // const optionList$: Observable<string[]> = this.buOptionListSearchChange$
    //   .asObservable()
    //   .pipe(debounceTime(500))
    //   .pipe(switchMap(getBuOptionList));
    // optionList$.subscribe(data => {
    //   this.buOptionList = data;
    //   this.isBuOptionListLoading = false;
    // });

    this.getBu();
  }


  getBu() {
    const rr = new BusinessUnitRetrieveRequest();
    rr.per_page = null;
    this.appService.getBusinessUnits(rr).subscribe(value => {
      this.buOptionList = value['items'];
    });

  }

  public remove(entity: Location) {
    const index: number = this.locations.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.name + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteLocation(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new LocationRetrieveRequest('');
    rr.sort_order = sortOrder ? sortOrder : 'asc';
    rr.order_by = sortField ? sortField : 'name';
    rr.per_page = pageSize;
    rr.page = pageIndex;

    this.appService.getLocations(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.locations = data['items'];
    });
  }
  showImportModal(): void {
    this.isImportFormVisible = true;
  }

  handleImportModalSubmit(): void{
    // console.log("here")
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    this.appService.importLocation(formData).subscribe((res: any) => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      this.message.create('success', res.message, {
        nzDuration: 5000
      });
    }, error => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }


  downloadSampleCSV(){
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/Location-BulkUpload-Template.csv');
    //link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;
  }

  alphaNumberOnly (e: any) {  // Accept only letters and numbers
    e = e || window.event;
   var bad = /[^\sa-z\d&-]/i,
       key = String.fromCharCode(e.keyCode || e.which);
   if (e.which !== 0 && e.charCode !== 0 && bad.test(key)) {
       e.returnValue = false;
       if (e.preventDefault) {
           e.preventDefault();
       }
   }
 }
}
