import {Component, OnInit} from '@angular/core';
import {AppService} from 'src/app/app.service';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgAuthService} from '../../ng-auth.service';
import {environment} from 'src/environments/environment';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public forgotForm: FormGroup;
  stage_message: string = 'start_up';
  token: any;
  jwt_token: any;
  privacyChecked: boolean = false;
  isFirslogin=false;
  termsChecked: boolean = false;
  isPrivacyTermsModalOpen: boolean;
  isPrivacySelected: boolean;
  forgot = false;
  forgot_stage_message = 'forgot_start';
  forgot_token: any;
  title = 'Forgot Password';
  userName: any;
  // captchaStatus: any;
  siteKey = environment.recaptcha_sitekey;
  // nextButtonEnable: boolean = false;
  captchaStatus = 'captchas_success';
  nextButtonEnable = true;
  otp_validaity=60*30*24;

  cookiesForm!: FormGroup;
  checkOptionsOne = [
    {label: 'Allow', value: 'Allow', checked: false},
  ];
  effect = 'scrollx';

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public appService: AppService,
    private spinner: NgxSpinnerService,
    public ngAuthService: NgAuthService,
    private modal: NzModalService,
  ) {
  }
  info = "Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character."
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, this.stage_message === 'email_verified' ?? Validators.compose([Validators.required])],
      otp: [null, this.stage_message === 'email_available' ?? Validators.compose([Validators.required])],
      password1: [null, this.stage_message === 'password_reset' ?? Validators.compose([Validators.required])],
      password2: [null, this.stage_message === 'password_reset' ?? Validators.compose([Validators.required])],
      // corporate_id: [null],
      remember_me: false
    });


    this.forgotForm = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      otp: [null, this.forgot_stage_message == 'email_verified' ?? Validators.compose([Validators.required])],
      password1: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
      password2: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
    });
    this.cookiesForm = this.fb.group({
      chk: ['', [Validators.required]],
    });

    const userId = sessionStorage.getItem('me');
    const cookies = sessionStorage.getItem('Cookies');
    if (cookies == null && userId == null) {
      this.openCookiesPopup();
    }
  }

  slides = [
    // {label: 'Image Title', image: '../../../assets/images/login/Group626.png'},
    // {label: 'Image Title', image: '../../../assets/images/login/Group626.png'},
    // {label: 'Image Title', image: '../../../assets/images/login/Group626.png'},
    {label: "Image Title", image: "../../../assets/images/login/img1.png"},
    {label: "Image Title", image: "../../../assets/images/login/img2.png"},
  ];

  resolved(captchaResponse: string) {
    //console.log('Resolved response token',captchaResponse);
    this.appService.recaptchaVerification(captchaResponse).subscribe((res) => {
      if (res['status'] == 200) {
        this.captchaStatus = 'captchas_success';
        if (this.captchaStatus == 'captchas_success') {
          this.nextButtonEnable = true;
        }
      }
    });
  }

  forgotaction() {
    this.forgot = true;
    this.forgotForm = this.fb.group({
      username: [this.userName, Validators.compose([Validators.required])],
      otp: [null, this.forgot_stage_message == 'email_verified' ?? Validators.compose([Validators.required])],
      password1: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
      password2: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
    });
    this.forgotForm.controls['username'].disable();
  }

  // Functions Added By Athaf
  // Created On 14-09-2021
  // Privacy and terms chnages

  onTermsChanged() {
    // console.log('user naem is : ', this.loginForm.getRawValue().username);

    if (!this.termsChecked) {
      return;
    }
    let type = 'terms-of-service';
    this.setprivacyAndTerms(type);
  }

  onPrivacyChanged() {
    if (!this.privacyChecked) {
      return;
    }
    let type = 'privacy-policy';
    this.setprivacyAndTerms(type);
  }

  openPdf() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/pdf/cookie_policy.pdf');
    // link.setAttribute('download', `cookie_policy.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onTermsClicked() {
    this.isPrivacyTermsModalOpen = true;
    this.isPrivacySelected = false;
  }

  onPrivacyClicked() {
    this.isPrivacyTermsModalOpen = true;
    this.isPrivacySelected = true;
  }

  handleModalCancel() {
    this.isPrivacyTermsModalOpen = false;
  }

  handleModalOk() {
    this.handleModalCancel();
  }

  // Terms An d privacy reelated functions ends here

  setprivacyAndTerms(type) {
    const data = {
      device: 'web',
      type: type,
      meta: '',
      user_name: this.loginForm.getRawValue().username,
    };
    this.appService.setPrivacyAndTerms(data).subscribe(res => {
    });
  }


  public onLoginFormSubmit(): void {

    // console.log('OnLoginFormSubmit', this.stage_message);

    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
    if (this.loginForm.valid) {
      //Startup or email checking code
      // console.log("Login Checker : ", this.stage_message);

      if (this.stage_message != 'start_up') {
        this.loginForm.controls['username'].disable();
      } else {
        this.loginForm.controls['username'].disable();
      }

      if (this.stage_message == 'start_up') {
        this.spinner.show();
        this.userName = this.loginForm.controls['username'].value;
        //console.log(this.userName)
        this.appService.checkValidEmail(this.loginForm.controls['username'].value).subscribe(result => {
            this.spinner.hide();
            if (result['status'] == 'first_login') {
              this.isFirslogin=true;
              this.stage_message = 'email_available';
              this.token = result['token'];
              Swal.fire('', 'OTP Sent to email successfully', 'success');
              this.timerStart = this.otp_validaity;

              this.countDown();
            } else if (result['status'] == 'password_login') {
              this.isFirslogin=false;
              this.stage_message = 'email_verified';
            } else {
              this.isFirslogin=false;
              this.timerStart = this.otp_validaity;

              this.countDown();
              Swal.fire('', 'OTP Sent to email successfully', 'success');
            }

          },
          error => {

            this.spinner.hide();
            Swal.fire('Oops...', error.error.message, 'error');
            this.loginForm.controls['username'].enable();

          });
      } else if (this.stage_message == 'email_available') {
        //if email available
        this.spinner.show();
        this.isFirslogin=false;
        this.appService.checkValidOtp(this.loginForm.controls['username'].value, this.loginForm.controls['otp'].value).subscribe(value => {
          this.spinner.hide();
          if (value['status'] == 'otp_correct') {
            this.token = value['token'];
            this.stage_message = 'password_reset';
            document.getElementById('next_button').innerText = 'Submit';
          } else {
            Swal.fire('Oops...', value['message'], 'error');
          }
        }, error => {
          this.spinner.hide();

          Swal.fire('Oops...', error.error.message, 'error');

          if (error['status'] == 'otp_expired') {
            setTimeout(function() {
              window.location.reload();
            }, 4000);
          }


        });

      } else if (this.stage_message == 'password_reset') {
        if (this.loginForm.controls['password1'].value != this.loginForm.controls['password2'].value) {
          this.loginForm.controls['password2'].setErrors({'Password Mismatch': true});
          this.loginForm.invalid;
          Swal.fire('Oops...', 'Password Mismatch', 'error');
          return;
        }

        if (!this.privacyChecked || !this.termsChecked) {
          Swal.fire('Oops...', 'You must agree Terms & Conditions', 'error');
          return;
        }

        this.spinner.show();
        this.appService.resetPassword(this.loginForm.controls['username'].value, this.token, this.loginForm.controls['password1'].value).subscribe(value => {
          this.spinner.hide();
          if (value['status'] == 'reset_success') {
            //this.stage_message = 'email_verified'
            sessionStorage.setItem('accountAccessToken', value['token']);
            sessionStorage.setItem('tokenexpiry', value['token_expiry']);
            localStorage.setItem('dupaccountAccessToken', value['token']);
            localStorage.setItem('duptokenexpiry', value['token_expiry']);
            this.appService.getLoggedInUserDetail().subscribe((res) => {
              sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
              localStorage.setItem('dupme', btoa(encodeURIComponent(JSON.stringify(res))));
              this.router.navigate(['/']);
              this.spinner.hide();
            });


            //console.log(value)
          } else {

            Swal.fire('Oops...', value['message'], 'error');
          }
        }, error => {
          this.spinner.hide();
          Swal.fire('Oops...', error.error.message, 'error');
          if (error['status'] == 'invalid_user') {
            setTimeout(function() {
              window.location.reload();
            }, 4000);
          }
        });


      } else if (this.stage_message == 'email_verified' && this.captchaStatus == 'captchas_success') {
        // console.log(this.loginForm.controls['username'].value);

        this.spinner.show();
        this.appService.getMultiFactor(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value).subscribe(value => {
          // this.spinner.hide()
          // console.log('otp no', value);

          if (value['status'] == 'first_login_by_default_password') {
            this.spinner.hide();
            this.token = value['token'];
            this.stage_message = 'password_reset';
            document.getElementById('next_button').innerText = 'Submit';
          }

          // for otp bypass
          if (value['status'] == 'otp_authentication_not_required') {
            sessionStorage.setItem('accountAccessToken', value['token']);
            sessionStorage.setItem('tokenexpiry', value['token_expiry']);
            localStorage.setItem('dupaccountAccessToken', value  ['token']);
            localStorage.setItem('duptokenexpiry', value ['token_expiry']);
            this.appService.getLoggedInUserDetail().subscribe(res => {
              sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
              localStorage.setItem('dupme', btoa(encodeURIComponent(JSON.stringify(res))));
              this.router.navigate(['/']);
              this.spinner.hide();
            });
          } else if(value['status'] != 'first_login_by_default_password') {
            this.spinner.hide();
            this.stage_message = value['status'];
            //multi_factor_otp_success
            // value['OTP Expiry']
            this.jwt_token = value['JWT Token'];
            Swal.fire('', value['message'], 'success');
            this.timerStart = this.otp_validaity;

            this.countDown();
          }
        }, error => {
          this.spinner.hide();
          // console.log(error);
          if (error.error.message == 'Password expired, Please reset your password') {
            //this.spinner.hide()
            Swal.fire('Oops...', error.error.message, 'error');
            this.forgot = true;
            this.title = 'Reset Password';
            this.forgotForm = this.fb.group({
              username: [this.userName, Validators.compose([Validators.required])],
              otp: [null, this.forgot_stage_message == 'email_verified' ?? Validators.compose([Validators.required])],
              password1: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
              password2: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
            });


          } else if (error.error.message == 'Account_locked, Please Reset your password') {
            //console.log("TEST2",error.error.message)
            //this.spinner.hide()
            Swal.fire('Oops...', error.error.message, 'error');

            this.forgot = true;
            this.title = 'Reset Password';
            this.forgotForm = this.fb.group({
              username: [this.userName, Validators.compose([Validators.required])],
              otp: [null, this.forgot_stage_message == 'email_verified' ?? Validators.compose([Validators.required])],
              password1: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
              password2: [null, this.forgot_stage_message == 'otp_verified' ?? Validators.compose([Validators.required])],
            });
          } else {
            Swal.fire('Oops...', 'Password is wrong', 'error');

          }


        });


        // this.appService.getAuthorizationToken(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value).subscribe(result => {
        //   localStorage.setItem('accountAccessToken', result['token']);
        //
        //   this.appService.getLoggedInUserDetail().subscribe(res => {
        //     localStorage.setItem('me', btoa(JSON.stringify(res)));
        //     this.router.navigate(['/']);
        //   })
        // }, error => {
        //   alert('Invalid username or password');
        //   console.log(error);
        // })

      } else if (this.stage_message == 'multi_factor_otp_success') {
        this.spinner.show();

        this.appService.getAfterMultiFactor(this.jwt_token, this.loginForm.controls['otp'].value).subscribe(value => {
          // console.log('value token', value);

          sessionStorage.setItem('accountAccessToken', value['token']);
          sessionStorage.setItem('tokenexpiry', value['token_expiry']);
          localStorage.setItem('dupaccountAccessToken', value  ['token']);
            localStorage.setItem('duptokenexpiry', value ['token_expiry']);
          this.appService.getLoggedInUserDetail().subscribe(res => {
            sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
              localStorage.setItem('dupme', btoa(encodeURIComponent(JSON.stringify(res))));
              this.router.navigate(['/']);
            this.spinner.hide();
          });

        }, error => {
          this.spinner.hide();
          if (error.error.message === 'Signature has expired') {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'OTP Expired\nYou will redirected to login page',
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'OK',
              denyButtonText: `Don't save`,
            }).then((result) => {
              window.location.href = '/login';
              window.location.reload();
              this.router.navigate(['/login']);
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.router.navigate(['/login']);
              } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
              }
            });
          } else {
            Swal.fire('Oops...', error.error.message, 'error');
          }

        });
      } else if (this.stage_message == 'email_verified' && this.captchaStatus != 'captchas_success') {

        Swal.fire('Oops...', 'Please do recaptcha verification', 'error');
      }
      //
    }
  }


  public onforgotFormSubmit(): void {
    // console.log('OnForgotFormSubmit', this.forgot_stage_message);
    this.forgotForm.controls['username'].disable();

    for (const i in this.forgotForm.controls) {
      this.forgotForm.controls[i].markAsDirty();
      this.forgotForm.controls[i].updateValueAndValidity();
    }
    if (this.forgotForm.valid) {

      // if (this.forgot_stage_message != 'forgot_start') {
      //   this.forgotForm.controls['username'].disable()
      // }

      if (this.forgot_stage_message == 'forgot_start') {
        this.spinner.show();
        this.appService.getForgotOtp(this.forgotForm.controls['username'].value).subscribe((res) => {
          // console.log(res);
          this.spinner.hide();
          // if(res['OTP']=='OTP Sent Successfully'){
          this.forgot_stage_message = 'email_verified';
          Swal.fire('', 'OTP Sent to email successfully', 'success');
          //}
        }, error => {
          // console.log(error);
          this.spinner.hide();
          Swal.fire('Oops...', 'username not found', 'error');
        });
      } else if (this.forgot_stage_message == 'email_verified') {
        this.spinner.show();
        this.appService.checkValidOtp(this.forgotForm.controls['username'].value, this.forgotForm.controls['otp'].value).subscribe((res) => {
            this.spinner.hide();
            if (res['status'] == 'otp_correct') {
              //this.spinner.hide()
              this.forgot_stage_message = 'otp_verified';
              this.forgot_token = res['token'];
            }
          },
          error => {
            this.spinner.hide();
            Swal.fire('Oops...', error.error.message, 'error');
          });
      } else if (this.forgot_stage_message == 'otp_verified') {


        if (this.forgotForm.controls['password1'].value != this.forgotForm.controls['password2'].value) {
          this.forgotForm.controls['password2'].setErrors({'Password Mismatch': true});
          this.forgotForm.invalid;
          Swal.fire('Oops...', 'Password Mismatch', 'error');
          return;
        }
        this.spinner.show();
        this.appService.resetPassword(this.forgotForm.controls['username'].value, this.forgot_token, this.forgotForm.controls['password1'].value).subscribe((res) => {
          if (res['status'] == 'reset_success') {
            sessionStorage.setItem('accountAccessToken', res['token']);
            sessionStorage.setItem('tokenexpiry', res['token_expiry']);
            localStorage.setItem('dupaccountAccessToken', res['token']);
            localStorage.setItem('duptokenexpiry', res['token_expiry']);
            this.appService.getLoggedInUserDetail().subscribe((res) => {
              sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
              localStorage.setItem('dupme', btoa(encodeURIComponent(JSON.stringify(res))));
              this.router.navigate(['/']);
              this.spinner.hide();
            });


            // console.log(res);
          }
        }, error => {
          this.spinner.hide();
          // console.log(error);
          Swal.fire('Oops...', error.error.message, 'error');
        });


      }
      //
    }
  }


  //openCookiesPopup
  isVisible = false;

  openCookiesPopup() {
    this.isVisible = true;
  }

  handleOk() {
    this.isVisible = false;
    sessionStorage.setItem('Cookies', 'true');
  }

  handleCancel() {
    this.isVisible = false;
    this.router.navigate(['prelogin']);
  }

  isChk = false;

  timerStart = this.otp_validaity;

  Checked(e) {
    // alert(JSON.stringify(e))
  }

  intervalId = 0;

  clearTimer() {
    clearInterval(this.intervalId);
  }

  public countDown() {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.timerStart -= 1;
      if (this.timerStart === 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  resendOTP() {
    this.captchaStatus = 'captchas_success';
    this.stage_message = 'email_verified';
    if(this.isFirslogin){
      this.stage_message = 'start_up';
    }

    this.onLoginFormSubmit();
  }
}
