
<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="stepwizard mt-4">
                            <div class="stepwizard-row setup-panel text-center">
                              <!-- <div class="wizard-step tab-first">
                                                  <a class="btn btn-default" [routerLink]="['/maximizer-dashboard/basic-details']" routerLinkActive="active" >
                                                    <div class="iconBox">
                                                      <img class="icon" src="../../../assets/images/icon/Group471.svg" alt="" sizes="" > 
                                                      </div> <span> Basic Details</span> 
                                                  </a>
                                              </div> -->
                              <div class="wizard-step tab-second">
                                <a class="btn btn-default" [routerLink]="['/settings']"
                                  routerLinkActive="active">
                                  <div class="iconBox">
                                    <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                  </div>
                                  <span>Settings</span>
                                  <!-- <p>(26th Aug, 2021 - 25th Sept 2021)</p> -->
                                </a>
                              </div>
                              <div class="wizard-step tab-second">
                                <a class="active btn btn-default" [routerLink]="['/maximizer-setup/order-history']"
                                  routerLinkActive="active">
                                  <div class="iconBox">
                                    <img class="icon" src="../../../assets/images/icon/Group474.svg" alt="" sizes="">
                                  </div>
                                  <span>Ordre History</span>
                                  <!-- <p>(26th Aug, 2021 - 25th Sept 2021)</p> -->
                                </a>
                              </div>
              
                            </div>
                          </div>



<div  class="col-sm-12">  
    <h5 class="py-4 text-center">Application Active till: 31-12-2020 | No. of Users: 500/1000</h5> 
        
    <nz-table #basicTable nzBordered  >
          <thead>
            <tr>
              <th>Invoice Date</th>
              <th>Invoice Amount</th>
              <th>View Invoice</th> 
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>15/08/2020</td>
              <td>25,000</td>
              <td>Invoice Details  <a class="text-primary  pl-2"><u>view</u></a></td>  
            </tr>
            <tr>
                <td>15/08/2020</td>
                <td>25,000</td>
                <td>Invoice Details  <a class="text-primary pl-2"><u>view</u></a></td>  
              </tr>
          </tbody>
        </nz-table> 

</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
