import {Component, OnInit, ViewChild} from '@angular/core';
import {Competency, BehaviourRetrieveRequest, Behaviour} from 'src/app/app.models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from 'src/app/app.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzUploadFile} from 'ng-zorro-antd/upload';

export interface TreeNodeInterface {
  key: string;
  behaviour_name: string;
  // title: string;
  level?: number;
  expand?: boolean;
  address?: string;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
  mydata?: any;
}

@Component({
  selector: 'app-behaviour-structure',
  templateUrl: './behaviour-structure.component.html',
  styleUrls: ['./behaviour-structure.component.css']
})
export class BehaviourStructureComponent implements OnInit {
  isFormVisible = false;
  isFormLoading = false;
  downloading = false;
  action = "";
  radioValue;
  radioFileds: any;
  inputTag = '';

  total = 1;
  // These are the Competencies which are displayed in the list screen.
  behaviours: Behaviour[] = [];
  // This is the competency being Eed.
  competencyId: number;
  // For Adding tag values
  tagValue = [];
  behavioursOptionList: any;
  loading = true;
  pageSize = 100;
  pageIndex = 1;
  private treeFlag: boolean;
  listOfMapData: TreeNodeInterface[] = [];

  // listOfMapData: TreeNodeInterface[] = [
  //   {
  //     key: `1`,
  //     behaviour_name: 'John Brown sr.',
  //     level: 60,
  //     children: [
  //       {
  //         key: `1-1`,
  //         behaviour_name: 'John Brown',
  //         level: 42,
  //       },
  //       {
  //         key: `1-2`,
  //         behaviour_name: 'John Brown jr.',
  //         level: 30,
  //         children: [
  //           {
  //             key: `1-2-1`,
  //             behaviour_name: 'Jimmy Brown',
  //             level: 16,
  //           }
  //         ]
  //       },
  //       {
  //         key: `1-3`,
  //         behaviour_name: 'Jim Green sr.',
  //         level: 72,
  //         children: [
  //           {
  //             key: `1-3-1`,
  //             behaviour_name: 'Jim Green',
  //             level: 42,
  //             children: [
  //               {
  //                 key: `1-3-1-1`,
  //                 behaviour_name: 'Jim Green jr.',
  //                 level: 25,
  //               },
  //               {
  //                 key: `1-3-1-2`,
  //                 behaviour_name: 'Jimmy Green sr.',
  //                 level: 18,
  //                 children: [
  //                   {
  //                     key: `1-3-2-1`,
  //                     behaviour_name: 'Jim Green jr1.',
  //                     level: 25,
  //                   },
  //                   {
  //                     key: `1-3-2-2`,
  //                     behaviour_name: 'Jimmy Green sr2.',
  //                     level: 18,
  //                     children: [
  //                       {
  //                         key: `1-3-3-1`,
  //                         behaviour_name: 'Jim Green jr1.',
  //                         level: 25,
  //                       },
  //                       {
  //                         key: `1-3-3-2`,
  //                         behaviour_name: 'Jimmy Green sr2.',
  //                         level: 18,
  //                       }
  //                     ]
  //                   }
  //                 ]
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   },
  //   {
  //     key: `2`,
  //     behaviour_name: 'Joe Black',
  //     level: 32,
  //   }
  // ];
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) {
  }
  bahaviourForm!: FormGroup;
  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: false });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  submitForm(): void {
    for (const i in this.bahaviourForm.controls) {
      this.bahaviourForm.controls[i].markAsDirty();
      this.bahaviourForm.controls[i].updateValueAndValidity();
    }
  }
  levelZeroId
  ngOnInit(): void {
    // this.listOfMapData.forEach(item => {
    //   this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
    // });
    this.bahaviourForm = this.fb.group({
      behaviour_name: [null, [Validators.required]],
      // parent_id: [null, [Validators.required]],
      description:[null]
    });
    this.loadDataFromServer(1, 10000, null, null, null, null, null, []);

    // this.bahaviourForm.get('parent_id').disable();

    this.appService.getLevelSettings().subscribe((res) => {
      this.radioFileds = res['items'];
      if (this.radioFileds.length) {
        this.levelZeroId = this.radioFileds[0].id;
      }
      this.resetCreateModal();
    });
  }
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  resetCreateModal(): void {
    this.radioValue = this.levelZeroId;
    this.bahaviourForm = this.fb.group({
      behaviour_name: [null, [Validators.required]],
      // parent_id: [null, [Validators.required]],
      description:[null]
    });
    // this.bahaviourForm.get('parent_id').disable();
  }
  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, containsName: string | '', levelId: string | null, parentId: string | null, ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new BehaviourRetrieveRequest();
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    rr.behaviour_name = containsName;
    rr.level_id = levelId;
    rr.parent_id = parentId;
    rr.ids = ids;

    this.appService.getAllBehaviour(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.behaviours = this.behavioursOptionList = data['items'];
      // console.log('behaviours', this.behaviours);
      // this.tempCompetency = this.competencies;
      this.listOfMapData = [];
      this.processBehaviour([...this.behaviours]);
      // console.log('processCompData', this.listOfMapData);

      this.listOfMapData.forEach(item => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });

      // console.log('mapOfExpandedData', this.mapOfExpandedData);

    });
  }

  levelChanged(index) {
    // let parent = null;
    // if (index) {
    //   this.bahaviourForm.get('parent_id').setValue(null);
    //   parent = index - 1;
    //   this.bahaviourForm.get('parent_id').enable();
    //   let parentId = this.radioFileds[parent].id;
    //   this.behavioursOptionList = this.competencies.filter(element => element.level_id === parentId);
    // } else {
    //   this.bahaviourForm.get('parent_id').setValue(null);
    //   this.bahaviourForm.get('parent_id').disable();
    // }

  }

  handleInputConfirm() {
    if (this.inputTag && this.tagValue.indexOf(this.inputTag) === -1) {
      this.tagValue = [...this.tagValue, this.inputTag];
    }
    this.inputTag = '';
  }

  handleClose(removedTag: {}): void {
    this.tagValue = this.tagValue.filter(tag => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  processBehaviour(behavioursNew: Behaviour[]) {
    // console.log('incoming to process ', behavioursNew);
    for (let i = 0; i < behavioursNew.length; i++) {
      const f = behavioursNew[i];
      if (f.parent_id === null || parseInt(f.parent_id, 10) === 0) {
        this.listOfMapData = [...this.listOfMapData, {
          behaviour_name: f.behaviour_name,
          key: f.id.toString(),
          expand: false,
          mydata: f,
        }];

        behavioursNew.splice(i, 1);
        i--;
      } else if (this.listOfMapData.length > 0) {
        this.treeFlag = true;
        for (let j = 0; j < this.listOfMapData.length; j++) {
          if (this.treeFlag) {
            this.listOfMapData[j] = this.treeTraversing(i, f, this.listOfMapData[j]);
          } else {
            break;
          }

        }


        if (!this.treeFlag) {
          behavioursNew.splice(i, 1);
          i--;
        }
      }
    }
    if (behavioursNew && behavioursNew.length > 0) {
      this.processBehaviour(behavioursNew);
    }
  }

  private treeTraversing(num: number, f: Behaviour, currentTree: any) {
    // console.log('inside tree traverse', currentTree);

    const g = currentTree;
    if (f.parent_id.toString() === g.key.toString()) {
      this.treeFlag = false;
      if (currentTree.hasOwnProperty('children')) {
        currentTree.children = [...currentTree.children, {
          behaviour_name: f.behaviour_name,
          key: f.id.toString(),
          expand: false,
          mydata: f,
        }];
      } else {
        currentTree.children = [{
          behaviour_name: f.behaviour_name,
          key: f.id.toString(),
          expand: false,
          mydata: f,
        }];
      }


      currentTree.expanded = true;
      // this.competencies.splice(i, 1);
      return currentTree;
    }
    if (currentTree.hasOwnProperty('children')) {
      for (let i = 0; i < currentTree.children.length; i++) {
        currentTree.children[i] = this.treeTraversing(num, f, currentTree.children[i]);
      }
      return currentTree;
    } else {
      return currentTree;
    }


  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.tagValue = [];
    this.action = 'Create';
    this.isFormVisible = true;
  }
  parparent_Id:any
  showCreateChildren(item){
    // console.log('item',item)
    this.parparent_Id = item['mydata'].id
    this.resetCreateModal();
    this.action = 'Create Children';
    this.isFormVisible = true;
  }
  parent_Id;
  isChild = false;
  showEditChildren(item){
    // console.log('item',item)
    this.parent_Id = item['mydata'].id
    this.bahaviourForm.patchValue(item['mydata']);
    this.parparent_Id = item['mydata'].parent_id;
    if(this.parparent_Id == null){
      this.isChild = false;
    }else{
      this.isChild = true;
    }
    this.tagValue = item['mydata']?.keywords;
    this.action = 'Edit';
    this.isFormVisible = true;
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
  }

  handleModalSubmit(){
    this.submitForm();

    this.isFormLoading = true;

    // Create the competency on the server.
    if (this.bahaviourForm.status === 'VALID') {
      let data
      if(this.action == "Create Children"){
        data = {
          'behaviour_name': this.bahaviourForm.value.behaviour_name,
          'keywords': this.tagValue,
          'description': this.bahaviourForm.value.description,
          'parent_id': this.parparent_Id?this.parparent_Id:null,
        };
      }else if(this.action == "Create"){
        data = {
          'behaviour_name': this.bahaviourForm.value.behaviour_name,
          'keywords': this.tagValue,
          'description': this.bahaviourForm.value.description,
        };
      }else if(this.action == "Edit"){
        if(this.isChild){
          data = {
            'behaviour_name': this.bahaviourForm.value.behaviour_name,
            'keywords': this.tagValue,
            'description': this.bahaviourForm.value.description,
            'parent_id': this.parparent_Id?this.parparent_Id:null,
          };
        }else{
          data = {
            'behaviour_name': this.bahaviourForm.value.behaviour_name,
            'keywords': this.tagValue,
            'description': this.bahaviourForm.value.description,
          };
        }

      }


      let subscriber = null;
      let message = null;
      if (this.action === 'Create' || this.action == "Create Children") {
        // console.log(data);
        subscriber = this.appService.createBehaviourValues(data);
        message = 'Behaviour "' + this.bahaviourForm.controls['behaviour_name'].value + '" has been added.';
      } else if (this.action === 'Edit') {
        subscriber = this.appService.updateBehaviourValues(data, this.parent_Id);
        message = 'Behaviour "' + this.bahaviourForm.controls['behaviour_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;
        this.tagValue = [];
        this.radioValue = this.levelZeroId;
        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, []);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }
  }

  deleteBehaviour(e){
    // this.parent_Id = e['mydata'].id
    this.message.create('warning', 'Please Wait !', {
      // nzDuration:
    });
    this.appService.deleteBehaviour(e['mydata'].id).subscribe(res => {
      if(res){
        this.message.create('success', e['mydata'].behaviour_name+' '+'deleted successfully', {
          nzDuration: 5000
        });
        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, []);
      }
    },err=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.error.message
      });
    })
  }
  isImportVisible = false;
  showImportModal(): void {
    this.isImportVisible = true;
  }

  handleImportCancel(): void {
    this.fileList = [];
    this.isImportVisible = false;
  }

  isValidationVisible=false;
  errorData:any;
  handleImportSubmit(): void {
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    this.appService.behaviourBulkUpload(formData).subscribe((res: any) => {
      this.isImportVisible = false;
      this.fileUploading = false;
      if(res.error_data.length == 0){
        this.message.create('success', res.message, {
          nzDuration: 5000
        });
      }else{
        this.isValidationVisible = true;
        this.errorData = res.error_data
      }


      this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, []);
      this.fileList = [];

    }, error => {
      this.fileList = [];
      this.isImportVisible = false;
      this.fileUploading = false;
      this.isValidationVisible = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }
  isClose = false;
  handleValidationSubmit(){
    this.isValidationVisible = false;
    this.isImportVisible = true;
  }

  handleValidationCancel(){
    this.isValidationVisible = false;
    this.isImportVisible = true;
  }

  //Sample CSV Download
  downloadSampleCSV(){
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/BehaviourStructure-BulkUpload-Template.xlsx');
    // link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;

  }
  //bluk upload
  fileUploading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  back(){

  }
}
