import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router ,NavigationEnd} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  Employee,
  EmployeeRetrieveRequest,
  TalentcardRetrieveRequest,
  GradeRetrieveRequest,
  RoleRetrieveRequest,
} from 'src/app/app.models';
@Component({
  selector: 'app-talent-card-form',
  templateUrl: './talent-card-form.component.html',
  styleUrls: ['./talent-card-form.component.css']
})
export class TalentCardFormComponent implements OnInit {
  talentCardForm: FormGroup;
  today = new Date();
  loggedInUserDetail
  gradeListOption: any;
  employeeList: any;
  gradeName
  loading = false;
  total = 10;
  pageSize = 100;
  pageIndex = 0
  tickets = [{ name: 'ttt' }]
  mobilityList = [{ key: 'yes', name: 'Yes' }, { key: 'no', name: 'No' }]
  talentID: any;
  promotionNotAvailable = false;
  experienceNotAvailable = false;
  currentRoleSelectedIndex = null;

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;
  constructor(private fb: FormBuilder,
              private modal: NzModalService,
              private activatedRoute: ActivatedRoute,
              private appService: AppService, public datepipe: DatePipe,
              public router: Router,
              private spinner: NgxSpinnerService,
              private message: NzMessageService,) { }

  async ngOnInit() {
    this.loggedInUserDetail = this.appService.getMe();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.talentID = parseInt(params['talentID']);
    });
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if(ev.url == "/talent-card-form"){
          this.ngViewInit();
        }
      }
    });
    if (this.talentID) {
      // alert(JSON.stringify(this.talentID))
      this.ngAfterViewOninit();
      this.getAllTalentCard();
    } else {
      this.ngViewInit();
    }
  }

  contentIndex = 0;
  printedChart: boolean = false;
  tabChange(no) {
    this.contentIndex = no;
    if (this.contentIndex == 1) {
      this.printedChart = false;
    }
  }
  back() {

    this.loggedInUserDetail.user_type=='tenant_admin'?  this.router.navigate(['/talent-card-list']):this.router.navigate(['/']);
  }

  ngViewInit() {
    this.loggedInUserDetail = this.appService.getMe();
    this.appService.getLoggedInUserDetail().subscribe(res => {
      if (res) {
        this.loggedInUserDetail = res
        this.talentCardForm.get('functional_manager').patchValue(this.loggedInUserDetail?.employee?.reporting_manager_data?.display_name ? this.loggedInUserDetail?.employee?.reporting_manager_data?.display_name : '')
        if (this.talentCardForm.get('functional_manager').value != null) {
          this.talentCardForm.get('functional_manager').disable()
        }
        this.talentCardForm.get('grade').patchValue(this.loggedInUserDetail?.employee?.grade?.grade_name);
      }
    });
    this.getTalentCard();
    this.getTalentCardStatus();
    // this.getAllTalentCard();
    this.talentCardForm = this.fb.group({
      name: [null, [Validators.required]],
      doj: [null, [Validators.required]],
      department: [null, [Validators.required]],
      reviewer: [null, [Validators.required]],
      professional_qualification: [null, [Validators.required]],
      employee_code: [null, [Validators.required]],
      grade: [null, [Validators.required]],
      functional_manager: [null, [Validators.required]],
      mobility: [null, [Validators.required]],
      geography: [null],
      education_qualification: [null, [Validators.required]],
      previous_promotion: this.fb.array([]),
      previous_experience: this.fb.array([]),
      company_experience: this.fb.array([]),
      promotionNAchecked: [false],
      experienceNAchecked: [false]
    });

    this.talentCardForm.get('name').patchValue(this.loggedInUserDetail?.employee?.display_name)
    this.talentCardForm.get('doj').patchValue(this.loggedInUserDetail?.employee?.joining_date)
    this.talentCardForm.get('department').patchValue(this.loggedInUserDetail?.employee?.department?.name)
    this.talentCardForm.get('employee_code').patchValue(this.loggedInUserDetail?.employee?.employee_id)
    this.talentCardForm.get('grade').patchValue(this.loggedInUserDetail?.employee?.grade_id)
    this.talentCardForm.get('name').disable()
    this.talentCardForm.get('doj').disable()
    this.talentCardForm.get('department').disable()
    this.talentCardForm.get('employee_code').disable()
    this.talentCardForm.get('grade').disable()
  }

  ngAfterViewOninit() {
    this.talentCardForm = this.fb.group({
      name: [null, [Validators.required]],
      doj: [null, [Validators.required]],
      department: [null, [Validators.required]],
      reviewer: [null, [Validators.required]],
      professional_qualification: [null, [Validators.required]],
      employee_code: [null, [Validators.required]],
      grade: [null, [Validators.required]],
      functional_manager: [null, [Validators.required]],
      mobility: [null, [Validators.required]],
      geography: [null],
      education_qualification: [null, [Validators.required]],
      previous_promotion: this.fb.array([]),
      previous_experience: this.fb.array([]),
      company_experience: this.fb.array([]),
      promotionNAchecked: [false],
      experienceNAchecked: [false]
    });
  }

  filters;
  talentCardDetails
  getAllTalentCard() {
    this.spinner.show();
    let rr = new TalentcardRetrieveRequest();
    rr = this.filters
    this.appService.getAllTelantCard(rr).subscribe(res => {
      this.talentCardDetails = res['items']
      let talentCardDetails = this.talentCardDetails.filter(item => (item['talent_card'].id == this.talentID))
      console.log('talentCardDetails', talentCardDetails)
      this.talentCardForm.patchValue(talentCardDetails[0]["talent_card"]);
      this.talentCardForm.get('mobility').patchValue(talentCardDetails[0]["talent_card"].mobility?.value)
      this.talentCardForm.get('geography').patchValue(talentCardDetails[0]["talent_card"].mobility?.geography)

      talentCardDetails[0]["talent_card"].previous_promotion.sort(function (a, b) {
        return +new Date(a['month/year']) - +new Date(b['month/year']);
      })
      talentCardDetails[0]["talent_card"].previous_experience.sort(function (a, b) {
        return +new Date(a.from) - +new Date(b.from);
      })
      talentCardDetails[0]["talent_card"].company_experience.sort(function (a, b) {
        return +new Date(a.from) - +new Date(b.from);
      })

      this.patchPreviousPromotion(talentCardDetails[0]["talent_card"].previous_promotion)
      this.patchPreviousExperience(talentCardDetails[0]["talent_card"].previous_experience)
      this.patchCompanyExperience(talentCardDetails[0]["talent_card"].company_experience)
      this.isSubmit = talentCardDetails[0]["talent_card"].final_submit;
      if(talentCardDetails[0]['talent_card'].previous_promotion[0]['data']=='N/A'){
        this.talentCardForm.get('promotionNAchecked').patchValue(true)
      }
      if(talentCardDetails[0]["talent_card"].previous_experience?.length != 0 &&talentCardDetails[0]['talent_card'].previous_experience[0]['data']=='N/A'){
        console.log("186")
        this.talentCardForm.get('experienceNAchecked').patchValue(true)
      }
      this.talentCardForm.disable();
      this.spinner.hide();

    }, err => {
      this.spinner.hide();
    })
  }


  onChange(result: Date): void {
    // console.log('onChange: ', result);
  }
  // patchPreviousPromotion

  patchPreviousPromotion(element) {
    // console.log('previous_promotion', element)
    const previous_promotion = this.talentCardForm.get('previous_promotion') as FormArray;
    element.forEach(item => {
      previous_promotion.push(this.fb.group({
        "month/year": [item["month/year"], [Validators.required]],
        from: [item.from, [Validators.required]],
        to: [item.to, [Validators.required]],
      }));
    });
  }

  get desiredBLevelArray(): FormGroup {
    return this.fb.group({
      "month/year": [null, [Validators.required]],
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
    });
  }

  addNewBLevel() {
    const previous_promotion = this.talentCardForm.get('previous_promotion') as FormArray;
    previous_promotion.push(this.fb.group({
      "month/year": [null, [Validators.required]],
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
    }));
  }
  removeBLevel(index, id) {
    const desiredBLevelArray = this.talentCardForm.get('previous_promotion') as FormArray;
    desiredBLevelArray.removeAt(index);

  }


  // patchPreviousExperience

  get desiredExperienceArray(): FormGroup {
    return this.fb.group({
      company_name: [null, [Validators.required]],
      role: [null, [Validators.required]],
      from_to_date: [null, [Validators.required]],
      from: [null],
      to: [null],
    });
  }

  addNewExperience() {
    const previous_experience = this.talentCardForm.get('previous_experience') as FormArray;
    previous_experience.push(this.fb.group({
      company_name: [null, [Validators.required]],
      role: [null, [Validators.required]],
      from_to_date: [null, [Validators.required]],
      from: [null],
      to: [null],
    }));
  }
  removeExperience(index, id) {
    const desiredExperienceArray = this.talentCardForm.get('previous_experience') as FormArray;
    desiredExperienceArray.removeAt(index);
  }

  patchPreviousExperience(element) {
    const previous_experience = this.talentCardForm.get('previous_experience') as FormArray;
    // console.log('experience', previous_experience)
    element.forEach(item => {
      if (item.from == null || item.to == null) {
        previous_experience.push(this.fb.group({
          company_name: [item.company_name, [Validators.required]],
          role: [item.role, [Validators.required]],
          from_to_date: [null, [Validators.required]],

          from: [item.from],
          to: [item.to],
        }));
      }
      else {
        previous_experience.push(this.fb.group({
          company_name: [item.company_name, [Validators.required]],
          role: [item.role, [Validators.required]],
          from_to_date: [[item.from, item.to], [Validators.required]],
          from: [item.from],
          to: [item.to],
        }));
      }

    });
  }

  // patchPreviousExperience

  get desiredLupinArray(): FormGroup {
    return this.fb.group({
      lupin_role: [null, [Validators.required]],
      lupin_from_to_date: [null, [Validators.required]],
      from: [null],
      to: [null],
    });
  }

  addNewLupin() {
    const company_experience = this.talentCardForm.get('company_experience') as FormArray;
    company_experience.push(this.fb.group({
      lupin_role: [null, [Validators.required]],
      lupin_from_to_date: [null, [Validators.required]],
      from: [null,[Validators.required]],
      to: [null],
      isCurrent:[false]
    }));
  }
  removeLupin(index, id,isChecked) {
    if(isChecked){
      this.currentRoleSelectedIndex = null
    }

    const desiredExperienceArray = this.talentCardForm.get('company_experience') as FormArray;
    desiredExperienceArray.removeAt(index);

  }

  patchCompanyExperience(element) {
    const company_experience = this.talentCardForm.get('company_experience') as FormArray;
    element.forEach((item,index) => {
      company_experience.push(this.fb.group({
        lupin_role: [item.lupin_role, [Validators.required]],
        lupin_from_to_date: [[item.from, item.to], [Validators.required]],
        from: [item.from,[Validators.required]],
        to: [item.to],
        isCurrent: [item.isCurrent == undefined ? false : item.isCurrent]
      }));
      if(item.isCurrent){
        this. currentRoleSelectedIndex=index
      }
    });
  }

  talentId: any;
  isKeyEnable = false;
  isSubmit = 'no';
  getTalentCard() {
    this.spinner.show();
    this.appService.getTelantCard().subscribe(res => {
      if (res) {
        this.isKeyEnable = true;
        this.resetLoader = false;
        this.talentId = res["talent-card"].id
        // console.log('res["talent-card"]', res["talent-card"])
        this.talentCardForm.patchValue(res["talent-card"]);
        this.talentCardForm.get('mobility').patchValue(res["talent-card"].mobility?.value)
        this.talentCardForm.get('geography').patchValue(res["talent-card"].mobility?.geography)

        res["talent-card"].previous_promotion?.sort(function (a, b) {
          return +new Date(a['month/year']) - +new Date(b['month/year']);
        })
        res["talent-card"].previous_experience.sort(function (a, b) {
          return +new Date(a.from) - +new Date(b.from);
        })
        res["talent-card"].company_experience.sort(function (a, b) {
          return +new Date(a.from) - +new Date(b.from);
        })

        if (res["talent-card"].previous_promotion?.length != 0) {
          this.patchPreviousPromotion(res["talent-card"].previous_promotion)
        } else {
          this.addNewBLevel()
        }
        if (res["talent-card"].previous_experience?.length != 0) {
          this.patchPreviousExperience(res["talent-card"].previous_experience)
        } else {
          this.addNewExperience()
        }
        if (res["talent-card"].company_experience?.length != 0) {
          this.patchCompanyExperience(res["talent-card"].company_experience)
        } else {
          this.addNewLupin()
        }
        this.talentCardForm.get('mobility').enable();
        if(res["talent-card"].previous_promotion?.length != 0 &&res['talent-card'].previous_promotion[0]['data']=='N/A'){
          this.talentCardForm.get('promotionNAchecked').enable();
          this.talentCardForm.get('promotionNAchecked').patchValue(true)
        }
        if(res["talent-card"].previous_experience?.length != 0 &&res['talent-card'].previous_experience[0]['data']=='N/A'){
          console.log("384")
          this.talentCardForm.get('experienceNAchecked').enable();
          this.talentCardForm.get('experienceNAchecked').patchValue(true)
        }

        this.isSubmit = res["talent-card"].final_submit;
        if (res["talent-card"].final_submit == 'yes') {
          // this.talentCardForm.disable();
          this.talentCardForm.get('mobility').disable()
          this.talentCardForm.get('education_qualification').disable()
          this.talentCardForm.get('professional_qualification').disable()
          this.talentCardForm.get('reviewer').disable()
        }
        this.spinner.hide();
      }
    }, err => {
      this.isKeyEnable = false;
      this.resetLoader = false;
      this.talentCardForm.get('mobility').enable();
      this.talentCardForm.get('promotionNAchecked').enable();
      this.talentCardForm.get('experienceNAchecked').enable();
      this.addNewBLevel()
      this.addNewExperience()
      this.addNewLupin()
      this.spinner.hide();
    });
  }
  getTalentCardStatus() {
    // this.ngOnInit();
    this.appService.getTelantCardStatus().subscribe(res => {
      // console.log('Status', res)
      // window
    })
  }
  dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b
    return false;
  }

  getOverLappedDate(item) {
    let i, j;
    for (i = 0; i < item.length; i++) {
      for (j = i + 1; j < item.length; j++) {
        if (
          this.dateRangeOverlaps(
            this.datepipe.transform(item[i].from, 'yyyy-MM-dd'), this.datepipe.transform(item[i].to, 'yyyy-MM-dd'), this.datepipe.transform(item[j].from, 'yyyy-MM-dd'), this.datepipe.transform(item[j].to, 'yyyy-MM-dd'),

            // new Date(item[i].from), new Date(item[i].to),
            // new Date(item[j].from), new Date(item[j].to)
          )
        ) return j;
      }
    }
    return false;
  }
  getOverLappedDateCompany(item) {
    console.log(item)
    console.log("item here")
    let i, j;
    for (i = 0; i < item.length; i++) {
      for (j = i + 1; j < item.length; j++) {
        if (
          this.dateRangeOverlaps(
            this.datepipe.transform(item[i].from, 'yyyy-MM-dd'), this.datepipe.transform(item[i].to, 'yyyy-MM-dd'), this.datepipe.transform(item[j].from, 'yyyy-MM-dd'), this.datepipe.transform(item[j].to, 'yyyy-MM-dd'),

            // new Date(item[i].from), new Date(item[i].to),
            // new Date(item[j].from), new Date(item[j].to)
          )
        ) return j;
      }
    }
    return false;
  }

  getUnusedDate(e, item) {
    for (let i = 0; i < e.length; i++) {

      var isFound=false;
      var dt= this.datepipe.transform(e[i]['month/year'], 'yyyy-MM-dd');
      // console.log(dt);
      // dt = e[i]['month/year'] + '-01'
      // var dt= this.datepipe.transform(dt, 'yyyy-MM-dd');
      //  var dYear=dt.split('-')[0]
      //  var dMonth=dt.split('-')[1]
      for (let j = 0; j < item.length; j++) {
        const element = item[j];
        if(element.to==null){
          var d=dt >=this.datepipe.transform(element.from, 'yyyy-MM-dd')
          if(d){
            isFound=true;
            break;
          }
        }
        else{
          var d=dt >=this.datepipe.transform(element.from, 'yyyy-MM-dd')&&dt<=this.datepipe.transform(element.to, 'yyyy-MM-dd')
          if(d){
            isFound=true;
            break;
          }
        }
        //  var sd=this.datepipe.transform(element.from, 'yyyy-MM-dd')
        //  var splittedDate=sd.split("-",2);
        //  var sYear=splittedDate[0]
        //  var sMonth=splittedDate[1]
        //  if(dYear==sYear&&dMonth==sMonth){
        //   isFound=true;
        //   break;
        //  }


      }

      // const f = item.find(v => (v.isCurrent==undefined||v.is&&dt >=this.datepipe.transform(v.from, 'yyyy-MM-dd')&&dt<=this.datepipe.transform(v.to, 'yyyy-MM-dd'))||(dt >=this.datepipe.transform(v.from, 'yyyy-MM-dd')&&v.isCurrent) )

      // if (!f) return true
      if(!isFound)return true
    }
    return false;
  }

  currentRoleOverlap(item) {
    let currenrRoleStartDate;
    let c = item.find(e => e.isCurrent == true)
    currenrRoleStartDate = this.datepipe.transform(c?.from, 'yyyy-MM-dd');
    if (currenrRoleStartDate) {
      for (let index = 0; index < item.length; index++) {
        const element = item[index];
        if (element.isCurrent) {
          continue
        }
        else {
          element.from = this.datepipe.transform(element?.from, 'yyyy-MM-dd');
          element.to = this.datepipe.transform(element?.to, 'yyyy-MM-dd');
          if (element.from >= currenrRoleStartDate || currenrRoleStartDate <= element.to) {

            return true;
          }
        }

      }
    }

    return false;

  }

  checkforEndDateInLupinExperience(item) {
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      if (!element.isCurrent) {
        element.from = this.datepipe.transform(element?.from, 'yyyy-MM-dd');
        element.to = this.datepipe.transform(element?.to, 'yyyy-MM-dd');
        if (element.to == null) {
          return {
            'status': true,
            'msg': 'End date is missing for lupin experience'
          }
        }
        else if (element.from >= element.to) {
          return {
            'status': true,
            'msg': 'Start date is above end date for lupin experience'
          }

        }
      }
    }

    return {
      'status': false,
      'msg': 'Success'
    }

  }

  checkPreviousExpWithCurrent(pExp, lExp) {
    let currenrRoleStartDate
    let c = lExp.find(e => e.isCurrent == true)
    if (c) {
      currenrRoleStartDate = this.datepipe.transform(c?.from, 'yyyy-MM-dd');
      for (let index = 0; index < pExp.length; index++) {
        const element = pExp[index];
        if (element.to > currenrRoleStartDate) {
          return true
        }

      }
    }
    return false;
  }



  isFormLoading = false;
  isFormLoading1 = false;
  submittalentCardForm(e) {
    if (e == 'save') {
      this.isFormLoading = true;
      let data
      if(this.isSubmit == 'no'){
         data = {
          "employee_id": this.loggedInUserDetail?.employee?.id,
          "employee_code": this.talentCardForm.get('employee_code').value,
          "name": this.talentCardForm.get('name').value,
          "doj": this.talentCardForm.get('doj').value,
          "grade": this.talentCardForm.get('grade').value,
          "department": this.talentCardForm.get('department').value,
          "functional_manager": this.talentCardForm.get('functional_manager').value,
          "reviewer": this.talentCardForm.get('reviewer').value,
          "education_qualification": this.talentCardForm.get('education_qualification').value,
          "professional_qualification": this.talentCardForm.get('professional_qualification').value,
          "previous_promotion": this.talentCardForm.get('previous_promotion').value,
          "previous_experience": this.talentCardForm.get('previous_experience').value,
          "final_submit": "no",
          "company_experience": this.talentCardForm.get('company_experience').value,
          "mobility": {
            "value": this.talentCardForm.get('mobility').value,
            "geography": this.talentCardForm.get('geography').value ? this.talentCardForm.get('geography').value : ''
          }
        }
      }else if(this.isSubmit == 'yes'){
        data = {
        
          "previous_promotion": this.talentCardForm.get('previous_promotion').value,
          "previous_experience": this.talentCardForm.get('previous_experience').value,
          "company_experience": this.talentCardForm.get('company_experience').value,
        }
      }
      

      // if(data?.mobility.value == 'Yes' && (data?.mobility.geography == null || data?.mobility.geography == "")){
      //   Swal.fire({
      //     icon: 'warning',
      //     text: "Please fill the geography"
      //   });
      //   this.isFormLoading = false;
      //   return;
      // }

      let lupinproDate = []
      data?.previous_promotion.forEach(element => {
        // element["month/year"] = this.datepipe.transform(element["month/year"], 'yyyy-MM');
        element["month/year"] = this.datepipe.transform(element["month/year"], 'yyyy-MM-dd');
        element.chkDate = { from: element["month/year"], to: element["month/year"] };
        lupinproDate.push(element.chkDate)
      });


      // Find the duplicate date/year
      var valueArr = data?.previous_promotion.map(function (item) { return item["month/year"] });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx
      });
      if (isDuplicate) {
        Swal.fire({
          icon: 'warning',
          text: "previous promotion Month/Year overlap with another entry.Please update the Month/Year!"
        });
        this.isFormLoading = false;
        return;
      }

      let previousExperience = []
      let propreviousExperience = []
      data?.previous_experience.forEach((element, i) => {
        if (element.from_to_date != null) {
          element.from = this.datepipe.transform(element?.from_to_date[0], 'yyyy-MM-dd');
          element.to = this.datepipe.transform(element?.from_to_date[1], 'yyyy-MM-dd');
          element.from_to_dates = { from: this.datepipe.transform(element?.from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.from_to_date[1], 'yyyy-MM-dd') };
          element.pre_pro = { from: this.datepipe.transform(element?.from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.from_to_date[1], 'yyyy-MM-dd') };
          previousExperience.push(element.from_to_dates)
          propreviousExperience.push(element.pre_pro)
        }
      });

      propreviousExperience.forEach(item => {
        lupinproDate.push(item)
      });


      let companyExperience = []
      let lupincompanyExperience = []
      data?.company_experience.forEach((element, j) => {
        if (element.from != null) {
          element.from = this.datepipe.transform(element?.from, 'yyyy-MM-dd');
          element.to = this.datepipe.transform(element?.to, 'yyyy-MM-dd');
          element.lupin_from_to_dates = { from: this.datepipe.transform(element?.from, 'yyyy-MM-dd'), to: this.datepipe.transform(element?.to, 'yyyy-MM-dd') };
          element.lup_pro = { from: this.datepipe.transform(element?.from, 'yyyy-MM-dd'), to: this.datepipe.transform(element?.to, 'yyyy-MM-dd') };
          companyExperience.push(element.lupin_from_to_dates)
          lupincompanyExperience.push(element.lup_pro)
        }});
      // data?.company_experience.forEach((element, j) => {
      //   if (element?.lupin_from_to_date != null) {
      //     element.from = this.datepipe.transform(element?.lupin_from_to_date[0], 'yyyy-MM-dd');
      //     element.to = this.datepipe.transform(element?.lupin_from_to_date[1], 'yyyy-MM-dd');
      //     element.lupin_from_to_dates = { from: this.datepipe.transform(element?.lupin_from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.lupin_from_to_date[1], 'yyyy-MM-dd') };
      //     element.lup_pro = { from: this.datepipe.transform(element?.lupin_from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.lupin_from_to_date[1], 'yyyy-MM-dd') };
      //     companyExperience.push(element.lupin_from_to_dates)
      //     lupincompanyExperience.push(element.lup_pro)
      //   }
      // });
      let lupin_experience = lupincompanyExperience

      companyExperience.forEach(element => {
        previousExperience.push(element)
      });


      this.getOverLappedDate(previousExperience)
      if (this.getOverLappedDate(previousExperience)) {
        Swal.fire({
          icon: 'warning',
          text: "Experience Date overlap with another entry.Please update the dates!"
        });
        this.isFormLoading = false;
        return;
      }

      // this.getOverLappedDateCompany(lupinproDate)
      if (this.getOverLappedDateCompany(lupinproDate)) {
        Swal.fire({
          icon: 'warning',
          text: "Previous promotion Month/Year overlap with Previous experience entry.Please update the dates!"
        });
        this.isFormLoading = false;
        return;
      }

      // if (this.checkPreviousExpWithCurrent(data?.previous_experience, data?.company_experience)) {
      //   Swal.fire({
      //     icon: 'warning',
      //     text: "Previous experience above current experience entry.Please update the dates!"
      //   });
      //
      //   this.isFormLoading = false;
      //   return;
      // }
      if (this.currentRoleOverlap(data?.company_experience)) {
        Swal.fire({
          icon: 'warning',
          text: "  Lupin experience overlap current experience entry.Please update the dates!"
        });

        this.isFormLoading = false;
        return;
      }
      // var d = this.checkforEndDateInLupinExperience(data?.company_experience)
      // if (d['status']) {
      //   Swal.fire({
      //     icon: 'warning',
      //     text: d['msg']
      //   });
      //
      //   this.isFormLoading = false;
      //   return;
      // }
      // this.getUnusedDate(data?.previous_promotion, lupin_experience)
      this.getOverLappedDateCompany(lupincompanyExperience)


      // if (!this.promotionNotAvailable) {
      //   let isKey = this.getUnusedDate(data?.previous_promotion, lupin_experience)
      //
      //   if (isKey) {
      //     Swal.fire({
      //       icon: 'warning',
      //       text: "Please enter the valid lupin previous promotion Month/Year!"
      //     });
      //     this.isFormLoading = false;
      //     return;
      //   }
      // }
      data?.previous_promotion.forEach(element => {
        delete element.chkDate
      });
      data?.previous_experience.forEach(item => {
        delete item.from_to_date
        delete item.pre_pro
      });
      data?.company_experience.forEach(item => {
        delete item.lupin_from_to_date
        delete item.lup_pro
      });
      if(this.promotionNotAvailable){
        data.previous_promotion=[{'data':'N/A'}]
      }
      if(this.experienceNotAvailable){
        data.previous_experience=[{'data':'N/A'}]
      }



      let subscriber = null;
      let message = null;
      if (this.talentId) {
        subscriber = this.appService.updateTelantCard(data, this.talentId);
        message = 'Talent card updated successfully';
      } else {
        subscriber = this.appService.createTelantCard(data);
        message = 'Talent card added successfully';
      }
      subscriber.subscribe(res => {
        this.isFormLoading = false;
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.ngViewInit()
      }, error => {
        this.isFormLoading = false;
        this.message.create('error', error.error.message, {
          nzDuration: 5000
        });
      });
    } else {
      // if(this.talentCardForm.get('mobility').value == 'Yes' && (this.talentCardForm.get('geography').value == null || this.talentCardForm.get('geography').value == "")){
      //   Swal.fire({
      //     icon: 'warning',
      //     text: "Please fill the geography"
      //   });
      //   this.isFormLoading1 = false;
      //   return;
      // }
      if (this.talentCardForm.valid) {
        this.modal.confirm({
          nzTitle: 'Submit Talent Card?',
          nzContent: `Talent Card cannot be edited after Submit Except(Previous Promotion Details ,Previous Experience Details,Lupin Experience Details). Click OK to Submit or Cancel to Exit`,
          nzOnOk: () => {
            this.isFormLoading1 = true;

            let data;
            if(this.isSubmit == 'no'){
              data = {
                "employee_id": this.loggedInUserDetail?.employee?.id,
                "employee_code": this.talentCardForm.get('employee_code').value,
                "name": this.talentCardForm.get('name').value,
                "doj": this.talentCardForm.get('doj').value,
                "grade": this.talentCardForm.get('grade').value,
                "department": this.talentCardForm.get('department').value,
                "functional_manager": this.talentCardForm.get('functional_manager').value,
                "reviewer": this.talentCardForm.get('reviewer').value,
                "education_qualification": this.talentCardForm.get('education_qualification').value,
                "professional_qualification": this.talentCardForm.get('professional_qualification').value,
                "previous_promotion": this.talentCardForm.get('previous_promotion').value,
                "previous_experience": this.talentCardForm.get('previous_experience').value,
                "company_experience": this.talentCardForm.get('company_experience').value,
                "final_submit": "yes",
                "mobility": {
                  "value": this.talentCardForm.get('mobility').value,
                  "geography": this.talentCardForm.get('geography').value ? this.talentCardForm.get('geography').value : ''
                }
              }
            }else{
              data = {
                "previous_promotion": this.talentCardForm.get('previous_promotion').value,
                "previous_experience": this.talentCardForm.get('previous_experience').value,
                "company_experience": this.talentCardForm.get('company_experience').value,
                "final_submit": "yes",
              }
            }
            


            let lupinproDate = []
            data?.previous_promotion.forEach(element => {
              console.log(element + ' here')
              // element["month/year"] = this.datepipe.transform(element["month/year"], 'yyyy-MM');
              element["month/year"] = this.datepipe.transform(element["month/year"], 'yyyy-MM-dd');
              element.chkDate = { from: element["month/year"], to: element["month/year"] };
              if(!this.promotionNotAvailable){
                console.log("fools"+element.chkDate)

                lupinproDate.push(element.chkDate)
              }

            });


            // Find the duplicate date/year
            var valueArr = data?.previous_promotion.map(function (item) { return item["month/year"] });
            var isDuplicate = valueArr.some(function (item, idx) {
              return valueArr.indexOf(item) != idx
            });
            if (isDuplicate) {
              Swal.fire({
                icon: 'warning',
                text: "previous promotion Month/Year overlap with another entry.Please update the Month/Year!"
              });
              this.isFormLoading1 = false;
              return;
            }

            let previousExperience = []
            let propreviousExperience = []
            data?.previous_experience.forEach((element, i) => {
              if (element.from_to_date != null) {
                element.from = this.datepipe.transform(element?.from_to_date[0], 'yyyy-MM-dd');
                element.to = this.datepipe.transform(element?.from_to_date[1], 'yyyy-MM-dd');
                element.from_to_dates = { from: this.datepipe.transform(element?.from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.from_to_date[1], 'yyyy-MM-dd') };
                element.pre_pro = { from: this.datepipe.transform(element?.from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.from_to_date[1], 'yyyy-MM-dd') };
                previousExperience.push(element.from_to_dates)
                propreviousExperience.push(element.pre_pro)
              }
            });

            propreviousExperience.forEach(item => {
              console.log(" why this test"+item)
              lupinproDate.push(item)
            });


            let companyExperience = []
            let lupincompanyExperience = []
            data?.company_experience.forEach((element, j) => {
              if (element.from != null) {
                element.from = this.datepipe.transform(element?.from, 'yyyy-MM-dd');
                element.to = this.datepipe.transform(element?.to, 'yyyy-MM-dd');
                element.lupin_from_to_dates = { from: this.datepipe.transform(element?.from, 'yyyy-MM-dd'), to: this.datepipe.transform(element?.to, 'yyyy-MM-dd') };
                element.lup_pro = { from: this.datepipe.transform(element?.from, 'yyyy-MM-dd'), to: this.datepipe.transform(element?.to, 'yyyy-MM-dd') };
                companyExperience.push(element.lupin_from_to_dates)
                lupincompanyExperience.push(element.lup_pro)
              }});
            // data?.company_experience.forEach((element, j) => {
            //   if (element?.lupin_from_to_date != null) {
            //     element.from = this.datepipe.transform(element?.lupin_from_to_date[0], 'yyyy-MM-dd');
            //     element.to = this.datepipe.transform(element?.lupin_from_to_date[1], 'yyyy-MM-dd');
            //     element.lupin_from_to_dates = { from: this.datepipe.transform(element?.lupin_from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.lupin_from_to_date[1], 'yyyy-MM-dd') };
            //     element.lup_pro = { from: this.datepipe.transform(element?.lupin_from_to_date[0], 'yyyy-MM-dd'), to: this.datepipe.transform(element?.lupin_from_to_date[1], 'yyyy-MM-dd') };
            //     companyExperience.push(element.lupin_from_to_dates)
            //     lupincompanyExperience.push(element.lup_pro)
            //   }
            // });
            let lupin_experience = lupincompanyExperience

            companyExperience.forEach(element => {
              previousExperience.push(element)
            });


            this.getOverLappedDate(previousExperience)
            if (this.getOverLappedDate(previousExperience)) {
              Swal.fire({
                icon: 'warning',
                text: "Experience Date overlap with another entry.Please update the dates!"
              });
              this.isFormLoading1 = false;
              return;
            }

            // this.getOverLappedDateCompany(lupinproDate)
            if (this.getOverLappedDateCompany(lupinproDate)) {
              Swal.fire({
                icon: 'warning',
                text: "Previous Promotion Month/Year overlap with Previous experience entry.Please update the dates!"
              });
              this.isFormLoading1 = false;
              return;
            }

            // if (this.checkPreviousExpWithCurrent(data?.previous_experience, data?.company_experience)) {
            //   Swal.fire({
            //     icon: 'warning',
            //     text: "Previous experience above current experience entry.Please update the dates!"
            //   });
            //
            //   this.isFormLoading1 = false;
            //   return;
            // }
            if (this.currentRoleOverlap(data?.company_experience)) {
              Swal.fire({
                icon: 'warning',
                text: "Previous experience overlap current experience entry.Please update the dates!"
              });

              this.isFormLoading1 = false;
              return;
            }
            // var d = this.checkforEndDateInLupinExperience(data?.company_experience)
            // if (d['status']) {
            //   Swal.fire({
            //     icon: 'warning',
            //     text: d['msg']
            //   });
            //
            //   this.isFormLoading1 = false;
            //   return;
            // }


            // this.getUnusedDate(data?.previous_promotion, lupin_experience)
            this.getOverLappedDateCompany(lupincompanyExperience)
            // if (!this.promotionNotAvailable) {
            //   let isKey = this.getUnusedDate(data?.previous_promotion, lupin_experience)
            //
            //   if (isKey) {
            //     Swal.fire({
            //       icon: 'warning',
            //       text: "Please enter the valid lupin previous promotion Month/Year!"
            //     });
            //     this.isFormLoading1 = false;
            //     return;
            //   }
            // }
            data?.previous_promotion.forEach(element => {
              delete element.chkDate
            });
            data?.previous_experience.forEach(item => {
              delete item.from_to_date
              delete item.pre_pro
            });
            data?.company_experience.forEach(item => {
              delete item.lupin_from_to_date
              delete item.lup_pro
            });
            if(this.promotionNotAvailable){
              data.previous_promotion=[{'data':'N/A'}]
            }
            if(this.experienceNotAvailable){
              data.previous_experience=[{'data':'N/A'}]
            }
            let subscriber = null;
            let message = null;
            if (this.talentId) {
              subscriber = this.appService.updateTelantCard(data, this.talentId);
              message = 'Talent card updated successfully';
            } else {
              subscriber = this.appService.createTelantCard(data);
              message = 'Talent card added successfully';
            }
            subscriber.subscribe(res => {
              this.isFormLoading1 = false;
              this.message.create('success', message, {
                nzDuration: 5000
              });
              this.ngViewInit()
            }, error => {
              this.isFormLoading1 = false;
              this.message.create('error', error.error.message, {
                nzDuration: 5000
              });
            });
          }
        });

      } else
      {
        this.isFormLoading1 = false;
        Swal.fire({
          icon: 'warning',
          text: 'Please fill all required fields then save form before submitting'
        });
      }
    }
  }
  resetLoader = false;
  talentCardFormRest() {
    this.resetLoader = true;
    this.getTalentCard()
  }


  onQueryParamsChangeList() {

  }
  promotionNA(e:Event) {
    // console.log(this.talentCardForm.controls.previous_promotion)
    this.promotionNotAvailable = this.talentCardForm.get('promotionNAchecked').value
    if (e) {
      const previous_promotion = this.talentCardForm.get('previous_promotion') as FormArray;
      previous_promotion.clear();
      previous_promotion.push(this.fb.group({
        "month/year": [null, ],
        from: [null, ],
        to: [null,],
      }));
      this.talentCardForm.get('previous_promotion').disable()

    }
    else{
      const previous_promotion = this.talentCardForm.get('previous_promotion') as FormArray;
      previous_promotion.clear();
      previous_promotion.push(this.fb.group({
        "month/year": [null, [Validators.required]],
        from: [null, [Validators.required]],
        to: [null, [Validators.required]],
      }));
    }
  }

  onCheckboxChanged(e, index) {
    console.log(e)
    if (e) {
      this.currentRoleSelectedIndex = index

    } else {
      this.currentRoleSelectedIndex = null
    }

    this.talentCardForm.controls.company_experience['controls'][index].controls.to.setValue(null)

  }
  experienceNA(e: Event) {
    console.log(this.experienceNotAvailable,"this.experienceNotAvailable")

    this.experienceNotAvailable = this.talentCardForm.get('experienceNAchecked').value
    if (e) {
      const previous_experience = this.talentCardForm.get('previous_experience') as FormArray;
      previous_experience.clear();
      previous_experience.push(this.fb.group({
        company_name: [null,],
        role: [null, ],
        from_to_date: [null, ],
        from: [null],
        to: [null],
      }));
      this.talentCardForm.get('previous_experience').disable()
    }
    else{
      const previous_experience = this.talentCardForm.get('previous_experience') as FormArray;
      previous_experience.clear();
      previous_experience.push(this.fb.group({
        company_name: [null, [Validators.required]],
        role: [null, [Validators.required]],
        from_to_date: [null, [Validators.required]],
        from: [null],
        to: [null],
      }));
    }
  }
}
