import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, Type } from '@angular/core';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import {
  Grade,
  GradeRetrieveRequest,
  Employee,
  EmployeeRetrieveRequest,
  EmployeeCSVSampleTemplateDocRetrieveRequest
} from 'src/app/app.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { profile } from 'console';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';

interface DataItem {
  display_name: string;
  email: string;
  employee_id: string;
  business_unit: string;
  function: string;
  location: string;
  department: string;
  role: string;
  grade: string;
  action: string;
}


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  // storage account url
  storageAccount: String;
  empImageSafeUrl: string;
  total = 1;
  // These are the Employees which are displayed in the list screen.
  employees: Employee[] = [];
  employeeId: number;

  searchValue = '';
  visible = false;
  searchValue1 = '';
  visible1 = false;
  multiKey = true;
  loading = false;
  pageSize = 100;
  pageIndex = 1;
  getAllemployees
  search_key=false;
  percent = 0
  // form shown inside the modal.
  isFormVisible = false;
  downloading = false;
  IsSelectOne = false;
  // listOfData: DataItem[] = [];
  listOfData: any;
  //bluk upload
  fileUploading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  iEmployeeModalOpen: boolean;
  selectedEmployeesFromAll: any;
  notSendedEmployees: any;

  /////this is for new emp popup
  isEmployeeLoading = false;
  pTotal = 1;
  pPage = 1;
  modalUploadTitle: any;

  task_id = '';

  sortDisplayNameFn = (a: DataItem, b: DataItem) => a.display_name.localeCompare(b.display_name)
  sortEmailFn = (a: DataItem, b: DataItem) => a.email.localeCompare(b.email)
  sortEmpIdFn = (a: DataItem, b: DataItem) => a.employee_id.localeCompare(b.employee_id)
  sortBSFn = (a: DataItem, b: DataItem) => a.business_unit.localeCompare(b.business_unit)
  sortFunFn = (a: DataItem, b: DataItem) => a.function.localeCompare(b.function)
  sortLocFn = (a: DataItem, b: DataItem) => a.location.localeCompare(b.location)
  sortDeptFn = (a: DataItem, b: DataItem) => a.department.localeCompare(b.department)
  sortRoleFn = (a: DataItem, b: DataItem) => a.role.localeCompare(b.role)
  sortGradeFn = (a: DataItem, b: DataItem) => a.grade.localeCompare(b.grade)

  constructor(private fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer) {
  }


  showImportModal(type:any): void {
    this.isFormVisible = true;
    this.modalUploadTitle = type
  }

  handleModalSubmit(): void {
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });

    if (formData) {
      if (this.modalUploadTitle == 'Import') {
        this.appService.importEmployees(formData).subscribe((res: any) => {
          this.fileList = [];
          this.isFormVisible = false;
          this.fileUploading = false;
          // if (res.type == "file") {
          if (res) {
            Swal.fire({
              icon: 'success',
              text: "XLSX processing has been queued, you will be emailed the status on your Registered Email",
              showConfirmButton: false,
              timer: 5000,
              allowOutsideClick: false,
            })
            // let path = res.result
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: 'There are some errors in the uploaded file, click on download to see it',
            //   confirmButtonText: 'Download',
            //   allowOutsideClick: false,
            // }).then(result => {
            //   this.spinner.show();
            //   if (result.isConfirmed) {
            //     let payload = {
            //       file_path: path
            //     }
            //     this.appService.getParticipantsExcel(payload).subscribe((res: Blob) => {
            //       var file = new Blob([res], {type: 'application/octet-stream'})
            //       FileSaver.saveAs(file, 'Employee-BulkUpload-Validation.xlsx');
            //       this.spinner.hide();
            //       this.fileList = [];
            //     }, err => {
            //       this.spinner.hide();
            //       this.fileList = [];
            //     });
            //   }
            // });
          // } else {
          //   Swal.fire({
          //     icon: 'success',
          //     text: res.result,
          //     showConfirmButton: false,
          //     timer: 5000,
          //     allowOutsideClick: false,
          //   })
          }


        }, error => {
          this.fileList = [];
          this.isFormVisible = false;
          this.fileUploading = false;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });

        });
      } else {
        console.log("updating")
        this.appService.bulkUpdateEmployees(formData).subscribe((res:any)=>{
          this.fileList = [];
          this.isFormVisible = false;
          this.fileUploading = false;
          if(res){
            Swal.fire({
              icon: 'success',
              text: res.result,
              showConfirmButton: false,
              timer: 5000,
              allowOutsideClick: false,
            })
          }
        },(error)=>{
          this.fileList = [];
          this.isFormVisible = false;
          this.fileUploading = false;

          Swal.fire({
            icon:'error',
            title: 'Oops...',
            text: error.error.message
          })
        })

      }
    }

  }

  handleModalCancel(): void {
    this.fileList = [];
    this.isFormVisible = false;
  }

  ngOnInit(): void {
    // this.getAllEmployees();

    this.task_id = sessionStorage.getItem('task_id')
    if(this.task_id){
      this.call_task_check(this.task_id)
    }
    this.storageAccount = this.appService.getMe().tenant.storage_url;
    this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null);
  }

  public remove(entity: Employee) {
    const index: number = this.employees.indexOf(entity);

    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.display_name + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteEmployee(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;


              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    if (this.searchValue != ''){
      this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, this.searchValue);
    }
    else{
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null);
    }
  }
  listOfCurrentPageData: ReadonlyArray<any> = [];

  onCurrentPageDataChange($event: ReadonlyArray<any>): void {
    this.listOfCurrentPageData = $event;
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null,  display_name: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new EmployeeRetrieveRequest('');
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    if (display_name != null){
      rr.display_name = display_name? display_name: null;
    }

    if (this.search_key == true){
      this.appService.getEmployeesSearch(rr).subscribe(data => {
        this.loading = false;
        this.total = data['_meta']['total_items'];
        this.employees = data['items'];
        let customData:any = this.employees;
        customData.forEach(item=>{
          item.display_name = item.display_name;
          item.email = item.user?.email ?? "";
          item.employee_id = item.employee_id;
          item.business_unit = item.business_unit?.name?item?.business_unit?.name:"--";;
          item.function = item.department?.function?.name?item.department?.function?.name:"--";
          item.location = item.location?.name?item.location?.name:"--";
          item.department = item.department_id ? item.department.name : "--";
          item.role = item.role_id ? item.role.role_name : "--";
          item.grade = item.grade?.grade_name ?? "--";
        })
        this.getAllemployees = customData
        this.listOfData = customData;

      });
    }
    else{
    this.appService.getEmployees(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.employees = data['items'];
      let customData: any = this.employees;
      customData.forEach(item => {
        item.display_name = item.display_name;
        item.email = item.user?.email ?? "";
        item.employee_id = item.employee_id;
        item.business_unit = item.business_unit?.name ? item?.business_unit?.name : "--";;
        item.function = item.department?.function?.name ? item.department?.function?.name : "--";
        item.location = item.location?.name ? item.location?.name : "--";
        item.department = item.department_id ? item.department.name : "--";
        item.role = item.role_id ? item.role.role_name : "--";
        item.grade = item.grade?.grade_name ?? "--";
      })
      this.getAllemployees = customData
      this.listOfData = customData;
      // console.log("employee data",this.employees)
    });}
  }

  async getAllEmployees() {
    // console.log('The employees here : ', this.notSendedEmployees);
    this.isEmployeeLoading=true;
    this.notSendedEmployees=[]
    // this.isEmployeeTableLoading = true;
    this.spinner.show()
    let rr = new EmployeeRetrieveRequest('');
    rr.sort_order = 'desc';
    rr.order_by = 'created_on';
    rr.per_page = 100;
    rr.page = 1;
    rr.notification_sent = 'no'

    this.pPage = 1;
    this.appService.getEmployees(rr).subscribe(async data => {
      // this.notSendedEmployees = data['items'];

      this.pTotal = data['_meta']['total_pages'];
      for (let index = 1; index <= this.pTotal; index++) {
        this.pPage = index;
        rr.page = this.pPage
        var nonSenddata = await this.appService.getEmployees(rr).toPromise();
        // console.log(nonSenddata);
        nonSenddata['items'].forEach(element => {
          this.notSendedEmployees.push(element)
        });
      }

            // console.log('The employees here : ', this.notSendedEmployees);

      this.spinner.hide()
      this.iEmployeeModalOpen = true;
      this.isEmployeeLoading=false;
      // this.isEmployeeTableLoading = false;

      // this.notSendedEmployees = data['items'];
      // console.log('The employees here : ', this.notSendedEmployees);
    });


  }

   async openEmployeeModal() {
    await  this.getAllEmployees()
    //  this.iEmployeeModalOpen = true;

  }

  handleEmployeeCancel() {
    this.iEmployeeModalOpen = false;
  }

  handleEmployeeOk() {
    const data = {
      ids: [...this.selectedEmployeesFromAll]
    };
    // console.log(data);
    this.appService.sendNotification(data).subscribe(result => {

      this.message.create('success', 'Notification process initiated please check your email for notification', {
        nzDuration: 5000
      });
      // console.log('The result aftr notification is : ', result);


    });
    this.handleEmployeeCancel();
  }

  setEmployeesForLA(employees) {
    this.selectedEmployeesFromAll = employees;
  }

  // ImageUrlSanitizer(url: string) {
  //   if (!url) {
  //     return null;
  //   } else {
  //     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //   }
  // }
  downloadSampleCSV() {
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/Employee-BulkUpload-Template.xlsx');
    //link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;
    // this.downloading = false;

    // let rr = new EmployeeCSVSampleTemplateDocRetrieveRequest();
    // rr.caption = 'Employee upload template';
    // this.appService.getEmployeeCSVSampleTemplateDoc(rr).subscribe(data => {
    //   this.downloading = false;

    //   var hiddenElement = document.createElement('a');
    //   hiddenElement.href = data['items'][0].document_url;
    //   hiddenElement.target = '_blank';
    //   hiddenElement.click();
    // });
  }

  // Search Functions
  search(): void {
    this.visible = false;
    // this.listOfData = this.getAllemployees.filter((item) => item.display_name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
    this.listOfData = this.getAllemployees.filter((item: any) => {
      let fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
      fullName = fullName.replace(/\s/g, "");
      let searchValue = this.searchValue.toLowerCase();
      searchValue = searchValue.replace(/\s/g, "");
      return fullName.indexOf(searchValue) !==-1;
      })
  }



  




searchServer():void{
  this.search_key = true;
  this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, this.searchValue);
}

  reset(): void {
    this.searchValue = '';
    this.searchValue1 = '';
    this.search_key=false;
    this.listOfData =  this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null);
    this.visible = false;
  }

  search1(): void {
    this.visible1 = false;
    this.listOfData = this.getAllemployees.filter((item) => item.email.toLowerCase().indexOf(this.searchValue1.toLowerCase()) !== -1);
  }
  reset1(): void {
    this.searchValue = '';
    this.searchValue1 = '';
    this.listOfData = this.getAllemployees;
    this.visible1 = false;
  }

  download_excel_employee(){
    this.appService.getEmployeeDataExcelDump().subscribe((res:any)=>{
      if(res){
        this.task_id = res.message;
        sessionStorage.setItem('task_id',this.task_id)
        this.call_task_check(this.task_id)
        Swal.fire({
          icon:'success',
          title:'Successfull',
          text:'Your employee data Excel file is on its way to your email. Please sit back and relax while the file is being prepared. You can track the progress in the progress bar.'
        })
      }
    })
  }

  dwld_intervval:any;
  call_task_check(id:any){
    
    this.dwld_intervval = setInterval(()=>{
      this.appService.get_employee_data_download_status({'id':id}).subscribe((res:any)=>{
        this.percent = res.progress;
        if(this.percent >= 100){
          clearInterval(this.dwld_intervval);
          this.task_id = ''
          sessionStorage.removeItem('task_id')
          return
        }
      })
    },2000)
  }

  ///this for new popup


}
