import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Assessment, AssessmentRetrieveRequest} from 'src/app/app.models';
import {AppService} from 'src/app/app.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js'
import {ExcelGeneratorService} from 'src/app/services/excel-generator/excel-generator.service';
import { saveAs } from 'file-saver';
import {NgxSpinnerService} from 'ngx-spinner';

interface DataItem {
  start_date: string;
  end_date: string;
};

@Component({
  selector: 'app-learning-assessment',
  templateUrl: './learning-assessment.component.html',
  styleUrls: ['./learning-assessment.component.css']
})
export class LearningAssessmentComponent implements OnInit {
  total = 1;
  loading = false;
  pageSize = 100;
  pageIndex = 1;
  assessments: Assessment[] = [];
  assessmentId: number;
  loggedInUserDetail: any;
  openPrematureModalPopup: boolean = false;
  openDeadlineModalPopup: boolean = false;
  prematureForm!: FormGroup;
  deadlineForm!: FormGroup;
  prematureOptionList = [];
  deadlineOptionList = [];
  myZoneAndTime: any;
  disabledDate: any;
  alreadyAdded = [];
  assessmentTitle;
  filterfn = false
  sortStartDateFn = (a: DataItem, b: DataItem) => a.start_date.localeCompare(b.start_date);
  sortEndDateFn = (a: DataItem, b: DataItem) => a.end_date.localeCompare(b.end_date);

  listOfFilter: [
    { text: 'Yes', value: 'yes' },
    { text: 'No', value: 'no' }
  ]

  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private modal: NzModalService,
    private message: NzMessageService,
    private router: Router,
    public fb: FormBuilder,
    private datePipe: DatePipe,
    private excelGenerator: ExcelGeneratorService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    this.getMyTime();
    this.resetDeadlineForm();
    this.resetPrematureForm();
  }

  resetPrematureForm() {
    this.prematureForm = this.fb.group({
      a_id: [null, Validators.compose([Validators.required])],
      p_id: [null, Validators.compose([Validators.required])],
      comment: [null, Validators.compose([Validators.required])]
    });
  }

  resetDeadlineForm() {
    this.deadlineForm = this.fb.group({
      a_id: [null, Validators.compose([Validators.required])],
      d_id: [null, Validators.compose([Validators.required])],
      extend_date: [null, Validators.compose([Validators.required])],
      comment: [null, Validators.compose([Validators.required])]
    });
  }
  dupPageIndex :any;
  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort, filter} = params;
    // console.log('Params', params);
    this.dupPageIndex = params.pageIndex
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    // const sortField = filter[0]?.key;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    // if (this.loggedInUserDetail.user_type == "employee") {
    //   let rr = new NewsFeedRetrieveRequest();
    //   rr.emp_id = this.loggedInUserDetail.employee.id;
    //   this.appService.getNewsFeeds(rr).subscribe(data => {
    //     let assessment_ids = [];
    //     data['items'].forEach(e => {
    //       assessment_ids.push(e.feed_parent_id);
    //     });
    //     let farr = new AssessmentRetrieveRequest();
    //     farr.assessment_type = "learning_assessment";
    //     farr.sort_order = sortOrder;
    //     farr.order_by = sortField;
    //     farr.per_page = pageSize;
    //     farr.page = pageIndex;
    //     farr.ids = assessment_ids;
    //     this.appService.getAssessments(farr).subscribe(data => {
    //       this.loading = false;
    //       this.total = data['_meta']["total_items"];
    //       this.assessments = data['items'];
    //     });
    //   });
    // } else if (this.loggedInUserDetail.user_type !== "employee") {
    let rr = new AssessmentRetrieveRequest();
    rr.assessment_type = 'learning_assessment';
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'id';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    this.appService.getAssessments(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.assessments = data['items'];
      // console.log('Assessment Data', this.assessments);
      this.filterfn = true
    });
    // }

  }

  public launch_assessment(entity: Assessment) {
    const index: number = this.assessments.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Do you want to launch',
        nzContent: `Assessment cannot be edited after launch.<br>Click Ok to Proceed`,
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            entity.launched = 'yes';

            this.appService.launchAssessment(entity, entity.id).subscribe(res => {
              this.pageIndex = this.dupPageIndex?this.dupPageIndex:this.pageIndex;
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              // console.log('this.pageIndex, this.pageSize',this.pageIndex, this.pageSize)
              entity.launched = 'yes';
              this.message.create('success', 'Assessment Launched', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {
              entity.launched = 'no';

              //const errorMessage = error.error.message

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }
  }

  public remove(entity: Assessment) {
    const index: number = this.assessments.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete "' + entity.title + '"?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteAssessment(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  // public startAssessment(assessment) {
  //   if (assessment.attempt_id) {
  //     this.router.navigate(['learning-assessment/learning-assessment-attempt/'+ assessment.attempt_id]);
  //   } else {
  //     // api call to create assessment attempt
  //     let data = {
  //       // activity_type: "learning_assessment",
  //       assessment_id: assessment.id,
  //       employee_id: this.loggedInUserDetail.employee.id
  //     }
  //     this.appService.createAssessmentAttempt(data).subscribe((res: any) =>
  //     {
  //       console.log(res);
  //       this.router.navigate(['learning-assessment/learning-assessment-attempt/'+ res.id]);
  //     }, error => {
  //       let errorMessage = error.error.message;
  //       this.message.create('error', errorMessage, {
  //         nzDuration: 5000
  //       });
  //     });
  //   }
  // }
  back() {
    this.router.navigate(['/']);
  }

  downloadSummary(assessment) {
    let is_assessor = false;
    assessment.assessors.forEach(element => {
      if (element == this.loggedInUserDetail.employee.id) {
        is_assessor = true;
      }
    });
    if (is_assessor) {
      let data = {
        'assessment_id': assessment.id
      };
      this.appService.createAssessmentActivitiesAttemptsSummary(data).subscribe(data => {
        // console.log(data);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'Assessment-Activities-Attempts-Summary_'+this.assessmentTitle+'.csv';
        hiddenElement.click();
      });
    } else {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You are not assessor for this Assessment.'
      });

    }
  }

  activityAdminView(assessment) {
    let is_assessor = false;
    assessment.assessors.forEach(element => {
      if (element == this.loggedInUserDetail.employee.id) {
        is_assessor = true;
      }
    });
    if (is_assessor) {
      this.router.navigate(['/learning-assessment/activities', assessment.id]);
    } else {


      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You are not assessor for this Assessment.'
      });
    }

  }


  async openPrematureModal(data) {
    if (data.launched == 'yes') {
      this.resetPrematureForm();
      this.prematureForm.controls.a_id.setValue(data.id);
      // console.log(data.id);
      let flag = 0;
      this.appService.getAssessmentCategoryData(data.id, 'premature_closure').subscribe((res: any) => {
        //console.log("DATA From PRe",res)
        if (res.length > 0) {
          flag = 1;
        }
        if (flag == 0) {
          this.openPrematureModalPopup = true;
          this.getAllCategories('premature_closure');
        } else {
          this.modal.confirm({
            // nzTitle: 'Info',
            nzContent: 'Assessment is already closed due to :<br> <b>Reason</b> : ' + res[0].category.category_name + '<br> ' + ' <b>Comments</b> :' + res[0].comment,
            nzOnOk: () => {
            }
          });
        }

      });
    } else {
      this.modal.confirm({
        nzTitle: 'Info',
        nzContent: 'Assessment is not launched so you can delete the assessment !',
        nzOnOk: () => {
        }
      });

    }
  }

  prematureHandleModalOk() {
    this.openPrematureModalPopup = false;
    this.appService.savePrematureClosureandDeadline({
      category_id: this.prematureForm.value.p_id,
      assessment_id: this.prematureForm.value.a_id,
      comment: this.prematureForm.value.comment
    }).subscribe(res => {
      if (res) {
        //console.log(res);
        this.message.create('success', 'Assessment prematurely closed', {
          nzDuration: 5000,
        });
      }
      this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);
    }, err => {
      if (err) {
        //console.log(err);
      }
    });
  }

  prematureHandleModalCancel() {
    this.openPrematureModalPopup = false;
  }

  async getAllCategories(type) {
    this.appService.getAllCategories(type).subscribe((res: []) => {
      if (res.length > 0) {
        if (type == 'premature_closure') {
          this.prematureOptionList = res;
        } else {
          this.deadlineOptionList = res;
        }
      }
    });
  }


  getMyTime() {
    this.appService.getMyTime().subscribe(res => {
      this.myZoneAndTime = res;
    });
  }

  getDownloadStatusCheck() {

  }

  openDownloadReports = false;
  downloadStatus;
  assess_Id
  downloadReport(item){
    this.assess_Id = item.id;
    this.assessmentTitle=item.title

      this.appService.getDownloadStatusCheck(item.id).subscribe(res => {
        this.downloadStatus = res
        // console.log('this.downloadStatus',this.downloadStatus)
      },err=>{
        this.downloadStatus = err.error.text
        if(this.downloadStatus == 'No'){
          Swal.fire({
            icon: 'warning',
            text: "Can't generate Reports. No Participant has completed any activities yet!"
          });
        }else{
          this.openDownloadReports = true;
        }
      });
    }
  downloadModalCancel(){
  this.openDownloadReports = false;
  }
  downloadModalOk(){
  this.openDownloadReports = false;
  }
  isDownloadStatus = false;
  downloading=false;
  downloadScore(){
    Swal.fire('', 'Assessment Scores will be notified through email.', 'success');
    this.openDownloadReports = false;

    this.appService.getDownloadScoreDetails(this.assess_Id).subscribe(result => {

      // console.log(result);
      // console.log(result['assessment_title'].join());

    },err=>{
      Swal.fire('', 'Something went wrong, please try again later.', 'warning');
    });


  }

  // downloadAssStatus(){
  //   this.isDownloadStatus = true;
  //   this.spinner.show();
  //   this.appService.getDownloadStatusDetails(this.assess_Id).subscribe(result => {
  //     this.excelGenerator.generateExcelAssessmentStatus(result,this.loggedInUserDetail.tenant.variable_filter_field,this.assessmentTitle);
  //     this.isDownloadStatus = false;
  //     this.spinner.hide();
  //     this.openDownloadReports = false;
  //   },err=>{
  //     this.isDownloadStatus = false;
  //     this.spinner.hide();
  // });
  // }
  downloadAssStatus(){
       this.isDownloadStatus = true;
    this.appService.getDownloadStatusDetails(this.assess_Id).subscribe(result => {
           this.openDownloadReports = false;
         this.isDownloadStatus = false;
      Swal.fire('', result['message'], 'success');

      // console.log(result);
      // console.log(result['assessment_title'].join());

    },err=>{
         this.isDownloadStatus = false;
              this.openDownloadReports = false;
      Swal.fire('', 'Something went wrong, please try again later.', 'warning');
    });
  }

}


