import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {CampaignItemGridViewRetrieveRequest, EmployeeTransactionRetrieveRequest, CampaignsRetrieveRequest} from './../../../app.models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {AppService} from 'src/app/app.service';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {CampaignEmployees, CampaignEmployeesRetrieveRequest} from 'src/app/app.models';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-list-of-transactions',
  templateUrl: './list-of-transactions.component.html',
  styleUrls: ['./list-of-transactions.component.css']
})
export class ListOfTransactionsComponent implements OnInit {
  currentDate = new Date();
  loading = false;
  loggedInUserDetail: any;
  transactionForm: FormGroup;
  action = 'create';
  titleForTransactionModal = 'You observed a Integrity behavior in:';
  isVisibleTransactionModal = false;
  isTransactionFormLoading = false;
  isAcceptButtonLoading = false;
  isRejectButtonLoading = false;
  isGiveFeedback: boolean = false;
  listOfTransactionType = [
    {label: 'Seeking From Peer', value: 'seeking from peer'},
    {label: 'Sharing With Peer', value: 'sharing with peer'},
    {label: 'Self', value: 'self'}
  ];
  selectedTransactionType: null;
  clickedRowDetails: any;
  selectedCampaignItemId: null;
  campaignDetails: any;
  selectedTechLevelLabel = null;
  campaignItemsTree: any;
  transactionDetails: any;
  employeeOptionList: CampaignEmployees[] = [];
  employeeOptionListSearchChange$ = new BehaviorSubject('');
  isEmployeeOptionListLoading = false;
  campaignItemDetails: null;
  // campaign Dropdown related
  campaignListForm: FormGroup;
  selectedCampaignId: number = null;
  selectedCampaignDetails = null;
  isCampaignExpire: boolean = false;
  // table pagination realted
  // loading = true;
  pageSize = 100;
  pageIndex = 1;
  total = 1;

  // campaign_list = 'Please select campaign';
  onEmployeeOptionListSearch(value: string): void {
    this.isEmployeeOptionListLoading = true;
    this.employeeOptionListSearchChange$.next(value);
  }

  constructor(private fb: FormBuilder,
              private appService: AppService,
              private modal: NzModalService,
              public message: NzMessageService,
              public datePipe: DatePipe,) {
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.initTransactionForm();
    // this.transactionForm.controls['employee_ids'].disable();
    // this.transactionForm.controls['manager_ids'].disable();
    // this.getEmployeeTransactionById();
    // this.getEmployeeTransaction();
    // this.loadDataFromServer(this.pageIndex, this.pageSize, 'desc', 'id', null, []);
    this.getCampaigns();
    this.initCampaignListForm();
  }

// campaign Dropdown related
  initCampaignListForm() {
    this.campaignListForm = this.fb.group({
      campaign_list: [null]
    });
  }

  initTransactionForm(): void {
    this.transactionForm = this.fb.group({
      campaign_id: [null, [Validators.required]],
      campaign_item_id: [null, [Validators.required]],
      share_type: [null, [Validators.required]],
      displayer_comments: [null],
      observer_comments: [null],
      employee_ids: [null],
      manager_ids: [null],
    });
  }

  async showTransactionModal() {
    this.action = 'create';
    this.isVisibleTransactionModal = true;
    this.selectedTechLevelLabel = null;
    await this.transactionForm.reset();
    await this.getCampaigns();
    await this.transactionForm.controls['share_type'].setValue('seeking from peer');
    // this.getEmployees();
  }

  async editTransactionModal(data) {
    this.action = 'edit';
    this.clickedRowDetails = data;
    await this.getEmployees();
    await this.getCampaigns();
    // console.log('clickedRowDetails', data);
    this.isVisibleTransactionModal = true;
    this.selectedTechLevelLabel = null;
    // await this.transactionForm.reset();
    await this.transactionForm.patchValue(this.clickedRowDetails);

    if (this.clickedRowDetails?.share_type === 'seeking from peer') {
      await this.transactionForm.controls['employee_ids'].setValue(this.clickedRowDetails.observer_emp_id);
    } else if (this.clickedRowDetails?.share_type === 'sharing with peer') {
      await this.transactionForm.controls['employee_ids'].setValue(this.clickedRowDetails.displayer_emp_id);
    } else if (this.clickedRowDetails?.share_type === 'self') {
      await this.transactionForm.controls['employee_ids'].setValue(this.clickedRowDetails.displayer_emp_id);
      await this.transactionForm.controls['manager_ids'].setValue(this.clickedRowDetails.observer_emp_id);
    }
  }

  transactionModalHandleCancel() {
    this.transactionForm.reset();
    this.isVisibleTransactionModal = false;
    this.transactionForm.enable();
    this.isGiveFeedback = false;
    this.clickedRowDetails = null;
    this.isCampaignExpire = false;
  }

  transactionModalHandleSubmit() {
    for (const i in this.transactionForm.controls) {
      this.transactionForm.controls[i].markAsDirty();
      this.transactionForm.controls[i].updateValueAndValidity();
    }
    this.isTransactionFormLoading = true;
    // Create the employee transaction on the server.
    if (this.transactionForm.status === 'VALID') {
      let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'IST'); //, "IST"HH:mm:ss
      let subscriber = null;
      let message = null;
      let data = null;
      let transactionDate = null;
      if (this.action === 'create') {
        transactionDate = date;
      } else {
        transactionDate = this.clickedRowDetails.transaction_date;
      }

      if (this.selectedTransactionType === 'seeking from peer') {
        data = {
          campaign_id: this.selectedCampaignId,
          displayer_emp_id: this.loggedInUserDetail?.employee?.id,
          observer_emp_id: this.transactionForm.value.employee_ids,
          transaction_date: transactionDate,
          campaign_item_id: this.transactionForm.value.campaign_item_id,
          share_type: this.transactionForm.value.share_type,
          displayer_comments: this.transactionForm.value.displayer_comments,
          observer_comments: this.transactionForm.value.observer_comments,
        };
        if (this.isGiveFeedback) {
          data = {
            observer_comments: this.transactionForm.value.observer_comments,
            status: 'pending acceptance',
          };
        }
      } else if (this.selectedTransactionType === 'sharing with peer') {
        data = {
          campaign_id: this.selectedCampaignId,
          observer_emp_id: this.loggedInUserDetail?.employee?.id,
          displayer_emp_id: this.transactionForm.value.employee_ids,
          status: 'pending acceptance',
          transaction_date: transactionDate,
          campaign_item_id: this.transactionForm.value.campaign_item_id,
          share_type: this.transactionForm.value.share_type,
          displayer_comments: this.transactionForm.value.displayer_comments,
          observer_comments: this.transactionForm.value.observer_comments,
        };
        if (this.isGiveFeedback) {
          data = {observer_comments: this.transactionForm.value.observer_comments,};
        }
      } else if (this.selectedTransactionType === 'self') {
        data = {
          campaign_id: this.selectedCampaignId,
          displayer_emp_id: this.loggedInUserDetail?.employee?.id,
          observer_emp_id: this.transactionForm.value.manager_ids,
          campaign_item_id: this.transactionForm.value.campaign_item_id,
          share_type: this.transactionForm.value.share_type,
          displayer_comments: this.transactionForm.value.displayer_comments,
          observer_comments: this.transactionForm.value.observer_comments,
          transaction_date: transactionDate,
        };
        if (this.isGiveFeedback) {
          data = {
            observer_comments: this.transactionForm.value.observer_comments,
            status: 'pending acceptance',
          };
        }
      }
      if (this.action === 'create') {
        subscriber = this.appService.createEmployeeTransaction(data);
        message = 'Transaction "' + this.transactionForm.controls['share_type'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateEmployeeTransaction(data, this.clickedRowDetails.id);
        message = 'Transaction"' + this.transactionForm.controls['share_type'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isTransactionFormLoading = false;
        this.campaignListForm.controls['campaign_list'].setValue(null);
        this.transactionModalHandleCancel();
        this.message.create('success', message, {
          nzDuration: 5000
        });
        // this.getEmployeeTransaction();
        this.loadDataFromServer(this.pageIndex, this.pageSize, 'id', 'desc', null, null);
        // if (this.isGiveFeedback){
        //   this.transactionForm.enable();
        //   this.isGiveFeedback = false;
        // }
      }, error => {
        this.isTransactionFormLoading = false;
        this.transactionModalHandleCancel();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }

  }

  // Transaction Modal related
  onTransactionTypeChange() {
    this.selectedTransactionType = this.transactionForm.get('share_type').value;
    // console.log(this.selectedTransactionType);
    if (this.selectedTransactionType === 'seeking from peer') {
      // this.titleForTransactionModal = "Someone observed a Integrity behavior in you:";
      this.transactionForm.controls['manager_ids'].setValidators(null);
      this.transactionForm.controls['employee_ids'].setValidators([Validators.required]);
      this.transactionForm.controls['employee_ids'].updateValueAndValidity();
      this.transactionForm.controls['manager_ids'].updateValueAndValidity();
    } else if (this.selectedTransactionType === 'sharing with peer') {
      // this.titleForTransactionModal = "You observed a Integrity behavior in:";
      this.transactionForm.controls['manager_ids'].setValidators(null);
      this.transactionForm.controls['employee_ids'].setValidators([Validators.required]);
      this.transactionForm.controls['employee_ids'].updateValueAndValidity();
      this.transactionForm.controls['manager_ids'].updateValueAndValidity();
    } else if (this.selectedTransactionType === 'self') {
      // this.titleForTransactionModal = "Seek Recommendation from Manager";
      this.transactionForm.controls['employee_ids'].setValidators(null);
      this.transactionForm.controls['manager_ids'].setValidators([Validators.required]);
      this.transactionForm.controls['employee_ids'].updateValueAndValidity();
      this.transactionForm.controls['manager_ids'].updateValueAndValidity();
    }
  }

  getEmployees() {
    // to get employees
    const optionList = (functionName: any) => {
      this.isEmployeeOptionListLoading = true;
      let rr = new CampaignEmployeesRetrieveRequest(functionName);
      rr.emp_id = this.loggedInUserDetail?.employee?.id;
      rr.campaign_id = this.selectedCampaignId;
      rr.txn_type = this.selectedTransactionType;
      // if (this.action === 'edit') {
      //   rr.emp_id= this.loggedInUserDetail?.employee?.id;
      //   rr.campaign_id= this.clickedRowDetails?.campaign_id;
      //   rr.txn_type= this.clickedRowDetails?.share_type;
      // }
      return this.appService.getCampaignEmployees(rr)
        .pipe(
          map((res: any) => {
            // this.transactionForm.controls['employee_ids'].setValue(this.clickedRowDetails.displayer_emp_id);
            return res['items'];
          })
        );
    };
    const optionList$: Observable<CampaignEmployees[]> = this.employeeOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.employeeOptionList = data;
      this.isEmployeeOptionListLoading = false;
      // if (this.action === 'edit') {
      //   this.transactionForm.controls['employee_ids'].setValue(this.clickedRowDetails.displayer_emp_id);
      //   this.transactionForm.controls['manager_ids'].setValue(this.clickedRowDetails.displayer_emp_id);
      // }
    });
  }

  // campaign Item Dropdown change related
  onCampaignItemChange(campaignItemId: any) {
    // this.selectedCampaignItemId = this.transactionForm.get('campaign_item_id').value;
    this.selectedCampaignItemId = campaignItemId;
    // console.log('selectedCampaignItemId', this.selectedCampaignItemId);
    // finding tech level last label
    // let result= this.campaignDetails.find(x => x.id === this.selectedCampaignItemId);
    // this.selectedTechLevelLabel= result.level_names[result.level_names.length-1];
    if (this.selectedCampaignItemId !== null) {
      // this.transactionForm.controls['employee_ids'].enable();
      // this.transactionForm.controls['manager_ids'].enable();
      this.getEmployees();
    }
  }

  // Get List of Campaigns
  getCampaigns() {
    let rr = new CampaignsRetrieveRequest();
    rr.emp_id = this.loggedInUserDetail?.employee?.id;
    this.appService.getCampaigns(rr).subscribe(res => {
      this.campaignDetails = res['items'];
    });
  }

// campaign Dropdown change related
  onCampaignChange(value: number) {
    this.selectedCampaignId = value;
    if (this.selectedCampaignId !== null) {
      this.getCampaignItemGridView();
      // this.getEmployeeTransaction();
      this.loadDataFromServer(this.pageIndex, this.pageSize, 'id', 'desc', null, null);
    }
  }

//Modal Dropdown changes related
  onModalCampaignChange(value: number) {
    this.selectedCampaignId = value;
    if (this.selectedCampaignId !== null) {
      this.getCampaignItemGridView();
      this.selectedCampaignDetails = this.campaignDetails.find(x => x.id === this.selectedCampaignId);
      // console.log('selectedCampaignDetails', this.selectedCampaignDetails);
      let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd', 'IST');
      let sdate = this.datePipe.transform(this.selectedCampaignDetails?.start_date, 'yyyy-MM-dd', 'IST');
      let edate = this.datePipe.transform(this.selectedCampaignDetails?.end_date, 'yyyy-MM-dd', 'IST');
      // console.log('current', date);
      // console.log('start', sdate);
      // console.log('end', edate);
      // console.log('con 1', edate >= date);
      // console.log('con 2', sdate <= date);
      if (edate >= date && sdate <= date) {
          // console.log('yes');
        this.isCampaignExpire = false;
      } else {
        this.isCampaignExpire = true;
      }
      this.transactionForm.controls['campaign_item_id'].reset();
      this.transactionForm.controls['employee_ids'].reset();
      this.transactionForm.controls['manager_ids'].reset();
    }
    // else{
    // this.transactionForm.controls['campaign_item_id'].reset();
    // this.transactionForm.controls['employee_ids'].reset();
    // this.transactionForm.controls['manager_ids'].reset();
    // }
  }

  getCampaignItemGridView() {
    let rr = new CampaignItemGridViewRetrieveRequest();
    rr.campaign_id = this.selectedCampaignId;
    rr.txn_counts = 'yes';
    this.appService.getCampaignItemGridView(rr).subscribe(res => {
      this.campaignItemDetails = res['items'];
    });
  }

  // Transaction table related
  // getEmployeeTransaction() {
  //   this.loading = true;
  //   let rr = new EmployeeTransactionRetrieveRequest();
  //   rr.campaign_id = this.selectedCampaignId;
  //   this.appService.getEmployeeTransactions(rr).subscribe(res => {
  //     this.transactionDetails = res['items'];
  //     this.loading = false;
  //   });
  // }
  // Transaction table remove record related
  public remove(data) {
    this.modal.confirm({
      nzTitle: 'Confirm Delete',
      nzContent: 'Are you sure, you want to delete?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          let subscriber = null;

          subscriber = this.appService.deleteEmployeeTransaction(data.id);
          subscriber.subscribe(res => {

            this.message.create('success', 'Record Deleted', {
              nzDuration: 5000
            });
            // this.getEmployeeTransaction();
            this.loadDataFromServer(this.pageIndex, this.pageSize, 'id', 'desc', null, null);
            resolve(res);
          }, error => {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });

            reject();
          });

        }).catch(() => console.log('Oops errors!'))
    });
  }

  // Accept, Reject & Deny Feedback Button related
  updateTransactionStatus(btnType: string, item: any) {
    let clickedButtonType = btnType;
    let clickedTransactionDetails = item;

    let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'IST'); //, "IST"HH:mm:ss
    let subscriber = null;
    let message = null;
    let data = null;
    if (clickedButtonType === 'accept') {
      data = {status: 'accepted', acceptance_date: date,};
      this.isAcceptButtonLoading = true;
    } else if (clickedButtonType === 'reject') {
      data = {status: 'rejected', transaction_date: date,};
      this.isRejectButtonLoading = true;
    } else if (clickedButtonType === 'deny') {
      data = {status: 'acknowledgement denied', transaction_date: date,};
      this.isRejectButtonLoading = true;
    }
    subscriber = this.appService.updateEmployeeTransaction(data, clickedTransactionDetails.id);
    message = 'Transaction"' + clickedTransactionDetails?.share_type + '" has been updated.';

    subscriber.subscribe(res => {
      this.isRejectButtonLoading = false;
      this.isAcceptButtonLoading = false;
      this.message.create('success', message, {
        nzDuration: 5000
      });
      // this.getEmployeeTransaction();
      this.loadDataFromServer(this.pageIndex, this.pageSize, 'id', 'desc', null, null);
    }, error => {
      this.isRejectButtonLoading = false;
      this.isAcceptButtonLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }

  // give Feedback Button related
  giveFeedback(data: any) {
    this.isGiveFeedback = true;
    this.transactionForm.disable();
    // this.transactionForm.controls['employee_ids'].disable();
    // this.transactionForm.controls['campaign_item_id'].disable();
    // this.transactionForm.controls['manager_ids'].disable();
    // this.transactionForm.controls['share_type'].disable();
    this.transactionForm.controls['observer_comments'].enable();
    this.editTransactionModal(data);
  }

  // table pagination related
  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null, []);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, containsName: string | '', ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new EmployeeTransactionRetrieveRequest();
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    rr.campaign_id = this.selectedCampaignId;
    rr.displayer_emp_id = this.loggedInUserDetail?.employee?.id;
    rr.observer_emp_id = this.loggedInUserDetail?.employee?.id;
    // rr.ids = ids;

    this.appService.getEmployeeTransactions(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.transactionDetails = data['items'];
      // console.log('transactionDetails', this.transactionDetails);

    });
  }
}
