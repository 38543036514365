<!-- Sign in Start -->
<section class="sign-in-page">
  <!-- <div id="container-inside">
      <div id="circle-small"></div>
      <div id="circle-medium"></div>
      <div id="circle-large"></div>
      <div id="circle-xlarge"></div>
      <div id="circle-xxlarge"></div>
  </div> -->

  <!-- <img src="../../../assets/images/logos/logo-client.png" class="clientLogo" alt="Client Logo">   -->
  <div class="container p-0">
    <div class="row no-gutters">
      <div class="col-md-6 text-center pt-5">
        <div class="sign-in-detail text-white">
          <a class="sign-in-logo1" href="#"><img src="../../../assets/images/logos/ESPL_PNG_03.png" class="img-fluid" alt="logo" width="160" height="160"></a>

          <div style="width: 100%; height: 90%">
            <nz-carousel [nzEffect]="effect" nzAutoPlay>
              <div nz-carousel-content *ngFor="let s of slides" style="margin-top: 100px">
                <img [src]="s.image" class="img-fluid" alt="logo">
                <div>
                  <h4 class="mb-5 imgLabel text-center">Deliver Performance</h4>
                </div>
              </div>
            </nz-carousel>
          </div>


          <!-- <div class="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
              <div class="item">
                  <img src="../../../assets/images/login/1.png" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">Find new friends</h4>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              </div>
              <div class="item">
                  <img src="../../../assets/images/login/2.png" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">Connect with the world</h4>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              </div>
              <div class="item">
                  <img src="../../../assets/images/login/3.png" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">Create new events</h4>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              </div>
          </div> -->
        </div>
      </div>
      <div class="col-md-4 bg-purple text-white pt-5" *ngIf="!forgot">
        <div class="sign-in-from">
          <h3 class="text-white mb-4 text-center"><img width="200" src="../../../assets/images/TruLead Logo.png"></h3>
          <h1 class="mb-0 text-white">Sign in</h1>
          <p>Enter your username and password to access.</p>

          <form nz-form [formGroup]="loginForm" class="login-form mt-4 text-white" (ngSubmit)="onLoginFormSubmit()">
            <div>

              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your username!">
                  <div class="form-group">
                    <label>Username</label>
                    <input type="text" nz-input formControlName="username" autocomplete="on" nzSize="large"
                           placeholder="Username"/>
                  </div>
                </nz-form-control>
              </nz-form-item>

            </div>


            <div *ngIf="stage_message=='email_available' || stage_message=='multi_factor_otp_success'">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input Token">
                  <div class="form-group">
                    <label>OTP</label>
                    <input autocompleteOff type="text" nz-input formControlName="otp" nzSize="large" placeholder="OTP"/>
                  </div>
                </nz-form-control>
              </nz-form-item>
              <ng-container *ngIf="timerStart==0; else resendTimerMsg">
                <button [nzType]="'primary'" class="login-form-button login-form-margin" nz-button style="float: right;"
                        (click)="resendOTP()">Resend OTP
                </button>
              </ng-container>
              <ng-template #resendTimerMsg><span style="top:-10px; position: relative;">Time remaining to resend otp {{timerStart}}s</span>
              </ng-template>
            </div>

            <div *ngIf="stage_message=='password_reset'">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input Password">
                  <div class="form-group">
                    <label>Password <a class="ml-2" nzTooltipTitle="{{info}}" nzTooltipPlacement="right" nz-tooltip><i class="fa fa-info-circle" aria-hidden="true"></i></a></label>
                    <input type="password" nz-input formControlName="password1" nzSize="large" placeholder="Password"/>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control nzErrorTip="Password Mismatch">
                  <div class="form-group">
                    <label>Retype Password</label>
                    <input type="password" nz-input formControlName="password2" nzSize="large"
                           placeholder="Retype Password"/>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control nzErrorTip="Required">
                  <div class="form-group">
                    <label nz-checkbox [(ngModel)]="termsChecked" (ngModelChange)="onTermsChanged()"
                           [ngModelOptions]="{standalone: true}"> I agree </label><a class="a-text"
                                                                                     (click)="onTermsClicked()">Terms
                    & Conditions</a>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control nzErrorTip="Required">
                  <div class="form-group">
                    <label nz-checkbox [(ngModel)]="privacyChecked" (ngModelChange)="onPrivacyChanged()"
                           [ngModelOptions]="{standalone: true}"> I agree </label><a class="a-text"
                                                                                     (click)="onPrivacyClicked()">Privacy
                    Policy</a>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>


            <div *ngIf="stage_message=='email_verified'">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your Password!">
                  <div class="form-group">
                    <label>Password</label>
                    <input type="password" nz-input formControlName="password" nzSize="large" placeholder="Password"/>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>

            <!-- <div *ngIf="stage_message=='email_verified'">
              <nz-form-item>
                <div class="form-group">
                  <re-captcha
                    (resolved)="resolved($event)"
                    siteKey='{{siteKey}}'>
                  </re-captcha>
                </div>
              </nz-form-item>
            </div> -->
            <!-- <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Password!">
                <div class="form-group">
                    <label>Corporate Id</label>
                    <input type="text" nz-input formControlName="corporate_id" nzSize="large" placeholder="Enter Id" />
                </div>
              </nz-form-control>
            </nz-form-item> -->
            <div nz-row class="login-form-margin">

              <ng-template [ngIf]="stage_message=='email_verified'" [ngIfElse]="next_block">
                <div nz-col [nzSpan]="16">
                  <label nz-checkbox nzSize="large" formControlName="remember_me">
                    <span>Remember me</span>
                  </label>
                </div>


                <!-- <div  nz-col [nzSpan]="12">
                  <a class="login-form-forgot">Forgot password</a>
                </div> -->

                <div nz-col [nzSpan]="8" class="text-right">
                  <button [disabled]="!loginForm.valid || !nextButtonEnable" nz-button
                          class="login-form-button login-form-margin"
                          [nzType]="'primary'">Next
                  </button>
                </div>

                <div style="height: 50px;"></div>
                <div nz-col [nzSpan]="20">
                  <label nz-button nzSize="large">
                    <button class="forgotbutton login-form-margin" nz-button id="next_button"
                            nzType="primary" (click)="forgotaction()">Forgot password?
                    </button>
                  </label>
                </div>

              </ng-template>

              <ng-template #next_block>
                <nz-form-item>
                  <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 20, offset: 4 }">
                    <button [disabled]="!loginForm.valid" class="login-form-button login-form-margin" nz-button
                            id="next_button"
                            nzType="primary">Login
                    </button>
                    <br>

                    <br>


                  </nz-form-control>
                </nz-form-item>
              </ng-template>


            </div>
          </form>
        </div>
      </div>

      <div class="col-md-4 bg-purple text-white pt-5" *ngIf="forgot">
        <div class="sign-in-from">
          <h3 class="text-white mb-4 text-center"><img width="200" src="../../../assets/images/TruLead Logo.png"></h3>
          <h1 class="mb-0 text-white">{{title}}</h1>
          <p>Enter your email address for account verification</p>
          <form nz-form [formGroup]="forgotForm" class="login-form mt-4 text-white" (ngSubmit)="onforgotFormSubmit()">
            <div>

              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your username!">
                  <div class="form-group">
                    <label>Email address/Username</label>
                    <input type="text" nz-input formControlName="username" autocomplete="on" nzSize="large"
                           placeholder="Email address"/>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="forgot_stage_message=='email_verified'">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input Token">
                  <div class="form-group">
                    <label>OTP</label>
                    <input autocompleteOff type="text" nz-input formControlName="otp" nzSize="large" placeholder="OTP"/>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="forgot_stage_message=='otp_verified'">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input Password">
                  <div class="form-group">
                    <label>Password <a class="ml-2" nzTooltipTitle="{{info}}" nzTooltipPlacement="right" nz-tooltip><i class="fa fa-info-circle" aria-hidden="true"></i></a></label>
                    <input type="password" nz-input formControlName="password1" nzSize="large" placeholder="Password"/>
                  </div>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control nzErrorTip="Password Mismatch">
                  <div class="form-group">
                    <label>Retype Password</label>
                    <input type="password" nz-input formControlName="password2" nzSize="large"
                           placeholder="Retype Password"/>
                  </div>
                </nz-form-control>
              </nz-form-item>

            </div>

            <div nz-row class="login-form-margin">
              <nz-form-item>
                <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 20, offset: 4 }">
                  <button [disabled]="!forgotForm.valid" class="login-form-button login-form-margin" nz-button
                          id="next_button"
                          nzType="primary">Submit
                  </button>
                  <br>

                  <br>


                </nz-form-control>
              </nz-form-item>
            </div>


          </form>
        </div>
      </div>


    </div>
  </div>
</section>

<nz-modal nzMaskClosable="false" [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()"
          (nzOnOk)="handleOk()" nzCancelText="Deny" nzOkText="Ok" nzOkType="primary"
          [nzOkDisabled]="!checkOptionsOne[0].checked">
  <form nz-form [nzLayout]="'vertical'" [formGroup]="cookiesForm">
    <br>
    <p>
      <svg viewBox="64 64 896 896" focusable="false" fill="#F26522" width="1em" height="1em" class="ng-tns-c136-61"
           data-icon="question-circle" aria-hidden="true">
        <path
          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
        <path
          d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
      </svg>
      This application uses cookies to ensure you get the best experience on our application. <u><a (click)="openPdf()">Learn
      more</a></u></p>
    <nz-checkbox-group [(ngModel)]="checkOptionsOne" formControlName="chk"
                       (ngModelChange)="Checked(checkOptionsOne)"></nz-checkbox-group>
  </form>
</nz-modal>


<nz-modal nzWidth="900px" [(nzVisible)]="isPrivacyTermsModalOpen" nzClosable="true" nzMaskClosable="false"
          (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalOk()" nzOkText="Done">
  <app-privacy-policy *ngIf="isPrivacySelected && stage_message === 'password_reset'"
                      [userName]="loginForm.getRawValue().username"></app-privacy-policy>
  <app-terms-and-conitions *ngIf="!isPrivacySelected && stage_message === 'password_reset'"
                           [userName]="loginForm.getRawValue().username"></app-terms-and-conitions>
</nz-modal>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- Sign in END -->


