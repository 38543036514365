import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import * as FileSaver from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-quick-three-sixty-report',
  templateUrl: './quick-three-sixty-report.component.html',
  styleUrls: ['./quick-three-sixty-report.component.css']
})

export class QuickThreeSixtyReportComponent implements OnInit {

  loading = false;
  total = 10;
  pageSize = 100;
  pageIndex = 0
  quickData:any;
  listOfCurrentPageData: ReadonlyArray<any> = [];
  constructor(
    private modal: NzModalService,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    private message: NzMessageService,) { }

  ngOnInit(): void {
    this.getQuickReport();
  }

  // onQueryParamsChange(params: NzTableQueryParams): void {
  //   const {pageSize, pageIndex} = params;
  //   this.loadDataFromServer(pageIndex, pageSize);
  // }

  onCurrentPageDataChange($event: ReadonlyArray<any>): void {
    this.listOfCurrentPageData = $event;
  }

  loadDataFromServer(pageIndex, pageSize){

  }

  getQuickReport(){
    this.spinner.show();
    this.loading = true;
    this.appService.getQuick360Report().subscribe(res => {
      // console.log('res',res);
      let data:any = res
      this.quickData = data.reverse();
      this.loading = false;
      this.spinner.hide();
    },err=>{
      this.loading = false;
      this.spinner.hide();
    })
  }
  isReport = false;
  downloadReport(e){
    this.spinner.show();
    this.isReport = true;
    this.appService.getQuick360ReportExcel(e.id).subscribe((res:Blob) => {
      var file = new Blob([res],{type: 'application/octet-stream'})
      FileSaver.saveAs(file,'Quick-360-Report.xlsx')
      this.isReport = false;
      // console.log('file',file);
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if(error.error?.isTrusted){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong...!'
        });
      }else{
        const errorJson = JSON.parse(this.blobToString(error.error));
        if(errorJson?.message){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorJson?.message
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Encountered an error while generating Report. Check the Data provided.'
          });
        }
      }
      this.isReport = false;
      this.spinner.hide();
    })
  }

  // Change the Blob responseType
  private blobToString(blob): string {
    const url = URL.createObjectURL(blob);
    let xmlRequest = new XMLHttpRequest();
    xmlRequest.open('GET', url, false);
    xmlRequest.send();
    URL.revokeObjectURL(url);
    return xmlRequest.responseText;
  }

  download(e:Blob){
    var file = new Blob([e],{type: 'application/octet-stream'})
    FileSaver.saveAs(file,'Quick-360-Report.xlsx')
  }
  openDetails = false;
  details;
  viewQuick(item){
    // console.log('item',item)
    this.openDetails = true;
    this.details = item;
  }

  detailsCancel(){
    this.openDetails = false;
  }
  detailsOk(){
    this.openDetails = false;
  }

  goDashboard(e){
    this.router.navigate(['/report/quick-360-dashboard', e.id]);
  }

}
