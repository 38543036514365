<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body"> 
            <div class="row mb-4">
                  <div class="col-md-1">
                    <h3> 
                      <a class="customPlusIcon" (click)="showTransactionModal()">
                        <i nz-icon class="plusIcon mt-4" nzType="plus-circle" nzTheme="fill"></i>
                      </a>
                    </h3>
                  </div>
                  <!-- Campaign Dropdown list  -->
                  <div class="col-md-11 text-center">
                    <h3 class="py-4 text-center">List Of Transactions</h3>
                      <form nz-form [nzLayout]="'vertical'" [formGroup]="campaignListForm">
                          <nz-form-item class="w-25 m-auto">
                              <nz-form-control nzErrorTip="Please select Campaign!">
                                  <nz-form-label nzFor="Campaign" [nzNoColon]="false">Select the Campaign:
                                  </nz-form-label>
                                  <nz-input-group nzCompact>
                                      <nz-select  formControlName="campaign_list" [nzSize]="'large'"
                                          (ngModelChange)="onCampaignChange($event)"
                                          nzPlaceHolder="Please select campaign">
                                          <nz-option *ngFor="let item of campaignDetails"
                                              [nzLabel]="item.campaign_name" [nzValue]="item.id">
                                          </nz-option>
                                          <nz-option nzLabel="Select Campaign" nzValue="select campaign" nzHide></nz-option>
                                           </nz-select>
                                  </nz-input-group>
                              </nz-form-control>
                          </nz-form-item>
                      </form>
                  </div>
              </div>
            <!-- {{campaignListForm.value | json}} -->
            <!-- Transaction Modal  -->
            <nz-modal [(nzVisible)]="isVisibleTransactionModal" [nzTitle]="transactionModalTitle"
              (nzOnOk)="transactionModalHandleSubmit()" (nzOnCancel)="transactionModalHandleCancel()" nzOkText="Send"
              nzOkType="primary" [nzOkLoading]="isTransactionFormLoading" 
              [nzOkDisabled]="(transactionForm.valid === true && isCampaignExpire === true ? true : false) || transactionForm.valid === false">
             
              <ng-template #transactionModalTitle>
                <!-- {{ titleForTransactionModal | titlecase}} -->
                Initiate Transaction  
              </ng-template> 
              <form nz-form [nzLayout]="'vertical'" [formGroup]="transactionForm">
                <!-- <div class="" [class.d-none]="isGiveFeedback"> -->
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please select campaign!">
                      <nz-form-label nzRequired nzFor="Campaign" [nzNoColon]="false">Select the campaign
                      </nz-form-label>
                      <nz-input-group>
                          <nz-select nzAllowClear	 nzClearIcon nzShowSearch  formControlName="campaign_id" [nzSize]="'large'"
                              (ngModelChange)="onModalCampaignChange($event)"
                              nzPlaceHolder="Please select campaign" >
                              <nz-option *ngFor="let item of campaignDetails"
                                  [nzLabel]="item.campaign_name" [nzValue]="item.id">
                              </nz-option>
                          </nz-select>
                          <span class="errorMsg" *ngIf="isCampaignExpire" >
                            Current date is out of the start & end date range of the selected campaign. Cannot create/update transaction.
                          </span>
                      </nz-input-group>
                  </nz-form-control>
              </nz-form-item> 
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Select the Campaign" nzNoColon="false">
                      Select the campaign item
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Please select campaign item!">
                      <nz-input-group nzSize="large"> 
                          <nz-select nzSize="large" nzAllowClear nzClearIcon nzShowSearch nzPlaceHolder="Select campaign item" 
                          (ngModelChange)="onCampaignItemChange($event)"
                               formControlName="campaign_item_id">
                              <nz-option *ngFor="let item of campaignItemDetails"
                              [nzLabel]="item.campaign_item_name" [nzValue]="item.id"></nz-option>
                          </nz-select>
                      </nz-input-group>
                  </nz-form-control>
              </nz-form-item>
              <nz-form-label *ngIf="selectedTechLevelLabel !== null"                                                                                                                                                                                        nzFor="Select the Campaign" nzNoColon="false">
                <!-- Select {{selectedTechLevelLabel }} -->
            </nz-form-label>
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please select transaction type!">
                    <nz-form-label nzRequired nzFor="Transaction Type" nzNoColon="false">Transaction type</nz-form-label>
                    <nz-input-group>
                      <nz-select nzAllowClear	 nzClearIcon nzShowSearch  formControlName="share_type" [nzSize]="'large'" (ngModelChange)="onTransactionTypeChange()"
                        nzPlaceHolder="Select transaction type" [nzOptions]="listOfTransactionType">
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <!-- Employee HTML -->
                <nz-form-item *ngIf="selectedTransactionType !== 'self'">
                  <nz-form-label nzRequired> {{selectedTransactionType === 'sharing with peer' ? 'Displaying' : 'Observing'}} employee</nz-form-label>
                  <nz-form-control nzErrorTip="Please select employee!">
                    <nz-input-group >
                      <nz-select [nzSize]="'large'"  nzAllowClear	 nzClearIcon nzShowSearch 
                        formControlName="employee_ids" (nzOnSearch)="onEmployeeOptionListSearch($event)" 
                        nzPlaceHolder="Select {{selectedTransactionType === 'sharing with peer' ? 'displaying' : 'observing'}} employee">
                        <ng-container *ngFor="let o of employeeOptionList">
                          <nz-option *ngIf="!isEmployeeOptionListLoading" [nzValue]="o.id" [nzLabel]="o.display_name">
                          </nz-option>
                        </ng-container>
                        <nz-option *ngIf="isEmployeeOptionListLoading" nzDisabled nzCustomContent>
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                    <!-- list: {{employeeOptionList.length > 0}} -->
                          <!-- Employee HTML  for Reporting Manager-->
                          <nz-form-item *ngIf="selectedTransactionType === 'self'">
                            <nz-form-label nzRequired>Reporting manager</nz-form-label>
                            <nz-form-control nzErrorTip="Please select employee!">
                              <nz-input-group >
                                <nz-select [nzSize]="'large'" nzAllowClear  
                                  formControlName="manager_ids" (nzOnSearch)="onEmployeeOptionListSearch($event)"
                                  nzPlaceHolder="Select your manager">
                                  <ng-container *ngFor="let o of employeeOptionList">
                                    <nz-option *ngIf="!isEmployeeOptionListLoading" [nzValue]="o.id" [nzLabel]="o.display_name">
                                    </nz-option>
                                  </ng-container>
                                  <nz-option *ngIf="isEmployeeOptionListLoading" nzDisabled nzCustomContent>
                                    <i nz-icon nzType="loading" class="loading-icon"></i>
                                    Loading Data...
                                  </nz-option>
                                </nz-select>
                              </nz-input-group>
                            </nz-form-control>
                          </nz-form-item>
                <nz-form-item *ngIf="selectedTransactionType === 'seeking from peer' || selectedTransactionType === 'self' "> 
                  <nz-form-control nzErrorTip="Please enter displayer description!">
                    <nz-form-label nzFor="Description" nzNoColon="false">Displayer (your) description</nz-form-label>
                    <nz-input-group>
                      <textarea nz-input placeholder="Write a recommendation..." formControlName="displayer_comments"
                        cols="10" rows="3" [disabled]="isGiveFeedback" ></textarea>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              <!-- </div> -->
                <nz-form-item *ngIf="selectedTransactionType === 'sharing with peer' || isGiveFeedback"> 
                  <nz-form-control nzErrorTip="Please enter observer description!">
                    <nz-form-label nzFor="Description" nzNoColon="false">Observer (your) description</nz-form-label>
                    <nz-input-group>
                      <textarea nz-input placeholder="Write a recommendation..." formControlName="observer_comments"
                        cols="10" rows="3"></textarea>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </form>
              <!-- {{transactionForm.value | json}} -->
            </nz-modal>
          <!-- <ng-container *ngIf="selectedCampaignId !== null"> -->
            <!-- <nz-table 
            nzBordered nzTableLayout="fixed" 
            [nzFrontPagination]="true" [nzLoading]="loading" [nzShowPagination]="true"
             > -->
             <nz-table 
             nzBordered
             nzShowSizeChanger
             [nzFrontPagination]="false"
             [nzLoading]="loading"
             [nzTotal]="total"
             [nzPageSize]="pageSize"
             [nzPageIndex]="pageIndex"
             (nzQueryParams)="onQueryParamsChange($event)"
             [nzData]="transactionDetails"
             
             >
             <!-- #smallTable nzSize="small" -->
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Displayer</th>
                  <th>Observer</th>
                  <th class="transactionDateCol">Date</th>
                  <th class="transactionTextCol">Transaction Text</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody> 
                <!-- {{ item.expected_end_date | date:'yyyy-MM-dd HH:mm:ss'}} -->
                <tr  *ngFor="let item of transactionDetails">
                  <td>{{item.campaign_name | titlecase}}</td>
                  <td>{{item.share_type | titlecase}}</td>
                  <td> {{item?.displayer?.display_name}}</td>
                  <td> {{item?.observer?.display_name}} </td>
                  <td class="transactionDateCol">{{item.transaction_date | date:'yyyy-MM-dd'}}</td>
                  <td class="transactionTextCol">
                   <div class="transactionText">
                    <div *ngIf="item.displayer_comments !== null; then displayerText else observerText"></div>
                    <ng-template #displayerText>
                      <span nzPopoverPlacement="topLeft" 
                      [nzPopoverOverlayStyle]="{width: '500px'}"
                       nz-popover [nzPopoverContent]="displayerPopoverContentTemplate">
                        {{item.displayer_comments}}
                      </span>
                    </ng-template>
                    <ng-template #observerText>
                      <span nzPopoverPlacement="topLeft" 
                      [nzPopoverOverlayStyle]="{'width': '500px'}" 
                       nz-popover [nzPopoverContent]="displayerPopoverContentTemplate">
                         {{item.observer_comments}} 
                      </span>
                    </ng-template> 
                    <span *ngIf="item?.displayer_comments === null && item?.observer_comments === null">
                      No comment found.
                    </span>
                    <!-- <ng-template #titleTemplate><i nz-icon nzType="close"></i> Title</ng-template> -->
                    <ng-template #displayerPopoverContentTemplate>
                      <span  *ngIf="item?.displayer_comments !== null"> <strong>{{item?.transaction_date | date:'yyyy-MM-dd HH:mm:ss'}}</strong>
                       {{ item?.share_type | titlecase  }} transaction initiated by
                       <strong>{{item?.displayer?.display_name | titlecase}}</strong>
                       <div>{{item?.displayer_comments }}</div> </span> 
                       <hr *ngIf="item?.displayer_comments !== null && item?.observer_comments !== null">
                      <span *ngIf="item?.observer_comments !== null">  
                        <strong>{{item?.transaction_date | date:'yyyy-MM-dd HH:mm:ss'}} </strong> 
                       <strong>{{item?.observer?.display_name | titlecase}}</strong> 
                      gave recommendation <div>{{item?.observer_comments}}</div></span>
                    </ng-template> 
                  </div>
                   <!-- <div class="transactionText">  
                     <span *ngIf="item.observer_comments" nz-popover  nzPopoverPlacement="topLeft" [nzPopoverOverlayStyle]="{width: '500px'}" [nzPopoverContent]="observerPopoverContentTemplate">
                      {{item.observer_comments | titlecase}}
                    </span>
                    <ng-template #observerPopoverContentTemplate>
                      <strong>{{item?.transaction_date | date:'yyyy-MM-dd HH:mm:ss'}}</strong> 
                       {{item?.observer?.display_name | titlecase}} 
                      gave recommendation <div>{{item?.observer_comments | titlecase}}</div>
                   </ng-template>
                    </div>  -->
                  </td>
                  <td>{{item.status | titlecase}}</td>
                  <td>
                    <!-- (click)="changeStatus(item)" -->
                    <nz-button-group class="mb-2"  
                    *ngIf="(item?.share_type !== 'sharing with peer' && item.status === 'initiated' && loggedInUserDetail?.employee?.id ===item?.displayer_emp_id) || 
                    (item?.share_type === 'sharing with peer' && item.status === 'pending acceptance' && loggedInUserDetail?.employee?.id ===item?.observer_emp_id)">
                    <button (click)="editTransactionModal(item)" nz-button nzType="primary" class="1m-1">Edit</button>
                    <button (click)="remove(item)" nz-button nzType="default" class="1m-1">Delete</button>
                    </nz-button-group>
                    <nz-button-group class="mb-2" 
                    *ngIf="item?.share_type !== 'sharing with peer' && item?.observer_emp_id === loggedInUserDetail?.employee?.id && 
                    item?.status === 'initiated'"> 
                    <button (click)="giveFeedback(item)" nz-button nzType="primary" class="1m-1">Give</button>
                    <button (click)="updateTransactionStatus('deny', item)" nz-button nzType="default" class="1m-1">Deny</button>
                    </nz-button-group>
                    <nz-button-group class="mb-2" *ngIf="item?.displayer_emp_id === loggedInUserDetail?.employee?.id && 
                    item?.status === 'pending acceptance'">
                    <button (click)="updateTransactionStatus('accept', item)" nz-button nzType="primary" class="1m-1">Accept</button>
                    <button (click)="updateTransactionStatus('reject', item)" nz-button nzType="default" class="1m-1">Reject</button>
                    </nz-button-group>
                  </td>
                  <!-- nzSize="small" -->
                  <!-- <td>Peer to Peer</td>
                  <td>Harish Patel</td>
                  <td>Mayank Vora</td>
                  <td>20-09-2020</td>
                  <td>Well Done.. Looks Good! More</td>
                  <td>Pending Acceptance</td>
                  <td>
                    <button nz-button nzType="primary" class="mr-1 mb-1">Edit</button>
                    <button nz-button nzType="default" class="">Delete</button>
                  </td> -->
                </tr>
                <!-- <tr>
                  <td>Peer to Peer</td>
                  <td>Nirav Gosalia</td>
                  <td>Mayank Vora</td>
                  <td>18-09-2020</td>
                  <td>Excellent!</td>
                  <td>Pending Recommendation</td>
                  <td>
                    <button nz-button nzType="primary" class="mr-1 mb-1">Give</button>
                    <button nz-button nzType="default" class="">Deny</button>
                  </td>
                </tr> 
                <tr>
                  <td>Peer to Peer</td>
                  <td>Nirav Gosalia</td>
                  <td>Mayank Vora</td>
                  <td>20-09-2020</td>
                  <td>Excellent!</td>
                  <td>Pending Recommendation</td>
                  <td>
                    <button nz-button nzType="primary" class="mr-1 mb-1">Accept</button>
                    <button nz-button nzType="default" class="">Reject</button>
                  </td>
                </tr>-->
              </tbody>
            </nz-table>
          <!-- </ng-container> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>