import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import  differenceInCalendarDays  from 'date-fns/differenceInCalendarDays';
import { ProjectRetrieveRequest } from './../../../app.models';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { NzModalService } from 'ng-zorro-antd/modal';
import { AppService } from 'src/app/app.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import getISOWeek from 'date-fns/getISOWeek';
interface Person {
  key: string;
  project: string;
  startDate: string;
  expectedDate: string;
  actualDate: string;
  completed: string;
  status:string
}
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  isVisible = false;
  isVisibleEditModal= false;
  i= 0;
  editId: string | null = null;
  expandSet = new Set<number>();
  isConfirmLoading: boolean= false;
  isFormLoading: boolean= false;
  public projectDetails;
  public project;
  projectId: number;
  projectForm: FormGroup;
  selectedProjectEmpId: any;
  listOfData: Person[] = [
    {
      key: '1',
      project: 'Proposal of Code',
      startDate: '01-02-2020',
      expectedDate: '01-02-2021',
      actualDate: '06-02-2020',
      completed: 'Yes',
      status: 'Approved'
    },
    {
      key: '2',
      project: 'Proposal of Code',
      startDate: '01-02-2020',
      expectedDate: '01-02-2021',
      actualDate: '06-02-2020',
      completed: 'No',
      status: 'Rejected'
    },
    {
      key: '3',
      project: 'Proposal of Code',
      startDate: '01-02-2020',
      expectedDate: '01-02-2021',
      actualDate: '06-02-2020',
      completed: 'Yes',
      status: 'Approved'
    }
  ];
  date:null;
  dateFormat = 'dd/MM/yyyy';
  loggedInUserDetail: any;
  action = null;
  isManager:string= 'no';
  // dropdown list
  listOfNoOfOption = [
    { label: 'Approved', value: 1 },
    { label: 'Rejected', value: 2 },
  ];
  listOfQuestionTypeOption = [
    { label: 'MCQ', value: 'MCQ' },
    { label: 'Subjective', value: 'Subjective' }
  ];
  constructor(public fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService,
    private datepipe: DatePipe) { }
  onChange(result: Date[]): void {
    // console.log('onChange: ', result);
  }

  getWeek(result: Date[]): void {
    // console.log('week: ', result.map(getISOWeek));
  }
  //date picker
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;

  disabledStartDate = (current: Date): boolean => {
    // if (!current || !this.assessmentForm.get("end_date").value) {
    //   return false;
    // }
    // return start_date.getTime() > (new Date(this.assessmentForm.get("end_date").value)).getTime();
    return differenceInCalendarDays(current, new Date()) < 0;
  };

  disabledEndDate = (end_date: Date): boolean => {
    if (!end_date || !this.projectForm.get("start_date").value) {
      return false;
    }
    return end_date.getTime() <= (new Date(this.projectForm.get("start_date").value)).getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
    // console.log('handleStartOpenChange', open);
  }

  handleEndOpenChange(open: boolean): void {
    // console.log('handleEndOpenChange', open);
  }
  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    this.getProjectDetails();
    this.resetCreateModal();
  }

// modal action
showModal(): void {
  this.projectForm.reset();
  this.action = 'create';
  this.isVisible = true;
}
showEditModal(item: any): void {
  this.projectForm.reset();
  this.projectId = item.id;
  this.action = 'edit';
  this.isVisible = true;
  this.isManager= item.employee.is_manager;
  this.selectedProjectEmpId =item.emp_id;
  this.appService.getProjectsById(this.projectId).subscribe(res =>{
   this.project = res;
  this.projectForm.patchValue(this.project);
   })
}
handleModalCancel(): void {
  this.isVisible = false;
}
// Edit modal
showMangerEditModal(item: any) {
  this.projectForm.reset();
  this.projectId = item.id;
this.isVisibleEditModal = true;
this.action = 'editStatus';
}
editModalHandleCancel(){
  this.isVisibleEditModal = false;
}
// editModalHandleSubmit(){
//   this.isVisibleEditModal = false;
// }
// Get Project List for table
getProjectDetails(){
let rr = new ProjectRetrieveRequest();
rr.emp_ids= [this.loggedInUserDetail.employee.id];
rr.load_subordinate = 'yes';
// rr.reporting_manager = [this.loggedInUserDetail.employee.reporting_manager];
this.appService.getProjects(rr).subscribe(res =>{
this.projectDetails = res['items'];
// console.log('projectDetails', this.projectDetails);
})
}
getProjectDetailsById(id){
  this.appService.getProjectsById(id).subscribe(res =>{
  this.project = res['items'];
  // console.log('project', this.project);
  })
  }
submitForm(): void {
  for (const i in this.projectForm.controls) {
    this.projectForm.controls[i].markAsDirty();
    this.projectForm.controls[i].updateValueAndValidity();
  }
}
resetCreateModal(): void {
  this.projectForm = this.fb.group({
    // emp_id
    project_name: [null],
    start_date:[null],
    expected_end_date:[null],
    actual_end_date:[null],
    approval_description:[null],
    is_complete:[null],
    status: [null],
  });
}
handleModalSubmit(): void {
  this.submitForm();
  this.isFormLoading = true;
  // Create the business unit on the server.
  if (this.projectForm.status === 'VALID') {

    let startDate = this.datepipe.transform(this.projectForm.value.start_date, "yyyy-MM-dd  HH:mm:ss");
    let expectedEndDate = this.datepipe.transform(this.projectForm.value.expected_end_date, 'yyyy-MM-dd HH:mm:ss');
    let status;
    if (this.projectForm.value.status === 1)
      status= 'Approved'
     else if (this.projectForm.value.status === 2)
      status= 'Rejected'
      else status= 'Approval Pending'
    let subscriber = null;
    let message = null;

    if (this.action === "create") {
      let data = {
        "emp_id": this.loggedInUserDetail.employee.id,
        "project_name": this.projectForm.value.project_name,
        "start_date":startDate,
        "expected_end_date": expectedEndDate,
      }
      subscriber = this.appService.createProject(data);
      message = 'Project "' + this.projectForm.controls['project_name'].value + '" has been added.';
    }
    else if (this.action === 'edit') {
        let data = {
          "emp_id": this.selectedProjectEmpId,
          "project_name": this.projectForm.value.project_name,
          "start_date":startDate,
          "expected_end_date": expectedEndDate,
        }
        subscriber = this.appService.updateProject(data, this.projectId);
        message = 'Project "' + this.projectForm.controls['project_name'].value + '" has been updated.';
      } else{
        let data = { "status": status,}
        subscriber = this.appService.updateProject(data, this.projectId);
        message = 'Project  status has been updated.';
      }
      // message = 'Project "' + this.projectForm.controls['project_name'].value + '" has been updated.';


    subscriber.subscribe(res => {
      this.isVisible = false;
      this.isVisibleEditModal = false;
      this.isFormLoading = false;

      this.message.create('success', message, {
        nzDuration: 5000
      });

      // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);
      this.getProjectDetails();
    }, error => {
      this.isVisible = false;
      this.isVisibleEditModal = false;
      this.isFormLoading = false;

      let errorMessage = error.error.message;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }
 }
public remove(id) {
  this.modal.confirm({
    nzTitle: 'Confirm Delete',
    nzContent: 'Are you sure, you want to delete?',
    nzOnOk: () =>
      new Promise((resolve, reject) => {
        let subscriber = null;

        subscriber = this.appService.deleteProject(id);
        subscriber.subscribe(res => {

          this.message.create('success', 'Record Deleted', {
            nzDuration: 5000
          });
           this.getProjectDetails();
          resolve(res);
        }, error => {

          let errorMessage = error.error.message;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });

          reject();
        })

      }).catch(() => console.log('Oops errors!'))
  });
}


}



