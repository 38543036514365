<div style="padding-left: 400px;padding-top:100px;" id="contentToConvert">

  <h3>EMPLOYEE DETAILS</h3>
  <br>
  <br>
  <br>

  <table class="styled-table">
    <tbody>
    <tr>
      <th>Employee ID</th>
      <td>{{empData?.employee_id}}</td>

    </tr>
    <tr>
      <th>Name</th>
      <td>{{empData?.display_name}}</td>
    </tr>
    <tr>
      <th>Designation</th>
      <td>{{empData?.designation}}</td>
    </tr>
    <tr>
      <th>DOB</th>
      <td>{{empData?.dob}}</td>
    </tr>
    <tr>
      <th>Department</th>
      <td>{{empData?.department?.name}}</td>
    </tr>
    <tr>
      <th>About Me</th>
      <td>{{empData?.user?.about_me}}</td>
    </tr>
    </tbody>
  </table>

  <br>
  <br>
  <h3>ASSESSMENTS HISTORY</h3>
  <table *ngFor='let assessment of assessments' class="table table-striped">

    <tr></tr>
    <tr>
      <th rowspan="15" style="width:15%;">{{assessment?.title}}</th>
    </tr>

    <tr>
      <th>ASSESSMENT TYPE</th>
      <td class="cap">{{assessment?.assessment_type}}</td>
    </tr>

    <tr>
      <th>START DATE</th>
      <td>{{assessment?.start_date}}</td>

    </tr>
    <tr>
      <th>END DATE</th>
      <td>{{assessment?.end_date}}</td>

    </tr>
    <tr>
      <th>ACHIEVED SCORE</th>
      <td>
        <div *ngFor="let recipient of totalScoreMap | keyvalue">
          <div *ngIf="recipient.key==assessment?.id; else elseBlock">{{recipient.value}}</div>
          <!-- <ng-template #elseBlock>0  out of {{attemps?.assessment.total_score}}</ng-template> -->
        </div>


      </td>

    </tr>
    <tr>
      <th>MAXIMUM SCORE</th>
      <td>
        {{assessment?.total_score}}

      </td>
    </tr>

    <tr>
      <th>ASSESSMENT ACTIVITY DETAILS</th>


      <div *ngFor="let activity of activity">

        <div *ngIf="activity?.assessment_id==assessment?.id">

          <table class="inner">
            <tr>
              <th>ACTIVITY TITLE</th>
              <td class="cap">{{activity?.activity_title}}</td>
            </tr>
            <tr>
              <th>ACTIVITY TYPE</th>
              <td class="cap">{{activity?.activity_type}}</td>
            </tr>
          </table>

          <div *ngFor="let assessmentquestiondata of assessmentQuestionData">

            <div *ngIf="activity?.id==assessmentquestiondata?.assessment_activity_id">
              <div *ngFor="let competency of competenciesData">
                <div *ngFor="let group of questionGroup">
                  <div
                    *ngIf="competency?.id==group?.question_competency_id && group.question_id==assessmentquestiondata?.id">

                    <table class="inner">
                      <tr>
                        <th>COMPETENCIES</th>
                        <td>{{competency?.competency_name}}</td>
                        <th>ACHIEVED SCORE</th>
                        <td>{{group?.score}}</td>
                        <th>MAXIMUM SCORE</th>
                        <td>{{group?.marks_to_earn}}</td>
                      </tr>


                    </table>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngFor="let activityfeedbackquestion of activityFeedbackQuestion">

            <div *ngIf="activity?.id==activityfeedbackquestion?.assessment_activity_id">

              <div *ngFor="let competency1 of competenciesFeedbackData">
                <div *ngFor="let fgroup of feedbackGroup">
                  <div
                    *ngIf="competency1?.id==fgroup?.question_competency_id &&fgroup?.question_id== activityfeedbackquestion?.id">

                    <table class="inner">
                      <tr>
                        <th>COMPETENCIES</th>
                        <td>{{competency1?.competency_name}}</td>
                        <th>ACHIEVED SCORE</th>
                        <td>{{fgroup?.achieved_score}}</td>
                        <th>MAXIMUM SCORE</th>
                        <td>{{fgroup?.max_score}}</td>
                      </tr>
                    </table>


                  </div>
                </div>

              </div>
            </div>
          </div>
          <div *ngFor="let psychometric of psychometricAssessment">

            <div *ngIf="activity?.id==psychometric?.assessment_activity_id">
              <table class="inner">
                <tr>
                  <th>COMPETENCIES NAME</th>
                  <td>{{psychometric?.competency.competency_name}}</td>
                  <th>ACHIEVED SCORE</th>
                  <td>{{psychometric?.competency_score}} </td>
                  <th>MAXIMUM SCORE</th>
                  <td>{{psychometric?.maximum_score}}</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Total Score {{variable.total_score}} out of {{variable.out_of_score}} -->
          <!-- Total Competencies Score-{{final_total_score}} out of {{out_of_score}} -->
          <!-- ASSESSmetn activity id veh=chuu match cheamm -->
          <div *ngFor="let total of totalOfQuestionGroup">
            <div *ngIf="activity?.id==total?.assessment_activity_id">
              <table class="inner">
                <tr>
                  <th>
                    COMPETENCIES TOTAL
                  </th>
                  <th>ACHIEVED SCORE</th>
                  <td>{{total?.score}}</td>
                  <th>MAXIMUM SCORE</th>
                  <td>{{total?.max_score}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div *ngFor="let totalf of totalOfFeedbackGroup">
            <div *ngIf="activity?.id==totalf?.assessment_activity_id">
              <table class="inner">
                <tr>
                  <th>
                    COMPETENCIES TOTAL
                  </th>
                  <th>ACHIEVED SCORE</th>
                  <td>{{totalf?.score}}</td>
                  <th>MAXIMUM SCORE</th>
                  <td>{{totalf?.max_score}}</td>
                </tr>
              </table>

            </div>
          </div>
          <div *ngFor="let totalp of totalOfPsychometric">
            <div *ngIf="activity?.id==totalp?.assessment_activity_id">
              <table class="inner">
                <tr>
                  <th>
                    COMPETENCIES TOTAL
                  </th>
                  <th>ACHIEVED SCORE</th>
                  <td>{{totalp?.score}}</td>
                  <th>MAXIMUM SCORE</th>
                  <td>{{totalp?.max_score}}</td>
                </tr>
              </table>

            </div>
          </div>

          <div *ngFor="let activityAttempt of assessmentActivityAttempt">
            <div *ngIf=" activityAttempt?.assessment_activity_id==activity?.id">
              <table class="inner">
                <tr>
                  <th>STATUS</th>
                  <td class="c">{{activityAttempt?.status}}</td>
                </tr>
                <tr>
                  <th>TIME TAKEN</th>
                  <td>{{ activityAttempt?.time_consumed}}</td>
                </tr>
                <tr>
                  <th>RANK</th>
                  <div *ngIf="activityAttempt?.rank!=null; else elseBlock">
                    <td>{{ activityAttempt?.rank}}</td>
                  </div>
                  <ng-template #elseBlock>
                    <td>N/A</td>
                  </ng-template>

                </tr>
              </table>
            </div>

          </div>

        </div>


      </div>
    </tr>

  </table>

  <br><br><br>
</div>
<div style="justify-content: center;padding-left: 400px;">
  <!-- <button type="button"  (click)="generatePDF()">Export to PDF</button> -->
  <button (click)="openPDF()">Export To Pdf</button>
  <!-- <button (click)="downloadPDF()">
      generatePDF
    </button> -->
</div>

