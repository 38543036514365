<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <h3 class="py-4 text-center">Behaviour Transactions</h3>
                        <h3 class="plusIcon"> <i nz-icon class="mb-4" nzType="plus-circle"
                                nzTheme="fill"></i></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
