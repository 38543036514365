<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">{{ submissions.length ? submissions[0].form.name : '' }}</h3>

            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div></div>
            </div>

            <nz-modal [(nzVisible)]="isVisible" nzTitle="Submission" (nzOnCancel)="onReject()" nzCancelText="Reject" (nzOnOk)="onApprove()" nzOkText="Approve">
              <ng-container *nzModalContent>
                <nz-table #submissionTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="submission" [nzScroll]="{ x: '300px' }">
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of submission.content | keyvalue">
                      <td>{{ data.key }}</td>
                      <td>{{ data.value }}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </ng-container>
            </nz-modal>

            <nz-table #submissionsTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total" [nzPageSize]="limit" [nzPageIndex]="page" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="submissions" [nzScroll]="{ x: '300px' }">
              <thead>
                <tr>
                  <th nzLeft>Name</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Form Status</th>
                  <th nzRight class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let record of submissionsTable.data;">
                  <td>{{ record.employee.first_name }} {{ record.employee.last_name }}</td>
                  <td>{{ record.employee.designation }}</td>
                  <td>{{ record.employee.department.name }}</td>
                  <td>{{ record.status.toUpperCase() }}</td>
                  <td class="text-center">
                    <a (click)="onOpenEntry(record)" title="View Entry">
                      <i class="ri-eye-fill grayIcon"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
