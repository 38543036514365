<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="row">
              <div class="col-sm-12">
                <a class="back-btn" nz-button nzType="link" (click)="back()">
                  <i class="ri-arrow-go-back-fill"></i>
                </a>
              </div>
            </div>
            <div class="stepwizard mb-0">
              <div class="stepwizard-row setup-panel text-center">
                <div class="wizard-step tab-second" [ngClass]="{'active' : contentIndex == 0}">
                  <a class="btn btn-default" (click)="tabChange(0)">
                    <i class="ri-draft-line text-warning"></i><span>Talent Card</span>
                  </a>
                </div>
                <!-- <div class="wizard-step tab-second" [ngClass]="{'active' : contentIndex == 1}">
                  <a class="btn btn-default" (click)="tabChange(1)">
                    <i class="ri-book-read-line"></i><span>Talent Card List</span>
                  </a>
                </div> -->
              </div>
            </div>
            <!-- Form Content-->
            <div class="setup-content tab-first" *ngIf="contentIndex == 0">
              <form class="row talent-form mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="talentCardForm">
                <div class="col-md-6">
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="name">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Name!">
                      <input nz-input formControlName="name" nzSize="large" placeholder="Enter Name" id="name"
                             OnlyCharacter readonly />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="DOJ">DOJ</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Legal Entity!">
                      <nz-date-picker nzSize="large" formControlName="doj" nzFormat="yyyy-MM-dd"
                                      [nzDisabledDate]="disabledDate" readonly></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Department/Function">Department/Function</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Department/Function!">
                      <input nz-input formControlName="department" nzSize="large"
                             placeholder="Enter Department/Function" id="department" OnlyCharacter />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Reviewer">Reviewer</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Reviewer!">
                      <input nz-input formControlName="reviewer" nzSize="large" placeholder="Enter Reviewer"
                             id="reviewer" OnlyCharacter />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Professional Qualification">Professional Qualification
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Enter Professional Qualification!">
                      <input nz-input formControlName="professional_qualification" nzSize="large"
                             placeholder="Enter Professional Qualification" id="professional_qualification" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div class="col-md-6">
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="name">Employee Code</nz-form-label>
                    <nz-form-control nzErrorTip="Please Employee Code!">
                      <input nz-input formControlName="employee_code" nzSize="large" placeholder="Employee Code"
                             id="employee_code" readonly>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Grade">Grade</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Grade!">
                      <input nz-input formControlName="grade" nzSize="large" placeholder="Enter Grade" id="grade"
                             readonly />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label  nzFor="Functional Manager">Functional Manager</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Functional Manager!">
                      <input nz-input formControlName="functional_manager" nzSize="large"
                             placeholder="Enter Functional Manager" id="functional_manager" OnlyCharacter />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Education Qualification">Education Qualification</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Education Qualification!">
                      <input nz-input formControlName="education_qualification" nzSize="large"
                             placeholder="Enter Education Qualification" id="education_qualification" />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Mobility">Mobility</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Mobility">
                      <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Enter Mobility"
                                 formControlName="mobility">
                        <nz-option *ngFor="let item of mobilityList" [nzLabel]="item.name" [nzValue]="item.name">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <!-- <nz-form-item *ngIf="talentCardForm.get('mobility')?.value == 'Yes'">
                    <nz-form-label nzRequired nzFor="geography">Geography</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Geography">
                      <input nz-input id="geography" placeholder="Enter Geography" nzSize="large"
                        formControlName="geography">
                    </nz-form-control>
                  </nz-form-item> -->
                </div>
                <div class="col-lg-12" style="margin-left: -10px;">
                  <div class="row mb-1 mt-3">
                    <div class="col-md-6">
                      <h5>Previous Promotion Details (Only Lupin)</h5>
                    </div>
                    <div class="col-md-6  text-right">
                      <label nz-checkbox formControlName="promotionNAchecked"
                             (ngModelChange)="promotionNA($event)">N/A</label>
                    </div>
                  </div>
                </div>


                <div class="col-lg-12 row PromotionD" formArrayName="previous_promotion">
                  <div class="col-lg-4">
                    <!-- <nz-form-label nzRequired nzFor="Year/Month">Year/Month</nz-form-label> -->
                    <nz-form-label nzRequired nzFor="Year/Month">From Date</nz-form-label>
                  </div>
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Grade From">Grade From</nz-form-label>
                  </div>
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Grade To">Grade To</nz-form-label>
                  </div>
                  <ng-container *ngFor="let blevel of talentCardForm.controls.previous_promotion.controls; let k=index"
                                [formGroupName]="k">
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Year/Month!">
                          <!-- <nz-date-picker nzMode="month" nzSize="large" [nzDisabledDate]="disabledDate"
                            formControlName="month/year"></nz-date-picker> -->
                          <nz-date-picker  nzSize="large" [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd"
                                           formControlName="month/year"></nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Grade From!">
                          <input nz-input id="from" nzSize="large" formControlName="from" placeholder="Grade From" />

                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Grade To!">
                          <input nz-input id="to" nzSize="large" formControlName="to" placeholder="Grade To" />
                          <!-- && isSubmit == 'no' -->
                          <a *ngIf="talentCardForm.controls.previous_promotion?.length > 1 "
                             title="Delete" class="mr-2  delete-icon"><i class="ri-delete-bin-6-fill"
                                                                         (click)="removeBLevel(k, blevel['controls']['id'])"></i></a>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </ng-container>
                  <!-- isSubmit == 'no'&&  -->
                  <div class="col-lg-12 new-add text-right" *ngIf="!promotionNotAvailable">
                    <a title="Add" class="add-btn ml-3" (click)="addNewBLevel()"><i class="ri-add-circle-fill"></i></a>
                  </div>
                  <!-- </div> -->
                </div>


                <div class="col-lg-12" style="margin-left: -10px;">
                  <div class="row mb-1 mt-3">
                    <div class="col-md-6">
                      <h5>Previous Experience Details</h5>
                    </div>
                    <div class="col-md-6 text-right">
                      <label nz-checkbox formControlName="experienceNAchecked"
                             (ngModelChange)="experienceNA($event)">N/A</label>
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 row PromotionD" formArrayName="previous_experience">
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Company Name">Company Name</nz-form-label>
                  </div>
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Role">Role</nz-form-label>
                  </div>
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Date (From – To)">Date (From – To)</nz-form-label>
                  </div>
                  <ng-container *ngFor="let ex of talentCardForm.controls.previous_experience.controls; let j=index"
                                [formGroupName]="j">
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Company Name!">
                          <input nz-input id="company_name" placeholder="Enter Company Name" nzSize="large"
                                 formControlName="company_name" placeholder="Company Name" />

                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Role!">
                          <input nz-input id="role" placeholder="Enter Role" nzSize="large" formControlName="role"
                                 placeholder="Role" />

                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-4 previous">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Date (From – To)!">
                          <nz-range-picker nzSize="large" nzFormat="yyyy-MM-dd" [nzDisabledDate]="disabledDate"
                                           formControlName="from_to_date"></nz-range-picker>
                                           <!-- && isSubmit == 'no' -->
                          <a *ngIf="talentCardForm.controls.previous_experience.controls?.length > 1 "
                             title="Delete" class="mr-2  delete-icon"><i class="ri-delete-bin-6-fill"
                                                                         (click)="removeExperience(j, ex['controls']['id'])"></i></a>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </ng-container>
                  <!-- isSubmit == 'no'&&  -->
                  <div class="col-lg-12  new-add text-right" *ngIf="!experienceNotAvailable">
                    <a title="Add" class="add-btn ml-3" (click)="addNewExperience()"><i
                      class="ri-add-circle-fill"></i></a>
                  </div>
                </div>


                <div class="row mb-1 mt-3">
                  <h5>Lupin Experience Details</h5>

                </div>
                <div class="col-lg-12 row PromotionD" formArrayName="company_experience">
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Role">Role</nz-form-label>
                  </div>
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="Start Date">Start Date</nz-form-label>
                  </div>
                  <div class="col-lg-4">
                    <nz-form-label nzRequired nzFor="End Date">End Date</nz-form-label>
                  </div>
                  <ng-container *ngFor="let lupin of talentCardForm.controls.company_experience.controls; let l=index"
                                [formGroupName]="l">

                    <div class="col-lg-12" *ngIf="currentRoleSelectedIndex==null ||lupin.controls.isCurrent.value"
                         style="margin-bottom: 10px;"> <label nzSize="large" (ngModelChange)="onCheckboxChanged($event,l)"
                                                              nz-checkbox formControlName="isCurrent">I am currently working in this role </label></div>
                    <div class="col-lg-4">

                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Role!">
                          <input nz-input id="role" placeholder="Enter Role" nzSize="large" formControlName="lupin_role"
                                 placeholder="Role" />
                        </nz-form-control>
                      </nz-form-item>

                    </div>
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Start Date">
                          <nz-date-picker nzSize="large" [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd" formControlName="from">
                          </nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-4">
                      <nz-form-item>
                        <nz-form-control *ngIf="!lupin.controls.isCurrent.value" nzErrorTip="Please Enter End date">
                          <nz-date-picker nzSize="large" [nzDisabledDate]="disabledDate" formControlName="to" nzFormat="yyyy-MM-dd">
                          </nz-date-picker>
                        </nz-form-control>
                        <div *ngIf="lupin.controls.isCurrent.value" style="text-align: center;"> Present</div>
                        <a *ngIf="talentCardForm.controls.company_experience.controls?.length > 1 && isSubmit == 'no'"
                           title="Delete" class="mr-2  delete-icon"><i class="ri-delete-bin-6-fill"
                                                                       (click)="removeLupin(l, lupin['controls']['id'],lupin.controls.isCurrent.value)"></i></a>
                      </nz-form-item>
                    </div>

                    <!-- <div class="col-lg-7">
                    <nz-form-item>
                      <nz-form-control *ngIf="!lupin.controls.isCurrent.value"   nzErrorTip="Please Enter Date (From – To)!">
                        <nz-range-picker nzSize="large" nzFormat="yyyy-MM-dd" formControlName="lupin_from_to_date"
                          [nzDisabledDate]="disabledDate"></nz-range-picker>
                        <a *ngIf="talentCardForm.controls.company_experience.controls?.length > 1 && isSubmit == 'no'"
                          title="Delete" class="mr-2  delete-icon"><i class="ri-delete-bin-6-fill"
                            (click)="removeLupin(l, lupin['controls']['id'])"></i></a>
                      </nz-form-control>
                    </nz-form-item>
                  </div> -->

                  </ng-container>
                  <div class="col-lg-12  new-add text-right" *ngIf="isSubmit == 'no'">
                    <a title="Add" class="add-btn ml-3" (click)="addNewLupin()"><i class="ri-add-circle-fill"></i></a>
                  </div>
                </div>
                <!-- <div class="col-lg-12 row PromotionD" formArrayName="company_experience">
                  <div class="col-lg-5">
                    <nz-form-label nzRequired nzFor="Role">Role</nz-form-label>
                  </div>
                  <div class="col-lg-7">
                    <nz-form-label nzRequired nzFor="Date (From – To)">Date (From – To)</nz-form-label>
                  </div>
                  <ng-container *ngFor="let lupin of talentCardForm.controls.company_experience.controls; let l=index"
                    [formGroupName]="l">
                    <div class="col-lg-5">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Role!">
                          <input nz-input id="role" placeholder="Enter Role" nzSize="large" formControlName="lupin_role"
                            placeholder="Role" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-7">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please Enter Date (From – To)!">
                          <nz-range-picker nzSize="large" nzFormat="yyyy-MM-dd" formControlName="lupin_from_to_date"
                            [nzDisabledDate]="disabledDate"></nz-range-picker>
                          <a *ngIf="talentCardForm.controls.company_experience.controls?.length > 1 && isSubmit == 'no'"
                            title="Delete" class="mr-2  delete-icon"><i class="ri-delete-bin-6-fill"
                              (click)="removeLupin(l, lupin['controls']['id'])"></i></a>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </ng-container>
                  <div class="col-lg-12  new-add text-right" *ngIf="isSubmit == 'no'">
                    <a title="Add" class="add-btn ml-3" (click)="addNewLupin()"><i class="ri-add-circle-fill"></i></a>
                  </div>
                </div> -->

                <div class="col-md-12 text-center">
                  <!-- <button nz-button nzSize="large" nzType="default" [nzLoading]="resetLoader" (click)="talentCardFormRest()">Reset</button> -->
                  <!-- [disabled]="isSubmit == 'yes'" -->
                  <button nz-button nzSize="large" nzType="primary" 
                          [nzLoading]="isFormLoading" class="ml-3 my-3" (click)="submittalentCardForm('save')">
                    Save
                  </button>
                  <!-- [disabled]="isSubmit == 'yes'" -->
                  <button nz-button nzSize="large" nzType="primary" 
                          [nzLoading]="isFormLoading1" class="ml-3 my-3" (click)="submittalentCardForm('submit')">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Please Wait... </p>
</ngx-spinner>
