import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmployeeInitiativesGroupsRetrieveRequest } from 'src/app/app.models';
// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking

interface Person {
  key: string;
  name: string;
  role: string;
  status: string;
  objectivesFor: string;
  description: string;
}
@Component({
  selector: 'app-setup-initiatives',
  templateUrl: './setup-initiatives.component.html',
  styleUrls: ['./setup-initiatives.component.css']
})
export class SetupInitiativesComponent implements OnInit {
  // calendarOptions: CalendarOptions = {
  //   initialView: 'dayGridMonth'
  // };
  public loggedInUserDetail: any;
  isVisible = false;  
  i= 0;
  editId: string | null = null;
  expandSet = new Set<number>(); 
  dateFormat = 'dd/MM/yyyy';
  current = 0;
  contentIndex = 0;
  listOfNoOfOption = [
    { label: 'For 1 Apri-2020', value: 1 },
    { label: 'For 1 May-2020', value: 2 },
    { label: 'For 1 June-2020', value: 3 },
    { label: 'For 1 July-2020', value: 4 },
  ]; 
  listOfQuestionTypeOption = [
    { label: 'MCQ', value: 'MCQ' },
    { label: 'Subjective', value: 'Subjective' }
  ];
  listOfMonths = [
    { label: 'January', value: 'January' },
    { label: 'February', value: 'February' },
    { label: 'March', value: 'March' },
    { label: 'April', value: 'April' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'June' },
    { label: 'July', value: 'July' },
    { label: 'August', value: 'August' },
    { label: 'September', value: 'September' },
    { label: 'October', value: 'October' },
    { label: 'November', value: 'November' },
    { label: 'December', value: 'December' },
  ]
  listOfYears = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
  ]
  listOfCompetenciesOption: Array<{ value: string; label: string }> = [];
  listOfSelectedCompetenciesValue = [];
  noOfActivity = [];
  setupCaseStudyForm!: FormGroup;
  participantsForm!: FormGroup;
  feedbackForm!: FormGroup;
  dateForm!: FormGroup;
  selectedMonth:string;
  selectedYear: string;
  employeeInitiativeGroupId: number;
  constructor(public fb: FormBuilder,
    public appService: AppService,
    public router: Router,
    ) { }

  ngOnInit(): void {
       //get user details
       this.loggedInUserDetail = this.appService.getMe();
      //  console.log(this.loggedInUserDetail);
    this.dateForm = this.fb.group({
      month:[null, [Validators.required]],
      year:[null, [Validators.required]],
    })
    this.setupCaseStudyForm = this.fb.group({
      idealActiveTime: [null, [Validators.required]],
      caseStudyComment: [null, [Validators.required]],
      title: [null, [Validators.required]],
      listOfSelectedCompetenciesValue: [null, Validators.compose([Validators.required])],
      startDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
      participate:[null],
      assessors_id:[null],
    });
    this.participantsForm = this.fb.group({
      questionTypeSelectedValue: [null],
      question: [null],
      choice1:[null],
      isValid:[null],
    });
    this.feedbackForm = this.fb.group({
      questionTypeSelectedValue: [null],
      question: [null],
      choice1:[null],
      isValid:[null],
    });
  
  }
 
  createFormGroup = (): FormGroup => {
    const formBuilder = new FormBuilder();
    return formBuilder.group({
      listOfSelectedActivityValue: [
        null,
        Validators.compose([Validators.required])
      ]
    });
  }

  activitySubmitForm(e) {
    // console.log(e);
  } 
 
// modal action fuctions
showModal(): void {
  this.isVisible = true;
} 
handleOk(): void { 
  this.isVisible = false;
} 
handleCancel(): void { 
  this.isVisible = false;
}
submitDateForm(){
  if (this.dateForm.valid){
    this.selectedMonth = this.dateForm.get('month').value;
    this.selectedYear = this.dateForm.get('year').value; 
    // console.log(this.selectedYear, this.selectedMonth);
    // this.router.nagvigate(['dd'], queryParams:{ddd: ''});
    this.router.navigate(['/maximizer-dashboard/detail-initiatives/'], { queryParams: { sm: this.selectedMonth, sy:this.selectedYear } });
    // '/maximizer-dashboard/detail-initiatives'
    // this.getEmpInitiativeGroupsByDate();  
  } 
}

// getEmpInitiativeGroupsByDate(){
  // let rr = new EmployeeInitiativesGroupsRetrieveRequest();
  // rr.emp_id = this.loggedInUserDetail.employee.id;
  // rr.month = this.selectedMonth;
  // rr.year = this.selectedYear;
  // rr.emp_initiative_group_id= this.employeeInitiativeGroupId;
  // // rr.sort_order = 'asc';
  //  this.appService.getEmployeeInitiativesGroups(rr).subscribe((res) => {
  //    this.employeeInitiativeGroupId= res['emp_initiative_group']['initiative_group_id'];
  //    console.log("employeeInitiativeGroupId", res);
     
    
    // this.EmpObjectiveGroupsList = res['emp_objective_groups'];
    // console.log("EmpObjectiveGroupsList", res);
  // })
// }
}
 






