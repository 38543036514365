<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                     <div class="row">
                         <div class="col-md-12 text-center">
                            <h5 class="my-3"><strong>*List of activities for the month of October for objective O1 (5%) for Mayank Vora* </strong></h5>
                            <p class="mb-3">O1 - Propose solution for moving the client forward in sales pipeline</p> 
                            
                        </div>
                         <div class="col-md-4 offset-md-4">
                            <div class="row">
                                <div class="col-md-6 d-flex align-items-center">
                                    <strong>Support in acquiring customers</strong>
                                </div>
                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="text-center">
                                        <div class="my-2">Enterprise Outcome</div>
                                      <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                <nz-progress
                                [nzWidth]="96"
                                nz-tooltip
                                [nzFormat]="EnterpriseOutcome"
                                nzTitle="3 done / 3 in progress / 4 to do"
                                nzType="circle"
                                [nzPercent]="60 "
                                [nzSuccessPercent]="60"
                              ></nz-progress>
                              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                              </a>
                              <span class="cardNumber">01</span>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                </ul>
                              </nz-dropdown-menu>
                                     </nz-card>
                                     </div>
                                </div>
                            </div>
                        </div>
                      
                        <div class="col-md-12 text-center my-4">
                            <img class="downArrow" src="../../../../../assets/images/icon/downArrow.png" alt="Down Arrow" >
                            <h5 class="text-center mt-4">Initiatives for October</h5>
                        </div>

                        <div class="col-md-12 mb-3">
                            <nz-card class="border-red" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <div class="rightIcon" (click)="toggle()"><i nz-icon class="grayIcon dropDownArrow" nzType="caret-down" nzTheme="fill"></i>
                                <!-- <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" > -->
                                </div> 
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div> *I1 (20%)*</div>
                                               <div> Meet 15 resellers to push them for the new anti-virus and servers</div>
                                            </div>
                                            <div class="col-md-3 text-center">
                                                <img class="barIcon" src="../../../../../assets/images/icon/Group461.svg" alt="Bar Icon" >
                                                <span class="ml-2">3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" *ngIf="isShow">  
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3 Cr Achieved - 1.1 Cr</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="60"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="36"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 35 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="60"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="52"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                        <div class="col-md-12 mb-3">
                            <nz-card class="border-green mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                <div class="row">
                                    <div class="col-md-2">  
                                        <div>6th Oct </div>
                                        <div>13:30 - 14:30</div> 
                                    </div>
                                    <div  class="col-md-1">
                                        <nz-divider nzType="vertical"></nz-divider>
                                    </div>
                                    <div class="col-md-7">  
                                        <div>Meeting with exlegenze</div>
                                        Meeting to discuss ui / ux and timelines
                                    </div>
                                    <div class="col-md-2">  <label nz-checkbox >1 Cr</label></div>
                                </div>
                            </nz-card>
                            <nz-card class="border-green mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                <div class="row">
                                    <div class="col-md-2">  
                                        <div>6th Oct </div>
                                        <div>13:30 - 14:30</div> 
                                    </div>
                                    <div  class="col-md-1">
                                        <nz-divider nzType="vertical"></nz-divider>
                                    </div>
                                    <div class="col-md-7">  
                                        <div>Meeting with exlegenze</div>
                                        Meeting to discuss ui / ux and timelines
                                    </div>
                                    <div class="col-md-2">  <label nz-checkbox >Proposal-CODE</label></div>
                                </div>
                            </nz-card>
                            <nz-card class="mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                <div class="row">
                                    <div class="col-md-2">  
                                        <div>6th Oct </div>
                                        <div>13:30 - 14:30</div> 
                                    </div>
                                    <div  class="col-md-1">
                                        <nz-divider nzType="vertical"></nz-divider>
                                    </div>
                                    <div class="col-md-7">  
                                        <div>Meeting with exlegenze</div>
                                        Meeting to discuss ui / ux and timelines
                                    </div>
                                    <div class="col-md-2"> 
                                         <!-- <label nz-checkbox >Proposal-CODE</label> -->
                                        </div>
                                </div>
                            </nz-card>
                            <nz-card class="mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                <div class="row">
                                    <div class="col-md-2">  
                                        <div>6th Oct </div>
                                        <div>13:30 - 14:30</div> 
                                    </div>
                                    <div  class="col-md-1">
                                        <nz-divider nzType="vertical"></nz-divider>
                                    </div>
                                    <div class="col-md-7">  
                                        <div>Meeting with exlegenze</div>
                                        Meeting to discuss ui / ux and timelines
                                    </div>
                                    <div class="col-md-2"> 
                                         <!-- <label nz-checkbox >Proposal-CODE</label> -->
                                        </div>
                                </div>
                            </nz-card>
                            <nz-card class="border-green mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                <div class="row">
                                    <div class="col-md-2">  
                                        <div>6th Oct </div>
                                        <div>13:30 - 14:30</div> 
                                    </div>
                                    <div  class="col-md-1">
                                        <nz-divider nzType="vertical"></nz-divider>
                                    </div>
                                    <div class="col-md-7">  
                                        <div>Meeting with exlegenze</div>
                                        Meeting to discuss ui / ux and timelines
                                    </div>
                                    <div class="col-md-2"> 
                                         <label nz-checkbox >1 Cr</label>
                                        </div>
                                </div>
                            </nz-card>
                            <nz-card class="mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                <div class="row">
                                    <div class="col-md-2">  
                                        <div>6th Oct </div>
                                        <div>13:30 - 14:30</div> 
                                    </div>
                                    <div  class="col-md-1">
                                        <nz-divider nzType="vertical"></nz-divider>
                                    </div>
                                    <div class="col-md-7">  
                                        <div>Meeting with exlegenze</div>
                                        Meeting to discuss ui / ux and timelines
                                    </div>
                                    <div class="col-md-2"> 
                                         <!-- <label nz-checkbox >Proposal-CODE</label> -->
                                        </div>
                                </div>
                            </nz-card>
                            <nz-pagination class="text-right mb-3 mt-4" [nzPageIndex]="1" [nzTotal]="50"></nz-pagination>
                        </div>
                        <div class="col-md-12 mb-3">
                            <nz-card class="border-green" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row mb-3">
                                            <div class="col-md-9">
                                                <div> *I1 (25%)*</div>
                                               <div> Meet 15 resellers to push them for the new anti-virus and servers</div>
                                            </div>
                                            <div class="col-md-3 text-center">
                                                <img class="barIcon" src="../../../../../assets/images/icon/Group461.svg" alt="Bar Icon" >
                                                <span class="ml-2">3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row"> 
                                    
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3 Cr Achieved - 1.1 Cr</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="60"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="36"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 35 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="60"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="52"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                        <div class="col-md-12 mb-3">
                            <nz-card class="border-green" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row mb-3">
                                            <div class="col-md-9">
                                                <div> *I1 (25%)*</div>
                                               <div> Meet 15 resellers to push them for the new anti-virus and servers</div>
                                            </div>
                                            <div class="col-md-3 text-center">
                                                <img class="barIcon" src="../../../../../assets/images/icon/Group461.svg" alt="Bar Icon" >
                                                <span class="ml-2">3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row"> 
                                   
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3 Cr Achieved - 1.1 Cr</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="60"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="36"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 35 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="60"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="52"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                        <div class="col-md-12 mb-3">
                            <nz-card class="border-red" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row mb-3">
                                            <div class="col-md-9">
                                                <div> *I1 (30%)*</div>
                                               <div> Meet 15 resellers to push them for the new anti-virus and servers</div>
                                            </div>
                                            <div class="col-md-3 text-center">
                                                <img class="barIcon" src="../../../../../assets/images/icon/Group461.svg" alt="Bar Icon" >
                                                <span class="ml-2">3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">  
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3 Cr Achieved - 1.1 Cr</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="60"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="36"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 35 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="60"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="52"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                    
                     </div>
                     <!-- <div class="row">
                         <div class="col-md-12">
                            <nz-collapse [nzBordered]="false">
                                <nz-collapse-panel
                                  #p
                                  *ngFor="let panel of panels; let isFirst = first"
                                  [nzHeader]="panel.name"
                                  [nzActive]="panel.active"
                                  [ngStyle]="panel.customStyle"
                                  [nzExpandedIcon]="!isFirst ? panel.icon || expandedIcon : undefined"
                                >
                                  <p>{{ panel.name }} content</p>
                                  <ng-template #expandedIcon let-active>
                                    {{ active }}
                                    <i nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -90"></i>
                                  </ng-template>
                                </nz-collapse-panel>
                              </nz-collapse>

                              <div class="accordion" id="accordionExample">
                                <div class="card">
                                  <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                      <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Collapsible Group Item #1
                                      </button>
                                    </h2>
                                  </div>
                              
                                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                      <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Collapsible Group Item #2
                                      </button>
                                    </h2>
                                  </div>
                                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                      <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Collapsible Group Item #3
                                      </button>
                                    </h2>
                                  </div>
                                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                  </div>
                                </div>
                              </div>
                         </div>
                     </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>