<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
              <nz-modal
                [(nzVisible)]="isFormVisible"
                nzTitle="{{action | titlecase}} Roles"
                (nzOnCancel)="handleModalCancel()"
                (nzOnOk)="handleModalSubmit()"
                nzOkText="Save"
                nzOkType="primary"
                [nzOkLoading]="isFormLoading"
                [nzOkDisabled]="!roleForm.valid"
              >
                <form
                  nz-form
                  [nzLayout]="'vertical'"
                  [formGroup]="roleForm"
                  (ngSubmit)="submitForm()"
>
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="role_name" nzNoColon="false">Role Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input role name!">
                      <nz-input-group nzSize="large">
                        <input
                          formControlName="role_name"
                          nz-input
                          nzSize="large"
                          placeholder="Role Name" (keypress)="alphaNumberOnly($event)"
                        />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                  <!-- <div *ngIf="roleForm.hasError('whitespace')">Please enter valid data</div> -->
                  <nz-form-item>
                    <nz-form-label nzFor="role_name" nzNoColon="false">Manager Role</nz-form-label>
                    <nz-form-control nzErrorTip="Please input manager role!">
                      <nz-input-group nzSize="large">
                        <nz-select nzSize="large" nzAllowClear nzPlaceHolder="Select Manager Role"
                        formControlName="parent_role_id">
                        <nz-option *ngFor="let item of roleListOption" [nzLabel]="item?.role_name" [nzValue]="item.id">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                      <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes' ">
                        <nz-form-control nzErrorTip="Please Select Assessment Centre Creation!">
                            <label nz-checkbox formControlName="allow_assessment_centre_creation">Assessment Centre Creation</label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction === 'yes' ">
                      <nz-form-control nzErrorTip="Please Select Behavior Transaction Campaign Creation!">
                          <label nz-checkbox formControlName="allow_behavior_txn_campaign_creation">Behavior Transaction Campaign Creation</label>
                      </nz-form-control>
                  </nz-form-item>
                    <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes' ">
                        <nz-form-control nzErrorTip="Please Select Labelled Content Creation!">
                            <label nz-checkbox formControlName="allow_labelled_content_creation">Labelled Content Creation</label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes' ">
                        <nz-form-control nzErrorTip="Please Select Learning Assessment Creation!">
                            <label nz-checkbox formControlName="allow_learning_assessment_creation">Learning Assessment Creation
                                </label>
                        </nz-form-control>
                    </nz-form-item>

                  <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_survey === 'yes' ">
                    <nz-form-control nzErrorTip="Please Select Survey Creation!">
                      <label nz-checkbox formControlName="allow_survey_creation">Survey Creation
                      </label>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_gap_analysis === 'yes' ">
                    <nz-form-control nzErrorTip="Please Select Gap Analysis View!">
                      <label nz-checkbox formControlName="allow_gap_analysis_creation">Gap Analysis Creation
                      </label>
                    </nz-form-control>
                  </nz-form-item>


                  <nz-form-item *ngIf="loggedInUserDetail?.tenant?.enable_9box === 'yes' ">
                    <nz-form-control nzErrorTip="Please Select 9Box View!">
                      <label nz-checkbox formControlName="allow_nine_box_creation">9 Box Creation
                      </label>
                    </nz-form-control>
                  </nz-form-item>



                  <nz-form-item *ngIf="loggedInUserDetail?.employee?.role?.allow_organisation_setup === 'yes' " >
                        <nz-form-control nzErrorTip="Please Select Organisation Setup!">
                            <label nz-checkbox formControlName="allow_organisation_setup">Organisation Setup</label>
                        </nz-form-control>
                    </nz-form-item>
                </form>
              </nz-modal>
              <h3 class="py-4 text-center">List of Roles</h3>
              <div class="d-flex justify-content-between align-items-center">
               
                <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle"   nzTheme="fill"></i></h3>
                <div>
                  <button nz-button nzType="primary" (click)="showImportModal()">
                    <span>Import</span>
                  </button>
               </div>
              </div>
           <div class="row">
           
           
         
             <div class="col-md-4 text-right">
               <span *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes' ">
              <!-- <button nz-button (click)="propogateObjective()" nzType="primary" class="mr-2">Propogate Objectives</button> -->
              <!-- <button nz-button nzType="default">Import</button> -->
            </span>
             </div>
           </div>
           <nz-modal [(nzVisible)]="isImportFormVisible" nzTitle="Bulk Import" (nzOnCancel)="handleModalCancel()"
           (nzOnOk)="handleImportModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
           nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

           <h4 style="font-weight: 500;">Instructions</h4>
            <ul>
              <li>Add the names of the roles you want to configure.</li>
              <li>These roles will be needed as part of configuring employees</li>
              <li>Give necessary rights by entering "YES", "NO" in relevant columns</li>
              <li>Manager Role coulmn please enter "nil"</li>
            </ul>

           <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
             class="mr-3">
             Sample CSV Download
           </button>
           <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
             <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
           </nz-upload>
         </nz-modal>
              <nz-table
                nzBordered
                nzShowSizeChanger
                [nzFrontPagination]="false"
                [nzLoading]="loading"
                [nzTotal]="total"
                [nzPageSize]="pageSize"
                [nzPageIndex]="pageIndex"
                (nzQueryParams)="onQueryParamsChange($event)"
                [nzData]="roles"
              >
                <thead>
                  <tr>
                    <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                    <th nzColumnKey="name" [nzSortFn]="true">Name</th> -->


                    <th>Name</th>
                    <th>Manager Role</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of roles">

                    <td>{{ role.role_name }}</td>
                    <td>{{ role?.parent_role?.role_name }}</td>
                    <td class="text-right">
                      <span *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes' ">
                        <button nz-button (click)="propogateObjective(role.id)" nzType="primary" class="mr-2">Propogate Objectives</button>
                      </span>
                      <a *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes' " class="nz-button" [routerLink]="'objective/'+role.id"><button nz-button nzType="default" >Setup Objectives</button></a>
                      <!--<a [routerLink]="['objective']">Setup Objectives</a>-->
                      <nz-divider nzType="vertical"></nz-divider>
                      <a (click)="showEditModal(role.id)">  <i class="ri-pencil-fill grayIcon"></i></a>
                      <nz-divider nzType="vertical"></nz-divider>
                      <a (click)="remove(role)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

