<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body"> 
                     <div class="row">
                         <div class="col-md-12 text-center">
                            <h5 class="my-3"><strong>*Team Wise Drilldown of Annual Priority A1 (25%) for Mayank Vora*</strong></h5> 
                         </div>
                      
                         <div class="col-md-4 offset-md-4">
                            <div class="row">
                                <div class="col-md-6 d-flex align-items-center justify-content-center">
                                    <strong>Support in acquiring customers</strong>
                                </div>
                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="text-center">
                                        <div class="my-2">Enterprise Outcome</div>
                                      <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                <nz-progress
                                [nzWidth]="96"
                                nz-tooltip
                                [nzFormat]="EnterpriseOutcome"
                                nzTitle="3 done / 3 in progress / 4 to do"
                                nzType="circle"
                                [nzPercent]="60 "
                                [nzSuccessPercent]="60"
                              ></nz-progress>
                              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                              </a>
                              <span class="cardNumber">01</span>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                </ul>
                              </nz-dropdown-menu>
                                     </nz-card> 
                                     </div><span class="ml-3">100%</span>
                                </div>
                            </div>
                        </div>
                     
                  
                        <div class="col-md-4 offset-md-4 my-5 text-right">
                            <img class="downArrow mr-5" src="../../../../../assets/images/icon/downArrow.png" alt="Down Arrow" >
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <div class="row">
                                <div class="col-md-6 d-flex align-items-center justify-content-center">
                                    <strong>Mayank</strong>
                                </div>
                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="text-center">
                                        <div class="my-2">Enterprise Outcome</div>
                                      <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                <nz-progress
                                [nzWidth]="96"
                                nz-tooltip
                                [nzFormat]="EnterpriseOutcome"
                                nzTitle="3 done / 3 in progress / 4 to do"
                                nzType="circle"
                                [nzPercent]="60 "
                                [nzSuccessPercent]="60"
                              ></nz-progress>
                              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                              </a>
                              <span class="cardNumber">01</span>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                </ul>
                              </nz-dropdown-menu>
                                     </nz-card> 
                                     </div><span class="ml-3">100%</span>
                                </div>
                            </div>
                        </div>
                      
                        <div class="col-md-4 offset-md-4">
                            <div class="row">
                                <div class="col-md-6 d-flex align-items-center justify-content-center">
                                    <strong>Harish</strong>
                                </div>
                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="text-center">
                                        <div class="my-2">Enterprise Outcome</div>
                                      <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                <nz-progress
                                [nzWidth]="96"
                                nz-tooltip
                                [nzFormat]="EnterpriseOutcome"
                                nzTitle="3 done / 3 in progress / 4 to do"
                                nzType="circle"
                                [nzPercent]="60 "
                                [nzSuccessPercent]="60"
                              ></nz-progress>
                              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                              </a>
                              <span class="cardNumber">01</span>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                </ul>
                              </nz-dropdown-menu>
                                     </nz-card> 
                                     </div><span class="ml-3">100%</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 offset-md-4">
                            <div class="row">
                                <div class="col-md-6 d-flex align-items-center justify-content-center">
                                    <strong class="text-center">Nirav</strong>
                                </div>
                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="text-center">
                                        <div class="my-2">Enterprise Outcome</div>
                                      <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                <nz-progress
                                [nzWidth]="96"
                                nz-tooltip
                                [nzFormat]="EnterpriseOutcome"
                                nzTitle="3 done / 3 in progress / 4 to do"
                                nzType="circle"
                                [nzPercent]="60 "
                                [nzSuccessPercent]="60"
                              ></nz-progress>
                              <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                              </a>
                              <span class="cardNumber">01</span>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                  <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                  <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                </ul>
                              </nz-dropdown-menu>
                                     </nz-card> 
                                     </div><span class="ml-3">100%</span>
                                </div>
                            </div>
                        </div>
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>