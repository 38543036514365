<div id="content-page" class="content-page">
    <div class="container">
      <div class="nz-demo-typography-title">
        <!-- <h3 nz-typography>List of Objective Masters</h3></div> -->
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
              <h3 class="py-4">List of Objective Masters</h3>
              <div class="row my-4">
                <div class="col-md-4 plusSection">
                  <h3 class="text-left plusIcon"> 
                    <i nz-icon class="" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
                    <!-- <nz-select nzSize="large" >
                      <nz-option nzValue="SP" nzLabel="SP"></nz-option> 
                    </nz-select> -->
                  </div>
                  <div class="col-md-4">
                    <!-- <nz-select nzSize="large" >
                      <nz-option nzValue="AP" nzLabel="AP"></nz-option> 
                    </nz-select> -->
                  </div>
                  <div class="col-md-4 searchSection">
                    <!-- <nz-select nzSize="large" >
                      <nz-option nzValue="FY-20-21" nzLabel="FY-20-21"></nz-option>
                      <nz-option nzValue="FY-21-22" nzLabel="FY-21-22"></nz-option>
                    </nz-select>
                    <h3 class="searchIcon"><i nz-icon nzType="search" nzTheme="outline"></i></h3> -->
                  </div>
                  
              </div>
              <nz-modal
                [(nzVisible)]="isFormVisible"
                nzTitle="Define Objectives"
                (nzOnCancel)="handleModalCancel()"
                (nzOnOk)="handleModalSubmit()"
                nzOkText="Save"
                nzOkType="primary"
                [nzOkLoading]="isFormLoading"
                [nzOkDisabled]="!objectiveMasterForm.valid"
              >
                <form
                  nz-form
                  [nzLayout]="'vertical'"
                  [formGroup]="objectiveMasterForm"
                  (ngSubmit)="submitForm()"
                >
                  <!-- Capture the Objective Master name/ title -->
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="title" nzNoColon="false">Objective Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Objective Name!"> 
                        <input nzSize="large" formControlName="title" nz-input placeholder="Objective Name" /> 
                    </nz-form-control>
                  </nz-form-item>

                  <!-- Capture the Short Code -->
                  <nz-form-item>
                    <nz-form-label nzFor="short_code" nzNoColon="false">Short Code</nz-form-label>
                    <nz-form-control> 
                        <input nzSize="large" formControlName="short_code" nz-input placeholder="Short Code" /> 
                    </nz-form-control>
                  </nz-form-item>
  
                  <!-- Capture the AP -->
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="ap_id" nzNoColon="false">Annual Priority</nz-form-label>
                    <nz-form-control nzErrorTip="Please Select Annual Priority!"> 
                        <nz-select
                        nzSize="large"
                          nzAllowClear
                          nzShowSearch
                          nzServerSearch
                          formControlName="ap_id"
                          (nzOnSearch)="onAPOptionListSearch($event)">
                          <ng-container *ngFor="let o of apOptionList">
                            <nz-option
                              *ngIf="!isAPOptionListLoading"
                              [nzValue]="o.id"
                              [nzLabel]="o.title"
                            ></nz-option>
                          </ng-container>
                          <nz-option
                            *ngIf="isAPOptionListLoading"
                            nzDisabled
                            nzCustomContent
                          >
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select> 
                    </nz-form-control>
                  </nz-form-item>

                </form>
              </nz-modal>
           
              <nz-table
                nzBordered
                nzShowSizeChanger
                [nzFrontPagination]="false"
                [nzLoading]="loading"
                [nzTotal]="total"
                [nzPageSize]="pageSize"
                [nzPageIndex]="pageIndex"
                (nzQueryParams)="onQueryParamsChange($event)"
                [nzData]="objectiveMasters"
              >
                <thead>
                  <tr>
                    <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                    <th nzColumnKey="title" [nzSortFn]="true">Objective Name</th>
                    <th nzColumnKey="ap_id" [nzSortFn]="true">Annual Priority Name</th>
                    <th nzColumnKey="shortCode" [nzSortFn]="true">Short Code</th> -->
                    
                    <th>Objective Name</th>
                    <th>Annual Priority Name</th>
                    <th>Short Code</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let om of objectiveMasters">
                    
                    <td>{{ om.title }}</td>
                    <td>
                      <a [routerLink]="['/annual-priority']" [queryParams]="{id: om.ap_id}">{{ om.annual_priority.title }}</a>
                    </td>
                    <td>{{ om.short_code }}</td>
                    <td>
                      <a (click)="showEditModal(om.id)"><i class="ri-pencil-fill grayIcon"></i></a>
                      <nz-divider nzType="vertical"> | </nz-divider>
                      <a (click)="remove(om)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

