import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeRetrieveRequest } from 'src/app/app.models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-left-menu-panel',
  templateUrl: './left-menu-panel.component.html',
  styleUrls: ['./left-menu-panel.component.css']
})
export class LeftMenuPanelComponent implements OnInit {
  loggedInUserDetail: any;
  gapAnalysisIds: any;
  nineBoxIds: any;
  myGapIds: any;
  myNineBoxids: any;
  publishedNineBoxidsForVariableFilter:any;
  publishedGapAnalysisForMe:any;
  reportPublishList: any;
  leftMenuItems: any;
  storageAccount: String;
  isManager: any = false;
  success
  leaderSpeak
  announce
  learnGo

  variableFIlterCheck:any
  constructor(private appService: AppService, public router: Router,) {
  }

  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();

console.log(' this.loggedInUserDetail', this.loggedInUserDetail)
    if(this.loggedInUserDetail){
      // console.log("this.loggedInUserDetail.employee.id",this.loggedInUserDetail.employee.id)
      this.appService.getEmployeeVariableFilter(this.loggedInUserDetail.employee.id).subscribe((res:any)=>{
        this.variableFIlterCheck = res.status

        sessionStorage.setItem('variable_filter_check', JSON.stringify(res));
        console.log("variable new res",this.variableFIlterCheck)
      },(error)=>{
        Swal.fire({
          icon:'error',
          title:'Oops!',
          text:error.error.message
        })
      })
    }

    let rr = new EmployeeRetrieveRequest();
    rr.reporting_manager = this.loggedInUserDetail.employee.id;
    this.appService.getEmployees(rr).subscribe((data: any) => {
      if(data['_meta']['total_items'] > 0){
        this.isManager = true;
      }
    });

    // console.log("Login user Access", this.loggedInUserDetail)
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
    if(this.loggedInUserDetail.employee.is_admin == "yes"){
      Object.keys(this.loggedInUserDetail.employee.role).forEach(key => {
        if(this.loggedInUserDetail.employee.role[key] === "no"){
          this.loggedInUserDetail.employee.role[key] = "yes"
        }
      });
      sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(this.loggedInUserDetail))));
      sessionStorage.setItem('dupme', btoa(encodeURIComponent(JSON.stringify(this.loggedInUserDetail))));
    }
    this.getGapAnalysisIds();
    this.getNineBoxIds();
    this.getGapAnalysisMyParticipation();
    this.getNineBoxMyParticipation();
    this.getNineBoxPublishedForVariableFilters();
    this.getGapAnalysisPublishedForMe();
    let types = this.loggedInUserDetail?.tenant?.labelled_content_types;
    this.success = types?.filter(e=>(e == "success-stories"))
    this.leaderSpeak = types?.filter(e=>(e == "leader-speak"))
    this.announce = types?.filter(e=>(e == "org-announce"))
    this.learnGo = types?.filter(e=>(e == "learn-go"))
    this.getAssessmentReportPublishList()
    this.getTalentCardStatus();
    this.getLeftMenuData();

  }


  async getGapAnalysisIds() {
    this.gapAnalysisIds = await this.appService.GapAnalysisPublishIds().toPromise();
    // console.log('GAP IDS', this.gapAnalysisIds);
  }

  async getNineBoxIds() {

    this.nineBoxIds = await this.appService.get9BoxPublishList().toPromise();

    // console.log('9Box pulbished list', this.nineBoxIds);
  }

  async getGapAnalysisMyParticipation() {
    this.myGapIds = await this.appService.getMyGapAnalysisParticipation().toPromise();
    //console.log("my Gap data",this.myGapIds)
  }
  async getNineBoxMyParticipation() {
    this.myNineBoxids = await this.appService.getMyNineBoxParticipation().toPromise();
    console.log("my Nine box ids in menu",this.myNineBoxids)
  }

  async getNineBoxPublishedForVariableFilters() {
    this.publishedNineBoxidsForVariableFilter = await this.appService.getPublishedNineBox().toPromise();
  }



  async getGapAnalysisPublishedForMe() {
    this.publishedGapAnalysisForMe = await this.appService.getPublishedGapAnalysis().toPromise();
  }


  redirectToSvabhav(){
    window.open("https://svabawdemo.truleadai.com/login/", "_blank");
  }

  redirectToRoute(link, type) {
    // console.log(link, type);
    if (type == 'assessment') {
      sessionStorage.setItem('asmntLevel', '0');
    } else if (type == 'coaching') {
      sessionStorage.setItem('asmntLevel', '1');
    } else if (type == 'group-reflection') {
      sessionStorage.setItem('asmntLevel', '2');
    }
    this.router.navigate([link]);
  }

  async getLeftMenuData() {
    this.leftMenuItems = await this.appService.getLeftMenuData().toPromise();
    // console.log('fff', this.leftMenuItems);
  }

  getImageUrl(item) {
    if (item.icon_id != null) {
      return this.storageAccount + item.icon_data.original_image_url;
    } else if (item.page.standard_icon_id) {
      return this.storageAccount + item.page.standard_icon_data.original_image_url;
    }
  }

  checkUserTypeAllowed(menu) {
    if (menu.is_parent == 'yes') {
      if (menu.user_types.length > 0) {
        let index = menu.user_types.indexOf(this.loggedInUserDetail.user_type);
        if (index >= 0) {
          return true;
        }
        return false;
      }
      return false;
    } else {
      if (menu.user_types.length > 0) {
        let index = menu.user_types.indexOf(this.loggedInUserDetail.user_type);
        if (index >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (menu.page.user_types.length > 0) {
          let index = menu.page.user_types.indexOf(this.loggedInUserDetail.user_type);
          if (index >= 0) {
            return true;
          }
          return true;
        }
        return false;
      }
    }
  }

  checkUserRoleAllowed(menu){
    // console.log(menu.privilege);
    if (menu.privilege){
      if(this.loggedInUserDetail.employee.role[menu.privilege] == 'yes') {
        return true;
      }
      return false;
    }
    return true;
  }

  async getAssessmentReportPublishList(){
    this.appService.getReportPublishList().subscribe((res)=>{
      this.reportPublishList=res
      console.log(this.reportPublishList,'reort publish list')
    })

  }

  talentCardStatus = "inactive"
  getTalentCardStatus(){
    this.appService.getTelantCardStatus().subscribe(res => {
      // console.log('Status',res)
      this.talentCardStatus = res.talent_card_status;
    },err=>{
      this.talentCardStatus = "inactive";
    })
  }

}
