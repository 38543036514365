import {AppService} from 'src/app/app.service';
import {Router, ActivatedRoute} from '@angular/router';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {RoleRetrieveRequest} from 'src/app/app.models';
import {NgxSpinnerService} from 'ngx-spinner';
import {Workbook} from 'exceljs';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import {Employee} from 'src/app/app.models';
import {Object} from '@amcharts/amcharts4/.internal/fabric/fabric-impl';


@Component({
  selector: 'app-form-report',
  templateUrl: './form-report.component.html',
  styleUrls: ['./form-report.component.css']
})
export class FormReportComponent implements OnInit, OnDestroy {
  private sub: any;

  timer;
  loading = false;

  launch: any = {};
  page = 1;
  total = 1;
  limit = 100;
  employees: Employee[] = [];
  curPageEmployees: Employee[] = [];
  statuses: any = {};
  showDownloads: any = {};
  visible = false
  searchValue = '';

  constructor(
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    // this.loading = true;

    this.sub = this.route.params.subscribe(params => {
      if (params['id'] > 0) {
        this.appService.getFormLaunchById(params['id']).subscribe((launch: any) => {
          this.launch = launch;
          console.log("launch data",this.launch)
          this.loadEmployees();

          this.appService.getSubmissionsByLaunchId(params['id']).subscribe((submissions: any) => {
            for (const submission of submissions) {
              this.statuses[submission.employee_id] = submission.status2;
              this.showDownloads[submission.employee_id] = submission.status;
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message,
              allowOutsideClick: false,
            });
            this.spinner.hide();
            // this.loading = false;
          });

          this.spinner.hide();
          // this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          // this.loading = false;
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  splitIds(page) {
    if (this.launch.employee_ids?.length <= this.limit) {
      return this.launch.employee_ids;
    }

    const perChunk = this.limit;
    const inputArray = this.launch.employee_ids;
    const result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    return result[page - 1];
  }

  loadEmployees(): void {
    this.loading = true;
    // this.spinner.show();
    let paramIds = {
      ids: this.splitIds(this.page),
      
    };
    this.appService.getAllEmployeesById(paramIds).subscribe((data: any) => {
      console.log("data 1",data)
      this.total = this.launch.employee_ids.length;
      this.employees = data;
      this.spinner.hide();
      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    this.limit = pageSize;
    this.page = pageIndex;
    this.loadEmployees();
  }

  downloadReport(employee): void {
    let params = {
      employee_id: employee.id,
      launch_id: this.launch.id,
    };
    this.spinner.show();
    this.appService.getParticipantExcelForLaunch(params).subscribe((res: any) => {
      let goals = res.goals;
      let progress = res.progress;
      let status2 = res.status2;

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Report');

      let cols = [];
      for (const colId of goals.cols) {
        for (const schema of res.form.schemas) {
          if (colId == schema.id) {
            cols.push(schema.label);
            break;
          }
        }
      }
      let count = 1;
      for (const key in progress) {
        if (key == 'cols') {
          continue;
        }
        for (const colId of progress.cols) {
          for (const schema of res.form.schemas) {
            if (colId == schema.id) {
              cols.push(`${schema.label} - Update ${count}`);
              break;
            }
          }
        }
        count++;
      }
      cols.push("Status");

      let headerRow = worksheet.addRow(cols);
      headerRow.eachCell((cell, number) => {
        cell.font = {
          bold: true,
        };
      });

      delete goals.cols;
      delete progress.cols;

      let result = [];
      for (const key in goals) {
        let index = 0;
        for (const record of goals[key]) {
          record.pop();
          result = [...result, ...record];
          for (const pkey in progress) {
            for (const record of progress[pkey]) {
              progress[pkey][index].pop();
              result = [...result, ...progress[pkey][index]];
              break;
            }
          }
          index++;
          result = [...result, status2.toUpperCase()];
          worksheet.addRow(result);
          result = [];
        }
        break;
      }

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        FileSaver.saveAs(blob, 'participant-status.xlsx');
      });

      this.spinner.hide();
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }


  downloadAllReport(): void {
    let params = {
      launch_id: this.launch.id,
    };
    this.spinner.show();
    this.appService.getAllParticipantsExcelForLaunch(params).subscribe((res: any) => {
      console.log(res);
      let goals = res[0].goals;
      let progress = res[0].progress;
      // let status2 = res[0].status2;

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Report');

      let cols = ['employee_id', 'first_name', 'last_name', 'business_unit', 'department', 'location'];

      for (const colId of goals.cols) {
        for (const schema of res[0].form.schemas) {
          if (colId == schema.id) {
            cols.push(schema.label);
            break;
          }
        }
      }

      cols.push("Status");
      // let count = 1;
      // for (const key in goals) {
      //   // if (key == 'cols') {
      //   //   continue;
      //   // }
      //   // console.log(progress[key]);
      //   // for (let i=0;i<progress[key][0].length;i++)
      //   // {
      //   //   cols.push(`Status - Update ${i}`);
      //   // }
      //   // break;
      //   // for (const colId of progress.cols) {
      //   //   for (const schema of res[0].form.schemas) {
      //   //     if (colId == schema.id) {
      //   //       cols.push(`${schema.label} - Update ${count}`);
      //   //       count++;
      //   //       break;
      //   //     }
      //   //   }
      //   // }
      //   // count++;
      // }
      // cols.push("Status");
      // cols.push("Status2");

      let headerRow = worksheet.addRow(cols);
      headerRow.eachCell((cell, number) => {
        cell.font = {
          bold: true,
        };
      });


      let result = [];

      for (let i = 0; i < res.length; i++) {
        let item = res[i];
        // console.log('item : ');
        // console.log(item);
        // let goal = { cols: null };
        // Object.assign(goal, {cols: item['goals']});
        // Object.assign(progress, item['progress']);
        // console.log(item['goals']);
        // console.log(item['progress']);
        // console.log('goal');
        // console.log(goal);

        delete item['goals'].cols;
        // delete progress.cols;
        // console.log('after delete');
        // console.log(item['goals']);
        // delete goals.id;
        for (const key in item['goals']) {
          // console.log('key');
          // console.log(key);
          let index = 0;
          for (const record of item['goals'][key]) {
            // console.log('record');
            // console.log(record);
            result = Array(item['employee'].employee_id, item['employee'].first_name, item['employee'].last_name, item['employee']['business_unit']['name'], item['employee']['department']['name'], item['employee']['location']['name']);
            record.pop();
            result = [...result, ...record];
            // console.log(key);
            // console.log(index)
            // console.log(progress);
            // console.log('result');
            // console.log(result);
            // console.log(progress[key][index]);
            // result = [...result, ...Array.from(progress[key][index],x => x)];
            // console.log(result);
            // record.pop();
            // for (const pkey in progress) {
            //   console.log(pkey);
            //   for (const record of progress[pkey]) {
            //     if (typeof progress[pkey][record] !== 'undefined'){
            //     progress[pkey][index].pop();
            //     result = [...result, ...progress[pkey][index]];
            //     break;
            //     }else
            //     {
            //       result = [...result, ''];
            //     }
            //   }
            // }
            // index++;

            result = [...result, item['status2'].toUpperCase()];
            // console.log(result);
            worksheet.addRow(result);
            result = [];
            index++;
          }
          // break;
        }
      }


      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        FileSaver.saveAs(blob, 'participants-status-bulk.xlsx');
      });

      this.spinner.hide();
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  searchServer():void{
    if(this.searchValue != null){
      this.loading = true
      let paramIds = {
        ids: this.splitIds(this.page),
        partial_name:this.searchValue
      };
      this.appService.getAllEmployeesById(paramIds).subscribe((data: any) => {
        console.log("data 22222",data)
        this.total = data.length;
        this.employees = data;
        this.loading = false
        this.visible = false
        this.searchValue =''
      })
    } else {
      this.loadEmployees()
      this.visible = false
    }
  }

  reset(): void {
    this.loadEmployees()
    this.visible = false
    this.searchValue =''
  }

}
