<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">{{launch.title}} - Dashboard</h3>
            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms/launches" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div>
                <!-- <button nz-button [nzType]="'primary'" [nzLoading]="loading">Export</button> -->
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-lg-4 text-center">
              <div class="card shadow bg-light mb-3 card1" style="max-width: 20rem; height: 6.5rem;">
                <div class="card-body">
                  <h5 class="textcolor">Total Participants</h5>
                  <h5 class="card-title textcolor pt-2"><b>{{totalParticipants}}</b></h5>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center">
              <div class="card shadow bg-light mb-3 card3" style="max-width: 20rem; height: 6.5rem;">
                <div class="card-body">
                  <h5 class="textcolor">Not Started</h5>
                  <h5 class="card-title textcolor mb-0"><b>{{notStarted}}</b></h5>
                  <p class="card-text" style="text-align: center; font-size: 16px; font-weight: 500;">{{notStartedPercentage}}%</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center">
              <div class="card shadow bg-light mb-3 card2" style="max-width: 20rem; height: 6.5rem;">
                <div class="card-body">
                  <h5 class="textcolor">Saved</h5>
                  <h5 class="card-title textcolor mb-0"><b>{{saved}}</b></h5>
                  <p class="card-text" style="text-align: center; font-size: 16px; font-weight: 500;">{{savedPercentage}}%</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center">
              <div class="card shadow bg-light mb-3 card4" style="max-width: 20rem; height: 6.5rem;">
                <div class="card-body">
                  <h5 class="textcolor">Submitted</h5>
                  <h5 class="card-title textcolor mb-0"><b>{{submitted}}</b></h5>
                  <p class="card-text" style="text-align: center; font-size: 16px; font-weight: 500;">{{submittedPercentage}}%</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center">
              <div class="card shadow bg-light mb-3 card5" style="max-width: 20rem; height: 6.5rem;">
                <div class="card-body">
                  <h5 class="textcolor">Rejected</h5>
                  <h5 class="card-title textcolor mb-0"><b>{{rejected}}</b></h5>
                  <p class="card-text" style="text-align: center; font-size: 16px; font-weight: 500;">{{rejectedPercentage}}%</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center">
              <div class="card shadow bg-light mb-3 card6" style="max-width: 20rem; height: 6.5rem;">
                <div class="card-body">
                  <h5 class="textcolor">Approved</h5>
                  <h5 class="card-title textcolor mb-0"><b>{{approved}}</b></h5>
                  <p class="card-text" style="text-align: center; font-size: 16px; font-weight: 500;">{{approvedPercentage}}%</p>
                </div>
              </div>
            </div>
          </div>
          <br /><br /><br />
        </div>
      </div>
    </div>
  </div>
</div>
