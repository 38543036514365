import {CampaignsRetrieveRequest} from '../../../app.models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {formatDistance} from 'date-fns';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ActivatedRoute} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AppService} from 'src/app/app.service';
import {
  CampaignItemGridViewRetrieveRequest,
  CampaignEmployees,
  CampaignEmployeesRetrieveRequest,
  EmployeeTransactionRetrieveRequest
} from 'src/app/app.models';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {DatePipe} from '@angular/common';

export interface TreeNodeInterface {
  key: string;
  levelType: number;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
  campaign_id: number;
  campaign_item_name: string;
  id: number;
  image: string;
  image_id: number;
  image_url: string;
  item_type: string;
  parent_item_id: number;
}

@Component({
  selector: 'app-transaction-grid',
  templateUrl: './transaction-grid.component.html',
  styleUrls: ['./transaction-grid.component.css']
})
export class TransactionGridComponent implements OnInit {
  // storage account url
  storageAccount: String;
  selectedCardIndex: number = null;
  selectedCollapsePanelIndex: number = 0;
  campaignListForm: FormGroup;
  displayTransactionForm!: FormGroup;
  isVisibleDisplayTransactionModal = false;
  isDisplayTransactionFormLoading = false;
  isAcceptButtonLoading = false;
  isRejectButtonLoading = false;
  transactionCardDetails: any;
  selectedCampaignId: null;
  campaignDetails: any;
  campaignItemGridViewDetails: any;
  selectedCampaignItemDetails: any = null;
  selectedCampaignDetails = null;
  isCampaignExpire: boolean = false;
  selectedCampaignLastLevel: string = null;
  // panels = [
  //   {
  //     active: true,
  //     name: 'This is panel header 1',
  //     childPanel: [
  //       {
  //         active: false,
  //         name: 'This is panel header 1-1'
  //       }
  //     ]
  //   },
  //   {
  //     active: false,
  //     name: 'This is panel header 2'
  //   },
  //   {
  //     active: false,
  //     name: 'This is panel header 3'
  //   }
  // ];
  // comment section related

  // tslint:disable-next-line:no-any
  data: any[] = [];
  submitting = false;
  user = {
    author: 'Han Solo',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
  };
  inputValue = '';

  handleSubmit(): void {
    this.submitting = true;
    const content = this.inputValue;
    this.inputValue = '';
    setTimeout(() => {
      this.submitting = false;
      this.data = [
        ...this.data,
        {
          ...this.user,
          content,
          datetime: new Date(),
          displayTime: formatDistance(new Date(), new Date())
        }
      ].map(e => {
        return {
          ...e,
          displayTime: formatDistance(new Date(), e.datetime)
        };
      });
    }, 800);
  }


// Save Transaction Model Related
  currentDate = new Date();
  loggedInUserDetail: any;
  transactionForm!: FormGroup;
  action = 'create';
  titleForTransactionModal = 'You observed a Integrity behavior in:';
  isVisibleTransactionModal = false;
  isTransactionFormLoading = false;
  listOfTransactionType = [
    {label: 'Seeking From Peer', value: 'seeking from peer'},
    {label: 'Sharing With Peer', value: 'sharing with peer'},
    {label: 'Self', value: 'self'}
  ];
  selectedTransactionType: null;
  selectedCampaignItemId: number;
  clickedRowDetails: any;
// selectedCampaignId: null;
// campaignDetails: any;
  selectedTechLevelLabel = null;
  campaignItemsTree: any;
  transactionDetails: any;
  employeeOptionList: CampaignEmployees[] = [];
  employeeOptionListSearchChange$ = new BehaviorSubject('');
  isEmployeeOptionListLoading = false;

  onEmployeeOptionListSearch(value: string): void {
    this.isEmployeeOptionListLoading = true;
    this.employeeOptionListSearchChange$.next(value);
  }

// right panel tree table
  countIndex: number = 0;
  listOfMapData: TreeNodeInterface[];
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({...root, level: 0, expand: true});

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({...node.children[i], level: node.level! + 1, expand: true, parent: node});
        }
      }
    }
    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  constructor(public fb: FormBuilder,
              public appService: AppService,
              public message: NzMessageService,
              private activatedRoute: ActivatedRoute,
              private modal: NzModalService,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.initCampaignListForm();
    this.getCampaigns();
    // Save Transaction Model Related
    this.initTransactionForm();
    // this.getEmployeeTransactions();
    this.initDisplayTransactionForm();
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
  }

  initCampaignListForm() {
    this.campaignListForm = this.fb.group({
      campaign: ['select campaign', [Validators.required]],
    });
  }

  initDisplayTransactionForm(): void {
    this.displayTransactionForm = this.fb.group({
      // share_type: [null],
      // displayer_comments: [null],
      observer_comments: [null, [Validators.required]],
      // employee_ids: [null],
      // campaign_item_id: [null],
      // manager_ids: [null],
    });
  }

  showDisplayTransactionModal(data: any) {
    this.selectedCampaignItemDetails = data;
    this.getEmployeeTransactions();
    this.isVisibleDisplayTransactionModal = true;
    this.action = 'edit';
  }

  displayTransactionModalHandleCancel() {
    this.isVisibleDisplayTransactionModal = false;
  }

  displayTransactionModalHandleSubmit(item) {
    let clickedTransactionDetails = item;
    for (const i in this.displayTransactionForm.controls) {
      this.displayTransactionForm.controls[i].markAsDirty();
      this.displayTransactionForm.controls[i].updateValueAndValidity();
    }
    // this.isDisplayTransactionFormLoading = true;
    // Create the employee transaction on the server.
    if (this.displayTransactionForm.valid) {
      let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'IST'); //HH:mm:ss
      let subscriber = null;
      let message = null;
      let data = null;
      if (clickedTransactionDetails?.share_type === 'seeking from peer') {
        data = {
          observer_comments: this.displayTransactionForm.value.observer_comments,
          status: 'pending acceptance',
        };
      } else if (clickedTransactionDetails?.share_type === 'sharing with peer') {
        data = {
          observer_comments: this.displayTransactionForm.value.observer_comments,
          // status: 'pending acceptance',
        };
      } else if (clickedTransactionDetails?.share_type === 'self') {
        data = {
          observer_comments: this.displayTransactionForm.value.observer_comments,
          status: 'pending acceptance',
        };
      }
      if (this.action === 'edit') {
        subscriber = this.appService.updateEmployeeTransaction(data, clickedTransactionDetails.id);
        message = 'Transaction"' + clickedTransactionDetails?.share_type + '" has been updated.';
      }

      subscriber.subscribe(res => {
        // this.isTransactionFormLoading = false;
        // this.isVisibleTransactionModal = false;
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.getEmployeeTransactions();
        this.displayTransactionForm.reset();
        this.getCampaignItemGridView();
      }, error => {
        // this.isTransactionFormLoading = false;
        // this.isVisibleTransactionModal = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }

  }

  onBtnClickChangeStatus(btnType: string, item: any) {
    let clickedButtonType = btnType;
    let clickedTransactionDetails = item;

    let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'IST'); //, "IST"HH:mm:ss
    let subscriber = null;
    let message = null;
    let data = null;

    if (clickedTransactionDetails.share_type === 'seeking from peer') {
      if (clickedButtonType === 'accept') {
        data = {status: 'accepted', acceptance_date: date,};
        this.isAcceptButtonLoading = true;
      } else if (clickedButtonType === 'reject') {
        data = {status: 'rejected', transaction_date: date,};
        this.isRejectButtonLoading = true;
      } else if (clickedButtonType === 'deny') {
        data = {status: 'acknowledgement denied', transaction_date: date,};
        this.isRejectButtonLoading = true;
      }
    } else if (clickedTransactionDetails.share_type === 'sharing with peer') {
      if (clickedButtonType === 'accept') {
        data = {status: 'accepted', acceptance_date: date,};
        this.isAcceptButtonLoading = true;
      } else {
        data = {status: 'rejected', transaction_date: date,};
        this.isRejectButtonLoading = true;
      }
    } else if (clickedTransactionDetails.share_type === 'self') {
      if (clickedButtonType === 'accept') {
        data = {status: 'accepted', acceptance_date: date,};
        this.isAcceptButtonLoading = true;
      } else if (clickedButtonType === 'reject') {
        data = {status: 'rejected', transaction_date: date,};
        this.isRejectButtonLoading = true;
      } else if (clickedButtonType === 'deny') {
        data = {status: 'acknowledgement denied', transaction_date: date,};
        this.isRejectButtonLoading = true;
      }
    }
    if (this.action === 'edit') {
      subscriber = this.appService.updateEmployeeTransaction(data, clickedTransactionDetails.id);
      message = 'Transaction"' + clickedTransactionDetails?.share_type + '" has been updated.';
    }
    subscriber.subscribe(res => {
      this.isRejectButtonLoading = false;
      this.isAcceptButtonLoading = false;
      this.message.create('success', message, {
        nzDuration: 5000
      });
      this.getCampaignItemGridView();
      this.getEmployeeTransactions();
    }, error => {
      this.isRejectButtonLoading = false;
      this.isAcceptButtonLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }

  // campaign Dropdown change related
  onCampaignChange() {
    this.selectedCampaignId = this.campaignListForm.get('campaign').value;
    // console.log('selectedCampaignId', this.selectedCampaignId);
    if (this.selectedCampaignId !== null) {
      this.getCampaignItemGridView();
      this.getCampaignItemsTree();
      // campaign start/end date validation related
      this.selectedCampaignDetails = this.campaignDetails.find(x => x.id === this.selectedCampaignId);
      // console.log('selectedCampaignDetails', this.selectedCampaignDetails);

      let date = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd', 'IST'));
      let startDate = new Date(this.selectedCampaignDetails?.start_date);
      let endDate = new Date(this.selectedCampaignDetails?.end_date);
      date.setHours(0, 0, 0);
      startDate.setHours(0, 0, 0);
      endDate.setHours(0, 0, 0);
      // console.log('todays date', date);
      // console.log('campaign date', startDate, endDate);

      if (startDate <= date && endDate >= date) {
        this.isCampaignExpire = false;
      } else {
        this.isCampaignExpire = true;
      }
    }
  }

  // Get List of Campaigns
  getCampaigns() {
    let rr = new CampaignsRetrieveRequest();
    rr.emp_id = this.loggedInUserDetail?.employee?.id;
    this.appService.getCampaigns(rr).subscribe(res => {
      this.campaignDetails = res['items'];
    });
  }

  getCampaignItemGridView() {
    let rr = new CampaignItemGridViewRetrieveRequest();
    rr.campaign_id = this.selectedCampaignId;
    rr.txn_counts = 'yes';
    rr.per_page = 500;
    this.appService.getCampaignItemGridView(rr).subscribe(res => {
      this.campaignItemGridViewDetails = res['items'];
      // console.log('test', this.campaignItemGridViewDetails);
      this.selectedCampaignLastLevel = this.campaignItemGridViewDetails[0]?.item_type;
      // console.log("selectedCampaignLastLevel", this.campaignItemGridViewDetails[0].item_type);

    });
  }

  getEmployeeTransactions() {
    let rr = new EmployeeTransactionRetrieveRequest();
    rr.sort_order = 'desc';
    rr.order_by = 'created_on';
    rr.campaign_item_id = this.selectedCampaignItemDetails?.id;
    rr.campaign_id = this.selectedCampaignId;
    rr.displayer_emp_id = this.loggedInUserDetail?.employee?.id;
    rr.observer_emp_id = this.loggedInUserDetail?.employee?.id;
    rr.pending = 'yes';
    this.appService.getEmployeeTransactions(rr).subscribe(res => {
      this.transactionCardDetails = res['items'];
      // console.log('transactionCardDetails', this.transactionCardDetails);

    });
  }


// Save Transaction Model Related
  initTransactionForm(): void {
    this.transactionForm = this.fb.group({
      share_type: [null, [Validators.required]],
      displayer_comments: [null],
      observer_comments: [null],
      employee_ids: [null],
      // campaign_item_id: [null],
      manager_ids: [null],
    });
  }

  async showTransactionModal(selectedTransType: any, cmpItmId: number) {
    this.selectedTransactionType = selectedTransType;
    this.selectedCampaignItemId = cmpItmId;
    this.action = 'create';
    this.isVisibleTransactionModal = true;
    // await this.transactionForm.reset();
    this.selectedTechLevelLabel = null;
    await this.getEmployees();
    await this.getCampaigns();
    await this.transactionForm.controls['share_type'].setValue(this.selectedTransactionType);

  }

  transactionModalHandleCancel() {
    this.transactionForm.reset();
    this.isVisibleTransactionModal = false;
  }

  transactionModalHandleSubmit() {
    for (const i in this.transactionForm.controls) {
      this.transactionForm.controls[i].markAsDirty();
      this.transactionForm.controls[i].updateValueAndValidity();
    }
    this.isTransactionFormLoading = true;
    // Create the employee transaction on the server.
    if (this.transactionForm.valid) {
      let date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'IST'); //HH:mm:ss
      let subscriber = null;
      let message = null;
      let data = null;
      let transactionDate = null;
      if (this.action === 'create') {
        transactionDate = date;
      } else {
        transactionDate = this.clickedRowDetails.transaction_date;
      }

      if (this.selectedTransactionType === 'seeking from peer') {
        data = {
          campaign_id: this.selectedCampaignId,
          displayer_emp_id: this.loggedInUserDetail?.employee?.id,
          observer_emp_id: this.transactionForm.value.employee_ids,
          transaction_date: transactionDate,
          campaign_item_id: this.selectedCampaignItemId,
          share_type: this.transactionForm.value.share_type,
          displayer_comments: this.transactionForm.value.displayer_comments,
          observer_comments: this.transactionForm.value.observer_comments,
        };
      } else if (this.selectedTransactionType === 'sharing with peer') {
        data = {
          campaign_id: this.selectedCampaignId,
          observer_emp_id: this.loggedInUserDetail?.employee?.id,
          displayer_emp_id: this.transactionForm.value.employee_ids,
          status: 'pending acceptance',
          transaction_date: transactionDate,
          campaign_item_id: this.selectedCampaignItemId,
          share_type: this.transactionForm.value.share_type,
          displayer_comments: this.transactionForm.value.displayer_comments,
          observer_comments: this.transactionForm.value.observer_comments,
        };
      } else if (this.selectedTransactionType === 'self') {
        data = {
          campaign_id: this.selectedCampaignId,
          displayer_emp_id: this.loggedInUserDetail?.employee?.id,
          observer_emp_id: this.transactionForm.value.manager_ids,
          transaction_date: transactionDate,
          campaign_item_id: this.selectedCampaignItemId,
          share_type: this.transactionForm.value.share_type,
          displayer_comments: this.transactionForm.value.displayer_comments,
          observer_comments: this.transactionForm.value.observer_comments,
        };
      }
      if (this.action === 'create') {
        subscriber = this.appService.createEmployeeTransaction(data);
        message = 'Transaction "' + this.transactionForm.controls['share_type'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateEmployeeTransaction(data, this.clickedRowDetails.id);
        message = 'Transaction"' + this.transactionForm.controls['share_type'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isTransactionFormLoading = false;
        this.transactionModalHandleCancel();
        this.message.create('success', message, {
          nzDuration: 5000
        });
        // this.getEmployeeTransactions();
        this.transactionForm.reset();
        this.getCampaignItemGridView();
      }, error => {
        this.isTransactionFormLoading = false;
        this.transactionModalHandleCancel();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        })

      });
    }

  }

// Transaction Modal related
  onTransactionTypeChange() {
    this.selectedTransactionType = this.transactionForm.get('share_type').value;
    // console.log(this.selectedTransactionType);
    if (this.selectedTransactionType === 'seeking from peer') {
      // this.titleForTransactionModal = "Someone observed a Integrity behavior in you:";
      this.transactionForm.controls['manager_ids'].setValidators(null);
      this.transactionForm.controls['employee_ids'].setValidators([Validators.required]);
      this.transactionForm.controls['employee_ids'].updateValueAndValidity();
      this.transactionForm.controls['manager_ids'].updateValueAndValidity();
    } else if (this.selectedTransactionType === 'sharing with peer') {
      // this.titleForTransactionModal = "You observed a Integrity behavior in:";
      this.transactionForm.controls['manager_ids'].setValidators(null);
      this.transactionForm.controls['employee_ids'].setValidators([Validators.required]);
      this.transactionForm.controls['employee_ids'].updateValueAndValidity();
      this.transactionForm.controls['manager_ids'].updateValueAndValidity();
    } else if (this.selectedTransactionType === 'self') {
      // this.titleForTransactionModal = "Seek Recommendation from Manager";
      this.transactionForm.controls['employee_ids'].setValidators(null);
      this.transactionForm.controls['manager_ids'].setValidators([Validators.required]);
      this.transactionForm.controls['employee_ids'].updateValueAndValidity();
      this.transactionForm.controls['manager_ids'].updateValueAndValidity();
    }
  }

  getEmployees() {
    // to get employees
    const optionList = (functionName: any) => {
      this.isEmployeeOptionListLoading = true;
      let rr = new CampaignEmployeesRetrieveRequest(functionName);
      // rr.campaign_item_id= this.selectedCampaignItemId;
      rr.emp_id = this.loggedInUserDetail?.employee?.id;
      rr.campaign_id = this.selectedCampaignId;
      rr.txn_type = this.selectedTransactionType;
      return this.appService.getCampaignEmployees(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };
    const optionList$: Observable<CampaignEmployees[]> = this.employeeOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.employeeOptionList = data;
      this.isEmployeeOptionListLoading = false;
    });
  }

  setCardIndex(index: number) {
    this.selectedCardIndex = index;
  }

  setCollapsePanelIndex(collapseIndex: number) {
    this.selectedCollapsePanelIndex = collapseIndex;
  }

// campaign Dropdown change related
// onCampaignChange() {
//   this.selectedCampaignId = this.transactionForm.get('campaign_item_id').value;
//   console.log("selectedCampaignId", this.selectedCampaignId);
  // finding tech level last label
  // let result= this.campaignDetails.find(x => x.id === this.selectedCampaignId);
  // this.selectedTechLevelLabel= result.level_names[result.level_names.length-1];

// }
// Get List of Campaigns
// getCampaigns() {
//   this.appService.getCampaigns().subscribe(res => {
//     this.campaignDetails = res['items'];
//   });
// }

  getCampaignItemsTree() {
    // this.appService.getCampaignItemsTree()
    this.appService.get('/api/campaign-items/load-tree/' + this.selectedCampaignId).subscribe(res => {
      this.campaignItemsTree = res;
      this.getTreeTableData();
    });
  }

  //Converting data into tree table
  getTreeTableData() {
    this.listOfMapData = [];
    this.campaignItemsTree.forEach((p: any, i) => {
      let level_1 = (i + 1).toString();
      this.listOfMapData.push({
        key: level_1,
        levelType: 1,
        campaign_id: p.campaign_id,
        campaign_item_name: p.campaign_item_name,
        id: p.id,
        image: p.image,
        image_id: p.image_id,
        image_url: null,
        item_type: p.item_type,
        parent_item_id: p.parent_item_id,
      });
      if (p.children) {
        if (p.children.length > 0) {
          this.listOfMapData[i].children = [];
          p.children.forEach((c: any, j) => {
            let level_2 = (level_1 + '-' + (j + 1));
            this.listOfMapData[i]['children'].push({
              key: level_2,
              levelType: 2,
              campaign_id: c.campaign_id,
              campaign_item_name: c.campaign_item_name,
              id: c.id,
              image: c.image,
              image_id: c.image_id,
              item_type: c.item_type,
              image_url: c?.image?.original_image_url,
              parent_item_id: c.parent_item_id,
            });
            if (c.children) {
              if (c.children.length > 0) {
                this.listOfMapData[i]['children'][j].children = [];
                c.children.forEach((n: any, k) => {
                  let level_3 = (level_2 + '-' + (k + 1));
                  this.listOfMapData[i]['children'][j]['children'].push({
                    key: level_3,
                    levelType: 3,
                    campaign_id: n.campaign_id,
                    campaign_item_name: n.campaign_item_name,
                    id: n.id,
                    image: n.image,
                    image_id: n.image_id,
                    item_type: n.item_type,
                    image_url: c?.image?.original_image_url,
                    parent_item_id: n.parent_item_id,
                  });
                  if (n.children) {
                    if (n.children.length > 0) {
                      this.listOfMapData[i]['children'][j]['children'][k].children = [];
                      n.children.forEach((o: any, l) => {
                        let level_4 = (level_3 + '-' + (l + 1));
                        this.listOfMapData[i]['children'][j]['children'][k]['children'].push({
                          key: level_4,
                          levelType: 4,
                          campaign_id: o.campaign_id,
                          campaign_item_name: o.campaign_item_name,
                          id: o.id,
                          image: o.image,
                          image_id: o.image_id,
                          item_type: o.item_type,
                          image_url: c?.image?.original_image_url,
                          parent_item_id: o.parent_item_id,
                        });

                      });
                    }
                  }
                });
              }
            }

          });

        }
      }

    });
    this.listOfMapData.forEach(item => {
      this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
    });
    // console.log('listOfMapData', this.listOfMapData);
    // this.isShowTbl = !this.isShowTbl;

  }
}
