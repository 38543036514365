<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="d-flex justify-content-center">Activity Log</h3>

        <div class="filter" *ngIf="userType=='tenant_admin'">
          <form nz-form>
            <nz-form-item>
              <nz-row>
                <nz-col nzSpan="24">

                  <input
                    placeholder="Search for a Username"
                    nz-input
                    [(ngModel)]="inputValue"
                    (input)="onInput($event)"


                    [nzAutocomplete]="auto"
                    [formControl]="filterControl"
                  />
                  <!--                (keyup)="onKeyUp($event)"-->
                  <!--              [nzDataSource]="options"-->
                  <!--    (mouseEntered)="optionSelectionChange(data)"-->
                  <nz-autocomplete nzBackfill #auto>

                    <nz-auto-option *ngFor="let data of activities | async" [nzValue]="data?.user_name"
                                    (click)="onUserSelect(data)" (keydown.enter)="onUserSelect(data)"   >
                      {{data?.user_name}}
                    </nz-auto-option>

                  </nz-autocomplete>
                </nz-col>
              </nz-row>


              <!--              <input type="text" placeholder="Search for a Username" [formControl]="filterControl"-->
              <!--                     [autocomplete]="auto" (keyup)="onKeyUp($event)">-->
              <!--              <nz-autocomplete #auto>-->
              <!--                <nz-auto-option *ngFor="let data of activities | async" [nzValue]="data?.user_name"-->
              <!--                                (click)="onUserSelect(data)" (mouseEntered)="optionSelectionChange(data)">-->
              <!--                  {{data?.user_name}}-->
              <!--                </nz-auto-option>-->
              <!--              </nz-autocomplete>-->
            </nz-form-item>
          </form>

        </div>

        <nz-table nzBordered nzShowSizeChanger [nzPageSize]="pageSize" [nzPageIndex]="pageIndex">
          <thead>
          <tr>
            <th class="col-2">Date/Time</th>
            <th class="col-1">User ID</th>
            <th class="col-2">Username</th>
            <th class="col-2">Requested Resource</th>
            <th class="col-2">Source IP Address</th>
            <th class="col-1">Browser</th>
            <th class="col-4">Activity</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of activityData">
            <td>{{data.datetimestamp}}</td>
            <td>{{data.user_id}}</td>
            <td>{{data.username}}</td>
            <td>{{data.req_resource}}</td>
            <td>{{data.src_ip}}</td>
            <td>{{data.browser}}</td>
            <td>{{data.activity}}</td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
