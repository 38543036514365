import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;
import {
  BusinessUnit,
  BusinessUnitRetrieveRequest,
  Department,
  DepartmentRetrieveRequest,
  FunctionRetrieveRequest,
  GradeRetrieveRequest,
  HybridDrillDownRetrieveRequest,
  LocationRetrieveRequest,
  RoleRetrieveRequest
} from 'src/app/app.models';
@Component({
  selector: 'app-quick-three-sixty-dashboard',
  templateUrl: './quick-three-sixty-dashboard.component.html',
  styleUrls: ['./quick-three-sixty-dashboard.component.css']
})
export class QuickThreeSixtyDashboardComponent implements OnInit {

  activityId:any;
  fLForm: FormGroup;
  dLForm:FormGroup;
  employeeAdminSetup: any;
  loggedInUserDetail: any;
  storageAccount: any;
  activityName:any;

  chart1Data: any;
  chart2Data: any;

  // Charts loading
  isLoading1: boolean;
  isLoading2: boolean;
  isLoading3: boolean;
  isLoading4: boolean;
  isLoadingP: boolean;
  feedBackList = [];


  type:any;
  enableChart = false;
  // Filter keys
  functionOptionList: Function[] = [];
  isFunctionOptionListLoading = false;

  departmentOptionList: Department[] = [];
  isDepartmentOptionListLoading = false;

  locationOptionList: Location[] = [];
  isLocationOptionListLoading = false;

  bUOptionList: BusinessUnit[] = [];
  isBusinessUnitOptionListLoading = false;
  roleListOption: any;
  gradeListOption: any;
  assessId;
  constructor(private activatedRoute: ActivatedRoute, 
              private router: Router, 
              private appService: AppService,
              private fb: FormBuilder,
              public message: NzMessageService) {
  }
  selectedFilter :any;
  assessmentGroupBy: any = {'label': 'Department', 'value': 'department', 'is_select': true};
  groupingList = [
    {'label': 'Bussiness Unit', 'value': 'business_unit', 'is_select': false},
    {'label': 'Location', 'value': 'location', 'is_select': false},
    {'label': 'Function', 'value': 'function', 'is_select': false},
    {'label': 'Department', 'value': 'department', 'is_select': true},
    {'label': 'Role', 'value': 'role', 'is_select': false},
    {'label': 'Grade', 'value': 'grade', 'is_select': false},
  ];
  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;

    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.activityId = params['id'];
      }
      this.getAdminFilters();

      if (!this.employeeAdminSetup?.role.length) {
        this.getRoleList();
      }

      if (!this.employeeAdminSetup?.grade.length) {
        this.getGradeList();
      }

      if (!this.employeeAdminSetup?.business_unit.length) {
        this.getBUs();
      }
      if(this.loggedInUserDetail.user_type == 'tenant_admin' || this.loggedInUserDetail.user_type == 'admin'){
        this.enableChart = false;
        this.getCardsCount();
        this.setHybridChartDashboard();
      }else{
        this.enableChart = true;
        this.getCardsCountUser()
        this.setBaloonChartDashboard();
      }
      this.appService.getQuick360Report().subscribe((res:any) => {
        let feedRoleList = res.filter(item=>(item.id == this.activityId)) 
        this.activityName = feedRoleList[0].activity_title;
        feedRoleList[0]?.feedback_roles.forEach((element,index) => {
          let data = {
            'id': index,
            'name': element
          };
          this.feedBackList.push(data);
        });
        // console.log('feedRoleList',this.feedBackList);
        // this.feedBackList = data.reverse();
      },err=>{
        this.feedBackList = [];
      })
    });
    // Set form for filters
    this.resetCreateModal();
    this.resetFeedbackModal();
  }

  // resetting Form/initialisizing new form
  resetCreateModal(): void {
    this.dLForm = this.fb.group({
      business_unit_ids: [null],
      location_ids: [null],
      function_ids: [null],
      department_ids: [null],
      role_ids: [null],
      grade_ids: [null],
      status: [true]
    });
  }

  resetFeedbackModal() {
    this.fLForm = this.fb.group({
      type: [null]
    });
  }

  getAdminFilters() {
    this.appService.getEmployeeAdminFilterById(this.loggedInUserDetail?.employee.id).subscribe(async (res: any) => {
      // console.log("The is admin result is : ", res);
      this.employeeAdminSetup = res;
      await this.getBUsById(res.business_unit);
      await this.getLocationsById(res.location);
      await this.getFunctionsById(res.function);
      await this.getDepartmentsById(res.department);
      await this.getRolesById(res.role);
      await this.getGradesById(res.grade);

    });
  }

  getBUsById(buIds) {
    let rr = new BusinessUnitRetrieveRequest();
    rr.ids = buIds;
    this.appService.getBusinessUnits(rr).subscribe(res => {
      this.bUOptionList = res['items'];
    });
  }

  getLocationsById(locationIds) {
    let rr = new LocationRetrieveRequest();
    rr.ids = locationIds;
    this.appService.getLocations(rr).subscribe(res => {
      this.locationOptionList = res['items'];
    });
  }

  getFunctionsById(functionIds) {
    let rr = new FunctionRetrieveRequest();
    rr.ids = functionIds;
    this.appService.getFunctions(rr).subscribe(res => {
      this.functionOptionList = res['items'];
    });
  }

  getGradesById(gradeIds) {
    let rr = new GradeRetrieveRequest();
    rr.ids = gradeIds;
    this.appService.getGrades(rr).subscribe(res => {
      this.gradeListOption = res['items'];
    });
  }

  getRolesById(roleIds) {
    let rr = new RoleRetrieveRequest();
    rr.ids = roleIds;
    this.appService.getRoles(rr).subscribe(res => {
      this.roleListOption = res['items'];
    });
  }

  getDepartmentsById(departmentids) {
    let rr = new DepartmentRetrieveRequest();
    rr.ids = departmentids;
    this.appService.getDepartments(rr).subscribe(res => {
      this.departmentOptionList = res['items'];
    });
  }

  // Get Functions
  getDepartments(functionId) {
    this.isDepartmentOptionListLoading = true;
    let rr = new DepartmentRetrieveRequest();
    rr.function_ids = functionId;
    this.appService.getDepartments(rr).subscribe((res) => {
      this.departmentOptionList = res['items'];
      this.isDepartmentOptionListLoading = false;
    });
  }

  onLocationOpened(isOpened) {
    if (!isOpened || this.employeeAdminSetup?.location.length) {
      return;
    }
    this.getLocations(this.dLForm.value.business_unit_ids);
  }

  onFunctionOpened(isOpened) {
    if (!isOpened || this.employeeAdminSetup?.function.length) {
      return;
    }
    this.getFunctions(this.dLForm.value.business_unit_ids);
  }

  onDepartmentOpened(isOpened) {
    if (!isOpened || this.employeeAdminSetup?.department.length) {
      return;
    }

    this.getDepartments(this.dLForm.value.function_ids);
  }

  // Setting Roles from API
  getRoleList() {
    this.appService.getRoles().subscribe(data => {
      this.roleListOption = data['items'];
    });
  }

  // Setting Grades from API
  getGradeList() {
    this.appService.getGrades().subscribe(data => {
      this.gradeListOption = data['items'];
    });
  }

  // Get Bussiness Unit
  getBUs() {
    let rr = new BusinessUnitRetrieveRequest();
    this.appService.getBusinessUnits(rr).subscribe((res) => {
      this.bUOptionList = res['items'];
    });
  }

  // get Locations
  getLocations(businessUnitId) {
    this.isLocationOptionListLoading = true;
    let rr = new LocationRetrieveRequest();
    //console.log("Th bussiness Unit Ids : ", businessUnitId)
    rr.business_unit_ids = businessUnitId;
    this.appService.getLocations(rr).subscribe((res) => {
      this.locationOptionList = res['items'];
      this.isLocationOptionListLoading = false;
    });
  }

  // Get Functions
  getFunctions(businessUnitId) {
    this.isFunctionOptionListLoading = true;
    let rr = new FunctionRetrieveRequest();
    rr.business_unit_ids = businessUnitId;
    this.appService.getFunctions(rr).subscribe((res) => {
      this.functionOptionList = res['items'];
      this.isFunctionOptionListLoading = false;
    });
  }
  


  cardsData
  getCardsCount() {
    this.appService.getQuick360DashboardCard(this.activityId).subscribe((res: any) => {
      this.cardsData = res;
    });

  }
  cardsDataUser
  getCardsCountUser() {
    this.appService.getQuick360DashboardCardsUser(this.activityId).subscribe((res: any) => {
      this.cardsDataUser = res;
    });

  }
  
  back(){
    this.router.navigate(['report/quick-360']);
  }

  getChartBehavDispByEmp(data) {
    let chart = am4core.create('baloonChart', am4plugins_forceDirected.ForceDirectedTree);
    chart.legend = new am4charts.Legend();
    let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    networkSeries.data = data;
    networkSeries.dataFields.linkWith = 'linkWith';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'name';
    networkSeries.dataFields.value = 'value';
    networkSeries.dataFields.children = 'children';
    networkSeries.nodes.template.tooltipText = '{name}';
    networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.nodes.template.label.text = '{name}';

    networkSeries.fontSize = 8;
    networkSeries.maxLevels = 2;
    networkSeries.maxRadius = am4core.percent(6);
    networkSeries.manyBodyStrength = -16;
    networkSeries.nodes.template.label.hideOversized = true;
    networkSeries.nodes.template.label.truncate = true;
  }
  isGroupingModalOpen = false;
  handleGroupCancel() {
    this.isGroupingModalOpen = false;
  }

  handleGroupOk() {
    this.isGroupingModalOpen = false;
    this.groupingList.forEach(element => {
      if (element['is_select'] == true) {
        this.assessmentGroupBy = element;
        // this.getDeptWiseBehav(this.shareType, this.startDate.slice(0, 10), this.endDate.slice(0, 10), this.campaignId, this.timePeriod, this.campaignDetail.level_names.length);
      }
    });
    this.setHybridChartDashboard();
  }
  checkedChoice(group) {
    this.groupingList.forEach(element => {
      if (element['value'] == group.value) {
        element['is_select'] = true;
      } else {
        element['is_select'] = false;
      }
    });
  }
  openGroupingModal() {
    this.isGroupingModalOpen = true;
  }
  FeedBackTypeModal = false;
  openFeedBackTypeModal() {
    this.fLForm.patchValue({
      type: null
    });
    this.FeedBackTypeModal = true;
  }
  openFeedBackTypeModalCancel(){
    this.FeedBackTypeModal = false;
  }
  openFeedBackTypeModalOk() {
    // if (this.fLForm.value.type != null) {
      this.type = this.fLForm.value.type;
      this.setHybridChartDashboard();
    // }

    this.FeedBackTypeModal = false;
  }

  onFeedbackTypeChange(isOpened) {
    if (!isOpened) {
      return;
    }
    this.feedBackList;
  }

  isFilterModalOpen = false;
  openFilterModal() {
    this.isFilterModalOpen = true;
    this.dLForm.setValue({
      business_unit_ids: null,
      function_ids: null,
      location_ids: null,
      department_ids: null,
      role_ids: null,
      grade_ids: null,
      status: true
    });
  }

  handleFilterOk() {

    const filters = {
      business_unit_ids: this.dLForm.value.business_unit_ids ? this.dLForm.value.business_unit_ids : [],
      function_ids: this.dLForm.value.function_ids ? this.dLForm.value.function_ids : [],
      location_ids: this.dLForm.value.location_ids ? this.dLForm.value.location_ids : [],
      department_ids: this.dLForm.value.department_ids ? this.dLForm.value.department_ids : [],
      role_ids: this.dLForm.value.role_ids ? this.dLForm.value.role_ids : [],
      grade_ids: this.dLForm.value.grade_ids ? this.dLForm.value.grade_ids : [],
    };
    if (this.employeeAdminSetup) {
      if (this.employeeAdminSetup?.business_unit?.length && !this.dLForm.value.business_unit_ids?.length
        || this.employeeAdminSetup?.location?.length && !this.dLForm.value.location_ids?.length
        || this.employeeAdminSetup?.function?.length && !this.dLForm.value.function_ids?.length
        || this.employeeAdminSetup?.department?.length && !this.dLForm.value.department_ids?.length
        || this.employeeAdminSetup?.role?.length && !this.dLForm.value.role_ids?.length
        || this.employeeAdminSetup?.grade?.length && !this.dLForm.value.grade_ids?.length) {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Select At least one filter that is allowed to you by the Admin.',
        });
        return;
      }

    }
    this.selectedFilter = filters;
    this.setHybridChartDashboard();
    this.isFilterModalOpen = false;
  }
  setHybridChartDashboard() {
    this.isLoading4 = true;
    let rr = new HybridDrillDownRetrieveRequest();
    rr.business_unit_ids = this.selectedFilter?.business_unit_ids ? this.selectedFilter?.business_unit_ids : [];
    rr.function_ids = this.selectedFilter?.function_ids ? this.selectedFilter?.function_ids : [];
    rr.location_ids = this.selectedFilter?.location_ids ? this.selectedFilter?.location_ids : [];
    rr.department_ids = this.selectedFilter?.department_ids ? this.selectedFilter?.department_ids : [];
    rr.role_ids = this.selectedFilter?.role_ids ? this.selectedFilter?.role_ids : [];
    rr.grade_ids = this.selectedFilter?.grade_ids ? this.selectedFilter?.grade_ids : [];
    rr.group_by = this.assessmentGroupBy['value'] ? this.assessmentGroupBy['value'] : 'department';
    rr.type = this.type;
    this.appService.getFeedbackDaskhboard(rr, this.activityId).subscribe((res: any) => {
      if (!res.length) {
        const data = {
          isNoData: true,
          data: []
        };
        this.chart1Data = data;
        this.isLoading4 = false;
        return;
      }
      res.sort((a, b) => (a.count > b.count) ? -1 : 1);
      const data = {
        isNoData: false,
        data: res
      };
      this.chart1Data = data;
      this.isLoading4 = false;
      if(!this.chart1Data.isNoData){
        setTimeout(() => {
          this.getChartDeptWiseBehav(this.chart1Data.data, this.assessmentGroupBy['label']);
        }, 2000);
      }
    },err=>{
      const data = {
        isNoData: true,
        data: []
      };
      
      this.chart1Data = data;
      this.isLoading4 = false;
    });

  }

  setBaloonChartDashboard() {
      this.isLoading3 = true;
      this.appService.getBaloonDataDashboard(this.activityId).subscribe((res: any) => {
      if (!res.length) {
        const data = {
          isNoData: true,
          data: []
        };
        this.chart2Data = data;
        this.isLoading3 = false;
        return;
      }
      const data = {
        isNoData: false,
        data: res
      };
      this.chart2Data = data;
      this.isLoading3 = false;
      if(!this.chart2Data.isNoData){
        setTimeout(() => {
          this.getChartBehavDispByEmp(this.chart2Data.data);
        }, 2000);
      }

    },err=>{
      const data = {
        isNoData: true,
        data: []
      };
      
      this.chart2Data = data;
      this.isLoading3 = false;
    });
  }

  handleFilterCancel() {
    this.isFilterModalOpen = false;
  }

  getChartDeptWiseBehav(d, pieLabel) {
    let data = d;

    let chart = am4core.create('chart1', am4core.Container);
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);
    chart.layout = 'horizontal';

    /**
     * Column chart
     */

      // Create chart instance
    let columnChart = chart.createChild(am4charts.XYChart);

    // Create axes
    let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

    // Create series
    let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = 'count';
    columnSeries.dataFields.categoryY = 'category';
    columnSeries.columns.template.strokeWidth = 0;

    let labelXY = columnChart.chartContainer.createChild(am4core.Label);
    // labelXY.text = 'Displayed Behaviours';
    labelXY.align = 'center';
    labelXY.fontWeight = '500';
    labelXY.fontSize = 20;

    /**
     * Pie chart
     */

      // Create chart instance
    let pieChart = chart.createChild(am4charts.PieChart);
    pieChart.data = data;
    pieChart.innerRadius = am4core.percent(50);

    let labelPie = pieChart.chartContainer.createChild(am4core.Label);
    labelPie.text = pieLabel;
    labelPie.align = 'center';
    labelPie.fontWeight = '500';
    labelPie.fontSize = 20;

    // Add and configure Series
    let pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'count';
    pieSeries.dataFields.category = 'status';
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.labels.template.disabled = true;

    // Set up labels
    let label1 = pieChart.seriesContainer.createChild(am4core.Label);
    label1.text = '';
    label1.horizontalCenter = 'middle';
    label1.fontSize = 35;
    label1.fontWeight = '600';
    label1.dy = -30;

    let label2 = pieChart.seriesContainer.createChild(am4core.Label);
    label2.text = '';
    label2.horizontalCenter = 'middle';
    label2.fontSize = 12;
    label2.dy = 20;

    // Auto-select first slice on load
    pieChart.events.on('ready', function(ev) {
      pieSeries.slices.getIndex(0).isActive = true;
    });

    // Set up toggling events
    pieSeries.slices.template.events.on('toggled', function(ev) {
      if (ev.target?.isActive) {

        // Untoggle other slices
        pieSeries.slices.each(function(slice) {
          if (slice != ev.target) {
            slice.isActive = false;
          }
        });

        // Update column chart
        columnSeries.appeared = false;
        columnChart.data = ev.target.dataItem.dataContext['breakdown'];
        columnSeries.fill = ev.target.fill;
        columnSeries.reinit();

        // Update labels
        label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, '#.\'%\'');
        label1.fill = ev.target.fill;

        label2.text = ev.target.dataItem['category'];
      }
    });
  }


}
