import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondToDuration'
})
export class SecondToDurationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value)
    {
      return new Date(1970, 0, 1).setSeconds(<number> value);
    }
    return null;
  }

}
