// ----------------------------------------------------------------------------------------------------------------------------------
// --Modified by: Manuel Robert------------------------------------------------------------------------------------------------------
// --User Story/ Task ID :   Added more types of labelled contents ------------------------------------------------------------------
// --Modified on: 29-10-2021 --------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { LabelledContent, LabelledContentRetrieveRequest } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-labelled-content',
  templateUrl: './labelled-content.component.html',
  styleUrls: ['./labelled-content.component.css']
})
export class LabelledContentComponent implements OnInit {
  loggedInUserDetail: any;
  total = 1;
  loading = false;
  pageSize = 100;
  pageIndex = 1;
  labelledContent: LabelledContent[] = [];
  assessmentId: number;
  selectedType: string;
  selectedTypeForm: string;
  title: string;
  isVisible_image_modal: boolean = false;
  current_image_path: string;
  current_title: string;
  current_description: string;
  isVisible_video_modal: boolean = false;
  // storage account url
  storageAccount: String;

  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private modal: NzModalService,
    private message: NzMessageService,
    private location: Location,
    // private _lightbox: Lightbox
  ) {

  }




  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    this.selectedType = this.location.path().replace('/', '');
    this.selectedTypeForm = this.location.path() + '/form';
    this.setTypeTitle(this.selectedType);
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
    // console.log('stststs', this.storageAccount);
  }


  // close(): void {
  //   // close lightbox programmatically
  //   this._lightbox.close();
  // }

  setTypeTitle(type) {
    switch (type) {
      case 'org-announce':
        this.title = "Organisation Announcement"
        break;
      case 'leader-speak':
        this.title = "Leader's Speak"
        break;
      case 'success-stories':
        this.title = "Success Stories"
        break;
      case 'learn-go':
        this.title = "Learning On the Go"
        break;
      case 'idp':
        this.title = "IDP"
        break;
      case 'succession-planning':
        this.title = "Succession Planning"
        break;
      case 'continous-360-feedback':
        this.title = "Continous 360 Feedback"
        break;
      case 'reflections':
        this.title = "Reflections"
        break;
      case 'bbs-certificate':
        this.title = "Certificates"
        break
      case 'challenges':
        this.title = "Challenges"
        break;
      case 'collab-corner':
        this.title = "CollabCorner"
        break;
      case 'help-videos':
        this.title = "Help Videos"
        break;
      // case 'testlc8':
      //   this.title = "Test LC 8"
      //   break;
      // case 'testlc9':
      //   this.title = "Test LC 9"
      //   break;
      // case 'testlc10':
      //   this.title = "Test LC 10"
      //   break;
      default: break;
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, this.selectedType);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, type: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new LabelledContentRetrieveRequest();
    // rr.assessment_type = "learning_assessment";
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "created_on";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    rr.menu_type = type
    // console.log(rr);
    this.appService.getLabelledContents(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.labelledContent = data['items'];
      // console.log(this.labelledContent)
      // console.log(data['items'])
    });
  }

  public remove(entity: LabelledContent) {
    const index: number = this.labelledContent.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.lc_title + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteLabelledContent(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, this.selectedType);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {


              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }


  showImage(item: any) {
    this.isVisible_image_modal = true;
    this.current_image_path = item?.image?.original_image_url
    this.current_title = item?.lc_title
    this.current_description = item?.lc_description
  }

  handleOk(): void {
    this.isVisible_image_modal = false;
  }

  handleCancel(): void {

    this.isVisible_image_modal = false;
  }

  handleVideoOk(): void {
    this.isVisible_video_modal = false;
    window.location.reload()
  }

  handleVideoCancel(): void {

    this.isVisible_video_modal = false;
    window.location.reload()
  }
  @ViewChild('videoSrc') videoSrc: ElementRef;
  showVideo(item: any) {
    // this.isVisible_image_modal = false;
    this.isVisible_video_modal = true;
    // console.log('hello', item);
    this.current_image_path = this.storageAccount + item?.image?.original_image_url
    // console.log('ttt', this.current_image_path);
    this.current_title = item?.lc_title
    // console.log(this.videoSrc.nativeElement)
    // console.log(this.videoplayer.nativeElement)
    this.videoSrc.nativeElement.src = this.storageAccount + item?.image?.original_image_url
    this.videoplayer.nativeElement.src = this.storageAccount + item?.image?.original_image_url
    this.current_description = item?.lc_description
    this.videoplayer.nativeElement.load();

  }

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  downloading = false
  //Sample Download
  download(e) {
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `${this.storageAccount + e?.image?.document_url}`);
    link.setAttribute('download', `${e.image?.document_url}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;

  }

}
