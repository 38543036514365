<div class="right-sidebar-mini">
    <div class="right-sidebar-panel p-0">
       <div class="iq-card shadow-none">
          <div class="iq-card-body p-0">
             <div class="media-height p-3">
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/01.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Anna Sthesia</a></h6>
                      <p class="mb-0">Just Now1</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/02.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Paul Molive</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/03.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Anna Mull</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/04.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Paige Turner</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/11.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Bob Frapples</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/02.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Barb Ackue</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-online">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/03.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Greta Life</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-away">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/12.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Ira Membrit</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center mb-4">
                   <div class="iq-profile-avatar status-away">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/01.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Pete Sariya</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
                <div class="media align-items-center">
                   <div class="iq-profile-avatar">
                      <img class="rounded-circle avatar-50" src="../../../../assets/images/user/02.jpg" alt="">
                   </div>
                   <div class="media-body ml-3">
                      <h6 class="mb-0"><a href="#">Monty Carlo</a></h6>
                      <p class="mb-0">Admin</p>
                   </div>
                </div>
             </div>
             <div class="right-sidebar-toggle bg-primary mt-3">
                <i class="ri-arrow-left-line side-left-icon"></i>
                <i class="ri-arrow-right-line side-right-icon"><span class="ml-3 d-inline-block">Close Menu</span></i>
             </div>
          </div>
       </div>
    </div>
 </div>