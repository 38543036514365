<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">

        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">List of Forms</h3>

            <div class="d-flex justify-content-between align-items-center">
              <a class="add-btn" routerLink="/forms/add" title="Create New">
                <i class="ri-add-circle-fill"></i>
              </a>
              <div></div>
            </div>

            <nz-table #formsTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total" [nzPageSize]="limit" [nzPageIndex]="page" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="forms">
              <thead>
                <tr>
                  <th class="text-center" [nzSortFn]="sortNameFn">Name</th>
                  <th class="text-center" [nzSortFn]="sortFrequencyFn">Frequency</th>
                  <th class="text-center" [nzSortFn]="sortStatusFn">Status</th>
                  <th class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let form of formsTable.data; let i = index;">
                  <td nzLeft>{{ form.name }}</td>
                  <td>{{ form.frequency }} months</td>
                  <td>
                    <a class="d-flex align-items-center" (click)="toggleStatus(form)" title="Active" *ngIf="form.status === 'inactive'">
                      {{ form.status.toUpperCase() }} <i class="ri-play-circle-line grayIcon ml-2"></i>
                    </a>
                    <a class="d-flex align-items-center" *ngIf="form.status === 'active'">
                      {{ form.status.toUpperCase() }} <i class="ri-rocket-2-line grayIcon ml-2"></i>
                    </a>
                  </td>
                  <td class="text-center">
                    <a class="mr-2" routerLink="/forms/edit/{{ form.id }}" title="Edit">
                      <i class="ri-edit-line grayIcon"></i>
                    </a>
                    <a (click)="removeForm(i, form)" title="Delete">
                      <i class="ri-delete-bin-6-fill text-danger"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
