import {Component, OnInit, ViewChild} from '@angular/core';
import {Competency, CompetencyRetrieveRequest} from 'src/app/app.models';
import {FormBuilder,FormArray, FormGroup, Validators} from '@angular/forms';
import {AppService} from 'src/app/app.service';
import {environment} from 'src/environments/environment';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzUploadFile} from 'ng-zorro-antd/upload';

export interface TreeNodeInterface {
  key: string;
  title: string;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
  mydata?: any;
}

@Component({
  selector: 'app-competency',
  templateUrl: './competency.component.html',
  styleUrls: ['./competency.component.css']
})
export class CompetencyComponent implements OnInit {


  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) {
  }

  private treeFlag: boolean;
  listOfMapData: TreeNodeInterface[] = [];

  // private tempCompetency: Competency[];

  total = 1;
  isImportFormVisible = false;
  fileUploading = false;
  downloading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  // These are the Competencies which are displayed in the list screen.
  competencies: Competency[] = [];
  // This is the competency being edited.
  competencyId: number;
  // For Adding tag values
  tagValue = [];

  loading = true;
  pageSize = 100;
  pageIndex = 1;

  radioValue;
  radioFileds: any;
  inputTag = '';

  // The create / edit form shown inside the modal.
  competencyForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;
  competancyOptionList: any;
  levelZeroId: any;


  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};


  showCreateModal(): void {
    this.resetCreateModal();
    this.tagValue = [];
    this.action = 'create';
    this.isFormVisible = true;
    this.addNewDesc()
  }

  addNewDesc() {
    const description = this.competencyForm.get('description') as FormArray;
    description.push(this.fb.group({
      description: [null, [Validators.required]],
    }));
  }
  removeDesc(index, ee) {
    // console.log('ee',ee)
    if(ee.description['value'] != null){
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ?',
        nzOnOk: () =>{
          const descriptionArray = this.competencyForm.get('description') as FormArray;
          descriptionArray.removeAt(index);
        }
      })
    }else{
      const descriptionArray = this.competencyForm.get('description') as FormArray;
      descriptionArray.removeAt(index);
    }

  }

  showEditModal(entityId: number): void {
    this.competencyId = entityId;
    this.getCompetencyById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getCompetencyById(id) {
    this.appService.get<any>('/api/competencies/' + id).subscribe(res => {
      if(res.description?.length == 0){
        this.addNewDesc();
      }else{
        this.patchDescription(res.description);
      }
      this.competencyForm.patchValue(res?.competency);
      this.radioValue = res.competency.level_id;
      this.tagValue = res.competency.keywords;
      if (res.competency.level_id === this.levelZeroId) {
        this.competencyForm.get('parent_id').disable();
      }
    });
  }

  patchDescription(ele){
    const descArray = this.competencyForm.get('description') as FormArray;
    ele.forEach(item => {
      descArray.push(this.fb.group({
        description: [item, [Validators.required]],
      }));
    });
  }

  handleInputConfirm() {
    if (this.inputTag && this.tagValue.indexOf(this.inputTag) === -1) {
      this.tagValue = [...this.tagValue, this.inputTag];
    }
    this.inputTag = '';
  }

  handleClose(removedTag: {}): void {
    this.tagValue = this.tagValue.filter(tag => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  handleModalSubmit(): void {
    this.submitForm();

    this.isFormLoading = true;
    let descArray = []
    this.competencyForm.value.description.forEach(desc => {
      descArray.push(desc.description)
    });

    // Create the competency on the server.
    if (this.competencyForm.status === 'VALID') {
      let data = {
        'competency_name': this.competencyForm.value.competency_name,
        ///"is_active": this.competencyForm.value.is_active,
        'level_id': this.radioValue,
        'parent_id': this.competencyForm.value.parent_id,
        'keywords': this.tagValue,
        'description': descArray,
      };
      // console.log(data);

      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        // console.log(data);
        subscriber = this.appService.createCompetency(data);
        message = 'Competency "' + this.competencyForm.controls['competency_name'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateCompetency(data, this.competencyId);
        message = 'Competency "' + this.competencyForm.controls['competency_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;
        this.radioValue = this.levelZeroId;
        this.competencyForm.get('parent_id').disable();

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, []);
        this.resetCreateModal();

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
    this.competencyForm.get('parent_id').disable();
    this.isImportFormVisible =false;
    this.fileList = [];
    this.resetCreateModal();

  }

  resetCreateModal(): void {
    this.radioValue = this.levelZeroId;
    this.competencyForm = this.fb.group({
      competency_name: [null, [Validators.required]],
      parent_id: [null, [Validators.required]],
      description: this.fb.array([]),
    });
    this.competencyForm.get('parent_id').disable();
  }

  submitForm(): void {
    for (const i in this.competencyForm.controls) {
      this.competencyForm.controls[i].markAsDirty();
      this.competencyForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.competencyForm = this.fb.group({
      competency_name: [null, [Validators.required]],
      parent_id: [null, [Validators.required]],
      description: this.fb.array([]),
    });
    this.competencyForm.get('parent_id').disable();

    this.loadDataFromServer(1, 10000, null, null, null, null, null, []);
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['id']) {
        this.showEditModal(queryParams['id']);
      }
    });
    this.appService.getLevelSettings().subscribe((res) => {
      this.radioFileds = res['items'];
      if (this.radioFileds.length) {
        this.levelZeroId = this.radioFileds[0].id;
      }
      this.resetCreateModal();
    });


  }


  levelChanged(index) {
    let parent = null;
    if (index) {
      this.competencyForm.get('parent_id').setValue(null);
      parent = index - 1;
      this.competencyForm.get('parent_id').enable();
      let parentId = this.radioFileds[parent].id;
      this.competancyOptionList = this.competencies.filter(element => element.level_id === parentId);
    } else {
      this.competencyForm.get('parent_id').setValue(null);
      this.competencyForm.get('parent_id').disable();
    }

  }

  public remove(entity: Competency) {
    const index: number = this.competencies.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.competency_name + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteCompetency(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, null, null, []);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {


              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  // onQueryParamsChange(params: NzTableQueryParams): void {
  //   const {pageSize, pageIndex, sort} = params;
  //   const currentSort = sort.find(item => item.value !== null);
  //   const sortField = (currentSort && currentSort.key) || null;
  //   const sortOrder = (currentSort && currentSort.value) || null;
  //   this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null, null, null, []);
  // }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, containsName: string | '', levelId: string | null, parentId: string | null, ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new CompetencyRetrieveRequest();
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    rr.competency_name = containsName;
    rr.level_id = levelId;
    rr.parent_id = parentId;
    rr.ids = ids;

    this.appService.getCompetencies(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.competencies = this.competancyOptionList = data['items'];
      // console.log('competencies', this.competencies);
      // this.tempCompetency = this.competencies;
      this.listOfMapData = [];
      this.processCompetency([...this.competencies]);
      // console.log('processCompData', this.listOfMapData);

      this.listOfMapData.forEach(item => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });

      // console.log('mapOfExpandedData', this.mapOfExpandedData);

    });
  }


  processCompetency(competenciesNew: Competency[]) {
    // console.log('incoming to process ', competenciesNew);
    for (let i = 0; i < competenciesNew.length; i++) {
      const f = competenciesNew[i];
      if (f.parent_id === null || parseInt(f.parent_id, 10) === 0) {
        this.listOfMapData = [...this.listOfMapData, {
          title: f.competency_name,
          key: f.id.toString(),
          expand: false,
          mydata: f,
        }];

        competenciesNew.splice(i, 1);
        i--;
      } else if (this.listOfMapData.length > 0) {
        this.treeFlag = true;
        for (let j = 0; j < this.listOfMapData.length; j++) {
          if (this.treeFlag) {
            this.listOfMapData[j] = this.treeTraversing(i, f, this.listOfMapData[j]);
          } else {
            break;
          }

        }


        if (!this.treeFlag) {
          competenciesNew.splice(i, 1);
          i--;
        }
      }
    }
    if (competenciesNew && competenciesNew.length > 0) {
      this.processCompetency(competenciesNew);
    }


  }

  private treeTraversing(num: number, f: Competency, currentTree: any) {
    // console.log('inside tree traverse', currentTree);

    const g = currentTree;
    if (f.parent_id.toString() === g.key.toString()) {
      this.treeFlag = false;
      if (currentTree.hasOwnProperty('children')) {
        currentTree.children = [...currentTree.children, {
          title: f.competency_name,
          key: f.id.toString(),
          expand: false,
          mydata: f,
        }];
      } else {
        currentTree.children = [{
          title: f.competency_name,
          key: f.id.toString(),
          expand: false,
          mydata: f,
        }];
      }


      currentTree.expanded = true;
      // this.competencies.splice(i, 1);
      return currentTree;
    }
    if (currentTree.hasOwnProperty('children')) {
      for (let i = 0; i < currentTree.children.length; i++) {
        currentTree.children[i] = this.treeTraversing(num, f, currentTree.children[i]);
      }
      return currentTree;
    } else {
      return currentTree;
    }


  }

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({...root, level: 0, expand: false});

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({...node.children[i], level: node.level! + 1, expand: false, parent: node});
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  showImportModal(): void {
    this.isImportFormVisible = true;
  }

  handleImportModalSubmit(): void{
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    this.appService.importCompetency(formData).subscribe((res: any) => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      this.message.create('success', res.message, {
        nzDuration: 5000
      });
    }, error => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }


  downloadSampleCSV(){
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/Competency-BulkUpload-Template.xlsx');
    //link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;
  }

  openInstructionPdf(){
    window.open('../../assets/pdf/competency-instructions.pdf', '_blank');
  }


}
