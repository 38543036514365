<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
              <h3 class="py-4 text-center">List of Grades</h3>
       
              <div class="d-flex justify-content-between align-items-center">
               
                <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
                <div>
                  <button nz-button nzType="primary" (click)="showImportModal()">
                    <span>Import</span>
                  </button>
               </div>
              </div>
              <nz-modal
                [(nzVisible)]="isFormVisible"
                nzTitle="{{action | titlecase}} Grades"
                (nzOnCancel)="handleModalCancel()"
                (nzOnOk)="handleModalSubmit()"
                nzOkText="Save"
                nzOkType="primary"
                [nzOkLoading]="isFormLoading"
                [nzOkDisabled]="!gradeForm.valid"
              >
                <form
                  nz-form
                  [nzLayout]="'vertical'"
                  [formGroup]="gradeForm"
                  (ngSubmit)="submitForm()"
                >
                  <!-- Capture the grade name -->
                  <nz-form-item>
                    <nz-form-label nzRequired>Grade Name</nz-form-label>
                    <nz-form-control nzErrorTip="Grade Name is mandatory!">
                      <nz-input-group  [nzSize]="'large'">
                        <input noWhitespace formControlName="grade_name" nz-input placeholder="Name" (keypress)="alphaNumberOnly($event)"/>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
  
                  <!-- Capture the BU -->
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="business_unit_id" nzNoColon="false">Business Unit</nz-form-label>
                    <nz-form-control nzErrorTip="Business Unit is mandatory!">
                      <nz-input-group [nzSize]="'large'">
                        <nz-select
                         [nzSize]="'large'"
                          nzAllowClear
                          nzShowSearch
                          nzServerSearch
                          formControlName="business_unit_id"
                          (nzOnSearch)="onBuOptionListSearch($event)">
                          <ng-container *ngFor="let o of buOptionList">
                            <nz-option
                              *ngIf="!isBuOptionListLoading"
                              [nzValue]="o.id"
                              [nzLabel]="o.name"
                            ></nz-option>
                          </ng-container>
                          <nz-option
                            *ngIf="isBuOptionListLoading"
                            nzDisabled
                            nzCustomContent
                          >
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item> 
                  <!-- {{gradeForm.value | json}} -->
                </form>
              </nz-modal>
              <nz-modal [(nzVisible)]="isImportFormVisible" nzTitle="Bulk Import" (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleImportModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
              nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

              <h4 style="font-weight: 500;">Instructions</h4>
              <ul>
                <li>Add the name of the grade accoridng to your organization which you want to be configured onto the platform</li>
                <li>Remember to use the same name in other templates wherever Grade is required</li>
              </ul>

              <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
                class="mr-3">
                Sample CSV Download
              </button>
              <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
              </nz-upload>
            </nz-modal>
              <nz-table
                nzBordered
                nzShowSizeChanger
                [nzFrontPagination]="false"
                [nzLoading]="loading"
                [nzTotal]="total"
                [nzPageSize]="pageSize"
                [nzPageIndex]="pageIndex"
                (nzQueryParams)="onQueryParamsChange($event)"
                [nzData]="grades"
              >
                <thead>
                  <tr>
                    <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                    <th nzColumnKey="grade_name" [nzSortFn]="true">Grade Name</th>
                    <th nzColumnKey="business_unit_id" [nzSortFn]="true">Business Unit</th> -->
                   
                    <th>Grade Name</th>
                    <th>Business Unit</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let grade of grades">
                    
                    <td>{{ grade.grade_name }}</td>
                    <td>
                      <a [routerLink]="['/business-unit']" [queryParams]="{id: grade.business_unit_id}">{{ grade.business_unit.name }}</a>
                    </td>
                    <td>
                      <a (click)="showEditModal(grade.id)"><i class="ri-pencil-fill grayIcon"></i></a>
                      <nz-divider nzType="vertical"></nz-divider>
                      <a (click)="remove(grade)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
