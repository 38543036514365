<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">{{ launch.title }}</h3>

            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms/submissions/{{submission.launch_id}}" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div></div>
            </div>

            <nz-modal nzWidth="1200" [(nzVisible)]="showPreviousProgress" nzTitle="Previous Progress for {{ launch.title }}" (nzOnCancel)="previousProgressHandleCancel()" [nzFooter]="modalFooter">
              <nz-table #previousSubmissionsTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="['']" [nzScroll]="{ x: '300px' }">
                <thead>
                  <tr>
                    <ng-container *ngFor="let col of form.schemas">
                      <th class="text-center" style="width:{{col.configurable.width || 100}}px" *ngIf="(
                        (['immediate', 'during_review'].indexOf(col.configurable.fill_when) !== -1) ||

                        (col.configurable.fill_when == 'after_rejection' && statuses.indexOf('rejected') !== -1) ||

                        (col.configurable.fill_when == 'after_approval' && statuses.indexOf('approved') !== -1 && submission.status == 'approved')
                      )">{{ col.label }}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let record of prevRecords[prevProgressIndex]; let i = index;">
                    <ng-container *ngFor="let col of form.schemas">
                      <td *ngIf="(
                        (['immediate', 'during_review'].indexOf(col.configurable.fill_when) !== -1) ||

                        (col.configurable.fill_when == 'after_rejection' && (record['ng-status'] == 'rejected'  || submission.status == 'rejected')) ||

                        (col.configurable.fill_when == 'after_approval' && record['ng-status'] == 'approved' && submission.status == 'approved')
                      )" nz-tooltip [nzTooltipTitle]="col.type == 'date' ? record[col.id || col.label].toString().slice(0,10) : record[col.id || col.label]">
                        <div *ngIf="col.type === 'text'">
                          <input type="text" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true" />
                        </div>
                        <div *ngIf="col.type === 'url'">
                          <input type="url" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true" />
                        </div>
                        <div *ngIf="col.type === 'date'">
                          <nz-date-picker [nzPlaceHolder]="col.configurable.placeholder" [nzPlacement]="bottomLeft" [(ngModel)]="record[col.id || col.label]" [disabled]="true"></nz-date-picker>
                        </div>
                        <div *ngIf="col.type === 'number'">
                          <nz-input-number [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true" class="w-100"></nz-input-number>
                        </div>
                        <div *ngIf="col.type === 'select'">
                          <nz-select [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true">
                            <nz-option *ngFor="let option of col.configurable.options.split(',')" [nzLabel]="option" [nzValue]="option"></nz-option>
                          </nz-select>
                        </div>
                        <div *ngIf="col.type === 'autoInc'">{{ record[col.id || col.label] }}</div>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </nz-table>
              <ng-template #modalFooter>
                <button nz-button nzType="primary" (click)="viewLeftPreviousProgress()" [disabled]="prevProgressIndex == 0">Previous</button>&nbsp;
                <button nz-button nzType="primary" (click)="viewRightPreviousProgress()" [disabled]="prevProgressIndex == prevRecords.length-1">Next</button>
              </ng-template>
            </nz-modal>

            <nz-table #submissionsTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="['']" [nzScroll]="{ x: '300px' }">
              <thead>
                <tr>
                  <ng-container *ngFor="let col of form.schemas">
                    <th class="text-center" style="width:{{col.configurable.width || 100}}px" *ngIf="(
                      (['immediate', 'during_review'].indexOf(col.configurable.fill_when) !== -1) ||

                      (col.configurable.fill_when == 'after_rejection' && statuses.indexOf('rejected') !== -1) ||

                      (col.configurable.fill_when == 'after_approval' && statuses.indexOf('approved') !== -1 && submission.status == 'approved')
                    )">{{ col.label }}</th>
                  </ng-container>
                  <th nzRight class="text-center" style="width:150px">Status</th>
                  <th nzRight class="text-center" style="width:100px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let record of records; let i = index;">
                  <ng-container *ngFor="let col of form.schemas">
                    <td *ngIf="(
                      (['immediate', 'during_review'].indexOf(col.configurable.fill_when) !== -1) ||

                      (col.configurable.fill_when == 'after_rejection' && (record['ng-status'] == 'rejected'  || submission.status == 'rejected')) ||

                      (col.configurable.fill_when == 'after_approval' && record['ng-status'] == 'approved' && submission.status == 'approved')
                    )" nz-tooltip [nzTooltipTitle]="col.type == 'date' ? record[col.id || col.label].toString().slice(0,10) : record[col.id || col.label]">
                      <div *ngIf="col.type === 'text'">
                        <input type="text" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (col.configurable.fill_by != 'employee' && loggedInUserDetail.user_type == 'employee' && !isManager) ||
                          (col.configurable.fill_by != 'manager' && ((loggedInUserDetail.user_type == 'employee' && isManager) || (loggedInUserDetail.user_type == 'tenant_admin' && loggedInUserDetail.employee.is_admin == 'yes')))
                        )" />
                      </div>
                      <div *ngIf="col.type === 'url'">
                        <input type="url" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (col.configurable.fill_by != 'employee' && loggedInUserDetail.user_type == 'employee' && !isManager) ||
                          (col.configurable.fill_by != 'manager' && ((loggedInUserDetail.user_type == 'employee' && isManager) || (loggedInUserDetail.user_type == 'tenant_admin' && loggedInUserDetail.employee.is_admin == 'yes')))
                        )" />
                      </div>
                      <div *ngIf="col.type === 'date'">
                        <nz-date-picker [nzPlaceHolder]="col.configurable.placeholder" [nzPlacement]="bottomLeft" [nzAllowClear]="false" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (col.configurable.fill_by != 'employee' && loggedInUserDetail.user_type == 'employee' && !isManager) ||
                          (col.configurable.fill_by != 'manager' && ((loggedInUserDetail.user_type == 'employee' && isManager) || (loggedInUserDetail.user_type == 'tenant_admin' && loggedInUserDetail.employee.is_admin == 'yes')))
                        )"></nz-date-picker>
                      </div>
                      <div *ngIf="col.type === 'number'">
                        <nz-input-number [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (col.configurable.fill_by != 'employee' && loggedInUserDetail.user_type == 'employee' && !isManager) ||
                          (col.configurable.fill_by != 'manager' && ((loggedInUserDetail.user_type == 'employee' && isManager) || (loggedInUserDetail.user_type == 'tenant_admin' && loggedInUserDetail.employee.is_admin == 'yes')))
                        )" class="w-100"></nz-input-number>
                      </div>
                      <div *ngIf="col.type === 'select'">
                        <nz-select [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (col.configurable.fill_by != 'employee' && loggedInUserDetail.user_type == 'employee' && !isManager) ||
                          (col.configurable.fill_by != 'manager' && ((loggedInUserDetail.user_type == 'employee' && isManager) || (loggedInUserDetail.user_type == 'tenant_admin' && loggedInUserDetail.employee.is_admin == 'yes')))
                        )">
                          <nz-option *ngFor="let option of col.configurable.options.split(',')" [nzLabel]="option" [nzValue]="option"></nz-option>
                        </nz-select>
                      </div>
                      <div *ngIf="col.type === 'autoInc'">{{ record[col.id || col.label] }}</div>
                    </td>
                  </ng-container>
                  <td nzRight class="text-center" style="width:150px">
                    {{ submission.goals[lastSubmissionDate][i][submission.goals[lastSubmissionDate][i].length - 1].toUpperCase() }}
                  </td>
                  <td nzRight class="text-center" style="width:100px">
                    <a (click)="onApprove(record, i)" title="Approve" [class.disabled]="updateStatuses[i]!='submitted'">
                      <i class="ri-checkbox-circle-line"></i>
                    </a>
                    <a class="ml-2" (click)="onReject(record, i)" title="Reject" [class.disabled]="updateStatuses[i]!='submitted'">
                      <i class="ri-close-circle-line"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <div></div>
              <div>
                <div>
                  <button class="ml-3" nz-button [nzType]="'primary'" [nzLoading]="loading" (click)="sentToReSubmission()" *ngIf="submission.status2=='submitted to manager' || submission.status2=='partially rejected by manager' || submission.status2=='rejected by manager'">
                    <span>Re-Submission</span>
                  </button>
                  <button class="ml-3" nz-button [nzType]="'primary'" [nzLoading]="loading" (click)="viewPreviousProgress()" *ngIf="prevRecords.length >= 2">
                    <span>Previous Progress</span>
                  </button>
                  <button class="ml-3" nz-button [nzType]="'primary'" [nzLoading]="loading" (click)="onSubmit()" *ngIf="submission.status=='approved'" [disabled]="today > afterFrequencyDate || submission.status2 == 'approved by manager' || submission.status2 == 'progress updated by manager' || !checkManagersAfterApprovalFields">
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br /><br /><br /><br />
