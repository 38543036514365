import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-spinner';
import { Form, FormRetrieveRequest } from 'src/app/app.models';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-form-submission',
  templateUrl: './form-submission.component.html',
  styleUrls: ['./form-submission.component.css']
})
export class FormSubmissionComponent implements OnInit, OnDestroy {
  private sub: any;

  loading = false;
  today: any = new Date();
  loggedInUserDetail: any;

  form: any = {};
  submission: any = {
    goals: {},
    status: '',
  };
  launchEndDate: any;
  launchId: number;
  launch: any = {};
  progress: any = {};
  records: any[] = [];
  statuses: any[] = [];
  lastSubmissionDate: any;
  afterFrequencyDate: any;
  afterApprovalCols: any[] = [];
  prevRecords: any[] = [];
  showPreviousProgress= false;
  prevProgressIndex = 0;
  
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    public appService: AppService,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private spinner: NgxSpinnerService
  ) {
  }

  getOptions(col): void {
    let optionSource = col.configurable.option_source || '';
    switch(optionSource){
      case 'table':
        let optionTable = col.configurable.options_table || '';
        let optionTableKey = col.configurable.options_table_key || '';
        this.appService.getOptionsTable(optionTable, optionTableKey).subscribe((tblOptions: any) => {
          if(Array.isArray(tblOptions)){
            col.configurable.options = tblOptions.join();
          }
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
        break;
      case 'api':
        let optionApi = col.configurable.options_api || '';
        let optionsApiKey = col.configurable.options_api_key || '';
        this.appService.getOptionsApi(optionApi).subscribe((apiOptions: any) => {
          let newOptions = [];
          for(const apiOption of apiOptions){
            newOptions.push(apiOption[optionsApiKey]);
          }
          if(Array.isArray(newOptions)){
            col.configurable.options = newOptions.join();
          }
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
        break;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loading = true;

    this.loggedInUserDetail = this.appService.getMe();
    this.today = this.datePipe.transform(this.today, 'yyyy-MM-dd');

    this.sub = this.route.params.subscribe(params => {
      this.launchId = params['launch'] || 0;
      this.appService.getFormLaunchById(this.launchId).subscribe((launch: any) => {
        this.launch = launch;
        this.form = launch.form;
        this.launchEndDate = this.datePipe.transform(launch.end_date, 'yyyy-MM-dd');

        let schemas = this.form.schemas || [];
        for(const col of schemas){
          if(col.type == 'select'){
            this.getOptions(col);
          }
          if(col.configurable.fill_when == 'after_approval'){
            let colId = col.id || col.label;
            this.afterApprovalCols.push(colId);
          }
        }

        this.appService.getMySubmissionByLaunchId(this.launchId).subscribe((submission: any) => {
          this.submission = submission;

          if(submission.goals){
            let records = [];
            for(const key in submission.goals){
              if(key !== 'cols'){
                this.lastSubmissionDate = key;
              }
            }

            let dateParts = this.lastSubmissionDate.split('-');
            let lastDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            this.afterFrequencyDate = this.datePipe.transform(lastDate.setMonth(lastDate.getMonth() + this.submission.form.frequency), 'yyyy-MM-dd');

            for(const i in submission.goals[this.lastSubmissionDate]){
              if(!records[i]){
                records[i] = [];
              }
              this.statuses.push(submission.goals[this.lastSubmissionDate][i][submission.goals[this.lastSubmissionDate][i].length - 1]);
              for(const index in submission.goals[this.lastSubmissionDate][i]){
                let value = submission.goals[this.lastSubmissionDate][i][index];
                if(this.today > this.afterFrequencyDate && this.afterApprovalCols.indexOf(submission.goals.cols[index]) !== -1){
                  value = '';
                }
                records[i][submission.goals.cols[index]] = value;
              }
              for(const index in submission.progress[this.lastSubmissionDate][i]){
                let value = submission.progress[this.lastSubmissionDate][i][index];
                if(this.today > this.afterFrequencyDate && this.afterApprovalCols.indexOf(submission.progress.cols[index]) !== -1){
                  value = '';
                }
                records[i][submission.progress.cols[index]] = value;
              }
            }
            if (Object.keys(submission.progress).length >= 3) {
              let dates = [];
              let prevRecords = [];

              for (const key in submission.progress) {
                if (key !== 'cols') {
                  dates.push(key);
                }
              }

              for (const date of dates) {
                for (const i in submission.goals[date]) {
                  if (!prevRecords[i]) {
                    prevRecords[i] = [];
                  }
                  for (const index in submission.goals[date][i]) {
                    let value = submission.goals[date][i][index];
                    prevRecords[i][submission.goals.cols[index]] = value;
                  }
                  for (const index in submission.progress[date][i]) {
                    let value = submission.progress[date][i][index];
                    prevRecords[i][submission.progress.cols[index]] = value;
                  }
                }

                this.prevRecords.push(prevRecords);
                prevRecords = [];
              }
            }
            this.records = records;
          }

          if(!this.submission.id){
            this.addRecord();
          }

          this.spinner.hide();
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message,
          allowOutsideClick: false,
        });
        this.spinner.hide();
        this.loading = false;
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  addRecord(): void {
    if(this.today > this.launchEndDate){
      Swal.fire({
        title: 'Launch is over',
        text: 'You cannot add any records as the launch is over',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Close',
      }).then((result) => {
        if(result.value){

        }
      });
      return;
    }
    if(this.submission.status === 'submitted'){
      Swal.fire({
        title: 'Submission submitted',
        text: 'You cannot add any records as the submission is already submitted',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Close',
      }).then((result) => {
        if(result.value){

        }
      });
      return;
    }

    let i = this.records.length;
    let schemas = this.form.schemas || [];
    for(const col of schemas){
      if(!this.records[i]) this.records[i] = [];

      if(col.type === 'select'){
        this.getOptions(col);
      }

      let defaultValue = col.configurable.default || '';
      if(col.type === 'autoInc'){
        if(col.configurable.default){
          defaultValue += i;
        }else{
          defaultValue = i + 1;
        }
      }
      let colId = col.id || col.label;
      this.records[i][colId] = defaultValue;
    }
  }

  deleteRecord(index): void {
    if(this.submission.status === 'submitted'){
      return;
    }
    this.records.splice(index, 1);
  }

  onSave(): void {
    let status2 = 'saved';
    if(this.submission.status === 'submitted'){
      return;
    }
    if(this.submission.status != 'submitted'){
      status2 = this.submission.status;
    }
    this.postData('saved', status2);
  }

  onSubmit(): void {
    let status2 = '';
    if(!this.submission.goals){
      this.submission.goals = {};
    }
    for(const i in this.records){
      for(const key in this.records[i]){
        for(const schema of this.form.schemas){
          if(schema.id === key || schema.label === key) {
            let isRequired = schema.configurable.required || false;
            if((
              (['employee'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'immediate') ||

              (['employee'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'during_review' && (this.statuses.indexOf('approved') !== -1 || this.statuses.indexOf('rejected') !== -1)) ||

              (['employee'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_rejection' && (this.statuses.indexOf('approved') !== -1 || this.statuses.indexOf('rejected') !== -1)) ||

              (['employee'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_approval' && this.statuses.indexOf('approved') !== -1 && this.submission.status == 'approved')
            )){
              if((['employee'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_approval' && (this.statuses.indexOf('approved') !== -1 || this.statuses.indexOf('rejected') !== -1))) {
                status2 = 'progress updated';
              }
              if(isRequired && !this.records[i][key]){
                Swal.fire({
                  title: 'Required',
                  text: `${schema.label} cannot be empty`,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: 'Close',
                }).then((result) => {
                  if(result.value){
          
                  }
                });
                return;
              }
            }
          }
        }
      }
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once you submit you cannot make any changes, Do you still want to continue with submission',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.isConfirmed) {
          let status = 'submitted';
          if(status2 != 'progress updated'){
            status2 = 'submitted to manager';
          }
          if(this.submission.status == 'approved'){
            status = this.submission.status;
          }
          if(this.submission.status == 'rejected'){
            status2 = 're-submitted';
          }
          if(this.submission.status == 'approved' && status2 == 'progress updated') {
            status2 == 'progress updated';
          }
          this.postData(status, status2);
        }
      });
  }

  postData(status, status2): void {
    if(this.today > this.launchEndDate){
      Swal.fire({
        title: 'Launch is over',
        text: 'You cannot update your submission as the launch is over',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Close',
      }).then((result) => {
        if(result.value){

        }
      });
      return;
    }

    let formData = {
      form_id: this.form.id,
      launch_id: this.launchId,
      status: status,
      status2: status2,
    };

    if(this.today <= this.afterFrequencyDate){
      this.today = this.lastSubmissionDate;
    }

    if(!this.submission.goals){
      this.submission.goals = {};
    }
    this.submission.goals[this.today] = [];
    for(const i in this.records){
      this.submission.goals[`cols`] = [];
      const ogStatus = this.records[i]['ng-status'];
      for(const key in this.records[i]){
        for(const schema of this.form.schemas){
          let colId = schema.id || schema.label;
          if(colId == key){
            if(schema.configurable.fill_when != 'after_approval'){
              this.submission.goals.cols.push(key);
              if(!this.submission.goals[this.today][i]){
                this.submission.goals[this.today][i] = [];
              }
              this.submission.goals[this.today][i].push(this.records[i][key]);
            }
          }
        }
      }
      if(this.submission.goals.cols.indexOf('ng-status') === -1){
        this.submission.goals.cols.push('ng-status');
      }

      if(this.submission.goals.cols.length != this.submission.goals[this.today][i].length){
        if(ogStatus != 'approved'){
          this.submission.goals[this.today][i].push(status);
        }else{
          this.submission.goals[this.today][i].push(ogStatus);
        }
      }
    }
    formData['goals'] = this.submission.goals;

    if(!this.submission.progress){
      this.submission.progress = {};
    }
    this.submission.progress[this.today] = [];
    for(const i in this.records){
      this.submission.progress[`cols`] = [];
      const opStatus = this.records[i]['ng-status'];
      for(const key in this.records[i]){
        for(const schema of this.form.schemas){
          if(schema.id === key) {
            if(schema.configurable.fill_when == 'after_approval'){
              this.submission.progress.cols.push(key);
              if(!this.submission.progress[this.today][i]){
                this.submission.progress[this.today][i] = [];
              }
              this.submission.progress[this.today][i].push(this.records[i][key]);
            }
          }
        }
      }
      if(this.submission.progress.cols.indexOf('ng-status') === -1){
        this.submission.progress.cols.push('ng-status');
      }
      if(!this.submission.progress[this.today][i]){
        this.submission.progress[this.today][i] = [];
      }
      if(this.submission.progress.cols.length != this.submission.progress[this.today][i].length){
        if(opStatus != 'approved'){
          this.submission.progress[this.today][i].push(status);
        }else{
          this.submission.progress[this.today][i].push(opStatus);
        }
      }
    }
    formData['progress'] = this.submission.progress

    this.spinner.show();
    if(!this.submission.id){
      this.appService.saveFormSubmission(formData).subscribe((res: any) => {
        this.submission = res;
        let message = 'Data Submitted';
        if(status == 'saved'){
          message = 'Form Saved';
        }
        if(status == 'submitted'){
          message = 'Form Submitted';
        }
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.spinner.hide();
        if(status != 'saved'){
          this.router.navigate(['forms/mine']);
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message,
          allowOutsideClick: false,
        });
        this.spinner.hide();
        this.loading = false;
      });
    }else{
      this.appService.updateFormSubmission(this.submission.id, formData).subscribe((res: any) => {
        let message = 'Data Submitted';
        if(status == 'saved'){
          message = 'Form Saved';
        }
        if(status == 'submitted'){
          message = 'Form Submitted';
        }
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.spinner.hide();
        if(status != 'saved'){
          this.router.navigate(['forms/mine']);
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message,
          allowOutsideClick: false,
        });
        this.spinner.hide();
        this.loading = false;
      });
    }
  }

  viewPreviousProgress(): void {
    this.showPreviousProgress = true;
  }

  previousProgressHandleCancel(): void {
    this.showPreviousProgress = false;
  }

  viewLeftPreviousProgress(): void {
    this.prevProgressIndex--;
  }

  viewRightPreviousProgress(): void {
    this.prevProgressIndex++;
  }

}

