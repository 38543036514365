import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  @Input() userName: any;
  policyHtml = '';
  key = ['privacy-policy'];

  constructor(public fb: FormBuilder,
    public appService: AppService,
    public router: Router,
    public message: NzMessageService,
    public datepipe: DatePipe,) { }

  ngOnInit(): void {

    this.appService.getPublicSettings(this.key, this.userName).subscribe(res => {
      this.policyHtml = res['items'][0].value
    })
  }

}
