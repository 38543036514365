import { environment } from 'src/environments/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgxSpinnerService} from 'ngx-spinner';
import * as FileSaver from 'file-saver';




@Component({
  selector: 'app-list-of-campaigns',
  templateUrl: './list-of-campaigns.component.html',
  styleUrls: ['./list-of-campaigns.component.css']
})
export class ListOfCampaignsComponent implements OnInit {
  loggedInUserDetail: any;
  campaignList: any;
  isAllChecked = false;
  reportOkDisabled=true;
  popup = false;
  loading = false;
  listOfCampaigns:  ReadonlyArray<any> = [];
  setofCheckedCampaigns=new Set<number>();
  selected:Array<number>=[];
  campaigsData: any;
  constructor(private appService: AppService,
    public message: NzMessageService,
    private modal: NzModalService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.getCampaigns();
  }

  // Get List of Campaigns
  getCampaigns() {
    this.loading = true;
    this.appService.getCampaigns().subscribe(res => {
      this.campaignList = res['items'];
      this.loading = false;
      
    })
  }
  downloadExelPopup() {
    this.popup = true;
   
  }
  onAllChecked(e) {
    this.listOfCampaigns.forEach((item) => {
     
        this.updateCheckedSet(item.id, e)
   
    }
    );
    this.refreshCheckedStatus();
  }
  onCurrentPageDataChange($event: ReadonlyArray<any>): void {
    this.listOfCampaigns = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    if(this.selected.length!=0){
      this.isAllChecked = this.listOfCampaigns.every((item) =>
      this.setofCheckedCampaigns.has(item.id)
      
    );
    }
   
    
    
   
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setofCheckedCampaigns.add(id);
      this.selected.push(id);
      
    } else {
      this.setofCheckedCampaigns.delete(id);
      this.selected.splice(this.selected.indexOf(id),1);
      
    }
  }

  handleModalCancel() {
    this.popup = false;
  }
  handleModalOk() {
   

    if(this.selected.length!=0){
      let data={
        campaign_ids:this.selected
      }
      this.spinner.show();
      this.popup=false;
      this.selected=[];
      this.appService.getTransactionReport(data).subscribe((res :Blob)=>{
        
        
        var file = new Blob([res], { type: 'application/octet-stream' })
        
        // var fileURL = URL.createObjectURL(file);
        FileSaver.saveAs(file, 'report.xlsx');
        // window.open(fileURL);
        this.spinner.hide();
      }
      ,error=>{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong ',
        })
        this.spinner.hide();
      }
      )
    }

  }

  public remove(data) {
    this.modal.confirm({
      nzTitle: 'Confirm Delete',
      nzContent: 'Are you sure, you want to delete?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          let subscriber = null;

          subscriber = this.appService.deleteCampaign(data.id);
          subscriber.subscribe(res => {

            this.message.create('success', 'Record Deleted', {
              nzDuration: 5000
            });
            this.getCampaigns();
            resolve(res);
          }, error => {


            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });

            reject();
          })

        }).catch(() => console.log('Oops errors!'))
    });
  }


}
