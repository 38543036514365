import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
interface ItemData {
  title: string;
  keyword: Array<[]>;
  description: string;
}
@Component({
  selector: 'app-lag-indication',
  templateUrl: './lag-indication.component.html',
  styleUrls: ['./lag-indication.component.css']
})
export class LagIndicationComponent implements OnInit {

  loading = false;
  total = 10;
  pageSize = 100;
  pageIndex = 1;
  lagIndicationForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;
  btnName;
  radioValue = "Active"
  inputTag = '';
  tagValue = [];
  lagData:ItemData[] = [];

constructor(private fb: FormBuilder,
  private activatedRoute: ActivatedRoute,
  private appService: AppService,
  private message: NzMessageService,
  private modal: NzModalService) { }
  resetCreateModal(): void {
    this.lagIndicationForm = this.fb.group({
      title: [null, [Validators.required]],
      // parent_id: [null, [Validators.required]],
      description:[null]
    });
  }
  ngOnInit(): void {
    this.resetCreateModal()
    this.getAllLagIndicators()
  }

  getAllLagIndicators(){
    this.loading =true;
    this.appService.getAllLagIndicator().subscribe(data => {
      // console.log('data',data)
      this.lagData = data.reverse()
      this.loading =false;
  },err=>{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: err.error.message
    });
  });
  }

  showEditModal(entity): void {
    this.action = 'Edit';
    this.btnName = 'Update';
    this.isFormVisible = true;
    this.lagId = entity.id;
    this.tagValue = entity?.keyword;
    // console.log(entity)
    this.lagIndicationForm.patchValue(entity);
  }

  public remove(entity) {
    const index: number = this.lagData.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.title+'?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {
            this.appService.deleteLagIndicator(entity.id).subscribe(res => {
              this.getAllLagIndicators()
              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              this.message.create('error', error.error.message, {
                nzDuration: 5000
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.tagValue = [];
    this.action = 'Create';
    this.isFormVisible = true;
  }
  lagId:any;
  handleModalSubmit(){
    this.isFormLoading = true;
    if(this.lagIndicationForm.valid){
      let data = {
        "keyword":this.tagValue?this.tagValue:[],
        "title":this.lagIndicationForm.value.title,
        "description":this.lagIndicationForm.value.description?this.lagIndicationForm.value.description:''
        }
      // alert(JSON.stringify(data))

      let subscriber = null;
      let message = null;
      if (this.action === "Create") {
        subscriber = this.appService.createLagIndicator(data);
        message = 'Lag Indicator "' + this.lagIndicationForm.controls['title'].value + '" has been added.';
      }
      else if (this.action === 'Edit') {
        subscriber = this.appService.updateLagIndicator(data, this.lagId);
        message = 'Lag Indicator "' + this.lagIndicationForm.controls['title'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;
        this.getAllLagIndicators()
        this.message.create('success', message, {
          nzDuration: 5000
        });

        // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        //let errorMessage = error.error.message;
        this.message.create('error',error.error.message, {
          nzDuration: 5000
        });

      });
    }
  }


  handleModalCancel(): void {
    this.isFormVisible = false;
  }

  handleInputConfirm() {
    if (this.inputTag && this.tagValue.indexOf(this.inputTag) === -1) {
      this.tagValue = [...this.tagValue, this.inputTag];
    }
    this.inputTag = '';
  }

  handleClose(removedTag: {}): void {
    this.tagValue = this.tagValue.filter(tag => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

}
