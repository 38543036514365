import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Employee,
  EmployeeRetrieveRequest,
  Form,
  FormRetrieveRequest
} from 'src/app/app.models';
import Swal from 'sweetalert2';


// status = saved/submitted/rejected/approved
// status2 = submitted to manager/partially rejected by manager/rejected by manager/approved by manager/progress updated/progress seen by manager/re-submitted

@Component({
  selector: 'app-form-approval',
  templateUrl: './form-approval.component.html',
  styleUrls: ['./form-approval.component.css']
})
export class FormApprovalComponent implements OnInit, OnDestroy {
  private sub: any;

  loading = false;
  today: any = new Date();
  loggedInUserDetail: any;
  isManager: any = false;

  form: any = {};
  launch: any = {};
  records: any[] = [];
  submission: any = {};
  statuses: any[] = [];
  updateStatuses: any[] = [];
  lastSubmissionDate: any;
  afterFrequencyDate: any;
  prevRecords: any[] = [];
  previousRecord: any[] = [];
  showPreviousProgress= false;
  prevProgressIndex = 0;
  checkManagersAfterApprovalFields = 0;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    public appService: AppService,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private spinner: NgxSpinnerService
  ) {
  }

  getOptions(col): void {
    let optionSource = col.configurable.option_source || '';
    switch(optionSource){
      case 'table':
        let optionTable = col.configurable.options_table || '';
        let optionTableKey = col.configurable.options_table_key || '';
        this.appService.getOptionsTable(optionTable, optionTableKey).subscribe((tblOptions: any) => {
          if(Array.isArray(tblOptions)){
            col.configurable.options = tblOptions.join();
          }
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
        break;
      case 'api':
        let optionApi = col.configurable.options_api || '';
        let optionsApiKey = col.configurable.options_api_key || '';
        this.appService.getOptionsApi(optionApi).subscribe((apiOptions: any) => {
          let newOptions = [];
          for(const apiOption of apiOptions){
            newOptions.push(apiOption[optionsApiKey]);
          }
          if(Array.isArray(newOptions)){
            col.configurable.options = newOptions.join();
          }
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
        break;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loading = true;
    this.loggedInUserDetail = this.appService.getMe();

    let rr = new EmployeeRetrieveRequest();
    rr.reporting_manager = this.loggedInUserDetail.employee.id;
    this.appService.getEmployees(rr).subscribe((data: any) => {
      if(data['_meta']['total_items'] > 0){
        this.isManager = true;
      }
    });

    this.today = this.datePipe.transform(this.today, 'yyyy-MM-dd');

    this.sub = this.route.params.subscribe(params => {
      this.appService.getSubmissionById(params['id']).subscribe((submission: any) => {
        this.appService.getFormLaunchById(submission.launch_id).subscribe((launch: any) => {
          this.launch = launch;
          this.form = launch.form;
          this.submission = submission;

          if(this.submission.status2 === 'progress updated'){
            let formData = {
              status2: 'progress seen by manager'
            };
            this.appService.updateFormSubmission(this.submission.id, formData).subscribe((res: any) => {
              this.submission = res;
            })
          }

          let schemas = this.form.schemas || [];
          for(const col of schemas){
            if(col.type == 'select'){
              this.getOptions(col);
            }
          }

          if(submission.goals){
            console.log(this.submission,"submission")
            let records = [];
            for(const key in submission.goals){
              if(key !== 'cols'){
                this.lastSubmissionDate = key;
              }
            }

            let dateParts = this.lastSubmissionDate.split('-');
            let lastDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            this.afterFrequencyDate = this.datePipe.transform(lastDate.setMonth(lastDate.getMonth() + this.submission.form.frequency), 'yyyy-MM-dd');

            for(const i in submission.goals[this.lastSubmissionDate]){
              if(!records[i]){
                records[i] = [];
              }
              this.statuses.push(submission.goals[this.lastSubmissionDate][i][submission.goals[this.lastSubmissionDate][i].length - 1]);
              this.updateStatuses[i] = submission.goals[this.lastSubmissionDate][i][submission.goals[this.lastSubmissionDate][i].length - 1];
              for(const index in submission.goals[this.lastSubmissionDate][i]){
                let value = submission.goals[this.lastSubmissionDate][i][index];
                records[i][submission.goals.cols[index]] = value;
              }
              if(Object.keys(submission.progress).length !== 0){
                for(const index in submission.progress[this.lastSubmissionDate][i]){
                  let value = submission.progress[this.lastSubmissionDate][i][index];
                  records[i][submission.progress.cols[index]] = value;
                }
              }
            }
            if (Object.keys(submission.progress).length >= 3) {
              let dates = [];
              let prevRecords = [];

              for (const key in submission.progress) {
                if (key !== 'cols') {
                  dates.push(key);
                }
              }

              for (const date of dates) {
                for (const i in submission.goals[date]) {
                  if (!prevRecords[i]) {
                    prevRecords[i] = [];
                  }
                  for (const index in submission.goals[date][i]) {
                    let value = submission.goals[date][i][index];
                    prevRecords[i][submission.goals.cols[index]] = value;
                  }
                  for (const index in submission.progress[date][i]) {
                    let value = submission.progress[date][i][index];
                    prevRecords[i][submission.progress.cols[index]] = value;
                  }
                }

                this.prevRecords.push(prevRecords);
                prevRecords = [];
              }
            }
            this.records = records;
            if(this.submission.status == 'approved') this.isManagersAfterApprovalFields();
          }
          console.log('this.submission',this.submission)

          this.spinner.hide();
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message,
          allowOutsideClick: false,
        });
        this.spinner.hide();
        this.loading = false;
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  isManagersAfterApprovalFields(){
    for(const schema of this.form.schemas){
      if(['manager'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_approval' && this.statuses.indexOf('approved') !== -1)
      {
        this.checkManagersAfterApprovalFields++;
        break;
      }
    }
    console.log(this.checkManagersAfterApprovalFields);
    return;
  };

  onCheckRequired(record) {
    for(const key in record){
      for(const schema of this.form.schemas){
        if(schema.id != key && schema.label != key) {
          continue;
        }

        if((
          (['manager', 'tenant_admin'].indexOf(schema.configurable.fill_by) !== -1 && ['immediate', 'during_review'].indexOf(schema.configurable.fill_when) !== -1) ||

          (['manager', 'tenant_admin'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_rejection' && this.statuses.indexOf('rejected') !== -1) ||

          (['manager', 'tenant_admin'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_approval' && this.statuses.indexOf('approved') !== -1 && this.submission.status == 'approved')
        )){
          let isRequired = schema.configurable.required || false;
          if(isRequired && !record[key]){
            Swal.fire({
              title: 'Required',
              text: `${schema.label} cannot be empty`,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Close',
            }).then((result) => {
              if(result.value){

              }
            });
            return false;
          }
        }
      }
    }
    return true;
  }

  onApprove(record, index): void {
    let check = this.onCheckRequired(record);
    if(!check) return;
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm before approve`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, approve it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if(result.value){
        this.onApproveRejectStatus(record, index, 'approved');
        this.message.create('success', 'Approved', {
          nzDuration: 5000
        });
      }
    });
  }

  onReject(record, index): void {
    let check = this.onCheckRequired(record);
    if(!check) return;
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm before reject`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, reject it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if(result.value){
        this.onApproveRejectStatus(record, index, 'rejected');
        this.message.create('success', 'Rejected', {
          nzDuration: 5000
        });
      }
    });
  }

  onApproveRejectStatus(record, index, customStatus) {
    for(const key in this.records[index]){
      for(const schema of this.form.schemas){
        let colId = schema.id || schema.label;
        if(colId == key){
          if(schema.configurable.fill_when != 'after_approval'){
            let keyIndex = this.submission.goals.cols.indexOf(key);
            this.submission.goals[this.lastSubmissionDate][index][keyIndex] = record[key];
            break;
          }
        }
      }
      for(const schema of this.form.schemas){
        let colId = schema.id || schema.label;
        if(colId == key){
          if(schema.configurable.fill_when == 'after_approval'){
            let keyIndex = this.submission.progress.cols.indexOf(key);
            this.submission.progress[this.lastSubmissionDate][index][keyIndex] = record[key];
            break;
          }
        }
      }
    }
    this.updateStatuses[index] = customStatus;
    this.submission.goals[this.lastSubmissionDate][index][this.submission.goals[this.lastSubmissionDate][index].length - 1] = customStatus;
    this.submission.progress[this.lastSubmissionDate][index][this.submission.progress[this.lastSubmissionDate][index].length - 1] = customStatus;
    this.postData(null);
    return;
  }

  updateRecord(customStatus) {
    let flag:Boolean = false;
    console.log(this.submission,"afterFrequencyDate")
    if(this.today <= this.afterFrequencyDate){
      this.today = this.lastSubmissionDate;
      console.log(this.today,'this.today')
    }

    this.submission.goals[`${this.lastSubmissionDate}`] = [];
    this.submission.progress[`${this.lastSubmissionDate}`] = [];
    let status;
    for(const i in this.records){
      for(const key in this.records[i]){
        if(customStatus) {
          status = customStatus;
        } else {
          status = this.records[i]['ng-status'];
        }
        for(const schema of this.form.schemas){
          let colId = schema.id || schema.label;
          if(colId == key){
            console.log(schema.configurable.fill_when)
            if(schema.configurable.fill_when != 'after_approval'){
              if(!this.submission.goals[this.lastSubmissionDate][i]){
                this.submission.goals[this.lastSubmissionDate][i] = [];
              }
              this.submission.goals[this.lastSubmissionDate][i].push(this.records[i][key]);
            }
          }
        }

      }
      if(this.submission.goals.cols.indexOf('ng-status') === -1){
        this.submission.goals.cols.push('ng-status');
      }
      if(this.submission.goals.cols.length != this.submission.goals[this.today][i].length){
        this.submission.goals[this.today][i].push(status);
      }
      if(this.submission.goals[this.today][i][this.submission.goals[this.today][i].length - 1] != status && this.submission.goals[this.today][i][this.submission.goals[this.today][i].length - 1] != 'approved'){
        this.submission.goals[this.today][i][this.submission.goals[this.today][i].length - 1] = status;
      }
    }
    console.log(this.form.schemas,'this.records')
    for(const i in this.records){
      for(const key in this.records[i]){
        if(customStatus) {
          status = customStatus;
        } else {
          status = this.records[i]['ng-status'];
        }
        for(const schema of this.form.schemas){
          let colId = schema.id || schema.label;
          // console.log(key,colId)

          if(colId == key){
            // console.log("entered...",schema.configurable.fill_when)
            if(schema.configurable.fill_when == 'after_approval'){
              // console.log("after_approval...")
              if(!this.submission.progress[this.lastSubmissionDate][i]){
                this.submission.progress[this.lastSubmissionDate][i] = [];
              }
              this.submission.progress[this.lastSubmissionDate][i].push(this.records[i][key]);
              flag = true;
            }
          }
        }

      }
      if(this.submission.progress.cols.indexOf('ng-status') === -1){
        this.submission.progress.cols.push('ng-status');
      }
      console.log(this.submission,i,status)
      if((this.submission.progress.cols.length != this.submission.progress[this.today][i]?.length)&&(flag)){
        this.submission.progress[this.today][i].push(status);
      }
      if(flag && this.submission.progress[this.today][i][this.submission.progress[this.today][i].length - 1] != status && this.submission.progress[this.today][i][this.submission.progress[this.today][i].length - 1] != 'approved'){
        this.submission.progress[this.today][i][this.submission.progress[this.today][i].length - 1] = status;
      }
    }

    if(customStatus) {
      this.postData('reSubmission');
    } else {
      console.log("inside the null")
      this.postData(null);
    }
    return;
  }

  onSubmit(): void {
    for(const i in this.records){
      for(const key in this.records[i]){
        for(const schema of this.form.schemas){
          let isRequired = schema.configurable.required || false;
          if(schema.id === key || schema.label === key) {
            if((
              (['manager', 'tenant_admin'].indexOf(schema.configurable.fill_by) !== -1 && ['immediate', 'during_review'].indexOf(schema.configurable.fill_when) !== -1) ||

              (['manager', 'tenant_admin'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_rejection' && this.statuses.indexOf('rejected') !== -1) ||

              (['manager', 'tenant_admin'].indexOf(schema.configurable.fill_by) !== -1 && schema.configurable.fill_when == 'after_approval' && this.statuses.indexOf('approved') !== -1 && this.submission.status == 'approved')
            )){
              if(isRequired && !this.records[i][key]){
                Swal.fire({
                  title: 'Required',
                  text: `${schema.label} cannot be empty`,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: 'Close',
                }).then((result) => {
                  if(result.value){

                  }
                });
                return;
              }
            }
          }
        }
      }
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Once you submit you cannot make any changes, Do you still want to continue with submission',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
        if (result.isConfirmed) {
          this.updateRecord(null);
          this.message.create('success', 'Data Submitted', {
            nzDuration: 5000
          });
        }
      });
  }

  postData(params): void {
    let formData = {
      form_id: this.form.id,
      goals: this.submission.goals,
      progress: this.submission.progress,
      launch_id: this.submission.launch_id,
    };

    if(this.updateStatuses.indexOf('rejected') === -1 && this.updateStatuses.indexOf('submitted') === -1){
      formData['status'] = 'approved';
      formData['status2'] = 'approved by manager';
    } else if(this.updateStatuses.indexOf('rejected') != -1){
      formData['status'] = 'rejected';
      formData['status2'] = 'rejected by manager';
    }

    if(this.updateStatuses.indexOf('rejected') != -1 && this.updateStatuses.indexOf('approved') != -1){
      formData['status2'] = 'partially rejected by manager';
    }

    if(this.submission.status2 == 'progress seen by manager'){
      formData['status2'] = 'progress updated by manager';
    }

    if(params == 'reSubmission'){
      formData['status'] = 'saved';
      formData['status2'] = 'saved';
    }

    this.spinner.show();
    this.appService.updateFormSubmission(this.submission.id, formData).subscribe((res: any) => {
      this.submission.status2 = res.status2;
      this.spinner.hide();
      if(params == 'reSubmission'){
        this.router.navigate(['forms/teams-submissions']);
      }
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  viewPreviousProgress(): void {
    this.showPreviousProgress = true;
  }

  previousProgressHandleCancel(): void {
    this.showPreviousProgress = false;
  }

  viewLeftPreviousProgress(): void {
    this.prevProgressIndex--;
  }

  viewRightPreviousProgress(): void {
    this.prevProgressIndex++;
  }

  sentToReSubmission(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm to send this form for Re-Submission.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then((result) => {

      if(result.isConfirmed){
        this.updateRecord('saved')
        this.message.create('success', 'Send for Re-Submission', {
          nzDuration: 5000
        });
      } else return;
    });
  }
}
