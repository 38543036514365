<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="row">
                <!-- <div class="col-sm-12">
                  <a class="back-btn" nz-button nzType="link" (click)="back()">
                    <i class="ri-arrow-go-back-fill"></i>
                  </a>
                </div> -->
              </div>
              <!-- List View -->
              <div class="col-lg-12 mt-5 text-center">
                <h3>Talent Card List</h3>
              </div>
              <div class="setup-content tab-first">
                <div class="row" *ngIf="!loading">
                  <div class="col-lg-6 searchbox">
                    <!-- <nz-form-item>
                      <nz-form-control>
                        <nz-input-group>
                          <nz-select nzShowSearch (ngModelChange)="selectOption($event)" nzAllowClear nzPlaceHolder="Search for Employee" [(ngModel)]="selectedValue">
                            <ng-container *ngFor="let user of usersList">
                              <nz-option *ngIf="!allData" [nzLabel]="user.display_name" [nzValue]="user.display_name">
                              </nz-option>
                            </ng-container>
                            <nz-option *ngIf="allData" nzDisabled nzCustomContent>
                              <i nz-icon nzType="loading" class="loading-icon"></i>
                              Loading Data...
                            </nz-option>
                          </nz-select>
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item> -->
                    <!-- <form nz-form> -->
                      <!-- <nz-form-label nzFor="Ticket ID">Ticket ID</nz-form-label> -->
                        <!-- <nz-row>
                          <nz-col nzSpan="28"> -->
                            <!-- <input
                              placeholder="Search for Employee"
                              nz-input
                              [(ngModel)]="inputValue"
                              (ngModelChange)="onInput($event)"
                              [nzAutocomplete]="auto"
                              [formControl]="filterControl"/>
                            <nz-autocomplete nzBackfill #auto>
          
                              <nz-auto-option *ngFor="let data of activitiesData | async" [nzValue]="data?.name"
                                              (click)="onUserSelect(data)" (keydown.enter)="onUserSelect(data)"   >
                                {{data?.user_name}}
                              </nz-auto-option>
          
                            </nz-autocomplete> -->
                            
                        <!-- </nz-col>
                        </nz-row>
                    </form> -->
                  </div>
                  <div class="col-lg-6 text-right mb-2">
                    <button class="mr-2" nz-button nzType="primary" nzSize="medium" (click)="getTalentcardDump()">Talent Card Dump</button>
                    <a class="pr-2" (click)="openFilterModal()"><span>Filter <i class="ri-filter-2-line"></i></span></a>
                  </div>
                </div>
                <nz-table [nzScroll]="{ x: '1250px' }" [nzFrontPagination]="false" [nzLoading]="loading" nzShowSizeChanger
                    [nzTotal]="total" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                    (nzQueryParams)="onQueryParamsChange($event)"
                    [nzData]="talentCardDetails">
                    <thead>
                        <tr>
                          <th class="searchfilter" nzLeft>
                            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue?.length > 0" [nzDropdownMenu]="menu">
                              <i nz-icon nzType="search" nz-tooltip nzTooltipTitle="Search Employee"></i>
                            </nz-filter-trigger>
                            Employee Name</th>
                            <th>Employee ID</th>
                            <th>Location</th>
                            <th>Function</th>
                            <th>Department</th>
                            <th>Role</th>
                            <th>Grade</th>
                            <th nzRight style="width:120px">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of talentCardDetails">
                            <td nzLeft>{{ item?.first_name }} {{ item?.last_name }}</td>
                            <td>{{ item?.employee_id }}</td>
                            <td>{{ item?.location?.name }}</td>
                            <td>{{ item?.department?.function.name }}</td>
                            <td>{{ item?.department?.name }}</td>
                            <td>{{ item?.role?.role_name }}</td>
                            <td>{{ item?.grade?.grade_name }}</td>
                            <td nzRight>
                              <button nz-button nzType="primary" nzSize="medium" (click)="talentCardView(item.talent_card)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <div class="ant-table-filter-dropdown">
                    <div class="search-box">
                      <input type="text" nz-input placeholder="Search Employee Name" [(ngModel)]="searchValue" />
                      <button nz-button nzSize="small" nzType="primary" (click)="searchServer()" class="search-button">Search</button>
                      <button nz-button nzSize="small" (click)="reset()">Reset</button>
                    </div>
                  </div>
                </nz-dropdown-menu>
              </div>
            </div>

            <nz-modal [(nzVisible)]="isFilterModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Select Filter"
        (nzOnCancel)="handleFilterCancel()" (nzOnOk)="handleFilterOk()" nzOkText="Apply">
        <form nz-form [nzLayout]="'vertical'" [formGroup]="filterForm" (ngSubmit)="handleFilterOk()">



          <!-- Input for Business Unit select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="business_unit_ids" nzNoColon="false">Business unit</nz-form-label>
            <nz-form-control nzErrorTip="Business Unit is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="false"
                  nzPlaceHolder="Select Business Unit" formControlName="business_unit_ids">
                  <ng-container *ngFor="let item of bUOptionList">
                    <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->

          <!-- Input for Location select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="location_ids" nzNoColon="false">Locations</nz-form-label>
            <nz-form-control nzErrorTip="Location is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" (nzOpenChange)="onLocationOpened($event)"
                  [nzDisabled]="!filterForm.controls.business_unit_ids?.value" nzAllowClear *ngIf="isFilterModalOpen"
                  nzMode="false" nzPlaceHolder="Select Locatiion" formControlName="location_ids">

                  <ng-container *ngFor="let item of locationOptionList">
                    <nz-option *ngIf="!isLocationOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->

          <!-- Input for Function select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="function_ids" nzNoColon="false">Functions</nz-form-label>
            <nz-form-control nzErrorTip="Function is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" (nzOpenChange)="onFunctionOpened($event)"
                  [nzDisabled]="!filterForm.controls.business_unit_ids?.value" nzAllowClear *ngIf="isFilterModalOpen"
                  nzMode="multiple" nzPlaceHolder="Select Function" formControlName="function_ids">

                  <ng-container *ngFor="let item of functionOptionList">
                    <nz-option *ngIf="!isFunctionOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->

          <!-- Input for Department select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="department_ids" nzNoColon="false">Departments</nz-form-label>
            <nz-form-control nzErrorTip="Department is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" (nzOpenChange)="onDepartmentOpened($event)"
                  [nzDisabled]="!filterForm.controls.function_ids?.value" nzAllowClear *ngIf="isFilterModalOpen"
                  nzMode="multiple" nzPlaceHolder="Select Department" formControlName="department_ids">
                  <ng-container *ngFor="let item of departmentOptionList">
                    <nz-option *ngIf="!isDepartmentOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                    </nz-option>
                  </ng-container>
                  <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->


          <!-- Input for Roles select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="role_ids" nzNoColon="false">Roles</nz-form-label>
            <nz-form-control nzErrorTip="Role is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                  nzPlaceHolder="Select Roles" formControlName="role_ids">
                  <nz-option *ngFor="let item of roleListOption" [nzLabel]="item.role_name" [nzValue]="item.id">
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Roles select box ends here -->


          <!-- Input for Grades select box -->
          <nz-form-item>
            <nz-form-label nzRequired nzFor="grade_ids" nzNoColon="false">Grades</nz-form-label>
            <nz-form-control nzErrorTip="Grade is mandatory.">
              <nz-input-group>
                <nz-select [nzSize]="'large'" nzAllowClear *ngIf="isFilterModalOpen" nzMode="multiple"
                  nzPlaceHolder="Select Grade" formControlName="grade_ids">
                  <nz-option *ngFor="let item of gradeListOption" [nzLabel]="item.grade_name" [nzValue]="item.id">
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <!-- Input for Grades select box ends here -->

        </form>
            </nz-modal>


            <nz-modal [(nzVisible)]="isTalentModalOpen" nzClosable="true" nzMaskClosable="false" nzTitle="Talent Card Detail"
            (nzOnCancel)="handleTalentCancel()" (nzOnOk)="handleTalentOk()" nzOkText="Ok">
            <form class="row" nz-form [nzLayout]="'vertical'" [formGroup]="talentCardForm">
              <div class="col-md-12">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="name">Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Name!">
                    <input nz-input formControlName="name" nzSize="large" placeholder="Enter Name" id="name"
                      OnlyCharacter readonly />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="DOJ">DOJ</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Legal Entity!">
                    <nz-date-picker nzSize="large" formControlName="doj" nzFormat="yyyy-MM-dd"
                       readonly></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Department/Function">Department/Function</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Department/Function!">
                    <input nz-input formControlName="department" nzSize="large"
                      placeholder="Enter Department/Function" id="department" OnlyCharacter />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Reviewer">Reviewer</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Reviewer!">
                    <input nz-input formControlName="reviewer" nzSize="large" placeholder="Enter Reviewer"
                      id="reviewer" OnlyCharacter />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Professional Qualification">Professional Qualification
                  </nz-form-label>
                  <nz-form-control nzErrorTip="Enter Professional Qualification!">
                    <input nz-input formControlName="professional_qualification" nzSize="large"
                      placeholder="Enter Professional Qualification" id="professional_qualification" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-md-12">
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="name">Employee Code</nz-form-label>
                  <nz-form-control nzErrorTip="Please Employee Code!">
                    <input nz-input formControlName="employee_code" nzSize="large" placeholder="Employee Code"
                      id="employee_code" readonly>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Grade">Grade</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Grade!">
                    <input nz-input formControlName="grade" nzSize="large" placeholder="Enter Grade" id="grade"
                      readonly />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Functional Manager">Functional Manager</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Functional Manager!">
                    <input nz-input formControlName="functional_manager" nzSize="large"
                      placeholder="Enter Functional Manager" id="functional_manager" OnlyCharacter />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Education Qualification">Education Qualification</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Education Qualification!">
                    <input nz-input formControlName="education_qualification" nzSize="large"
                      placeholder="Enter Education Qualification" id="education_qualification" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="Mobility">Mobility</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Mobility">
                    <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select Enter Mobility"
                      formControlName="mobility">
                      <nz-option *ngFor="let item of mobilityList" [nzLabel]="item.name" [nzValue]="item.name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="talentCardForm.get('mobility')?.value == 'Yes'">
                  <nz-form-label nzRequired nzFor="geography">Geography</nz-form-label>
                  <nz-form-control nzErrorTip="Please Enter Geography">
                    <input nz-input id="geography" placeholder="Enter Geography" nzSize="large"
                      formControlName="geography">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="row mb-1 mt-3">
                <h5>Previous Promotion Details (Only Lupin)</h5>
              </div>
              <div class="col-lg-12 row PromotionD" formArrayName="previous_promotion">
                <!-- <div class="row"> -->
                  <ng-container *ngFor="let blevel of talentCardForm.controls.previous_promotion['controls']; let k=index" [formGroupName]="k">
                    <div class="col-lg-12">
                      <nz-form-item>
                        <nz-form-label nzRequired nzFor="Month/Year">Month/Year</nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Month/Year!">
                          <nz-date-picker nzMode="month" nzSize="large" 
                            formControlName="month/year"></nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-6">
                      <nz-form-item>
                        <nz-form-label nzRequired nzFor="Grade From">Grade From</nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Grade From!">
                          <input nz-input id="from" nzSize="large" formControlName="from" placeholder="Grade From" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="col-lg-6">
                      <nz-form-item>
                        <nz-form-label nzRequired nzFor="Grade To">Grade To</nz-form-label>
                        <nz-form-control nzErrorTip="Please Enter Grade To!">
                          <input nz-input id="to" nzSize="large" formControlName="to" placeholder="Grade To" />
                          <!-- <a *ngIf="talentCardForm.controls.previous_promotion?.length > 1" title="Delete" class="mr-2  delete-icon"><i
                              class="ri-delete-bin-6-fill" (click)="removeBLevel(k, blevel['controls']['id'])"></i></a> -->
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </ng-container>
                  <!-- <div class="col-lg-12 new-add text-right" *ngIf="isSubmit == 'no'">
                    <a title="Add" class="add-btn ml-3" (click)="addNewBLevel()"><i class="ri-add-circle-fill"></i></a>
                  </div> -->
                <!-- </div> -->
              </div>

              <div class="row mb-1 mt-3">
                <h5>Previous Experience Details</h5>
              </div>
              <div class="col-lg-12 row PromotionD" formArrayName="previous_experience">
                <ng-container *ngFor="let ex of talentCardForm.controls.previous_experience['controls']; let j=index" [formGroupName]="j">
                  <div class="col-lg-12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Company Name">Company Name</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Company Name!">
                        <input nz-input id="company_name" placeholder="Enter Company Name" nzSize="large"
                          formControlName="company_name" placeholder="Company Name" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div class="col-lg-12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Role">Role</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Role!">
                        <input nz-input id="role" placeholder="Enter Role" nzSize="large" formControlName="role"
                          placeholder="Role" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div class="col-lg-12 previous">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Date (From – To)">Date (From – To)</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Date (From – To)!">
                        <nz-range-picker nzSize="large" nzFormat="dd-MM-yyyy" 
                          formControlName="from_to_date"></nz-range-picker>
                        <!-- <a *ngIf="talentCardForm.controls.previous_experience.controls?.length > 1" title="Delete"
                          class="mr-2  delete-icon"><i class="ri-delete-bin-6-fill"
                            (click)="removeExperience(j, ex['controls']['id'])"></i></a> -->
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </ng-container>
                <!-- <div class="col-lg-12  new-add text-right" *ngIf="isSubmit == 'no'">
                  <a title="Add" class="add-btn ml-3" (click)="addNewExperience()"><i
                      class="ri-add-circle-fill"></i></a>
                </div> -->
              </div>

              <div class="row mb-1 mt-3">
                <h5>Lupin Experience Details</h5>
              </div>
              <div class="col-lg-12 row PromotionD" formArrayName="company_experience">
                <ng-container *ngFor="let lupin of talentCardForm.controls.company_experience['controls']; let l=index" [formGroupName]="l">
                  <div class="col-lg-12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Role">Role</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Role!">
                        <input nz-input id="role" placeholder="Enter Role" nzSize="large" formControlName="lupin_role"
                          placeholder="Role" />
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div class="col-lg-12">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="Date (From – To)">Date (From – To)</nz-form-label>
                      <nz-form-control nzErrorTip="Please Enter Date (From – To)!">
                        <nz-range-picker nzSize="large" nzFormat="dd-MM-yyyy" formControlName="lupin_from_to_date"
                          ></nz-range-picker>
                        <!-- <a *ngIf="talentCardForm.controls.company_experience.controls?.length > 1" title="Delete" class="mr-2  delete-icon"><i
                            class="ri-delete-bin-6-fill" (click)="removeLupin(l, lupin['controls']['id'])"></i></a> -->
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </ng-container>
                <!-- <div class="col-lg-12  new-add text-right" *ngIf="isSubmit == 'no'">
                  <a title="Add" class="add-btn ml-3" (click)="addNewLupin()"><i class="ri-add-circle-fill"></i></a>
                </div> -->
              </div>
            </form>
            </nz-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
    <p style="color: white"> Please Wait... </p>
  </ngx-spinner>