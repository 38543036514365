
<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
<div  class="  col-sm-12">  
    <div class="col-md-8">
      <h3 class="py-4">Objective Target Analysis</h3>
    </div>
    <div class="col-md-4">
      <nz-form-item> 
          <nz-form-control nzErrorTip="Please input Types!">
              <nz-input-group class="form-group">
                  <nz-select
                      nzPlaceHolder="Please select"
                      [nzOptions]="listOfNoOfOption"
                      >
                  </nz-select>
              </nz-input-group>
          </nz-form-control>
      </nz-form-item>
    </div>
      <nz-table #basicTable nzBordered [nzData]="listOfData">
          <thead>
            <tr>
              <th>SP Name</th>
              <th>My Target</th>
              <th>Team Target</th>
              <th>Buffer</th> 
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data">
              <td>{{ data.name }}</td>
              <td>{{ data.myTarget }}</td>
              <td>{{ data.teamTarget }} <a (click)="showModal()" ><i nz-icon nzType="team" nzTheme="outline"></i></a></td>
              <td>{{ data.buffer }}</td> 
            </tr>
          </tbody>
        </nz-table>
        <nz-modal [(nzVisible)]="isVisible" nzTitle="Objective Target Analysis" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
            <nz-table #basicTable nzBordered [nzData]="listOfData">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Target</th> 
                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of basicTable.data">
                      <td>{{ data.manager }}</td> 
                      <td>{{ data.buffer }}</td> 
                    </tr>
                  </tbody>
                </nz-table>
      </nz-modal>
        <!-- <nz-form-item>
          <nz-form-control>
              <button nz-button nzType="primary"
                 (click)="next()">Next</button>
          </nz-form-control>
      </nz-form-item> -->

</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>