<!--------------------------------------------------------------------------------------------------------------------------------
--Modified by: Manuel Robert------------------------------------------------------------------------------------------------------
--User Story/ Task ID :   Modified the icons for settings and adjusted the margin for Employee performace setting card------------
-----------------------   uat_assessment and others-------------------------------------------------------------------------------
--Modified on: 25-10-2021---------------------------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------------------------------------------------->

<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tab design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="stepwizard mt-4">
                            <div class="stepwizard-row setup-panel text-center">
                                <!-- General Tab-->
                                <div class="wizard-step tab-first">
                                    <a class="btn btn-default" (click)="onTabChange(0)"
                                       [ngClass]="{'active' : contentIndex == 0}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group471.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-file-list-3-line"></i>
                                        <span>General</span>
                                    </a>
                                </div>
                                <!-- Desired level Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(7)"
                                       [ngClass]="{'active' : contentIndex == 7}">
                                        <!-- <div class="iconBox">
                                                      <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                                    </div> -->
                                        <i class="ri-user-heart-fill"></i>
                                        <span>Competency Level Settings</span>
                                    </a>
                                </div>
                                <!-- 360 Degree Score Settings Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes' || loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(3)"
                                       [ngClass]="{'active' : contentIndex == 3}">
                                        <!-- <div class="iconBox">
                         <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                       </div> -->
                                        <i class="ri-group-fill"></i>
                                        <span>360 Degree Score Settings</span>
                                    </a>
                                </div>
                                <!-- Employee Performance Settings -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(9)"
                                       [ngClass]="{'active' : contentIndex == 9}">

                                        <!-- <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes=""> -->
                                        <i class="ri-file-settings-fill text-danger"></i>
                                        <span style="margin: 10px;">Employee Performance Settings</span>
                                    </a>
                                </div>
                                <!-- 9 Box Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(8)"
                                       [ngClass]="{'active' : contentIndex == 8}">

                                        <!-- <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes=""> -->
                                        <i class="ri-bar-chart-box-fill text-danger"></i>
                                        <span>9 Box Settings</span>
                                    </a>
                                </div>
                                <!-- Premature Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(13)"
                                       [ngClass]="{'active' : contentIndex == 13}">
                                        <!-- <div class="iconBox">
                                    <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                  </div> -->
                                        <i class="ri-user-heart-fill"></i>
                                        <span>Premature Closure and Deadline Extend</span>
                                    </a>
                                </div>
                                <!-- Profit Maximiser Settings  Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser == 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(1)"
                                       [ngClass]="{'active' : contentIndex == 1}">
                                        <!-- <div class="iconBox"> -->
                                        <!-- <img class="icon" src="../../../assets/images/icon/Group47" alt="" sizes=""> -->
                                        <!-- </div> -->
                                        <i class="ri-hammer-fill"></i>
                                        <span>TruPerform Settings</span>
                                    </a>
                                </div>
                                <!-- Points Structure Settings Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement == 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(2)"
                                       [ngClass]="{'active' : contentIndex == 2}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-tools-fill"></i>
                                        <span>Points Structure Settings</span>
                                    </a>
                                </div>

                                <!-- Badges Settings Tab -->
                                <div class="wizard-step tab-fifth tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement === 'yes' || loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(4)"
                                       [ngClass]="{'active' : contentIndex == 4}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-bank-card-fill"></i>
                                        <span>Badges Settings</span>
                                    </a>
                                </div>
                                <!-- Behavior Transaction Settings Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction === 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(5)"
                                       [ngClass]="{'active' : contentIndex == 5}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-briefcase-fill"></i>
                                        <span>Behavior Transaction Settings</span>
                                    </a>
                                </div>
                                <!-- Assessment Settings Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement == 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(6)"
                                       [ngClass]="{'active' : contentIndex == 6}">

                                        <!-- <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes=""> -->
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-file-fill"></i>
                                        <span>Assessment Settings</span>
                                    </a>
                                </div>


                                <!-- Time Zone Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(10)"
                                       [ngClass]="{'active' : contentIndex == 10}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-time-fill"></i>
                                        <span>Time Zone Settings</span>
                                    </a>
                                </div>

                                <!-- Privacy Policy Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(11)"
                                       [ngClass]="{'active' : contentIndex == 11}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-chat-private-fill"></i>
                                        <span>Privacy Policy</span>
                                    </a>
                                </div>

                                <!-- Time Zone Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(12)"
                                       [ngClass]="{'active' : contentIndex == 12}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-compass-4-fill"></i>
                                        <span>Terms & Conditions</span>
                                    </a>
                                </div>


                                <!-- <div class="wizard-step tab-second">
                                  <a class="btn btn-default" [routerLink]="['/maximizer-setup/order-history']"
                                    routerLinkActive="active">
                                    <div class="iconBox">
                                      <img class="icon" src="../../../assets/images/icon/Group474.svg" alt="" sizes="">
                                    </div>
                                    <span>Ordre History</span>
                                  </a>
                                </div> -->


                                <!-- Time Zone Settings Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement == 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(14)"
                                       [ngClass]="{'active' : contentIndex == 14}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-compass-4-fill"></i>
                                        <span>Default Spacing</span>
                                    </a>
                                </div>

                                <!-- Time Zone Settings Tab -->
                                <div class="wizard-step tab-third"
                                     *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement == 'yes'">
                                    <a class="btn btn-default" (click)="onTabChange(15)"
                                       [ngClass]="{'active' : contentIndex == 15}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-compass-4-fill"></i>
                                        <span>Expected % of wrong Questions </span>
                                    </a>
                                </div>

                                <!-- Talent card active/inactive Settings Tab -->
                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(16)"
                                       [ngClass]="{'active' : contentIndex == 16}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-compass-4-fill"></i>
                                        <span>Talent Card Status</span>
                                    </a>
                                </div>

                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(17)"
                                       [ngClass]="{'active' : contentIndex == 17}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-compass-4-fill"></i>
                                        <span>Report Threshold</span>
                                    </a>
                                </div>

                                <div class="wizard-step tab-third">
                                    <a class="btn btn-default" (click)="onTabChange(18)"
                                       [ngClass]="{'active' : contentIndex == 18}">
                                        <!-- <div class="iconBox">
                                          <img class="icon" src="../../../assets/images/icon/Group472.svg" alt="" sizes="">
                                        </div> -->
                                        <i class="ri-user-settings-fill"></i>
                                        <span>Succession Management</span>
                                    </a>
                                </div>

                            </div>
                        </div>

                        <!-- General Content-->
                        <div class="row1 setup-content tab-first" *ngIf="contentIndex == 0">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="generalForm">
                                <div class="col-md-6">
                                    <h4 class="my-3">Basic Details</h4>
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="name">Name</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Name!">
                                            <input nz-input noWhitespace formControlName="company_name" nzSize="large"
                                                   placeholder="Enter Name" id="name" OnlyCharacter/>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="legalEntity">Legal Entity</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Legal Entity!">
                                            <input nz-input noWhitespace formControlName="legal_entity" nzSize="large"
                                                   placeholder="Enter Legal Entity" id="legal_entity" OnlyCharacter/>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="contact">Contact</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Contact Details!">
                                            <input nz-input noWhitespace formControlName="contact_person" nzSize="large"
                                                   placeholder="Enter Contact Details" id="contact_person"
                                                   OnlyCharacter/>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="phone">Phone</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Phone No.!">
                                            <input nz-input noWhitespace formControlName="contact_person_phone"
                                                   nzSize="large"
                                                   placeholder="Enter Phone No." maxlength="10"
                                                   id="contact_person_phone" numsplOnly/>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="email">E-mail</nz-form-label>
                                        <nz-form-control nzErrorTip="The input is not valid E-mail!">
                                            <input nz-input noWhitespace formControlName="contact_person_email"
                                                   nzSize="large"
                                                   placeholder="Enter email Id" id="contact_person_email"/>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-label nzRequired nzFor="website">Website</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter website!">
                                            <input nz-input noWhitespace id="company_website"
                                                   placeholder="Enter website URL" nzSize="large"
                                                   formControlName="company_website" placeholder="website"/>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div class="col-md-6"></div>
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="default" (click)="generalForm.reset()">
                                        Reset
                                    </button>
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitGeneralForm()"
                                            [disabled]="!generalForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>

                        <!-- Profit Maximiser Settings Content -->
                        <div class="row setup-content tab-second"
                             *ngIf="contentIndex == 1 && loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes'">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="profitMaximiserForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h4 class="my-3">Features</h4>
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="sp_name">What would you call Strategic
                                                Perspective ?
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please Enter SP Name!">
                                                <input nz-input noWhitespace formControlName="sp_name" nzSize="large"
                                                       placeholder="Enter SP Name" id="sp_name"/>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="ap_name">What would you call Annual
                                                Priorities?
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please Enter AP Name!">
                                                <input nz-input noWhitespace formControlName="ap_name" nzSize="large"
                                                       placeholder="Enter AP Name" id="ap_name"/>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="objective_name">What would you call
                                                Objectives?
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="14" [nzXs]="24"
                                                             nzErrorTip="Please Enter Objectives Name!">
                                                <input nz-input noWhitespace formControlName="objective_name"
                                                       nzSize="large"
                                                       placeholder="Enter Objectives Name" id="objective_name"/>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="initiative_name">What would you call
                                                Initiatives?
                                            </nz-form-label>
                                            <nz-form-control [nzSm]="14" [nzXs]="24"
                                                             nzErrorTip="Please Enter Initiatives Name!">
                                                <input nz-input noWhitespace formControlName="initiative_name"
                                                       nzSize="large"
                                                       placeholder="Enter Initiatives Name" id="initiative_name"/>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-input-group [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="24">
                                                    <nz-form-label nzRequired nzFor="initiative_month_start">Initiative
                                                        Monthly Cycle
                                                    </nz-form-label>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <nz-form-item>
                                                        <nz-form-control
                                                                nzErrorTip="Please Enter Initiative Monthly Cycle!">
                                                            <nz-select name="initiative_month_start" nzSize="large"
                                                                       nz-input
                                                                       formControlName="initiative_month_start"
                                                                       [nzOptions]="listOfMonthlyCycleOption"
                                                                       placeholder="Please select value">
                                                                <nz-option nzLabel="1" nzValue="1" nzHide></nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">to</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <nz-form-item>
                                                        <nz-form-control
                                                                nzErrorTip="Please Enter Initiative Monthly Cycle!">
                                                            <nz-select name="initiative_month_end" nzSize="large"
                                                                       nz-input
                                                                       formControlName="initiative_month_end"
                                                                       [nzOptions]="listOfMonthlyCycleOption"
                                                                       placeholder="Please select value">
                                                                <nz-option nzLabel="1" nzValue="1" nzHide></nz-option>
                                                            </nz-select>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                    </div>
                                    <div class="col-md-6">
                                        <h4 class="my-3">Tile Configuration</h4>
                                        <div class="col-md-12">
                                            <nz-input-group [nzSize]="'large'">
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="6">
                                                        <nz-form-item>
                                                            <nz-form-control nzErrorTip="Please Select color picker!">
                                                                <input type="text" noWhitespace
                                                                       (ngModelChange)="onChangeTileRange1Color()"
                                                                       formControlName="tile_range_1_color" nz-input/>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="3">
                                                        <div class="box" [(colorPicker)]="tile_1_color"
                                                             (colorPickerChange)="profitMaximiserForm.controls.tile_range_1_color.setValue($event)"
                                                             [style.background]="tile_1_color"></div>
                                                    </div>
                                                    <div nz-col nzSpan="4">
                                                        <nz-form-item>
                                                            <nz-form-control nzRequired
                                                                             nzErrorTip="Please Select Range!">
                                                                <!-- <input type="text" formControlName="tile_range_1_min_perc" nz-input /> -->
                                                                <nz-input-number [nzSize]="'large'" class="w-100"
                                                                                 formControlName="tile_range_1_min_perc"
                                                                                 [nzMin]="1" [nzMax]="59" [nzStep]="1"
                                                                                 [nzPrecision]="0">
                                                                </nz-input-number>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="2">
                                                        <div class="mt-2 text-center">to</div>
                                                    </div>
                                                    <div nz-col nzSpan="4">
                                                        <nz-form-item>
                                                            <nz-form-control nzRequired
                                                                             nzErrorTip="Please Select Range!">
                                                                <!-- <input type="text" formControlName="tile_range_1_max_perc" nz-input /> -->
                                                                <nz-input-number [nzSize]="'large'" class="w-100"
                                                                                 formControlName="tile_range_1_max_perc"
                                                                                 [nzMin]="2" [nzMax]="60" [nzStep]="1"
                                                                                 [nzPrecision]="0">
                                                                </nz-input-number>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <nz-input-group [nzSize]="'large'">
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="6">
                                                        <nz-form-item>
                                                            <nz-form-control nzErrorTip="Please Select color picker!">
                                                                <input type="text" noWhitespace
                                                                       (ngModelChange)="onChangeTileRange2Color()"
                                                                       formControlName="tile_range_2_color" nz-input/>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="3">
                                                        <div class="box" [(colorPicker)]="tile_2_color"
                                                             (colorPickerChange)="profitMaximiserForm.controls.tile_range_2_color.setValue($event)"
                                                             [style.background]="tile_2_color"></div>
                                                    </div>
                                                    <div nz-col nzSpan="4">
                                                        <nz-form-item>
                                                            <nz-form-control nzRequired
                                                                             nzErrorTip="Please Select Range!">
                                                                <!-- <input type="text" formControlName="tile_range_2_min_perc" nz-input /> -->
                                                                <nz-input-number [nzSize]="'large'" class="w-100"
                                                                                 formControlName="tile_range_2_min_perc"
                                                                                 [nzMin]="61" [nzMax]="79" [nzStep]="1"
                                                                                 [nzPrecision]="0">
                                                                </nz-input-number>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="2">
                                                        <div class="mt-2 text-center">to</div>
                                                    </div>
                                                    <div nz-col nzSpan="4">
                                                        <nz-form-item>
                                                            <nz-form-control nzRequired
                                                                             nzErrorTip="Please Select Range!">
                                                                <!-- <input type="text" formControlName="tile_range_2_max_perc" nz-input /> -->
                                                                <nz-input-number [nzSize]="'large'" class="w-100"
                                                                                 formControlName="tile_range_2_max_perc"
                                                                                 [nzMin]="62" [nzMax]="80" [nzStep]="1"
                                                                                 [nzPrecision]="0">
                                                                </nz-input-number>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <nz-input-group [nzSize]="'large'">
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="6">
                                                        <nz-form-item>
                                                            <nz-form-control nzErrorTip="Please Select color picker!">
                                                                <input type="text" noWhitespace
                                                                       (ngModelChange)="onChangeTileRange3Color()"
                                                                       formControlName="tile_range_3_color" nz-input/>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="3">
                                                        <div class="box" [(colorPicker)]="tile_3_color"
                                                             (colorPickerChange)="profitMaximiserForm.controls.tile_range_3_color.setValue($event)"
                                                             [style.background]="tile_3_color"></div>
                                                    </div>
                                                    <div nz-col nzSpan="4">
                                                        <nz-form-item>
                                                            <nz-form-control nzRequired
                                                                             nzErrorTip="Please Select Range!">
                                                                <!-- <input type="text" formControlName="tile_range_3_min_perc" nz-input /> -->
                                                                <nz-input-number [nzSize]="'large'" class="w-100"
                                                                                 formControlName="tile_range_3_min_perc"
                                                                                 [nzMin]="61" [nzMax]="79" [nzStep]="1"
                                                                                 [nzPrecision]="0">
                                                                </nz-input-number>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="2">
                                                        <div class="mt-2 text-center">to</div>
                                                    </div>
                                                    <div nz-col nzSpan="4">
                                                        <nz-form-item>
                                                            <nz-form-control nzRequired
                                                                             nzErrorTip="Please Select Range!">
                                                                <!-- <input type="text" formControlName="tile_range_3_max_perc" nz-input /> -->
                                                                <nz-input-number [nzSize]="'large'" class="w-100"
                                                                                 formControlName="tile_range_3_max_perc"
                                                                                 [nzMin]="62" [nzMax]="80" [nzStep]="1"
                                                                                 [nzPrecision]="0">
                                                                </nz-input-number>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                        </div>
                                        <div class="col-md-12">
                                            <nz-input-group [nzSize]="'large'">
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="6">
                                                        <nz-form-item>
                                                            <nz-form-control nzErrorTip="Please Select color picker!">
                                                                <input type="text" noWhitespace
                                                                       (ngModelChange)="onChangeEffortMoreThanTargetColor()"
                                                                       formControlName="effort_more_than_target_color"
                                                                       nz-input/>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="3">
                                                        <div class="box" [(colorPicker)]="tile_4_color"
                                                             (colorPickerChange)="profitMaximiserForm.controls.effort_more_than_target_color.setValue($event)"
                                                             [style.background]="tile_4_color"></div>
                                                    </div>
                                                    <div nz-col nzSpan="12">
                                                        <div class="mt-2"> Effort >= Target</div>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <nz-input-group [nzSize]="'large'">
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="6">
                                                        <nz-form-item>
                                                            <nz-form-control nzErrorTip="Please Select color picker!">
                                                                <input type="text" noWhitespace
                                                                       (ngModelChange)="onChangeEffortLessThanTargetColor()"
                                                                       formControlName="effort_less_than_target_color"
                                                                       nz-input/>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="3">
                                                        <div class="box" [(colorPicker)]="tile_5_color"
                                                             (colorPickerChange)="profitMaximiserForm.controls.effort_less_than_target_color.setValue($event)"
                                                             [style.background]="tile_5_color"></div>
                                                    </div>
                                                    <div nz-col nzSpan="12">
                                                        <div class="mt-2"> Effort < Target</div>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <h4 class="my-3">Average Monthly Max Effort</h4>
                                        <nz-input-group class="mb-2" [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> January</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_jan" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_jan"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> July</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <!-- <input type="text" nz-input formControlName="max_effort_jul" /> -->
                                                    <nz-input-number [nzSize]="'large'" class="w-100"
                                                                     formControlName="max_effort_jul" [nzMin]="0"
                                                                     [nzMax]="500" [nzStep]="1">
                                                    </nz-input-number>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                        <nz-input-group class="mb-2" [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> February</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_feb" />  -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_feb"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> August</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_aug" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_aug"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                        <nz-input-group class="mb-2" [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> March</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_mar" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_mar"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> September</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_sep" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_sep"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                        <nz-input-group class="mb-2" [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> April</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_apr" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_apr"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> October</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_oct" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_oct"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                        <nz-input-group class="mb-2" [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> May</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_may" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_may"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> November</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_nov" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_nov"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                        <nz-input-group class="mb-2" [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> June</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_jun" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_jun"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                                <div nz-col nzSpan="6">
                                                    <div class="mt-2 text-left"> December</div>
                                                </div>
                                                <div nz-col nzSpan="4">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select Hrs!">
                                                            <!-- <input type="text" nz-input formControlName="max_effort_dec" /> -->
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="max_effort_dec"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1">
                                                            </nz-input-number>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="2">
                                                    <div class="mt-2 text-center">Hrs</div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                    </div>
                                    <div class="col-md-4">
                                        <h4 class="my-3">User Interface</h4>
                                        <nz-input-group [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="24">
                                                    <nz-form-label nzFor="Upload Logo">Upload Logo:
                                                    </nz-form-label>
                                                </div>
                                                <div nz-col [nzSm]="14" [nzXs]="24" class="ml-1 mt-1 mb-3 ">
                                                    <!-- <nz-upload nzSize="large" formControlName="image_file" ngDefaultControl [(nzFileList)]="fileList"
                                                      [nzBeforeUpload]="beforeUpload">
                                                      <a nz-button nzType="primary"><i nz-icon nzType="upload"></i>Upload File</a>
                                                    </nz-upload> -->

                                                    <!-- <nz-upload nzSize="large" formControlName="image_file" ngDefaultControl
                                                    [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                                                    <a nz-button nzType="primary"><i nz-icon nzType="upload"></i>Upload
                                                      File</a>
                                                  </nz-upload> -->

                                                    <nz-form-control nzErrorTip="Please upload Profile Picture!">
                                                        <nz-input-group [nzSize]="'large'">
                                                            <nz-upload class="avatar-uploader" nzName="avatar"
                                                                       formControlName="image_file"
                                                                       nzListType="picture-card"
                                                                       [nzShowUploadList]="false"
                                                                       [nzBeforeUpload]="beforeUpload"
                                                                       (nzChange)="handleChange($event)">
                                                                <ng-container *ngIf="!avatarUrl">
                                                                    <i class="upload-icon" nz-icon
                                                                       [nzType]="loading ? 'loading' : 'plus'"></i>
                                                                    <div class="ant-upload-text">Upload</div>
                                                                </ng-container>
                                                                <img *ngIf="avatarUrl" [src]="avatarUrl"
                                                                     class="imagePreviewBox"/>
                                                            </nz-upload>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                        <nz-input-group [nzSize]="'large'">
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="24">
                                                    <nz-form-label nzFor="Theme Colour">Theme Colour:
                                                    </nz-form-label>
                                                </div>
                                                <div nz-col nzSpan="8">
                                                    <nz-form-item>
                                                        <nz-form-control nzErrorTip="Please Select color picker!">
                                                            <input noWhitespace type="text"
                                                                   (ngModelChange)="onChangeThemeColor()"
                                                                   formControlName="theme_colour" nz-input/>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div nz-col nzSpan="3">
                                                    <div class="box" [(colorPicker)]="themeColor"
                                                         (colorPickerChange)="profitMaximiserForm.controls.theme_colour.setValue($event)"
                                                         [style.background]="themeColor"></div>
                                                </div>
                                            </div>
                                        </nz-input-group>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                  <div class="col-md-12 mt-4 text-center">
                                    <nz-form-item class="register-area">
                                      <nz-form-control>
                                        <button nz-button nzSize="large" nzType="primary" (click)="resetForm()">Cancel</button>
                                        <button nz-button nzSize="large" nzType="primary" [disabled]="!settingForm.valid">Save</button>
                                      </nz-form-control>
                                    </nz-form-item>
                                  </div>
                                </div> -->
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="default"
                                            (click)="profitMaximiserForm.reset()">Reset
                                    </button>
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitProfitMaximiserForm()"
                                            [disabled]="!profitMaximiserForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Points Structure Settings Content-->
                        <div class="row1 setup-content tab-third" *ngIf="contentIndex == 2">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="pointsStructureForm">
                                <div class="row">
                                    <!-- <div class="col-md-5">
                                    <h4 class="my-2">Behaviour Transactions Points</h4>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label>Displayer Points(Within Same BU, Function, Location)</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Displayer Points!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                              formControlName="btp_displayer_points_within_same" [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label> Displayer Points(Across Same BU, Function, Location)</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Displayer Points!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                              formControlName="btp_displayer_points_across_same" [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>

                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label>Observer Points(Within Same BU, Function, Location)</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Observer Points!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                              formControlName="btp_observer_points_within_same" [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label>Observer Points(Across Same BU, Function, Location)</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Observer Points!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                              formControlName="btp_observer_points_across_same" [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <h4 class="my-2">Challenge Points</h4>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label>Creation</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Creation!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100" formControlName="challenge_points_creation"
                                              [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label>Participation</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Participation!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                              formControlName="challenge_points_participation" [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label>Submission</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Submission!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                              formControlName="challenge_points_submission" [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                  </div> -->
                                    <div class="col-md-5">
                                        <div *ngIf="loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes'">
                                            <h4 class="my-2">Learning Assessment Points</h4>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>Each Correct Ans</nz-form-label>
                                                    <nz-form-control nzErrorTip="Please Select Each Correct Ans!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="lap_each_correct_answer"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1"
                                                                             [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>Each Correct Ans On Reattempt
                                                    </nz-form-label>
                                                    <nz-form-control
                                                            nzErrorTip="Please Select Each Correct Ans On Reattempt!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="lap_each_correct_answer_reattempt"
                                                                             [nzMin]="0" [nzMax]="500"
                                                                             [nzStep]="1" [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>> 80% Score</nz-form-label>
                                                    <nz-form-control nzErrorTip="Please Select > 80% Score!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="lap_greater_than_80"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1"
                                                                             [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>100% Score</nz-form-label>
                                                    <nz-form-control nzErrorTip="Please Select 100% Score!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="lap_100" [nzMin]="0"
                                                                             [nzMax]="500" [nzStep]="1"
                                                                             [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-5">
                                        <!-- <h4 class="my-2">Survey Points</h4>
                                      <nz-input-group>
                                        <nz-form-item>
                                          <nz-form-label>Submission</nz-form-label>
                                          <nz-form-control nzErrorTip="Please Select Submission!">
                                            <nz-input-group nzSize="large">
                                              <nz-input-number [nzSize]="'large'" class="w-100" formControlName="survey_points_submission"
                                                [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                              </nz-input-number>
                                            </nz-input-group>
                                          </nz-form-control>
                                        </nz-form-item>
                                      </nz-input-group>
                                      <h4 class="my-2">Poll Points</h4>
                                      <nz-input-group>
                                        <nz-form-item>
                                          <nz-form-label>Submission</nz-form-label>
                                          <nz-form-control nzErrorTip="Please Select Submission!">
                                            <nz-input-group nzSize="large">
                                              <nz-input-number [nzSize]="'large'" class="w-100" formControlName="poll_points_submission"
                                                [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                              </nz-input-number>
                                            </nz-input-group>
                                          </nz-form-control>
                                        </nz-form-item>
                                      </nz-input-group>
                                      <h4 class="my-2">Discussion Forum Points</h4>
                                      <nz-input-group>
                                        <nz-form-item>
                                          <nz-form-label>Comments</nz-form-label>
                                          <nz-form-control nzErrorTip="Please Select Comments!">
                                            <nz-input-group nzSize="large">
                                              <nz-input-number [nzSize]="'large'" class="w-100" formControlName="dfp_comments" [nzMin]="0"
                                                [nzMax]="500" [nzStep]="0.1">
                                              </nz-input-number>
                                            </nz-input-group>
                                          </nz-form-control>
                                        </nz-form-item>
                                      </nz-input-group> -->
                                        <div *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes'">
                                            <h4 class="my-2">Maximiser Settings Points</h4>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired> Point Target Achieved</nz-form-label>
                                                    <nz-form-control nzErrorTip="Please Select Point Target Achieved!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="point_target_achieved"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1"
                                                                             [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>Points Milestone Achieved</nz-form-label>
                                                    <nz-form-control
                                                            nzErrorTip="Please Select Points Milestone Achieved!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="points_milestone_achieved"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1"
                                                                             [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>Points Project Completion</nz-form-label>
                                                    <nz-form-control
                                                            nzErrorTip="Please Select Points Project Completion!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number [nzSize]="'large'" class="w-100"
                                                                             formControlName="points_project_completion"
                                                                             [nzMin]="0" [nzMax]="500" [nzStep]="1"
                                                                             [nzPrecision]="0">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>

                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes' ||
              loggedInUserDetail?.tenant?.enable_behaviour_transaction === 'yes' ||
              loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes'" class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="default"
                                            (click)="pointsStructureForm.reset()">Reset
                                    </button>
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitPointsStructureForm()"
                                            [disabled]="!pointsStructureForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- 360 Degree Score Settings Content-->
                        <div class="row1 setup-content tab-fourth"
                             *ngIf="(contentIndex == 3 && loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes') || (contentIndex == 3 && loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes')  ">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="degreeScoreForm">
                                <div class="col-md-24">
                                    <div class="row">
                                        <div *ngIf="loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes'">

                                            <div class="col-md-12">
                                                <h4 class="my-2 ">Scoring by Frequency</h4>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Almost Never</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Almost Never!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sbf_almost_never"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                                <!-- <input nz-input formControlName="sbf_almost_never"  placeholder="Enter input"/> -->
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Not Very Often</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Not Very Often!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sbf_not_very_often"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Some Of The Time</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Some Of The Time!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sbf_some_of_the_time"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Most Of The Time</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Most Of The Time!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sbf_most_of_the_time"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Never Always</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Never Always!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sbf_never_always"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <h4 class="my-2">Scoring By Ability</h4>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Clear Weakness</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Clear Weakness!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sba_clear_weakness"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Not Very Good</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Not Very Good!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sba_not_very_good"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Good</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Good !">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large" formControlName="sba_good"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Very Good</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Very Good !">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sba_very_good"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Clear Strength</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select Clear Strength!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sba_clear_strength"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                            </div>
                                            <div class="col-md-12">
                                                <h4 class="my-2">Scoring By Effectiveness</h4>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>1-20%</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select 1-20%!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large" formControlName="sbe_1_to_20"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>21-40%</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select 21-40%!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large" formControlName="sbe_21_to_40"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>41-60%</nz-form-label>
                                                        <nz-form-control nzSize="large"
                                                                         nzErrorTip="Please Select 41-60%!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large" formControlName="sbe_41_to_60"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>61-80%</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select 61-80%!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large" formControlName="sbe_61_to_80"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                                <nz-input-group>
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>81-100%</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Select 81-100%!">
                                                            <nz-input-group>
                                                                <nz-select nzSize="large"
                                                                           formControlName="sbe_81_to_100"
                                                                           nzPlaceHolder="Please select"
                                                                           [nzOptions]="listOfNoOfOption">
                                                                </nz-select>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </nz-input-group>
                                            </div>

                                        </div>
                                        <div *ngIf="loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes'">
                                            <div class="col-md-12" style="display:flex">
                                                <div>
                                                    <h4 class="my-2">Notification message</h4>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Self</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please enter notification message for Self">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="self_notify_message"
                                                                           placeholder="Enter Self Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Manager</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please enter notification message for Manager">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="manager_notify_message"
                                                                           placeholder="Enter Manager Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Peer</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please enter notification message for Peer">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="peer_notify_message"
                                                                           placeholder="Enter Peer Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Subordinate</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please enter notification message for Subordinate">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="subordinate_notify_message"
                                                                           placeholder="Enter Subornate Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <!--
                                                      Modified by Geo Thomas on 05-01-2022
                                                    -->
                                                    <!-- <nz-input-group>
                                                      <nz-form-item>
                                                        <nz-form-label nzRequired>Reviewer</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please input Reviewer message !">
                                                          <nz-input-group nzSize="large">
                                                            <input nz-input formControlName="reviewer_notify_message"
                                                              placeholder="Enter Reviewer Message" />
                                                          </nz-input-group>
                                                        </nz-form-control>
                                                      </nz-form-item>
                                                    </nz-input-group> -->
                                                </div>
                                                <div style="margin-left: 150px;">
                                                    <h4 class="my-2">Stake Holder Definition</h4>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Self</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please Select definition message!">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="self_definition_message"
                                                                           placeholder="Enter Self Definition Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Manager</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please Select definition message!">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="manager_definition_message"
                                                                           placeholder="Enter Manager Definition Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Peer</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please Select definition message !">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="peer_definition_message"
                                                                           placeholder="Enter Peer Definition Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>
                                                    <nz-input-group>
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Subordinate</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please Select definition message!">
                                                                <nz-input-group nzSize="large">
                                                                    <input nz-input
                                                                           formControlName="subordinate_definition_message"
                                                                           placeholder="Enter Subornate Definition Message"/>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </nz-input-group>

                                                    <!--
                                                      Modified by Geo Thomas on 05-01-2022
                                                    -->
                                                    <!-- <nz-input-group>
                                                      <nz-form-item>
                                                        <nz-form-label nzRequired>Reviewer</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please input definition message!">
                                                          <nz-input-group nzSize="large">
                                                            <input nz-input formControlName="reviewer_definition_message"
                                                              placeholder="Enter Reviewer Definition Message" />
                                                          </nz-input-group>
                                                        </nz-form-control>
                                                      </nz-form-item>
                                                    </nz-input-group> -->

                                                </div>
                                            </div>

                                        </div>


                                        <div class="col-md-12">

                                            <h4 class="my-2">Manager Invite Preference</h4>
                                            <nz-input-group>
                                                <nz-form-item>
                                                    <nz-form-label nzRequired>Restrict to Reporting Manager
                                                    </nz-form-label>
                                                    <nz-form-control nzErrorTip="Please Select an option!">
                                                        <nz-input-group nzSize="large">
                                                            <nz-switch
                                                                    formControlName="restrict_360_manager"></nz-switch>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </nz-input-group>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="default" (click)="degreeScoreForm.reset()">
                                        Reset
                                    </button>
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitDegreeScoreForm()"
                                            [disabled]="!degreeScoreForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Badges Settings  -->
                        <div class="row1 setup-content tab-fifth" *ngIf="contentIndex == 4 ">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="badgesForm">
                                <div class="row">
                                    <div class="col-md-5">
                                        <nz-input-group>
                                            <nz-form-item>
                                                <nz-form-label nzRequired>Level 1</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Name!">
                                                    <nz-input-group nzSize="large">
                                                        <input nz-input noWhitespace
                                                               formControlName="badges_setup_level_1_name"
                                                               placeholder="Enter Level Name"/>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </nz-input-group>
                                        <nz-input-group>
                                            <nz-form-item>
                                                <nz-form-label nzRequired>Level 2</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Name!">
                                                    <nz-input-group nzSize="large">
                                                        <input nz-input noWhitespace
                                                               formControlName="badges_setup_level_2_name"
                                                               placeholder="Enter Level Name"/>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </nz-input-group>
                                        <nz-input-group>
                                            <nz-form-item>
                                                <nz-form-label nzRequired>Level 3</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Name!">
                                                    <nz-input-group nzSize="large">
                                                        <input nz-input noWhitespace
                                                               formControlName="badges_setup_level_3_name"
                                                               placeholder="Enter Level Name"/>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </nz-input-group>
                                    </div>
                                    <div class="col-md-2"></div>
                                    <div class="col-md-5">
                                        <nz-input-group>
                                            <nz-form-item>
                                                <nz-form-label></nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Points!">
                                                    <!-- <nz-input-group nzSize="large">
                                                    <input  nz-input noWhitespace formControlName="badges_setup_level_1_score" placeholder="Enter Level Points" />
                                                 </nz-input-group> -->
                                                    <nz-input-number [nzSize]="'large'" placeholder="Enter Level Points"
                                                                     class="w-100"
                                                                     formControlName="badges_setup_level_1_score"
                                                                     [nzMin]="1" [nzMax]="1000" [nzStep]="1"
                                                                     [nzPrecision]="0">
                                                    </nz-input-number>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </nz-input-group>
                                        <nz-input-group>
                                            <nz-form-item>
                                                <nz-form-label></nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Points!">
                                                    <!-- <nz-input-group nzSize="large">
                                                    <input nz-input noWhitespace formControlName="badges_setup_level_2_score" placeholder="Enter Level Points" />
                                                  </nz-input-group> -->
                                                    <nz-input-number [nzSize]="'large'" placeholder="Enter Level Points"
                                                                     class="w-100"
                                                                     formControlName="badges_setup_level_2_score"
                                                                     [nzMin]="1" [nzMax]="1000" [nzStep]="1"
                                                                     [nzPrecision]="0">
                                                    </nz-input-number>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </nz-input-group>
                                        <nz-input-group>
                                            <nz-form-item>
                                                <nz-form-label></nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Points!">
                                                    <!-- <nz-input-group nzSize="large">
                                                    <input nz-input noWhitespace formControlName="badges_setup_level_3_score" placeholder="Enter Level Points" />
                                                  </nz-input-group> -->
                                                    <nz-input-number [nzSize]="'large'" placeholder="Enter Level Points"
                                                                     class="w-100"
                                                                     formControlName="badges_setup_level_3_score"
                                                                     [nzMin]="1" [nzMax]="1000" [nzStep]="1"
                                                                     [nzPrecision]="0">
                                                    </nz-input-number>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </nz-input-group>
                                    </div>
                                </div>
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="default" (click)="badgesForm.reset()">
                                        Reset
                                    </button>
                                    <button nz-button nzSize="large" (click)="submitBadgesForm()" nzType="primary"
                                            class="my-3"
                                            [disabled]="!badgesForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Behavior Transaction Settings  -->
                        <div class="row1 setup-content tab-sixth" *ngIf="contentIndex == 5 ">
                            <!-- <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="behaviorTransactionForm">
                              <div class="row">
                                <div class="col-md-4">
                                  <div *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction === 'yes'">
                                    <h4 class="my-2">Define Level Names</h4>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Principle Label</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Principle Label!">
                                          <nz-input-group nzSize="large">
                                            <input nz-input noWhitespace formControlName="principle_label"
                                                   placeholder="Enter Principle Label"/>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Category Label</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Category Label!">
                                          <nz-input-group nzSize="large">
                                            <input nz-input noWhitespace formControlName="category_label"
                                                   placeholder="Enter Category Label"/>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Behavior Label</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Behavior Label!">
                                          <nz-input-group nzSize="large">
                                            <input nz-input noWhitespace formControlName="behavior_label"
                                                   placeholder="Enter Behavior Label"/>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Nanobehavior Label</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Enter Nanobehavior Label!">
                                          <nz-input-group nzSize="large">
                                            <input nz-input noWhitespace formControlName="nanobehavior_label"
                                                   placeholder="Enter Nanobehavior Label"/>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                  </div>
                                </div>
                                <div class="col-md-2"></div>
                                <div class="col-md-4">
                                  <div *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction === 'yes'">
                                    <h4 class="my-2">Behavior Transactions Points</h4>

                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Observer Points For Same Business Unit/Function/Location
                                        </nz-form-label>
                                        <nz-form-control
                                          nzErrorTip="Please Select Observer Points For Same Business Unit/Function/Location!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'"
                                                             placeholder="Enter Observer Points For Same Business Unit/Function/Location"
                                                             class="w-100" formControlName="same_function_location_bu_observer_points"
                                                             [nzMin]="0"
                                                             [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Displayer Points For Same Business Unit/Function/Location
                                        </nz-form-label>
                                        <nz-form-control
                                          nzErrorTip="Please Select Displayer Points For Same Business Unit/Function/Location!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'"
                                                             placeholder="Enter Displayer Points For Same Business Unit/Function/Location"
                                                             class="w-100"
                                                             formControlName="same_function_location_bu_displayer_points" [nzMin]="0"
                                                             [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Observer Points For Different Business Unit/Function/Location
                                        </nz-form-label>
                                        <nz-form-control
                                          nzErrorTip="Please Select Observer Points For Different Business Unit/Function/Location!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'"
                                                             placeholder="Enter Observer Points For Different Business Unit/Function/Location"
                                                             class="w-100"
                                                             formControlName="different_function_location_bu_observer_points"
                                                             [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                    <nz-input-group>
                                      <nz-form-item>
                                        <nz-form-label nzRequired>Displayer Points For Different Business Unit/Function/Location
                                        </nz-form-label>
                                        <nz-form-control
                                          nzErrorTip="Please Select Displayer Points For Different Business Unit/Function/Location!">
                                          <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'"
                                                             placeholder="Enter Displayer Points For Different Business Unit/Function/Location"
                                                             class="w-100"
                                                             formControlName="different_function_location_bu_displayer_points"
                                                             [nzMin]="0" [nzMax]="500" [nzStep]="0.1">
                                            </nz-input-number>
                                          </nz-input-group>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </nz-input-group>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 text-center">
                                <button nz-button nzSize="large" nzType="default" (click)="behaviorTransactionForm.reset()">Reset
                                </button>
                                <button nz-button nzSize="large" (click)="submitBehaviorTransactionForm()" nzType="primary"
                                        class="my-3" [disabled]="!behaviorTransactionForm.valid">Save
                                </button>
                              </div>
                            </form> -->

                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="behaviourLevelForm">
                                <h4 class="my-2">Behaviour Level Name Configuration</h4><br>
                                <div formArrayName="behaviourlevels">
                                    <div class="row" style="margin-bottom: 10px;">
                                        <div class="col-md-5">
                                            <nz-form-label nzRequired>Behaviour Level Name</nz-form-label>
                                        </div>
                                        <!-- <div class="col-md-5">
                                          <nz-form-label nzRequired>Attach Desired Score To Level</nz-form-label>
                                        </div> -->
                                    </div>
                                    <div class="row" *ngFor="let blevel of desiredBLevelArray.controls; let k=index"
                                         [formGroupName]="k">
                                        <div class="col-md-5">
                                            <nz-form-item>
                                                <nz-form-label>Level {{k + 1}} </nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Level Name">
                                                    <nz-input-group nzSize="large">
                                                        <input nz-input noWhitespace formControlName="level_name"
                                                               placeholder="Enter Level Name"/>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>

                                        <div class="col-md-4">
                                            <nz-form-item>
                                                <nz-form-label></nz-form-label>
                                                <nz-form-control>
                                                    <nz-input-group nzSize="large">
                                                        <a title="Delete" class="mr-2 delete-icon"><i
                                                                class="ri-delete-bin-6-fill"
                                                                (click)="removeBLevel(k, blevel['controls']['id'].value)"></i></a>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <a title="Add" class="add-btn ml-3" (click)="addNewBLevel()"><i
                                            class="ri-add-circle-fill"></i></a>
                                </div>


                                <div class="text-center">
                                    <button nz-button nzSize="large" nzType="default"
                                            (click)="behaviourLevelForm.reset()">Reset
                                    </button>
                                    <button nz-button nzSize="large" (click)="submitBehaviourLevelForm()"
                                            [nzLoading]="submitLoading"
                                            nzType="primary" class="my-3" [disabled]="!behaviourLevelForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Assessment Settings  -->
                        <div class="setup-content tab-seventh" *ngIf="contentIndex == 6 ">

                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="assessmentSettingForm">
                                <h4 class="my-2">Assessment Configuration</h4>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Max Activities(Learning Assessment)</nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Max Activities(Learning Assessment)!">
                                        <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" formControlName="max_activities_la"
                                                             [nzMin]="1" [nzMax]="12"
                                                             [nzStep]="1" [nzPrecision]="0">
                                            </nz-input-number>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Max Activities(Assessment Center)</nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Max Activities(Assessment Center)!">
                                        <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'"
                                                             formControlName="max_activities_assessment_centre"
                                                             [nzMin]="1"
                                                             [nzMax]="12" [nzStep]="1" [nzPrecision]="0">
                                            </nz-input-number>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Max Score(Learning Assessment)</nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Max Score(Learning Assessment)!">
                                        <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" formControlName="max_score_la"
                                                             [nzMin]="1" [nzMax]="500"
                                                             [nzStep]="1" [nzPrecision]="0">
                                            </nz-input-number>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Max FARF</nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Max FARF ">
                                        <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" formControlName="max_farf" [nzMin]="1"
                                                             [nzMax]="5"
                                                             [nzStep]="1" [nzPrecision]="0">
                                            </nz-input-number>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Max FARF Duration</nz-form-label>
                                    <nz-form-control nzErrorTip="Please Enter Max FARF Duration!">
                                        <nz-input-group nzSize="large">
                                            <nz-input-number [nzSize]="'large'" formControlName="max_farf_duration"
                                                             [nzMin]="1" [nzMax]="5"
                                                             [nzStep]="1" [nzPrecision]="0">
                                            </nz-input-number>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <div class="text-center">
                                    <button nz-button nzSize="large" nzType="default"
                                            (click)="assessmentSettingForm.reset()">Reset
                                    </button>
                                    <button nz-button nzSize="large" (click)="submitAssessmentSettingForm()"
                                            nzType="primary" class="my-3"
                                            [disabled]="!assessmentSettingForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>


                        <!-- Desired level Settings  -->
                        <div class="setup-content tab-eight" *ngIf="contentIndex == 7 ">
                            <nz-tabset [nzSize]="'large'" nzCentered>
                                <nz-tab [nzTitle]="'Competency Level Name Configuration'">

                                    <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="desiredLevelForm">
                                        <h4 class="my-2">Competency Level Name Configuration</h4>
                                        <div formArrayName="levels">
                                            <div class="row" style="margin-bottom: 10px;">
                                                <div class="col-md-5">
                                                    <nz-form-label nzRequired>Competency Level Name</nz-form-label>
                                                </div>
                                                <div class="col-md-5">
                                                    <nz-form-label nzRequired>Attach Desired Score To Level
                                                    </nz-form-label>
                                                </div>
                                            </div>
                                            <div class="row"
                                                 *ngFor="let level of desiredLevelArray.controls; let i=index"
                                                 [formGroupName]="i">
                                                <div class="col-md-5">
                                                    <nz-form-item>
                                                        <nz-form-label>Level {{i + 1}} </nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Enter Level Name">
                                                            <nz-input-group nzSize="large">
                                                                <input nz-input noWhitespace
                                                                       formControlName="level_name"
                                                                       placeholder="Enter Level Name"/>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>

                                                    <!-- Hidden Input for order -->
                                                    <nz-form-item>
                                                        <nz-form-control>
                                                            <nz-input-group nzSize="large">
                                                                <input nz-input type="hidden" [ngModel]="i+1"
                                                                       formControlName="order">
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                    <!-- Hidde =n Input ends-->
                                                </div>
                                                <div class="col-md-3">
                                                    <nz-form-item>
                                                        <nz-form-label></nz-form-label>
                                                        <nz-form-control
                                                                nzErrorTip="Please Enter Max Activities(Assessment Center)!">
                                                            <nz-input-group nzSize="large">
                                                                <a (click)="makeLikeRadio(i)">
                                                                    <label class="question-checkbox" nz-checkbox
                                                                           formControlName="is_select">{{level['controls']['is_select'].value ? 'Selected' :
                                                                        'Un Selected'}}</label>
                                                                </a>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                                <div class="col-md-4">
                                                    <nz-form-item>
                                                        <nz-form-label></nz-form-label>
                                                        <nz-form-control>
                                                            <nz-input-group nzSize="large">
                                                                <a title="Delete" class="mr-2 delete-icon"><i
                                                                        class="ri-delete-bin-6-fill"
                                                                        (click)="removeLevel(i, level['controls']['id'].value)"></i></a>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <a title="Add" class="add-btn ml-3" (click)="addNewLevel()"><i
                                                    class="ri-add-circle-fill"></i></a>
                                        </div>


                                        <div class="text-center">
                                            <button nz-button nzSize="large" nzType="default"
                                                    (click)="desiredLevelForm.reset()">Reset
                                            </button>
                                            <button nz-button nzSize="large" (click)="submitDesiredLevelForm()"
                                                    nzType="primary" class="my-3"
                                                    [disabled]="!desiredLevelForm.valid">Save
                                            </button>
                                        </div>
                                    </form>

                                </nz-tab>
                                <nz-tab [nzTitle]="'Desired Score Scale'">
                                    <form class="mx-5" nz-form [nzLayout]="'horizontal'"
                                          [formGroup]="MaxDesiredsettingForm">
                                        <!-- <h4 class="my-2">Max Desired Score</h4> -->
                                        <div class="row">
                                            <div class="col-md-10">
                                                <nz-form-item>
                                                    <nz-form-label>Desired Score Scale</nz-form-label>
                                                    <nz-form-control nzErrorTip="Please Enter Desired Score Scale">
                                                        <nz-input-group nzSize="large">
                                                            <nz-input-number formControlName="value"
                                                                             placeholder="Enter Desired Score Scale">
                                                            </nz-input-number>
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>

                                        </div>

                                        <div class="text-center">
                                            <button nz-button nzSize="large" nzType="default"
                                                    (click)="MaxDesiredsettingForm.reset()">Reset
                                            </button>
                                            <button nz-button nzSize="large" nzType="primary" class="my-3"
                                                    [disabled]="!MaxDesiredsettingForm.valid"
                                                    (click)="maximumDesiredSubmit()">Save
                                            </button>
                                        </div>

                                    </form>

                                </nz-tab>
                            </nz-tabset>
                        </div>
                        <!-- Desired Level Settings ends here -->


                        <!-- 9 Box Settings Settings  -->
                        <div class="setup-content tab-nine" *ngIf="contentIndex == 8 ">


                            <form class="mx-5" nz-form [nzLayout]="'horizontal'" [formGroup]="nineBoxSettingGroup">
                                <h4 class="my-2" nzCentered>9 Box Setting</h4>

                                <div style="background: #ECECEC;padding:30px;">
                                    <div nz-row [nzGutter]="8">
                                        <div nz-col [nzSpan]="12">
                                            <nz-card nzTitle="Potential Levels" nzCentered>
                                                <div formArrayName="potentialLevels">
                                                    <div *ngFor="let potentialLevel of potentialLevels.controls; let i=index"
                                                         [formGroupName]="i">
                                                        <nz-form-item>
                                                            <nz-form-label>{{potentialLevel.controls.caption.value}}</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please Enter {{potentialLevel.controls.caption.value}}">
                                                                <nz-input-group nzSize="large"
                                                                                [nzPrefix]="suffixIconSearch">
                                                                    <input nz-input formControlName="value"
                                                                           (ngModelChange)="potentialChange(potentialLevel)"
                                                                           [readonly]="potentialLevel.controls.status.value=='true'"
                                                                           placeholder="Enter Enter {{potentialLevel.controls.caption.value}}">
                                                                </nz-input-group>
                                                                <ng-template #suffixIconSearch>
                                                                    <i>{{potentialLevel.controls.icon.value}}</i>
                                                                </ng-template>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-card>
                                        </div>

                                        <div nz-col [nzSpan]="12">
                                            <nz-card nzTitle="Performance Levels" nzCentered>
                                                <div formArrayName="performanceLevels">
                                                    <div *ngFor="let performanceLevel of performanceLevels.controls; let i=index"
                                                         [formGroupName]="i">
                                                        <nz-form-item>
                                                            <nz-form-label>{{performanceLevel.controls.caption.value}}</nz-form-label>
                                                            <nz-form-control
                                                                    nzErrorTip="Please Enter {{performanceLevel.controls.caption.value}}">
                                                                <nz-input-group nzSize="large"
                                                                                [nzPrefix]="suffixIconSearch">
                                                                    <input nz-input formControlName="value"
                                                                           (ngModelChange)="performanceChange(performanceLevel)"
                                                                           [readonly]="performanceLevel.controls.status.value=='true'"
                                                                           placeholder="Enter Enter {{performanceLevel.controls.caption.value}}">
                                                                </nz-input-group>
                                                                <ng-template #suffixIconSearch>
                                                                    <i>{{performanceLevel.controls.icon.value}}</i>
                                                                </ng-template>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-card>
                                        </div>
                                    </div>


                                </div>

                                <div class="text-center">
                                    <button nz-button nzSize="large" nzType="default" (click)="boxReset()">Reset
                                    </button>
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            [disabled]="!nineBoxSettingGroup.valid"
                                            (click)="nineBoxSubmit()">Save
                                    </button>
                                </div>

                            </form>


                        </div>
                        <!-- 9 Box Settings Settings ends here -->
                        <!-- Performance performance-max-score Settings  -->
                        <div class="setup-content tab-ten" *ngIf="contentIndex == 9 ">

                            <form class="mx-5" nz-form [nzLayout]="'horizontal'" [formGroup]="PerformanceMaxScoreForm">
                                <h4 class="my-2">Performance Settings</h4>
                                <ng-container formArrayName="dataItems">
                                    <ng-container
                                            *ngFor="let dataItem of PerformanceMaxScoreForm.get('dataItems').controls; let i = index">
                                        <nz-form-item [formGroupName]="i">
                                            <nz-form-label>{{ dataItem.get('key').value  | titleCase}}</nz-form-label>
                                            <nz-form-control
                                                    nzErrorTip="Please Enter {{ dataItem.get('key').value | titleCase}}">
                                                <nz-input-group nzSize="large">
                                                    <nz-input-number [nzDisabled]="true" formControlName="value"
                                                                     [placeholder]="'Enter ' + dataItem.get('key').value  | titleCase">
                                                    </nz-input-number>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </ng-container>
                                </ng-container>


                                <!-- <div class="text-center">
                                  <button nz-button nzSize="large" nzType="default" (click)="PerformanceMaxScoreForm.reset()">Reset
                                  </button>
                                  <button nz-button nzSize="large" nzType="primary" class="my-3"
                                    [disabled]="!PerformanceMaxScoreForm.valid" (click)="maximumPerformanceSubmit()">Save
                                  </button>
                                </div> -->

                            </form>
                        </div>
                        <!-- Performance Settings ends here -->

                        <!-- Time zone settings -->
                        <div class="row1 setup-content tab-eleven" *ngIf="contentIndex == 10">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="timeZoneForm">
                                <div class="col-md-6">
                                    <h4 class="my-3">Select The Time Zone</h4>
                                    <nz-form-item>
                                        <nz-form-label nzRequired>Time Zones</nz-form-label>
                                        <nz-form-control nzErrorTip="Please Select Time zone">
                                            <nz-input-group>
                                                <nz-select [nzSize]="'large'" nzShowSearch nzAllowClear
                                                           nzPlaceHolder="Select Time Zone"
                                                           formControlName="value">
                                                    <nz-option *ngFor="let zone of timeZones"
                                                               [nzLabel]="zone.country.country + '(' + zone.timezone_code + ')'"
                                                               [nzValue]="zone.id">
                                                    </nz-option>
                                                </nz-select>

                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>
                                <div class="col-md-6"></div>
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitTimeZoneForm()"
                                            [disabled]="!generalForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Time Zone settings ends here -->

                        <!-- Privacy settings -->
                        <div class="row1 setup-content tab-eleven" *ngIf="contentIndex == 11">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="privacyForm">
                                <h4 class="my-3">Setup Privacy Policy</h4>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Privacy Policy Text</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input Privacy Policy">
                                        <nz-input-group nzSize="large">
                                            <quill-editor [styles]="editorStyle" formControlName="value"></quill-editor>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitPrivacyForm()"
                                            [disabled]="!privacyForm.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Privacy settings ends here -->

                        <!-- Premature Closure and Deadline extends  -->
                        <div class="setup-content tab-eight" *ngIf="contentIndex == 13 ">
                            <nz-tabset [nzSize]="'large'" nzCentered>
                                <nz-tab [nzTitle]="'Premature Closure'">

                                    <form class="mx-5" nz-form [nzLayout]="'vertical'"
                                          [formGroup]="prematureClosureForm">
                                        <div formArrayName="categories">
                                            <div class="row"
                                                 *ngFor="let p of prematureClosureCategoryArray.controls; let i=index"
                                                 [formGroupName]="i">
                                                <div class="col-md-5">
                                                    <nz-form-item>
                                                        <nz-form-label>Category {{i + 1}} </nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Enter Category">
                                                            <nz-input-group nzSize="large">
                                                                <input nz-input noWhitespace
                                                                       formControlName="category_name"
                                                                       placeholder="Enter Category"/>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                                <div class="col-md-4">
                                                    <nz-form-item>
                                                        <nz-form-label></nz-form-label>
                                                        <nz-form-control>
                                                            <nz-input-group nzSize="large">
                                                                <a title="Delete" class="mr-2 delete-icon">
                                                                    <i class="ri-delete-bin-6-fill"
                                                                       (click)="removeCategory(i, p['controls']['id'].value, p['controls']['category_type'].value)"></i>
                                                                </a>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <a title="Add" class="add-btn ml-3"
                                               (click)="addCategory('premature_closure')"><i
                                                    class="ri-add-circle-fill"></i></a>
                                        </div>


                                        <div class="text-center">
                                            <button nz-button nzSize="large" nzType="default"
                                                    (click)="prematureClosureForm.reset()">
                                                Reset
                                            </button>
                                            <button nz-button nzSize="large" nzType="primary" class="my-3"
                                                    [disabled]="!prematureClosureForm.valid"
                                                    (click)="submitCategoryForm('premature_closure')">
                                                Save
                                            </button>
                                        </div>
                                    </form>

                                </nz-tab>
                                <nz-tab [nzTitle]="'Deadline Extend'">
                                    <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="deadlineExtendForm">
                                        <div formArrayName="categories">
                                            <div class="row"
                                                 *ngFor="let p of deadlineExtendCategoryArray.controls; let i=index"
                                                 [formGroupName]="i">
                                                <div class="col-md-5">
                                                    <nz-form-item>
                                                        <nz-form-label>Category {{i + 1}} </nz-form-label>
                                                        <nz-form-control nzErrorTip="Please Enter Category">
                                                            <nz-input-group nzSize="large">
                                                                <input nz-input noWhitespace
                                                                       formControlName="category_name"
                                                                       placeholder="Enter Category"/>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                                <div class="col-md-4">
                                                    <nz-form-item>
                                                        <nz-form-label></nz-form-label>
                                                        <nz-form-control>
                                                            <nz-input-group nzSize="large">
                                                                <a title="Delete" class="mr-2 delete-icon">
                                                                    <i class="ri-delete-bin-6-fill"
                                                                       (click)="removeCategory(i, p['controls']['id'].value, p['controls']['category_type'].value)"></i>
                                                                </a>
                                                            </nz-input-group>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <a title="Add" class="add-btn ml-3"
                                               (click)="addCategory('deadline_extend')"><i
                                                    class="ri-add-circle-fill"></i></a>
                                        </div>

                                        <div class="text-center">
                                            <button nz-button nzSize="large" nzType="default"
                                                    (click)="deadlineExtendForm.reset()">
                                                Reset
                                            </button>
                                            <button nz-button nzSize="large" nzType="primary" class="my-3"
                                                    [disabled]="!deadlineExtendForm.valid"
                                                    (click)="submitCategoryForm('deadline_extend')">
                                                Save
                                            </button>
                                        </div>

                                    </form>

                                </nz-tab>
                            </nz-tabset>
                        </div>
                        <!-- Premature Closure and Deadline extends ends here -->

                        <!-- Terms and condtions settings -->
                        <div class="row1 setup-content tab-eleven" *ngIf="contentIndex == 12">
                            <form class="mx-5" nz-form [nzLayout]="'vertical'" [formGroup]="termsForm">
                                <h4 class="my-3">Setup Terms & Conditions</h4>
                                <nz-form-item>
                                    <nz-form-label nzRequired>Terms And Conditions Text</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input Terms And Conditions">
                                        <nz-input-group nzSize="large">
                                            <quill-editor [styles]="editorStyle" formControlName="value"></quill-editor>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <div class="col-md-12 text-center">
                                    <button nz-button nzSize="large" nzType="primary" class="my-3"
                                            (click)="submitTermsForm()"
                                            [disabled]="!termsForm.valid">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- Terms and conditions ends here -->

                        <!-- Default Spacing settings -->
                        <div class="setup-content tab-nine" *ngIf="contentIndex == 14">
                            <div class="row" [formGroup]="spacingSettingGroup">
                                <div class="col-lg-3"></div>
                                <div class="col-lg-6">
                                    <h4 class="my-2" nzCentered>Default Spacing</h4>
                                    <div class="row" style="border: 1px gray solid;padding: 15px;">
                                        <div class="col-lg-6 label-style">
                                            <nz-form-item>
                                                <nz-form-label>Spacing</nz-form-label>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label>No of questions per round</nz-form-label>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label>Spacing within a round</nz-form-label>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label>Start time of question</nz-form-label>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label>No of Re-attempt round</nz-form-label>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-6">
                                            <nz-form-item>
                                                <nz-form-control nzErrorTip="Please Enter Spacing">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="spacing_days"
                                                                         placeholder="Enter Spacing">
                                                        </nz-input-number>&nbsp;&nbsp; Days
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item class="mt-3">
                                                <nz-form-control nzErrorTip="Please Enter No of questions per round">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="no_of_questions_per_round"
                                                                         placeholder="No of questions per round">
                                                        </nz-input-number>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item class="mt-3">
                                                <nz-form-control nzErrorTip="Please Enter Spacing within a round">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="spacing_within_a_round"
                                                                         [nzMax]="24"
                                                                         placeholder="Spacing within a round">
                                                        </nz-input-number>&nbsp;&nbsp; Hrs
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item class="mt-3">
                                                <nz-form-control nzErrorTip="Please Enter Start time of question">
                                                    <nz-input-group nzSize="large">
                                                        <nz-time-picker style="width: 50%;"
                                                                        formControlName="start_time_of_question"
                                                                        [nzUse12Hours]="true"
                                                                        (ngModelChange)="log($event)"
                                                                        nzFormat="h:mm a"></nz-time-picker>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item class="mt-3">
                                                <nz-form-control nzErrorTip="Please Enter No of Re-attempt round">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="no_of_reattempt_round"
                                                                         placeholder="No of Re-attempt round">
                                                        </nz-input-number>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3"></div>
                            </div>
                            <div class="text-center">
                                <button nz-button nzSize="large" nzType="default" (click)="spacingReset()"
                                        [nzLoading]="resetloading">Reset
                                </button>
                                <button nz-button nzSize="large" nzType="primary" class="my-3" [nzLo]
                                        [disabled]="!spacingSettingGroup.valid" [nzLoading]="isFormLoading"
                                        (click)="spacingSubmit()">Save
                                </button>
                            </div>
                        </div>
                        <!-- Default Spacing ends here -->


                        <!-- Expected % of Wrong Questions settings -->
                        <div class="setup-content tab-nine" *ngIf="contentIndex == 15">
                            <div class="row" [formGroup]="ExpectedWrongQuestionsSettingGroup">
                                <div class="col-lg-3"></div>
                                <div class="col-lg-6">
                                    <h4 class="my-2" nzCentered>Expected % of Wrong Questions</h4>
                                    <div class="row" style="border: 1px gray solid;padding: 15px;">
                                        <div class="col-lg-6 label-style">
                                            <nz-form-item>
                                                <nz-form-label>Best Case Scenario</nz-form-label>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label>Medium Case Scenario</nz-form-label>
                                            </nz-form-item>
                                            <nz-form-item>
                                                <nz-form-label>Worst Case Scenario</nz-form-label>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-6">
                                            <nz-form-item>
                                                <nz-form-control nzErrorTip="Please Enter Best Case Scenario">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="best_case_scenario"
                                                                         [nzMax]="100"
                                                                         placeholder="Enter Best Case Scenario">
                                                        </nz-input-number>
                                                        <span class="p_style">%</span>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item class="mt-3">
                                                <nz-form-control nzErrorTip="Please Enter Medium Case Scenario">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="medium_case_scenario"
                                                                         [nzMax]="100"
                                                                         placeholder="Medium Case Scenario">
                                                        </nz-input-number>
                                                        <span class="p_style">%</span>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                            <nz-form-item class="mt-3">
                                                <nz-form-control nzErrorTip="Please Enter Worst Case Scenario">
                                                    <nz-input-group nzSize="large">
                                                        <nz-input-number formControlName="worst_case_scenario"
                                                                         [nzMax]="100"
                                                                         placeholder="Worst Case Scenario">
                                                        </nz-input-number>
                                                        <span class="p_style">%</span>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3"></div>
                            </div>
                            <div class="text-center">
                                <button nz-button nzSize="large" nzType="default" (click)="spacingExpectedReset()"
                                        [nzLoading]="resetloading">Reset
                                </button>
                                <button nz-button nzSize="large" nzType="primary" class="my-3"
                                        [disabled]="!ExpectedWrongQuestionsSettingGroup.valid"
                                        [nzLoading]="isFormLoading"
                                        (click)="spacingExpectedSubmit()">Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Expected % of Wrong Questions ends here -->

                    <!-- Talent card status settings -->
                    <div class="setup-content tab-nine" *ngIf="contentIndex == 16">
                        <div class="row" [formGroup]="talentStatusForm">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-6">
                                <h4 class="my-2" nzCentered>Talent card status</h4>
                                <div class="row" style="border: 1px gray solid;padding: 15px;">
                                    <nz-form-item style="width: 65%;" class="mb-0">
                                        <nz-form-control>
                                            <nz-select [nzSize]="'large'" nzAllowClear nzPlaceHolder="Select status"
                                                       formControlName="talentStatus">
                                                <nz-option *ngFor="let item of talentCard" [nzLabel]="item.value"
                                                           [nzValue]="item.value">
                                                </nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <button nz-button nzSize="large" nzType="primary" class="my-0 ml-5"
                                            [disabled]="!talentStatusForm.valid"
                                            [nzLoading]="isStatusFormLoading" (click)="statusSubmit()">Save
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-3"></div>
                        </div>
                    </div>
                    <!-- Talent card status settings ends here -->

                    <!-- 360 Report Threshold Settings start here  -->
                    <div class="setup-content tab-ten" *ngIf="contentIndex == 17">

                        <form class="mx-5" nz-form [nzLayout]="'horizontal'" [formGroup]="ReportThresholdForm">
                            <h4 class="my-2">Report Threshold</h4>
                            <ng-container formArrayName="dataReports">
                                <ng-container
                                        *ngFor="let dataReports of ReportThresholdForm.get('dataReports').controls; let i = index">
                                    <nz-form-item [formGroupName]="i">
                                        <nz-form-label>{{ dataReports.get('key').value  | titleCaseUnderscore}}</nz-form-label>
                                        <nz-form-control
                                                nzErrorTip="The Value must be smaller than High Potential: {{highPotentialValue}}">
                                            <nz-input-group nzSize="large">
                                                <nz-input-number formControlName="value"
                                                                 [placeholder]="'Enter ' + dataReports.get('key').value  | titleCase">
                                                </nz-input-number>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </ng-container>
                            </ng-container>


                            <div class="text-center">
                                <!-- <button nz-button nzSize="large" nzType="default" (click)="ReportThresholdForm.reset()">Reset
                                </button> -->
                                <button nz-button nzSize="large" nzType="primary" class="my-3"
                                        [disabled]="!ReportThresholdForm.valid" (click)="ReportThresholdSubmit()">Update
                                </button>
                            </div>

                        </form>
                    </div>
                    <!-- 360 Report Threshold Settings ends here -->


                    <!-- Sucession Management Settings start here  -->
                    <div class="setup-content tab-ten" style="margin-bottom: 15px" *ngIf="contentIndex == 18">

                        <form class="mx-5" nz-form [nzLayout]="'horizontal'" [formGroup]="SuccessionManagementForm">
                            <h4 class="my-2">Succession Management</h4>

                            <div class="row col-md-12 mt-4">
                                <div class="col-xl-4 col-lg-4 col-md-6">

                                    <nz-form-item>
                                        <nz-input-group>
                                            <nz-form-label nzRequired>Critical Role Approval</nz-form-label>
                                            <br>
                                            <nz-radio-group [nzSize]="'large'" formControlName="critical_role_approval">
                                                <label class="mr-4" nz-radio nzValue='inactive'>Disable</label>
                                                <label nz-radio nzValue='active'>Enable</label>
                                            </nz-radio-group>
                                        </nz-input-group>
                                    </nz-form-item>

                                </div>
                                <div class="col-xl-1 col-lg-0"></div>
                                <div class="col-xl-4 col-lg-4 col-md-6">

                                  <nz-form-item>
                                    <nz-input-group>
                                      <nz-form-label nzRequired>Role Parameter</nz-form-label>
                                      <br>
                                      <nz-radio-group [nzSize]="'large'" formControlName="role_parameter">
                                        <label class="mr-4" nz-radio nzValue='inactive'>Disable</label>
                                        <label nz-radio nzValue='active'>Enable</label>
                                      </nz-radio-group>
                                    </nz-input-group>
                                  </nz-form-item>

<!--                                    <nz-form-item>-->
<!--                                        <nz-input-group>-->
<!--                                            <nz-form-label nzRequired>Critical Level</nz-form-label>-->
<!--                                            <br>-->
<!--                                            <nz-radio-group [nzSize]="'large'" formControlName="critical_level">-->
<!--                                                <label [nzDisabled]="loggedInUserDetail.tenant_name != 'lupin'" class="mr-4" nz-radio nzValue='inactive'>Disable</label>-->
<!--                                                <label [nzDisabled]="loggedInUserDetail.tenant_name != 'lupin'" nz-radio nzValue='active'>Enable</label>-->
<!--                                            </nz-radio-group>-->
<!--                                        </nz-input-group>-->
<!--                                    </nz-form-item>-->

                                </div>
                            </div>

                          <hr>

                          <div class="row align-items-center">
                                <div class="col-xl-4 col-lg-4 col-md-6">

                                  <nz-form-item>
                                    <nz-form-label [nzSpan]="24" nzRequired>Career Plan Update Interval</nz-form-label>
                                    <nz-form-control [nzSpan]="24"
                                      nzErrorTip="Please Enter Career Plan Update Interval!">
                                      <nz-select formControlName="career_plan_update"
                                                 nzPlaceHolder="Please select Interval">
                                        <nz-option *ngFor="let item of listOfMonths" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
                                      </nz-select>
                                    </nz-form-control>
                                  </nz-form-item>

                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-6 mt-3">
                                  <button [disabled]="!career_plan_update?.value" nz-button (click)="updateCareerPlanInterval()" nzType="primary">Update</button>
                                </div>
                            </div>

                            <div class="sub-form mt-4"
                                 *ngIf="SuccessionManagementForm.get('role_parameter')?.value =='active'">
                                <h5 class="mt-1">Critical Levels</h5>
                                <div class="row justify-content-md-center mt-4" formArrayName="criticality_levels">
                                    <ng-container
                                            *ngFor="let criticality_levels_control of criticality_levels.controls; let k=index"
                                            [formGroupName]="k">
                                        <div class="row col-md-12">

                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="from_percentage" nzRequired>From
                                                        (%)
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24">
                                                        <input nz-input formControlName="from_percentage" type="number"
                                                               placeholder="From Percentage" readonly/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="to_percentage" nzRequired>To
                                                        (%)
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24"
                                                                     nzErrorTip="Must be greater than From Percentage & Total of To fields must be sum of 100">
                                                        <input nz-input formControlName="to_percentage" type="number"
                                                               placeholder="To Percentage"/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="label" nzRequired>Label
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24"
                                                                     nzErrorTip="Enter Valid Label">
                                                        <input nz-input formControlName="label"
                                                               placeholder="Label"/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" [nzFor]="'color' + k" nzRequired>
                                                        Color
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24" [nzErrorTip]="'Select Valid Color'">
                                                        <input [(colorPicker)]="controlColors[k]"
                                                               [style.background]="controlColors[k]"
                                                               [value]="controlColors[k]" formControlName="color"
                                                               style="width: 85px" readonly nz-input/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="actions" nzRequired>Action
                                                    </nz-form-label>
                                                    <button [disabled]="k == 0" nz-button (click)="removeRowCL(k)"
                                                            nzType="primary" class="btn-red">Remove
                                                    </button>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                                <div class="row ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button (click)="addRowCL()" nzType="primary">Add</button>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                                <div class="row mt-4 ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button nzType="primary" class="btn-green" (click)="submitSMCriticalLevels()">Update Critical levels
                                            </button>
<!--                                            <button nz-button nzType="primary" class="btn-red">Cancel-->
<!--                                            </button>-->
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>
                            </div>


                            <!-- adding Readiness Level - Nafi -->


                            <div class="sub-form mt-4">
                                <h5 class="mt-1">Readiness Level</h5>
                                <div class="row justify-content-md-center mt-4" formArrayName="readiness_levels">
                                    <ng-container
                                            *ngFor="let criticality_levels_control of readiness_levels.controls; let k=index"
                                            [formGroupName]="k">
                                        <div class="row col-md-12">




                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="label" nzRequired>Level Name
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24"
                                                                     nzErrorTip="Enter Valid Label">
                                                        <input nz-input formControlName="rediness_level_name"
                                                               placeholder="Label"/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>

                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="to_months" nzRequired>Type
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24"
                                                                     nzErrorTip="Retired and Incumbent Cannot Be Repeated">
                                                                     <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a Type" formControlName="type">
                                                                        <nz-option nzLabel="Incumbent" nzValue="incumbent"></nz-option>
                                                                        <nz-option nzLabel="Retired" nzValue="retired"></nz-option>
                                                                        <nz-option nzLabel="Decrement" nzValue="decrement"></nz-option>
                                                                      </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>

                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="from_month" nzRequired>From
                                                        (Month)
                                                    </nz-form-label>

                                                    <nz-form-control [nzSpan]="24" nzErrorTip="Must be greater than From To percentage">

                                                        <input nz-input formControlName="from_month"
                                                               placeholder="From Month" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="to_months" nzRequired>To
                                                        (Month)
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24"
                                                                     nzErrorTip="Must be greater than From Percentage">
                                                        <input nz-input formControlName="to_months" type="number"
                                                               placeholder="To Month"/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>



                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" [nzFor]="'color' + k" nzRequired>
                                                        Color
                                                    </nz-form-label>
                                                    <nz-form-control [nzSpan]="24" [nzErrorTip]="'Select Valid Color'">
                                                        <input [(colorPicker)]="RLcontrolColors[k]"
                                                               [style.background]="RLcontrolColors[k]"
                                                               [value]="RLcontrolColors[k]" formControlName="color"
                                                               style="width: 85px" readonly nz-input/>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-xl-2 col-lg-6 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="actions" nzRequired>Action
                                                    </nz-form-label>
                                                    <button [disabled]="k == 0" nz-button (click)="removeRowReadinessLevel(k)"
                                                            nzType="primary" class="btn-red">Remove
                                                    </button>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                                <div class="row ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button (click)="addRowReadinessLevel()" nzType="primary">Add</button>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                                <div class="row mt-4 ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button nzType="primary" class="btn-green" (click)="submitReadinessLevel()">Update Readiness levels
                                            </button>
<!--                                            <button nz-button nzType="primary" class="btn-red">Cancel-->
<!--                                            </button>-->
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>
                            </div>


                            <!-- End adding Readiness Level - Nafi -->


                            <div class="sub-form mt-4"
                                 *ngIf="SuccessionManagementForm.get('role_parameter')?.value =='active'">
                                <h5 class="mt-1">Role Parameters</h5>
                                <div class="row justify-content-md-center mt-4" formArrayName="role_parameters">
                                    <ng-container
                                            *ngFor="let role_parameters_control of role_parameters.controls; let k=index"
                                            [formGroupName]="k">
                                        <div class="row col-md-12">

                                            <div class="col-xl-4 col-lg-4 col-md-12">
                                              <nz-form-item>
                                                <nz-form-label [nzSpan]="24" nzRequired nzFor="parameter_name">Parameter Name</nz-form-label>
                                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Parameter Name">
                                                  <nz-input-group>
                                                    <input nz-input id="parameter_name" formControlName="parameter_name" placeholder="Enter Parameter Name"/>
                                                  </nz-input-group>
                                                </nz-form-control>
                                              </nz-form-item>
                                            </div>
                                            <div class="col-xl-1 col-lg-1 col-md-12">
                                              <nz-form-item>
                                              <nz-form-label [nzSpan]="24" nzFor="min_score" nzRequired>Min Score
                                              </nz-form-label>
                                              <nz-form-control [nzSpan]="24"
                                                               nzErrorTip="Enter Min Score">
                                                <input nz-input formControlName="min_score" type="number"
                                                       placeholder="Score"/>
                                              </nz-form-control>
                                              </nz-form-item>
                                            </div>

                                            <div class="col-xl-1 col-lg-1 col-md-12">
                                              <nz-form-item>
                                                <nz-form-label [nzSpan]="24" nzFor="max_score" nzRequired>Max Score
                                                </nz-form-label>
                                                <nz-form-control [nzSpan]="24"
                                                                 nzErrorTip="Enter Max Score">
                                                  <input nz-input formControlName="max_score" type="number"
                                                         placeholder="Score"/>
                                                </nz-form-control>
                                              </nz-form-item>
                                            </div>

                                            <div class="col-xl-2 col-lg-2 col-md-12">
                                              <nz-form-item>
                                              <nz-form-label [nzSpan]="24" nzFor="weightage" nzRequired>Weightage
                                              </nz-form-label>
                                              <nz-form-control [nzSpan]="24"
                                                               nzErrorTip="Enter Weightage, Only 2 digits are allowed after Decimal Point">
                                                <input nz-input formControlName="weightage" type="number"
                                                       placeholder="Weightage"/>
                                              </nz-form-control>
                                              </nz-form-item>
                                            </div>

                                            <div class="col-xl-2 col-lg-2 col-md-12">
                                              <nz-form-item *ngIf="!this.previous_data_rp[k]?.weightage">
                                                <nz-form-label [nzSpan]="24" nzFor="default_value" nzRequired>Default Value
                                                </nz-form-label>
                                                <nz-form-control [nzSpan]="24"
                                                                 nzErrorTip="Enter Default Value">
                                                  <input nz-input formControlName="default_value" type="number"
                                                         placeholder="Default"/>
                                                </nz-form-control>
                                              </nz-form-item>
                                            </div>

                                            <div class="col-xl-2 col-lg-2 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="actions" nzRequired>Action
                                                    </nz-form-label>
                                                    <button [disabled]="k == 0" nz-button (click)="removeRowRP(k)"
                                                            nzType="primary" class="btn-red">Remove
                                                    </button>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                                <div class="row ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button (click)="addRowRP()" nzType="primary">Add</button>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                                <div class="row mt-4 ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button nzType="primary" class="btn-green" (click)="submitSMParameterRoles()">Update Role Parameters
                                            </button>
<!--                                            <button nz-button nzType="primary" class="btn-red">Cancel-->
<!--                                            </button>-->
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>
                            </div>

                            <div class="sub-form mt-4 ">
                                <h5 class="mt-1">Managerial Levels</h5>
                                <div class="row justify-content-md-center mt-4" formArrayName="managerial_levels">
                                    <ng-container
                                            *ngFor="let managerial_levels_control of managerial_levels.controls; let k=index"
                                            [formGroupName]="k">
                                        <div class="row col-md-12">

                                            <div class="col-xl-3 col-lg-3 col-md-12">
                                              <nz-form-item>
                                                <nz-form-label [nzSpan]="24" nzRequired nzFor="level_name">Level Name</nz-form-label>
                                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Level Name">
                                                  <nz-input-group>
                                                    <input nz-input id="level_name" formControlName="level_name" placeholder="Enter Level Name"/>
                                                  </nz-input-group>
                                                </nz-form-control>
                                              </nz-form-item>
                                            </div>
<!--                                            <div class="col-xl-6 col-lg-6 col-md-12">-->
<!--                                              <nz-form-item>-->
<!--                                                <nz-form-label [nzSpan]="24" nzFor="grade_ids" nzNoColon="false" nzRequired>Grades</nz-form-label>-->
<!--                                                <nz-form-control [nzSpan]="24" nzErrorTip="Grade is mandatory.">-->
<!--                                                  <nz-input-group>-->
<!--                                                    <nz-select nzAllowClear nzMode="multiple"-->
<!--                                                               nzPlaceHolder="Select Grade" formControlName="grade_ids">-->
<!--                                                      <nz-option *ngFor="let item of gradeListOption" [nzLabel]="item.grade_name" [nzValue]="item.id">-->
<!--                                                      </nz-option>-->
<!--                                                    </nz-select>-->
<!--                                                  </nz-input-group>-->
<!--                                                </nz-form-control>-->
<!--                                              </nz-form-item>-->
<!--                                            </div>-->

                                            <div class="col-xl-3 col-lg-3 col-md-12">
                                              <nz-form-item>
                                                <nz-input-group>
                                                  <nz-form-label [nzSpan]="24" nzRequired>Leadership Role</nz-form-label>
                                                  <nz-radio-group [nzSpan]="24" formControlName="leadership_role">
                                                    <label class="mr-4" nz-radio nzValue='no'>No</label>
                                                    <label nz-radio nzValue='yes'>Yes</label>
                                                  </nz-radio-group>
                                                </nz-input-group>
                                              </nz-form-item>
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-12">
                                              <nz-form-item *ngIf="managerial_levels.controls[k].value.id">
                                                <nz-input-group>
                                                  <nz-form-label [nzSpan]="24" nzRequired>Manage Employees</nz-form-label>
                                                  <button nz-button (click)="manageEmployeeModal(managerial_levels.controls[k].value.id,managerial_levels.controls[k].value.level_name)"
                                                          nzType="primary" class="btn-red">Manage
                                                  </button>
                                                </nz-input-group>
                                              </nz-form-item>
                                            </div>


                                            <div class="col-xl-1 col-lg-1 col-md-12">
                                                <nz-form-item>
                                                    <nz-form-label [nzSpan]="24" nzFor="actions" nzRequired>Action
                                                    </nz-form-label>
                                                    <button [disabled]="k == 0" nz-button (click)="removeRowML(k)"
                                                            nzType="primary" class="btn-red">Remove
                                                    </button>
                                                </nz-form-item>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                                <div class="row ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button (click)="addRowML()" nzType="primary">Add</button>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>

                              <form nz-form [nzLayout]="'vertical'" [formGroup]="validateForm">

                                <nz-modal [nzStyle]="{ top: '10px' }" nzWidth="900px" [(nzVisible)]="isMLEmployeeModal" nzClosable="true" nzMaskClosable="false"
                                          nzTitle="Manage Sub Admin's Managerial Level" (nzOnCancel)="handleCancel()">
                                  <ng-container *nzModalContent>

                                    <div class="row">

                                      <div class="col-xl-6 col-sm-6">
<!--                                        <h5>Employee Filters</h5>-->
<!--                                        <hr>-->
                                          <nz-form-item>
                                            <nz-form-label [nzSpan]="24" nzFor="business_unit_id" nzNoColon="false">Business unit</nz-form-label>
                                            <nz-form-control [nzSpan]="24" nzErrorTip="Business Unit is Mandatory">
                                              <nz-input-group>
                                                <nz-select nzAllowClear nzMode="multiple"
                                                           nzPlaceHolder="Select Business Unit" formControlName="business_unit_id"
                                                           class="buSelectControlClass">
                                                  <nz-option *ngIf="showSelectBusiness" nzLabel="Select All" nzValue="select_all"></nz-option>
                                                  <ng-container *ngFor="let item of bUOptionList">
                                                    <nz-option *ngIf="!isBusinessUnitOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                                                    </nz-option>
                                                  </ng-container>
                                                  <nz-option *ngIf="isBusinessUnitOptionListLoading" nzDisabled nzCustomContent>
                                                    <i nz-icon nzType="loading" class="loading-icon"></i>
                                                    Loading Data...
                                                  </nz-option>
                                                </nz-select>
                                              </nz-input-group>
                                            </nz-form-control>
                                          </nz-form-item>

                                          <nz-form-item>
                                            <nz-form-label [nzSpan]="24" nzFor="function_id" nzNoColon="false">Functions</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                              <nz-input-group>
                                                <nz-select (nzOpenChange)="onFunctionOpened($event)"
                                                           [nzDisabled]="business_unit_id.value?.length <= 0" nzAllowClear
                                                           nzMode="multiple" nzPlaceHolder="Select Function"
                                                           formControlName="function_id" class="funcSelectControlClass">
                                                  <nz-option *ngIf="showSelectFunction" nzLabel="Select All" nzValue="select_all"></nz-option>
                                                  <ng-container *ngFor="let item of functionOptionList">
                                                    <nz-option *ngIf="!isFunctionOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                                                    </nz-option>
                                                  </ng-container>
                                                  <nz-option *ngIf="isFunctionOptionListLoading" nzDisabled nzCustomContent>
                                                    <i nz-icon nzType="loading" class="loading-icon"></i>
                                                    Loading Data...
                                                  </nz-option>
                                                </nz-select>
                                              </nz-input-group>
                                            </nz-form-control>
                                          </nz-form-item>

                                          <nz-form-item>
                                            <nz-form-label [nzSpan]="24" nzFor="department_id" nzNoColon="false">Departments</nz-form-label>
                                            <nz-form-control [nzSpan]="24">
                                              <nz-input-group>
                                                <nz-select (nzOpenChange)="onDepartmentOpened($event)" [nzDisabled]="function_id.value?.length <= 0"
                                                           nzAllowClear
                                                           nzMode="multiple" nzPlaceHolder="Select Department"
                                                           formControlName="department_id" class="DeptSelectControlClass">
                                                  <nz-option *ngIf="showSelectDepartment" nzLabel="Select All" nzValue="select_all"></nz-option>
                                                  <ng-container *ngFor="let item of departmentOptionList">
                                                    <nz-option *ngIf="!isDepartmentOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                                                    </nz-option>
                                                  </ng-container>
                                                  <nz-option *ngIf="isDepartmentOptionListLoading" nzDisabled nzCustomContent>
                                                    <i nz-icon nzType="loading" class="loading-icon"></i>
                                                    Loading Data...
                                                  </nz-option>
                                                </nz-select>
                                              </nz-input-group>
                                            </nz-form-control>
                                          </nz-form-item>

                                          <nz-form-item>
                                            <nz-form-label [nzSpan]="24" nzFor="location_id" nzNoColon="false">Locations</nz-form-label>
                                            <nz-form-control [nzSpan]="24" nzErrorTip="Location is mandatory.">
                                              <nz-input-group>
                                                <nz-select (nzOpenChange)="onLocationOpened($event)"
                                                           [nzDisabled]="business_unit_id.value?.length <= 0" nzAllowClear
                                                           nzMode="multiple" nzPlaceHolder="Select Location"
                                                           formControlName="location_id" class="locSelectControlClass">
                                                  <nz-option *ngIf="showSelectLocation" nzLabel="Select All" nzValue="select_all"></nz-option>
                                                  <ng-container *ngFor="let item of locationOptionList">
                                                    <nz-option *ngIf="!isLocationOptionListLoading" [nzLabel]="item.name" [nzValue]="item.id">
                                                    </nz-option>
                                                  </ng-container>
                                                  <nz-option *ngIf="isLocationOptionListLoading" nzDisabled nzCustomContent>
                                                    <i nz-icon nzType="loading" class="loading-icon"></i>
                                                    Loading Data...
                                                  </nz-option>
                                                </nz-select>
                                              </nz-input-group>
                                            </nz-form-control>
                                          </nz-form-item>

                                          <nz-form-item>
                                            <nz-form-label [nzSpan]="24" nzFor="employee_grade_id" nzNoColon="false">Grades</nz-form-label>
                                            <nz-form-control [nzSpan]="24" nzErrorTip="Grade is mandatory.">
                                              <nz-input-group>
                                                <nz-select nzAllowClear nzMode="multiple" (nzOpenChange)="onGradeOpened($event)"
                                                           [nzDisabled]="business_unit_id.value?.length <= 0"
                                                           nzPlaceHolder="Select Grade" formControlName="employee_grade_id"
                                                           class="gradeSelectControlClass">
                                                  <nz-option *ngIf="showSelectGrades" nzLabel="Select All" nzValue="select_all"></nz-option>
                                                  <nz-option *ngFor="let item of gradeListOption" [nzLabel]="item.grade_name" [nzValue]="item.id">
                                                  </nz-option>
                                                </nz-select>
                                              </nz-input-group>
                                            </nz-form-control>
                                          </nz-form-item>


                                      </div>

                                      <div class="col-xl-6 col-sm-6">

                                        <nz-form-item class="sub-form">
                                          <nz-form-label [nzSpan]="24" nzFor="employee_id" nzNoColon="false" nzRequired>Select Sub-Admin
                                          </nz-form-label>
                                          <nz-form-control [nzSpan]="24" nzErrorTip="Select Employee">

<!--                                            nzShowSearch nzServerSearch (nzOnSearch)="onSearchEmployee($event)"-->
                                            <nz-select nzAllowClear [nzMode]="'multiple'" (nzScrollToBottom)="loadMore()" (nzOpenChange)="onEmployeeOpened($event)"
                                                         nzPlaceHolder="Select Employee"
                                                         formControlName="employee_id">
                                                <ng-container *ngFor="let item of employeeList">
                                                  <nz-option *ngIf="!isEmployeeListLoading"
                                                             [nzLabel]="item.employee_id + ' - '+ item.first_name + ' ' + item.last_name + '(' + item?.user?.email + ')'"
                                                             [nzValue]="item.id">
                                                  </nz-option>
                                                </ng-container>
                                                <nz-option *ngIf="isEmployeeListLoading" nzDisabled nzCustomContent>
                                                  <i nz-icon nzType="loading" class="loading-icon"></i>
                                                  Loading Data...
                                                </nz-option>
                                              <nz-option *ngIf="showSelectEmployeeML && !isEmployeeListLoading" nzLabel="Select All" nzValue="select_all"></nz-option>
                                              </nz-select>
                                          </nz-form-control>
                                          <button class="mt-2" nz-button nzType="primary" (click)="addEmployeesToManagerialLevel()">Add</button>
                                        </nz-form-item>

                                        <div class="sub-form-purple">
                                          <h6>Sub Admin's Mapped with Managerial Level - <b>{{managerialLevelSelectedName}}</b></h6>
                                          <hr>
                                          <div class="row align-items-center card-employees" *ngFor="let item of managerialLevelEmployees">
                                            <div class="col-lg-2 justify-content-center" >

                                              <img *ngIf="!item?.employee_data?.profile_pic?.original_image_url"
                                                   src="./assets/images/profiletest.png" alt="profile"
                                                   style="height: 40px; width: 40px;border-radius: 50px">

                                              <img *ngIf="item?.employee_data?.profile_pic?.original_image_url"
                                                   src="{{storageAccount+item?.employee_data?.profile_pic?.original_image_url}}" alt="profile"
                                                   style="height: 40px; width: 40px;border-radius: 50px">

                                            </div>
                                            <div class="col-lg-9">
                                              <h6 style="font-size: 0.7rem"><b>{{item?.employee_data?.first_name}} {{item?.employee_data?.last_name}}</b></h6>
                                              <h6 style="font-size: 0.7rem">{{item?.employee_data?.employee_id}}</h6>
                                            </div>
                                            <div class="col-lg-1 justify-content-center align-items-center text-center">
                                              <i (click)="removeEmployeeFromManagerialLevel(item?.id)" nz-button
                                                 nzTooltipTitle="Remove Employee From Managerial Level" nz-tooltip
                                                 class="ri-delete-bin-7-fill delete-employee-icon"></i>
                                            </div>

                                          </div>
                                        </div>

                                        <nz-divider></nz-divider>

                                      </div>

                                    </div>

                                  </ng-container>
                                  <div *nzModalFooter>
                                    <button nz-button nzType="default" (click)="handleCancel()">Close</button>
                                  </div>
                                </nz-modal>
                              </form>



                                <div class="row mt-5 ml-2">

                                    <nz-form-item nz-row class="register-area">
                                        <nz-form-control>
                                            <button nz-button nzType="primary" class="btn-green" (click)="submitSMManagerialLevels()">Update Managerial Levels
                                            </button>
                                        </nz-form-control>
                                    </nz-form-item>

                                </div>
                            </div>

                            <div class="sub-form mt-4">
                                <h5 class="mt-1">Custom Retirement Status</h5>
                                <div class="row justify-content-md-center mt-4" formArrayName="retirement_status">
                                    <ng-container
                                            *ngFor="let retirement_status_control of retirement_status.controls; let k=index"
                                            [formGroupName]="k">
                                        <div class="row col-md-12">

                                            <div class="col-xl-4 col-lg-4 col-md-12">
                                              <nz-form-item>
                                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Status">
                                                  <nz-input-group>
                                                    <input nz-input id="name" formControlName="name" placeholder="Enter Status"/>
                                                  </nz-input-group>
                                                </nz-form-control>
                                              </nz-form-item>
                                            </div>

                                            <div class="col-xl-1 col-lg-1 col-md-12">
                                                <button [disabled]="k == 0" nz-button (click)="removeRowRS(k)"
                                                        nzType="primary" class="btn-red">Remove
                                                </button>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>

                                <div class="row ml-2">

                                  <nz-form-item nz-row class="register-area">
                                    <nz-form-control>
                                      <button nz-button (click)="addRowRS()" nzType="primary">Add</button>
                                    </nz-form-control>
                                  </nz-form-item>

                                </div>

                                <div class="row mt-5 ml-2">

                                  <nz-form-item nz-row class="register-area">
                                    <nz-form-control>
                                      <button nz-button nzType="primary" class="btn-green" (click)="submitSMRetirementStatus()">Update Retirement Status
                                      </button>
                                    </nz-form-control>
                                  </nz-form-item>

                                </div>
                            </div>



                        </form>
                    </div>
                    <!-- Succession Management Settings ends here -->
                  <div class="mt-5"></div>

                    <!-- Talent card status ends here -->
                </div>
            </div>
        </div>
    </div>
</div>
