import { Component, OnInit } from '@angular/core';
import { Grade, GradeRetrieveRequest, Department, BusinessUnitRetrieveRequest } from 'src/app/app.models'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import {NzUploadFile} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.css']
})
export class GradeComponent implements OnInit {

  total = 1;
  isImportFormVisible = false;
  fileUploading = false;
  downloading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  // These are the Grades which are displayed in the list screen.
  grades: Grade[] = [];
  gradeId: number;

  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  gradeForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;

  // state required for the auto complete
  buOptionList: Department[] = [];
  buOptionListSearchChange$ = new BehaviorSubject('');
  isBuOptionListLoading = false;

  constructor(private fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) { }

  onBuOptionListSearch(value: string): void {
    this.isBuOptionListLoading = true;
    this.buOptionListSearchChange$.next(value);
  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(entityId: number): void {
    this.gradeId = entityId;
    this.getGradeById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getGradeById(id) {
    this.appService.get('/api/grades/' + id).subscribe(res => {
      this.gradeForm.patchValue(res);
      // console.log(res);

    })
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;

    // Create the location on the server.
    if (this.gradeForm.status === 'VALID') {
      let data = {
        "grade_name": this.gradeForm.value.grade_name,
        "business_unit_id": this.gradeForm.value.business_unit_id
      }

      let subscriber = null;
      let message = null;
      if (this.action === "create") {
        subscriber = this.appService.createGrade(data);
        message = 'Grade "' + this.gradeForm.controls['grade_name'].value + '" has been added.';
      }
      else if (this.action === 'edit') {
        subscriber = this.appService.updateGrade(data, this.gradeId);
        message = 'Grade "' + this.gradeForm.controls['grade_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        //let errorMessage = error.error.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
    this.isImportFormVisible =false;
    this.fileList = [];
  }

  resetCreateModal(): void {
    this.gradeForm = this.fb.group({
      grade_name: [null, [Validators.required,Validators.pattern("^[a-zA-Z0-9\\s&-]+$")]],
      business_unit_id: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    for (const i in this.gradeForm.controls) {
      this.gradeForm.controls[i].markAsDirty();
      this.gradeForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.resetCreateModal();

    const optionList = (buName: string) => {
      this.isBuOptionListLoading = true;
      let rr = new BusinessUnitRetrieveRequest(buName)
      return this.appService.getBusinessUnits(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    }

    const optionList$: Observable<Department[]> = this.buOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.buOptionList = data;
      this.isBuOptionListLoading = false;
    });
  }

  public remove(entity: Grade) {
    const index: number = this.grades.indexOf(entity);

    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.grade_name+'?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteGrade(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new GradeRetrieveRequest('')
    rr.sort_order = sortOrder ? sortOrder : "asc";
    rr.order_by = sortField ? sortField : "grade_name";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    this.appService.getGrades(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.grades = data['items'];
    });
  }

  showImportModal(): void {
    this.isImportFormVisible = true;
  }

  handleImportModalSubmit(): void{
    // console.log("here")
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    this.appService.importGrade(formData).subscribe((res: any) => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      this.message.create('success', res.message, {
        nzDuration: 5000
      });
    }, error => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }


  downloadSampleCSV(){
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/Grade-BulkUpload-Template.csv');
    //link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;
  }

  alphaNumberOnly (e: any) {  // Accept only letters and numbers
    e = e || window.event;
   var bad = /[^\sa-z\d&-]/i,
       key = String.fromCharCode(e.keyCode || e.which);
   if (e.which !== 0 && e.charCode !== 0 && bad.test(key)) {
       e.returnValue = false;
       if (e.preventDefault) {
           e.preventDefault();
       }
   }
  }

}
