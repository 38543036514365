<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">

        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">List of Form Launch</h3>

            <div class="d-flex justify-content-between align-items-center">
              <a class="add-btn" routerLink="/forms/launches/create" title="Create New">
                <i class="ri-add-circle-fill"></i>
              </a>
              <div></div>
            </div>

            <nz-modal [(nzVisible)]="isVisible" nzTitle="Change Dates" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
              <ng-container *nzModalContent>
                <div class="form-group">
                  <label>Start Date - End Date:<strong class="text-danger">*</strong></label>
                  <nz-range-picker
                    nzMode="date"
                    [nzShowTime]="false"
                    nzFormat="yyyy-MM-dd"
                    [(ngModel)]="start_end_date"
                    [nzDisabledDate]="disabledDate">
                  </nz-range-picker>
                </div>
              </ng-container>
            </nz-modal>

            <nz-modal [(nzVisible)]="isVisible2" nzTitle="Download Reports" (nzOnCancel)="handleCancel2()" (nzOnOk)="handleOk2()">
              <ng-container *nzModalContent>
                <div class="text-center">
                  <h6 class="mb-2">Reports for employee Status</h6>
                  <button nz-button [nzType]="'primary'" [nzLoading]="loading" class="ml-3" (click)="downloadStatus()">
                    <i class="fa fa-download"></i> <span class="ml-2">Download Status</span>
                  </button>
                </div>
              </ng-container>
            </nz-modal>

            <nz-table #launchesTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total" [nzPageSize]="limit" [nzPageIndex]="page" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="launches">
              <thead>
                <tr>
                  <th class="text-center" [nzSortFn]="sortTitleFn">Title</th>
                  <th class="text-center" [nzSortFn]="sortStartDateFn">Start Date</th>
                  <th class="text-center" [nzSortFn]="sortEndDateFn">End Date</th>
                  <th class="text-center" [nzSortFn]="sortStatusFn">Status</th>
                  <th class="text-center" width="8%">Reports</th>
                  <th class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let launch of launchesTable.data; let i = index;">
                  <td>{{ launch.title }}</td>
                  <td>{{ launch.start_date | date: 'dd-MM-yyyy' }}</td>
                  <td>{{ launch.end_date | date: 'dd-MM-yyyy' }}</td>
                  <td>
                    <a class="d-flex align-items-center" (click)="toggleStatus(launch)" title="Launched" *ngIf="launch.status === 'not launched'">
                      {{ launch.status.toUpperCase() }} <i class="ri-play-circle-line grayIcon ml-2"></i>
                    </a>
                    <a class="d-flex align-items-center" title="Launched" *ngIf="launch.status === 'launched'">
                      {{ launch.status.toUpperCase() }} <i class="ri-rocket-2-line grayIcon ml-2"></i>
                    </a>
                  </td>
                  <td class="text-center">
                    <a class="mr-2" routerLink="/forms/launches/{{launch.id}}/dashboard" title="Dashboard">
                      <i class="ri-dashboard-fill grayIcon"></i>
                    </a>
                    <a class="mr-2" routerLink="/forms/launches/{{launch.id}}/report" title="IDP">
                      <i class="ri-file-copy-2-line grayIcon"></i>
                    </a>
                    <a (click)="onClickDownload(launch)" title="Download">
                      <i class="ri-download-2-fill grayIcon"></i>
                    </a>
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="loggedInUserDetail.user_type === 'tenant_admin'">
                      <a class="mr-2" (click)="onEditLaunch(launch)" title="Edit">
                        <i class="ri-edit-line grayIcon"></i>
                      </a>
                      <a class="mr-2" (click)="onOpenDateModal(launch)" title="Change Dates">
                        <i class="ri-calendar-todo-line grayIcon"></i>
                      </a>
                      <a (click)="removeFormLaunch(i, launch)" title="Delete">
                        <i class="ri-delete-bin-6-fill text-danger"></i>
                      </a>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
