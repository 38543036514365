import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-hrapp-redirection',
  templateUrl: './hrapp-redirection.component.html',
  styleUrls: ['./hrapp-redirection.component.css']
})


export class HrappRedirectionComponent implements OnInit {

  constructor(private appService: AppService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    // console.log('REDIRECTING TO HRAPP');
    this.spinner.show();
    this.appService.get<any>('/api/rdrct_to_hrapp').subscribe(res => {
      // console.log('response', res);
      if (res.status == 'success') {
        // console.log('success');
        window.location.href = 'https://infotech.hrapp.co.in/in/tlauth?rtkn=' + res.cipherRefreshToken;
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
      }
    });
  }
}
