import { Component, OnInit, AfterViewInit, Output, ViewChild, Input, ElementRef, NgZone, EventEmitter } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz"
am4core.useTheme(am4themes_dataviz);

@Component({
  selector: 'app-chart-amcchart',
  templateUrl: './chart-amcchart.component.html',
  styleUrls: ['./chart-amcchart.component.css']
})
export class ChartAmcchartComponent implements OnInit {
  @Input() title: string;
  @Input() data: any[];
  @Input() type: string;
  // @Input() axisX: any;
  // @Input() axisY: any;

  private chart: am4charts.Chart;

  constructor(private zone: NgZone) { }

  ngOnInit(): void {
    // console.log("Enter for: ", this.title);

  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {

      let divId;
      switch(this.type){
        case 'la':
        divId="charRef";
        break;
        case 'ts':
        divId="charRef360";
        break;
        case 'pm':
        divId="charRefpsyco";
        break;
        default: break;
      }
      let chart = am4core.create(divId, am4charts.XYChart);

      let title = chart.titles.create();
      title.text = this.title;
      title.fontSize = 20;
      title.marginBottom = 30;

      chart.data = this.data;
      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "cusfoc";
      categoryAxis.title.text = "Competency";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "Score (M)";

      // Create series
      function createColumnSeries(field, name, stacked) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "cusfoc";
        series.name = name;
        series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);
      }

      // Create series
      function createLineSeries(field, name) {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "cusfoc";
        series.name = name;
        series.tensionX = 0.8;
        series.strokeWidth = 3;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.fill = am4core.color("#fff");
        bullet.circle.strokeWidth = 3;
      }

      createColumnSeries("normalised_achieved_score", "Actual", false);
      createColumnSeries("desired_score", "Desired", false);
      createLineSeries("gap", "Gap");
      // createSeries("asia", "Asia", false);
      // createSeries("lamerica", "Latin America", true);
      // createSeries("meast", "Middle East", true);
      // createSeries("africa", "Africa", true);


      // Add legend
      chart.legend = new am4charts.Legend();
      this.chart = chart;

    });

  }

  getPDF(){
    Promise.all([
      this.chart.exporting.pdfmake,
      this.chart.exporting.getImage("png"),
      this.chart.exporting.getImage("png"),
    ]).then(res => {
      // console.log("res is : ", res);

      let pdfmake = res[0];
      let doc = {
        pageSize: "A4",
        pageOrientation: "portrait",
        pageMargins: [50,50,50,50],
        content: [] as any
      };

      doc.content.push({
        image: res[1],
        width: 530,
        bold: true
      });
      doc.content.push({
        image: res[2],
        width: 530,
        bold: true
      });
      // doc.content.push({
      //   image: res[3],
      //   width: 530,
      //   bold: true
      // });

      pdfmake.createPdf(doc).download("Gap Analysys Report.pdf");

    });

  }

  ngOnestroy(){

  }


}
