<div id="content-page" class="content-page">
    <div class="container">
      <!-- <div class="nz-demo-typography-title">
        <h3 nz-typography>List of Annual Priorities</h3></div> -->
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
              <h3 class="py-4">List of Annual Priorities</h3>
              <div class="row my-4">
                <div class="col-md-4">
                  <h3 class="text-left plusIcon"> 
                    <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
    
                  </div>
                  <div class="col-md-4 searchSection">
                    <!-- <nz-select nzSize="large" >
                      <nz-option nzValue="FY-20-21" nzLabel="FY-20-21"></nz-option>
                      <nz-option nzValue="FY-21-22" nzLabel="FY-21-22"></nz-option>
                    </nz-select>
                    <h3 class="searchIcon"><i nz-icon nzType="search" nzTheme="outline"></i></h3> -->
                  </div>
                  <div class="col-md-4"></div>
              </div>
              <nz-modal
                [(nzVisible)]="isFormVisible"
                nzTitle="Define Annual Priorities Under Strategic Perspectives"
                (nzOnCancel)="handleModalCancel()"
                (nzOnOk)="handleModalSubmit()"
                nzOkText="Save"
                nzOkType="primary"
                [nzOkLoading]="isFormLoading"
                [nzOkDisabled]="!annualPriorityForm.valid"
              >
                <form
                  nz-form
                  [nzLayout]="'vertical'"
                  [formGroup]="annualPriorityForm"
                  (ngSubmit)="submitForm()"
                >
                  <!-- Capture the AP name -->
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="Annual Priority" nzNoColon="false">Annual Priority Name</nz-form-label>
                    <nz-form-control nzErrorTip="AP Name is mandatory.">
                      <nz-input-group [nzSize]="'large'">
                        <input formControlName="title"  nz-input placeholder="Annual Priority Name" />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <!-- Capture the Description -->
                  <nz-form-item>
                    <nz-form-label nzFor="description" nzNoColon="false">Description</nz-form-label>
                    <nz-form-control>
                      <nz-input-group [nzSize]="'large'" >
                        <input formControlName="description"   nz-input placeholder="Description" />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <!-- Capture the Short Code -->
                  <nz-form-item>
                    <nz-form-label nzFor="short_code" nzNoColon="false">Short Code</nz-form-label>
                    <nz-form-control>
                      <nz-input-group [nzSize]="'large'">
                        <input formControlName="short_code"   nz-input placeholder="Short Code" />
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
  
                  <!-- Capture the SP -->
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="sp_id" nzNoColon="false">Strategic Perspective (SP)</nz-form-label>
                    <nz-form-control nzErrorTip="Strategic Perspective is mandatory.">
                      <nz-input-group [nzSize]="'large'">
                        <nz-select
                          nzAllowClear
                          nzSize="large"
                          nzShowSearch
                          nzServerSearch
                          formControlName="sp_id"
                          (nzOnSearch)="onSPOptionListSearch($event)">
                          <ng-container *ngFor="let o of spOptionList">
                            <nz-option
                              *ngIf="!isSPOptionListLoading"
                              [nzValue]="o.id"
                              [nzLabel]="o.title"
                            ></nz-option>
                          </ng-container>
                          <nz-option
                            *ngIf="isSPOptionListLoading"
                            nzDisabled
                            nzCustomContent
                          >
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                          </nz-option>
                        </nz-select>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <!-- Capture the Start Date -->
                  <nz-form-item>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="start_date" nzNoColon="false">Start Date</nz-form-label>
                    <nz-form-control [nzSm]="16" [nzXs]="24">
                      <nz-date-picker nzSize="large" 
                      formControlName="start_date"
                      [nzFormat]="dateFormat" 
                      [nzDisabledDate]="disabledStartDate"
                      (nzOnOpenChange)="handleStartOpenChange($event)"
                      ></nz-date-picker>
                      <!--<nz-input-group class="form-group">
                        <input formControlName="startDate" nz-input placeholder="End Date" />
                      </nz-input-group>-->
                    </nz-form-control>
                  </nz-form-item>
                   
                  <!-- Capture the End Date -->
                  <nz-form-item>
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="end_date" nzNoColon="false">End Date</nz-form-label>
                    <nz-form-control [nzSm]="16" [nzXs]="24">
                      <nz-date-picker 
                      nzSize="large" 
                      formControlName="end_date"
                      [nzFormat]="dateFormat"
                      #endDatePicker
                      [nzDisabledDate]="disabledEndDate"
                      (nzOnOpenChange)="handleEndOpenChange($event)"
                      ></nz-date-picker> 
                      <!--<nz-input-group class="form-group">
                        <input formControlName="endDate" nz-input placeholder="End Date" />
                      </nz-input-group>-->
                    </nz-form-control>
                  </nz-form-item>

                </form>
              </nz-modal>
             
              <nz-table
                nzBordered
                nzShowSizeChanger
                [nzFrontPagination]="false"
                [nzLoading]="loading"
                [nzTotal]="total"
                [nzPageSize]="pageSize"
                [nzPageIndex]="pageIndex"
                (nzQueryParams)="onQueryParamsChange($event)"
                [nzData]="aps"
              >
                <thead>
                  <tr>
                    <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                    <th nzColumnKey="title" [nzSortFn]="true">Annual Priority Name</th>
                    <th nzColumnKey="sp_id" [nzSortFn]="true">Strategic Perspective Name</th>
                    <th nzColumnKey="description" [nzSortFn]="true">Description</th>
                    <th nzColumnKey="shortCode" [nzSortFn]="true">Short Code</th>
                    <th nzColumnKey="startDate" [nzSortFn]="true">Start Date</th>
                    <th nzColumnKey="endDate" [nzSortFn]="true">End Date</th> -->

                    
                    <th>Annual Priority Name</th>
                    <th>Strategic Perspective Name</th>
                    <th>Description</th>
                    <th>Short Code</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <!-- <th nzColumnKey="isActive" [nzSortFn]="true">Is Active</th> -->
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ap of aps">
                    
                    <td>{{ ap.title }}</td>
                    <td>
                      <a [routerLink]="['/strategic-perspectives']" [queryParams]="{id: ap.sp_id}">{{ ap.strategic_perspective.title }}</a>
                    </td>
                    <td>{{ ap.description }}</td>
                    <td>{{ ap.short_code }}</td>
                    <td>{{ ap.start_date }}</td>
                    <td>{{ ap.end_date }}</td>
                    <!-- <td>{{ ap.is_active }}</td> -->
                    <td>
                      <a (click)="showEditModal(ap.id)"><i class="ri-pencil-fill grayIcon" ></i></a>
                      <nz-divider nzType="vertical"> | </nz-divider>
                      <a (click)="remove(ap)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
