// ----------------------------------------------------------------------------------------------------------------------------------
// --Modified by: Manuel Robert------------------------------------------------------------------------------------------------------
// --User Story/ Task ID :   Edited the added routes for labelled contents-----------------------------------------------------------
// --Modified on: 29-10-2021 --------------------------------------------------------------------------------------------------------
// --Modified by: Geo Thomas------------------------------------------------------------------------------------------------------
// --User Story/ Task ID :   Edited the added routes for premium-360-degree-feedback-lisitng-----------------------------------------------------------
// --Modified on: 06-01-2022 --------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Router, NavigationEnd } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './shared/login/login.component';
import { PreloginComponent } from './shared/prelogin/prelogin.component';
import { QuickThreeSixtyDashboardComponent } from 'src/app/pages/reports/quick-three-sixty-dashboard/quick-three-sixty-dashboard.component';
import { AppService } from './app.service';
import {AjnaRedirectionComponent} from './shared/ajna-redirection/ajna-redirection.component'

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    { path: 'routing', loadChildren: () => import('./pages/routing-test/routing-test.module').then(m => m.RoutingTestModule) },
    { path: 'business-unit', loadChildren: () => import('./pages/business-unit/business-unit.module').then(m => m.BusinessUnitModule) },
    { path: 'desired-level', loadChildren: () => import('./pages/desired-level/desired-level.module').then(m => m.DesiredLevelModule) },
    { path: 'location', loadChildren: () => import('./pages/location/location.module').then(m => m.LocationModule) },
    { path: 'function', loadChildren: () => import('./pages/function/function.module').then(m => m.FunctionModule) },
    { path: 'department', loadChildren: () => import('./pages/department/department.module').then(m => m.DepartmentModule) },
    { path: 'grade', loadChildren: () => import('./pages/grade/grade.module').then(m => m.GradeModule) },
    { path: 'employee', loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule) },
    { path: 'tags', loadChildren: () => import('./pages/tags/tags.module').then(m => m.TagsModule) },
    { path: 'assessment-center', loadChildren: () => import('./pages/assessment-center/assessment-center.module').then(m => m.AssessmentCenterModule) },
    { path: 'strategic-perspectives', loadChildren: () => import('./pages/strategic-perspectives/strategic-perspectives.module').then(m => m.StrategicPerspectivesModule) },
    { path: 'annual-priority', loadChildren: () => import('./pages/annual-priority/annual-priority.module').then(m => m.AnnualPriorityModule) },
    { path: 'objective-master', loadChildren: () => import('./pages/objective-master/objective-master.module').then(m => m.ObjectiveMasterModule) },
    { path: 'maximizer-dashboard', loadChildren: () => import('./pages/maximizer-dashboard/maximizer-dashboard.module').then(m => m.MaximizerDashboardModule) },
    { path: 'maximizer-setup', loadChildren: () => import('./pages/maximizer-setup/maximizer-setup.module').then(m => m.MaximizerSetupModule) },
    { path: 'role', loadChildren: () => import('./pages/role/role.module').then(m => m.RoleModule) },
    { path: 'competency', loadChildren: () => import('./pages/competency/competency.module').then(m => m.CompetencyModule) },
    { path: 'learning-assessment', loadChildren: () => import('./pages/learning-assessment/learning-assessment.module').then(m => m.LearningAssessmentModule) },
    { path: 'nine-box', loadChildren: () => import('./pages/nine-box/nine-box.module').then(m => m.NineBoxModule) },
    { path: 'reinforcement-learning', loadChildren: () => import('./pages/reinforcement-learning/reinforcement-learning.module').then(m => m.ReinforcementLearningModule) },
    { path: 'report', loadChildren: () => import('./pages/nine-box/nine-box.module').then(m => m.NineBoxModule) },
    { path: 'survey', loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule) },


    { path: 'org-announce', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'leader-speak', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'success-stories', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'learn-go', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'idp', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'succession-planning', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'continous-360-feedback', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'reflections', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'challenges', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'collab-corner', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'help-videos', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    { path: 'bbs-certificate', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    // { path: 'testlc8', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    // { path: 'testlc9', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },
    // { path: 'testlc10', loadChildren: () => import('./pages/labelled-content/labelled-content.module').then(m => m.LabelledContentModule) },


    { path: 'gap-analysis', loadChildren: () => import('./pages/gap-analysys/gap-analysys.module').then(m => m.GapAnalysysModule) },
    { path: 'assessment-question-attempt', loadChildren: () => import('./pages/assessment-question-attempt/generic-assessment-question-attempt.module').then(m => m.GenericAssessmentQuestionAttemptModule) },
    { path: 'assessment-feedback-attempt', loadChildren: () => import('./pages/assessment-feedback-attempt/assessment-feedback-attempt.module').then(m => m.AssessmentFeedbackAttemptModule) },
    { path: 'assessment-attempt', loadChildren: () => import('./pages/assessment-attempts/assessment-attempts.module').then(m => m.AssessmentAttemptsModule) },
    { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
    { path: '360-degree-feedback-lisitng', loadChildren: () => import('./pages/three-sixty-feedback/three-sixty-feedback.module').then(m => m.ThreeSixtyFeedbackModule) },
    { path: 'view-answer', loadChildren: () => import('./pages/view-answer/view-answer.module').then(m => m.ViewAnswerModule) },
    { path: 'behaviour-transactions', loadChildren: () => import('./pages/behaviour-transactions/behaviour-transactions.module').then(m => m.BehaviourTransactionsModule) },
    { path: 'video', loadChildren: () => import('./pages/video/video.module').then(m => m.VideoModule) },
    { path: 'notification-setup', loadChildren: () => import('./pages/notification-setup/notification-setup.module').then(m => m.NotificationSetupModule) },
    { path: 'activity', loadChildren: () => import('./pages/activity-log/activity-log.module').then(m => m.ActivityLogModule) },
    { path: 'employee-report', loadChildren: () => import('./pages/employee-report/employee-report.module').then(m => m.EmployeeReportModule) },
    { path: 'pii-requests', loadChildren: () => import('./pages/pii/pii.module').then(m => m.PiiModule) },
    { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
    { path: 'terms-and-conditions', loadChildren: () => import('./pages/terms-and-conitions/terms-and-conitions.module').then(m => m.TermsAndConitionsModule) },
    { path: 'admin-dash', loadChildren: () => import('./pages/admin-dash/admin-dash.module').then(m => m.AdminDashModule) },
    { path: 'tenant', loadChildren: () => import('./pages/tenant/tenant.module').then(m => m.TenantModule) },

    { path: 'premium-360-degree-feedback-lisitng', loadChildren: () => import('./pages/premium-three-sixty-feedback/premium-three-sixty-feedback.module').then(m => m.PremiumThreeSixtyFeedbackModule) },
    { path: 'behaviour-structure', loadChildren: () => import('./pages/behaviour-structure/behaviour-structure.module').then(m => m.BehaviourStructureModule) },
    { path: 'lead-indication', loadChildren: () => import('./pages/lead-indication/lead-indication.module').then(m => m.LeadIndicationModule) },
    { path: 'lag-indication', loadChildren: () => import('./pages/lag-indication/lag-indication.module').then(m => m.LagIndicationModule) },
    { path: 'current-indication', loadChildren: () => import('./pages/current-indication/current-indication.module').then(m => m.CurrentIndicationModule) },
    { path: 'features', loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule) },
    { path: 'talent-card-form', loadChildren: () => import('./pages/talent-card-form/talent-card-form.module').then(m => m.TalentCardFormModule) },
    { path: 'talent-card-list', loadChildren: () => import('./pages/talentcard-list/talentcard-list.module').then(m => m.TalentcardListFormModule) },
    { path: 'tickets', loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsModule) },
    { path: 'tickets-dashboard', loadChildren: () => import('./pages/tickets-dashboard/tickets-dashboard.module').then(m => m.TicketsDashboardModule) },
    { path: 'report/quick-360', loadChildren: () => import('./pages/reports/quick-three-sixty-report/quick-three-sixty-report.module').then(m => m.QuickThreeSixtyReportModule) },
    { path: 'report/continuous-360', loadChildren: () => import('./pages/reports/continuous-three-sixty/continuous-three-sixty.module').then(m => m.ContinuousThreeSixtyModule) },
    { path: 'report/quick-360-dashboard/:id',component: QuickThreeSixtyDashboardComponent},
    { path: 'LR-enhancement', loadChildren: () => import('./pages/lr-enhancement/lr-enhancement.module').then(m => m.LrEnhancementModule) },
    { path: 'my-nudges', loadChildren: () => import('./pages/my-nudges/my-nudges.module').then(m => m.MyNudgesModule) },
    { path: 'leaderboard', loadChildren: () => import('./pages/leaderboard/leaderboard.module').then(m => m.LeaderboardModule) },
    { path: 'forms', loadChildren: () => import('./pages/form/form-list.module').then(m => m.FormListModule) },
    {path:'succession-management',loadChildren: () => import('./pages/succession-management/succession-management.module').then(m => m.SuccessionManagementModule)}

  ]
},
{
  path: 'trulead-redirection', children: [
    { path: '', loadChildren: () => import('./shared/trulead-redirection/trulead-redirection.module').then(m => m.TruleadRedirectionModule) },
  ]
},
{
  path: 'hrapp-redirection', children: [
    { path: '', loadChildren: () => import('./shared/hrapp-redirection/hrapp-redirection.module').then(m => m.HrappRedirectionModule) },
  ]
},
{
  path: 'lms-redirection', children: [
    { path: '', loadChildren: () => import('./shared/lms-redirection/lms-redirection.module').then(m => m.LmsRedirectionModule) },
  ]
},
{ path: 'ajna-redirection-dashboard', component: AjnaRedirectionComponent },
{ path: 'ajna-redirection-vstream', component: AjnaRedirectionComponent },
{ path: 'login', component: LoginComponent },
{ path: 'prelogin', component: PreloginComponent },
{ path: '**', component: NotFoundComponent }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // <- comment this line for activate lazy load
      preloadingStrategy: PreloadAllModules,
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(private router: Router, private appservice: AppService) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url == '/prelogin' || ev.url == '/login' || ev.url.search('trulead-redirection') > 0 || ev.url.search('lms-redirection') > 0) {
          // console.log('Login procedures');
        } else {
          this.appservice.refresh_token();
        }
      }
    });
  }
 }
