<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <h3 class="py-4 text-center">My Nudges</h3>
              <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="tblData" [nzLoading]="loading"
              (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" [nzScroll]="{ x: '150px' }">
                <thead>
                  <tr>
                    <th style="width: 70px; min-width: 70px;">S.No</th>
                    <th>Notification</th>
                    <th style="width: 200px; min-width: 200px;">Delivery Time</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rowSelectionTable.data;let i = index;">
                    <td>{{ item.slno }}</td>
                    <td>{{ item.app_notification_text }}</td>
                    <td>{{ item.delivery_time }}</td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>