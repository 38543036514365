import { Component, OnInit } from '@angular/core';
import { ObjectiveMaster, ObjectiveMasterRetrieveRequest, AnnualPriority, APRetrieveRequest } from 'src/app/app.models'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-objective-master',
  templateUrl: './objective-master.component.html',
  styleUrls: ['./objective-master.component.css']
})
export class ObjectiveMasterComponent implements OnInit {
  total = 1;
  // These are the Objective Masters which are displayed in the list screen.
  objectiveMasters: ObjectiveMaster[] = [];
  // This is the OM being edited.
  objMasterId: number;
  // loading indictor for the table
  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  objectiveMasterForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;

  // state required for the auto complete
  apOptionList: AnnualPriority[] = [];
  apOptionListSearchChange$ = new BehaviorSubject('');
  isAPOptionListLoading = false;

  constructor(private fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) { }

  onAPOptionListSearch(value: string): void {
    this.isAPOptionListLoading = true;
    this.apOptionListSearchChange$.next(value);
  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(entityId: number): void {
    this.objMasterId = entityId;
    this.getObjMasterById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getObjMasterById(id) {
    this.appService.get('/api/objective-masters/' + id).subscribe(res => {
      this.objectiveMasterForm.patchValue(res);
    })
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;

    if (this.objectiveMasterForm.status === 'VALID') {
      let data = {
        "title": this.objectiveMasterForm.value.title,
        "ap_id": this.objectiveMasterForm.value.ap_id,
        "short_code": this.objectiveMasterForm.value.short_code
      }

      let subscriber = null;
      let message = null;
      if (this.action === "create") {
        subscriber = this.appService.createObjectiveMaster(data);
        message = 'Objective Master "' + this.objectiveMasterForm.controls['title'].value + '" has been added.';
      }
      else if (this.action === 'edit') {
        subscriber = this.appService.updateObjectiveMaster(data, this.objMasterId);
        message = 'Objective Master "' + this.objectiveMasterForm.controls['title'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  submitForm(): void {
    for (const i in this.objectiveMasterForm.controls) {
      this.objectiveMasterForm.controls[i].markAsDirty();
      this.objectiveMasterForm.controls[i].updateValueAndValidity();
    }
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
  }

  resetCreateModal(): void {
    this.objectiveMasterForm = this.fb.group({
      title: [null, [Validators.required]],
      ap_id: [null, [Validators.required]],
      short_code: [null]
    });
  }

  ngOnInit(): void {
    this.resetCreateModal();
    const optionList = (apTitle: string) => {
      this.isAPOptionListLoading = true;
      let rr = new APRetrieveRequest(apTitle)
      return this.appService.getAnnualPriorities(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    }

    const optionList$: Observable<AnnualPriority[]> = this.apOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.apOptionList = data;
      this.isAPOptionListLoading = false;
    });
  }

  public remove(entity: ObjectiveMaster) {
    const index: number = this.objectiveMasters.indexOf(entity);

    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.title+'?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteObjectiveMaster(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    // let rr = new APRetrieveRequest('')
    let rr = new ObjectiveMasterRetrieveRequest('')
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "created_on";
    rr.per_page = pageSize;
    rr.page = pageIndex;

    this.appService.getObjectiveMasters(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.objectiveMasters = data['items'];
    });
  }

}

