import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {NzUploadFile, NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../app.service';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import {
Tenant
} from '../../../app.models';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {NzModalService} from 'ng-zorro-antd/modal';
import { DisabledTimeFn, DisabledTimePartial } from 'ng-zorro-antd/date-picker';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-tenant-detail',
    templateUrl: './tenant-detail.component.html',
    styleUrls: ['./tenant-detail.component.css']
  })

  export class TenantDetailComponent implements OnInit {
    loading = false;
    tenantForm!: FormGroup;
    action = 'create';
    isFormLoading = false;
    tenantId: any;
    constructor(
      public fb: FormBuilder,
      public appService: AppService,
      public activatedRoute: ActivatedRoute,
      public message: NzMessageService,
      public datepipe: DatePipe,
      public router: Router,
      private modal: NzModalService,

    ) {
  
    }
    ngOnInit(): void {
        this.tenantForm = this.fb.group({
            tenant_name: ["", [Validators.required]],
            tenant_admin_email: ["", [Validators.required, Validators.email, Validators.minLength(7)]],
            tenant_admin_email_notifiction: ["", [Validators.required, Validators.email, Validators.minLength(7)]],
            aws_access_key: ["", [Validators.required]],
            aws_bucket: ["", [Validators.required]],
            aws_region_host: ["", [Validators.required]],
            aws_region_name: ["", [Validators.required]],
            aws_secret_key: ["", [Validators.required]],
            azure_account_connection_string: [null],
            azure_container_name: [null],
            db_connection_string: ["", [Validators.required]],
            storage_url: ["", [Validators.required]],
            storage_provider: ["", [Validators.required]],
            smtp_hostname: ["", [Validators.required]],
            smtp_password: ["", [Validators.required]],
            smtp_username: ["", [Validators.required]],
            subdomain: ["", [Validators.required]],
            business_days: [""],
            enable_assessment_centre: ["", [Validators.required]],
            enable_labelled_content: ["", [Validators.required]],
            enable_learning_assessment: ["", [Validators.required]],
            enable_profit_maximiser: ["", [Validators.required]],
            enable_behaviour_transaction: ["", [Validators.required]],
            enable_gap_analysis: ["", [Validators.required]],
            enable_9box: ["", [Validators.required]],
            enable_survey: ["", [Validators.required]],
            enable_360: ["", [Validators.required]],
            enable_premium_360: ["", [Validators.required]],
            enable_learnig_reinforcement: ["", [Validators.required]],
            enable_hrapp: ["", [Validators.required]],
            enable_lms: ["", [Validators.required]],



        });

        this.activatedRoute.params.subscribe(params => {
          if (params['id']) {
            this.tenantId = params['id'];
            this.action = 'edit';
            this.getTenantById(this.tenantId);
          }
     
    });}
    

    async getTenantById(id){
      this.appService.getTenantbyId(id).subscribe((result: any) =>{
        this.tenantForm.patchValue(result);

        this.tenantForm.get('tenant_name').setValue(result.tenant_name);
        this.tenantForm.get('tenant_admin_email').setValue(result.tenant_admin_email);
        this.tenantForm.get('tenant_admin_email_notifiction').setValue(result.tenant_admin_email_notifiction);
        this.tenantForm.get('aws_access_key').setValue(result.aws_access_key);
        this.tenantForm.get('aws_bucket').setValue(result.aws_bucket);
        this.tenantForm.get('aws_region_host').setValue(result.aws_region_host);
        this.tenantForm.get('aws_region_name').setValue(result.aws_region_name);
        this.tenantForm.get('aws_secret_key').setValue(result.aws_secret_key);
        this.tenantForm.get('azure_account_connection_string').setValue(result.azure_account_connection_string);
        this.tenantForm.get('azure_container_name').setValue(result.azure_container_name);
        this.tenantForm.get('db_connection_string').setValue(result.db_connection_string);
        this.tenantForm.get('storage_provider').setValue(result.storage_provider);
        this.tenantForm.get('storage_url').setValue(result.storage_url);
        this.tenantForm.get('smtp_hostname').setValue(result.smtp_hostname);
        this.tenantForm.get('smtp_password').setValue(result.smtp_password);
        this.tenantForm.get('smtp_username').setValue(result.smtp_username);
        this.tenantForm.get('subdomain').setValue(result.subdomain);
        this.tenantForm.get('business_days').setValue(result.business_days);
        this.tenantForm.get('enable_assessment_centre').setValue(result.enable_assessment_centre);
        this.tenantForm.get('enable_labelled_content').setValue(result.enable_labelled_content);
        this.tenantForm.get('enable_learning_assessment').setValue(result.enable_learning_assessment);
        this.tenantForm.get('enable_profit_maximiser').setValue(result.enable_profit_maximiser);
        this.tenantForm.get('enable_behaviour_transaction').setValue(result.enable_behaviour_transaction);
        this.tenantForm.get('enable_gap_analysis').setValue(result.enable_gap_analysis);
        this.tenantForm.get('enable_9box').setValue(result.enable_9box);
        this.tenantForm.get('enable_survey').setValue(result.enable_survey);
        this.tenantForm.get('enable_360').setValue(result.enable_360);
        this.tenantForm.get('enable_premium_360').setValue(result.enable_premium_360);
        this.tenantForm.get('enable_learnig_reinforcement').setValue(result.enable_learnig_reinforcement);
        this.tenantForm.get('enable_lms').setValue(result.enable_lms);
        this.tenantForm.get('enable_hrapp').setValue(result.enable_hrapp);

      

      });
    }
    back() {
        this.router.navigate(['tenant']);
      }
      resetValue = false
      resetForm(): void {
        this.resetValue = true
        if(this.action == "create"){
          this.tenantForm.reset();
        }else{
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    submitForm(){
      if(!this.resetValue){
        this.isFormLoading = true;
        let data = {
          'tenant_name':this.tenantForm.get('tenant_name').value,
          'tenant_admin_email':  this.tenantForm.get('tenant_admin_email').value,
           'tenant_admin_email_notifiction':  this.tenantForm.get('tenant_admin_email_notifiction').value,
           'aws_access_key':this.tenantForm.get('aws_access_key').value,
           'aws_bucket': this.tenantForm.get('aws_access_key').value,
           'aws_region_host': this.tenantForm.get('aws_region_host').value,
           'aws_region_name': this.tenantForm.get('aws_region_name').value,
           'aws_secret_key': this.tenantForm.get('aws_secret_key').value,
           'azure_account_connection_string': this.tenantForm.get('azure_account_connection_string').value, 
           'azure_container_name': this.tenantForm.get('azure_container_name').value,
           'db_connection_string':this.tenantForm.get('db_connection_string').value,
           'storage_provider': this.tenantForm.get('storage_provider').value,
            'storage_url': this.tenantForm.get('storage_url').value,
            'smtp_hostname': this.tenantForm.get('smtp_hostname').value,
            'smtp_password': this.tenantForm.get('smtp_password').value,
            'smtp_username': this.tenantForm.get('smtp_username').value,
            'subdomain': this.tenantForm.get('subdomain').value,
            'business_days': this.tenantForm.get('business_days').value,
            'enable_assessment_centre':this.tenantForm.get('enable_assessment_centre').value,
            'enable_labelled_content': this.tenantForm.get('enable_labelled_content').value,
            'enable_learning_assessment':this.tenantForm.get('enable_learning_assessment').value,
            'enable_profit_maximiser':this.tenantForm.get('enable_profit_maximiser').value,
            'enable_behaviour_transaction': this.tenantForm.get('enable_behaviour_transaction').value,
            'enable_gap_analysis': this.tenantForm.get('enable_gap_analysis').value,
            'enable_9box': this.tenantForm.get('enable_9box').value,
            'enable_survey': this.tenantForm.get('enable_survey').value,
            'enable_360': this.tenantForm.get('enable_360').value,
            'enable_premium_360': this.tenantForm.get('enable_premium_360').value,
            'enable_learnig_reinforcement': this.tenantForm.get('enable_learnig_reinforcement').value,
            'enable_hrapp': this.tenantForm.get('enable_hrapp').value,
            'enable_lms': this.tenantForm.get('enable_lms').value,
           
        }
      
       
        let subscriber = null;
        // console.log("formdata", data)
        let message = null;
        if (this.action === 'create') {
          subscriber = this.appService.createTenant(data);
          message = 'Tenant "' + this.tenantForm.controls['tenant_name'].value + '" has been added.';
          // console.log("message", message)
        } else if (this.action === 'edit') {
          subscriber = this.appService.updateTenant(data, this.tenantId);
          message = 'Tenant "' + this.tenantForm.controls['tenant_name'].value + '" has been updated.';
        }

        subscriber.subscribe(res =>{
          // console.log("tenant data ", res)
          this.isFormLoading = false;

          this.message.create('success', message, {
            nzDuration: 5000
          });
          this.router.navigate(['/tenant']);
          if (this.action === 'edit') {
            sessionStorage.setItem('isReloadGetMe', 'yes');
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        }, error => {
          this.isFormLoading = false;
    
          //let errorMessage = error.error.message
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
    
        });



      }

    }
   
    
  }
