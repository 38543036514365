import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {startWith, map} from 'rxjs/operators';
import {
  BusinessUnitRetrieveRequest,
  DepartmentRetrieveRequest,
  FunctionRetrieveRequest,
  GradeRetrieveRequest,
  LocationRetrieveRequest,
  RoleRetrieveRequest,
  TalentcardRetrieveRequest
} from 'src/app/app.models';
import {Observable, of} from 'rxjs';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
@Component({
  selector: 'app-talentcard-list',
  templateUrl: './talentcard-list.component.html',
  styleUrls: ['./talentcard-list.component.css']
})
export class TalentcardListComponent implements OnInit {
  loading = false;
  total = 0;
  talentCardForm: FormGroup;
  pageSize = 100;
  pageIndex = 1
  tickets = [{name:'ttt'}]
  filterControl = new FormControl();
  inputValue: any;
  filterForm: FormGroup;
  talentCardDetails:any[] = [];
  employeeAdminSetup: any;
  selectedValue:any;
  usersList = [];
  mobilityList=[{key:'yes',name:'Yes'},{key:'no',name:'No'}]
  activities: Observable<Array<any>>;
  activitiesData
  filters={
    page:  1,
    per_page:  100,
    order_by:  'created_on',
    sort_order:  'desc',
    ids: [],
    emp_ids:  [],
    display_name:  '',
    reporting_manager:  null,
    business_unit_ids:  [],
    function_ids:  [],
    location_ids:  [],
    department_ids:  [],
    role_ids:  [],
    grade_ids:  [],
    employee_full_search:''
  }
  searchValue = '';
  visible = false;
  constructor(private fb: FormBuilder,
    private modal: NzModalService,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    private message: NzMessageService,) { }

  ngOnInit(): void {
    this.onView();
    // this.subscribeToFilterControlChanges();
    this.resetCreateModal();
    // this.getAllTalentCard()
    this.getBUs();
    this.getGradeList();
    this.getRoleList();
    this.fetchUsersList();
  }

  onView(){
    this.talentCardForm = this.fb.group({
      name: [null, [Validators.required]],
      doj: [null, [Validators.required]],
      department: [null, [Validators.required]],
      reviewer: [null, [Validators.required]],
      professional_qualification: [null, [Validators.required]],
      employee_code:[null, [Validators.required]],
      grade:[null, [Validators.required]],
      functional_manager:[null, [Validators.required]],
      mobility:[null, [Validators.required]],
      geography:[null],
      education_qualification:[null, [Validators.required]],
      previous_promotion: this.fb.array([]),
      previous_experience:this.fb.array([]),
      company_experience:this.fb.array([])
    });
  }

  allData = false;
  filters1
  fetchUsersList() {
    this.allData = true;
    let rr = new TalentcardRetrieveRequest();
    rr = this.filters1
    this.appService.getAllTelantCard(rr).subscribe(resp => {
      if (resp) {
        console.log(resp,"this is resp");
        
        this.usersList = resp.items;
        this.allData = false;

      }
    })
  }
  resetCreateModal(): void {
    this.filterForm = this.fb.group({
      business_unit_ids: [null],
      location_ids: [null],
      function_ids: [null],
      department_ids: [null],
      role_ids: [null],
      grade_ids: [null],
      status: [true]
    });
  }
  // filters
  getAllTalentCard(){
    let rr = new TalentcardRetrieveRequest();
    rr = this.filters
    // rr.emp_ids = this.emp_Id?this.emp_Id:[],
    this.loading = true;
    this.appService.getAllTelantCard(rr).subscribe(res => {
      // console.log('all-talent-card',res)
      this.talentCardDetails = res['items']
      console.log(this.talentCardDetails,"card details")
      this.loading = false;
    },err=>{
      this.loading = false;
    })
  }
  onQueryParamsChange(params: NzTableQueryParams){
    const {pageSize, pageIndex, sort} = params;
    let rr = new TalentcardRetrieveRequest();
    this.filters['page'] = pageIndex
    this.filters['per_page'] = pageSize
    rr = this.filters
    // rr.emp_ids = this.emp_Id?this.emp_Id:[],
    this.loading = true;
    this.appService.getAllTelantCard(rr).subscribe(res => {
      // console.log('all-talent-card',res)
    
      this.talentCardDetails = res['items']
      this.total = res._meta.total_items
      console.log(this.talentCardDetails,"card details")
      this.loading = false;
    },err=>{
      this.loading = false;
    })
  }
  back(){

  }
  onInput(e) {
    // console.log('event',e)
  }
  onUserSelect(user) {
    // console.log(user)
    if (user.user_id) {
      // this.fetchSelectedUserActivity(user.user_id);
    }
  }
  optionSelectionChange(data) {
    this.onUserSelect(data);
  }
  bUOptionList
  getBUsById(buIds) {
    let rr = new BusinessUnitRetrieveRequest();
    rr.ids = buIds;
    this.appService.getBusinessUnits(rr).subscribe(res => {
      this.bUOptionList = res['items'];
    });
  }
  locationOptionList
  getLocationsById(locationIds) {
    let rr = new LocationRetrieveRequest();
    rr.ids = locationIds;
    this.appService.getLocations(rr).subscribe(res => {
      this.locationOptionList = res['items'];
    });
  }
  functionOptionList
  getFunctionsById(functionIds) {
    let rr = new FunctionRetrieveRequest();
    rr.ids = functionIds;
    this.appService.getFunctions(rr).subscribe(res => {
      this.functionOptionList = res['items'];
    });
  }
  gradeListOption
  getGradesById(gradeIds) {
    let rr = new GradeRetrieveRequest();
    rr.ids = gradeIds;
    this.appService.getGrades(rr).subscribe(res => {
      this.gradeListOption = res['items'];
    });
  }
  roleListOption
  getRolesById(roleIds) {
    let rr = new RoleRetrieveRequest();
    rr.ids = roleIds;
    this.appService.getRoles(rr).subscribe(res => {
      this.roleListOption = res['items'];
    });
  }
  departmentOptionList
  getDepartmentsById(departmentids) {
    let rr = new DepartmentRetrieveRequest();
    rr.ids = departmentids;
    this.appService.getDepartments(rr).subscribe(res => {
      this.departmentOptionList = res['items'];
    });
  }

    // Get Bussiness Unit
    getBUs() {
      let rr = new BusinessUnitRetrieveRequest();
      this.appService.getBusinessUnits(rr).subscribe((res) => {
        this.bUOptionList = res['items'];
      });
    }
    isLocationOptionListLoading = false;
    // get Locations
    getLocations(businessUnitId) {
      this.isLocationOptionListLoading = true;
      let rr = new LocationRetrieveRequest();
      //console.log("Th bussiness Unit Ids : ", businessUnitId)
      rr.business_unit_ids = businessUnitId;
      this.appService.getLocations(rr).subscribe((res) => {
        this.locationOptionList = res['items'];
        this.isLocationOptionListLoading = false;
      });
    }
    isFunctionOptionListLoading = false;
    // Get Functions
    getFunctions(businessUnitId) {
      this.isFunctionOptionListLoading = true;
      let rr = new FunctionRetrieveRequest();
      rr.business_unit_ids = businessUnitId;
      this.appService.getFunctions(rr).subscribe((res) => {
        this.functionOptionList = res['items'];
        this.isFunctionOptionListLoading = false;
      });
    }
    isDepartmentOptionListLoading = false;
    // Get Functions
    getDepartments(functionId) {
      this.isDepartmentOptionListLoading = true;
      let rr = new DepartmentRetrieveRequest();
      rr.function_ids = functionId;
      this.appService.getDepartments(rr).subscribe((res) => {
        this.departmentOptionList = res['items'];
        this.isDepartmentOptionListLoading = false;
      });
    }

    onLocationOpened(isOpened) {
      if (!isOpened || this.employeeAdminSetup?.location.length) {
        return;
      }
      this.getLocations(this.filterForm.value.business_unit_ids);
    }

    onFunctionOpened(isOpened) {
      if (!isOpened || this.employeeAdminSetup?.function.length) {
        return;
      }
      this.getFunctions(this.filterForm.value.business_unit_ids);
    }

    onDepartmentOpened(isOpened) {
      if (!isOpened || this.employeeAdminSetup?.department.length) {
        return;
      }

      this.getDepartments(this.filterForm.value.function_ids);
    }

    // Setting Roles from API
    getRoleList() {
      this.appService.getRoles().subscribe(data => {
        this.roleListOption = data['items'];
      });
    }

    // Setting Grades from API
    getGradeList() {
      this.appService.getGrades().subscribe(data => {
        this.gradeListOption = data['items'];
      });
    }

  isFilterModalOpen = false
  openFilterModal(){
    this.isFilterModalOpen = true;
  }
  handleFilterOk() {
    // this.filterForm.reset();
    this.filters = {
      page:  1,
      per_page:  100,
      order_by:  'created_on',
      sort_order:  'desc',
      ids: [],
      emp_ids:  [],
      display_name:  '',
      employee_full_search:  this.searchValue,
      reporting_manager:null,
      business_unit_ids: this.filterForm.value.business_unit_ids ? this.filterForm.value.business_unit_ids : [],
      function_ids: this.filterForm.value.function_ids ? this.filterForm.value.function_ids : [],
      location_ids: this.filterForm.value.location_ids ? this.filterForm.value.location_ids : [],
      department_ids: this.filterForm.value.department_ids ? this.filterForm.value.department_ids : [],
      role_ids: this.filterForm.value.role_ids ? this.filterForm.value.role_ids : [],
      grade_ids: this.filterForm.value.grade_ids ? this.filterForm.value.grade_ids : [],
    };
    // console.log('filter',this.filters)
    this.getAllTalentCard()
    this.isFilterModalOpen = false;
  }
  emp_name
  // selectOption(e){
  //   // console.log('eeee',e)
  //   this.emp_name = e
  //   this.filters = {
  //     page:  1,
  //     per_page:  100,
  //     order_by:  'created_on',
  //     sort_order:  'desc',
  //     ids: [],
  //     emp_ids: [],
  //     display_name:  this.emp_name,

  //     reporting_manager:null,
  //     business_unit_ids:  [],
  //     function_ids: [],
  //     location_ids: [],
  //     department_ids: [],
  //     role_ids: [],
  //     grade_ids: [],
  //   };
  //   this.getAllTalentCard();
  // }
  handleFilterCancel() {
    this.isFilterModalOpen = false;
  }
  isTalentModalOpen = false;
  talentCardView(e){
    this.router.navigate(['talent-card-form'], { queryParams: { 'talentID': e.id } });
    // this.isTalentModalOpen = true;
    // this.talentCardForm.patchValue(e);
    // this.talentCardForm.get('mobility').patchValue(e.mobility?.value)
    // this.talentCardForm.get('geography').patchValue(e.mobility?.geography)
    // e.previous_promotion.sort(function(a,b){
    //   return  +new Date(a['month/year']) - +new Date(b['month/year']);
    // })
    // e.previous_experience.sort(function(a,b){
    //   return  +new Date(a.from) - +new Date(b.from);
    // })
    // e.company_experience.sort(function(a,b){
    //   return  +new Date(a.from) - +new Date(b.from);
    // })
    // this.patchPreviousPromotion(e.previous_promotion)
    // this.patchPreviousExperience(e.previous_experience)
    // this.patchCompanyExperience(e.company_experience)
    // this.talentCardForm.disable();
    // adminView(assessment) {
    // }
  }
  handleTalentOk(){
    this.onView();
    this.isTalentModalOpen = false;
  }
  handleTalentCancel(){
    this.onView();
    this.isTalentModalOpen = false;
  }

  patchPreviousPromotion(element){
    // console.log('previous_promotion',element)
    const previous_promotion = this.talentCardForm.get('previous_promotion') as FormArray;
    element.forEach(item => {
      previous_promotion.push(this.fb.group({
        "month/year": [item["month/year"], [Validators.required]],
        from: [item.from, [Validators.required]],
        to: [item.to, [Validators.required]],
      }));
    });
  }

  patchPreviousExperience(element){
    const previous_experience = this.talentCardForm.get('previous_experience') as FormArray;
    element.forEach(item => {
      previous_experience.push(this.fb.group({
        company_name: [item.company_name, [Validators.required]],
        role: [item.role, [Validators.required]],
        from_to_date: [[item.from,item.to], [Validators.required]],
        from: [item.from],
        to: [item.to],
      }));
    });
  }

  patchCompanyExperience(element){
    const company_experience = this.talentCardForm.get('company_experience') as FormArray;
    element.forEach(item => {
      company_experience.push(this.fb.group({
        lupin_role: [item.lupin_role, [Validators.required]],
        lupin_from_to_date: [[item.from,item.to], [Validators.required]],
        from: [item.from],
        to: [item.to],
      }));
    });
  }

  getTalentcardDump(){
    this.appService.getTalentCardExcelDump().subscribe((res:any)=>{
      if(res){
        Swal.fire({
          icon:'success',
          title:'Successfull',
          text:'Talent Card Data Will Recieve To Your Email.'
        })
      }
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Error',
        text:error.error.message,
      })
    })
  }

  searchServer():void{
    this.filters = {
      page:  1,
      per_page:  100,
      order_by:  'created_on',
      sort_order:  'desc',
      ids: [],
      emp_ids:  [],
      display_name:  '',
      employee_full_search:  this.searchValue,
      reporting_manager:null,
      business_unit_ids: [],
      function_ids:  [],
      location_ids: [],
      department_ids:  [],
      role_ids:  [],
      grade_ids:  [],
    };
    this.visible = false
    // console.log('filter',this.filters)
    this.getAllTalentCard()
  }

  reset(){
    this.searchValue = ''
    this.visible = false
    this.filters = {
      page:  1,
      per_page:  100,
      order_by:  'created_on',
      sort_order:  'desc',
      ids: [],
      emp_ids:  [],
      display_name:  '',
      employee_full_search:  '',
      reporting_manager:null,
      business_unit_ids: [],
      function_ids:  [],
      location_ids: [],
      department_ids:  [],
      role_ids:  [],
      grade_ids:  [],
    };
    // console.log('filter',this.filters)
    this.getAllTalentCard()
  }
}
