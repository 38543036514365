<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">List Of Campaigns</h3>
           
            <div class="row" style="justify-content: space-between;margin-left: 8px;margin-right: 8px;">
              <h3>
                <a [routerLink]="['/behaviour-transactions/campaign-details-form']"
                  routerLinkActive="router-link-active">
                  <i nz-icon class="mb-4 plusIcon " nzType="plus-circle" nzTheme="fill"></i></a>
              </h3>
   
              
             
              <h3>
                <button  nz-button  nz-tooltip nzTooltipTitle="Download Excel Report"
                nzTooltipPlacement="bottomLeft" nzType="primary" nzShape="circle"><i nz-icon nzType="download"
                    (click)="downloadExelPopup()"></i></button>
                  </h3>
            </div>


            <!-- [nzData]="projectDetails" -->
            <nz-table nzBordered [nzLoading]="loading" [nzFrontPagination]="false">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Levels</th>
                  <th>Created By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- *ngFor="let item of campaignList" -->
                <!-- {{ item.expected_end_date | date:'yyyy-MM-dd HH:mm:ss'}} -->
                <tr *ngFor="let item of campaignList">
                  <td>{{item.campaign_name | titlecase}}</td>
                  <td>{{item.start_date }}</td>
                  <td>{{item.end_date }}</td>
                  <!-- <td>Principle > Category > Nano Behaviour</td>  -->
                  <td>
                    <nz-breadcrumb nzSeparator=">">
                      <ng-container *ngFor="let ln of item.level_names">
                        <nz-breadcrumb-item>
                          {{ln}}
                        </nz-breadcrumb-item>
                      </ng-container>
                    </nz-breadcrumb>
                  </td>
                  <td>{{item.created_by_name | titlecase}}</td>
                  <td>
                    <a title="Edit" [routerLink]="['/behaviour-transactions/campaign-details-form/edit', item.id]">
                      <i class="ri-pencil-fill grayIcon mr-2"></i>
                    </a>
                    <a title="Delete" (click)="remove(item)"><i class="ri-delete-bin-6-fill grayIcon mr-2"></i></a>
                    <a title="Dashboard" [routerLink]="['/behaviour-transactions/campaign-dashboard', item.id]"
                      [queryParams]="{ levels: item.no_of_levels }"> <i
                        class="ri-pie-chart-box-line grayIcon mr-2"></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <nz-modal nzWidth="900px" [(nzVisible)]="popup" nzClosable="true" nzMaskClosable="false" 
  nzTitle="Select Campaigns" (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalOk()" [nzOkDisabled]="selected.length==0">
  <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="campaignList"
    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
    <thead>
      <tr>
        <th [(nzChecked)]="isAllChecked" (nzCheckedChange)="onAllChecked($event)"></th>
        <th>Campaign Name</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data">
        <td [nzChecked]="setofCheckedCampaigns.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
        <td>{{data.campaign_name}}</td>
      </tr>
    </tbody>
  </nz-table>
</nz-modal> 
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Generating Report. Please Wait... </p>
</ngx-spinner>

