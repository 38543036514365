import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {NzUploadFile, NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../app.service';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {
  BusinessUnit,
  BusinessUnitRetrieveRequest,
  Department,
  DepartmentRetrieveRequest,
  Employee,
  EmployeeRetrieveRequest,
  FunctionRetrieveRequest,
  Grade,
  GradeRetrieveRequest,
  LocationRetrieveRequest
} from '../../../app.models';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DisabledTimeFn, DisabledTimePartial} from 'ng-zorro-antd/date-picker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {
  // storage account url
  storageAccount: String;
  dateFormat = 'yyyy';
  joinDateFormat = 'dd-MM-yyyy';
  // upload logo related
  file: any;
  // fileList: NzUploadFile[] = [];
  // beforeUpload = (file: NzUploadFile): boolean => {
  //   this.fileList = this.fileList.concat(file);
  //   return false;
  // };
  loggedInUserDetail: any;
  //start for avatar upload
  loading = false;
  avatarUrl?: string;
  //end for avatar upload


  employeeForm!: FormGroup;
  business_unit_ids: any = [];
  selectedOrDirectProceedEmployees = [];
  selectedEmployeesFromAll: any = null;
  isEmployeeModalOpen = false;
  // files: NzUploadFile[] = [];
  selectedUser: any;
  color = '#26247B';
  IsSelectOne = true;
  isShowSelf = true;
  isShowBs = true;
  // mockOSSData = {
  //   dir: 'user-dir/',
  //   expire: '1577811661',
  //   host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
  //   accessId: 'c2hhb2RhaG9uZw==',
  //   policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
  //   signature: 'ZGFob25nc2hhbw=='
  // };

  employeeId: any;
  action = 'create';
  isFormLoading = false;
  allEmployeeList: any;
  empFilterOptionList
  dLForm: FormGroup;


  // state required for the auto complete
  employeeOptionList: Employee[] = [];
  employeeOptionList1: Employee[] = [];
  employeeVariableFilterOptionList: Employee[] = [];
  employeeVariableFilterlistLoading = {};
  employeeFilterOptionList = {};
  variableFilterKeys = [];
  filterSearchKey = {};
  employeeOptionListSearchChange$ = new BehaviorSubject('');
  isEmployeeOptionListLoading = false;
  userType: any;
  isFilterModalOpen: boolean = false;
  roleListOption: any;
  gradeListOption: any;
  employeeAdminSetup: any = null;
  onFirstLoadAdminCheck: boolean = false;
  currentEmployee: any;
  variable_filter = [];
  variable_filterCheckbox = [];
  isAdminOnSame = false;
  listLoading = false;
  cachedEmployees: Employee[] = [];
  searchKey = '';
  isSearchButtonEnable = false;

  onEmployeeOptionListSearch(value: string): void {
    // this.isEmployeeOptionListLoading = true;
    this.employeeOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  functionOptionList: Function[] = [];
  functionOptionListSearchChange$ = new BehaviorSubject('');
  isFunctionOptionListLoading = false;

  onFunctionOptionListSearch(value: string): void {
    this.isFunctionOptionListLoading = true;
    this.functionOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  departmentOptionList: Department[] = [];
  departmentOptionListSearchChange$ = new BehaviorSubject('');
  isDepartmentOptionListLoading = false;

  onDepartmentOptionListSearch(value: string): void {
    this.isDepartmentOptionListLoading = true;
    this.departmentOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  locationOptionList: Location[] = [];
  locationOptionListSearchChange$ = new BehaviorSubject('');
  isLocationOptionListLoading = false;

  onLocationOptionListSearch(value: string): void {
    this.isLocationOptionListLoading = true;
    this.locationOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  bUOptionList: BusinessUnit[] = [];
  businessUnitOptionListSearchChange$ = new BehaviorSubject('');
  isBusinessUnitOptionListLoading = false;

  onBusinessUnitOptionListSearch(value: string): void {
    this.isBusinessUnitOptionListLoading = true;
    this.businessUnitOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  gradeOptionList: Grade[] = [];
  gradeOptionListSearchChange$ = new BehaviorSubject('');
  isGradeOptionListLoading = false;

  onGradeOptionListSearch(value: string): void {
    this.isGradeOptionListLoading = true;
    this.gradeOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }


  get variable_filter_field() {
    return this.employeeForm.get('variable_filter_field') as FormArray;
  }

  get variable_filter_select() {
    return this.employeeForm.get('variable_filter_select') as FormArray;
  }

  // isRoleOptionListLoading: boolean = false;
  roleOptionList: any;
  today = new Date();

  constructor(
    public fb: FormBuilder,
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public message: NzMessageService,
    public datepipe: DatePipe,
    public router: Router,
    private modal: NzModalService,
    private spinner: NgxSpinnerService
  ) {

  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDateTime: DisabledTimeFn = () => ({
    nzDisabledHours: () => this.range(0, 24).splice(4, 20),
    nzDisabledMinutes: () => this.range(30, 60),
    nzDisabledSeconds: () => [55, 56]
  });


  // transformFile = (file: NzUploadFile) => {
  //   const suffix = file.name.slice(file.name.lastIndexOf('.'));
  //   const filename = Date.now() + suffix;
  //   file.url = this.mockOSSData.dir + filename;

  //   return file;
  // };
  // getExtraData = (file: NzUploadFile) => {
  //   const { accessId, policy, signature } = this.mockOSSData;

  //   return {
  //     key: file.url,
  //     OSSAccessKeyId: accessId,
  //     policy: policy,
  //     Signature: signature
  //   };
  // };

  // onChange(e: NzUploadChangeParam): void {
  //   console.log('Aliyun OSS:', e.fileList);
  // }
  //date picker
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;

  disabledStartDate = (current: Date): boolean => {
    // if (!current || !this.assessmentForm.get("end_date").value) {
    //   return false;
    // }
    // return start_date.getTime() > (new Date(this.assessmentForm.get("end_date").value)).getTime();
    return differenceInCalendarDays(current, new Date()) < 0;
  };

  disabledEndDate = (end_date: Date): boolean => {
    if (!end_date || !this.employeeForm.get('dob').value) {
      return false;
    }
    return end_date.getTime() <= (new Date(this.employeeForm.get('dob').value)).getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
    // console.log('handleStartOpenChange', open);
  }

  handleEndOpenChange(open: boolean): void {
    // console.log('handleEndOpenChange', open);
  }

  isAdminClicked() {
    if (this.onFirstLoadAdminCheck) {
      this.onFirstLoadAdminCheck = false;
      return;
    }

    if (this.employeeForm.value.is_admin) {
      this.isFilterModalOpen = true;
    }
  }

  // Filter Modal Datas
  handleFilterCancel() {


    if (
      !this.dLForm.value.business_unit_ids_filter?.length
      && !this.dLForm.value.location_ids_filter?.length
      && !this.dLForm.value.function_ids_filter?.length
      && !this.dLForm.value.department_ids_filter?.length
      && !this.dLForm.value.role_ids_filter?.length
      && !this.dLForm.value.grade_ids_filter?.length) {
      this.employeeForm.get('is_admin').setValue(false);
      this.isFilterModalOpen = false;
    } else {
      this.isFilterModalOpen = false;
    }


  }

  handleFilterOk() {
    if (
      !this.dLForm.value.business_unit_ids_filter?.length
      && !this.dLForm.value.location_ids_filter?.length
      && !this.dLForm.value.function_ids_filter?.length
      && !this.dLForm.value.department_ids_filter?.length
      && !this.dLForm.value.role_ids_filter?.length
      && !this.dLForm.value.grade_ids_filter?.length) {
      this.modal.confirm({
        nzTitle: 'Are you sure',
        nzContent: 'Are you sure want to give all filter permission to this user ?',
        nzOnOk: () => {
          this.isFilterModalOpen = false;
        }
      });
    } else {
      this.isFilterModalOpen = false;
    }

  }


  // Filter fields
  // Get Bussiness Unit
  getBUsFilter() {
    let rr = new BusinessUnitRetrieveRequest();
    rr.per_page = null;
    this.appService.getBusinessUnits(rr).subscribe((res) => {
      this.bUOptionList = res['items'];
    });
  }

  // get Locations
  getLocationsFilter(businessUnitId) {
    this.isLocationOptionListLoading = true;
    let rr = new LocationRetrieveRequest();
    rr.per_page = null;
    // console.log('Th bussiness Unit Ids : ', businessUnitId);
    rr.business_unit_ids = businessUnitId;
    this.appService.getLocations(rr).subscribe((res) => {
      this.locationOptionList = res['items'];
      this.isLocationOptionListLoading = false;
    });
  }

  // Get Functions
  getFunctionsFilter(businessUnitId) {
    this.isFunctionOptionListLoading = true;
    let rr = new FunctionRetrieveRequest();
    rr.per_page = null;
    rr.business_unit_ids = businessUnitId;
    this.appService.getFunctions(rr).subscribe((res) => {
      // console.log('functions : ', res)
      this.functionOptionList = res['items'];
      this.isFunctionOptionListLoading = false;
    });
  }

  // Get Functions
  getDepartmentsFilter(functionId) {
    this.isDepartmentOptionListLoading = true;
    let rr = new DepartmentRetrieveRequest();
    rr.per_page = null;
    rr.function_ids = functionId;
    this.appService.getDepartments(rr).subscribe((res) => {
      this.departmentOptionList = res['items'];
      this.isDepartmentOptionListLoading = false;
    });
  }

  onLocationOpened(isOpened) {
    if (!isOpened) {
      return;
    }
    this.getLocationsFilter(this.dLForm.value.business_unit_ids_filter);
  }

  onFunctionOpened(isOpened) {
    if (!isOpened) {
      return;
    }
    this.getFunctionsFilter(this.dLForm.value.business_unit_ids_filter);
  }

  onDepartmentOpened(isOpened) {
    if (!isOpened) {
      return;
    }
    this.getDepartmentsFilter(this.dLForm.value.function_ids_filter);
  }

  onGradeOpened(isOpened) {
    if(!isOpened){
      return;
    }
    this.getGradeListFilter(this.dLForm.value.business_unit_ids_filter)
  }

  // Setting Roles from API
  getRoleListFilter() {
    this.appService.getRoles().subscribe(data => {
      this.roleListOption = data['items'];
    });
  }

  // Setting Grades from API
  getGradeListFilter(businessId) {
    // console.log("from here")
    // this.appService.getGrades().subscribe(data => {
    //   this.gradeListOption = data['items'];
    // });

    this.isGradeOptionListLoading = true;
    let rr = new GradeRetrieveRequest();
    rr.per_page = null;
    rr.business_unit_ids = businessId;
    this.appService.getGrades(rr).subscribe((res) => {
      this.gradeListOption = res['items'];
      // console.log("dataaaaaaaaa",this.gradeListOption)
      this.isGradeOptionListLoading = false;
    });
  }



  // Filter data ends here

  async ngOnInit() {
    this.loggedInUserDetail = this.appService.getMe();

    // add filter to list
    const filter = this.loggedInUserDetail?.tenant?.variable_filter_field ? this.loggedInUserDetail?.tenant?.variable_filter_field : []
    if (filter.length != 0) {
      filter.forEach(el => {
        this.variableFilterKeys.push(el);
      });
    }
    this.employeeForm = this.fb.group({
      first_name: ["", [Validators.required]],
      middle_name: [""],
      last_name: ["", [Validators.required]],
      display_name: ["", [Validators.required]],
      designation: ["", [Validators.required]],
      dob: [""],
      gender: ["", [Validators.required]],
      joining_date: ["", [Validators.required]],
      grade_id: ["", [Validators.required]],
      department_id: ["", [Validators.required]],
      function_id: ["", [Validators.required]],
      location_id: ["", [Validators.required]],
      business_unit_id: ["", [Validators.required]],
      employee_id: ["", [Validators.required]],
      reporting_manager: [""],
      is_admin: [""],
      is_manager: [""],
      // variable_filter_field:[""],
      variable_filter_field: this.fb.array([]),
      variable_filter_select: this.fb.array([]),
      // is_variable_filter:[""],
      is_department_head: [""],
      is_active: [""],
      is_functional_head: [""],
      is_bu_head: [""],
      // is_admin: [""],
      profile_pic: [""],
      // username: ["", [Validators.required]],
      username: undefined,
      // password: [""],
      //// password: undefined,
      email: ["", [Validators.required, Validators.email, Validators.minLength(7)]],
      about_me: [""],
      role_id: undefined,
      mobile_login_type: ['internal'],
      web_login_type: ['internal'],
    });
    this.employeeForm.get('is_admin').setValue(false);
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.employeeId = params['id'];
        this.action = 'edit';
        // this.loadDataFromServer(1, 100, null, null, null)

        // this.getEmployees();
        this.getEmployeeById(this.employeeId);

        // this.employeeForm.get('reporting_manager').disable();
        this.isAdminOnSame = this.employeeId == this.loggedInUserDetail.employee.id ? true : false


      } else {
        this.getBUs();
        this.getLocations();
        this.getFunctions();
        this.getGrades();
        this.getDepartments();
        // this.getEmployees();
        // this.loadDataFromServer(1, 100, null, null, null)
        this.getRoleList();
        // this.employeeForm.get('reporting_manager').disable();
        // this.patchVariableFilter(this.variable_filter)
      }
      // create filter fields
      let filter = {
        'employee_id': this.employeeId
      }

      this.appService.getFilterDataByFilter(filter).subscribe(value => {


        this.variableFilterKeys.forEach((filter) => {
          // console.log(filter)
          let flag = true;
          for (let i = 0; value['items'].length > i; i++) {
            let item = value['items'][i]
            // console.log('filter',filter)
            // console.log('filter',item)

            if (filter== item['filter_key']) {
              flag = false
              this.variable_filter_field.push(this.fb.group({
                filter_name: new FormControl(item['filter_key']),
                id: new FormControl(item['id']),
                employee_id: new FormControl(item.hasOwnProperty('filter_employee_data') ? item['filter_employee_data']['id'] : null)
              }))
              this.variable_filter_select.push(this.fb.group({
                filter_name: new FormControl(item['filter_key']),
                id: new FormControl(item['id']),
                status: new FormControl(item['self_selected'])
              }));
              if (item.hasOwnProperty('filter_employee_data')) {
                this.employeeFilterOptionList[item['filter_key']] = [item?.['filter_employee_data']]
              }
            }
          }


          if (flag) {
            this.variable_filter_field.push(this.fb.group({
              filter_name: new FormControl(filter),
              id: new FormControl(null),
              employee_id: new FormControl('')
            }))
            this.variable_filter_select.push(this.fb.group({
              filter_name: new FormControl(filter),
              id: new FormControl(null),
              status: new FormControl(false)
            }));
          }
        });


        // finish if variable filter available

      }, error => {
        // Swal.fire({titleText: 'Error', text: 'There is an error to get filter data'})
      })

    });

    if (sessionStorage.getItem('me')) {
      let userDetails = JSON.parse(decodeURIComponent(atob(sessionStorage.getItem('me'))));
      // this.userId = userDetails.user_id;
      this.userType = userDetails.user_type;
    }
    this.resetCreateModal();
    await this.getBUsFilter();
    await this.getRoleListFilter();
    // await this.getGradeListFilter();
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
  }

  resetCreateModal(): void {
    this.dLForm = this.fb.group({
      business_unit_ids_filter: [null],
      location_ids_filter: [null],
      function_ids_filter: [null],
      department_ids_filter: [null],
      role_ids_filter: [null],
      grade_ids_filter: [null],
      status: [true]
    });
  }


  async getEmployeeById(id) {
    this.appService.getEmployeeById(id).subscribe((result: any) => {
      this.currentEmployee = result;
      // console.log('get values:', result);
      this.employeeForm.patchValue(result);

      // let fullName= result.first_name +' '+ result.last_name;
      this.employeeForm.get('username').setValue(result.user.username);
      this.employeeForm.get('email').setValue(result.user.email);
      this.employeeForm.get('about_me').setValue(result.user.about_me);
      this.employeeForm.get('display_name').setValue(result.display_name);
      this.employeeForm.get('is_manager').setValue(result.is_manager == 'yes');
      this.employeeForm.get('is_department_head').setValue(result.is_department_head == 'yes');
      this.onFirstLoadAdminCheck = true;
      this.employeeForm.get('is_admin').setValue(result.is_admin == 'yes');
      this.employeeForm.get('is_active').setValue(result.is_active == 'yes');
      this.employeeForm.get('is_functional_head').setValue(result.is_functional_head == 'yes');
      this.employeeForm.get('is_bu_head').setValue(result.is_bu_head == 'yes');
      // this.employeeForm.get('is_admin').setValue(result.is_admin == 'yes');
      // this.employeeForm.get('profile_pic').setValue(result?.profile_pic?.original_image_url);
      // console.log("pic", result?.profile_pic?.original_image_url);
      this.avatarUrl = this.storageAccount + result?.profile_pic?.original_image_url;
      this.employeeForm.get('function_id').setValue(result.function_id);
      this.employeeForm.get('department_id').setValue(result.department_id);
      this.employeeForm.get('grade_id').setValue(result.grade_id);
      this.employeeForm.get('employee_id').setValue(result.employee_id);
      this.employeeForm.get('web_login_type').setValue(result.user.web_login_type);
      this.employeeForm.get('mobile_login_type').setValue(result.user.mobile_login_type);
      this.selectedOrDirectProceedEmployees = [result.reporting_manager];

      // sub admin get data
      // console.log('Thye is_admin is : ', result.is_admin);

      if (result.is_admin == 'yes') {
        this.appService.getEmployeeAdminFilterById(result.id).subscribe(async (res: any) => {
          // console.log('The is admin result is : ', res);
          this.employeeAdminSetup = res;
          this.dLForm.setValue({
            business_unit_ids_filter: res.business_unit,
            location_ids_filter: res.location,
            function_ids_filter: res.function,
            department_ids_filter: res.department,
            role_ids_filter: res.role,
            grade_ids_filter: res.grade,
            status: true
          });
          await this.getLocationsFilter(res.business_unit);
          await this.getFunctionsFilter(res.business_unit);
          await this.getDepartmentsFilter(res.function);
          await this.getGradeListFilter(res.business_unit)
        });
      }


      this.getReportingMangerSelected(result.reporting_manager);

      this.getBUs();
      this.getLocations();
      this.getFunctions();
      // await this.getLocationsFilter(result.business_unit);
      // await this.getFunctionsFilter(result.business_unit);
      // await this.getDepartmentsFilter(result.function);
      // await this.getGradeListFilter(result.grade_id) // this.getFunctions();
      this.getGrades();
      this.getDepartments();
      this.getEmployees();
      this.getRoleList();

    });


  }

  getBUs() {
    // this.appService.getBusinessUnits().subscribe(data => {
    //   this.bUOptionList = data['items'];
    // });
    const optionList = (functionName: string) => {
      this.isBusinessUnitOptionListLoading = true;
      let rr = new BusinessUnitRetrieveRequest(functionName);
      rr.per_page = null;
      return this.appService.getBusinessUnits(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<BusinessUnit[]> = this.businessUnitOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.bUOptionList = data;
      this.isBusinessUnitOptionListLoading = false;
    });
  }

  getLocations() {
    // this.appService.getLocations().subscribe(data => {
    //   this.locationOptionList = data['items'];
    // });

    const optionList = (functionName: string) => {
      this.isLocationOptionListLoading = true;
      let rr = new LocationRetrieveRequest(functionName);
      rr.per_page = null;
      rr.business_unit_ids = [this.employeeForm.get('business_unit_id').value];
      return this.appService.getLocations(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Location[]> = this.locationOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.locationOptionList = data;
      this.isLocationOptionListLoading = false;
    });
  }

  getFunctions() {
    // this.appService.getFunctions().subscribe(data => {
    //   this.functionOptionList = data['items'];
    // });

    const optionList = (functionName: string) => {
      this.isFunctionOptionListLoading = true;
      let rr = new FunctionRetrieveRequest(functionName);
      rr.per_page = null;
      rr.business_unit_ids = [this.employeeForm.get('business_unit_id').value];
      return this.appService.getFunctions(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Function[]> = this.functionOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.functionOptionList = data;
      this.isFunctionOptionListLoading = false;
    });
  }

  getDepartments() {
    // this.appService.getDepartments().subscribe(data => {
    //   this.departmentOptionList = data['items'];
    // });
    const optionList = (functionName: string) => {
      this.isDepartmentOptionListLoading = true;
      let rr = new DepartmentRetrieveRequest(functionName);
      rr.per_page = null;
      rr.function_ids = [this.employeeForm.get('function_id').value];
      return this.appService.getDepartments(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Department[]> = this.departmentOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.departmentOptionList = data;
      this.isDepartmentOptionListLoading = false;
    });
  }

  getGrades() {
    // this.appService.getGrades().subscribe(data => {
    //   this.gradeOptionList = data['items'];
    // });

    // console.log("grade called here")
    const optionList = (functionName: string) => {
      this.isGradeOptionListLoading = true;
      let rr = new GradeRetrieveRequest(functionName);
      rr.per_page = null;
      rr.business_unit_ids = [this.employeeForm.get('business_unit_id').value];
      return this.appService.getGrades(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Grade[]> = this.gradeOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.gradeOptionList = data;
      this.isGradeOptionListLoading = false;
    });
  }


  getEmployees() {
    if (this.isEmployeeModalOpen) {
      this.spinner.show()
    }
    const optionList = (functionName: string) => {
      this.isEmployeeOptionListLoading = true;
      let rr = new EmployeeRetrieveRequest(functionName);
      rr.per_page = null;
      // rr.reporting_manager = this.loggedInUserDetail.employee.id;
      return this.appService.getEmployees(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Employee[]> = this.employeeOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(1000))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      //const index = data.findIndex(element => element.user_id === this.loggedInUserDetail?.id);
      //  data.splice(index, 1);
      // data.forEach((element,index)=>{ if(element.user_id == this.loggedInUserDetail?.id) delete data[index];
      // });
      data.forEach(element => {
        element.display_name = element.display_name + ' [' + element.user.email + ']';
      });
      this.employeeOptionList = data;
      this.allEmployeeList = data
      this.empFilterOptionList = this.allEmployeeList;
      this.isEmployeeOptionListLoading = false;
      this.spinner.hide();
    }, err => {
      this.employeeOptionList = [];
      this.allEmployeeList = [];
      this.empFilterOptionList = []
      this.isEmployeeOptionListLoading = false;
      this.spinner.hide();
    });
  }

  resetValue = false

  resetForm(): void {
    this.resetValue = true
    if (this.action == "create") {
      this.employeeForm.reset();
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  submitForm() {
    if (!this.resetValue) {
      // for (const i in this.employeeForm.controls) {
      //   this.employeeForm.controls[i].markAsDirty();
      //   this.employeeForm.controls[i].updateValueAndValidity();
      // }
      this.isFormLoading = true;
      // Create the location on the server.
      //if (this.employeeForm.status === 'VALID') {
      let dob = this.datepipe.transform(this.employeeForm.value.dob, 'yyyy-MM-dd');
      // if (dob == null) {
      //   dob = '';
      // }


      let jd = this.datepipe.transform(this.employeeForm.value.joining_date, 'yyyy-MM-dd');
      let formData: any = new FormData();
      formData.append('first_name', this.employeeForm.get('first_name').value);
      formData.append('last_name', this.employeeForm.get('last_name').value);
      if (this.employeeForm.get('middle_name').value) {
        formData.append('middle_name', this.employeeForm.get('middle_name').value);
      }
      formData.append('display_name', this.employeeForm.get('display_name').value);
      formData.append('designation', this.employeeForm.get('designation').value);
      if (dob) {
        formData.append('dob', dob);
      }
      formData.append('grade_id', this.employeeForm.get('grade_id').value);
      formData.append('gender', this.employeeForm.get('gender').value);
      formData.append('joining_date', jd);
      formData.append('department_id', this.employeeForm.get('department_id').value);
      formData.append('employee_id', this.employeeForm.get('employee_id').value);
      formData.append('function_id', this.employeeForm.get('function_id').value);
      formData.append('location_id', this.employeeForm.get('location_id').value);
      formData.append('business_unit_id', this.employeeForm.get('business_unit_id').value);
      if (this.employeeForm.get('reporting_manager').value) {
        formData.append('reporting_manager', this.employeeForm.get('reporting_manager').value);
      }
      formData.append('is_manager', this.employeeForm.get('is_manager').value ? 'yes' : 'no');
      formData.append('is_department_head', this.employeeForm.get('is_department_head').value ? 'yes' : 'no');
      formData.append('is_admin', this.employeeForm.get('is_admin').value ? 'yes' : 'no');
      formData.append('is_active', this.employeeForm.get('is_active').value ? 'yes' : 'no');
      formData.append('is_functional_head', this.employeeForm.get('is_functional_head').value ? 'yes' : 'no');
      formData.append('is_bu_head', this.employeeForm.get('is_bu_head').value ? 'yes' : 'no');
      formData.append('about_me', this.employeeForm.get('about_me').value);
      formData.append('email', this.employeeForm.get('email').value);
      // formData.append("password", this.employeeForm.get('password').value);
      if (this.currentEmployee?.user?.username != this.employeeForm.get('username').value) {
        formData.append('username', this.employeeForm.get('username').value);
      }
      formData.append('mobile_login_type', this.employeeForm.get('mobile_login_type').value);
      formData.append('web_login_type', this.employeeForm.get('web_login_type').value);
      if (this.employeeForm.get('role_id').value) {
        formData.append('role_id', this.employeeForm.get('role_id').value);
      }
      formData.append('profile_pic', this.file);

      this.variable_filter_select.controls.forEach((item, index) => {

        let data = {
          employee_id: this.employeeId,
          filter_key: item.get('filter_name').value,
          self_selected: item.get('status').value,
          filter_employee_id: this.variable_filter_field.controls[index].get('employee_id').value
        }
        if (item.get('id').value == null) {
          this.appService.postFilterDataByFilter(data).subscribe((res) => {
            // alert('success' + res);
          }, error => {
            // alert('error' + error);
          });


        } else {
          this.appService.putFilterDataByFilter(item.get('id').value, data).subscribe((res) => {
            // alert('success' + res);
          }, error => {
            // alert('error' + error);
          });

        }
        // console.log(item.get('filter_name').value);
        // alert('i am' + item.get('filter_name').value);
      });

      // if (this.employeeForm.controls['profile_pic'].value[0]) {
      //   formData.append("logo_image", this.employeeForm.controls['profile_pic'].value[0], this.employeeForm.controls['profile_pic'].value[0].name);
      // }
      // formData.append("profile_pic", this.employeeForm.get('profile_pic').value);

      //mahesh image upload code
      // this.fileList.forEach((file: any) => {
      //   formData.append('profile_pic', file);
      // });


      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createEmployee(formData);
        message = 'Employee "' + this.employeeForm.controls['display_name'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateEmployee(formData, this.employeeId);
        message = 'Employee "' + this.employeeForm.controls['display_name'].value + '" has been updated.';
      }


      subscriber.subscribe(res => {
        // console.log('The added Employee data : ', res);

        if (res.id && this.employeeForm.get('is_admin').value) {

          const data = {
            emp_id: res.id,
            business_unit: this.dLForm.value.business_unit_ids_filter,
            location: this.dLForm.value.location_ids_filter,
            department: this.dLForm.value.department_ids_filter,
            function: this.dLForm.value.function_ids_filter,
            role: this.dLForm.value.role_ids_filter,
            grade: this.dLForm.value.grade_ids_filter,
          };
          let adminFilterSubscriber;
          if (!this.employeeAdminSetup) {
            adminFilterSubscriber = this.appService.createEmployeeAdminFilter(data);
          } else {
            adminFilterSubscriber = this.appService.updateEmployeeAdminFilter(data);
          }

          adminFilterSubscriber.subscribe(result => {
            this.isFormLoading = false;

            this.message.create('success', message, {
              nzDuration: 5000
            });
            this.router.navigate(['/employee']);
            if (this.action === 'edit') {
              sessionStorage.setItem('isReloadGetMe', 'yes');
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          });
        } else {
          this.isFormLoading = false;

          this.message.create('success', message, {
            nzDuration: 5000
          });
          this.router.navigate(['/employee']);
          if (this.action === 'edit') {
            sessionStorage.setItem('isReloadGetMe', 'yes');
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        }


      }, error => {
        this.isFormLoading = false;

        //let errorMessage = error.error.message
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  //start for avatar upload

  // beforeUpload = (file: NzUploadFile): boolean => {
  //   this.files = this.files.concat(file);
  //   return false;
  // };

  // beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
  //   return new Observable((observer: Observer<boolean>) => {
  //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //     if (!isJpgOrPng) {
  //       this.message.create('error', 'You can only upload JPG/PNG file!', {
  //         nzDuration: 5000
  //       });
  //       observer.complete();
  //       return;
  //     }
  //     const isLt2M = file.size! / 1024 / 1024 < 2;
  //     if (!isLt2M) {
  //       this.message.create('error', 'Image must smaller than 2MB!', {
  //         nzDuration: 5000
  //       });
  //       observer.complete();
  //       return;
  //     }
  //     observer.next(isJpgOrPng && isLt2M);
  //     observer.complete();

  //     // this.getBase64(this.employeeForm.get('profile_pic').value, (img: string) => {
  //     //   this.loading = false;
  //     //   this.avatarUrl = img;
  //     // });
  //   });
  // };

  // private getBase64(img: File, callback: (img: string) => void): void {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result!.toString()));
  //   reader.readAsDataURL(img);
  // }

  // handleChange(info: { file: NzUploadFile }): void {
  //   switch (info.file.status) {
  //     case 'uploading':
  //       this.loading = true;
  //       break;
  //     case 'done':
  //       // Get this url from response in real world.
  //       console.log("file=",info.file!.originFileObj);
  //       this.getBase64(info.file!.originFileObj!, (img: string) => {
  //         this.loading = false;
  //         this.avatarUrl = img;
  //       });
  //       console.log("file1=",this.avatarUrl);
  //       break;
  //     case 'error':
  //       this.message.create('error', 'Network error', {
  //         nzDuration: 5000
  //       });
  //       this.loading = false;
  //       break;
  //   }
  // }
  //start for avatar upload

  setDisplayName() {
    let displayName = '';
    if (this.employeeForm.get('first_name').value) {
      displayName += this.employeeForm.get('first_name').value;
    }
    if (this.employeeForm.get('last_name').value) {
      displayName += ' ' + this.employeeForm.get('last_name').value;
    }
    const disName = displayName.replace(/[^a-zA-Z ]/g, "");
    this.employeeForm.get('display_name').setValue(disName);
  }

  // RoleOptionList
  getRoleList() {
    this.appService.getRoles().subscribe(res => {
      this.roleOptionList = res['items'];
      // console.log('rolelist:', this.roleOptionList);

    });
  }

  onBUChange(value: any) {
    // if (value === null){
    this.employeeForm.controls.location_id.setValue(null);
    this.employeeForm.controls.function_id.setValue(null);
    this.employeeForm.controls.department_id.setValue(null);
    this.employeeForm.controls.grade_id.setValue(null);
    // }
  }

  onLocationChange(value: any) {
    // if (value === null){
    this.employeeForm.controls.function_id.setValue(null);
    this.employeeForm.controls.department_id.setValue(null);
    this.employeeForm.controls.grade_id.setValue(null);
    // }
  }

  onFunctionChange(value: any) {
    // if (value === null) {
    this.employeeForm.controls.department_id.setValue(null);
    this.employeeForm.controls.grade_id.setValue(null);
    // }
  }

  onDepartmentChange(value: any) {
    // if (value === null)
    this.employeeForm.controls.grade_id.setValue(null);
  }

  // logedin user not allow to select
  onReportingManagerChange(value: any) {
  }

  // file upload related
  imageAndVideoChange($event) {

  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
    this.file = file;
    // return false;
    return true;
    // return new Observable((observer: Observer<boolean>) => {
    //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //   if (!isJpgOrPng) {
    //     this.msg.error('You can only upload JPG file!');
    //     observer.complete();
    //     return;
    //   }
    //   const isLt2M = file.size! / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.msg.error('Image must smaller than 2MB!');
    //     observer.complete();
    //     return;
    //   }
    //   observer.next(isJpgOrPng && isLt2M);
    //   observer.complete();
    // });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }): void {
    this.getBase64(info.file!.originFileObj!, (img: string) => {
      this.loading = false;
      this.avatarUrl = img;
    });
  }

  back() {
    this.router.navigate(['employee']);
  }

  alphaNumberOnly(event: any) {  // Accept only letters and numbers
    if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122) || event.keyCode == 46 || event.keyCode == 32 || event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode == 32 || (event.keyCode >= 65 && event.keyCode <= 90)) {
      return true;
    } else {
      return false;
    }
  }

  // fuction added by Althaf
  buttonDisabled = []

  setEmployeesForLA(employees) {
    this.selectedEmployeesFromAll = employees;
    this.buttonDisabled = Array.from(this.selectedEmployeesFromAll)
  }


  handleOk(): void {
    this.isEmployeeModalOpen = false;
    this.selectedOrDirectProceedEmployees = [];
    this.selectedOrDirectProceedEmployees = Array.from(this.selectedEmployeesFromAll);
    this.employeeForm.get('reporting_manager').patchValue(this.selectedOrDirectProceedEmployees[0]);
  }

  handleCancel(): void {
    this.isEmployeeModalOpen = false;
  }

  //
  // selectEmp() {
  //   this.isEmployeeModalOpen = true;
  //   this.getEmployees();
  // }

  checkedData = []


  // onPaste(event: ClipboardEvent) {
  //   console.log('sss',event)
  //   let clipboardData = event.clipboardData;
  //   let pastedText = clipboardData.getData('text');
  //   let trimmedText = pastedText.replace(/[&\/\\#,+()$~%^'":*?<>{}]/g, '');
  //   (<HTMLInputElement>document.getElementById('username')).value = trimmedText;
  //   const removeData = trimmedText;
  //   setTimeout(() => {
  //     this.employeeForm.get('username').patchValue(removeData)
  //     event.preventDefault();
  //   }, 100)
  // }
  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, display_name: string | null): void {
    this.listLoading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new EmployeeRetrieveRequest('');
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    if (display_name != null) {
      rr.display_name = display_name ? display_name : null;
    }

    this.appService.getEmployeesSearch(rr).subscribe(data => {

        // this.total = data['_meta']['total_items'];
        this.employeeOptionList = data['items'];
        let customData: any = this.employeeOptionList;
        customData.forEach(item => {
          item.display_name = item.first_name + ' ' + item.last_name + '[' + item.user?.email + ']';

        })
        this.employeeOptionList = customData;
        if (display_name == null) {
          this.cachedEmployees = customData;
        }

        this.listLoading = false;
        this.isSearchButtonEnable = false;

      }, (err) => {
        this.isSearchButtonEnable = false;
        this.employeeOptionList = [];
        this.listLoading = false;
      }
    );
  }


  hide(e) {
  }

  onSearch(searchText: string) {
    if (searchText != '') {
      this.searchKey = searchText
      this.isSearchButtonEnable = true;
    } else {
      this.employeeOptionList = this.cachedEmployees;
    }
  }

  onFilterSearch(searchText: string, itemObj) {
    // console.log(searchText);
    if (searchText != '') {
      this.filterSearchKey[itemObj.filter_name] = searchText;
    } else {
      // this.employeeFilterOptionList[itemObj.filter_name] = this.cachedEmployees;
    }
  }

  onSearchButtonClick() {
    this.employeeForm.get('reporting_manager').setValue('')
    this.loadDataFromServer(1, 100, null, null, this.searchKey);
  }

  onVariableFilterSearchButtonClick(item: any, k) {
    this.employeeVariableFilterlistLoading[item.value.filter_name] = true;
    this.variable_filter_field.controls[k].get('employee_id').setValue('');
    let rr = new EmployeeRetrieveRequest('');
    rr.display_name = this.filterSearchKey[item.value.filter_name];
    rr.variable_filter_key = item.value.filter_name;
    this.appService.getEmployeesSearch(rr).subscribe(data => {
      this.employeeVariableFilterlistLoading[item.value.filter_name] = false;
      // exclude self
      this.employeeFilterOptionList[item.value.filter_name] = data['items'].filter((item) => item['id'] != this.employeeId);
      // console.log("employee filter option print", this.employeeFilterOptionList[item.value.filter_name])
    });

  }


  getReportingMangerSelected(id) {
    this.listLoading = true;
    this.appService.getEmployeeById(id).subscribe((result: any) => {
      // let customData=result;
      result.display_name = result.display_name + '[' + result.user?.email + ']';
      this.employeeOptionList.push(result);
      this.cachedEmployees.push(result)
      this.listLoading = false;
    },(error)=>{
      this.listLoading = false;
    });
  }
}

