import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RoleRetrieveRequest } from 'src/app/app.models';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-form-dashboard',
  templateUrl: './form-dashboard.component.html',
  styleUrls: ['./form-dashboard.component.css']
})
export class FormDashboardComponent implements OnInit, OnDestroy {
  private sub: any;

  loading = false;

  launch: any = {};

  totalParticipants: number = 0;

  notStarted: number = 0;
  notStartedPercentage: number = 0;

  saved: number = 0;
  savedPercentage: number = 0;

  submitted: number = 0;
  submittedPercentage: number = 0;

  rejected: number = 0;
  rejectedPercentage: number = 0;

  approved: number = 0;
  approvedPercentage: number = 0;

  constructor(
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loading = true;

    this.sub = this.route.params.subscribe(params => {
      if(params['id'] > 0){
        this.appService.getFormLaunchById(params['id']).subscribe((launch: any) => {
          this.launch = launch;

          this.totalParticipants = launch.employee_ids.length;
          this.appService.getSubmissionsByLaunchId(params['id']).subscribe((submissions: any) => {
            this.notStarted = this.totalParticipants - submissions.length;
            this.notStartedPercentage = Number((this.notStarted * 100 / this.totalParticipants).toFixed(2));
            for(const submission of submissions){
              if(submission.status == 'saved'){
                this.saved++;
              }
              if(submission.status == 'submitted'){
                this.submitted++;
              }
              if(submission.status == 'rejected' || submission.status2 == 'partially rejected by manager'){
                this.rejected++;
              }
              if(submission.status == 'approved'){
                this.approved++;
              }
            }
            this.savedPercentage = Number((this.saved * 100 / this.totalParticipants).toFixed(2));
            this.submittedPercentage = Number((this.submitted * 100 / this.totalParticipants).toFixed(2));
            this.rejectedPercentage = Number((this.rejected * 100 / this.totalParticipants).toFixed(2));
            this.approvedPercentage = Number((this.approved * 100 / this.totalParticipants).toFixed(2));
          }, error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message,
              allowOutsideClick: false,
            });
            this.spinner.hide();
            this.loading = false;
          });

          this.spinner.hide();
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
