<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
              <span>Create New</span>
            </button> -->
            <nz-modal
              [(nzVisible)]="isFormVisible"
              nzTitle="{{action | titlecase}} Business Unit"
              (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleModalSubmit()"
              nzOkText="Save"
              nzOkType="primary"
              [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!buForm.valid"
            >
              <form
                nz-form
                [nzLayout]="'vertical'"
                [formGroup]="buForm"
                (ngSubmit)="submitForm()"
              >
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="name" nzNoColon="false"
                    >Business Unit Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please input name!">
                    <!-- <nz-input-group nzSize="large"> -->
                      <input
                        formControlName="name"
                        nz-input
                        nzSize="large"
                        placeholder="Name"
                        noWhitespace  (keypress)="alphaNumberOnly($event)"
                      />
                    <!-- </nz-input-group> -->
                  </nz-form-control>
                </nz-form-item>
              </form>
            </nz-modal> 
            <h3 class="py-4 text-center">Business Unit</h3>
         
              <div class="d-flex justify-content-between align-items-center">
               
                <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
                <div>
                  <button nz-button nzType="primary" (click)="showImportModal()">
                    <span>Import</span>
                  </button>
               </div>
              </div>
            <nz-table
              nzBordered
              nzShowSizeChanger
              [nzFrontPagination]="false"
              [nzLoading]="loading"
              [nzTotal]="total"
              [nzPageSize]="pageSize"
              [nzPageIndex]="pageIndex"
              (nzQueryParams)="onQueryParamsChange($event)"
              [nzData]="businessUnits"
            >
              <thead>
                <tr>
                  <!-- sorting disabled  -->
                  <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                  <th nzColumnKey="name" [nzSortFn]="true">Name</th> -->
                  
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let businessUnit of businessUnits">
                  
                  <td>{{ businessUnit.name }}</td>
                  <td>
                    <a (click)="showEditModal(businessUnit.id)"><i class="ri-pencil-fill grayIcon" ></i></a>
                    <nz-divider nzType="vertical"> | </nz-divider>
                    <a (click)="remove(businessUnit)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>

            <nz-modal [(nzVisible)]="isImportFormVisible" nzTitle="Bulk Import" (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleImportModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
              nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

              <h4 style="font-weight: 500;">Instructions</h4>
              <ul>
                <li>Add the name of the business unit which you want to be configured onto the platform</li>
                <li>Remember to use the same name in other templates wherever BU is required</li>
              </ul>

              <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
                class="mr-3">
                Sample CSV Download
              </button>
              <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
              </nz-upload>
            </nz-modal>
          </div>
        </div>
 
      </div>
    </div>
  </div>
</div>
