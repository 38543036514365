import {  Component, OnInit } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'; 

interface Person {
  key: string;
  name: string;
  myTarget: string;
  teamTarget: string;
  buffer: string; 
}
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit { 
  isVisible = false;  
  i= 0; 
  listOfData: Person[] = [
    {
      key: '1',
      name: 'Sales through channel partner',
      myTarget: '10 Cr',
      teamTarget: '11 Cr',
      buffer: '1 Cr' 
    },
    {
      key: '2',
      name: 'Direct sales for server',
      myTarget: '10 Cr',
      teamTarget: '11 Cr',
      buffer: '1 Cr' 
    },
    {
      key: '3',
      name: 'Sales for code',
      myTarget: '10 Cr',
      teamTarget: '11 Cr',
      buffer: '1 Cr' 
    }
  ];
 
  listOfNoOfOption = [
    { label: 'For 1 Apri-2020', value: 1 },
    { label: 'For 1 May-2020', value: 2 },
    { label: 'For 1 June-2020', value: 3 },
    { label: 'For 1 July-2020', value: 4 },
  ];
 
  constructor(public fb: FormBuilder) { }

  ngOnInit(): void { 
  }
 
  createFormGroup = (): FormGroup => {
    const formBuilder = new FormBuilder();
    return formBuilder.group({
      listOfSelectedActivityValue: [
        null,
        Validators.compose([Validators.required])
      ]
    });
  }

  activitySubmitForm(e) {
    // console.log(e);
  }
 
 
// modal action fuctions
showModal(): void {
  this.isVisible = true;
}

handleOk(): void {
  // console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  // console.log('Button cancel clicked!');
  this.isVisible = false;
}
}
 






