import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';
import { Tags, TagsRetrieveRequest } from 'src/app/app.models'

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) { }
    tags: Tags[] = [];
    loading = false;
    total = 10;
    pageSize = 100;
    pageIndex = 1;
    tagsForm!: FormGroup;
    isFormVisible = false;
    isFormLoading = false;
    action = null;
    btnName;
    radioValue = "Active"
    tagsData=[{id:1,name:'tag_name',description:'data',status:'Active'},
    {id:2,name:'tag_name',description:'data',status:'Active'},
    {id:3,name:'tag_name',description:'data',status:'Active'},
    {id:4,name:'tag_name',description:'data',status:'Active'}
  ]
  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.btnName = 'Save';
    this.isFormVisible = true;
  }
  tagsId:any;
  showEditModal(entity): void {
    this.action = 'edit';
    this.btnName = 'Update';
    this.isFormVisible = true;
    this.tagsId = entity.id;
    // console.log(entity)
    this.tagsForm.patchValue(entity);
  }

  resetCreateModal(): void {
    this.tagsForm = this.fb.group({
      tag_name: [null, [Validators.required]],
      description:[null, [Validators.required]],
      status:[null, [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.resetCreateModal()
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
  }

  // submitForm(): void {
  //   // for (const i in this.tagsForm.controls) {
  //   //   this.tagsForm.controls[i].markAsDirty();
  //   //   this.tagsForm.controls[i].updateValueAndValidity();
  //   // }
  // }

  handleModalSubmit(){
    this.isFormLoading = true;
    if(this.tagsForm.valid){
      let data = {
        "tag_name":this.tagsForm.value.tag_name,
        "description":this.tagsForm.value.description,
        "status":this.tagsForm.value.status
      }
      // alert(JSON.stringify(data))

      let subscriber = null;
      let message = null;
      if (this.action === "create") {
        subscriber = this.appService.createTags(data);
        message = 'Tags "' + this.tagsForm.controls['tag_name'].value + '" has been added.';
      }
      else if (this.action === 'edit') {
        subscriber = this.appService.updateTags(data, this.tagsId);
        message = 'Tags "' + this.tagsForm.controls['tag_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        //let errorMessage = error.error.message;
        this.message.create('error',error.error.message, {
          nzDuration: 5000
        });

      });
    }
  }

  public remove(entity: Tags) {
    const index: number = this.tags.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.tag_name+'?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteTags(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;
              this.message.create('error', error.error.message, {
                nzDuration: 5000
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null, []);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, tag_name: string | '', ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new TagsRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "asc";
    rr.order_by = sortField ? sortField : "tag_name";
    rr.per_page = pageSize;
    rr.page = pageIndex;

    this.appService.getAllTags(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.tags = data['items'];
      // console.log('this.tags',this.tags)
    });
  }

}

