<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <!-- new tabe design start -->
      <div class="col-sm-12 col-lg-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="col-sm-12">
              <h3 class="py-4 text-center">Projects </h3>
              <!-- <div class="col-md-8 offset-md-2">
                                <nz-input-group >
                                    <div nz-row [nzGutter]="8">
                                      <div nz-col nzSpan="10">
                                        <nz-form-item> 
                                            <nz-form-control nzErrorTip="Please select status!">
                                                <nz-input-group  [nzSize]="'large'">
                                                  <input nz-input  placeholder="Project Name"  />
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                      </div>
                                      <div nz-col nzSpan="10">
                                        <nz-form-item> 
                                            <nz-form-control nzErrorTip="Please select status!">
                                                <nz-input-group >
                                                    <nz-select
                                                    [nzSize]="'large'"
                                                        nzPlaceHolder="Please select status"
                                                        [nzOptions]="listOfNoOfOption"    
                                                        >
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item> 
                                      </div>
                                      <div nz-col nzSpan="4">
                                          <h3 class="grayIcon mb-3"><i  nz-icon nzType="search" class="vAlignTop" nzTheme="outline"></i></h3>
                                      </div>                                
                                    </div>
                                  </nz-input-group> 
                            </div> -->
              <div class="col-md-4">
                <h3 class="plusIcon"> <i nz-icon class="mb-2" (click)="showModal()" nzType="plus-circle"
                    nzTheme="fill"></i></h3>
              </div>
              <!-- Create Modal  -->
              <nz-modal [(nzVisible)]="isVisible" nzTitle="Create Project" (nzOnOk)="handleModalSubmit()"
              (nzOnCancel)="handleModalCancel()" nzOkText="Save" nzOkType="primary" [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!projectForm.valid">
              <form nz-form [nzLayout]="'vertical'" (ngSubmit)="submitForm()" [formGroup]="projectForm">
                <!-- *ngIf="isManager === 'yes' || loggedInUserDetail.employee.is_manager !== 'yes'" -->
                <div> 
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please Enter Name!">
                    <nz-form-label nzRequired nzFor="Name" nzNoColon="false">Name</nz-form-label>
                    <nz-input-group [nzSize]="'large'">
                      <input nz-input [nzSize]="'large'" formControlName="project_name" placeholder="Project Name" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item >
                  <nz-form-control nzErrorTip="Please select start date!">
                    <nz-form-label nzFor="Duration" nzNoColon="false">Duration</nz-form-label>
                    <nz-input-group>
                      <!-- <nz-range-picker [nzSize]="'large'" [nzShowTime]="true" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-range-picker> -->
                      <nz-date-picker 
                      formControlName="start_date" 
                      nzFormat="yyyy-MM-dd HH:mm:ss" 
                      [nzShowTime]="true"
                        [nzSize]="'large'" 
                        [nzDisabledDate]="disabledStartDate"
                        (nzOnOpenChange)="handleStartOpenChange($event)"></nz-date-picker>
                    </nz-input-group>
                    <!-- <nz-form-label nzFor="will be approved by manager" nzNoColon="false">(will be approved by manager)
                    </nz-form-label> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item> 
                  <nz-form-control nzErrorTip="Please select end date!">
                    <nz-form-label nzFor="Duration" nzNoColon="false">To</nz-form-label>
                    <nz-input-group>
                      <!-- <nz-range-picker [nzSize]="'large'" [nzShowTime]="true" [(ngModel)]="date"
                        (ngModelChange)="onChange($event)"></nz-range-picker> -->
                      <nz-date-picker [nzSize]="'large'" 
                      nzFormat="yyyy-MM-dd HH:mm:ss" 
                      [nzShowTime]="true" 
                      formControlName="expected_end_date" 
                      #endDatePicker
                      [nzDisabledDate]="disabledEndDate"
                      (nzOnOpenChange)="handleEndOpenChange($event)"
                      >
                      </nz-date-picker>
                    </nz-input-group>
                    <nz-form-label nzFor="will be approved by manager" nzNoColon="false">(will be approved by manager)
                    </nz-form-label>
                  </nz-form-control>
                </nz-form-item> 
                </div> 
              </form>
              <!-- {{projectForm.value | json}}  -->
            </nz-modal>
               <!-- Edit Modal  -->
               <nz-modal [(nzVisible)]="isVisibleEditModal" nzTitle="Edit Project" (nzOnOk)="handleModalSubmit()"
               (nzOnCancel)="editModalHandleCancel()" nzOkText="Save" nzOkType="primary" [nzOkLoading]="isFormLoading"
               [nzOkDisabled]="!projectForm.valid">
               <form nz-form [nzLayout]="'vertical'" (ngSubmit)="submitForm()" [formGroup]="projectForm"> 
                 <nz-form-item> 
                   <nz-form-control nzErrorTip="Please select status!">
                     <nz-form-label nzFor="status" nzNoColon="false">Status</nz-form-label>
                       <nz-input-group >
                           <nz-select 
                           formControlName="status"
                           [nzSize]="'large'"
                               nzPlaceHolder="Please select status"
                               [nzOptions]="listOfNoOfOption"    
                               >
                           </nz-select>
                       </nz-input-group>
                   </nz-form-control>
               </nz-form-item> 
               <!-- *ngIf="loggedInUserDetail.employee.is_manager === 'yes' && isManager !== 'yes' "  -->
               <nz-form-item  > 
                 <nz-form-control nzErrorTip="Please Enter Description!">
                   <nz-form-label nzFor="Description" nzNoColon="false">Description</nz-form-label>
                     <nz-input-group >
                       <textarea nz-input formControlName="approval_description" cols="10" rows="3"></textarea>
                       </nz-input-group>
                       </nz-form-control>
               </nz-form-item>
               </form>
               <!-- {{projectForm.value | json}}  -->
             </nz-modal>
               <!-- Project Details Table -->
              <nz-table nzBordered [nzData]="projectDetails" [nzFrontPagination]="true">
                <thead>
                  <tr>
                    <th>Employee</th>
                    <th>Project</th>
                    <th>Start Date</th>
                    <th>Expected Date</th>
                    <th>Actual Date</th>
                    <th>Completed</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of projectDetails">
                    <td>{{ item.employee?.display_name | titlecase}}</td>
                    <td>{{ item.project_name  | titlecase}} </td>
                    <td>{{ item.start_date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                    <td>{{ item.expected_end_date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                    <td>{{ item.actual_end_date | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                    <td>{{ item.is_complete | titlecase }} </td>
                    <td>{{ item.status | titlecase }} </td>
                    <td>
                      <a *ngIf="!item.is_self && item.employee.is_manager == 'no'" (click)="showMangerEditModal(item)"><i class="ri-pencil-fill grayIcon"></i></a>
                      <a *ngIf="item.is_self && item.employee.is_manager == 'yes' || item.is_self && item.employee.is_manager == 'no'" (click)="showEditModal(item)">
                        <i class="ri-pencil-fill grayIcon"></i></a> <a (click)="remove(item.id)">
                          <i class="ri-delete-bin-6-fill grayIcon"></i></a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
              
              <!-- <nz-form-item>
                                  <nz-form-control>
                                      <button nz-button nzType="primary"
                                         (click)="next()">Next</button>
                                  </nz-form-control>
                              </nz-form-item> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>