export class RetrieveRequest {
  constructor(public page: number = 1,
              public per_page: number = null,
              public order_by: string = 'id',
              public sort_order: string = 'desc',
              public ids: number[] = []) {
  }
}

export class Tenant {
  constructor(public id: number,
              public tenant_name: string,
              public enable_learning_assessment: string,
              public enable_assessment_centre: string,
              public enable_labelled_content: string,
              public enable_profit_maximiser: string,
              public enable_behaviour_transaction: string,
              public enable_survey: string,
              public enable_gap_analysis: string,
              public enable_9box: string,
              public enable_360: string,
              public enable_learning_reinforcement: string,
              public enable_premium_360: string,
              public enable_hrapp: string,
              public enable_lms: string,
              public tenant_admin_email: string,
              public tenant_admin_email_notifiction: string,
              public business_days: number,
              public created_on: string,
              public  aws_access_key: string,
              public aws_secret_key: string,
              public aws_bucket: string,
              public aws_region_host: string,
              public aws_region_name: string,
              public azure_account_connection_string: string,
              public azure_container_name: string,
              public db_connection_string: string,
              public smtp_hostname: string,
              public  smtp_username: string,
              public smtp_password: string,
              public subdomain: string,
              public storage_provider: string,
              public storage_url: string) {
  }

}

export class TenantRetrieveRequest extends RetrieveRequest {
  constructor(
  ) {
    super();
  }
}

export class Ticket{
  constructor(
    public id: number,
    public ticket_id: string,
    public title: string,
    public created_date: string,
    public created_user_id: number,
    public steps_to_reproduce: string,
    public module_id: number,
    public status_id: number,
    public priority_id : number,
    public severity_id: number,
    public repoened_count: number,
    public fixed_date: number,
    public fixed_within_sla: boolean = false,
    public first_time_fix : boolean = false,
    public resolution_code_id: number,
    public resolution_details: string,
    public root_cause_code_id: number,
    public root_cause_details: string,
    public ticket_status: string

  ){}
}
export class TicketRetrieveRequest extends RetrieveRequest {
  constructor(
    public tenant_ids: number[]= [],
    public priority_ids: number[]= [],
    public severity_ids: number[]= [],
    public module_ids: number[]= [],
    public tenant_view: string = '',
  ) {
    super();
  }
}




export class ReferenceDataRetrieveRequest extends RetrieveRequest {
  constructor(
    public priority_ids: number[]= [],
    public severity_ids: number[]= [],
    public module_ids: number[]= [],
    public status_type: string = '',
    public res_ids: number[]= [],
    public root_cause_ids: number[] = [],
  ) {
    super();
  }
}
export class Attachment{
  constructor(
    public id: number,
    public title: string,
    public file_path: string,
    public ticket_id: number,
  ){


  }
}


export class ReferenceData {
  constructor(
    public id: number,
    public key:string,
    public value: string,
    public status: string,
  )
  {}
}

export class TicketComments{
  constructor(
    public id: number,
    public created_date: string,
    public comment: string,
    public ticket_id: number,
    public created_user: number
  ){}
}

export class Image {
  constructor(public id: number,
              public caption: string,
              public image_type: string,
              public original_image_url: string,
              public thumb_image_url_1: string,
              public thumb_image_url_2: string,
              public parent_entity: string,
              public parent_id: number) {
  }
}

export class ListOfValueRetrieveRequest extends RetrieveRequest {
  constructor(public lov_type: string = '', public parent_id: number = null) {
    super();
  }
}

export class ListOfValue {
  constructor(public id: number,
              public parent_id: number,
              public type: string,
              public value: string,
              public display: string,
              public selected: boolean = false) {
  }
}

export class BusinessUnit {
  constructor(public id: number,
              public name: string) {
  }

}

export class Tags {
  constructor(public id: number,
              public tag_name:string,
              public description:string,
              public status:string
              ) {
  }

}
export class HeatMaps {
  constructor(public id: number,
              public question: any,
              public score: any,
              public tags: any,
              public value: any,
              public Question: any,
              public data: any,
              public div:any
              ) {
  }

}
export class BusinessUnitRetrieveRequest extends RetrieveRequest {
  constructor(public name: string = '') {
    super();
  }
}

export class TagsRetrieveRequest extends RetrieveRequest {
  constructor(public tag_name: string = '',
              public description: string = '',
              public status: string = '') {
    super();
  }
}

export class HeatMapRetrieveRequest extends RetrieveRequest {
  constructor(public activity_ids: any = [],
              public key: string = "",
              ) {
    super();
  }
}

export class Location {
  constructor(public id: number,
              public name: string,
              public business_unit_id: number) {
  }

}

export class LocationRetrieveRequest extends RetrieveRequest {
  constructor(public name: string = '',
              public business_unit_ids: number[] = []) {
    super();
  }
}

export class Function {
  constructor(public id: number,
              public name: string,
              public business_unit_id: number) {
  }

}

export class FunctionRetrieveRequest extends RetrieveRequest {
  constructor(public name: string = '',
              public business_unit_ids: number[] = []) {
    super();
  }
}

export class Department {
  constructor(public id: number,
              public name: string,
              public function_id: number) {
  }
}

export class DepartmentRetrieveRequest extends RetrieveRequest {
  constructor(
    public name: string = '',
    public function_ids: number[] = []) {
    super();
  }
}

export class Grade {
  constructor(public id: number,
              public grade_name: string,
              public business_unit_id: number) {
  }

}

export class GradeRetrieveRequest extends RetrieveRequest {
  constructor(
    public grade_name: string = '',
    public business_unit_ids: number[] = []) {
    super();
  }
}

export class StrategicPerspective {
  constructor(public id: number,
              public title: string,
              public description: string,
              public short_code: string,
              public is_active: string) {
  }
}

export class SPRetrieveRequest extends RetrieveRequest {
  constructor(public title: string = '',
              public is_active: string = '',
              public short_code: string = '') {
    super();
  }
}

export class AnnualPriority {
  constructor(public id: number,
              public title: string,
              public description: string,
              public short_code: string,
              public is_active: string,
              public start_date: string,
              public end_date: string,
              public sp_id: number) {
  }
}

export class APRetrieveRequest extends RetrieveRequest {
  constructor(public title: string = '',
              public is_active: string = '',
              public short_code: string = '',
              public sp_id: number = null) {
    super();
  }
}

export class ObjectiveMaster {
  constructor(public id: number,
              public title: string,
              public short_code: string,
              public ap_id: number) {
  }
}

export class ObjectiveMasterRetrieveRequest extends RetrieveRequest {
  constructor(
    public title: string = '',
    public short_code: string = '',
    public ap_id: number = null,) {
    super();
  }
}

export class Competency {
  is_selected: boolean;
  constructor(public id: number,
              public competency_name: string,
              public level_id: string,
              public parent_id: string,
              public parent_name: string,
              public keywords: string) {
  }

}

export class CompetencyRetrieveRequest extends RetrieveRequest {
  constructor(
    public competency_name: string = '',
    public level_id: string = '',
    public parent_id: string = '') {
    super();
  }
}
export class Behaviour {
  constructor(public id: number,
              public behaviour_name: string,
              public complete_status: string,
              public level_name: string,
              public level_id: string,
              public parent_id: string,
              public parent_name: string,
              public description: string,
              public keywords: string) {
  }

}
export class BehaviourRetrieveRequest extends RetrieveRequest {
  constructor(
    public behaviour_name: string = '',
    public level_id: string = '',
    public parent_id: string = '') {
    super();
  }
}

export class DesiredLevel {
  constructor(
    public id: number,
    public business_unit_id: Array<number>[],
    public location_id: Array<number>[],
    public department_id: Array<number>[],
    public function_id: Array<number>[],
    public role_id: Array<number>[],
    public employee_grade_id: Array<number>[],
    public competency_id: Array<number>[],
    public desired_level_value: string,
    public status: boolean) {
  }

}

export class DesiredLevelRetrieveRequest extends RetrieveRequest {
  constructor(
    public function_id: Array<number> = [],
    public role_id: Array<number> = [],
    public employee_grade_id: Array<number> = [],
    public competency_id: Array<number> = [],
    public business_unit_id: Array<number> = [],
    public location_id: Array<number> = [],
    public department_id: Array<number> = [],
  ) {
    super();
  }
}

export class Employee {
  constructor(public id: number,
              public first_name: string,
              public last_name: string,
              public middle_name: string,
              public display_name: string,
              public designation: string,
              public email_id:string,
              public is_tenant_admin:string,
              public user_id: number,
              public grade_id: number,
              public employee_search_id: number,
              // public dob: string,
              public user:any,
              public business_unit_ids: Array<number>[],
              public function_ids: Array<number>[],
              public location_ids: Array<number>[],
              public department_ids: Array<number>[],

  ) {
  }

}

export class Managers {
  constructor(
    public id: number,
    public department_id: number,
    public designation: string,
    public display_name: string,
    public dob: string,
    public first_name: string,
    public last_name: string,
    public middle_name: string,
    public function_id: number,
    public gender: string,
  ) {
  }
}

export class EmployeeRetrieveRequest extends RetrieveRequest {
  constructor(
    public display_name: string = '',
    public reporting_manager: number = null,
    public emp_ids: Array<number> = [],
    public business_unit_ids: Array<number> = [],
    public function_ids: Array<number> = [],
    public location_ids: Array<number> = [],
    public department_ids: Array<number> = [],
    public role_ids: Array<number> = [],
    public grade_ids: Array<number> = [],
    public email_id: string = '',
    public notification_sent:string='',
    public variable_filter_key:Array<number> = [],
    public employee_search_id:string='',
    public is_tenant_admin:string=''
  ) {
    super();
  }
}

export class TalentcardRetrieveRequest extends RetrieveRequest {
  constructor(
    public display_name: string = '',
    public employee_full_search: string = '',
    public reporting_manager: number = null,
    public emp_ids: Array<number> = [],
    public business_unit_ids: Array<number> = [],
    public function_ids: Array<number> = [],
    public location_ids: Array<number> = [],
    public department_ids: Array<number> = [],
    public role_ids: Array<number> = [],
    public grade_ids: Array<number> = [],
  ) {
    super();
  }
}

export class Assessment {
  constructor(
    public id: number,
    public title: string,
    public start_date: string,
    public end_date: string,
    public assessors: Array<Number>[],
    public competencies: Array<String>[],
    public employee_id: Array<Number>[],
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public launched: string
  ) {
  }

}

export class AssessmentRetrieveRequest extends RetrieveRequest {
  constructor(
    // public name: string = '',
    public assessment_type: string = '',
    public ids: Array<number> = [],
    public assessor_emp_id: number = null,
    public emp_id: number = null,
    public launched: Array<string> = [],
    public is_assessor: string = '',) {
    super();
  }
}


export class SurveyRetrieveRequest extends RetrieveRequest {
  constructor(
    // public name: string = '',
    public launched: string = 'yes',
    public ids: Array<number> = [],
    public emp_ids: Array<number> = [],
  ) {
    super();
  }
}

export class LabelledContent {
  constructor(
    public id: number,
    public lc_title: string,
    public menu_type: string,
    public lc_description: string,
    public start_date: string,
    public image_url: string,
    public video_url: string,
    public employee_ids: Array<Number>[],
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public logo_image: any,
    public media_type: string,
    public image: any
  ) {
  }

}

export class LabelledContentRetrieveRequest extends RetrieveRequest {
  constructor(
    public title: string = '',
    public menu_type: string = '',
  ) {
    super();
  }
}

export class GapAnalysys {
  constructor(
    public id: number,
    public gap_analysis_title: string,
    public assessment_ids: string,
    public level_ids: string,
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public employee_ids: Array<Number>[],
    public role_ids: Array<Number>[],
    public grade_ids: Array<Number>[],
    public status: string,
    public error_count: string,
  ) {
  }

}

export class GapAnalysysRetrieveRequest extends RetrieveRequest {
  constructor(
    public title: string = ''
  ) {
    super();
  }
}


export class PsychometricAssessment {
  constructor(
    public id: number,
    public employee_id: string,
    public emp_id: string,
    public competency_id: string,
    public assessment_activity_id: string,
    public competency_score: string,
  ) {
  }

}

export class PsychometricAssessmentRetrieveRequest extends RetrieveRequest {
  constructor(
    public employee_ids: Array<number> = [],
    public emp_ids: Array<number> = [],
    public competency_ids: Array<number> = [],
    public assessment_activity_ids: Array<number> = [],
  ) {
    super();
  }
}

export class PerfomanceData {
  constructor(
    public id: number,
    public employee_id: string,
    public emp_id: string,
    public f_year: string,
    public performance_score: string,
    public max_performance_score: string,
  ) {
  }

}

export class PerfomanceDataRetrieveRequest extends RetrieveRequest {
  constructor(
    public employee_ids: Array<number> = [],
    public f_year: string = '',
    public emp_ids: Array<number> = [],
    public performance_score: string = '',
  ) {
    super();
  }
}


export class HybridDrillDown {
  constructor(
    public id: number,
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public role_ids: Array<Number>[],
    public grade_ids: Array<Number>[],
    public group_by: string,
    public type:string,
  ) {
  }

}

export class HybridDrillDownRetrieveRequest extends RetrieveRequest {
  constructor(
    public business_unit_ids: Array<number> = [],
    public function_ids: Array<number> = [],
    public location_ids: Array<number> = [],
    public department_ids: Array<number> = [],
    public role_ids: Array<number> = [],
    public grade_ids: Array<number> = [],
    public activity_ids: Array<number> = [],
    public group_by: string = '',
    public type:string=''
  ) {
    super();
  }
}

export class NineBoxChart {
  constructor(
    public id: number,
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public role_ids: Array<Number>[],
    public grade_ids: Array<Number>[],
  ) {
  }

}

export class NineBoxChartRetrieveRequest extends RetrieveRequest {
  constructor(
    public business_unit_ids: Array<number> = [],
    public function_ids: Array<number> = [],
    public location_ids: Array<number> = [],
    public department_ids: Array<number> = [],
    public role_ids: Array<number> = [],
    public grade_ids: Array<number> = [],
    public level: string = '',
    public f_year: string = '',
    public assessment_ids: Array<number> = []
  ) {
    super();
  }
}


export class Notification {
  constructor(
    public id: number,
    public emp_id: string,
    public is_app_read: string,
    public app_read_date_time: string,
    public app_sent: string,
    public app_notification_text: string,
    public delivery_time: string,
    public from_emp_id: string,
  ) {
  }

}

export class NotificationRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: Array<number> = [],
    public from_emp_id: Array<number> = [],
    public is_mob_read: string = '',
    public is_app_read: string = '',
    public app_sent : string = 'yes'
  ) {
    super();
  }
}


export class MovingBullet {
  constructor(
    public id: number,
    public competency: Array<number>[],
  ) {
  }

}

export class MovingBulletRetrieveRequest extends RetrieveRequest {
  constructor(
    public competency: Array<number> = [],
  ) {
    super();
  }
}

export class GapAnalysysReportRetrieveRequest extends RetrieveRequest {
  constructor(
    public superset: Array<Number> = [],
    public employee_ids: Array<Number> = [],
    public level: Array<Number> = [],
  ) {
    super();
  }
}

// Survey Related

export class Surveys {
  constructor(
    public id: number,
    public title: string,
    public start_date: string,
    public end_date: string,
    public emp_attending: Array<Number>[],
    public business_unit: Array<number>[],
    public department: Array<number>[],
    public functions: Array<number>[],
    public location: Array<number>[],
    public role: Array<Number>[],
    public grade: Array<Number>[],
    public launched: string,
    public emp_ids: Array<Number>[],
  ) {
  }

}

export class SurveysRetrieveRequest extends RetrieveRequest {
  constructor(
    //   public title: string = '',
    //   public start_date: string = '',
    //   public end_date: string = ''
    public launched: string = '',
    public emp_ids: Array<Number> = [],
  ) {
    super();
  }
}

// Survey related ends here

export class MaximiserSettings {
  constructor(
    public id: number,
    public company_name: string,
    public legal_entity: string,
    public contact: string,
    public phone: string,
    public email: string,
    public website: string,
    public spName: string,
    public objectivesName: string,
    public initiativesName: string,
    public monthlyCycleFrom: number,
    public monthlyCycleTo: number
  ) {
  }

}


export class MaximiserSettingsRetrieveRequest extends RetrieveRequest {
  constructor(public name: string = '') {
    super();
  }
}

export class Role {
  constructor(public id: number,
              public role_name: string) {
  }

}

export class RoleRetrieveRequest extends RetrieveRequest {
  constructor(public role_name: string = '') {
    super();
  }
}

export class ManagerRole {
  constructor(
    public id: number,
    public role_obj_group_id: number,
    public ap_id: number,
    public manager_id: number
  ) {
  }

}

export class ManagerRoleRetrieveRequest extends RetrieveRequest {
  constructor(
    // public id: number= null,
    public role_obj_group_id: number = null,
    public ap_id: number = null,
    public manager_id: number = null
  ) {
    super();
  }
}

export class Activity {
  constructor(
    public manager_id: number
  ) {
  }

}

export class ActivityRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_ids: Array<number> = [],
    public admin_view: string = '',
  ) {
    super();
  }
}

export class AssessmentActivityAttempt {
  constructor(
    public id: number,
    public assessment_activity_id: number,
  ) {
  }

}

export class AssessmentActivityAttemptsRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_attempt_ids: Array<number> = [],
    public assessment_activity_id: number = null,
    public status: string = null,
    public is_reattempt: string = null,
  ) {
    super();
  }
}

export class AssessmentActivityAdminViewCount {
  constructor(
    public assessment_id: number
  ) {
  }

}

export class AssessmentActivityAdminViewCountsRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_id: number = null,
  ) {
    super();
  }
}

export class ActivityQuestion {
  constructor(
    public id: number
  ) {
  }

}

export class ActivityQuestionsRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_ids: Array<number> = [],
    public assessment_activity_attempt_id: number = null,
    public is_reattempt: string = ''
  ) {
    super();
  }
}

export class ActivityFeedbackQuestion {
  constructor(
    public id: number
  ) {
  }

}

export class ActivityFeedbackQuestionsRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_ids: Array<number> = [],
    public activity_attempt_feedback_invitation_id: number = null,
  ) {
    super();
  }
}

export class ActivityAttemptFeedbackInvitation {
  constructor(
    public id: number
  ) {
  }

}

export class ActivityAttemptFeedbackInvitationsRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_attempt_ids: Array<number> = [],
    public feedback_given_by_id: number = null
  ) {
    super();
  }
}

export class AssessmentActivityFeedbackAttempt {
  constructor(
    public id: number
  ) {
  }

}

export class AssessmentActivityFeedbackAttemptsRetrieveRequest extends RetrieveRequest {
  constructor(
    public activity_attempt_feedback_invitation_id: number = null,
    public assessment_activity_attempt_id: number = null
  ) {
    super();
  }
}

export class AssessmentActivityQuestionAttempt {
  constructor(
    public id: number
  ) {
  }

}

export class AssessmentActivityQuestionAttemptsRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_attempt_id: number = null
  ) {
    super();
  }
}

export class ActivityMapping {
  constructor(
    public id: number
  ) {
  }

}

export class ActivityMappingRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_ids: Array<number> = [],
    public assessor_id: number = null,
    public participant_id: number = null,
    public emp_id: number = null
  ) {
    super();
  }
}

export class NewsFeed {
  constructor(
    public id: number
  ) {
  }

}

export class NewsFeedRetrieveRequest extends RetrieveRequest {
  constructor(
    public user_id?: number,
    public emp_id?: number,
    public feed_type?: string,
  ) {
    super();
  }
}

export class FeedLike {
  constructor(
    public id: number
  ) {
  }

}

export class FeedLikesRetrieveRequest extends RetrieveRequest {
  constructor(
    public feed_entry_ids: Array<number> = [],
  ) {
    super();
  }
}

export class FeedComment {
  constructor(
    public id: number
  ) {
  }

}

export class FeedCommentsRetrieveRequest extends RetrieveRequest {
  constructor(
    public feed_entry_ids: Array<number> = [],
  ) {
    super();
  }
}

export class RoleObjective {
  constructor(
    public role_id: number
  ) {
  }

}

export class RoleObjectiveRetrieveRequest extends RetrieveRequest {
  constructor(
    public role_id: number = null,
  ) {
    super();
  }
}

export class RoleSpAp {
  constructor(
    public id: number
  ) {
  }

}

export class RoleSpApRetrieveRequest extends RetrieveRequest {
  constructor(
    public id: Array<number> = [],
  ) {
    super();
  }
}

export class EmployeeObjective {
  constructor(
    public emp_id: number,
    public get_active_group: string,
  ) {
  }

}

export class EmployeeObjectiveRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: Array<number> = [],
    // public start_date: string= '',
    public get_active_group: string = ''
  ) {
    super();
  }
}

export class Project {
  constructor(
    public emp_ids: number,
    public is_complete: string
  ) {
  }

}

export class ProjectRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_ids: Array<number> = [],
    // public reporting_manager:Array<number> = [],
    public is_complete: string = '',
    public load_subordinate: string = ''
  ) {
    super();
  }
}

export class EmployeeInitiative {
  constructor(
    public emp_id: number,
    public start_date: string,
    // public get_active_group: string
  ) {
  }

}

export class EmployeeInitiativeRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: number = null,
    public start_date: string = '',
    // public get_active_group: string= ''
  ) {
    super();
  }
}

export class EmployeeInitiativeStatus {
  constructor(
    public emp_id: number,
    public load_subordinate: string,
  ) {
  }

}

export class EmployeeInitiativeStatusRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: number = null,
    public load_subordinate: string = ''
  ) {
    super();
  }
}

export class EmployeeObjectiveGroup {
  constructor(
    public emp_id: number
  ) {
  }

}

export class EmployeeObjectiveGroupsRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: Array<number> = [],
    public start_date: string = '',
    public end_date: string = '',
  ) {
    super();
  }
}

export class ThreeSixtyDegreeFeedbackList {
  constructor(
    public id: number,
    public activity: Activity,
    public assessment_activity_attempt: AssessmentActivityAttempt,
    public assessment_activity_attempt_id: number,
    public status: string
  ) {

  }
}

export class ThreeSixtyDegreeFeedbackListRetrieveRequest extends RetrieveRequest {
  constructor(public feedback_given_by_id: number = null) {
    super();
  }
}

export class EmployeeInitiativesGroup {
  constructor(
    public emp_id: number
  ) {
  }

}

export class EmployeeInitiativesGroupsRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: number = null,
    public month: string = '',
    public year: string = '',
    public emp_initiative_group_id: number = null
  ) {
    super();
  }
}

export class ConsensusLog {
  constructor(
    public id: number
  ) {
  }

}

export class ConsensusLogsRetrieveRequest extends RetrieveRequest {
  constructor(
    public activity_mapping_id: number = null,
  ) {
    super();
  }
}

export class CalenderActivities {
  constructor(
    public emp_ids: number,
    // public month: string,
    // public year: string,
    // public emp_initiative_group_id: number,
  ) {
  }

}

export class CalenderActivitiesRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_ids: Array<number> = [],
    // public month: string= null,
    // public year: string= null,
    // public emp_initiative_group_id: number= null
  ) {
    super();
  }
}

export class Campaigns {
  constructor(
    public id: number,
    public emp_id: number,
  ) {
  }

}

export class CampaignsRetrieveRequest extends RetrieveRequest {
  constructor(
    public id: number = null,
    public emp_id: number = null,
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null,
    public report: string = null,
  ) {
    super();
  }
}

export class CDTxnCountRetrieveRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null,
    public level: number = null
  ) {
    super();
  }
}

export class CDTxnTrendRetrieveRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null,
    public level: number = null,
    public time_period: string = null
  ) {
    super();
  }
}

export class CDTxnTrendRetrieveFilterRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null,
    public level: number = null,
    public time_period: string = null,
    public filter: string = null
  ) {
    super();
  }
}

export class CDTxnTrendRetrieveLevelNameRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null,
    public level: number = null,
    public time_period: string = null,
    public level_name: string = null,
  ) {
    super();
  }
}


export class CDEmpParticipationRetrieveRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null,
    public level: number = null
  ) {
    super();
  }
}

export class CDTopsRetrieveRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public from_date: string = null,
    public to_date: string = null,
    public share_type: string = null
  ) {
    super();
  }
}

export class CampaignItemsTree {
  constructor(
    public id: number
  ) {
  }

}

export class CampaignItemsTreeRetrieveRequest extends RetrieveRequest {
  constructor(
    public id: number = null,
  ) {
    super();
  }
}

export class CampaignItemGridView {
  constructor(
    public campaign_id: number
  ) {
  }

}

export class CampaignItemGridViewRetrieveRequest extends RetrieveRequest {
  constructor(
    public campaign_id: number = null,
    public txn_counts: string = null,
  ) {
    super();
  }
}

export class EmployeeTransaction {
  constructor(
    public campaign_item_id: number,
    public campaign_id: number,
    public displayer_emp_id: number,
    public observer_emp_id: number,
    public pending: string,
  ) {
  }

}

export class EmployeeTransactionRetrieveRequest extends RetrieveRequest {
  constructor(
    public campaign_item_id: number = null,
    public campaign_id: number = null,
    public displayer_emp_id: number = null,
    public observer_emp_id: number = null,
    public pending: string = null,
  ) {
    super();
  }
}

export class VideoNote {
  constructor(public id: number,
  ) {
  }

}

export class VideoNotesRetrieveRequest extends RetrieveRequest {
  constructor(public assessment_mapping_id: number = null,
              public assessor_id: number = null) {
    super();
  }
}

export class CampaignEmployees {
  constructor(
    public emp_id: number,
    public campaign_id: number,
    public txn_type: string,
  ) {
  }

}

export class CampaignEmployeesRetrieveRequest extends RetrieveRequest {
  constructor(
    public emp_id: number = null,
    public campaign_id: number = null,
    public txn_type: string = null,
  ) {
    super();
  }
}

export class NotificationTemplate {
  constructor(
    public id: number,
    public notification_type_id: number,
    public notification_type: any,
    public is_mob_notification_enabled: string = '',
    public is_app_notification_enabled: string = '',
    public is_email_notification_enabled: string = '',
    public app_template: string = '',
    public mob_template: string = '',
    public email_template: string = '',
    public early_minute: number,
    public percentage: number,
  ) {
  }

}

export class NotificationTemplatesRetrieveRequest extends RetrieveRequest {

  constructor(
    public notification_type_id: number = null,
    public is_email_notification_enabled: string = '',
    public notification_type: string = '',
    public is_app_notification_enabled: string = '',
  ) {
    super();
  }
}

export class EmployeeCSVSampleTemplateDoc {
  constructor(
    public id: number,
  ) {
  }

}

export class EmployeeCSVSampleTemplateDocRetrieveRequest extends RetrieveRequest {
  constructor(
    public caption: string = '',
  ) {
    super();
  }
}


export class UserActivityModel {
  activity: string;
  browser: string;
  datetimestamp: Date;
  id: number;
  req_resource: string;
  src_ip: string;
  token: string;
  token_expiration: string;
  user_id: number;
  username: string;
}


export class Survey {
  constructor(
    public id: number,
    public title: string,
    public start_date: string,
    public end_date: string,
    public competencies: Array<number>[],
    public emp_attending: Array<number>[],
    public business_unit: Array<number>[],
    public department: Array<number>[],
    public functions: Array<number>[],
    public location: Array<number>[],
    public role: Array<number>[],
    public grade: Array<number>[],
    public launched: String
  ) {
  }

}

//CREATED BY :GE0 THOMAS
//CREATED DATE:  26/08/2021

export class AssessmentAttempts {
  constructor(
    public id: number,
    public assessment_id: number,
    public employee_id: number,
    public attempt_date: String,
    public question_count: number,
    public rank: number,
    public total_score: number,
    public status: String,
  ) {

  }
}

export class AssessmentAttemptsRetrieveRequest extends RetrieveRequest {
  constructor(
    public ids: Array<number> = [],
    public assessment_ids: number = null,
    public emp_id: number = null,
  ) {
    super();
  }
}

//CREATED BY :GE0 THOMAS
//CREATED DATE:  26/08/2021
export class AssessmentActivityQuestionAttemptGroup {
  constructor(
    public id: number
  ) {
  }

}

export class AssessmentActivityQuestionAttemptsGroupRetrieveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_attempt_id: Array<number> = []
  ) {
    super();
  }
}

//CREATED BY :GE0 THOMAS
//CREATED DATE:  26/08/2021

export class AssessmentActivityFeedbackAttemptGroup {
  constructor(
    public id: number
  ) {
  }

}

export class AssessmentActivityFeedbackAttemptsGroupRetrieveRequest extends RetrieveRequest {
  constructor(
    public activity_attempt_feedback_invitation_id: number = null,
    public assessment_activity_attempt_id: Array<number> = []
  ) {
    super();
  }
}

//CREATED BY :GE0 THOMAS
//CREATED DATE:  26/08/2021
export class PsychometricAssessmentGroup {
  constructor(
    public id: number,
    public employee_id: string,
    public emp_id: string,
    public competency_id: string,
    public assessment_activity_id: string,
    public competency_score: string,
  ) {
  }

}

export class PsychometricAssessmentGroupRetrieveRequest extends RetrieveRequest {
  constructor(
    public employee_ids: Array<number> = [],
    public emp_ids: Array<number> = [],
    public competency_ids: Array<number> = [],
    public assessment_activity_ids: Array<number> = [],
  ) {
    super();
  }
}


export class EmployeePii {
  constructor(public id: number,
              public admin_approved: string = '',
              public src_ip: string = '',
              public browser: string = '',
              public req_resource: string = '',
              public requested_time: string = '',
              public datetimestamp: string = ''
  ) {
  }

}

export class EmployeePiiRetrieveRequest extends RetrieveRequest {
  constructor(
    public admin_approved: string = '',
    public emp_ids: Array<number> = [],
  ) {
    super();
  }
}

export class getOverallOfReportRetriveRequest extends RetrieveRequest {
  constructor(
    public assessment_activity_attempt_ids_la: Array<number> = [],
    public assessment_activity_attempt_ids_360: Array<number> = [],
    public assessment_activity_ids: Array<number> = [],
  ) {
    super();
  }
}


export class ReinforcementAssessmentStatus {
  constructor(
    public id: number,
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public role_ids: Array<Number>[],
    public grade_ids: Array<Number>[],
    public group_by: string,
  ) {
  }

}

export class ReinforcementAssessmentStatusRetrieveRequest extends RetrieveRequest {
  constructor(
    public business_unit_ids: Array<number> = [],
    public function_ids: Array<number> = [],
    public location_ids: Array<number> = [],
    public department_ids: Array<number> = [],
    public role_ids: Array<number> = [],
    public grade_ids: Array<number> = [],
    public group_by: string = '',
  ) {
    super();
  }
}

export class GapAnalysysByFilter {
  constructor(
    public id: number,
    public gap_analysis_title: string,
    public assessment_ids: string,
    public level_ids: string,
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public employee_ids: Array<Number>[],
    public role_ids: Array<Number>[],
    public grade_ids: Array<Number>[],
    public status: string,
    public error_count: string,
  ) {
  }

}

export class GapAnalysysByFilterRetrieveRequest extends RetrieveRequest {
  constructor(
    public title: string = '',
    public emp_id: number = null,
  ) {
    super();
  }
}

export class Premium_360 {
  constructor(
    public id: number
  ) {
  }

}

export class Premium_360RetrieveRequest extends RetrieveRequest {
  constructor(
    public id: number = null,
    public status: string = null,
  ) {
    super();
  }
}


export class Premium_360_Questions {
  constructor(
    public id: number
  ) {
  }

}

export class Premium_360_QuestionsRetrieveRequest extends RetrieveRequest {
  constructor(
    public template_id: number = null,
  ) {
    super();
  }
}



// Added for Form Module by Surajit Pramanik
export class Form {
  constructor(
    public id: number,
    public name: string,
    public who_review: string,
    public frequency: number,
    public status: string,
    public schemas: Array<any>[],
  ) {
  }
}

export class FormRetrieveRequest extends RetrieveRequest {
  constructor(
    public name: string = '',
  ) {
    super();
  }
}

export class FormLaunch {
  constructor(
    public id: number,
    public title: string,
    public start_date: string,
    public end_date: string,
    public assessors: Array<number>[],
    public competencies: Array<string>[],
    public employee_ids: Array<number>[],
    public business_unit_ids: Array<number>[],
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public location_ids: Array<number>[],
    public role_ids: Array<number>[],
    public grade_ids: Array<any>[],
    public status: string
  ) {
  }
}

export class FormLaunchRetrieveRequest extends RetrieveRequest {
  constructor(
    public title: string = '',
  ) {
    super();
  }
}
// End Added for Form Module by Surajit Pramanik

//Succession Management

export class CriticalRole {
  constructor(
    public critical_role_id: number,
    public role_name: string,
    public except_role_id: string,
    public occupied_data_needed: boolean,
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public business_unit_ids: Array<number>[],
    public managerial_level_id: Array<number>[],
    public ids: Array<number>[],
  ) {
  }
}

export class CriticalRoleRetieveRequest extends RetrieveRequest {
  constructor(
    public role_name: string='',
    public critical_role_id: string='',
    public except_role_id: string='',
    public occupied_data_needed: boolean = false,
    public department_ids: Array<number> = [],
    public function_ids: Array<number> = [],
    public business_unit_ids: Array<number> = [],
    public managerial_level_id: Array<number> = [],
    public ids: Array<number> = [],
  ) {
    super();
  }
}

export class CriticalRoleEmployeeMap {
  constructor(
    public id: number,
    public critical_role_id: number,
    public rediness_level_id: number,
    public employee_id: number,
    public status: string,
    public department_ids: Array<number>[],
    public function_ids: Array<number>[],
    public business_unit_ids: Array<number>[],
    public except_list: string,
    public variable_filter_key: string,
    public managerial_level_id: Array<number>[],
  ) {
  }
}

export class CriticalRoleEmployeeMapLog {
  constructor(
    public id: number,
    public is_updated: string,
    public check_update: string,
    public critical_role_id: string,
    public no_incumbent: string,
    public variable_filter_key: string,
    public managerial_level_id: Array<number>[],

  ) {
  }
}

export class CriticalRoleEmployeeMapRetieveRequest extends RetrieveRequest {
  constructor(
      public critical_role_id: string='',
      public rediness_level_id: string='',
      public employee_id: string='',
      public status: string='',
      public except_list: string ='',
      public variable_filter_key: string ='',
      public managerial_level_id: Array<number> = [],
  ) {
    super();
  }
}

export class CriticalRoleEmployeeMapLogRetieveRequest extends RetrieveRequest {
  constructor(
      public is_updated: string='',
      public check_update: string='',
      public critical_role_id: string='',
      public no_incumbent: string='',
      public variable_filter_key: string='',
      public managerial_level_id: Array<number> = [],
  ) {
    super();
  }
}

export class SMEmployeesDashboard {
  constructor(
    public employee_id: Array<number>[],
    public readiness_level_id: Array<number>[],
    public managerial_level_id: Array<number>[],
    public not_incumbent: string,
    public emp_filter: string,
  ) {
  }
}

export class SMEmployeesDashboardRequest extends RetrieveRequest {
  constructor(
    public employee_id: Array<number> = [],
    public readiness_level_id: Array<number> = [],
    public managerial_level_id: Array<number> = [],
    public not_incumbent: string='',
    public emp_filter: string='',
  ) {
    super();
  }
}

export class SMDashboardRequest extends RetrieveRequest{
  constructor(
      public business_unit_id: Array<number> = [],
      public function_id: Array<number> = [],
      public department_id: Array<number> = [],
      public managerial_level_id: Array<number> = [],
  ) {
    super();
  }
}

export class SMSuccessMatrixRequest extends RetrieveRequest{
  constructor(
      public managerial_level_id: Array<number> = [],
  ) {
    super();
  }
}

