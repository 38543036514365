import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { differenceInCalendarDays } from 'date-fns';
import {
  Employee,
  EmployeeRetrieveRequest,
  FormRetrieveRequest,
  BusinessUnitRetrieveRequest,
  LocationRetrieveRequest,
  DepartmentRetrieveRequest,
  FunctionRetrieveRequest,
  RoleRetrieveRequest,
  GradeRetrieveRequest,
} from 'src/app/app.models';
import Swal from 'sweetalert2';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-launch-create',
  templateUrl: './launch-create.component.html',
  styleUrls: ['./launch-create.component.css']
})
export class LaunchCreateComponent implements OnInit, OnDestroy {
  private sub: any;

  minDate = new Date();
  loading = false;
  start_end_date: any[] = [];
  launch: any = {
    id: 0,
    title: 'Untitled',
    form_id: 0,
    start_date: '',
    end_date: '',
    business_unit_ids: [],
    department_ids: [],
    function_ids: [],
    location_ids: [],
    role_ids: [],
    grade_ids: [],
    employee_ids: [],
    is_bulk_upload: 'no',
    status: 'not launched',
  };

  form_options: any[] = [];
  business_unit_options: any[] = [];
  location_options: any[] = [];
  department_options: any[] = [];
  function_options: any[] = [];
  role_options: any[] = [];
  grade_options: any[] = [];

  page = 1;
  total = 1;
  limit = 100;
  employees: Employee[] = [];
  curPageEmployees: Employee[] = [];
  rr: EmployeeRetrieveRequest;

  isVisible = false;

  checked = false;
  indeterminate = false;
  employeeIds = new Set<number>();

  fileList;
  disableFilters = false;
  uploadingExcel = false;
  isBulkUploadKey = false;
  openUploadModal = false;
  downloadingExcel = false;
  
  showSelectBusiness = false
  showSelectLocations = false
  showSelectFunctions = false
  showSelectDepartments =false
  showSelectGrades = false

  buControlSize = 8
  locControlSize = 8
  funcControlSize = 8
  deptControlSize = 8
  gradeControlSize = 8


  constructor(
    private router: Router,
    public datepipe: DatePipe,
    public appService: AppService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
  }

  openUpload() {
    if(this.launch.status == 'launched') return;
    this.openUploadModal = true;
  }

  uploadCancel() {
    this.openUploadModal = false;
  }

  uploadOk() {
    this.openUploadModal = false;
  }

  downloadExcel() {
    this.downloadingExcel = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/Participant-BulkUpload-Template.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloadingExcel = false;
  }

  beforeBulkUpload = (file) => {
    this.uploadingExcel = true;
    this.spinner.show();
    file.Status = 'success';
    this.fileList = file;
    let frmData = new FormData();
    frmData.append("input_file", file);
    this.appService.participantsBulkUploadForForm(frmData).subscribe(res => {
      this.spinner.hide();
      this.uploadingExcel = false;
      this.openUploadModal = false;
      let path = res.file_path
      if(res.status == 'success'){
        this.rr.ids = res.emp_id;
        this.isBulkUploadKey = true;
        this.launch.employee_ids = res.emp_id;
        this.setAllEmployeeIds();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Participants data uploaded successfully",
        });
        this.fileList = [];
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'There are some errors in the uploaded file, click on download to see it',
          confirmButtonText: 'Download',
          allowOutsideClick: false,
        }).then(result => {
          this.spinner.show();
          if(result.isConfirmed){
            let payload = {
              file_path: path
            }
            this.appService.getParticipantsExcelForForm(payload).subscribe((statuses: any) => {
              let workbook = new Workbook();
              let worksheet = workbook.addWorksheet('Status');

              let headerRow = worksheet.addRow(['Employee Id', 'Employee Name', 'Import Comments', 'Status']);
              headerRow.eachCell((cell, number) => {
                cell.font = {
                  bold: true,
                };
              });

              for(const record of statuses){
                worksheet.addRow([record['employee id'], record['employee name'], record['import comments'], record['status']]);
              }

              workbook.xlsx.writeBuffer().then((data) => {
                let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, 'participants-list.xlsx');
              });

              this.spinner.hide();
              this.fileList = [];
            }, error => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message,
                allowOutsideClick: false,
              });
              this.fileList = [];
              this.spinner.hide();
              this.loading = false;
            });
          }
        });
      }
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
      this.uploadingExcel = false;
      this.openUploadModal = false;
    });
  }

  onUploadChange(): void {
    this.isBulkUploadKey = false;
    if(this.launch.is_bulk_upload == 'yes'){
      this.disableFilters = true;
    }else{
      this.rr.ids = [];
      this.disableFilters = false;
    }
  }

  // get filter options;
  getForms(): void {
    let rr = new FormRetrieveRequest();
    rr.per_page = null;
    this.form_options = [];
    this.appService.getForms(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.form_options.push({value: value.id, label: value.name});
      }
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }
  getBusinessUnits(): void {
    let rr = new BusinessUnitRetrieveRequest();
    rr.per_page = null;
    this.business_unit_options = [];
    this.appService.getBusinessUnits(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.business_unit_options.push({value: value.id, label: value.name});
      }
      this.showSelectBusiness = true
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }
  getLocations(): void {
    let rr = new LocationRetrieveRequest();
    rr.per_page = null;
    this.location_options = [];
    rr.business_unit_ids = this.launch.business_unit_ids;
    this.appService.getLocations(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.location_options.push({value: value.id, label: value.name});
      }

      if(this.location_options){
        this.clearLocationDropdown()
      }

      this.showSelectLocations = true
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  clearLocationDropdown() {
    let location_items = this.launch.location_ids;
    // console.log("location items", location_items);

    if (location_items.length > 0 && this.location_options.length) {
        // console.log("location options", this.location_options);

        // Filter location_items array to remove elements not present in location_options
        location_items = location_items.filter((id) => {
            return this.location_options.some((item) => item.value === id); // Use 'value' property instead of 'id'
        });

        // console.log("updated location items", location_items);

        // Update location select control with the filtered location_items array
        this.launch.location_ids = location_items;

        // change size of location control when clear location function is called
        if(this.launch.location_ids.length > 7){
          this.locControlSize = 24
          } else if(this.launch.location_ids.length < 6){
            this.locControlSize = 8
          }
    }
}


  getDepartments(): void {
    // console.log("got call")
    let rr = new DepartmentRetrieveRequest();
    rr.per_page = null;
    this.department_options = [];
    rr.function_ids = this.launch.function_ids;
    this.appService.getDepartments(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.department_options.push({value: value.id, label: value.name});
      }

      if(this.department_options && this.function_options.length){
        this.clearDepartmentDropdown()
      }

      this.showSelectDepartments = true
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  clearDepartmentDropdown(){

    let department_items = this.launch.department_ids;
    // console.log("departments",department_items)

    if(department_items.length > 0 && this.department_options.length){
      // console.log("department options",this.department_options)
      
      // Filter out department items not related with selected function and bu
      department_items = department_items.filter((id) => {
        return this.department_options.some((item) => item.value === id);
      })

      // console.log("new department items",department_items)

      // Update department select control with the filtered department items
      this.launch.department_ids = department_items
      
      // change size of department control when clear department function is called

      if(this.launch.department_ids.length > 7){
        this.deptControlSize = 24
      } else if(this.launch.department_ids.length < 6){
        this.deptControlSize = 8
      }
    }
  }

  getFunctions(): void {
    let rr = new FunctionRetrieveRequest();
    rr.per_page = null;
    this.function_options = [];
    rr.business_unit_ids = this.launch.business_unit_ids;
    this.appService.getFunctions(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.function_options.push({value: value.id, label: value.name});
      }
      this.showSelectFunctions = true

      if(this.function_options){
        this.clearFunctionDropdown()
      }

    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  clearFunctionDropdown(){
    let function_items = this.launch.function_ids
    // console.log("Function items",function_items)

    if(function_items.length > 0 && this.function_options.length){
      // console.log("function options",this.function_options)

      // Filter out functions that are not connected with selecte BU's
      function_items = function_items.filter((id) => {
        return this.function_options.some((item) => item.value === id)
      })

      // console.log("new function options",function_items)

      // update function select control with new function items
      this.launch.function_ids = function_items
      if(this.launch.function_ids.length){
        this.getDepartments();
        
        // change size of function control when clear function filter function is called

        if(this.launch.function_ids.length > 7){
          this.funcControlSize = 24
        } else if(this.launch.function_ids.length < 6){
          this.funcControlSize = 8
        }
      }
    }
  }

  getRoles(): void {
    let rr = new RoleRetrieveRequest();
    rr.per_page = null;
    this.role_options = [];
    this.appService.getRoles(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.role_options.push({value: value.id, label: value.role_name});
      }
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }
  getGrades(): void {
    let rr = new GradeRetrieveRequest();
    rr.per_page = null;
    this.grade_options = [];
    rr.business_unit_ids = this.launch.business_unit_ids;
    this.appService.getGrades(rr).subscribe((data: any) => {
      for(const value of data['items']){
        this.grade_options.push({value: value.id, label: value.grade_name});
      }

      if(this.grade_options){
        this.clearGradeDropdown()
      }

      this.showSelectGrades = true
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  clearGradeDropdown(){
    let grade_items = this.launch.grade_ids
    // console.log("Grade items",grade_items)

    if(grade_items.length > 0 && this.grade_options.length){
      // console.log("grade options",this.grade_options)

      // Filter out grades that are not connected with selected BU's
      grade_items = grade_items.filter((id) => {
        return this.grade_options.some((item) => item.value === id)
      })
      // console.log("new grade options",grade_items)

      // Update grade dropdown with new grade items
      this.launch.grade_ids = grade_items

      // change size of grade control when clear grade function is called

      if(this.launch.grade_ids.length > 7){
        this.gradeControlSize = 24
      } else if(this.launch.grade_ids.length < 6){
        this.gradeControlSize = 8
      }
    }
  }

  // end get filter options;

  ngOnInit(): void {
    this.getForms();
    this.getBusinessUnits();
    this.getRoles();

    this.spinner.show();
    this.loading = true;

    this.rr = new EmployeeRetrieveRequest('');
    this.sub = this.route.params.subscribe(params => {
      if(params['id'] > 0){
        this.appService.getFormLaunchById(params['id']).subscribe((launch: any) => {
          this.launch.id = launch.id;
          this.launch.title = launch.title;
          this.launch.form_id = launch.form_id;
          this.launch.start_date = launch.start_date;
          this.launch.end_date = launch.end_date;
          this.launch.business_unit_ids = launch.business_unit_ids;
          this.launch.department_ids = launch.department_ids;
          this.launch.function_ids = launch.function_ids;
          this.launch.location_ids = launch.location_ids;
          this.launch.role_ids = launch.role_ids;
          this.launch.grade_ids = launch.grade_ids;
          this.launch.employee_ids = launch.employee_ids;
          this.launch.is_bulk_upload = launch.is_bulk_upload;
          this.launch.status = launch.status;

          if(this.launch.is_bulk_upload == 'yes'){
            this.disableFilters = true;
            this.isBulkUploadKey = true;
            this.rr.ids = this.launch.employee_ids;
          }

          this.minDate = new Date(this.launch.start_date);
          this.start_end_date = [new Date(this.launch.start_date), new Date(this.launch.end_date)];

          if(this.launch.status == 'launched'){
            this.rr.ids = this.launch.employee_ids;
          }else{
            let keys = ['business_unit_ids', 'function_ids', 'location_ids', 'department_ids', 'role_ids', 'grade_ids'];
            for(const key of keys){
              if(!this.launch[key]){
                this.launch[key] = [];
              }
              if(this.launch[key].length){
                this.rr[key] = this.launch[key];
              }
            }
          }
          this.loadEmployees(this.rr);
          this.spinner.hide();
          this.loading = false;
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }else{
        this.spinner.hide();
        this.loading = false;
      }
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // choose start end dates
  setStartEndDate(result): void {
    if(result[0]) this.launch.start_date = this.datepipe.transform(result[0], 'yyyy-MM-dd');
    if(result[1]) this.launch.end_date = this.datepipe.transform(result[1], 'yyyy-MM-dd');
  }
  onChangeDates(result: Date): void {
    this.setStartEndDate(result);
  }
  onOkDates(result: Date | Date[] | null): void {
    this.setStartEndDate(result);
  }
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.minDate) < 0;
  // end choose start end dates

  splitIds(page){
    if(this.rr.ids.length <= this.limit){
      return this.rr.ids;
    }

    const perChunk = this.limit;
    const inputArray = this.rr.ids;
    const result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if(!resultArray[chunkIndex]){
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    return result[page-1];
  }
  onChangeEmpFilters(key: string, result: any): void {
    // console.log("resulttttttt",result)
    
    if(key == 'business_unit_ids'){
      

      // console.log("result.length",result.length)
      // console.log("this.business_unit_options.length",this.business_unit_options.length)

      if(result[result.length - 1] == "select_all"){
        result = this.business_unit_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);
        // console.log("resulttttttt2",result)

        this.launch.business_unit_ids = this.business_unit_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);
        // console.log("resulttttttt2",result)
        
        // console.log("inside result.length",result.length)
        if(result.length == this.business_unit_options.length){
          this.showSelectBusiness = false
        }
      
      } else {
        if(result.length == this.business_unit_options.length){
          this.showSelectBusiness = false
        } else if(result.length < 1){
          this.launch.location_ids = []
          this.locControlSize = 8
          this.launch.function_ids = []
          this.funcControlSize = 8
          this.launch.department_ids = [];
          this.deptControlSize= 8
          this.launch.grade_ids = [];
          this.gradeControlSize = 8
        } else {
          this.showSelectBusiness = true
        }
      }

       if(result.length == this.business_unit_options.length){
          this.showSelectBusiness = false
        } else {
          this.showSelectBusiness = true
        }

      if(result.length > 7){
        this.buControlSize = 24
      } else if(result.length < 7){
        this.buControlSize = 8
      }

      this.getLocations();
      this.getFunctions();
      this.getGrades();
    
      
    }
    if(key == 'function_ids'){
      // this.launch.department_ids = [];

      // console.log("funcccccc",this.function_options)

      if(result[result.length - 1] == "select_all"){
        result = this.function_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);

        this.launch.function_ids = this.function_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);
        if(result.length == this.function_options.length){
          this.showSelectFunctions = false
        }
      } else {
        if(result.length == this.function_options.length){
          this.showSelectFunctions = false
        } else if(result.length < 1){
          this.launch.department_ids = [];
        } else {
          this.showSelectFunctions = true
        }
      }

      if(result.length == this.function_options.length){
          this.showSelectFunctions = false
        } else {
          this.showSelectFunctions = true
        }

      if(result.length > 7){
        this.funcControlSize = 24
      } else if(result.length < 7){
        this.funcControlSize = 8
      }

      this.getDepartments();
    }

    if(key == 'location_ids'){
      // this.launch.function_ids = []
      // this.launch.department_ids = [];

      // console.log("locccccccccc",this.location_options)

      if(result[result.length - 1] == "select_all"){
        result = this.location_options
        .filter(item =>item.value !== "select_all")
        .map(item => item.value);

        this.launch.location_ids = this.location_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);
        if(result.length == this.location_options.length){
          this.showSelectLocations = false
        }
      
      } else {
        if(result.length == this.location_options.length){
          this.showSelectLocations = false
        } else {
          this.showSelectLocations = true
        }
      }

      if(result.length > 7){
        this.locControlSize = 24
      } else if(result.length < 7){
        this.locControlSize = 8
      }
    }

    if(key == 'department_ids'){
      // console.log("depppppp",this.department_options)

      if(result[result.length - 1] == "select_all"){
        result = this.department_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);

        this.launch.department_ids = this.department_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);
        if(result.length == this.department_options.length){
          this.showSelectDepartments = false
        }
      
      } else {
        if(result.length == this.department_options.length){
          this.showSelectDepartments = false
        } else {
          this.showSelectDepartments = true
        }
      }

      if(result.length > 7){
        this.deptControlSize = 24
      } else if(result.length < 7){
        this.deptControlSize = 8
      }
    }

    if(key == 'grade_ids'){
      // console.log("gradeeeeeeeee",this.grade_options)

      if(result[result.length - 1] == "select_all"){
        result = this.grade_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);

        this.launch.grade_ids = this.grade_options
        .filter(item => item.value !== "select_all")
        .map(item => item.value);
        if(result.length == this.grade_options.length){
          this.showSelectGrades = false
        }
      } else {
        if(result.length == this.grade_options.length){
          this.showSelectGrades = false
        } else {
          this.showSelectGrades = true
        }
      }

      if(result.length > 7){
        this.gradeControlSize = 24
      } else if(result.length < 7){
        this.gradeControlSize = 8
      }
    }

    this.rr.page = this.page;
    this.rr.per_page = this.limit;
    this.rr[key] = result;
  }
  loadEmployees(rr): void {
    this.spinner.show();
    rr.page = this.page || 1;
    rr.per_page = this.limit || 100;

    if(this.launch.is_bulk_upload == 'yes'){
      let paramIds = {
        ids: this.splitIds(rr.page),
      };
      this.appService.getAllEmployeesById(paramIds).subscribe((data: Employee[]) => {
        this.total = this.rr.ids.length;
        this.employees = data;
        this.setAllEmployeeIds();
        this.spinner.hide();
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message,
          allowOutsideClick: false,
        });
        this.spinner.hide();
        this.loading = false;
      });
    }else{
      this.appService.getEmployees(rr).subscribe(data => {
        this.total = data['_meta']['total_items'];
        this.employees = data['items'];
        this.setAllEmployeeIds();
        this.spinner.hide();
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message,
          allowOutsideClick: false,
        });
        this.spinner.hide();
        this.loading = false;
      });
    }
  }
  setAllEmployeeIds(): void {
    if(this.launch.employee_ids.length){
      this.employeeIds.clear();
      for(const employee_id of this.launch.employee_ids){
        this.employeeIds.add(employee_id);
      }
    }
  }

  // employees modal fns
  showModal(): void {
    this.loadEmployees(this.rr);
    this.isVisible = true;
  }
  handleOk(): void {
    this.isVisible = false;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  // end employees modal fns

  // check uncheck employees
  updateCheckedSet(id: number, checked: boolean): void {
    if(checked){
      this.employeeIds.add(id);
    }else{
      this.employeeIds.delete(id);
    }
  }
  onCurrentPageDataChange(employeess: Employee[]): void {
    this.curPageEmployees = employeess;
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    const employees = this.curPageEmployees;
    this.checked = employees.every(({ id }) => this.employeeIds.has(id));
    this.indeterminate = employees.some(({ id }) => this.employeeIds.has(id)) && !this.checked;
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(checked: boolean): void {
    this.curPageEmployees.forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }
  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    this.limit = pageSize;
    this.page = pageIndex;
    this.loadEmployees(this.rr);
  }
  // end check uncheck employees

  // save launch form
  saveLaunch(): void {
    if(!this.launch.title){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please enter the launch title, it\'s a required field',
      });
      return;
    }
    if(!this.launch.form_id){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please choose the form, it\'s a required field',
      });
      return;
    }
    if(!this.start_end_date){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please select the launch start & end date, it\'s a required field',
      });
      return;
    }
    if(!this.employeeIds.size){
      Swal.fire({
        icon: 'error',
        title: 'Required!',
        confirmButtonText: 'Close',
        text: 'Please choose employees to start launch, it\'s a required field',
      });
      return;
    }

    this.launch.employee_ids = Array.from(this.employeeIds);

    this.launch.start_date = this.datepipe.transform(this.launch.start_date, 'yyyy-MM-dd');
    this.launch.end_date = this.datepipe.transform(this.launch.end_date, 'yyyy-MM-dd');

    this.spinner.show();
    if(!this.launch.id){
      this.appService.createFormLaunch(this.launch).subscribe();
    }else{
      this.appService.updateFormLaunch(this.launch, this.launch.id).subscribe();
    }
    this.spinner.hide();
    this.router.navigate(['forms/launches']);
  }
  // end save launch form

}
