<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms/launches" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div></div>
            </div>

            <nz-modal [(nzVisible)]="isVisible" nzTitle="Employees" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" nzWidth="75%">
              <ng-container *nzModalContent>
                <nz-table #employeesTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total" [nzPageSize]="limit" [nzPageIndex]="page" (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="employees">
                  <thead>
                    <tr>
                      <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)" [nzDisabled]="launch.status === 'launched'"></th>
                      <th>Employee ID</th>
                      <th>Employee First Name</th>
                      <th>Employee Last Name</th>
                      <th>Role</th>
                      <th>Designation</th>
                      <th>Department</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let employee of employeesTable.data;">
                      <td [nzChecked]="employeeIds.has(employee.id)" (nzCheckedChange)="onItemChecked(employee.id, $event)" [nzDisabled]="launch.status === 'launched'"></td>
                      <td>{{ employee.employee_id }}</td>
                      <td>{{ employee.first_name }}</td>
                      <td>{{ employee.last_name }}</td>
                      <td>{{ employee.role.role_name }}</td>
                      <td>{{ employee.designation }}</td>
                      <td>{{ employee.department.name }}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </ng-container>
            </nz-modal>

            <nz-modal nzWidth="600px" [(nzVisible)]="openUploadModal" nzClosable="true" nzMaskClosable="false" nzTitle="Participants Bulk Upload" (nzOnCancel)="uploadCancel()" (nzOnOk)="uploadOk()" nzOkText="Ok">
              <div class="row text-center mt-3">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                  <nz-upload [nzBeforeUpload]="beforeBulkUpload">
                    <button nz-button type="button" [disabled]="uploadingExcel" style="width: 301px;">
                      <i *ngIf="!uploadingExcel" class="ri-upload-2-fill" style="padding-right: 5px;"></i>
                      <i *ngIf="uploadingExcel" nz-icon nzType="loading" class="loading-icon"></i>
                      Upload
                    </button>
                  </nz-upload>
                </div>
                <div class="col-lg-2"></div>
              </div>
            </nz-modal>

            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Launch Title<strong class="text-danger">*</strong>:</label>
                  <input type="text" nz-input placeholder="Launch Title" [(ngModel)]="launch.title" [disabled]="launch.status === 'launched'" />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Choose Form<strong class="text-danger">*</strong>:</label>
                  <nz-select
                    [(ngModel)]="launch.form_id"
                    nzPlaceHolder="Choose Form"
                    [disabled]="launch.status === 'launched'">
                    <nz-option *ngFor="let option of form_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label>Start Date - End Date:<strong class="text-danger">*</strong></label>
                  <nz-range-picker
                    nzMode="date"
                    [nzShowTime]="false"
                    nzFormat="yyyy-MM-dd"
                    [(ngModel)]="start_end_date"
                    (nzOnOk)="onOkDates($event)"
                    (ngModelChange)="onChangeDates($event)"
                    [nzDisabledDate]="disabledDate"
                    [disabled]="launch.status === 'launched'"
                    [nzAllowClear]="true">
                  </nz-range-picker>
                </div>
              </div>
            </div>
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="buControlSize">
                <div class="form-group">
                  <label>Business units:</label>
                  <nz-select nzAllowClear
                    nzMode="multiple"
                    [nzTokenSeparators]="[',']"
                    [(ngModel)]="launch.business_unit_ids"
                    nzPlaceHolder="Choose Business units"
                    [disabled]="launch.status === 'launched' || disableFilters"
                    (ngModelChange)="onChangeEmpFilters('business_unit_ids', $event)" class="buSelectControlClass">
                    <nz-option *ngIf="showSelectBusiness" nzLabel="Select All" nzValue="select_all"></nz-option>
                    <nz-option *ngFor="let option of business_unit_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="locControlSize">
                <div class="form-group">
                  <label>Locations:</label>
                  <nz-select nzAllowClear
                    nzMode="multiple"
                    [nzTokenSeparators]="[',']"
                    [(ngModel)]="launch.location_ids"
                    nzPlaceHolder="Choose Locations"
                    [disabled]="launch.status === 'launched' || !launch.business_unit_ids.length || disableFilters"
                    (ngModelChange)="onChangeEmpFilters('location_ids', $event)" class="locSelectControlClass">
                    <nz-option *ngIf="showSelectLocations" nzLabel="Select All" nzValue="select_all"></nz-option>
                    <nz-option *ngFor="let option of location_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="funcControlSize">
                <div class="form-group">
                  <label>Functions:</label>
                  <nz-select nzAllowClear
                    nzMode="multiple"
                    [nzTokenSeparators]="[',']"
                    [(ngModel)]="launch.function_ids"
                    nzPlaceHolder="Choose Functions"
                    [disabled]="launch.status === 'launched' || !launch.business_unit_ids.length || disableFilters"
                    (ngModelChange)="onChangeEmpFilters('function_ids', $event)" class="funcSelectControlClass">
                    <nz-option *ngIf="showSelectFunctions" nzLabel="Select All" nzValue="select_all"></nz-option>
                    <nz-option *ngFor="let option of function_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="deptControlSize">
                <div class="form-group">
                  <label>Departments:</label>
                  <nz-select nzAllowClear
                    nzMode="multiple"
                    [nzTokenSeparators]="[',']"
                    [(ngModel)]="launch.department_ids"
                    nzPlaceHolder="Choose Departments"
                    [disabled]="launch.status === 'launched' || !launch.function_ids.length || disableFilters"
                    (ngModelChange)="onChangeEmpFilters('department_ids', $event)" class="DeptSelectControlClass">
                    <nz-option *ngIf="showSelectDepartments" nzLabel="Select All" nzValue="select_all"></nz-option>
                    <nz-option *ngFor="let option of department_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="8">
                <div class="form-group">
                  <label>Roles:</label>
                  <nz-select nzAllowClear
                    nzMode="multiple"
                    [nzTokenSeparators]="[',']"
                    [(ngModel)]="launch.role_ids"
                    nzPlaceHolder="Choose Roles"
                    [disabled]="launch.status === 'launched' || disableFilters"
                    (ngModelChange)="onChangeEmpFilters('role_ids', $event)">
                    <nz-option *ngFor="let option of role_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="gradeControlSize">
                <div class="form-group">
                  <label>Grades:</label>
                  <nz-select nzAllowClear
                    nzMode="multiple"
                    [nzTokenSeparators]="[',']"
                    [(ngModel)]="launch.grade_ids"
                    nzPlaceHolder="Choose Grades"
                    [disabled]="launch.status === 'launched' || !launch.business_unit_ids.length || disableFilters"
                    (ngModelChange)="onChangeEmpFilters('grade_ids', $event)" class="gradeSelectControlClass">
                    <nz-option *ngIf="showSelectGrades" nzLabel="Select All" nzValue="select_all"></nz-option>
                    <nz-option *ngFor="let option of grade_options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div nz-row [nzGutter]="12">
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-input-group class="form-group">
                      <nz-form-label nzRequired>Bulk upload Participants ?</nz-form-label><br>
                      <nz-radio-group [nzSize]="'large'" [(ngModel)]="launch.is_bulk_upload" (ngModelChange)="onUploadChange()" [disabled]="launch.status === 'launched'">
                        <label class="mr-4" nz-radio nzValue='yes'>Yes</label>
                        <label nz-radio nzValue='no'>No</label>
                      </nz-radio-group>
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>
              <div *ngIf="launch.is_bulk_upload == 'yes'">
                <div nz-row>
                  <button nz-button type="button" [disabled]="downloadingExcel" [nzType]="'primary'" (click)="downloadExcel()" class="mr-4 button-top">
                    <i *ngIf="!downloadingExcel" class="ri-download-2-fill" style="padding-right: 5px;"></i>
                    <i *ngIf="downloadingExcel" nz-icon nzType="loading" class="loading-icon"></i>
                    <span class="btn-top">Click to Download sample template</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="text-center my-3">
                  <button nz-button nzType="primary" *ngIf="launch.is_bulk_upload == 'yes'" (click)="openUpload()" class="mr-3" [disabled]="launch.status === 'launched'">
                    <span>Click to Upload</span>
                  </button>
                  <button nz-button [nzType]="'secondary'" [nzLoading]="loading" (click)="showModal()" class="mr-3" [disabled]="(launch.is_bulk_upload == 'yes' && !isBulkUploadKey)">
                    <span *ngIf="launch.status === 'not launched' && launch.is_bulk_upload == 'no'">Select Employees</span>
                    <span *ngIf="launch.status === 'launched' || launch.is_bulk_upload == 'yes'">Show Employees</span>
                  </button>
                  <button nz-button [nzType]="'primary'" [nzLoading]="loading" (click)="saveLaunch()" class="mr-3" [disabled]="launch.status === 'launched'">Save Launch</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
