import { Component, OnInit } from '@angular/core';
import { BusinessUnit, BusinessUnitRetrieveRequest } from 'src/app/app.models'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzUploadFile} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-business-unit',
  templateUrl: './business-unit.component.html',
  styleUrls: ['./business-unit.component.css']
})
export class BusinessUnitComponent implements OnInit {
  total = 1;
  isImportFormVisible = false;
  fileUploading = false;
  downloading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  // These are the BUs which are displayed in the list screen.
  businessUnits: BusinessUnit[] = [];
  // This is the BU being edited.
  businessUnitId: number;
  // loading indictor for the table
  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  buForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private message: NzMessageService,
    private modal: NzModalService) { }

  showCreateModal(): void {
    this.resetCreateModal();

    this.action = 'create';
    this.isFormVisible = true;
  }

  showEditModal(entityId: number): void {
    this.businessUnitId = entityId;
    this.getBusinessUnitById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getBusinessUnitById(id) {
    this.appService.get('/api/business-units/' + id).subscribe(res => {
      this.buForm.patchValue(res);
    })
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;
    this.fileList = [];


    // Create the business unit on the server.
    if (this.buForm.status === 'VALID') {
      let data = {
        "name": this.buForm.value.name
      }

      let subscriber = null;
      let message = null;
      if (this.action === "create") {
        subscriber = this.appService.createBusinessUnit(data);
        message = 'Business unit "' + this.buForm.controls['name'].value + '" has been added.';
      }
      else if (this.action === 'edit') {
        subscriber = this.appService.updateBusinessUnit(data, this.businessUnitId);
        message = 'Business unit "' + this.buForm.controls['name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        //let errorMessage = error.error.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
    this.isImportFormVisible =false;
    this.fileList = [];

  }

  resetCreateModal(): void {
    this.buForm = this.fb.group({
      name: [null, [Validators.required,Validators.pattern("^[a-zA-Z0-9\\s&-]+$")]]
    });
  }

  submitForm(): void {
    for (const i in this.buForm.controls) {
      this.buForm.controls[i].markAsDirty();
      this.buForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.resetCreateModal();

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['id']) {
        this.showEditModal(queryParams['id']);
      }
    });

    // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

  }

  public remove(entity: BusinessUnit) {
    const index: number = this.businessUnits.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.name+'?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteBusinessUnit(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null, []);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, containsName: string | '', ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new BusinessUnitRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "asc";
    rr.order_by = sortField ? sortField : "name";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    rr.name = containsName;
    rr.ids = ids;

    this.appService.getBusinessUnits(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.businessUnits = data['items'];
    });
  }
  showImportModal(): void {
    this.isImportFormVisible = true;
  }

  handleImportModalSubmit(): void{
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    this.appService.importBusinessUnit(formData).subscribe((res: any) => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      this.message.create('success', res.message, {
        nzDuration: 5000
      });
    }, error => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }


  downloadSampleCSV(){
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/BusinessUnit-BulkUpload-Template.csv');
    //link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;
  }

  alphaNumberOnly (e: any) {  // Accept only letters and numbers
     e = e || window.event;
    var bad = /[^\sa-z\d&-]/i,
        key = String.fromCharCode(e.keyCode || e.which);
    if (e.which !== 0 && e.charCode !== 0 && bad.test(key)) {
        e.returnValue = false;
        if (e.preventDefault) {
            e.preventDefault();
        }
    }
  }
}
