<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <!-- <div class="col-sm-12">
            <p class="text-center"><strong>Assessment Center List</strong></p>
          </div> -->
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">{{title}}</h3>
            <a class="add-btn" *ngIf="loggedInUserDetail?.employee?.role?.allow_labelled_content_creation === 'yes'" [routerLink]="selectedTypeForm" title="Create New"><i class="ri-add-circle-fill"></i></a>
            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
                      [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" [nzData]="labelledContent"
                      (nzQueryParams)="onQueryParamsChange($event)">
              <thead>
              <tr>
                <th>Title</th>
                <th>Start Date</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let lc of labelledContent">
                <td>{{ lc.lc_title }}</td>
                <td>{{ lc.start_date }}</td>
                <!--<td>{{ assessment.end_date }}</td>-->
                <td class="text-center">
                  <div *ngIf="loggedInUserDetail?.employee?.role?.allow_labelled_content_creation === 'yes'">
                    <a class="mr-2" title="Edit"
                       [routerLink]="[selectedTypeForm + '/edit', lc.id]"><i
                      class="ri-edit-fill grayIcon"></i></a>
                    <a title="Delete" (click)="remove(lc)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                  </div>

                  <div *ngIf="lc?.media_type==='image'">
                    <button nz-button [nzType]="'primary'" (click)="showImage(lc)"><span>View</span>
                    </button>
                  </div>

                  <div *ngIf="lc?.media_type==='video'">
                    <button nz-button [nzType]="'primary'" (click)="showVideo(lc)"><span>View</span>
                    </button>
                  </div>
                  <div *ngIf="lc?.media_type==='pdf' || lc?.media_type==='ppt' ">
                    <button nz-button [nzType]="'primary'" (click)="download(lc)"><span>Download</span>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>

<nz-modal nzWidth="80%" [(nzVisible)]="isVisible_video_modal" [nzTitle]="current_title"
  (nzOnCancel)="handleVideoCancel()" nzClosable="true" nzMaskClosable="false">
  <div>{{current_description}}</div>
  <div style="max-width:100%;max-height:100%;">
    <div class="video">
      <video controls (click)="toggleVideo()" style="width: 100%;" #videoPlayer>
        <source type="video/mp4" #videoSrc />
        Browser not supported
      </video>
    </div>
  </div>
  <div *nzModalFooter>

    <button nz-button nzType="primary" (click)="handleVideoOk()">Close</button>
  </div>
</nz-modal>


<nz-modal nzWidth="80%" [(nzVisible)]="isVisible_image_modal" [nzTitle]="current_title" (nzOnCancel)="handleCancel()">
  <div>{{current_description}}</div>
  <div>
    <img [src]="storageAccount+current_image_path" style="max-width:100%;max-height:100%;" />
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="handleOk()">Close</button>
  </div>
</nz-modal>

