<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
              <span>Create New</span>
            </button> -->
            <h3 class="py-4 text-center">Department</h3>
            <div class="d-flex justify-content-between align-items-center">
            <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle" nzTheme="fill"></i></h3>
            <div>
              <button nz-button nzType="primary" (click)="showImportModal()">
                <span>Import</span>
              </button>
           </div>
            </div>
            <nz-modal [(nzVisible)]="isImportFormVisible" nzTitle="Bulk Import" (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleImportModalSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'" nzOkType="primary"
              [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">

              <h4 style="font-weight: 500;">Instructions</h4>
              <ul>
                <li>Add the name of the department name which you want to be configured onto the platform</li>
                <li>Remember to use the same name in other templates wherever BU is required</li>
              </ul>
            
              <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()" class="mr-3">
              Sample CSV Download
            </button>
            <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
              <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
            </nz-upload>
          </nz-modal>



            <nz-modal
              [(nzVisible)]="isFormVisible"
              nzTitle="{{action | titlecase}} Department"
              (nzOnCancel)="handleModalCancel()"
              (nzOnOk)="handleModalSubmit()"
              nzOkText="Save"
              nzOkType="primary"
              [nzOkLoading]="isFormLoading"
              [nzOkDisabled]="!departmentForm.valid"
            >
              <form
                nz-form
                [nzLayout]="'vertical'"
                [formGroup]="departmentForm"
                (ngSubmit)="submitForm()"
              >
                <!-- Capture the department name -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="name" nzNoColon="false">Department Name</nz-form-label>
                  <nz-form-control nzErrorTip="Name is mandatory.">
                    <nz-input-group  [nzSize]="'large'">
                      <input noWhitespace formControlName="name" nz-input placeholder="Name" (keypress)="alphaNumberOnly($event)"/>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <!-- Capture the function -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="function_id" nzNoColon="false">Function</nz-form-label>
                  <nz-form-control nzErrorTip="Function is mandatory.">
                    <nz-input-group  [nzSize]="'large'">
                      <nz-select
                       [nzSize]="'large'"
                        nzAllowClear
                        nzShowSearch
                        nzServerSearch
                        formControlName="function_id"
                        (nzOnSearch)="onFunctionOptionListSearch($event)">
                        <ng-container *ngFor="let o of functionOptionList">
                          <nz-option
                            *ngIf="!isFunctionOptionListLoading"
                            [nzValue]="o.id"
                            [nzLabel]="o.name"
                          ></nz-option>
                        </ng-container>
                        <nz-option
                          *ngIf="isFunctionOptionListLoading"
                          nzDisabled
                          nzCustomContent
                        >
                          <i nz-icon nzType="loading" class="loading-icon"></i>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </form>
            </nz-modal>
          
            <nz-table
              nzBordered
              nzShowSizeChanger
              [nzFrontPagination]="false"
              [nzLoading]="loading"
              [nzTotal]="total"
              [nzPageSize]="pageSize"
              [nzPageIndex]="pageIndex"
              (nzQueryParams)="onQueryParamsChange($event)"
              [nzData]="departments"
            >
              <thead>
                <tr>
                  <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                  <th nzColumnKey="name" [nzSortFn]="true">Name</th>
                  <th nzColumnKey="function_id" [nzSortFn]="true">Function</th> -->
                 
                  <th>Name</th>
                  <th>Function</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let department of departments">
                  
                  <td>{{ department.name }}</td>
                  <td>
                    <a [routerLink]="['/function']" [queryParams]="{id: department.function_id}">{{ department.function.name }}</a>
                  </td>
                  <td>
                    <a (click)="showEditModal(department.id)"><i class="ri-pencil-fill grayIcon" ></i></a>
                    <nz-divider nzType="vertical"> | </nz-divider>
                    <a (click)="remove(department)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
