<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- Middle Panel Card  -->
            <div class="col-sm-9">
                <div class="iq-card bgGray">
                    <div class="iq-card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <h3 class="py-4 text-center">Your Transaction Grid</h3>
                                <!-- Transaction Modal  -->
                                <nz-modal [(nzVisible)]="isVisibleTransactionModal" [nzTitle]="transactionModalTitle"
                                    (nzOnOk)="transactionModalHandleSubmit()"
                                    (nzOnCancel)="transactionModalHandleCancel()" nzOkText="Send" nzOkType="primary"
                                    [nzOkLoading]="isTransactionFormLoading" [nzOkDisabled]="!transactionForm.valid">
                                    <ng-template #transactionModalTitle>
                                        <!-- {{ titleForTransactionModal | titlecase}} -->
                                        Initiate Transaction
                                    </ng-template>
                                    <form nz-form [nzLayout]="'vertical'" [formGroup]="transactionForm">
                                        <!-- <nz-form-item>
                                            <nz-form-label nzRequired nzFor="Select the Campaign" nzNoColon="false">
                                                Select the Campaign
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please Select Campaign!">
                                                <nz-input-group nzSize="large">
                                                    <nz-select nzSize="large" nzPlaceHolder="Select Campaign"
                                                        (ngModelChange)="onCampaignChange()"
                                                        formControlName="campaign_item_id">
                                                        <nz-option *ngFor="let item of campaignDetails"
                                                            [nzLabel]="item.campaign_name" [nzValue]="item.id">
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item> -->
                                        <!-- <nz-form-label *ngIf="selectedTechLevelLabel !== null"
                                            nzFor="Select the Campaign" nzNoColon="false">
                                            Select {{selectedTechLevelLabel }}
                                        </nz-form-label> -->
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please select transaction type!">
                                                <nz-form-label nzRequired nzFor="Please Select transaction type" nzNoColon="false">Transaction
                                                    type</nz-form-label>
                                                <nz-input-group>
                                                    <nz-select formControlName="share_type" [nzSize]="'large'"
                                                        (ngModelChange)="onTransactionTypeChange()" nzAllowClear
                                                        nzPlaceHolder="Select transaction type"
                                                        [nzOptions]="listOfTransactionType">
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <!-- Employee HTML -->
                                        <nz-form-item *ngIf="selectedTransactionType !== 'self'">
                                            <nz-form-label nzRequired> {{selectedTransactionType === 'sharing with peer' ?
                                                'Displaying' : 'Observing'}} employee</nz-form-label>
                                            <nz-form-control nzErrorTip="Please select employee!">
                                                <nz-input-group>
                                                    <nz-select  [nzSize]="'large'" nzAllowClear
                                                         nzShowSearch 
                                                        formControlName="employee_ids"
                                                        (nzOnSearch)="onEmployeeOptionListSearch($event)"
                                                        nzPlaceHolder="Select {{selectedTransactionType === 'sharing with peer' ? 'displaying' : 'observing'}} employee">
                                                        <ng-container *ngFor="let o of employeeOptionList">
                                                            <nz-option *ngIf="!isEmployeeOptionListLoading"
                                                                [nzValue]="o.id" [nzLabel]="o.display_name">
                                                            </nz-option>
                                                        </ng-container>
                                                        <nz-option *ngIf="isEmployeeOptionListLoading" nzDisabled
                                                            nzCustomContent>
                                                            <i nz-icon nzType="loading" class="loading-icon"></i>
                                                            Loading Data...
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <!-- Employee HTML  for Reporting Manager-->
                                        <nz-form-item *ngIf="selectedTransactionType === 'self'">
                                            <nz-form-label nzRequired>Reporting manager</nz-form-label>
                                            <nz-form-control nzErrorTip="Please select employee!">
                                                <nz-input-group>
                                                    <nz-select [nzSize]="'large'" nzAllowClear
                                                        nzShowSearch nzServerSearch formControlName="manager_ids"
                                                        (nzOnSearch)="onEmployeeOptionListSearch($event)"
                                                        nzPlaceHolder="Select your manager">
                                                        <ng-container *ngFor="let o of employeeOptionList">
                                                            <nz-option *ngIf="!isEmployeeOptionListLoading"
                                                                [nzValue]="o.id" [nzLabel]="o.display_name">
                                                            </nz-option>
                                                        </ng-container>
                                                        <nz-option *ngIf="isEmployeeOptionListLoading" nzDisabled
                                                            nzCustomContent>
                                                            <i nz-icon nzType="loading" class="loading-icon"></i>
                                                            Loading Data...
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item
                                            *ngIf="selectedTransactionType === 'seeking from peer' || selectedTransactionType === 'self' ">
                                            <nz-form-control nzErrorTip="Please enter displayer description!">
                                                <nz-form-label nzFor="Description" nzNoColon="false">Displayer (your)
                                                    description</nz-form-label>
                                                <nz-input-group>
                                                    <textarea nz-input placeholder="Write a recommendation..."
                                                        formControlName="displayer_comments" cols="10"
                                                        rows="3"></textarea>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>

                                        <nz-form-item *ngIf="selectedTransactionType === 'sharing with peer'">
                                            <nz-form-control nzErrorTip="Please enter observer description!">
                                                <nz-form-label nzFor="Description" nzNoColon="false">Observer (your)
                                                    description</nz-form-label>
                                                <nz-input-group>
                                                    <textarea nz-input placeholder="Write a recommendation..."
                                                        formControlName="observer_comments" cols="10"
                                                        rows="3"></textarea>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </form>
                                    <!-- {{transactionForm.value | json}} -->
                                </nz-modal>
                                <!-- dropdown List  -->
                                <div class="row">
                                    <div class="col-md-4 offset-md-4 text-center">
                                        <form nz-form [nzLayout]="'vertical'" [formGroup]="campaignListForm">
                                            <nz-form-item>
                                                <nz-form-label nzRequired nzFor="Select the campaign" nzNoColon="false" class="text-center">
                                                    Select the campaign
                                                </nz-form-label>
                                                <nz-form-control nzErrorTip="Please Select Campaign!">
                                                    <nz-input-group nzSize="large">
                                                        <!-- <nz-select nzSize="large" formControlName="" nzAllowClear placeholder="name">
                                              <nz-option *ngFor="let item campaignOptionList" [nzLabel]="item.label" [nzValue]="item.value">
                                              </nz-option>
                                            </nz-select>  -->
                                                        <nz-select nzSize="large" nzPlaceHolder="Select campaign"
                                                            (ngModelChange)="onCampaignChange()"
                                                            formControlName="campaign">
                                                            <nz-option *ngFor="let item of campaignDetails"
                                                                [nzLabel]="item.campaign_name" [nzValue]="item.id">
                                                            </nz-option>
                                                            <nz-option nzLabel="Select campaign" nzValue="select campaign" nzHide></nz-option>
                                                        </nz-select> 
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </form>
                                    </div>
                                    <div *ngIf="isCampaignExpire" class="col-md-10 offset-md-1 text-center">
                                        <!-- <span class="errorMsg" *ngIf="isCampaignExpire" >
                                            The selected campaign is already past the end date. Cannot create new transaction.
                                          </span> -->
                                          <nz-alert nzType="error" nzMessage="Current date is out of the start & end date range of the selected campaign. Cannot create/update transaction." nzShowIcon></nz-alert>
                                     </div> 
                                </div> 
                                <!-- Display Transaction Modal  -->
                                <nz-modal [nzWidth]="916"  (nzOnCancel)="displayTransactionModalHandleCancel()" [(nzVisible)]="isVisibleDisplayTransactionModal"
                                    [nzTitle]="displayTransactionTitle" nzOkType="primary" [nzFooter]="null"
                                    [nzOkLoading]="isDisplayTransactionFormLoading">

                                    <ng-template #displayTransactionTitle>
                                        <div class="row">
                                            <!-- <div class="col-md-7">All Transactions</div>
                                            <div class="col-md-5" *ngIf="transactionCardDetails?.length > 0">All ({{transactionCardDetails?.length}}) | 
                                                Completed ({{ transactionCardDetails?.length - selectedCampaignItemDetails?.pending_count | number : '1.0-0'}}) 
                                                | Pending ({{selectedCampaignItemDetails?.pending_count}})</div> -->
                                                <div class="col-md-8">Pending Transactions</div>
                                                <div class="col-md-4 text-center"  *ngIf="transactionCardDetails?.length > 0">Pending ({{selectedCampaignItemDetails?.pending_count}})</div>
                                        </div>
                                    </ng-template>
                                    <div class="displayModalBody" > 
                                    <!-- (nzOnOk)="displayTransactionModalHandleSubmit()"
                                    (nzOnCancel)="displayTransactionModalHandleCancel()" nzOkText="Send" -->
                                    <ng-container *ngIf="transactionCardDetails?.length > 0">
                                        <!-- nzGhost -->
                                    <nz-collapse>
                                        <nz-collapse-panel
                                        *ngFor="let item of transactionCardDetails;let i = index "
                                        [nzHeader]="customHeaderTemp" 
                                        [nzExpandIconPosition]="right"
                                        [nzActive]="selectedCollapsePanelIndex === i"
                                        (click)="setCollapsePanelIndex(i)"
                                        >               
                                    <ng-template #customHeaderTemp>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <nz-avatar nzIcon="user" [nzSrc]="storageAccount+item?.displayer?.profile_pic">
                                                        </nz-avatar>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <strong>{{item?.displayer?.display_name |
                                                            titlecase }}</strong>
                                                        <div>Displayer</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <nz-avatar nzIcon="user" [nzSrc]="storageAccount+item?.observer?.profile_pic">
                                                        </nz-avatar>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <strong>{{item?.observer?.display_name |
                                                            titlecase }} </strong>
                                                        <div>Observer</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-2 shareIconSec">
                                                        <i class="ri-share-forward-fill mr-2"></i>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <div>{{item?.share_type | titlecase }}</div>
                                                        <div>({{item?.status | titlecase }})</div>
                                                    </div>
                                                    <div class="col-md-5"> 
                                                        {{item.transaction_date | date:'yyyy-MM-dd HH:mm:ss' | dateAgo }}
                                                        <!-- | date:'short':'IST' | dateAgo -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <hr *ngIf="transactionCardDetails.length !== i+1">  -->
                                    </ng-template> 
                                       <!-- <p style="margin:0;"> -->
                                        <nz-list   [nzItemLayout]="'horizontal'">
                                        <!-- <ng-template #item let-item> 
                                            [nzAuthor]="item.displayer.display_name | titlecase"
                                                [nzDatetime]="item?.transaction_date | date:'yyyy-MM-dd HH:mm:ss'"-->
                                            <nz-comment *ngIf="item?.share_type !== 'sharing with peer' && item?.displayer_comments !== null"> 
                                                <nz-avatar nz-comment-avatar nzIcon="user"
                                                    [nzSrc]="storageAccount+item?.displayer?.profile_pic"></nz-avatar>
                                                <nz-comment-content>
                                                    <!-- <p *ngIf="item?.displayer_comments">{{ item.displayer_comments | titlecase }}</p>
                                                    <p *ngIf="item?.observer_comments">{{ item.observer_comments | titlecase }}</p> -->
                                                    <span  *ngIf="item?.displayer_comments !== null"> 
                                                        <strong> {{item.displayer.display_name | titlecase}}</strong>
                                                            {{item?.transaction_date | date:'yyyy-MM-dd HH:mm'}}
                                                        <!-- {{ item?.share_type | titlecase  }} transaction initiated by -->
                                                        <!-- <strong>{{item?.displayer?.display_name | titlecase}}</strong> -->
                                                        <div>{{item?.displayer_comments}}</div> </span> 
                                                </nz-comment-content>
                                            </nz-comment>
                                            <!-- <hr *ngIf="item?.displayer_comments !== null && item?.observer_comments !== null"> -->
                                            <nz-comment *ngIf="item?.observer_comments !== null"> 
                                                <nz-avatar nz-comment-avatar nzIcon="user"
                                                    [nzSrc]="storageAccount+item?.observer?.profile_pic"></nz-avatar>
                                                <nz-comment-content> 
                                                    <span *ngIf="item?.observer_comments !== null">  
                                                         <strong>{{item.observer.display_name | titlecase}} </strong>
                                                            {{item?.transaction_date | date:'yyyy-MM-dd HH:mm'}}  
                                                        <!-- <strong>{{item?.observer?.display_name | titlecase}}</strong> 
                                                       gave recommendation  -->
                                                       <div>{{item?.observer_comments}}</div></span>
                                                </nz-comment-content>
                                            </nz-comment>
                                        <!-- </ng-template> -->
                                    </nz-list>
                                    <!-- <div class="row"> 
                                            <div class="col-md-3">avtar</div>
                                            <div class="col-md-9">fdsfsdf</div> 
                                    </div> -->
                                    <nz-comment>
                                        <ng-container *ngIf="
                                        item?.observer_emp_id === loggedInUserDetail?.employee?.id  && item?.status === 'initiated'
                                        "> 
                                     <span class="m-2 d-inline-block">  
                                         <nz-avatar  
                                        nz-comment-avatar nzIcon="user"
                                        [nzSrc]="storageAccount+item?.observer?.profile_pic" 
                                        ></nz-avatar>
                                        </span>
                                        <span ><strong > {{item?.observer?.display_name | titlecase}}</strong></span>
                                        <nz-comment-content> 
                                        <form nz-form   [formGroup]="displayTransactionForm"> 
                                            <nz-form-item>
                                                <textarea formControlName="observer_comments"
                                                    nz-input rows="4"></textarea>
                                            </nz-form-item>
                                            <div class="row">
                                                <div class="col-md-12 text-center">
                                                    <nz-form-item>
                                                        <button nz-button nzType="primary"                                                        
                                                            [nzLoading]="isDisplayTransactionFormLoading"
                                                            [disabled]="!displayTransactionForm.valid"
                                                            (click)="displayTransactionModalHandleSubmit(item)">
                                                            {{ item?.share_type !== 'sharing with peer' ? 'Give' : 'Send'}}
                                                        </button>
                                                        <button class="mx-2" *ngIf="item?.share_type !== 'sharing with peer'" 
                                                        nz-button nzType="default"  
                                                            [nzLoading]="isRejectButtonLoading" 
                                                            (click)="onBtnClickChangeStatus('deny', item)">
                                                            Deny
                                                        </button>
                                                    </nz-form-item> 
                                                </div>
                                            </div> 
                                        </form> 
                                        </nz-comment-content>
                                        </ng-container>
                                        <div *ngIf="item?.displayer_emp_id === loggedInUserDetail?.employee?.id && 
                                                    item?.status === 'pending acceptance'" class="text-center mb-3">
                                            <button nz-button nzType="primary" class="mx-2"
                                            [nzLoading]="isAcceptButtonLoading"
                                            (click)="onBtnClickChangeStatus('accept', item)">
                                            Accepted, Thank You
                                        </button>
                                        <button nz-button nzType="default"
                                        [nzLoading]="isRejectButtonLoading" 
                                        (click)="onBtnClickChangeStatus('reject', item)">
                                        Reject
                                        </button>
                                        </div>
                                    </nz-comment> 
                                    <!-- {{displayTransactionForm.value | json}} -->
                                    <!-- </p>  -->
                                    </nz-collapse-panel>
                                </nz-collapse>
                                </ng-container>  
                                <ng-container *ngIf="transactionCardDetails?.length == 0" >
                                       <h5 class="text-center p-3" >  No Transaction Found...</h5>
                                </ng-container>
                                </div>
                                </nz-modal> 
                                <!-- list of Cards  -->
                                <div class="row text-center">
                                    <ng-container *ngIf="campaignItemGridViewDetails !== null">
                                        <div class="col-md-4 text-center my-3"
                                            *ngFor="let item of campaignItemGridViewDetails; let i = index">
                                            <!-- <a  href="#" > -->
                                            <nz-card class="transactionGridCard m-auto"  [class.active]="selectedCardIndex === item.id" 
                                            (click)="setCardIndex(item.id)"
                                                [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true">
                                                <div class="row cardHeader">
                                                    <div class="col-md-13 text-left">
                                                        <!-- <img src="../../../../assets/images/icon/star-1.svg"
                                                            alt="star icon">  --> 
                                                                <nz-avatar *ngIf="item?.image?.original_image_url" [nzSize]="25"
                                                                [nzSrc]="storageAccount+item?.image?.original_image_url"></nz-avatar>
                                                             <nz-avatar *ngIf="!item?.image?.original_image_url" [nzSize]="25" nzIcon="exclamation">
                                                             </nz-avatar>   

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-1"></div>
                                                    <div class="col-md-5 text-center">
                                                        <nz-badge nzShowZero [nzCount]="item.observer_count"
                                                            [nzStyle]="{ backgroundColor: '#26247B' }">
                                                            <a class="head-example"></a>
                                                        </nz-badge>
                                                        <h6 class="my-2">Observer</h6>
                                                    </div>
                                                    <div class="col-md-5 text-center">
                                                        <nz-badge nzShowZero [nzCount]="item.displayer_count"
                                                            [nzStyle]="{ backgroundColor: '#F6E92A' }">
                                                            <a class="head-example"></a>
                                                        </nz-badge>
                                                        <h6 class="my-2">Displayer</h6>
                                                    </div>
                                                    <div class="col-md-1"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 text-center">
                                                        <!-- <nz-avatar [nzText]="0"></nz-avatar>fff -->
                                                        <nz-avatar-group nz-popover nzPopoverTitle="Employees" [nzPopoverContent]="employeesTemplate" nzPopoverTrigger="hover">
                                                            <ng-container *ngFor="let a of item.employees; let j = index">
                                                              <nz-avatar *ngIf="j < 2" nzIcon="user"
                                                              [nzSrc]="storageAccount+a?.profile_pic"></nz-avatar>
                                                            </ng-container>
                                                            <nz-avatar *ngIf="item?.employees?.length > 2" [nzText]="'+' + (item?.employees?.length - 2)"></nz-avatar>
                                                          </nz-avatar-group>
                                                          <ng-template #employeesTemplate>
                                                            <div>
                                                              <div *ngFor="let a of item?.employees">{{a?.display_name | titlecase}}</div>
                                                            </div>
                                                          </ng-template>
                                                    </div>
                                                </div>
                                                <div class="cardFooter ">
                                                    <div class="row justify-content-around align-items-center mt-3">
                                                        <!-- <span class="col"></span>-->
                                                        <span class="col"> 
                                                            <a [ngClass]="{disabled: isCampaignExpire}" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"> <i
                                                                    class="ri-add-circle-fill" ></i></a>
                                                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                                                <ul class="dropdownList" nz-menu>
                                                                    <li nz-menu-item>
                                                                        <a (click)="showTransactionModal('sharing with peer', item?.id)"> <i
                                                                                class="ri-share-forward-fill mr-2"></i>Sharing
                                                                            With Peer</a>
                                                                    </li>
                                                                    <li nz-menu-divider></li>
                                                                    <li nz-menu-item>
                                                                        <a (click)="showTransactionModal('seeking from peer', item?.id)"> 
                                                                            <i class="ri-chat-new-line mr-2"></i>Seeking From
                                                                        Peer</a>
                                                                    </li>
                                                                    <li nz-menu-divider></li>
                                                                    <li nz-menu-item>
                                                                        <a (click)="showTransactionModal('self', item?.id)">   
                                                                            <i class="ri-user-line mr-2"></i>Self </a>
                                                                    </li>
                                                                </ul>
                                                            </nz-dropdown-menu>
                                                        <!-- </span>
                                                        <span class="col"> -->
                                                            <a [ngClass]="{disabled: isCampaignExpire}" (click)="showDisplayTransactionModal(item)">
                                                                <img *ngIf="!isCampaignExpire" src="../../../../assets/images/icon/notification.svg"
                                                                    alt="Notification">
                                                                    <i *ngIf="isCampaignExpire" class="ri-notification-2-fill"></i>
                                                                </a>
                                                        <!-- </span > -->
                                                         ({{item?.pending_count ? item?.pending_count: '0'}})
                                                        </span>
                                                        <!-- <span class="col"></span> -->
                                                    </div> 
                                                </div>
                                            </nz-card>
                                            <!-- </a> -->
                                        </div>
                                    </ng-container> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- right Panel Card -->
            <!-- <div class="col-md-3">
                <div class="iq-card">
                    <div class="iq-card-body p-0">
                        <div class="row">
                            <div class="col-md-12 rightPanel">
                                <h5 class="m-4">Select the <strong>{{selectedCampaignLastLevel ? selectedCampaignLastLevel : 'Nano Behavior'}}</strong></h5>
                                 
                                <ul class="list-group list-group-flush customList">
                                    <li class="list-group-item " (click)="setCardIndex(i)" [class.active]="selectedCardIndex === i"  
                                    *ngFor="let item of campaignItemGridViewDetails; let i = index" >
                                    <div class="row mb-3">
                                    <div class="col-md-2"> 
                                        <nz-avatar *ngIf="item?.image?.thumb_image_url_1" [nzSize]="25"
                                        [nzSrc]="item?.image?.thumb_image_url_1"></nz-avatar>
                                     <nz-avatar *ngIf="!item?.image?.thumb_image_url_1" [nzSize]="25" nzIcon="exclamation">
                                     </nz-avatar> 
                                    </div>
                                    <div class="col-md-10">
                                        {{item?.campaign_item_name | titlecase}} 
                                    </div>
                                </div>
                                    </li> 
                                </ul> 
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-3">
                <div class="iq-card">
                    <div class="iq-card-body p-0">
                        <div class="row">
                            <div class="col-md-12 rightPanel">
                                <!-- <h5 class="m-4">Select the <strong>{{selectedCampaignLastLevel ? selectedCampaignLastLevel : 'Nano Behavior'}}</strong></h5> -->
                                 
                                <!-- <ul class="list-group list-group-flush customList">
                                    <li class="list-group-item " (click)="setCardIndex(i)" [class.active]="selectedCardIndex === i"  
                                    *ngFor="let item of campaignItemGridViewDetails; let i = index" >
                                    <div class="row mb-3">
                                    <div class="col-md-2"> 
                                        <nz-avatar *ngIf="item?.image?.thumb_image_url_1" [nzSize]="25"
                                        [nzSrc]="item?.image?.thumb_image_url_1"></nz-avatar>
                                     <nz-avatar *ngIf="!item?.image?.thumb_image_url_1" [nzSize]="25" nzIcon="exclamation">
                                     </nz-avatar> 
                                    </div>
                                    <div class="col-md-10">
                                        {{item?.campaign_item_name | titlecase}} 
                                    </div>
                                </div>
                                    </li> 
                                </ul>  -->
                                <nz-table #expandTable [nzData]="listOfMapData" nzTableLayout="fixed">
                                    <thead>
                                        <tr>
                                            <!-- <th>Level Title</th>
                                            <th>Level</th> 
                                            <th>Action</th>-->
                                            <!-- <th nzEllipsis></th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let data of expandTable.data">
                                            <ng-container *ngFor="let item of mapOfExpandedData[data.key]; let i = index">
                                                <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                                                    <td  [nzIndentSize]="item.level! * 20"
                                                        [nzShowExpand]="!!item.children" [(nzExpand)]="item.expand"
                                                        (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)" [class.active]="selectedCardIndex === item.id" >
                                                        <!-- {{ item.campaign_item_name }} -->
                                                        <!-- <ng-container
                                                            *ngIf="treeTblLevelList?.length === item?.levelType">
                                                            <nz-avatar *ngIf="item?.image?.thumb_image_url_1"
                                                                [nzSize]="32"
                                                                [nzSrc]="item?.image?.thumb_image_url_1">
                                                            </nz-avatar>
                                                            <nz-avatar *ngIf="!item?.image?.thumb_image_url_1"
                                                                [nzSize]="32" nzIcon="exclamation">
                                                            </nz-avatar>
                                                        </ng-container> -->
                                                        <!-- <nz-avatar *ngIf="item?.image?.thumb_image_url_1"
                                                        [nzSize]="32"
                                                        [nzSrc]="item?.image?.thumb_image_url_1"> -->


                                                        <!-- <nz-comment nzAuthor="" >
                                                            <nz-avatar  *ngIf="item?.image?.thumb_image_url_1" 
                                                            nz-comment-avatar nzIcon="user" [nzSize]="32"   
                                                            [nzSrc]="item?.image?.thumb_image_url_1"></nz-avatar>
                                                            <nz-comment-content class="p-0">
                                                              
                                                                {{ item.campaign_item_name }}
                                                             
                                                            </nz-comment-content>
                                                        </nz-comment>  -->

                                                        <!-- <nz-list nzItemLayout="horizontal" >
                                                            <nz-list-item  class="p-0">
                                                              <nz-list-item-meta
                                                                [nzAvatar]="item?.image?.thumb_image_url_1"
                                                                nzDescription=""
                                                              >
                                                                <nz-list-item-meta-title>
                                                                  <a href="https://ng.ant.design">{{ item.campaign_item_name }}</a>
                                                                </nz-list-item-meta-title>
                                                              </nz-list-item-meta>
                                                            </nz-list-item>
                                                            <nz-list-empty *ngIf="data.length === 0"></nz-list-empty>
                                                          </nz-list> -->
                                                           <span *ngIf="!item?.image?.thumb_image_url_1">{{ item.campaign_item_name }} </span>
                                                           <a *ngIf="item?.image?.thumb_image_url_1"
                                                           (click)="setCardIndex(item.id)" [class.active]="selectedCardIndex === item.id"  class="text-muted1"> 
                                                           <div class="row">
                                                            <div class="col-md-12">  
                                                               
                                                               
                                                                    <nz-avatar 
                                                                     nzIcon="user" [nzSize]="22" class="mr-1"   
                                                                    [nzSrc]="storageAccount+item?.image?.thumb_image_url_1"></nz-avatar> 
                                                                 {{ item.campaign_item_name }}   
                                                                 
                                                            </div>
                                                        </div></a>
                                                       
                                                    </td>
                                                    <!-- <td>{{ item.item_type}}</td> -->
                                               
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </nz-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

        </div>
    </div>
</div>