<div class="iq-top-navbar">
   <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
         <div class="iq-navbar-logo d-flex justify-content-between">
            <a routerLink="/">
               <ng-container *ngIf="tenant_logo; else elsePart">
                  <img src={{tenant_logo}} class="img-fluid" alt="" style="margin-left: 35px;">
                </ng-container>
                <ng-template #elsePart>
                  <img src={{default_logo}} class="img-fluid" alt="" style="margin-left: 35px;" >
                </ng-template>

               
               <!-- <span>SocialV</span> -->
            </a>
            <div class="iq-menu-bt align-self-center">
               <div class="wrapper-menu open">
                  <div class="main-circle"><i class="ri-menu-line"></i></div>
               </div>
            </div>
         </div>
         <!-- <div class="iq-search-bar">
            <form action="#" class="searchbox">
               <input type="text" class="text search-input" placeholder="Type here to search...">
               <a class="search-link" href="#"><i class="ri-search-line"></i></a>
            </form>
         </div> -->
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
            <i class="ri-menu-3-line"></i>
         </button>
         <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto navbar-list">
               <li>
                  <a routerLink="/profile" class="iq-waves-effect d-flex align-items-center">
                     <!-- src="../../../../assets/images/user/1.jpg" -->
                     <!-- <img *ngIf="loggedInUserDetail?.employee?.profile_pic?.original_image_url" [src]="loggedInUserDetail?.employee?.profile_pic?.original_image_url" class="img-fluid rounded-circle mr-3" alt="user">
                     <i *ngIf="!loggedInUserDetail?.employee?.profile_pic?.original_image_url" class="ri-user-2-fill" style="font-size: 45px;"></i> -->
                     <nz-avatar *ngIf="loggedInUserDetail?.employee?.profile_pic?.original_image_url" [nzSize]="52"
                        [nzSrc]="storageAccount+loggedInUserDetail?.employee?.profile_pic?.original_image_url"></nz-avatar>
                     <nz-avatar *ngIf="!loggedInUserDetail?.employee?.profile_pic?.original_image_url" [nzSize]="52"
                        nzIcon="user"></nz-avatar>

                     <div class="caption">
                        <h6 class="ml-2 mb-0 line-height">{{loggedInUserDetail?.employee?.display_name | titlecase}}
                        </h6>
                     </div>
                  </a>
               </li>
               <!-- <li>
                  <a href="index.html" class="iq-waves-effect d-flex align-items-center">
                  <i class="ri-home-line"></i>
                  </a>
               </li> -->
               <!-- <li class="nav-item">
                  <a class="search-toggle iq-waves-effect" href="#"><i class="ri-group-line"></i></a>
                  <div class="iq-sub-dropdown iq-sub-dropdown-large">
                     <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                           <div class="bg-primary p-3">
                              <h5 class="mb-0 text-white">Friend Request<small class="badge  badge-light float-right pt-1">4</small></h5>
                           </div>
                           <div class="iq-friend-request">
                              <div class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between" >
                                 <div class="d-flex align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="../../../../assets/images/user/01.jpg" alt="">
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0 ">Jaques Amole</h6>
                                       <p class="mb-0">40  friends</p>
                                    </div>
                                 </div>
                                 <div class="d-flex align-items-center">
                                    <a href="javascript:void();" class="mr-3 btn btn-primary rounded">Confirm</a>
                                    <a href="javascript:void();" class="mr-3 btn btn-secondary rounded">Delete Request</a>
                                 </div>
                              </div>
                           </div>
                           <div class="iq-friend-request">
                              <div class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between" >
                                 <div class="d-flex align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="../../../../assets/images/user/02.jpg" alt="">
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0 ">Lucy Tania</h6>
                                       <p class="mb-0">12  friends</p>
                                    </div>
                                 </div>
                                 <div class="d-flex align-items-center">
                                    <a href="javascript:void();" class="mr-3 btn btn-primary rounded">Confirm</a>
                                    <a href="javascript:void();" class="mr-3 btn btn-secondary rounded">Delete Request</a>
                                 </div>
                              </div>
                           </div>
                           <div class="iq-friend-request">
                              <div class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between" >
                                 <div class="d-flex align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="../../../../assets/images/user/03.jpg" alt="">
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0 ">Manny Petty</h6>
                                       <p class="mb-0">3  friends</p>
                                    </div>
                                 </div>
                                 <div class="d-flex align-items-center">
                                    <a href="javascript:void();" class="mr-3 btn btn-primary rounded">Confirm</a>
                                    <a href="javascript:void();" class="mr-3 btn btn-secondary rounded">Delete Request</a>
                                 </div>
                              </div>
                           </div>
                           <div class="iq-friend-request">
                              <div class="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between" >
                                 <div class="d-flex align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="../../../../assets/images/user/04.jpg" alt="">
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0 ">Marsha Mello</h6>
                                       <p class="mb-0">15  friends</p>
                                    </div>
                                 </div>
                                 <div class="d-flex align-items-center">
                                    <a href="javascript:void();" class="mr-3 btn btn-primary rounded">Confirm</a>
                                    <a href="javascript:void();" class="mr-3 btn btn-secondary rounded">Delete Request</a>
                                 </div>
                              </div>
                           </div>
                           <div class="text-center">
                              <a href="#" class="mr-3 btn text-primary">View More Request</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </li> -->
               <!-- <li class="nav-item">
                  <a href="#" class="search-toggle iq-waves-effect" (click)="getVideoNotificationList()">
                     <i class="ri-vidicon-line"></i>
                     <span class="bg-danger dots"></span>
                  </a>
                  <div class="iq-sub-dropdown">
                     <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                           <div class="bg-primary p-3">
                              <h5 class="mb-0 text-white">Active Video Notifications<small
                                    class="badge  badge-light float-right pt-1">{{videoNotificationList.length}}</small>
                              </h5>
                           </div>
                           <ng-container *ngFor="let vn of videoNotificationList">
                           <a *ngFor="let vn of videoNotificationList" href="javaScript:void(0)" class="iq-sub-card"
                              (click)="createVideoRoomLink(vn)">
                              <div class="media align-items-center">
                                 <div class="">
                                    <i class="ri-vidicon-fill videoIcon"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">{{vn.activity_detail.activity_title}}</h6>
                                    <p class="mb-0">{{vn.meeting_datetime | date: 'MMM dd, HH:mm'}}</p>
                                 </div>
                              </div>
                           </a>
                           </ng-container>
                           <a href="#" class="iq-sub-card" >
                              <div class="media align-items-center">
                                 <div class="">
                                    <img class="avatar-40 rounded" src="../../../../assets/images/user/01.jpg" alt="">
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Emma Watson Bni</h6>
                                    <small class="float-right font-size-12">Just Now</small>
                                    <p class="mb-0">95 MB</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                  </div>
               </li> -->
               <li class="nav-item">
                  <a href="#" class="search-toggle iq-waves-effect">
                     <div id="lottie-beil"><i class="ri-notification-2-fill"></i></div>
                     <span *ngIf="notificationDetails?.length && isAnyNotificationUnRead" class="bg-danger dots"></span>
                  </a>
                  <div class="iq-sub-dropdown" [ngClass]="{'notifiction-drop': notificationDetails?.length}">
                     <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                           <!-- <div class="bg-primary p-3">
                              <h5 class="mb-0 text-white">All Notifications<small class="badge badge-light float-right pt-1">4</small></h5>
                           </div> -->
                          <ng-container *ngIf="notificationDetails?.length">
                            <ng-container>
                              <a (click)="deleteNotification()"><i style="float: right;position: relative;
                                 left: -25px" class="ri-delete-bin-6-fill grayIcon"> Clear All</i></a>
                            </ng-container>
                           <div class="scorll">
                              <ng-container *ngFor="let notification of notificationDetails" style="padding-bottom: 5px;">
                                 <a (click)="openNotificationModal(notification)" class="iq-sub-card border-top" [ngClass]="{'readed': notification?.is_app_read == 'yes'}">
                                    <div class="media align-items-center">
                                       <div class="">
                                          <img class="avatar-40 rounded" src="../../../../assets/images/user/user.png"
                                             alt="">
                                       </div>
                                       <div class="media-body ml-3">
                                          <h6 class="mb-0 ">{{notification.app_notification_text}}</h6>
                                          <small class="float-right font-size-12">{{notification.delivery_time}}</small>
                                       </div>
                                    </div>
                                 </a>
                              </ng-container>
                           </div>

                          </ng-container>

                           <a *ngIf="!notificationDetails?.length" class="iq-sub-card">
                              <div class="media align-items-center">

                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">No Notifications </h6>
                                 </div>
                              </div>
                           </a>


                        </div>
                     </div>
                  </div>
               </li>
               <!-- <li class="nav-item dropdown">
                  <a href="#" class="search-toggle iq-waves-effect">
                     <div id="lottie-mail"></div>
                     <span class="bg-primary count-mail"></span>
                  </a>
                  <div class="iq-sub-dropdown">
                     <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                           <div class="bg-primary p-3">
                              <h5 class="mb-0 text-white">All Messages<small class="badge  badge-light float-right pt-1">5</small></h5>
                           </div>
                           <a href="#" class="iq-sub-card" >
                              <div class="media align-items-center">
                                 <div class="">
                                    <img class="avatar-40 rounded" src="../../../../assets/images/user/01.jpg" alt="">
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Bni Emma Watson</h6>
                                    <small class="float-left font-size-12">13 Jun</small>
                                 </div>
                              </div>
                           </a>
                           <a href="#" class="iq-sub-card" >
                              <div class="media align-items-center">
                                 <div class="">
                                    <img class="avatar-40 rounded" src="../../../../assets/images/user/02.jpg" alt="">
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Lorem Ipsum Watson</h6>
                                    <small class="float-left font-size-12">20 Apr</small>
                                 </div>
                              </div>
                           </a>
                           <a href="#" class="iq-sub-card" >
                              <div class="media align-items-center">
                                 <div class="">
                                    <img class="avatar-40 rounded" src="../../../../assets/images/user/03.jpg" alt="">
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Why do we use it?</h6>
                                    <small class="float-left font-size-12">30 Jun</small>
                                 </div>
                              </div>
                           </a>
                           <a href="#" class="iq-sub-card" >
                              <div class="media align-items-center">
                                 <div class="">
                                    <img class="avatar-40 rounded" src="../../../../assets/images/user/04.jpg" alt="">
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Variations Passages</h6>
                                    <small class="float-left font-size-12">12 Sep</small>
                                 </div>
                              </div>
                           </a>
                           <a href="#" class="iq-sub-card" >
                              <div class="media align-items-center">
                                 <div class="">
                                    <img class="avatar-40 rounded" src="../../../../assets/images/user/05.jpg" alt="">
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Lorem Ipsum generators</h6>
                                    <small class="float-left font-size-12">5 Dec</small>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                  </div>
               </li> -->
            </ul>
            <ul class="navbar-list">
               <!-- <li><a href="#"><img src="../../../../assets/images/icon/Home.svg" class="custIcon" alt="icon"></a></li>
               <li><a href="#"><img src="../../../../assets/images/icon/Notifications.svg" class="custIcon" alt="icon"></a></li> -->
               <li>
                  <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                     <i class="ri-arrow-down-s-fill"></i>
                  </a>
                  <div class="iq-sub-dropdown iq-user-dropdown">
                     <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                           <div class="bg-primary p-3 line-height">
                              <h5 class="mb-0 text-white line-height">Hello,
                                 {{loggedInUserDetail?.employee?.display_name| titlecase}}
                              </h5>
                              <span class="text-white font-size-12">Available</span>
                           </div>
                           <a routerLink="/profile" class="iq-sub-card iq-bg-primary-hover">
                              <div class="media align-items-center">
                                 <div class="rounded1 iq-card-icon1 iq-bg-primary1">
                                    <!-- <i class="ri-file-user-line"></i> -->
                                    <!-- <img class="img-fluid" *ngIf="loggedInUserDetail?.employee?.profile_pic?.original_image_url" [src]="loggedInUserDetail?.employee?.profile_pic?.original_image_url"    max-height="45px" max-width="45px" srcset="">
                                 <i *ngIf="!loggedInUserDetail?.employee?.profile_pic?.original_image_url" class=" ri-user-2-fill" max-height="45px" max-width="45px"></i> -->

                                    <nz-avatar *ngIf="loggedInUserDetail?.employee?.profile_pic?.original_image_url"
                                       [nzSize]="44"
                                       [nzSrc]="storageAccount+loggedInUserDetail?.employee?.profile_pic?.original_image_url">
                                    </nz-avatar>
                                    <nz-avatar *ngIf="!loggedInUserDetail?.employee?.profile_pic?.original_image_url"
                                       [nzSize]="44" nzIcon="user"></nz-avatar>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">My Profile</h6>
                                    <p class="mb-0 font-size-12">View personal profile details.</p>
                                 </div>
                              </div>
                           </a>
                           <!-- <a href="profile-edit.html" class="iq-sub-card iq-bg-warning-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-warning">
                                    <i class="ri-profile-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Edit Profile</h6>
                                    <p class="mb-0 font-size-12">Modify your personal details.</p>
                                 </div>
                              </div>
                           </a> -->
                           <!-- <a href="account-setting.html" class="iq-sub-card iq-bg-info-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-info">
                                    <i class="ri-account-box-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Account settings</h6>
                                    <p class="mb-0 font-size-12">Manage your account parameters.</p>
                                 </div>
                              </div>
                           </a> -->
                           <!-- <a [routerLink]="['/maximizer-setup/maximiser-settings']" routerLinkActive="router-link-active"  class="iq-sub-card iq-bg-info-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-info">
                                    <img src="../../../../assets/images/icon/Group407.svg" alt="icon" >
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Settings</h6>
                                 </div>
                              </div>
                           </a> -->
                           <!-- <a href="privacy-setting.html" class="iq-sub-card iq-bg-danger-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-danger">
                                    <i class="ri-lock-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0 ">Privacy Settings</h6>
                                    <p class="mb-0 font-size-12">Control your privacy parameters.</p>
                                 </div>
                              </div>
                           </a> -->
                           <div class="d-inline-block w-100 text-center p-3">
                              <a class="bg-primary iq-sign-btn" (click)="logout()" role="button">Sign out<i
                                    class="ri-login-box-line ml-2"></i></a>
                           </div>

                           <nz-modal [(nzVisible)]="isNotificationModalOpen" nzFooter="null" nzClosable="true"
                              nzMaskClosable="false" nzTitle="" (nzOnCancel)="handleNotificationCancel()"
                              (nzOnOk)="handleNotificationOk()" nzOkText="Close">
                              <div class="row">
                                 <div class="media align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="../../../../assets/images/user/user.png"
                                          alt="">
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0 ">{{selectedNotification?.app_notification_text }}</h6>
                                       <small class="float-right font-size-12">{{selectedNotification?.delivery_time }}</small>
                                       <p class="mb-0"></p>
                                    </div>
                                 </div>
                              </div>
                           </nz-modal>
                        </div>
                     </div>
                  </div>
                  
               </li>
            </ul>
         </div>
      </nav>
   </div>
</div>
