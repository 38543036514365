import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormLaunch, FormLaunchRetrieveRequest } from 'src/app/app.models';
import { differenceInCalendarDays } from 'date-fns';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';

interface DataItem {
  title: string;
  start_date: string;
  end_date: string;
  status: string;
};

@Component({
  selector: 'app-form-launch',
  templateUrl: './form-launch.component.html',
  styleUrls: ['./form-launch.component.css']
})
export class FormLaunchComponent implements OnInit {

  launches: FormLaunch[] = [];
  loggedInUserDetail: any;

  launch: any = {};
  isVisible = false;
  minDate = new Date();
  start_end_date: any[] = [];

  isVisible2 = false;

  page = 1;
  total = 1;
  limit = 100;
  loading = false;
  sortOrder = 'desc';
  sortField = 'created_on';

  sortTitleFn = (a: DataItem, b: DataItem) => a.title.localeCompare(b.title);
  sortStartDateFn = (a: DataItem, b: DataItem) => a.start_date.localeCompare(b.start_date);
  sortEndDateFn = (a: DataItem, b: DataItem) => a.end_date.localeCompare(b.end_date);
  sortStatusFn = (a: DataItem, b: DataItem) => a.status.localeCompare(b.status);

  constructor(
    private router: Router,
    public datepipe: DatePipe,
    public appService: AppService,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private spinner: NgxSpinnerService
  ) {
  }

  loadLaunches(): void {
    this.spinner.show();
    this.loading = true;
    let rr = new FormLaunchRetrieveRequest();
    rr.page = this.page || 1;
    rr.per_page = this.limit || 100;
    rr.sort_order = this.sortOrder || 'desc';
    rr.order_by = this.sortField || 'created_on';

    this.appService.getFormLaunches(rr).subscribe((data: any) => {
      this.total = data['_meta']['total_items'];
      this.launches = data['items'];
      this.spinner.hide();
      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.loadLaunches();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sortField = (currentSort && currentSort.key) || null;
    this.sortOrder = (currentSort && currentSort.value) || null;
    this.limit = pageSize;
    this.page = pageIndex;
    this.loadLaunches();
  }

  toggleStatus(launch): void {
    let statusMsg = 'Form Launched';
    let confirmButtonText = 'Yes, launch it!';
    let text = `Please confirm before launching the form ${launch.form.name || launch.title}`;
    if(launch.status === 'launched'){
      statusMsg = 'Form Not Launched';
      confirmButtonText = 'Yes, stop it!';
      text = `Please confirm before stopping the launch of the form ${launch.form.name || launch.title}`;
    }
    Swal.fire({
      text: text,
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if(result.value){
        this.spinner.show();
        let data = { status: launch.status === 'launched' ? 'not launched' : 'launched' };
        this.appService.updateLaunchStatus(data, launch.id).subscribe((res: any) => {
          this.loadLaunches();
          this.message.create('success', statusMsg, {
            nzDuration: 5000
          });
          this.spinner.hide();
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }
    });
  }

  removeFormLaunch(index, launch): void {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm before deleting the launch ${launch.title}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if(result.value){
        this.appService.deleteFormLaunch(launch.id).subscribe((res: any) => {
          this.loadLaunches();
          this.message.create('success', 'Launch Deleted', {
            nzDuration: 5000
          });
        }, error => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message,
            allowOutsideClick: false,
          });
          this.spinner.hide();
          this.loading = false;
        });
      }
    });
  }

  onEditLaunch(launch): void {
    if(launch.status === 'launched'){
      Swal.fire({
        title: `Can't Edit`,
        text: `You can't edit as this is already launched`,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if(result.value){
          this.router.navigate([`/forms/launches/edit/${launch.id}`]);
        }
      });
    }else{
      this.router.navigate([`/forms/launches/edit/${launch.id}`]);
    }
  }

  onOpenDateModal(launch): void {
    this.launch = launch;
    this.minDate = new Date(launch.start_date);
    this.start_end_date = [new Date(launch.start_date), new Date(launch.end_date)];
    this.isVisible = true;
  }

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.minDate) < 0;

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.spinner.show();
    if(this.start_end_date[0]) this.launch.start_date = this.datepipe.transform(this.start_end_date[0], 'yyyy-MM-dd');
    if(this.start_end_date[1]) this.launch.end_date = this.datepipe.transform(this.start_end_date[1], 'yyyy-MM-dd');

    this.appService.updateFormLaunch(this.launch, this.launch.id).subscribe((res: any) => {
      this.loadLaunches();
      this.isVisible = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  handleCancel2(): void {
    this.isVisible2 = false;
  }

  handleOk2(): void {
    this.isVisible2 = false;
  }

  onClickDownload(launch): void {
    this.launch = launch;
    this.isVisible2 = true;
  }

  downloadStatus(): void {
    const params = {
      launch_id: this.launch.id,
    };
    this.spinner.show();
    this.appService.getParticipantsExcelForLaunch(params).subscribe((records: any) => {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Reports');

      let headerRow = worksheet.addRow([
        'Employee ID',
        'Employee First Name',
        'Employee Last Name',
        'Employee Email ID',
        'Business Unit',
        'Function',
        'Location',
        'Department',
        'Role',
        'Grade',
        'Manager ID',
        'Manager First Name',
        'Manager Last Name',
        'Manager Email ID',
        'Form Status'
      ]);
      headerRow.eachCell((cell, number) => {
        cell.font = {
          bold: true,
        };
      });

      for(const record of records){
        let status = (record.submission.status2 || 'not started').toUpperCase();
        worksheet.addRow([
          record.employee.employee_id || '',
          record.employee.first_name || '',
          record.employee.last_name || '',
          record.employee.user.email || '',
          record.employee.business_unit.name || '',
          record.employee.department.function.name || '',
          record.employee.location.name || '',
          record.employee.department.name || '',
          record.employee.role.role_name || '',
          record.employee.grade.grade_name || '',
          record.manager.employee_id || '',
          record.manager.first_name || '',
          record.manager.last_name || '',
          record.manager.user ? record.manager.user.email : '',
          status
        ]);
      }

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'participants-status.xlsx');
      });

      this.spinner.hide();
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

}
