<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">{{ launch.title }}</h3>

            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms/mine" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div></div>
            </div>

            <nz-modal nzWidth="1200" [(nzVisible)]="showPreviousProgress" nzTitle="Previous Progress for {{ launch.title }}" (nzOnCancel)="previousProgressHandleCancel()" [nzFooter]="modalFooter">
              <nz-table #previousSubmissionsTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="['']" [nzScroll]="{ x: '300px' }">
                <thead>
                  <tr>
                    <ng-container *ngFor="let col of form.schemas">
                      <th class="text-center" style="width:{{col.configurable.width || 100}}px" *ngIf="(
                        (['immediate', 'during_review'].indexOf(col.configurable.fill_when) !== -1) ||

                        (col.configurable.fill_when == 'after_rejection' && statuses.indexOf('rejected') !== -1) ||

                        (col.configurable.fill_when == 'after_approval' && statuses.indexOf('approved') !== -1 && submission.status == 'approved')
                      )">{{ col.label }}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let record of prevRecords[prevProgressIndex]; let i = index;">
                    <ng-container *ngFor="let col of form.schemas">
                      <td *ngIf="(
                        (['immediate', 'during_review'].indexOf(col.configurable.fill_when) !== -1) ||

                        (col.configurable.fill_when == 'after_rejection' && (record['ng-status'] == 'rejected'  || submission.status == 'rejected')) ||

                        (col.configurable.fill_when == 'after_approval' && record['ng-status'] == 'approved' && submission.status == 'approved')
                      )" nz-tooltip [nzTooltipTitle]="col.type == 'date' ? record[col.id || col.label].toString().slice(0,10) : record[col.id || col.label]">
                        <div *ngIf="col.type === 'text'">
                          <input type="text" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true" />
                        </div>
                        <div *ngIf="col.type === 'url'">
                          <input type="url" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true" />
                        </div>
                        <div *ngIf="col.type === 'date'">
                          <nz-date-picker [nzPlaceHolder]="col.configurable.placeholder" [nzPlacement]="bottomLeft" [(ngModel)]="record[col.id || col.label]" [disabled]="true"></nz-date-picker>
                        </div>
                        <div *ngIf="col.type === 'number'">
                          <nz-input-number [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true" class="w-100"></nz-input-number>
                        </div>
                        <div *ngIf="col.type === 'select'">
                          <nz-select [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="true">
                            <nz-option *ngFor="let option of col.configurable.options.split(',')" [nzLabel]="option" [nzValue]="option"></nz-option>
                          </nz-select>
                        </div>
                        <div *ngIf="col.type === 'autoInc'">{{ record[col.id || col.label] }}</div>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </nz-table>
              <ng-template #modalFooter>
                <button nz-button nzType="primary" (click)="viewLeftPreviousProgress()" [disabled]="prevProgressIndex == 0">Previous</button>&nbsp;
                <button nz-button nzType="primary" (click)="viewRightPreviousProgress()" [disabled]="prevProgressIndex == prevRecords.length-1">Next</button>
              </ng-template>
            </nz-modal>

            <nz-table #submissionsTable nzBordered [nzShowPagination]="false" [nzLoading]="loading" [nzData]="['']" [nzScroll]="{ x: '300px' }">
              <thead>
                <tr>
                  <ng-container *ngFor="let col of form.schemas">
                    <th class="text-center" style="width:{{col.configurable.width || 100}}px" *ngIf="(
                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate') ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review' && (statuses.indexOf('approved') !== -1 || statuses.indexOf('rejected') !== -1)) ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && (statuses.indexOf('rejected') !== -1 || submission.status == 'rejected')) ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_approval' && statuses.indexOf('approved') !== -1 && submission.status == 'approved') ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_approval' && statuses.indexOf('approved') !== -1 && submission.status == 'approved')
                    )">
                      {{ col.label }}
                    </th>
                  </ng-container>
                  <th nzRight class="text-center text-center" style="width:100px">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let record of records; let i = index;">
                  <ng-container *ngFor="let col of form.schemas">
                    <td *ngIf="(
                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate') ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review' && (statuses.indexOf('approved') !== -1 || statuses.indexOf('rejected') !== -1)) ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && record['ng-status'] && (record['ng-status'] == 'rejected' || submission.status == 'rejected')) ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved') ||

                      (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) === -1 && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved')
                    )" nz-tooltip [nzTooltipTitle]="col.type == 'date' ? record[col.id || col.label].toString().slice(0,10) : record[col.id || col.label]">
                      <div *ngIf="col.type === 'text'">
                        <input type="text" name="{{col.label}}" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate' && record['ng-status'] == 'approved') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && record['ng-status'] == 'approved' && col.configurable.required === false)||

                          (['manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && loggedInUserDetail.user_type == 'employee' && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved')
                        )" />
                      </div>
                      <div *ngIf="col.type === 'url'">
                        <input type="url" name="{{col.label}}" nz-input [placeholder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate' && record['ng-status'] == 'approved') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && record['ng-status'] == 'approved') && col.configurable.required === false||

                          (['manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && loggedInUserDetail.user_type == 'employee' && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved')
                        )" />
                      </div>
                      <div *ngIf="col.type === 'date'">
                        <nz-date-picker name="{{col.label}}" [nzPlacement]="bottomLeft" [nzAllowClear]="false" [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate' && record['ng-status'] == 'approved') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && record['ng-status'] == 'approved') && col.configurable.required === false||

                          (['manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && loggedInUserDetail.user_type == 'employee' && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved')
                        )"></nz-date-picker>
                      </div>
                      <div *ngIf="col.type === 'number'">
                        <nz-input-number name="{{col.label}}" [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate' && record['ng-status'] == 'approved') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && record['ng-status'] == 'approved') && col.configurable.required === false||

                          (['manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && loggedInUserDetail.user_type == 'employee' && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved')
                        )" class="w-100"></nz-input-number>
                      </div>
                      <div *ngIf="col.type === 'select'">
                        <nz-select name="{{col.label}}" [nzPlaceHolder]="col.configurable.placeholder" [(ngModel)]="record[col.id || col.label]" [disabled]="(
                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'immediate' && record['ng-status'] == 'approved') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'during_review') ||

                          (['employee', 'manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && col.configurable.fill_when == 'after_rejection' && record['ng-status'] == 'approved') && col.configurable.required === false||

                          (['manager', 'tenant_admin'].indexOf(col.configurable.fill_by) !== -1 && loggedInUserDetail.user_type == 'employee' && col.configurable.fill_when == 'after_approval' && record['ng-status'] && record['ng-status'] == 'approved' && submission.status == 'approved')
                        )">
                          <nz-option *ngFor="let option of col.configurable.options.split(',')" [nzLabel]="option" [nzValue]="option"></nz-option>
                        </nz-select>
                      </div>
                      <div name="{{col.label}}" *ngIf="col.type === 'autoInc'">{{ record[col.id || col.label] }}</div>
                    </td>
                  </ng-container>
                  <td nzRight class="text-center" style="width:100px">
                    <a (click)="deleteRecord(i)" nzDanger title="Delete" [class.disabled]="submission.status && submission.status != 'saved'">
                      <button nz-button nzType="default" nzDanger [disabled]="submission.status && submission.status != 'saved'">Delete</button>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>

            <div class="d-flex justify-content-between align-items-center mt-3">
              <div>
                <a class="add-btn" style="line-height:1" (click)="addRecord()" title="Add New" [class.disabled]="submission.status && submission.status != 'saved'">
                  <i class="ri-add-circle-fill"></i>
                </a>
              </div>
              <div>
                <button class="ml-3" nz-button [nzType]="'primary'" [nzLoading]="loading" (click)="viewPreviousProgress()" *ngIf="prevRecords.length >= 2">
                  <span>Previous Progress</span>
                </button>
                <button class="ml-3" nz-button [nzType]="'seconday'" [nzLoading]="loading" (click)="onSave()" *ngIf="!(submission.status && submission.status != 'saved')">
                  <span>Save</span>
                </button>
                <button class="ml-3" nz-button [nzType]="'primary'" [nzLoading]="loading" (click)="onSubmit()" [disabled]="(
                  submission.status && (statuses.indexOf('submitted') !== -1 || submission.status2 == 'progress updated')
                )">
                  <span>Submit</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br /><br /><br /><br />
