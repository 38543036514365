<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
                <div class="row mb-3">
                    <div class="col-md-12">
                      <a class="back-btn" nz-button nzType="link" (click)="back()">
                        <i class="ri-arrow-go-back-fill"></i>
                      </a>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                            <!-- <h4 class="pb-4 text-center">List of Behaviour Structures</h4> -->
                            <p class="txt-heading text-center ml-5">List of Behaviour Structures
                          <span style="float: right;">
                            <button nz-button nzType="primary" (click)="showImportModal()">
                              <span>Import</span>
                            </button>
                            <!-- <nz-upload [nzBeforeUpload]="beforeUpload">
                              <button nz-button ><i nz-icon nzType="upload"></i>Import</button>
                            </nz-upload> -->
                          </span>
                        </p>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12">
                        <a class="add-btn" (click)="showCreateModal();" title="Create New"><i class="ri-add-circle-fill"></i></a>
                    </div>
                </div>
                <nz-table #expandTable [nzLoading]="loading" [nzData]="listOfMapData" nzTableLayout="fixed">
                  <thead>
                    <tr>
                      <th>Behaviour Name</th>
                      <th>Level</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let data of expandTable.data">
                      <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
                        <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                          <td [nzIndentSize]="item.level! * 20" [nzShowExpand]="!!item.children" [(nzExpand)]="item.expand"
                            (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)">{{ item.behaviour_name }}</td>
                          <td>{{ item.level }}</td>
                          <td>
                            <a (click)="showCreateChildren(item);"><i class="fa fa-plus"></i></a>
                            <nz-divider nzType="vertical"> |</nz-divider>
                            <a (click)="showEditChildren(item)"><i class="ri-pencil-fill grayIcon"></i></a>
                            <nz-divider nzType="vertical"> |</nz-divider>
                            <a (click)="deleteBehaviour(item)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </nz-table>


                <!--             Add Behaviour modal-->
                <nz-modal nzWidth="800px" [(nzVisible)]="isFormVisible" nzTitle="{{action | titlecase}} Behaviour"
                (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalSubmit()" nzOkText="Save" nzOkType="primary"
                [nzOkLoading]="isFormLoading" [nzOkDisabled]="!bahaviourForm.valid">
                <form nz-form [nzLayout]="'vertical'" [formGroup]="bahaviourForm" (ngSubmit)="submitForm()">

                  <!-- Input For Behaviour Name -->
                  <nz-form-item>
                    <nz-form-label nzRequired nzFor="behaviour_name" nzNoColon="false">Behaviour Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input Behaviour name!">
                      <nz-input-group nzSize="large">
                        <input noWhitespace formControlName="behaviour_name" nz-input nzSize="large"
                          placeholder="Behaviour Name" OnlyCharacter/>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>

                  <!-- Input select for selecting keywords -->
                  <nz-form-item>
                    <nz-form-label nzFor="keywords" nzNoColon="false">Keywords</nz-form-label>
                    <nz-form-control>
                      <nz-input-group nzSize="large">
                        <input noWhitespace nz-input nzSize="large" [(ngModel)]="inputTag" (blur)="handleInputConfirm()"
                          (keydown.enter)="handleInputConfirm()" placeholder="Keywords"
                          [ngModelOptions]="{standalone: true}" />
                          <br />
                        <nz-tag *ngFor="let tag of tagValue; let i = index" nzMode="closeable" [nzColor]="'purple'"
                          (nzOnClose)="handleClose(tag)">{{ sliceTagName(tag) }}</nz-tag>
                      </nz-input-group>

                    </nz-form-control>
                  </nz-form-item>
                  <!-- Input for keyword ends here -->
                  <nz-form-item>
                    <nz-form-label nzFor="description" nzNoColon="false">Description</nz-form-label>
                    <nz-form-control>
                      <nz-input-group nzSize="large">
                        <textarea rows="3" nz-input formControlName="description" ></textarea>
                      </nz-input-group>

                    </nz-form-control>
                  </nz-form-item>

                </form>
              </nz-modal>

            <nz-modal [(nzVisible)]="isImportVisible" nzTitle="Bulk Import" (nzOnCancel)="handleImportCancel()"
              (nzOnOk)="handleImportSubmit()" [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
              nzOkType="primary" [nzOkLoading]="fileUploading" [nzOkDisabled]="fileList.length == 0">
              <button nz-button [nzType]="'primary'" [nzLoading]="downloading" (click)="downloadSampleCSV()"
                class="mr-3">
                Sample CSV Download
              </button>
              <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
              </nz-upload>
            </nz-modal>

            <nz-modal [(nzVisible)]="isValidationVisible" nzTitle="Bulk Import Validation"
              (nzOnOk)="handleValidationSubmit()" [nzMaskClosable] = "isClose" (nzOnCancel)="handleValidationCancel()" nzOkText="Ok"
              nzOkType="primary">
              <div class="row">
                <div class="col-sm-12">
                  <h6 class="text-center clrChange pt-0 pb-3">Error ({{errorData?.length}})</h6>
                  <div class="iq-card"  *ngFor="let item of errorData">
                    <div class="iq-card-body">
                      <span class="clrChange">{{item}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </nz-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  