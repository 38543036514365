<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body"> 
                     <div class="row">
                         <div class="col-md-12 text-center">
                            <h5 class="my-3"><strong>*Teamwise Drill down of Mayank Vora for objective O1 (5%) for the month of October* </strong></h5>
                            <p class="mb-3">O1 - Propose solution for moving the client forward in sales pipeline</p> 
                         </div>
                      
                         <div class="w-100 btnList my-4"> 
                             <div class="d-flex justify-content-around mb-3"> 
                                <button nz-button nzType="default">Total Target - 3 Cr</button>
                                <button nz-button nzType="default">Personal Target - 1 Cr</button>
                                <button nz-button nzType="default">Team Target - 2 Cr</button>
                             </div>
                            <div class="d-flex justify-content-around mb-3"> 
                                <button nz-button nzType="default">Total Target Acheived- 2 Cr</button> 
                                <button nz-button nzType="default">Personal Target Acheived- 1 Cr</button> 
                                <button nz-button nzType="default">Team Target Acheived- 1 Cr</button> 
                             </div>
                         </div>
                      <div class="col-md-12">
                        <nz-card [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                           <a [routerLink]="['/maximizer-dashboard/initiatives-drill-down']">
                             <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                         </a>
                            <div class="row">
                                <div class="col-md-12 m-3 text-center">
                                    <strong class="text-center ">Year to Date Objective Performance (1st Apr - 14th Oct)</strong>
                                </div>
                                <div class="col-md-4 d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-md-6 d-flex align-items-center">
                                           <span>Support in acquiring customers</span>
                                        </div>
                                        <div class="col-md-6 text-center">
                                            <div class="my-2">Enterprise Outcome</div>
                                              <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                        <nz-progress
                                        [nzWidth]="84"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="60 "
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                      <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                        <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                                      </a>
                                      <span class="cardNumber">01</span>
                                      <nz-dropdown-menu #menu="nzDropdownMenu">
                                        <ul nz-menu>
                                          <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                          <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                          <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                          <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                        </ul>
                                      </nz-dropdown-menu>
                                      </nz-card>
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-md-4 d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-md-6 d-flex align-items-center">
                                            <span>Target - 3Cr Achieved - 75L</span>
                                        </div>
                                        <div class="col-md-6 d-flex align-items-center">
                                            <nz-progress
                                            [nzWidth]="84"
                                            nz-tooltip
                                            nzTitle="3 done / 3 in progress / 4 to do"
                                            nzType="circle"
                                            [nzPercent]="25"
                                            [nzSuccessPercent]="30"
                                          ></nz-progress>
                                        </div>
                                    </div>                                   
                                </div>
                                <div class="col-md-4 d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-md-6 d-flex align-items-center">
                                            <span>Estimated - 68 Hrs Spent - 14 Hrs</span>
                                        </div>
                                        <div class="col-md-6 d-flex align-items-center">
                                            <nz-progress
                                    [nzWidth]="84"
                                    nz-tooltip
                                    nzTitle="3 done / 3 in progress / 4 to do"
                                    nzType="circle"
                                    [nzPercent]="20"
                                    [nzSuccessPercent]="30"
                                  ></nz-progress>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </nz-card>
                      </div>
                        <div class="col-md-12 text-center my-3">
                            <img class="downArrow" src="../../../../../assets/images/icon/downArrow.png" alt="Down Arrow" >
                        </div>

                        <div class="col-md-12 mb-3">
                            <nz-card [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <a [routerLink]="['/maximizer-dashboard/initiatives-drill-down']">
                                    <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                                </a>
                                <div class="row"> 
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                               <span class="monthSection">Mayank Vora</span>
                                            </div>
                                            <div class="col-md-6 text-center"> 
                                                  <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                            <nz-progress
                                            [nzWidth]="84"
                                            nz-tooltip
                                            nzTitle="3 done / 3 in progress / 4 to do"
                                            nzType="circle"
                                            [nzPercent]="60 "
                                            [nzSuccessPercent]="30"
                                          ></nz-progress>
                                          <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                                          </a>
                                          <span class="cardNumber">01</span>
                                          <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <ul nz-menu>
                                              <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                              <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                              <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                              <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                            </ul>
                                          </nz-dropdown-menu>
                                          </nz-card>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3Cr Achieved - 75L</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="84"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="25"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 14 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="84"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="20"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                        <div class="col-md-12 mb-3">
                            <nz-card [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <a [routerLink]="['/maximizer-dashboard/initiatives-drill-down']"> <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                            </a>
                                <div class="row"> 
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                               <span class="monthSection">Nirav Gosalia</span>
                                            </div>
                                            <div class="col-md-6 text-center"> 
                                                  <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                            <nz-progress
                                            [nzWidth]="84"
                                            nz-tooltip
                                            nzTitle="3 done / 3 in progress / 4 to do"
                                            nzType="circle"
                                            [nzPercent]="60 "
                                            [nzSuccessPercent]="30"
                                          ></nz-progress>
                                          <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                                          </a>
                                          <span class="cardNumber">01</span>
                                          <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <ul nz-menu>
                                              <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                              <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                              <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                              <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                            </ul>
                                          </nz-dropdown-menu>
                                          </nz-card>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3Cr Achieved - 75L</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="84"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="25"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 14 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="84"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="20"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                        <div class="col-md-12 mb-3">
                            <nz-card [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}">
                                <a [routerLink]="['/maximizer-dashboard/initiatives-drill-down']"> <img class="dropDownArrow" src="../../../../../assets/images/icon/Polygon6.svg" alt="Dropdown" >
                            </a>
                                <div class="row"> 
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                               <span class="monthSection">Kayaan Vora</span>
                                            </div>
                                            <div class="col-md-6 text-center"> 
                                                  <nz-card class="circleProgressSM" [nzBodyStyle]="{padding: '10px'}" [nzBordered]="true" > 
                                            <nz-progress
                                            [nzWidth]="84"
                                            nz-tooltip
                                            nzTitle="3 done / 3 in progress / 4 to do"
                                            nzType="circle"
                                            [nzPercent]="60 "
                                            [nzSuccessPercent]="30"
                                          ></nz-progress>
                                          <a nz-dropdown nzTrigger="click" class="cardDropDown" [nzDropdownMenu]="menu"> 
                                            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                                          </a>
                                          <span class="cardNumber">01</span>
                                          <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <ul nz-menu>
                                              <li nz-menu-item><i nz-icon nzType="calendar" nzTheme="outline"></i>Month wise Drilldown</li>
                                              <li nz-menu-item><i nz-icon nzType="line-chart" nzTheme="outline"></i>Initiative Drilldown</li>  
                                              <li nz-menu-item><i nz-icon nzType="bar-chart" nzTheme="outline"></i>Activity Drilldown</li>
                                              <li nz-menu-item><i nz-icon nzType="team" nzTheme="outline"></i>Team Wise Drilldown</li>
                                            </ul>
                                          </nz-dropdown-menu>
                                          </nz-card>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Target - 3Cr Achieved - 75L</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                                [nzWidth]="84"
                                                nz-tooltip
                                                nzTitle="3 done / 3 in progress / 4 to do"
                                                nzType="circle"
                                                [nzPercent]="25"
                                                [nzSuccessPercent]="30"
                                              ></nz-progress>
                                            </div>
                                        </div>                                   
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="row">
                                            <div class="col-md-6 d-flex align-items-center">
                                                <span>Estimated - 68 Hrs Spent - 14 Hrs</span>
                                            </div>
                                            <div class="col-md-6 d-flex align-items-center">
                                                <nz-progress
                                        [nzWidth]="84"
                                        nz-tooltip
                                        nzTitle="3 done / 3 in progress / 4 to do"
                                        nzType="circle"
                                        [nzPercent]="20"
                                        [nzSuccessPercent]="30"
                                      ></nz-progress>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </nz-card>
                        </div>
                      
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>