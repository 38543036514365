<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="tab-first">
                            <h3 class="py-4 text-center">Tickets</h3>
                            
                            <div   class="d-flex justify-content-between align-items-center">
                                <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()"
                                        nzType="plus-circle" nzTheme="fill"></i></h3>
                                <div>
                                    <!-- <button nz-button nzType="primary" (click)="showImportModal()">
                                        <span>Import</span>
                                    </button> -->
                                </div>
                            </div>
                            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading"
                                [nzTotal]="totalTicket" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                                (nzQueryParams)="onQueryParamsChange($event)" [nzData]="tickets">
                                <thead>
                                    <tr>
                                        <th>Ticket Id</th>
                                        <th >Ticket Title  <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
                                            <i nz-icon nzType="search"></i>
                                          </nz-filter-trigger></th>
                                        <th>Ticket Date</th>
                                        <th *ngIf="!tenantUser">Tenant Name</th>    
                                        <th>Status
                                            <nz-filter-trigger [(nzVisible)]="visible1" [nzActive]="searchValue1.length > 0" [nzDropdownMenu]="menu_status">
                                                <i nz-icon nzType="search"></i>
                                              </nz-filter-trigger>
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of tickets">
                                        <td>{{ ticket?.ticket_id }}</td>
                                        <td>{{ ticket?.title }}</td>
                                        <td>{{ ticket?.created_date }}</td>
                                        <td *ngIf="!tenantUser">{{ticket?.tenant_name}}</td>
                                        <td>{{ ticket?.ticket_status }}</td>
                                        
                                        <td>
                                            <a (click)="showEditModal(ticket)"><i
                                                    class="ri-pencil-fill grayIcon"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <div class="ant-table-filter-dropdown">
                              <div class="search-box">
                                <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
                                <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Search</button>
                                <button nz-button nzSize="small" (click)="reset()">Reset</button>
                              </div>
                            </div>
                          </nz-dropdown-menu>
                          <nz-dropdown-menu #menu_status="nzDropdownMenu">
                            <div class="ant-table-filter-dropdown">
                              <div class="search-box">
                                <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue1" />
                                <button nz-button nzSize="small" nzType="primary" (click)="search_status()" class="search-button">Search</button>
                                <button nz-button nzSize="small" (click)="reset()">Reset</button>
                              </div>
                            </div>
                          </nz-dropdown-menu>
                        <nz-modal [(nzVisible)]="isFormVisible" nzTitle="{{action | titlecase}} Ticket"
                            (nzOnCancel)="handleModalCancel()" [nzOkDisabled]="!ticketForm.valid" (nzOnOk)="handleModalSubmit()" nzOkText="Save"
                            nzOkType="primary" [nzOkLoading]="isFormLoading" [nzWidth]="700">
                            <form nz-form [nzLayout]="'vertical'" [formGroup]="ticketForm">
                                <div class="row">
                                  
                                        <div class="col-lg-4" *ngIf="action =='Edit'">
                                            <nz-form-item >
                                                <nz-form-label nzFor="Ticket ID">Ticket ID</nz-form-label>
                                                <nz-form-control >
                                                  <input [disabled]="true" nz-input id="ticket_id" placeholder="Enter Ticket ID"  formControlName="ticket_id"
                                                    placeholder="Ticket ID" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div> 
                                        <div class="col-lg-4" *ngIf="action =='Edit'"></div>
                                        <div class="col-lg-4" *ngIf="action =='Edit'">
                                            <nz-form-item>
                                                <nz-form-label nzRequired nzFor="Ticket Date">Ticket Date</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Ticket Date!">
                                                    <nz-date-picker
                                                        nzFormat="yyyy-MM-dd HH:mm"
                                                        [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 10 }"
                                                        [nzDisabledDate]="disabledDate"
                                                        formControlName="created_date"></nz-date-picker>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-8">
                                            <nz-form-item>
                                                <nz-form-label nzRequired nzFor="title">Ticket Title</nz-form-label>
                                                <nz-form-control nz-required nzErrorTip="Please Enter Ticket Title!">
                                                    <input nz-input id="title" placeholder="Enter Ticket Title"  formControlName="title"
                                                    placeholder="Ticket Title" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-4" *ngIf="action !='Edit'">
                                            <nz-form-item>
                                                <nz-form-label nzRequired nzFor="Ticket Date">Ticket Date</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Ticket Date!">
                                                    <nz-date-picker
                                                        nzFormat="yyyy-MM-dd HH:mm"
                                                        [nzDisabledDate]="disabledDate"
                                                        formControlName="created_date"></nz-date-picker>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <!-- <div class="col-lg-4"></div> -->
                                        <div class="col-lg-4" *ngIf="action =='Edit'">
                                            <nz-form-item>
                                                <nz-form-label nzRequired nzFor="Status">Status <i *ngIf="!isEdit" nzTooltipTitle="Change Status" nzTooltipPlacement="top" nz-tooltip class="fa fa-pencil-square-o edit-pencil" aria-hidden="true" (click)="isEdit = !isEdit"></i>
                                                    <i *ngIf="isEdit" nzTooltipTitle="Close" nzTooltipPlacement="top" nz-tooltip class="fa fa-times edit-pencil" aria-hidden="true" (click)="isEdit = !isEdit"></i>
                                                </nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Status!">
                                                    <nz-select nzAllowClear nzPlaceHolder="Select Status" formControlName="status_id" (ngModelChange)="statusChange($event)" *ngIf="isEdit">
                                                        <ng-container *ngIf="!espl_ticket">
                                                            <ng-container *ngFor="let item of refrence_data_status">
                                                                <nz-option *ngIf="item.key == 'TicketStatus' " [nzLabel]="item.value" [nzValue]="item.id">
                                                                </nz-option>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="espl_ticket">
                                                            <ng-container *ngFor="let item of refrence_data">
                                                                <nz-option *ngIf="item.key == 'TicketStatus' " [nzLabel]="item.value" [nzValue]="item.id">
                                                                </nz-option>
                                                            </ng-container>
                                                        </ng-container>
                                                    </nz-select>
                                                    <input *ngIf="!isEdit" nz-input id="statusName" readonly placeholder="Enter Ticket Status"  formControlName="statusName"
                                                    placeholder="Ticket Status" />
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                   
                                    <div class="col-lg-12">
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="Description">What is the issue?</nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter Description!">
                                                <textarea rows="3" nz-input formControlName="description" ></textarea>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-lg-12">
                                        <nz-form-item>
                                            <nz-form-label nzFor="Steps to Reproduce">What steps triggered this error? (Kindly reproduce in words)</nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter Steps to Reproduce!">
                                                <textarea rows="3" nz-input formControlName="steps_to_reproduce" ></textarea>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-lg-4" *ngIf="!tenantUser">
                                        <nz-form-item>
                                            <nz-form-label nzFor="Severity">Severity</nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter Severity!">
                                                <nz-select  nzAllowClear nzPlaceHolder="Select Severity"
                                                    formControlName="severity_id">
                                                    <ng-container  *ngFor="let item of refrence_data">
                                                    <nz-option *ngIf="item.key == 'TicketSeverity'" [nzLabel]="item.value" [nzValue]="item.id">
                                                    </nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-lg-4" *ngIf="!tenantUser">
                                        <nz-form-item>
                                            <nz-form-label nzFor="Module">Module</nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter Module!">
                                                <nz-select  nzAllowClear nzPlaceHolder="Select Module"
                                                    formControlName="module_id">
                                                    <ng-container  *ngFor="let item of refrence_data">
                                                    <nz-option *ngIf="item.key == 'Module'" [nzLabel]="item.value" [nzValue]="item.id">
                                                    </nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-lg-4" *ngIf="!tenantUser">
                                        <nz-form-item>
                                            <nz-form-label nzFor="Priority">Priority</nz-form-label>
                                            <nz-form-control nzErrorTip="Please Enter Priority!">
                                                <nz-select  nzAllowClear nzPlaceHolder="Select Priority"
                                                    formControlName="priority_id">
                                                    <ng-container  *ngFor="let item of refrence_data">
                                                    <nz-option *ngIf="item.key == 'TicketPriority'" [nzLabel]="item.value" [nzValue]="item.id">
                                                    </nz-option>
                                                    </ng-container>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-lg-12" *ngIf="action != 'Create'">
                                        <button nz-button nzSize="medium" class="mb-3 ant-btn ant-btn-md" (click)="fileAttachment()">
                                            Attachments ({{attachmentData?.length?attachmentData?.length:0}})
                                        </button>
                                    </div>
                                    <div class="col-lg-12" *ngIf="action == 'Create'">
                                        <button nz-button nzSize="medium" class="mb-3 ant-btn ant-btn-md" (click)="fileAttachment()">
                                            Attachments ({{attachmentFileData?.length?attachmentFileData?.length:0}})
                                        </button>
                                    </div>
                                   
                                    <ng-container *ngIf="!tenantUser">
                                        <div class="col-lg-2">
                                            <nz-form-item>
                                                <nz-form-label>Re-open Count</nz-form-label>
                                                <nz-form-control nzErrorTip="Please input Re-open Count!">
                                                    <nz-input-group>
                                                        <nz-input-number
                                                            formControlName="repoened_count"
                                                            [nzMin]="0"
                                                            [nzStep]="1" [nzPrecision]="0" (keypress)="numberOnly($event)">
                                                        </nz-input-number>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-3">
                                            <nz-form-item>
                                                <nz-form-label>Fixed within SLA?</nz-form-label>
                                                <nz-form-control nzErrorTip="Please input Fixed within SLA?!">
                                                    <nz-input-group class="pl-5">
                                                        <label nz-checkbox formControlName="fixed_within_sla"></label>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-3">
                                            <nz-form-item>
                                                <nz-form-label>First Time Fix?</nz-form-label>
                                                <nz-form-control nzErrorTip="Please input First Time Fix">
                                                    <nz-input-group class="pl-5">
                                                        <label nz-checkbox formControlName="first_time_fix"></label>
                                                    </nz-input-group>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-4">
                                            <nz-form-item>
                                                <nz-form-label nzFor="Ticket Fixed Date">Ticket Fixed Date</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Ticket Fixed Date!">
                                                    <nz-date-picker
                                                        nzFormat="yyyy-MM-dd HH:mm"
                                                        [nzDisabledDate]="disabledDate"
                                                        formControlName="fixed_date"></nz-date-picker>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-6">
                                            <nz-form-item>
                                                <nz-form-label nzFor="Resolution Type">Resolution Type</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Resolution Type!">
                                                    <nz-select  nzAllowClear nzPlaceHolder="Select Resolution Type"
                                                        formControlName="resolution_code_id">
                                                        <ng-container  *ngFor="let item of refrence_data">
                                                        <nz-option *ngIf="item.key == 'ResCode'" [nzLabel]="item.value" [nzValue]="item.id">
                                                        </nz-option>
                                                        </ng-container>

                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-6">
                                            <nz-form-item>
                                                <nz-form-label nzFor="Resolution Details">Resolution Details</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Resolution Details!">
                                                    <textarea rows="3" nz-input formControlName="resolution_details"></textarea>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-6">
                                            <nz-form-item>
                                                <nz-form-label nzFor="Root Cause">Root Cause</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Root Cause!">
                                                    <nz-select  nzAllowClear nzPlaceHolder="Select Root Cause"
                                                        formControlName="root_cause_code_id">
                                                        <ng-container  *ngFor="let item of refrence_data">
                                                        <nz-option *ngIf="item.key == 'RootCause'"  [nzLabel]="item.value" [nzValue]="item.id">
                                                        </nz-option>
                                                        </ng-container>
                                                    </nz-select>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <div class="col-lg-6">
                                            <nz-form-item>
                                                <nz-form-label nzFor="Root Cause Details">Root Cause Details</nz-form-label>
                                                <nz-form-control nzErrorTip="Please Enter Root Cause Details!">
                                                    <textarea rows="3" nz-input formControlName="root_cause_details"></textarea>
                                                </nz-form-control>
                                            </nz-form-item>
                                        </div>
                                        <!-- <div class="col-lg-12">
                                            <button nz-button nzSize="medium" class="mb-3 ant-btn ant-btn-md" (click)="showActivityLog()">
                                                Activity log
                                            </button>
                                        </div> -->
                                        </ng-container>
                                        <div class="col-lg-12" *ngIf="action == 'Edit'">
                                            <h6>Comments <button nz-button nzSize="small" class="ml-5 my-3 ant-btn ant-btn-md" (click)="addComments()">
                                                <i class="fa fa-plus mr-2" aria-hidden="true"></i> Add Comments
                                            </button></h6>
                                            <div class="mt-2" *ngIf="!loadingComments && allComments?.length != 0">
                                                <p class="mb-0" *ngFor="let comment of allComments">{{comment.user_name}} ({{comment?.comment.created_date | date: 'd-MMM-y'}}) : {{comment?.comment.comment}}</p>
                                            </div>
                                            <div class="mt-2 ml-3" *ngIf="loadingComments">
                                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                            </div>
                                            
                                        </div>
                             
                                </div>
                            </form>
                        </nz-modal>
                        <nz-modal [(nzVisible)]="isFileVisible" nzTitle="Attach screenshots"
                        (nzOnCancel)="fileModalCancel()" (nzOnOk)="fileModalOk()" nzOkText="Ok"
                        nzOkType="primary" [nzWidth]="600">
                        <!-- Action for Create -->
                        <div class="row" *ngIf="attachmentData?.length !=0 && action != 'Create'">
                            <div class="col-lg-12">
                                <button nz-button nzSize="medium" (click)="showattachmentModal()">+</button>
                            </div>
                            <div class="col-lg-6 mt-3 mb-2">
                                <h6><strong>Attachments Title</strong></h6>
                            </div>
                            <div class="col-lg-6 mt-3 mb-2">
                                <h6><strong>Attachments</strong></h6>
                            </div>
                            <ng-container *ngFor="let file of attachmentData">
                                <div class="col-lg-5">
                                    <p class="mb-0">{{file?.title}}</p>
                                </div>
                                <div class="col-lg-5">
                                    <p class="overflow-information mb-0" title="{{file?.file_path}}">{{file?.file_path}}</p>
                                </div>
                                <div class="col-lg-2">
                                    <i class="ri-delete-bin-6-fill mr-3 remove" (click)="removeAttachment(file)"></i>
                                    <i class="fa fa-download remove" (click)="downloadMeterial(file)"></i>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row" *ngIf="attachmentData?.length == 0 && action != 'Create'">
                            <div class="col-lg-12">
                                <button nz-button nzSize="medium" (click)="showattachmentModal()">+</button>
                            </div>
                            <div class="col-lg-12 text-center">
                                <h6>No Attachments</h6>
                            </div>
                        </div>
                        <!-- Action for Create -->
                        <div class="row" *ngIf="attachmentFileData?.length !=0 && action == 'Create'">
                            <div class="col-lg-12">
                                <button nz-button nzSize="medium" (click)="showattachmentModal()">+</button>
                            </div>
                            <div class="col-lg-6 mt-3 mb-2">
                                <h6><strong>Attachments Title</strong></h6>
                            </div>
                            <div class="col-lg-6 mt-3 mb-2">
                                <h6><strong>Attachments</strong></h6>
                            </div>
                            <ng-container *ngFor="let file of attachmentFileData">
                                <div class="col-lg-5">
                                    <p class="mb-0">{{file?.attachment_title}}</p>
                                </div>
                                <div class="col-lg-5">
                                    <p class="overflow-information mb-0" title="{{file?.file_Data?.name}}">{{file?.file_Data?.name}}</p>
                                </div>
                                <div class="col-lg-2">
                                    <i class="ri-delete-bin-6-fill mr-3 remove" (click)="removeAttachment(file)"></i>
                                    <!-- <i class="fa fa-download remove" (click)="downloadMeterial(file)"></i> -->
                                </div>
                            </ng-container>
                        </div>
                        <div class="row" *ngIf="attachmentFileData?.length == 0 && action == 'Create'">
                            <div class="col-lg-12">
                                <button nz-button nzSize="medium" (click)="showattachmentModal()">+</button>
                            </div>
                            <div class="col-lg-12 text-center">
                                <h6>No Attachments</h6>
                            </div>
                        </div>
                        </nz-modal>
                        <nz-modal [(nzVisible)]="isAttachmentVisible" nzTitle="File Attachments"
                        (nzOnCancel)="attachmentModalCancel()" [nzOkDisabled]="fileData?.length == 0" [nzWidth]="600" [nzOkLoading]="fileloader" (nzOnOk)="attachmentModalOk()" nzOkText="Upload"
                        nzOkType="primary">
                        <div class="row">
                            <div class="col-lg-12">
                            <!-- <form nz-form [nzLayout]="'vertical'" [formGroup]="attachmentsForm"> -->
                                <!-- <nz-form-item> -->
                                    <nz-form-label nzFor="Attachment Title">Attachment Title</nz-form-label>
                                <!-- </nz-form-item> -->
                                <nz-form-item>
                                    <!-- <nz-form-label nzFor="Attachment Title">Attachment Title</nz-form-label> -->
                                    <nz-form-control nzErrorTip="Please Attachment Title!">
                                        <input nz-input id="attachment_title" placeholder="Attachment Title"  [(ngModel)]="attachment_title">
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item class="mb-0">
                                    <nz-upload [nzFileList]="fileData" [nzPopoverVisible]="isFalse" (nzChange)="handleChange($event)">
                                        <button nz-button><i nz-icon nzType="upload"></i>Select files</button>
                                    </nz-upload>
                                </nz-form-item>
                            <!-- </form> -->
                            </div>
                        </div>
                        </nz-modal>

                        <nz-modal [(nzVisible)]="isAddCommentsVisible" nzTitle="Add Comments"
                        (nzOnCancel)="commentsModalCancel()" (nzOnOk)="commentsModalOk()" nzOkText="Add"
                        nzOkType="primary" [nzOkLoading]="commentsLoading" [nzOkDisabled]="!comments">
                        <div class="row">
                            <div class="col-lg-12">
                            <!-- <form nz-form [nzLayout]="'vertical'" [formGroup]="Form"> -->
                                <!-- <nz-form-item> -->
                                    <nz-form-label nzFor="Comments">Comments</nz-form-label>
                                <!-- </nz-form-item> -->
                                <nz-form-item>
                                    <!-- <nz-form-label nzFor="Comments">Comments</nz-form-label> -->
                                    <nz-form-control nzErrorTip="Please Comments!">
                                        <!-- <input nz-input id="attachment_title" placeholder="Comments"  [(ngModel)]="attachment_title"> -->
                                                <textarea rows="3" nz-input [(ngModel)]="comments" ></textarea>
                                            </nz-form-control>
                                </nz-form-item>
                            <!-- </form> -->
                            </div>
                        </div>
                        </nz-modal>

                        <nz-modal [(nzVisible)]="isActivityLogVisible" nzTitle="Ticket Activity Log"
                        (nzOnCancel)="activityLogCancel()" (nzOnOk)="activityLogOk()" [nzWidth]="700" nzOkText="Ok"
                        nzOkType="primary">
                        <div class="row">
                            <div class="col-lg-12">
                                <form nz-form>
                                    <nz-form-label nzFor="Ticket ID">Ticket ID</nz-form-label>
                                    <nz-form-item>
                                      <nz-row>
                                        <nz-col nzSpan="24">
                                          <input
                                            placeholder="Search for Ticket ID"
                                            nz-input
                                            [(ngModel)]="inputValue"
                                            (input)="onInput($event)"
                                            [nzAutocomplete]="auto"
                                            [formControl]="filterControl"/>
                                          <nz-autocomplete nzBackfill #auto>
                        
                                            <!-- <nz-auto-option *ngFor="let data of activities | async" [nzValue]="data?.user_name"
                                                            (click)="onUserSelect(data)" (keydown.enter)="onUserSelect(data)"   >
                                              {{data?.user_name}}
                                            </nz-auto-option> -->
                        
                                          </nz-autocomplete>
                                        </nz-col>
                                      </nz-row>
                                    </nz-form-item>
                                  </form>
                            </div>
                            <div class="col-lg-12">
                                <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading"
                                [nzTotal]="total" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                                (nzQueryParams)="onQueryParamsChange($event)" [nzData]="tickets">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>UserName</th>
                                        <th>Activity Name</th>
                                        <th>Activity Details</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of tickets">
                                        <td>{{ ticket.name }}</td>
                                        <td>{{ ticket.name }}</td>
                                        <td>{{ ticket.name }}</td>
                                        <td>{{ ticket.name }}</td>
                                        <td class="text-center">
                                            <button nz-button nzSize="medium"><i class="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                            </div>
                        </div>
                        </nz-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>