import { AppService } from './../../../app.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityMappingRetrieveRequest, NotificationRetrieveRequest } from 'src/app/app.models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-top-nav-bar1',
  templateUrl: './top-nav-bar1.component.html',
  styleUrls: ['./top-nav-bar1.component.css'],
})
export class TopNavBar1Component implements OnInit {

  // storage account url
  storageAccount: String;

  loggedInUserDetail: any;
  videoNotificationList = [];
  isNotificationModalOpen: boolean = false;
  notificationDetails: any;
  selectedNotification: any;
  isAnyNotificationUnRead: boolean;
  tenant_logo:String
  default_logo:String='https://trulead.s3.ap-south-1.amazonaws.com/logos/espl.jpeg'

  constructor(private router: Router, private appService: AppService, private authService: AuthService) {
    if (sessionStorage.getItem('isReloadGetMe')) {
      this.appService.getLoggedInUserDetail().subscribe((res) => {
        // localStorage.removeItem('me');
        sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
        this.loggedInUserDetail = res;
      });
      sessionStorage.removeItem('isReloadGetMe');
    }
  }

  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    this.tenant_logo=this.loggedInUserDetail?.tenant?.tenant_logo
    this.getNotification();
    this.appService.startNoticationPolling();
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
  }


  getNotification() {
    let rr = new NotificationRetrieveRequest();
    rr.per_page = null;
    rr.app_sent='yes';
    rr.emp_id = [this.loggedInUserDetail.employee.id];
    this.appService.getNotification(rr).subscribe(res => {
      this.notificationDetails = res['items'];
      // this.notificationDetails[0] = {...this.notificationDetails[0], is_app_read: "no"}
      this.isAnyNotificationUnRead = this.notificationDetails?.some(element => (element['is_app_read'] == 'no' && element['app_sent'] == 'yes' && element['app_notification_text'].length > 0) );

      console.log(this.isAnyNotificationUnRead );
    });


  }

  // logout() {
  //   this.appService.removeUserToken().subscribe((res) => {
  //     sessionStorage.removeItem('me');
  //     sessionStorage.removeItem('accountAccessToken');
  //     sessionStorage.removeItem('expires_at');
  //     sessionStorage.removeItem('Cookies');
  //     this.router.navigate(['/prelogin']);
  //   });
  // }

  openNotificationModal(notification) {
    this.selectedNotification = notification;
    if (this.selectedNotification?.is_app_read == 'no') {
      let data = { ...this.selectedNotification, is_app_read: 'yes' };
      this.appService.updateNotification(data, this.selectedNotification?.id).subscribe(res => {
        let data = this.notificationDetails.filter(element => element.id === this.selectedNotification?.id);
        if (data) {
          data = { ...data[0], is_app_read: 'yes' };
          let index = this.notificationDetails.findIndex(element => element.id === this.selectedNotification?.id);
          this.notificationDetails.splice(index, 1, data);
        }

      });
    }

    this.isNotificationModalOpen = true;
  }

  handleNotificationCancel() {
    this.isNotificationModalOpen = false;
  }

  handleNotificationOk() {
    this.handleNotificationCancel();
  }

  getVideoNotificationList() {
    let rr = new ActivityMappingRetrieveRequest();
    rr.emp_id = this.loggedInUserDetail.employee.id;
    // api call for get active video
    this.appService.getActivitiesMappingByActivityId(rr).subscribe((res) => {
      res['items'].forEach((e) => {
        e.is_assessor = 'no';
        this.appService
          .getActivityById(e.assessment_activity_id)
          .subscribe((res) => {
            e.activity_detail = res;
          });
        e.assessors.forEach((f) => {
          if (f == this.loggedInUserDetail.employee.id) {
            e.is_assessor = 'yes';
            return;
          }
        });
      });
      this.videoNotificationList = res['items'];
    });
  }

  createVideoRoomLink(mapping_detail) {
    let data = {
      room_name: mapping_detail.room_name,
      participant_name:
        this.loggedInUserDetail.employee.video_participant_name_slug,
      emp_id: this.loggedInUserDetail.employee.id,
      mapping_id: mapping_detail.id,
      is_assessor: mapping_detail.is_assessor,
      activity_id: mapping_detail.activity_detail.id,
      activity_type: mapping_detail.activity_detail.activity_type,
    };
    this.router.navigate(['video'], {
      queryParams: {d: btoa(encodeURIComponent(JSON.stringify(data)))},
    });
  }

  deleteNotification() {
    this.appService.deleteNotifications().subscribe(value => {
      this.notificationDetails = [];
      this.isAnyNotificationUnRead = false;
      Swal.fire(
        'Good job!',
        'Notification cleared!',
        'success'
      );
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<span>' + error.error.message + '</span>'
      });
    });
  }

  async logout() {
    clearInterval(this.appService.interval);
    await this.authService.signOut();

  }
}
