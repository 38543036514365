
<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div  class="  col-sm-12">  
                           <h3 class="py-4 text-center">Objective Target Analysis</h3>  
                            <div class="col-md-4 offset-md-4 text-center">
                              <nz-form-item> 
                                  <nz-form-control nzErrorTip="Please input Types!">
                                      <nz-input-group class="form-group">
                                          <nz-select
                                              [nzSize]="'large'"
                                              nzPlaceHolder="Please select Month"
                                              [nzOptions]="listOfNoOfOption"    
                                              >
                                          </nz-select>
                                      </nz-input-group>
                                  </nz-form-control>
                              </nz-form-item>
                            </div> 
                         <nz-table #editRowTable nzBordered [nzData]="listOfData">
                                  <thead>
                                    <tr>
                                      <th>SP Name</th>
                                      <th>My Target</th>
                                      <th>Team Target</th>
                                      <th>Buffer</th> 
                                    </tr>
                                  </thead> 
                                  <tbody>
                                    <tr *ngFor="let data of editRowTable.data" class="editable-row">
                                      <td>
                                        <!-- <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)"> -->
                                            {{ data.name }}
                                          <!-- </div> -->
                                          <!-- <input [hidden]="editId !== data.id" type="text" nz-input [(ngModel)]="data.name" (blur)="stopEdit()" /> -->
                                      </td>
                                      <td>{{ data.myTarget }}</td> 
                                      <td>{{ data.teamTarget }} <a class="ml-2" (click)="showModal()"><i nz-icon class="grayIcon" nzType="team" nzTheme="outline"></i></a></td>
                                      <td>
                                          {{data.buffer}}
                                          <!-- <a (click)="showModal()" ><i class="grayIcon"  nz-icon nzType="edit" nzTheme="outline"></i></a>   -->
                                          <!-- <a nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteRow(data.key)"><i nz-icon nzType="delete" nzTheme="fill"></i></a> -->
                                      </td>
                                    </tr>
                                  </tbody>
                                </nz-table> 
                                <nz-modal [(nzVisible)]="isVisible" nzTitle="" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                 
                                    <nz-table class="mt-5" #editRowTable nzBordered [nzData]="listOfData">
                                        <thead>
                                          <tr>
                                            <th>SP Name</th>
                                            <th>Target</th> 
                                          </tr>
                                        </thead> 
                                        <tbody>
                                          <tr>
                                            <td>Mayank Vora</td>
                                            <td>1 Cr</td>
                                          </tr>
                                          <tr>
                                            <td>Harish P</td>
                                            <td>1 Cr</td>
                                          </tr>
                                          <tr>
                                            <td>Kayaan V</td>
                                            <td>1 Cr</td>
                                          </tr> 
                                         </tbody>
                                     </nz-table>

                                  
                              </nz-modal> 
                      
                      </div>
</div> 
</div> 
</div> 
</div> 
</div> 
</div>