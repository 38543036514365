<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">{{launch.title}} - IDP</h3>
            <div class="d-flex justify-content-between align-items-center">
              <a routerLink="/forms/launches" class="back-btn ant-btn ant-btn-link ant-btn-icon-only">
                <i class="ri-arrow-go-back-fill"></i>
              </a>
              <div>
                <button nz-button [nzType]="'primary'" [nzLoading]="loading"  (click)="downloadAllReport()">
                  Bulk Report &nbsp;<i class="ri-download-line"></i>
                </button>
<!--                <button nz-button [nzType]="'primary'" [nzLoading]="loading">-->
<!--                  <i class="ri-admin-line"></i> <span class="ml-2">Role Based Publish</span>-->
<!--                </button>-->
              </div>
            </div>

            <div class="mt-3">
              <nz-table #employeesTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total" [nzPageSize]="limit" [nzPageIndex]="page" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="employees" [nzScroll]="{ x: '1150px'}">
                <thead>
                  <tr>
                    <th class="text-center">Employee ID</th>
                    <th class="searchfilter" nzLeft nzCustomFilter>
                      <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue?.length > 0" [nzDropdownMenu]="menu">
                        <i nz-icon nzType="search" nz-tooltip nzTooltipTitle="Search Employee"></i>
                      </nz-filter-trigger>
                      Employee Name</th>
                    <th class="text-center">Department</th>
                    <th class="text-center">Function</th>
                    <th class="text-center">Location</th>
                    <th class="text-center">Designation</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let employee of employees;">
                    <td>{{ employee.employee_id }}</td>
                    <td>{{ employee.first_name }} {{ employee.last_name }}</td>
                    <td>{{ employee.department.name }}</td>
                    <td>{{ employee.department.function.name }}</td>
                    <td>{{ employee.location.name }}</td>
                    <td>{{ employee.designation }}</td>
                    <td>{{ (statuses[employee.id] || 'not started').toUpperCase() }}</td>
                    <td class="text-center">
                      <button nz-button [nzType]="'primary'" [nzLoading]="loading" *ngIf="showDownloads[employee.id] == 'approved'" (click)="downloadReport(employee)">
                        <i class="ri-download-line"></i>
                      </button>
                      <span *ngIf="showDownloads[employee.id] != 'approved'">N/A</span>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <div class="ant-table-filter-dropdown">
                  <div class="search-box">
                    <input type="text" nz-input placeholder="Search Employee Name" [(ngModel)]="searchValue" />
                    <button nz-button nzSize="small" nzType="primary" (click)="searchServer()" class="search-button">Search</button>
                    <button nz-button nzSize="small" (click)="reset()">Reset</button>
                  </div>
                </div>
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
