import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormLaunch, FormLaunchRetrieveRequest } from 'src/app/app.models';

interface DataItem {
  title: string;
  start_date: string;
  end_date: string;
  status: string;
};

@Component({
  selector: 'app-form-my',
  templateUrl: './form-my.component.html',
  styleUrls: ['./form-my.component.css']
})
export class FormMyComponent implements OnInit {

  launches: FormLaunch[] = [];
  loggedInUserDetail: any;

  page = 1;
  total = 1;
  limit = 100;
  loading = false;
  sortOrder = 'desc';
  sortField = 'created_on';

  sortTitleFn = (a: DataItem, b: DataItem) => a.title.localeCompare(b.title);
  sortStartDateFn = (a: DataItem, b: DataItem) => a.start_date.localeCompare(b.start_date);
  sortEndDateFn = (a: DataItem, b: DataItem) => a.end_date.localeCompare(b.end_date);
  sortStatusFn = (a: DataItem, b: DataItem) => a.status.localeCompare(b.status);

  constructor(
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private spinner: NgxSpinnerService
  ) {
  }

  loadLaunches(): void {
    this.spinner.show();
    this.loading = true;
    let rr = new FormLaunchRetrieveRequest();
    rr.page = this.page || 1;
    rr.per_page = this.limit || 100;
    rr.sort_order = this.sortOrder || 'desc';
    rr.order_by = this.sortField || 'created_on';

    this.appService.getMyFormLaunches(rr).subscribe((data: any) => {
      this.total = data['_meta']['total_items'];
      this.launches = data['items'];
      for(let i in data['items']){
        this.appService.getMySubmissionByLaunchId(data['items'][i].id).subscribe((submission: any) => {
          if(submission.id){
            data['items'][i]['submission'] = submission;
          }
        });
      }
      this.spinner.hide();
      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.loadLaunches();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sortField = (currentSort && currentSort.key) || null;
    this.sortOrder = (currentSort && currentSort.value) || null;
    this.limit = pageSize;
    this.page = pageIndex;
    this.loadLaunches();
  }

}
