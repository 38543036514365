import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Employee, Form, FormRetrieveRequest } from 'src/app/app.models';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.css']
})
export class FormViewComponent implements OnInit, OnDestroy {
  private sub: any;

  page = 1;
  total = 1;
  limit = 100;
  formId: number;
  loading = false;
  submission: any;
  isVisible = false;
  sortOrder = 'desc';
  submissions: any[] = [];
  sortField = 'created_on';
  loggedInUserDetail: any;

  constructor(
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
  }

  loadSubmissions(formId): void {
    this.spinner.show();
    this.loading = true;

    this.appService.getSubmissionsByFormId(formId).subscribe((res: any) => {
      this.submissions = res;

      this.spinner.hide();
      this.loading = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.sub = this.route.params.subscribe(params => {
      this.formId = params['id'];
      this.loadSubmissions(this.formId);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onOpenEntry(record): void {
    this.isVisible = true;
    this.submission = record;
  }

  onReject(): void {
    this.onUpdateStatus('rejected');
  }

  onApprove(): void {
    this.onUpdateStatus('approved');
  }

  onUpdateStatus(status): void {
    let data = { status: status };
    this.appService.updateFormSubmission(this.submission.id, data).subscribe((res: any) => {
      this.loadSubmissions(this.formId);
      this.isVisible = false;
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message,
        allowOutsideClick: false,
      });
      this.spinner.hide();
      this.loading = false;
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sortField = (currentSort && currentSort.key) || null;
    this.sortOrder = (currentSort && currentSort.value) || null;
    this.limit = pageSize;
    this.page = pageIndex;
    this.loadSubmissions(this.formId);
  }

}
