import {AppService} from '../../app.service';
import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormControl
} from '@angular/forms';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {from} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {ColorPickerService} from 'ngx-color-picker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {forkJoin} from 'rxjs';
import {
  BusinessUnitRetrieveRequest, DepartmentRetrieveRequest, EmployeeRetrieveRequest,
  FunctionRetrieveRequest,
  GradeRetrieveRequest,
  LocationRetrieveRequest
} from "../../app.models";

declare function unescape(s:string): string;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})


export class SettingsComponent implements OnInit {



  // files: NzUploadFile[] = [];
  // // upload logo related
  // fileList: NzUploadFile[] = [];
  // beforeUpload = (file: NzUploadFile): boolean => {
  //   this.fileList = this.fileList.concat(file);
  //   return false;
  // };

  constructor(public fb: FormBuilder, public appService: AppService, public message: NzMessageService, public datepipe: DatePipe, private activatedRoute: ActivatedRoute, cpService: ColorPickerService, public vcRef: ViewContainerRef,) {
  }


  // Desired level array
  get desiredLevelArray() {
    return <FormArray> this.desiredLevelForm.get('levels');
  }

    // Desired level array
    get desiredBLevelArray() {
      return <FormArray> this.behaviourLevelForm.get('behaviourlevels');
    }

  get potentialLevels() {
    return <FormArray> this.nineBoxSettingGroup.get('potentialLevels');
  }

  get performanceLevels() {
    return <FormArray> this.nineBoxSettingGroup.get('performanceLevels');
  }

  // upload logo related
  file: any;
  fileLoading = false;
  avatarUrl?: string;
  action = 'create';
  loading = false;
  isFormLoading = false;
  settingForm!: FormGroup;
  settingId: any;
  current = 0;
  contentIndex = 0;
  loggedInUserDetail: any;
  themeColor: string;
  tile_1_color: string;
  tile_2_color: string;
  tile_3_color: string;
  tile_4_color: string;
  tile_5_color: string;
  generalForm!: FormGroup;
  profitMaximiserForm!: FormGroup;
  pointsStructureForm!: FormGroup;
  degreeScoreForm!: FormGroup;
  badgesForm!: FormGroup;
  behaviorTransactionForm!: FormGroup;
  assessmentSettingForm!: FormGroup;
  // Initializing Desired Level Form
  desiredLevelForm!: FormGroup;
  behaviourLevelForm!: FormGroup;
  badgesFormData: any = new FormData();
  generalFormData: any = new FormData();
  profitMaximiserFormData: any = new FormData();
  pointsStructureFormData: any = new FormData();
  degreeScoreFormData: any = new FormData();
  MaxDesiredsettingForm!: FormGroup;
  talentStatusForm!:FormGroup;
  // Time zone
  timeZoneForm!: FormGroup;
  privacyForm!: FormGroup;
  termsForm!: FormGroup;
  spacingSettingGroup!: FormGroup;
  ExpectedWrongQuestionsSettingGroup!: FormGroup;
  maximumDesiredScore: any;
  new_competency_count =0
  PerformanceMaxScoreFormDataNew:any[]=[]
  ReportThresholdDataNew:any[]=[]
  highPotentialValue:any
  check_id_value = 0
  listOfNoOfOption = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
  ];
  listOfMonthlyCycleOption = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 4},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12},
    {label: '13', value: 13},
    {label: '14', value: 14},
    {label: '15', value: 15},
    {label: '16', value: 16},
    {label: '17', value: 17},
    {label: '18', value: 18},
    {label: '19', value: 19},
    {label: '20', value: 20},
    {label: '21', value: 21},
    {label: '22', value: 22},
    {label: '23', value: 23},
    {label: '24', value: 24},
    {label: '25', value: 25},
    {label: '26', value: 26},
    {label: '27', value: 27},
    {label: '28', value: 28},
    {label: '29', value: 14},
    {label: '30', value: 30},
    {label: '31', value: 31},
  ];
  formValues;
  talentCardStatus
  talentCard=[{label:'active',value:'active'},{label:'inactive',value:'inactive'}]
  maximiserSettingId: number;
  nineBoxSettingGroup!: FormGroup;
  PerformanceMaxScoreForm!: FormGroup;
  ReportThresholdForm!: FormGroup;
  timeZones: any;
  editorStyle = {
    height: '600px'
  };

  selfData = {
    id: null,
    key: '360_self_notification_message'
  };

  managerData = {
    id: null,
    key: '360_manager_notification_message'
  };

  peerData = {
    id: null,
    key: '360_peer_notification_message'
  };

  subOrdinateData = {
    id: null,
    key: '360_subordinate_notification_message'
  };

  reviewerData={
    id: null,
    key: '360_reviewer_notification_message'
  }


  selfDefinition = {
    id: null,
    key: 'self_definition_message'
  };

  managerDefinition = {
    id: null,
    key: 'manager_definition_message'
  };

  peerDefinition = {
    id: null,
    key: 'peer_definition_message'
  };

  subOrdinateDefinition = {
    id: null,
    key: 'subordinate_definition_message'
  };

  reviewerDefinition = {
    id: null,
    key: 'reviewer_definition_message'
  };


  restrict_360_manager = {
    id: null,
    key: 'restrict_360_manager'
  };

  spacingKeyValue = [
  {id:null,key:'spacing_days',value:null},
  {id:null,key:'no_of_questions_per_round',value:null},
  {id:null,key:'spacing_within_a_round',value:null},
  {id:null,key:'start_time_of_question',value:null},
  {id:null,key:'no_of_reattempt_round',value:null}]

  spacingExpectedKeyValue = [
    {id:null,key:'best_case_scenario',value:null},
    {id:null,key:'medium_case_scenario',value:null},
    {id:null,key:'worst_case_scenario',value:null}]

  CompetencyLevelDeleteArray: any = [];
  BehaviourLevelDeleteArray: any = [];


  setting_data = new Map<string, any>();

  prematureClosureForm!: FormGroup;
  deadlineExtendForm!: FormGroup;

  prematureClosureDeleteArray: any = [];

  SuccessionManagementForm!: FormGroup;
  editOption=false
  controlColors: string[] = [];
  RLcontrolColors: string[] = [];

  SMSettings: any[]=[];
  criticalLevelID:any
  criticalRoleID:any
  parameterRoleID:any
  managerialLevelID:any
  editableRoleParameter= false
  previous_data_rp:any


  validateForm!:FormGroup
  bUOptionList:any[]=[]
  locationOptionList:any[]=[]
  functionOptionList:any[]=[]
  departmentOptionList:any[]=[]
  gradeListOption:any[]=[]

  isBusinessUnitOptionListLoading = true
  isLocationOptionListLoading = true
  isFunctionOptionListLoading = true
  isDepartmentOptionListLoading = true
  criticalRolesLodading = true

  showSelectBusiness = false
  showSelectLocation = true
  showSelectFunction = true
  showSelectDepartment = true
  showSelectGrades = true
  showSelectEmployeeML = true

  listOfMonths=[
    {'value':'1','label':'1 Month'},
    {'value':'2','label':'2 Month'},
    {'value':'3','label':'3 Month'},
    {'value':'4','label':'4 Month'},
    {'value':'5','label':'5 Month'},
    {'value':'6','label':'6 Month'},
    {'value':'7','label':'7 Month'},
    {'value':'8','label':'8 Month'},
    {'value':'9','label':'9 Month'},
    {'value':'10','label':'10 Month'},
    {'value':'11','label':'11 Month'},
    {'value':'12','label':'12 Month'},
  ]

  ngOnInit(): void {
    this.getAllMaximiserSettings();
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url

    // console.log(this.loggedInUserDetail);
    this.initGeneralForm();
    this.initProfitMaximiserForm();
    this.initPointsStructureForm();
    this.initDegreeScoreForm();
    this.intiBadgesForm();
    this.initBehaviorTransactionForm();
    this.initDesiredLevelForm();
    this.initNineBoxSettingForm();
    this.initPerformanceMaxScoreForm();
    this.initTimeZoneForm();
    this.initPrivacyForm();
    this.initTermsForm();
    this.initBehaviourLevelForm();
    this.initTalentCardForm();
    this.initReportThreshold();

    this.initSuccessionManagement()
    this.loadRetirementStatus()
    this.SuccessionManagementForm.get('critical_role_approval').valueChanges.subscribe((res)=>{
      this.successionManagementSettingsUpdate(this.criticalRoleID,'critical-role-approval',res)
    })

    this.loadRedinessLevelSM()


    this.SuccessionManagementForm.get('role_parameter').valueChanges.subscribe((res)=>{
      this.successionManagementSettingsUpdate(this.parameterRoleID,'role-parameter',res)
      if(res == "active"){
        this.loadRoleParameters()
        this.loadCriticalLevelSM()
      } else {

        console.log("this.criticality_levels.length",this.criticality_levels.length)
        console.log("this.role_parameters.length",this.role_parameters.length)
        this.criticality_levels.clear();
        this.role_parameters.clear();
        console.log('role_parameters control after remove at index all',this.role_parameters)
        console.log('criticality_levels control after remove at index all',this.criticality_levels)
      }
    })

    this.validateForm = this.fb.group({
      employee_from:[null],
      business_unit_id:[null],
      location_id:[null],
      department_id:[null],
      function_id:[null],
      employee_grade_id:[null],
      employee_id:[null],
    })

    this.business_unit_id.valueChanges.subscribe((res)=>{
      this.location_id.setValue(null)
      this.function_id.setValue(null)
      this.employee_grade_id.setValue(null)
      // console.log('business_unit_id.value',this.business_unit_id.value)
      if(res?.length > 0){
        if(res[res?.length - 1] == "select_all"){
          res = this.bUOptionList
            .filter(item => item.id != null)
            .map(item => item.id);
          this.business_unit_id.setValue(res)
          if(res.length == this.bUOptionList.length){
            this.showSelectBusiness = false
          }
        } else {
          if(res?.length == this.bUOptionList.length){
            this.showSelectBusiness = false
          } else {
            this.showSelectBusiness = true
          }
        }

      } else {
        this.showSelectBusiness = true
      }
    })

    this.location_id.valueChanges.subscribe((res)=>{
      if(res?.length > 0){
        if(res[res.length - 1] == "select_all"){
          res = this.locationOptionList
            .filter(item => item.id != null)
            .map(item => item.id);
          this.location_id.setValue(res)
          if(res.length == this.locationOptionList.length){
            this.showSelectLocation = false
          }
        } else {
          if(res.length == this.locationOptionList.length){
            this.showSelectLocation = false
          } else {
            this.showSelectLocation = true
          }
        }
      } else {
        this.showSelectLocation = true
      }
    })

    this.function_id.valueChanges.subscribe((res)=>{
      if(res?.length > 0){
        if(res[res.length - 1] == "select_all"){
          res = this.functionOptionList
            .filter(item => item.id != null)
            .map(item => item.id);
          this.function_id.setValue(res)
          if(res.length == this.functionOptionList.length){
            this.showSelectFunction = false
          }
        } else {
          if(res.length == this.functionOptionList.length){
            this.showSelectFunction = false
          } else {
            this.showSelectFunction = true
          }
        }
      } else{
        this.showSelectFunction = true
        this.department_id.setValue(null)
      }
    })

    this.department_id.valueChanges.subscribe((res)=>{
      if(res?.length > 0){
        if(res[res.length - 1] == "select_all"){
          res = this.departmentOptionList
            .filter(item => item.id != null)
            .map(item => item.id);
          this.department_id.setValue(res)
          if(res.length == this.departmentOptionList.length){
            this.showSelectDepartment = false
          }
        } else {
          if(res.length == this.departmentOptionList.length){
            this.showSelectDepartment = false
          } else {
            this.showSelectDepartment = true
          }
        }
      } else {
        this.showSelectDepartment = true
      }
    })

    this.employee_grade_id.valueChanges.subscribe((res:any)=>{
      if(res?.length > 0){
        if(res[res.length - 1] == "select_all"){
          res = this.gradeListOption
            .filter(item => item.id != null)
            .map(item => item.id);
          this.employee_grade_id.setValue(res)
          if(res.length == this.gradeListOption.length){
            this.showSelectGrades = false
          }
        } else {
          if(res.length == this.gradeListOption.length){
            this.showSelectGrades = false
          } else {
            this.showSelectGrades = true
          }
        }
      } else {
        this.showSelectGrades = true
      }
    })


    this.employee_id_mlctrl.valueChanges.subscribe((res)=>{
      if(res?.length > 0){
        if(res[res.length - 1] == "select_all"){
          res = this.employeeList
            .filter(item => item.id != null)
            .map(item => item.id);
          this.employee_id_mlctrl.setValue(res)
          if(res.length == this.employeeList.length){
            this.showSelectEmployeeML = false
          }
        } else {
          if(res.length == this.employeeList.length){
            this.showSelectEmployeeML = false
          } else {
            this.showSelectEmployeeML = true
          }
        }
      } else {
        this.showSelectEmployeeML = true
      }
    })
  }


  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  initGeneralForm() {
    this.generalForm = this.fb.group({
      company_name: [null, [Validators.required]],
      legal_entity: [null, [Validators.required]],
      contact_person: [null, [Validators.required]],
      contact_person_phone: [null, [Validators.required]],
      contact_person_email: [null, [Validators.required, Validators.email, Validators.minLength(7)]],
      company_website: [null, [Validators.required, Validators.pattern(this.urlRegex)]],
    });
  }
  initTalentCardForm(){
    this.talentStatusForm = this.fb.group({
      talentStatus:[null,[Validators.required]]
    });
  }
  initProfitMaximiserForm() {
    this.profitMaximiserForm = this.fb.group({
      sp_name: [null, [Validators.required]],
      ap_name: [null, [Validators.required]],
      objective_name: [null, [Validators.required]],
      initiative_name: [null, [Validators.required]],
      initiative_month_start: [null, [Validators.required]],
      initiative_month_end: [null, [Validators.required]],
      max_effort_jan: [null, [Validators.required]],
      max_effort_feb: [null, [Validators.required]],
      max_effort_mar: [null, [Validators.required]],
      max_effort_apr: [null, [Validators.required]],
      max_effort_may: [null, [Validators.required]],
      max_effort_jun: [null, [Validators.required]],
      max_effort_jul: [null, [Validators.required]],
      max_effort_aug: [null, [Validators.required]],
      max_effort_sep: [null, [Validators.required]],
      max_effort_oct: [null, [Validators.required]],
      max_effort_nov: [null, [Validators.required]],
      max_effort_dec: [null, [Validators.required]],
      tile_range_1_color: [null, [Validators.required]],
      tile_range_1_max_perc: [null, [Validators.required]],
      tile_range_1_min_perc: [null, [Validators.required]],
      tile_range_2_color: [null, [Validators.required]],
      tile_range_2_max_perc: [null, [Validators.required]],
      tile_range_2_min_perc: [null, [Validators.required]],
      tile_range_3_color: [null, [Validators.required]],
      tile_range_3_max_perc: [null, [Validators.required]],
      tile_range_3_min_perc: [null, [Validators.required]],
      effort_less_than_target_color: [null, [Validators.required]],
      effort_more_than_target_color: [null, [Validators.required]],
      // image_file: [null, [Validators.required]], //check
      image_file: [0],
      theme_colour: [null, [Validators.required]],
    });
  }

  initPointsStructureForm() {
    this.pointsStructureForm = this.fb.group({

      // Learning Assessment Points fields
      lap_each_correct_answer: [null],
      lap_each_correct_answer_reattempt: [null],
      lap_greater_than_80: [null],
      lap_100: [null],
      point_target_achieved: [null],
      points_milestone_achieved: [null],
      points_project_completion: [null],
    });
  }

  initDegreeScoreForm() {
    this.degreeScoreForm = this.fb.group({
      sbf_almost_never: [null, [Validators.required]],
      sbf_most_of_the_time: [null, [Validators.required]],
      sbf_never_always: [null, [Validators.required]],
      sbf_not_very_often: [null, [Validators.required]],
      sbf_some_of_the_time: [null, [Validators.required]],

      sba_clear_strength: [null, [Validators.required]],
      sba_clear_weakness: [null, [Validators.required]],
      sba_good: [null, [Validators.required]],
      sba_not_very_good: [null, [Validators.required]],
      sba_very_good: [null, [Validators.required]],

      sbe_1_to_20: [null, [Validators.required]],
      sbe_21_to_40: [null, [Validators.required]],
      sbe_41_to_60: [null, [Validators.required]],
      sbe_61_to_80: [null, [Validators.required]],
      sbe_81_to_100: [null, [Validators.required]],

      self_notify_message: [null, [Validators.required]],
      manager_notify_message: [null, [Validators.required]],
      peer_notify_message: [null, [Validators.required]],
      subordinate_notify_message: [null, [Validators.required]],
      reviewer_notify_message:[null],


      self_definition_message: [null, [Validators.required]],
      manager_definition_message: [null, [Validators.required]],
      peer_definition_message: [null, [Validators.required]],
      subordinate_definition_message: [null, [Validators.required]],
      reviewer_definition_message: [null],

      restrict_360_manager: [null, [Validators.required]],


    });
  }

  intiBadgesForm() {
    this.badgesForm = this.fb.group({
      badges_setup_level_1_name: [null, [Validators.required, Validators.pattern('^[a-zA-Z]+$')]], //"Silver"
      badges_setup_level_2_name: [null, [Validators.required, Validators.pattern('^[a-zA-Z]+$')]], //"Gold"
      badges_setup_level_3_name: [null, [Validators.required, Validators.pattern('^[a-zA-Z]+$')]], //"Platinum"
      badges_setup_level_1_score: [null, [Validators.required]], // 300
      badges_setup_level_2_score: [null, [Validators.required]], //500
      badges_setup_level_3_score: [null, [Validators.required]],  //1000
    });
  }

  initBehaviorTransactionForm() {
    this.behaviorTransactionForm = this.fb.group({
      category_label: [null, [Validators.required]],
      behavior_label: [null, [Validators.required]],
      principle_label: [null, [Validators.required]],
      nanobehavior_label: [null, [Validators.required]],

      same_function_location_bu_observer_points: [null, [Validators.required]],
      same_function_location_bu_displayer_points: [null, [Validators.required]],
      different_function_location_bu_observer_points: [null, [Validators.required]],
      different_function_location_bu_displayer_points: [null, [Validators.required]],
    });
  }

  initAssessmentSettingForm() {
    this.assessmentSettingForm = this.fb.group({
      max_activities_la: [null, [Validators.required]],
      max_activities_assessment_centre: [null, [Validators.required]],
      max_score_la: [null, [Validators.required]],
      max_farf: [null, [Validators.required]],
      max_farf_duration: [null, [Validators.required]],
    });
  }

  initNineBoxSettingForm() {
    this.nineBoxSettingGroup = this.fb.group({
      potentialLevels: this.fb.array([]),
      performanceLevels: this.fb.array([])
    });
  }

  initPerformanceMaxScoreForm() {
    this.PerformanceMaxScoreForm = this.fb.group({
      dataItems: this.fb.array([]),
    });
  }

  patchPerformanceMaxScoreForm() {
    const dataItemsFormArray = this.PerformanceMaxScoreForm.get('dataItems') as FormArray;
    for (const data of this.PerformanceMaxScoreFormDataNew) {
      dataItemsFormArray.push(
        this.fb.group({
          id: [data.id],
          key: [data.key],
          value: [data.value, Validators.required],
        })
      );
    }
  }

  initReportThreshold(){
    this.ReportThresholdForm = this.fb.group({
      dataReports: this.fb.array([])
    })
  }

  patchReportThresholdForm() {
    this.highPotentialValue;
    this.appService.getSettings(['high-potential']).subscribe((res: any) => {
      this.highPotentialValue = res.items[0].value
      // console.log('items high-potential', this.highPotentialValue);

      if(this.highPotentialValue){
        const dataReportFormArray = this.ReportThresholdForm.get('dataReports') as FormArray;
        for (const data of this.ReportThresholdDataNew) {
          dataReportFormArray.push(
            this.fb.group({
              id: [data.id],
              key: [data.key],
              value: [data.value, [Validators.required,Validators.max(this.highPotentialValue)]],
            })
          );
        }
      }
    })

  }

  initTermsForm() {
    this.privacyForm = this.fb.group({
      id: [null],
      key: ['privacy-policy'],
      value: [null, Validators.required]
    });
  }

  initPrivacyForm() {
    this.termsForm = this.fb.group({
      id: [null],
      key: ['terms-of-service'],
      value: [null, Validators.required]
    });
  }

  spacingFormGroup(){
    this.spacingSettingGroup = this.fb.group({
      spacing_days:[null,Validators.required],
      no_of_questions_per_round:[null,Validators.required],
      spacing_within_a_round:[null,Validators.required],
      start_time_of_question:[this.time,Validators.required],
      no_of_reattempt_round:[null,Validators.required],
    });
  }

  ExpectedWrongQuestionsFormGroup(){
    this.ExpectedWrongQuestionsSettingGroup = this.fb.group({
      best_case_scenario:[null,Validators.required],
      medium_case_scenario:[null,Validators.required],
      worst_case_scenario:[null,Validators.required],
    });
  }

  nineBoxItem(item: any) {
    return this.fb.group({
      id: [item.id],
      key: [item.key],
      value: [item.value, [Validators.required]],
      caption: [item.caption],
      icon: [item.icon],
      status: [item.status]
    });
  }


  initTimeZoneForm() {
    this.timeZoneForm = this.fb.group({
      id: [null],
      key: ['time_zone'],
      value: [null, Validators.required]
    });
  }

  getTimeZoneData() {
    const data = {
      country_id: [],
      timezone_code: []
    };
    this.appService.getTimeZones(data).subscribe(res => {
      // console.log('The time zones result is : ', res);
      this.timeZones = res['items'];
    });
  }

  initDesiredLevelForm() {
    this.new_competency_count = 0;
    this.desiredLevelForm = this.fb.group({
      levels: this.fb.array([this.desiredLevel()])
    });

    this.MaxDesiredsettingForm = this.fb.group({
      id: [null],
      key: ['maximum-desired-score'],
      value: [0, [Validators.required, Validators.min(1)]]
    });

  }
  initBehaviourLevelForm() {
    this.behaviourLevelForm = this.fb.group({
      behaviourlevels: this.fb.array([this.desiredBLevel()])
    });

    // this.MaxDesiredsettingForm = this.fb.group({
    //   id: [null],
    //   key: ['maximum-desired-score'],
    //   value: [0, [Validators.required, Validators.min(1)]]
    // });

  }

  // initialising the levels
  desiredLevel() {

    return this.fb.group({
      id: [null],
      level_name: [null, [Validators.required]],
      is_select: [null],
      status: [true],
      order: [null]
    });
  }

    // initialising the levels
    desiredBLevel() {
      return this.fb.group({
        id: [null],
        level_name: [null, [Validators.required]],
        is_select: [null],
        status: [true]

      });
    }

  // Adding new field to level
  addNewLevel() {
    this.desiredLevelArray.push(this.desiredLevel());

    if(this.desiredLevelArray.value.length==1){
      this.desiredLevelArray
      .controls[0]['controls']['is_select'].setValue(true)

    }
  }

  // Removing a level
  removeLevel(index, id) {
    // console.log(index, id);
    if (id) {
      this.CompetencyLevelDeleteArray.push(id);
    }
    this.desiredLevelArray.removeAt(index);
    if(this.desiredLevelArray.value.length==1){
      this.desiredLevelArray
      .controls[0]['controls']['is_select'].setValue(true)

    }
    // console.log( this.desiredLevelArray.value);
  }

  addNewBLevel() {
    this.desiredBLevelArray.push(this.desiredBLevel());
  }

  // Removing a level
  removeBLevel(index, id) {
    // console.log(index, id);
    if (id) {
      // this.BehaviourLevelDeleteArray.push(id);
      this.appService.deleteBehaviourLevelSettings(id).subscribe((res) => {
        this.desiredBLevelArray.removeAt(index);
        this.message.create('success', 'Deleted Successfully', {
          nzDuration: 5000
        });
        this.BehaviourLevelDeleteArray = [];
      },err=>{
        this.message.create('warning', 'Failed to deleted. Please try again!', {
          nzDuration: 5000
        });
        this.BehaviourLevelDeleteArray = [];
      });
    }else{
      this.desiredBLevelArray.removeAt(index);
    }
  }


  // To Make checkbox as like RadioButton
  makeLikeRadio(i) {
    for (let j in this.desiredLevelArray.controls) {

      if (i != j) {
        // console.log('i in first is : ', i, 'j is : ', j);
        this.desiredLevelArray.controls[j]['controls']['is_select'].setValue(false);
      } else {
        // console.log('i is : ', i, 'j is : ', j);
        this.desiredLevelArray.controls[j]['controls']['is_select'].setValue(true);
      }
    }
  }

  // Checkbox to radio ends

  submitBehaviorTransactionForm() {
    // Create the Setting on the server.
    if (this.behaviorTransactionForm.valid) {
      this.isFormLoading = true;
      let formData: any = new FormData();
      // Behavior Transactions Points Form Data
      formData.append('category_label', this.behaviorTransactionForm.value.category_label);
      formData.append('behavior_label', this.behaviorTransactionForm.value.behavior_label);
      formData.append('principle_label', this.behaviorTransactionForm.value.principle_label);
      formData.append('nanobehavior_label', this.behaviorTransactionForm.value.nanobehavior_label);

      formData.append('same_function_location_bu_observer_points', this.behaviorTransactionForm.value.same_function_location_bu_observer_points);
      formData.append('same_function_location_bu_displayer_points', this.behaviorTransactionForm.value.same_function_location_bu_displayer_points);
      formData.append('different_function_location_bu_observer_points', this.behaviorTransactionForm.value.different_function_location_bu_observer_points);
      formData.append('different_function_location_bu_displayer_points', this.behaviorTransactionForm.value.different_function_location_bu_displayer_points);

      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateMaximiserSettings(formData, this.maximiserSettingId);
      message = 'Behavior Transactions Settings has been updated.';

      subscriber.subscribe(res => {
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  submitGeneralForm() {
    // Create the Setting on the server.
    if (this.generalForm.valid) {
      this.isFormLoading = true;
      this.generalFormData = this.generalForm.value;

      // console.log('General:', this.generalFormData);
      let formData: any = new FormData();
      // General Form Data
      formData.append('company_name', this.generalFormData.company_name);
      formData.append('legal_entity', this.generalFormData.legal_entity);
      formData.append('contact_person', this.generalFormData.contact_person);
      formData.append('contact_person_phone', this.generalFormData.contact_person_phone);
      formData.append('contact_person_email', this.generalFormData.contact_person_email);
      formData.append('company_website', this.generalFormData.company_website);
      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateMaximiserSettings(formData, this.maximiserSettingId);
      message = 'General Settings has been updated.';

      subscriber.subscribe(res => {
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  submitProfitMaximiserForm() {
    if (this.profitMaximiserForm.valid) {
      this.isFormLoading = true;
      this.profitMaximiserFormData = this.profitMaximiserForm.value;
      // console.log('profit:', this.profitMaximiserFormData);
      let formData: any = new FormData();
      // Profit Maximiser Form Data
      // let image_file = this.settingForm.controls['image_file'].value[0].file;

      formData.append('sp_name', this.profitMaximiserFormData.sp_name);
      formData.append('ap_name', this.profitMaximiserFormData.ap_name);
      formData.append('objective_name', this.profitMaximiserFormData.objective_name);
      formData.append('initiative_name', this.profitMaximiserFormData.initiative_name);
      formData.append('initiative_month_start', this.profitMaximiserFormData.initiative_month_start);
      formData.append('initiative_month_end', this.profitMaximiserFormData.initiative_month_end);
      formData.append('max_effort_jan', this.profitMaximiserFormData.max_effort_jan);
      formData.append('max_effort_feb', this.profitMaximiserFormData.max_effort_feb);
      formData.append('max_effort_mar', this.profitMaximiserFormData.max_effort_mar);
      formData.append('max_effort_apr', this.profitMaximiserFormData.max_effort_apr);
      formData.append('max_effort_may', this.profitMaximiserFormData.max_effort_may);
      formData.append('max_effort_jun', this.profitMaximiserFormData.max_effort_jun);
      formData.append('max_effort_jul', this.profitMaximiserFormData.max_effort_jul);
      formData.append('max_effort_aug', this.profitMaximiserFormData.max_effort_aug);
      formData.append('max_effort_sep', this.profitMaximiserFormData.max_effort_sep);
      formData.append('max_effort_oct', this.profitMaximiserFormData.max_effort_oct);
      formData.append('max_effort_nov', this.profitMaximiserFormData.max_effort_nov);
      formData.append('max_effort_dec', this.profitMaximiserFormData.max_effort_dec);
      formData.append('tile_range_1_color', this.profitMaximiserFormData.tile_range_1_color);
      formData.append('tile_range_1_max_perc', this.profitMaximiserFormData.tile_range_1_max_perc);
      formData.append('tile_range_1_min_perc', this.profitMaximiserFormData.tile_range_1_min_perc);
      formData.append('tile_range_2_color', this.profitMaximiserFormData.tile_range_2_color);
      formData.append('tile_range_2_max_perc', this.profitMaximiserFormData.tile_range_2_max_perc);
      formData.append('tile_range_2_min_perc', this.profitMaximiserFormData.tile_range_2_min_perc);
      formData.append('tile_range_3_color', this.profitMaximiserFormData.tile_range_3_color);
      formData.append('tile_range_3_max_perc', this.profitMaximiserFormData.tile_range_3_max_perc);
      formData.append('tile_range_3_min_perc', this.profitMaximiserFormData.tile_range_3_min_perc);
      formData.append('effort_less_than_target_color', this.profitMaximiserFormData.effort_less_than_target_color);
      formData.append('effort_more_than_target_color', this.profitMaximiserFormData.effort_more_than_target_color);
      formData.append('theme_colour', this.profitMaximiserFormData.theme_colour);
      //  formData.append("image_file",  this.profitMaximiserFormData.image_file);
      formData.append('image_file', this.file);


      //image upload
      // this.fileList.forEach((file: any) => {
      //   formData.append('image_file', file);
      // });
      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateMaximiserSettings(formData, this.maximiserSettingId);
      message = 'Performance Maximiser Settings has been updated.';

      subscriber.subscribe(res => {
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }

  }

  submitPointsStructureForm() {
    // Create the Setting on the server.
    if (this.pointsStructureForm.valid) {
      this.isFormLoading = true;
      this.pointsStructureFormData = this.pointsStructureForm.value;
      // console.log('points structure:', this.pointsStructureFormData);
      let formData: any = new FormData();
      // Points Structure Form Data
      // formData.append("btp_displayer_points_across_same", this.pointsStructureFormData.btp_displayer_points_across_same);
      // formData.append("btp_displayer_points_within_same", this.pointsStructureFormData.btp_displayer_points_within_same);
      // formData.append("btp_observer_points_across_same", this.pointsStructureFormData.btp_observer_points_across_same);
      // formData.append("btp_observer_points_within_same", this.pointsStructureFormData.btp_observer_points_within_same);
      // formData.append("challenge_points_creation", this.pointsStructureFormData.challenge_points_creation);
      // formData.append("challenge_points_participation", this.pointsStructureFormData.challenge_points_participation);
      // formData.append("challenge_points_submission", this.pointsStructureFormData.challenge_points_submission);
      // formData.append("survey_points_submission", this.pointsStructureFormData.survey_points_submission);
      // formData.append("poll_points_submission", this.pointsStructureFormData.poll_points_submission);
      // formData.append("dfp_comments", this.pointsStructureFormData.dfp_comments);
      // Learning Assessment Points fields
      formData.append('lap_each_correct_answer', this.pointsStructureFormData.lap_each_correct_answer);
      formData.append('lap_each_correct_answer_reattempt', this.pointsStructureFormData.lap_each_correct_answer_reattempt);
      formData.append('lap_greater_than_80', this.pointsStructureFormData.lap_greater_than_80);
      formData.append('lap_100', this.pointsStructureFormData.lap_100);
      // Behavior Transaction Points fields
      formData.append('category_label', this.pointsStructureFormData.category_label);
      formData.append('behavior_label', this.pointsStructureFormData.behavior_label);
      formData.append('principle_label', this.pointsStructureFormData.principle_label);
      formData.append('nanobehavior_label', this.pointsStructureFormData.nanobehavior_label);

      // formData.append("same_function_location_bu_observer_points", this.pointsStructureFormData.same_function_location_bu_observer_points);
      // formData.append("same_function_location_bu_displayer_points", this.pointsStructureFormData.same_function_location_bu_displayer_points);
      // formData.append("different_function_location_bu_observer_points", this.pointsStructureFormData.different_function_location_bu_observer_points);
      // formData.append("different_function_location_bu_displayer_points", this.pointsStructureFormData.different_function_location_bu_displayer_points);

      // Maximiser Settings Points fields
      formData.append('point_target_achieved', this.pointsStructureFormData.point_target_achieved);
      formData.append('points_milestone_achieved', this.pointsStructureFormData.points_milestone_achieved);
      formData.append('points_project_completion', this.pointsStructureFormData.points_project_completion);
      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateMaximiserSettings(formData, this.maximiserSettingId);
      message = 'Points Structure Settings has been updated.';

      subscriber.subscribe(res => {
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;


        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  submitDegreeScoreForm() {
    // Create the Setting on the server.
    if (this.degreeScoreForm.valid) {
      this.isFormLoading = true;
      this.degreeScoreFormData = this.degreeScoreForm.value;
      // console.log('Degree score:', this.degreeScoreFormData);

      let formData: any = new FormData();
      //  Degree Score Form Data
      formData.append('sbf_almost_never', this.degreeScoreFormData.sbf_almost_never);
      formData.append('sbf_most_of_the_time', this.degreeScoreFormData.sbf_most_of_the_time);
      formData.append('sbf_never_always', this.degreeScoreFormData.sbf_never_always);
      formData.append('sbf_not_very_often', this.degreeScoreFormData.sbf_not_very_often);
      formData.append('sbf_some_of_the_time', this.degreeScoreFormData.sbf_some_of_the_time);
      formData.append('sba_clear_strength', this.degreeScoreFormData.sba_clear_strength);
      formData.append('sba_clear_weakness', this.degreeScoreFormData.sba_clear_weakness);
      formData.append('sba_good', this.degreeScoreFormData.sba_good);
      formData.append('sba_not_very_good', this.degreeScoreFormData.sba_not_very_good);
      formData.append('sba_very_good', this.degreeScoreFormData.sba_very_good);
      formData.append('sbe_1_to_20', this.degreeScoreFormData.sbe_1_to_20);
      formData.append('sbe_21_to_40', this.degreeScoreFormData.sbe_21_to_40);
      formData.append('sbe_41_to_60', this.degreeScoreFormData.sbe_41_to_60);
      formData.append('sbe_61_to_80', this.degreeScoreFormData.sbe_61_to_80);
      formData.append('sbe_81_to_100', this.degreeScoreFormData.sbe_81_to_100);

      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateMaximiserSettings(formData, this.maximiserSettingId);
      message = '360 Degree Settings has been updated.';

      subscriber.subscribe(res => {
        this.isFormLoading = false;

        // Self data handler
        if (!this.selfData['id']) {
          this.appService.createSettings({
            key: [this.selfData['key']],
            value: this.degreeScoreForm.value.self_notify_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.selfData['id'],
            key: [this.selfData['key']],
            value: this.degreeScoreForm.value.self_notify_message
          }).subscribe(res => {
          });
        }

        // Manager data Handler
        if (!this.managerData['id']) {
          this.appService.createSettings({
            key: [this.managerData['key']],
            value: this.degreeScoreForm.value.manager_notify_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.managerData['id'],
            key: [this.managerData['key']],
            value: this.degreeScoreForm.value.manager_notify_message
          }).subscribe(res => {
          });
        }

        // Peer data Setup
        if (!this.peerData['id']) {
          this.appService.createSettings({
            key: [this.peerData['key']],
            value: this.degreeScoreForm.value.peer_notify_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.peerData['id'],
            key: [this.peerData['key']],
            value: this.degreeScoreForm.value.peer_notify_message
          }).subscribe(res => {
          });
        }

        // Sub ordinate data Setup
        if (!this.subOrdinateData['id']) {
          this.appService.createSettings({
            key: [this.subOrdinateData['key']],
            value: this.degreeScoreForm.value.subordinate_notify_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.subOrdinateData['id'],
            key: [this.subOrdinateData['key']],
            value: this.degreeScoreForm.value.subordinate_notify_message
          }).subscribe(res => {
          });
        }
        // Reviwer message

        if (!this.reviewerData['id']) {
          this.appService.createSettings({
            key: [this.reviewerData['key']],
            value: this.degreeScoreForm.value.reviewer_notify_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.reviewerData['id'],
            key: [this.reviewerData['key']],
            value: this.degreeScoreForm.value.reviewer_notify_message
          }).subscribe(res => {
          });
        }


        // Self Definition handler
        if (!this.selfDefinition['id']) {
          this.appService.createSettings({
            key: [this.selfDefinition['key']],
            value: this.degreeScoreForm.value.self_definition_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.selfDefinition['id'],
            key: [this.selfDefinition['key']],
            value: this.degreeScoreForm.value.self_definition_message
          }).subscribe(res => {
          });
        }


        // Peer Definition handler
        if (!this.peerDefinition['id']) {
          this.appService.createSettings({
            key: [this.peerDefinition['key']],
            value: this.degreeScoreForm.value.peer_definition_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.peerDefinition['id'],
            key: [this.peerDefinition['key']],
            value: this.degreeScoreForm.value.peer_definition_message
          }).subscribe(res => {
          });
        }


        // Manager Definition handler
        if (!this.managerDefinition['id']) {
          this.appService.createSettings({
            key: [this.managerDefinition['key']],
            value: this.degreeScoreForm.value.manager_definition_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.managerDefinition['id'],
            key: [this.managerDefinition['key']],
            value: this.degreeScoreForm.value.manager_definition_message
          }).subscribe(res => {
          });
        }


        // Subordinate Definition handler
        if (!this.subOrdinateDefinition['id']) {
          this.appService.createSettings({
            key: [this.subOrdinateDefinition['key']],
            value: this.degreeScoreForm.value.subordinate_definition_message
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.subOrdinateDefinition['id'],
            key: [this.subOrdinateDefinition['key']],
            value: this.degreeScoreForm.value.subordinate_definition_message
          }).subscribe(res => {
          });
        }

          // Reviewer Definition handler
          if (!this.reviewerDefinition['id']) {
            this.appService.createSettings({
              key: [this.reviewerDefinition['key']],
              value: this.degreeScoreForm.value.reviewer_definition_message
            }).subscribe(res => {
            });
          } else {
            this.appService.updateSettings({
              id: this.reviewerDefinition['id'],
              key: [this.reviewerDefinition['key']],
              value: this.degreeScoreForm.value.reviewer_definition_message
            }).subscribe(res => {
            });
          }


        // restrict_360_manager handler
        // console.log('restrict_360_manager', this.degreeScoreForm.value.restrict_360_manager);
        if (!this.restrict_360_manager['id']) {
          this.appService.createSettings({
            key: [this.restrict_360_manager['key']],
            value: this.degreeScoreForm.value.restrict_360_manager === true ? 'true' : 'false'
          }).subscribe(res => {
          });
        } else {
          this.appService.updateSettings({
            id: this.restrict_360_manager['id'],
            key: [this.restrict_360_manager['key']],
            value: this.degreeScoreForm.value.restrict_360_manager === true ? 'true' : 'false'
          }).subscribe(res => {
          });
        }


        this.message.create('success', message, {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  submitBadgesForm() {
    // Create the Setting on the server.
    if (this.badgesForm.valid) {
      this.isFormLoading = true;
      this.badgesFormData = this.badgesForm.value;

      // console.log('badgesForm:', this.badgesFormData);
      let formData: any = new FormData();
      // Badges Form Data
      formData.append('badges_setup_level_1_name', this.badgesFormData.badges_setup_level_1_name);
      formData.append('badges_setup_level_2_name', this.badgesFormData.badges_setup_level_2_name);
      formData.append('badges_setup_level_3_name', this.badgesFormData.badges_setup_level_3_name);
      formData.append('badges_setup_level_1_score', this.badgesFormData.badges_setup_level_1_score);
      formData.append('badges_setup_level_2_score', this.badgesFormData.badges_setup_level_2_score);
      formData.append('badges_setup_level_3_score', this.badgesFormData.badges_setup_level_3_score);
      let subscriber = null;
      let message = null;
      subscriber = this.appService.updateMaximiserSettings(formData, this.maximiserSettingId);
      message = 'Badge Settings has been updated.';

      subscriber.subscribe(res => {
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  submitAssessmentSettingForm() {
    if (this.assessmentSettingForm.valid) {
      this.isFormLoading = true;
      let formData: any = new FormData();
      // assessment Setting Form Data
      formData.append('max_activities_la', this.assessmentSettingForm.value.max_activities_la);
      formData.append('max_activities_assessment_centre', this.assessmentSettingForm.value.max_activities_assessment_centre);
      formData.append('max_score_la', this.assessmentSettingForm.value.max_score_la);
      formData.append('max_farf', this.assessmentSettingForm.value.max_farf);
      formData.append('max_farf_duration', this.assessmentSettingForm.value.max_farf_duration);

      this.appService.updateMaximiserSettings(formData, this.maximiserSettingId).subscribe(res => {
        this.isFormLoading = false;

        this.message.create('success', 'Assessment Settings has been updated.', {
          nzDuration: 5000
        });
      }, error => {
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  maximumDesiredSubmit() {
    console.warn(this.MaxDesiredsettingForm.controls['id'].value);
    const element = this.MaxDesiredsettingForm.controls;
    let errorFlag = false;
    let errorMessage = '';
    let subscribe;
    if (element['id'].value) {
      subscribe = this.appService.updateSettings({id: element['id'].value, key: element['key'].value, value: element['value'].value});
    } else {
      subscribe = this.appService.createSettings({key: element['key'].value, value: element['value'].value});
    }

    errorFlag = false;
    subscribe.subscribe((res) => {
      this.isFormLoading = false;
    }, error => {
      errorMessage = error.error.message;
      errorFlag = true;
      this.isFormLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });


    });

    if (!errorFlag) {
      this.message.create('success', 'Max Desired score has been updated.', {nzDuration: 5000});
    }
  }

  keyIsTrue = false;

  nineBoxSubmit() {
    // console.warn(this.nineBoxSettingGroup.controls['id'].value);
    let errorFlag = false;
    let errorMessage = '';
    let subscribe;

    if (this.nineBoxSettingGroup.value.potentialLevels.filter((key) => key.key === 'low-potential')[0].value >= this.nineBoxSettingGroup.value.potentialLevels.filter((key) => key.key === 'medium-potential')[0].value) {

      errorMessage = 'low-potential need to be less than medium-potential';
      errorFlag = true;
      this.isFormLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage
      });
    } else if (this.nineBoxSettingGroup.value.performanceLevels.filter((key) => key.key === 'low-performance')[0].value >= this.nineBoxSettingGroup.value.performanceLevels.filter((key) => key.key === 'medium-performance')[0].value) {
      errorMessage = 'low-performance need to be less than medium-performance';
      errorFlag = true;
      this.isFormLoading = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage
      });
    } else {

      // potentialLevels submit levels.forEach
      this.nineBoxSettingGroup.value.potentialLevels.forEach((element) => {

        if (element.key != 'maximum-potential') {
          if (element.id) {
            subscribe = this.appService.updateSettings({id: element.id, key: element.key, value: element.value});
          } else {
            subscribe = this.appService.createSettings({key: element.key, value: element.value});
          }
          subscribe.subscribe((res) => {
            this.isFormLoading = false;
          }, error => {

            errorFlag = true;
            this.isFormLoading = false;

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });
          });
        }

      });


      this.nineBoxSettingGroup.value.performanceLevels.forEach((element) => {
        if (element.key != 'maximum-performance') {
          if (element.id) {
            subscribe = this.appService.updateSettings({id: element.id, key: element.key, value: element.value});
            errorMessage = 'Updated successfully';
          } else {
            subscribe = this.appService.createSettings({key: element.key, value: element.value});
            errorMessage = 'Created successfully';
          }
          subscribe.subscribe((res) => {
            this.keyIsTrue = true;
            this.isFormLoading = false;
          }, error => {

            errorFlag = true;
            this.isFormLoading = false;

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });
          });
        }

      });
      if (this.keyIsTrue) {
        this.message.create('success', errorMessage, {
          nzDuration: 5000
        });
      }
    }


    // End potentialLevels submit
    // performanceLevels submit


    // End performanceLevels submit


  }

  maximumPerformanceSubmit() {
    let errorFlag = false;
    let errorMessage = '';
    let subscribe;
    let element = this.PerformanceMaxScoreForm.value;

    // console.log(element);
    if (element.id) {
      subscribe = this.appService.updateSettings({id: element.id, key: element.key, value: element.value});
    } else {
      subscribe = this.appService.createSettings({key: element.key, value: element.value});
    }
    subscribe.subscribe((res) => {
      this.isFormLoading = false;
    }, error => {

      errorFlag = true;
      this.isFormLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });
    });

  }

  // Submitting Desired Level Form
  submitDesiredLevelForm() {
    // Check whether any one is selected

    if (this.CompetencyLevelDeleteArray.length > 0) {
      this.CompetencyLevelDeleteArray.forEach((f) => {
        this.new_competency_count -+ 1;
        this.appService.deleteLevelSettings(f).subscribe((res) => {

        });
      });

    }


    const found = this.desiredLevelArray.value.some(element => element.is_select);

    // if not selected any level
    if (!found) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Desired score has to be attached to one of the levels by checking the box'
      });
      return;
    }


    // If anything selected and form is valid
    if (this.desiredLevelForm.valid) {

      let errorFlag = false;
      let errorMessage = '';
      this.desiredLevelForm.value.levels.forEach(element => {
        let subscribe;
        if (element.id) {
          subscribe = this.appService.updateLevelSettings(element);
        } else {
          subscribe = this.appService.createLevelSettings(element);
        }
        subscribe.subscribe((res) => {
          this.isFormLoading = false;
        }, error => {

          errorFlag = true;
          this.isFormLoading = false;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        });

      });

      if (!errorFlag) {
        this.message.create('success', 'Competency Level has been updated.', {
          nzDuration: 5000
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage
        });
      }

    }
  }

  submitLoading = false;
    // Submitting Behaviour level  Form
    submitBehaviourLevelForm() {
      // Check whether any one is selected

      // if (this.BehaviourLevelDeleteArray.length > 0) {
      //   this.BehaviourLevelDeleteArray.forEach((f) => {
      //     this.appService.deleteBehaviourLevelSettings(f).subscribe((res) => {
      //       this.BehaviourLevelDeleteArray = [];
      //     },err=>{
      //       this.BehaviourLevelDeleteArray = [];
      //     });
      //   });

      // }


      // const found = this.desiredBLevelArray.value.some(element => element.is_select);

      // // if not selected any level
      // if (!found) {

      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: 'Please select one Level to continue.'
      //   });
      //   return;
      // }


      // If anything selected and form is valid
      if (this.behaviourLevelForm.valid) {
        this.submitLoading = true;
        let errorFlag = false;
        let errorMessage = '';
        this.behaviourLevelForm.value.behaviourlevels.forEach(element => {
          let subscribe;
          if (element.id) {
            subscribe = this.appService.updatebehaviourLevelSettings(element);
          } else {
            subscribe = this.appService.behaviourLevelSettings(element);
          }
          subscribe.subscribe((res) => {
            this.submitLoading = false;
            this.isFormLoading = false;
          }, error => {

            errorFlag = true;
            this.isFormLoading = false;

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });
          });

        });

        if (!errorFlag) {
          this.message.create('success', 'Behaviour Level has been updated.', {
            nzDuration: 5000
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMessage
          });
        }

      }
    }


  submitTimeZoneForm() {
    if (this.timeZoneForm.valid) {
      let errorFlag = false;
      let errorMessage = '';
      let message = '';
      let subscribe;
      let element = this.timeZoneForm.value;

      // console.log(element.id);
      if (element.id !== null) {
        subscribe = this.appService.updateSettings({id: element.id, key: element.key, value: element.value});
        message = 'Time Zone Added Successfully';
      } else {
        subscribe = this.appService.createSettings({key: element.key, value: element.value});
        message = 'Time Zone Updated Successfully';
      }
      subscribe.subscribe((res) => {
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.isFormLoading = false;
      }, error => {

        errorFlag = true;
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }


  submitPrivacyForm() {
    let keyValueName = 'Privacy Policy';
    this.keyValueGeneralSettingsSubmit(this.privacyForm, keyValueName);
  }

  submitTermsForm() {
    let keyValueName = 'Terms & Conditions';
    this.keyValueGeneralSettingsSubmit(this.termsForm, keyValueName);
  }

  keyValueGeneralSettingsSubmit(form, keyValueName) {
    let errorFlag = false;
    let message = '';
    let subscribe;
    let element = form.value;

    // console.log(element.id);
    if (element.id !== null) {
      subscribe = this.appService.updateSettings({id: element.id, key: element.key, value: btoa(unescape(encodeURIComponent(element.value)))});
      message = keyValueName + ' Update Successfully';
    } else {
      subscribe = this.appService.createSettings({key: element.key, value: btoa(unescape(encodeURIComponent(element.value)))});
      message = keyValueName + ' Added Successfully';
    }
    subscribe.subscribe((res) => {
      this.message.create('success', message, {
        nzDuration: 5000
      });
      this.isFormLoading = false;
    }, error => {

      errorFlag = true;
      this.isFormLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });
    });
  }



  boxReset() {
    this.initNineBoxSettingForm();
    this.appService.getSettings(['maximum-desired-score']).subscribe((res) => {
      if (res['items'].length) {
        this.maximumDesiredScore = res['items'][0].value;
      }
    });

    this.appService.getSettings(['low-potential', 'medium-potential']).subscribe((res) => {
      if (res['items'].length) {
        // low-potential push
        from(res['items']).pipe(filter(item => item['key'] === 'low-potential'), map((item) => {
          return {
            id: item['id'], key: item['key'], value: item['value'], caption: 'Low Potential', icon: '<=', status: 'false'
          };
        })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


        // medium-potential push
        from(res['items']).pipe(filter(item => item['key'] === 'medium-potential'), map((item) => {
          return {
            id: item['id'], key: item['key'], value: item['value'], caption: 'Medium Potential', icon: '<=', status: 'false'
          };
        })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


        // Maximum-potential push
        from(res['items']).pipe(filter(item => item['key'] === 'medium-potential'), map((item) => {
          return {
            id: null, key: 'maximum-potential', value: item['value'], caption: 'Maximum Potential', icon: '>', status: 'true'
          };
        })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


        // high-potential push
        // from(res['items']).pipe(filter(item => item['key'] === 'high-potential'), map((item) => {
        //   return {
        //     id: item['id'], key: item['key'], value: item['value'], caption: 'High Potential', icon: '>'
        //   };
        // })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


      } else {

        this.potentialLevels.push(this.nineBoxItem({
          id: null, key: 'low-potential', value: 3, caption: 'Low Potential', icon: '<=', status: 'false'
        }));

        this.potentialLevels.push(this.nineBoxItem({
          id: null, key: 'medium-potential', value: 6, caption: 'Medium Potential', icon: '<=', status: 'false'
        }));


        this.potentialLevels.push(this.nineBoxItem({
          id: null, key: 'maximum-potential', value: 6, caption: 'Maximum Potential', icon: '>', status: 'true'
        }));

        // this.potentialLevels.push(this.nineBoxItem({
        //   id: null, key: 'high-potential', value: 8, caption: 'High Potential', icon: '>'
        // }));
      }
    });
    this.appService.getSettings(['low-performance', 'medium-performance']).subscribe((res) => {
      if (res['items'].length) {
        // low-potential push
        from(res['items']).pipe(filter(item => item['key'] === 'low-performance'), map((item) => {
          return {
            id: item['id'], key: item['key'], value: item['value'], caption: 'Low Performance', icon: '<=', status: 'false'
          };
        })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


        // medium-potential push
        from(res['items']).pipe(filter(item => item['key'] === 'medium-performance'), map((item) => {
          return {
            id: item['id'], key: item['key'], value: item['value'], caption: 'Medium Performance', icon: '<=', status: 'false'
          };
        })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


        // maximum-potential push
        from(res['items']).pipe(filter(item => item['key'] === 'medium-performance'), map((item) => {
          return {
            id: null, key: 'maximum-performance', value: item['value'], caption: 'Maximum Performance', icon: '>', status: 'true'
          };
        })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


        // high-potential push
        // from(res['items']).pipe(filter(item => item['key'] === 'high-performance'), map((item) => {
        //   return {
        //     id: item['id'], key: item['key'], value: item['value'], caption: 'High Performance', icon: '>'
        //   };
        // })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


      } else {
        this.performanceLevels.push(this.nineBoxItem({
          id: null, key: 'low-performance', value: 0, caption: 'Low Performance', icon: '<=', status: 'false'
        }));

        this.performanceLevels.push(this.nineBoxItem({
          id: null, key: 'medium-performance', value: 0, caption: 'Medium Performance', icon: '<=', status: 'false'
        }));

        this.performanceLevels.push(this.nineBoxItem({
          id: null, key: 'maximum-performance', value: 0, caption: 'Maximum Performance', icon: '>', status: 'true'
        }));

        // this.performanceLevels.push(this.nineBoxItem({
        //   id: null, key: 'high-performance', value: 0, caption: 'High Potential', icon: '>'
        // }));
      }
    });
  }

  // tab related
  // onCurrentIndexChange(current: number): void {
  //   this.current = current;
  //   this.changeContent();
  // }
  // pre(): void {
  //   this.current -= 1;
  //   this.changeContent();
  // }
  // next(): void {
  //   this.current += 1;
  //   this.changeContent();
  // }
  // done(): void {
  //   console.log('done');
  // }
  // changeContent(): void {
  //   switch (this.current) {
  //     case 1: {
  //       this.contentIndex = 1;
  //       // PM
  //       // this.profitMaximiserForm.patchValue(this.formValues);
  //       break;
  //     }
  //     case 2: {
  //       this.contentIndex = 2;
  //       // PS
  //       // this.pointsStructureForm.patchValue(this.formValues);
  //       break;
  //     }
  //     case 3: {
  //       this.contentIndex = 3;
  //       // deg
  //       // this.degreeScoreForm.patchValue(this.formValues);
  //       break;
  //     }
  //     case 4: {
  //       this.contentIndex = 4;
  //       break;
  //     }
  //     default: {
  //       this.contentIndex = 0;
  //     }
  //   }
  // }
  spacingSubmit() {
    if (this.spacingSettingGroup.valid) {
      let message = '';
      let subscribers = [];
      let keyValueName = "Spacing"
      this.isFormLoading = true;
      if(this.isValidKey){
        this.spacingKeyValue.forEach(item => {
          if(item.key === "spacing_days"){
            item.value = this.spacingSettingGroup.value.spacing_days;
          }else if(item.key === "spacing_within_a_round"){
            item.value = this.spacingSettingGroup.value.spacing_within_a_round;
          }else if(item.key === "no_of_questions_per_round"){
            item.value = this.spacingSettingGroup.value.no_of_questions_per_round;
          }else if(item.key === "start_time_of_question"){
            item.value = this.spacingSettingGroup.value.start_time_of_question;
          }else if(item.key === "no_of_reattempt_round"){
            item.value = this.spacingSettingGroup.value.no_of_reattempt_round;
          }
        });
      }else{
        this.spacingKeyValue = [{id:null,key:'spacing_days',value:this.spacingSettingGroup.value.spacing_days},
          {id:null,key:'no_of_questions_per_round',value:this.spacingSettingGroup.value.no_of_questions_per_round},
          {id:null,key:'spacing_within_a_round',value:this.spacingSettingGroup.value.spacing_within_a_round},
          {id:null,key:'start_time_of_question',value:this.spacingSettingGroup.value.start_time_of_question},
          {id:null,key:'no_of_reattempt_round',value:this.spacingSettingGroup.value.no_of_reattempt_round}]
      }

      // Spacing Setting Form Data
      this.spacingKeyValue.forEach(ele => {
        if (ele.id !== null) {
          subscribers.push(this.appService.updateSettings(ele));
          message = 'Spacing Updated successfully.';
        } else {
          delete ele.id
          subscribers.push(this.appService.createSettings(ele));
          message = 'Spacing Added successfully.';
        }
      });
      forkJoin(subscribers).pipe(
        map((response) => {

        })
      ).subscribe(res => {

        this.message.create(
          'success',
          message,
          {
            nzDuration: 3000,
          }
        );
        this.getSpacingData();
        this.isFormLoading = false;
      }, error => {
        this.isFormLoading = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  spacingReset(){
    this.resetloading = true;
    this.spacingFormGroup();
    this.getSpacingData();
  }

  time: Date | null = null;

  log(value: Date): void {
    // console.log(value);
  }

  async onTabChange(id) {
    this.current = id;


    switch (this.current) {
      case 1: { // PM
        await this.getAllMaximiserSettings();
        this.contentIndex = 1;
        this.profitMaximiserForm.patchValue(this.formValues);
        this.avatarUrl = this.formValues?.image?.original_image_url;
        this.themeColor = this.formValues.theme_colour;
        this.tile_1_color = this.formValues.tile_range_1_color;
        this.tile_2_color = this.formValues.tile_range_2_color;
        this.tile_3_color = this.formValues.tile_range_3_color;
        this.tile_4_color = this.formValues.tile_range_4_color;
        this.tile_5_color = this.formValues.tile_range_5_color;
        // console.log('PM', this.formValues);
        // Tile Configuration colors related
        this.onChangeEffortMoreThanTargetColor();
        this.onChangeEffortLessThanTargetColor();
        this.onChangeTileRange3Color();
        this.onChangeTileRange2Color();
        this.onChangeTileRange1Color();
        this.onChangeThemeColor();
        break;
      }
      case 2: {// PS
        await this.getAllMaximiserSettings();
        this.contentIndex = 2;
        this.pointsStructureForm.patchValue(this.formValues);
        // console.log('PS', this.formValues);
        break;
      }
      case 3: { // deg
        await this.getAllMaximiserSettings();
        this.contentIndex = 3;
        await this.getNotificationData();
        this.degreeScoreForm.patchValue(this.formValues);
        // console.log('DEG', this.formValues);
        break;
      }
      case 4: {
        await this.getAllMaximiserSettings();
        this.contentIndex = 4;
        this.badgesForm.patchValue(this.formValues);
        // console.log('BEG', this.formValues);
        break;
      }
      case 5: {
        // await this.getAllMaximiserSettings();
        this.contentIndex = 5;
        // this.behaviorTransactionForm.patchValue(this.formValues);
        // console.log('BTS', this.formValues);
        this.initBehaviourLevelForm();
        await this.appService.getBehaviourLevelSettings().subscribe((res) => {
          if (res['items'].length) {
            this.desiredBLevelArray.removeAt(0);
            res['items'].forEach(element => {
              this.desiredBLevelArray.push(this.getCustomPatch(element));
            });
          }
        });
        break;
      }
      case 6: {
        this.contentIndex = 6;
        this.initAssessmentSettingForm();
        this.assessmentSettingForm.patchValue(this.formValues);
        break;
      }
      case 7: {
        this.contentIndex = 7;
        this.initDesiredLevelForm();
        await this.appService.getLevelSettings().subscribe((res) => {
          if (res['items'].length) {
            this.desiredLevelArray.removeAt(0);
            res['items'].forEach(element => {
              this.desiredLevelArray.push(this.getCustomPatch(element));
            });
          }
        });

        await this.appService.getSettings(['maximum-desired-score']).subscribe((res) => {
          if (res['items'].length) {
            this.MaxDesiredsettingForm.patchValue(res['items'][0]);

          }
        });

        break;
      }
      case 8: {
        this.contentIndex = 8;
        this.initNineBoxSettingForm();
        this.appService.getSettings(['maximum-desired-score']).subscribe((res) => {
          if (res['items'].length) {
            this.maximumDesiredScore = res['items'][0].value;
          }
        });

        this.appService.getSettings(['low-potential', 'medium-potential']).subscribe((res) => {
          if (res['items'].length) {
            // low-potential push
            from(res['items']).pipe(filter(item => item['key'] === 'low-potential'), map((item) => {
              return {
                id: item['id'], key: item['key'], value: item['value'], caption: 'Low Potential', icon: '<=', status: 'false'
              };
            })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


            // medium-potential push
            from(res['items']).pipe(filter(item => item['key'] === 'medium-potential'), map((item) => {
              return {
                id: item['id'], key: item['key'], value: item['value'], caption: 'Medium Potential', icon: '<=', status: 'false'
              };
            })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


            // Maximum-potential push
            from(res['items']).pipe(filter(item => item['key'] === 'medium-potential'), map((item) => {
              return {
                id: null, key: 'maximum-potential', value: item['value'], caption: 'Maximum Potential', icon: '>', status: 'true'
              };
            })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


            // high-potential push
            // from(res['items']).pipe(filter(item => item['key'] === 'high-potential'), map((item) => {
            //   return {
            //     id: item['id'], key: item['key'], value: item['value'], caption: 'High Potential', icon: '>'
            //   };
            // })).subscribe(val => this.potentialLevels.push(this.nineBoxItem(val)));


          } else {

            this.potentialLevels.push(this.nineBoxItem({
              id: null, key: 'low-potential', value: 3, caption: 'Low Potential', icon: '<=', status: 'false'
            }));

            this.potentialLevels.push(this.nineBoxItem({
              id: null, key: 'medium-potential', value: 6, caption: 'Medium Potential', icon: '<=', status: 'false'
            }));


            this.potentialLevels.push(this.nineBoxItem({
              id: null, key: 'maximum-potential', value: 6, caption: 'Maximum Potential', icon: '>', status: 'true'
            }));

            // this.potentialLevels.push(this.nineBoxItem({
            //   id: null, key: 'high-potential', value: 8, caption: 'High Potential', icon: '>'
            // }));
          }
        });
        this.appService.getSettings(['low-performance', 'medium-performance']).subscribe((res) => {
          if (res['items'].length) {
            // low-potential push
            from(res['items']).pipe(filter(item => item['key'] === 'low-performance'), map((item) => {
              return {
                id: item['id'], key: item['key'], value: item['value'], caption: 'Low Performance', icon: '<=', status: 'false'
              };
            })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


            // medium-potential push
            from(res['items']).pipe(filter(item => item['key'] === 'medium-performance'), map((item) => {
              return {
                id: item['id'], key: item['key'], value: item['value'], caption: 'Medium Performance', icon: '<=', status: 'false'
              };
            })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


            // maximum-potential push
            from(res['items']).pipe(filter(item => item['key'] === 'medium-performance'), map((item) => {
              return {
                id: null, key: 'maximum-performance', value: item['value'], caption: 'Maximum Performance', icon: '>', status: 'true'
              };
            })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


            // high-potential push
            // from(res['items']).pipe(filter(item => item['key'] === 'high-performance'), map((item) => {
            //   return {
            //     id: item['id'], key: item['key'], value: item['value'], caption: 'High Performance', icon: '>'
            //   };this.isValidKey
            // })).subscribe(val => this.performanceLevels.push(this.nineBoxItem(val)));


          } else {
            this.performanceLevels.push(this.nineBoxItem({
              id: null, key: 'low-performance', value: 0, caption: 'Low Performance', icon: '<=', status: 'false'
            }));

            this.performanceLevels.push(this.nineBoxItem({
              id: null, key: 'medium-performance', value: 0, caption: 'Medium Performance', icon: '<=', status: 'false'
            }));

            this.performanceLevels.push(this.nineBoxItem({
              id: null, key: 'maximum-performance', value: 0, caption: 'Maximum Performance', icon: '>', status: 'true'
            }));

            // this.performanceLevels.push(this.nineBoxItem({
            //   id: null, key: 'high-performance', value: 0, caption: 'High Potential', icon: '>'
            // }));
          }
        });


        // this.assessmentSettingForm.patchValue(this.formValues);
        break;
      }
      case 9: {
        this.contentIndex = 9;
        this.initPerformanceMaxScoreForm();
        this.appService.getSettings(['performance-max-score', 'maximum-desired-score']).subscribe((res: any) => {
          // console.log('items', res);
          this.PerformanceMaxScoreFormDataNew = res['items'];
          this.patchPerformanceMaxScoreForm();
        });
        break;
      }

      case 10: {
        this.initTimeZoneForm();
        await this.getTimeZoneData();
        this.appService.getDefaultTenantTime().subscribe(res => {
          if (res['items'].length) {
            this.timeZoneForm.setValue({id: res['items'][0].id, key: res['items'][0].key, value: +res['items'][0].value});
          }
        });
        this.contentIndex = 10;
        break;
      }

      case 11: {
        this.initPrivacyForm();
        let key = ['privacy-policy'];
        let policyData = await this.getPrivacyOrTerms(key);
        // console.log('The data  is : ', policyData);
        if (policyData['items'].length) {
          let finalPData = policyData['items'][0]?.value?policyData['items'][0]?.value :""
          policyData['items'][0].value = decodeURIComponent(atob(finalPData));
          this.privacyForm.patchValue(policyData['items'][0]);
        }
        this.contentIndex = 11;
        break;
      }

      case 12: {
        this.initTermsForm();
        let key = ['terms-of-service'];
        let termsData = await this.getPrivacyOrTerms(key);
        if (termsData['items'].length) {
          let finalData = termsData['items'][0]?.value?termsData['items'][0]?.value :""
          termsData['items'][0].value = decodeURIComponent(atob(finalData));
          this.termsForm.patchValue(termsData['items'][0]);
        }

        this.contentIndex = 12;
        break;
      }

      case 13: {
        this.contentIndex = 13;
        this.initPrematureClosureAndDeadlineExtendForm();

        await this.appService.getAllCategories('premature_closure').subscribe((res: []) => {
          if (res.length > 0) {
            // console.log(res);
            this.prematureClosureCategoryArray.removeAt(0);
            res.forEach(element => {
              this.prematureClosureCategoryArray.push(this.getCustomCategoryPatch(element));
            });
            // console.log('ayya', this.prematureClosureCategoryArray);
          }
        });

        await this.appService.getAllCategories('deadline_extend').subscribe((res: []) => {
          if (res.length > 0) {
            // console.log(res);
            this.deadlineExtendCategoryArray.removeAt(0);
            res.forEach(element => {
              this.deadlineExtendCategoryArray.push(this.getCustomCategoryPatch(element));
            });
            // console.log('ayya', this.deadlineExtendCategoryArray);
          }
        });

        break;
      }

      case 14: {
        this.contentIndex = 14;
        this.spacingFormGroup();
        this.getSpacingData();
        break;
      }
      case 15: {
        this.contentIndex = 15;
        this.ExpectedWrongQuestionsFormGroup();
        this.getSpacingExpectedData();
        break;
      }
      case 16: {
        this.contentIndex = 16;
        this.appService.getTelantCardStatus().subscribe(res => {
          // console.log('Status',res)
          this.talentCardStatus = res.talent_card_status
          this.talentStatusForm.get('talentStatus').patchValue(this.talentCardStatus)
        })
        break;
      }

      case 17:{
        this.contentIndex = 17
        this.appService.getSettings(['360_report_threshold_improvement', '360_report_threshold_strength']).subscribe((res: any) => {
          // console.log('items', res);
          this.ReportThresholdDataNew = res['items'];
          this.patchReportThresholdForm();
        });
        break;
      }

      case 18:{
        this.contentIndex = 18
        this.successionManagementSettings()

        // this.addRowCL()
        // this.addRowRP()

        // this.getGradeList()
        this.loadMangerialLevelSM()
        break;
      }

      default: {
        await this.getAllMaximiserSettings();
        this.contentIndex = 0;
        this.generalForm.patchValue(this.formValues);
        // console.log('GEN', this.formValues);
      }
    }
  }
  isStatusFormLoading =false
  statusSubmit(){
    if(this.talentStatusForm.valid){
      this.isStatusFormLoading = true;
      let payload = {
        "talent_card_status":this.talentStatusForm.value.talentStatus
      }
      this.appService.postTelantCardStatus(payload).subscribe(res => {
        // console.log('Status',res)
        this.isStatusFormLoading = false;
        this.message.create(
          'success',
          'Talent Card Status added/updated successfully',
          {
            nzDuration: 3000,
          }
        );
        window.location.reload();
      },err=>{
        this.isStatusFormLoading = false;
      })
    }
  }

  spacingExpectedSubmit() {
    if (this.ExpectedWrongQuestionsSettingGroup.valid) {
      let message = '';
      let subscribers = [];
      this.isFormLoading = true;
      if(this.isExpectedValidKey){
        this.spacingExpectedKeyValue.forEach(item => {
          if(item.key === "best_case_scenario"){
            item.value = this.ExpectedWrongQuestionsSettingGroup.value.best_case_scenario;
          }else if(item.key === "medium_case_scenario"){
            item.value = this.ExpectedWrongQuestionsSettingGroup.value.medium_case_scenario;
          }else if(item.key === "worst_case_scenario"){
            item.value = this.ExpectedWrongQuestionsSettingGroup.value.worst_case_scenario;
          }
        });
      }else{
        this.spacingExpectedKeyValue = [{id:null,key:'best_case_scenario',value:this.ExpectedWrongQuestionsSettingGroup.value.best_case_scenario},
          {id:null,key:'medium_case_scenario',value:this.ExpectedWrongQuestionsSettingGroup.value.medium_case_scenario},
          {id:null,key:'worst_case_scenario',value:this.ExpectedWrongQuestionsSettingGroup.value.worst_case_scenario}]
      }

      // Spacing Setting Form Data
      this.spacingExpectedKeyValue.forEach(ele => {
        if (ele.id !== null) {
          subscribers.push(this.appService.updateSettings(ele));
          message = 'Expected % of Wrong Questions Updated successfully.';
        } else {
          delete ele.id
          subscribers.push(this.appService.createSettings(ele));
          message = 'Expected % of Wrong Questions Added successfully.';
        }
      });
      forkJoin(subscribers).pipe(
        map((response) => {

        })
      ).subscribe(res => {

        this.message.create(
          'success',
          message,
          {
            nzDuration: 3000,
          }
        );
        this.getSpacingExpectedData();
        this.isFormLoading = false;
      }, error => {
        this.isFormLoading = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    }
  }

  spacingExpectedReset(){
    this.resetloading = true;
    this.ExpectedWrongQuestionsFormGroup();
    this.getSpacingExpectedData();
  }
  spacingData:any;
  isValidKey = false;
  getSpacingData(){
    this.appService.getSettings(['spacing_days','no_of_questions_per_round','spacing_within_a_round','start_time_of_question','no_of_reattempt_round']).subscribe(res => {
      this.spacingKeyValue = res['items'];
      this.resetloading = false;
    if(this.spacingKeyValue.length != 0){
        this.isValidKey = true;
      }else{
        this.isValidKey = false;
      }
      this.spacingKeyValue?.forEach(item => {
        if(item?.key === "spacing_days"){
          this.spacingSettingGroup.get('spacing_days').patchValue(item.value?item.value:null);
        }else if(item?.key === "spacing_within_a_round"){
          this.spacingSettingGroup.get('spacing_within_a_round').patchValue(item.value?item.value:null);
        }else if(item?.key === "no_of_questions_per_round"){
          this.spacingSettingGroup.get('no_of_questions_per_round').patchValue(item.value?item.value:null);
        }else if(item?.key === "start_time_of_question"){
          this.spacingSettingGroup.get('start_time_of_question').patchValue(item.value?item.value:null);
        }else if(item?.key === "no_of_reattempt_round"){
          this.spacingSettingGroup.get('no_of_reattempt_round').patchValue(item.value?item.value:null);
        }
      });
    });
  }

  isExpectedValidKey = false;
  resetloading = false;
  getSpacingExpectedData(){
    this.appService.getSettings(['best_case_scenario','medium_case_scenario','worst_case_scenario']).subscribe(res => {
    this.spacingExpectedKeyValue = res['items'];
    this.resetloading = false;
    if(this.spacingExpectedKeyValue.length != 0){
        this.isExpectedValidKey = true;
      }else{
        this.isExpectedValidKey = false;
      }
      this.spacingExpectedKeyValue?.forEach(item => {
        if(item?.key === "best_case_scenario"){
          this.ExpectedWrongQuestionsSettingGroup.get('best_case_scenario').patchValue(item.value?item.value:null);
        }else if(item?.key === "medium_case_scenario"){
          this.ExpectedWrongQuestionsSettingGroup.get('medium_case_scenario').patchValue(item.value?item.value:null);
        }else if(item?.key === "worst_case_scenario"){
          this.ExpectedWrongQuestionsSettingGroup.get('worst_case_scenario').patchValue(item.value?item.value:null);
        }
      });
    });
  }

  async getPrivacyOrTerms(key) {
    let keyData = await this.appService.getSettings(key).toPromise();
    return keyData;
  }

  getCustomPatch(element) {
    // console.log('Cutrrenbt : ', element);
    this.new_competency_count += 1
    // console.log("count comp", this.new_competency_count)
    return this.fb.group({
      id: [element.id],
      level_name: [element.level_name],
      is_select: [element.is_select],
      status: [element.status],
      order:[element.order],
    });
  }

  getNotificationData() {
    this.appService.getSettings([this.selfData['key'], this.managerData['key'], this.peerData['key'], this.subOrdinateData['key'], this.selfDefinition['key'], this.managerDefinition['key'], this.peerDefinition['key'], this.subOrdinateDefinition['key'], this.restrict_360_manager['key'],this.reviewerData['key'],this.reviewerDefinition['key']]).subscribe(res => {
      // console.log('The self an all result is : ', res);
      // storing items in key value
      res['items'].forEach((item) => this.setting_data.set(item.key, item));


      this.degreeScoreForm.patchValue({
        self_notify_message: this.setting_data.get(this.selfData['key'])?.value,
        manager_notify_message: this.setting_data.get(this.managerData['key'])?.value,
        peer_notify_message: this.setting_data.get(this.peerData['key'])?.value,
        subordinate_notify_message: this.setting_data.get(this.subOrdinateData['key'])?.value,
        reviewer_notify_message:this.setting_data.get(this.reviewerData['key'])?.value,

        self_definition_message: this.setting_data.get(this.selfDefinition['key'])?.value,
        manager_definition_message: this.setting_data.get(this.managerDefinition['key'])?.value,
        peer_definition_message: this.setting_data.get(this.peerDefinition['key'])?.value,
        subordinate_definition_message: this.setting_data.get(this.subOrdinateDefinition['key'])?.value,
        reviewer_definition_message: this.setting_data.get(this.reviewerDefinition['key'])?.value,

        restrict_360_manager: this.setting_data.get(this.restrict_360_manager['key'])?.value === 'true' ? true : false,
      });
      this.selfData['id'] = this.setting_data.get(this.selfData['key'])?.id;
      this.managerData['id'] = this.setting_data.get(this.managerData['key'])?.id;
      this.peerData['id'] = this.setting_data.get(this.peerData['key'])?.id;
      this.subOrdinateData['id'] = this.setting_data.get(this.subOrdinateData['key'])?.id;
      this.reviewerData['id']=this.setting_data.get(this.reviewerData['key'])?.id;

      this.selfDefinition['id'] = this.setting_data.get(this.selfDefinition['key'])?.id;
      this.managerDefinition['id'] = this.setting_data.get(this.managerDefinition['key'])?.id;
      this.peerDefinition['id'] = this.setting_data.get(this.peerDefinition['key'])?.id;
      this.subOrdinateDefinition['id'] = this.setting_data.get(this.subOrdinateDefinition['key'])?.id;
      this.reviewerDefinition['id']= this.setting_data.get(this.reviewerDefinition['key'])?.id;
      this.restrict_360_manager['id'] = this.setting_data.get(this.restrict_360_manager['key'])?.id;
    });
  }

  getAllMaximiserSettings() {
    this.appService.getMaximiserSettings().subscribe(res => {
      // console.log('the maximiser settings is : ', res['items']);

      this.formValues = res['items'][0];
      // console.log('maximiserSettingId:', this.formValues.id);
      this.maximiserSettingId = this.formValues.id;
      this.generalForm.patchValue(this.formValues);
      this.avatarUrl = this.formValues?.image?.original_image_url;
    });
  }

  // Tile Configuration colors related
  onChangeEffortMoreThanTargetColor() {
    this.tile_4_color = this.profitMaximiserForm.get('effort_more_than_target_color').value;
  }

  onChangeEffortLessThanTargetColor() {
    this.tile_5_color = this.profitMaximiserForm.get('effort_less_than_target_color').value;
  }

  onChangeTileRange3Color() {
    this.tile_3_color = this.profitMaximiserForm.get('tile_range_3_color').value;
  }

  onChangeTileRange2Color() {
    this.tile_2_color = this.profitMaximiserForm.get('tile_range_2_color').value;
  }

  onChangeTileRange1Color() {
    this.tile_1_color = this.profitMaximiserForm.get('tile_range_1_color').value;
  }

  onChangeThemeColor() {
    this.themeColor = this.profitMaximiserForm.get('theme_colour').value;
  }

  // theme logo upload related
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
    this.file = file;
    // return false;
    return true;
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }): void {
    this.getBase64(info.file!.originFileObj!, (img: string) => {
      this.loading = false;
      this.avatarUrl = img;
    });
  }


  performanceChange(performanceLevels: AbstractControl) {

    if (parseInt(performanceLevels.value.value) >= parseInt(this.maximumDesiredScore)) {
      this.isFormLoading = false;
      let errorMessage = performanceLevels.value.key + ' cant set greater than or equal to ' + this.maximumDesiredScore + ' (Maximum desired Score)';

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage
      });

    }


    if (performanceLevels.value.key === 'medium-performance' && performanceLevels.value.value > 0) {
      this.performanceLevels.controls.filter((f) => f.value.key === 'maximum-performance')[0].setValue({
        id: null,
        key: 'maximum-performance',
        value: performanceLevels.value.value,
        caption: 'Maximum Performance',
        icon: '>',
        status: 'true'
      });
    }
  }


  potentialChange(potentialLevels: AbstractControl) {

    if (parseInt(potentialLevels.value.value, 10) >= parseInt(this.maximumDesiredScore, 10)) {
      this.isFormLoading = false;
      let errorMessage = potentialLevels.value.key + ' cant set greater than or equal to ' + this.maximumDesiredScore + ' (Maximum desired Score)';

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage
      });
    }

    if (potentialLevels.value.key === 'medium-potential' && potentialLevels.value.value > 0) {
      this.potentialLevels.controls.filter((f) => f.value.key === 'maximum-potential')[0].setValue({
        id: null, key: 'maximum-potential', value: potentialLevels.value.value, caption: 'Maximum Potential', icon: '>', status: 'true'
      });
    }
  }

  category(category_type) {
    return this.fb.group({
      id: [null],
      category_name: [null, [Validators.required]],
      category_type: [category_type]
    });
  }

  initPrematureClosureAndDeadlineExtendForm() {
    this.prematureClosureForm = this.fb.group({
      categories: this.fb.array([this.category('premature_closure')])
    });

    this.deadlineExtendForm = this.fb.group({
      categories: this.fb.array([this.category('deadline_extend')])
    });
  }

  get prematureClosureCategoryArray() {
    return <FormArray> this.prematureClosureForm.get('categories');
  }

  get deadlineExtendCategoryArray() {
    return <FormArray> this.deadlineExtendForm.get('categories');
  }

  removeCategory(index, id, type) {
    // console.log(index, id, type);
    if (id) {
      this.appService.deleteCategory(id).subscribe();
    }
    if (type == 'premature_closure') {
      this.prematureClosureCategoryArray.removeAt(index);
    } else if (type == 'deadline_extend') {
      this.deadlineExtendCategoryArray.removeAt(index);
    }
  }

  addCategory(type) {
    if (type == 'premature_closure') {
      this.prematureClosureCategoryArray.push(this.category(type));
    } else if (type == 'deadline_extend') {
      this.deadlineExtendCategoryArray.push(this.category(type));
    }
  }

  submitCategoryForm(type) {
    if (type == 'premature_closure') {
      if (this.prematureClosureForm.valid) {
        // console.log('FORM DATA', this.prematureClosureForm.value.categories);
        this.prematureClosureForm.value.categories.forEach(element => {
          if (element.id == null) {
            this.appService.saveCategory({category_type: element.category_type, category_name: element.category_name}).subscribe(res => {
              // console.log('result', res);
              this.message.create('success', 'Premature Category Added', {
                nzDuration: 5000,
              });
            });
          } else {
            this.appService.updateCategory(element.id, {
              category_type: element.category_type,
              category_name: element.category_name
            }).subscribe(res => {
              // console.log('result', res);
              this.message.create('success', 'Premature Category Updated', {
                nzDuration: 5000,
              });
            });
          }
        });
      }
    } else if (type == 'deadline_extend') {
      if (this.deadlineExtendForm.valid) {
        // console.log('FORM DATA', this.deadlineExtendForm.value.categories);
        this.deadlineExtendForm.value.categories.forEach(element => {
          if (element.id == null) {
            this.appService.saveCategory({category_type: element.category_type, category_name: element.category_name}).subscribe(res => {
              // console.log('result', res);
              this.message.create('success', 'Deadline Category Added', {
                nzDuration: 5000,
              });
            });
          } else {
            this.appService.updateCategory(element.id, {
              category_type: element.category_type,
              category_name: element.category_name
            }).subscribe(res => {
              // console.log('result', res);
              this.message.create('success', 'Deadline Category Updated', {
                nzDuration: 5000,
              });
            });
          }
        });
      }
    }
  }

  getCustomCategoryPatch(element) {
    // console.log('Current : ', element);
    return this.fb.group({
      id: [element.id],
      category_name: [element.category_name],
      category_type: [element.category_type],
    });
  }

  ReportThresholdSubmit(){
    let subscribe;
    // console.log("form value",this.ReportThresholdForm.value)
    this.ReportThresholdForm.value.dataReports.forEach((element) => {
        if (element.id) {
          subscribe = this.appService.updateSettings({id: element.id, key: element.key, value: element.value});
        }
        subscribe.subscribe((res) => {
          // console.log("resss",res)
        }, error => {
            Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.error.message
          });
        });
    })
  }

  initSuccessionManagement(){
    this.SuccessionManagementForm = this.fb.group({
      critical_role_approval:[null,Validators.required],
      // critical_level:[null,Validators.required],
      role_parameter:[null,Validators.required],
      career_plan_update:[null,Validators.required],
      career_plan_update_id:[null,Validators.required],
      criticality_levels:this.fb.array([]),
      role_parameters:this.fb.array([]),
      managerial_levels:this.fb.array([]),
      retirement_status:this.fb.array([]),
      readiness_levels : this.fb.array([])
    })
  }

  successionManagementSettings(){
    this.appService.getSettings(['critical-role-approval','role-parameter','career_plan_update']).subscribe((res:any)=>{
      console.log('sm settings',res)
      this.SMSettings = res.items
      for(let i = 0;i<res.items.length;i++){
        if (res.items[i].key == 'critical-role-approval'){
          this.SuccessionManagementForm.get('critical_role_approval').setValue(res.items[i].value)
          this.criticalRoleID = res.items[i].id
        } else if(res.items[i].key == 'role-parameter'){
          this.SuccessionManagementForm.get('role_parameter').setValue(res.items[i].value)
          this.parameterRoleID = res.items[i].id
        } else if(res.items[i].key == 'career_plan_update') {
          this.career_plan_update.setValue(res.items[i].value)
          this.career_plan_update_id.setValue(res.items[i].id)
        }
      }
    })
  }

  loadMangerialLevelSM(){

    this.removeAllManagerialLevels()
    this.appService.getManagerialLevel().subscribe((res:any)=>{
      console.log("ML Results",res)
      if(res.length > 0){
        for(let i = 0;i < res.length; i++){
          this.addRowML()
          this.managerial_levels.controls[i].patchValue(res[i])
          // console.log('this.managerial_levels.controls',res[i].id)
          console.log('this.managerial_levels.controls',this.managerial_levels.controls[i])
        }

        this.getBUs()
      } else {
        this.addRowML()
      }
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
    })
  }

  removeAllManagerialLevels(){
    while (this.managerial_levels.length !== 0) {
      this.managerial_levels.removeAt(0)
    }
  }

  criticalLevelDataSettings:any

  loadCriticalLevelSM(){
    this.controlColors = []
    this.appService.getCriticalLevel().subscribe((res:any)=>{
      console.log("critical levels from db",res)
      this.criticalLevelDataSettings = res
        if(res.length > 0){
          for(let i = 0; i < res.length; i++){
            this.addRowCL()
            this.criticality_levels.controls[i].patchValue(res[i])
            this.controlColors[i] = res[i].color
          }
        } else {
          this.addRowCL()
        }
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
    })
  }

  // NAFIII
readinessLevelDataSettings:any
  loadRedinessLevelSM(){
    this.RLcontrolColors = []
    this.appService.getAllReadinessLevel().subscribe((res:any)=>{
      console.log("readiness levels from db",res)
      this.readinessLevelDataSettings = res.rediness_levels
        if(res.rediness_levels.length > 0){
          for(let i = 0; i < res.rediness_levels.length; i++){
            this.addRowReadinessLevel()

            this.readiness_levels.controls[i].patchValue(res.rediness_levels[i])
            this.RLcontrolColors[i] = res.rediness_levels[i].color
          }
        } else {
          // this.addRowCL()
        }
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
    })
  }

  loadRoleParameters(){
    this.appService.getParameterRoles().subscribe((res:any)=>{
      console.log("Parameter role all res",res)
      this.previous_data_rp = res
      if(res.length > 0){
        for(let i = 0;  i < res.length; i++) {
          this.addRowRP()
          this.role_parameters.controls[i].setValue(res[i])
        }
        this.editableRoleParameter = true
      } else {
        this.addRowRP()
      }
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
    })
  }

  loadRetirementStatus(){
    this.appService.getRetirementStatus().subscribe((res:any)=>{
      if(res.length > 0){
        for(let i = 0;  i < res.length; i++) {
          this.addRowRS()
          this.retirement_status.controls[i].setValue(res[i])
        }
      } else {
        this.addRowRS()
      }
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
    })
  }

  addRowCL() {

    if(!this.editOption){
      const lastIndex = this.criticality_levels.length - 1;
      if (lastIndex >= 0) {
        const lastToPercentageValue = this.criticality_levels.controls[lastIndex].get('to_percentage')?.value;

        if (lastToPercentageValue === null || lastToPercentageValue === undefined) {
          Swal.fire({
            icon: 'error',
            title: 'Cannot add a new row because the previous row\'s "to" value is empty.',
          });
          return;
        }

        if (lastToPercentageValue == 100) {
          Swal.fire({
            icon: 'error',
            title: 'Cannot add a new row because the previous row\'s "to" value is 100.',
          });
          return;
        }

        if (lastToPercentageValue > 100) {
          Swal.fire({
            icon: 'error',
            title: 'Cannot add a new row because, last row must be 100.',
          });
          return;
        }
      }
    }

    this.addRowInternal();
  }


  addRowReadinessLevel(){
    const newRow = this.fb.group({
      id:[],
      // from_month: [null,[Validators.required,this.customFromMonthValidator()]],
      from_month: [null, [Validators.required,this.fromMonthValidator.bind(this)]],
      to_months: [null, [Validators.required, this.toGreaterThanFromValidator('from_month')]],
      rediness_level_name: [null],
      is_decrement : [null],
      is_incumbent : [null],
      is_retired : [null],
      type : [null,[Validators.required,this.duplicateTypeValidator.bind(this)]],
      color: [null],
      check_id : [this.check_id_value]
    });

    console.log("pushed")

    this.readiness_levels.push(newRow);
    this.RLcontrolColors.push('')
    this.check_id_value = this.check_id_value +1
  }
  getIndexFromControl(control: any): number {
    const formArray = this.readiness_levels;

    for (let i = 0; i < formArray.controls.length; i++) {
      console.log(formArray.controls[i]['controls']?.check_id.value,control?._parent?.controls?.check_id.value)
        if (formArray.controls[i]['controls']?.check_id.value == control?._parent?.controls?.check_id.value) {
            return i; // Found the control, return its index
        }
    }

    return 1; // Control not found in the FormArray
  }

  getToMonthValue(index: any) {
    console.log(index, "this is function index");
    let value = 0;

    if (this.readiness_levels.controls[index]['controls']?.to_months.value != null) {
        value = this.readiness_levels.controls[index]['controls']?.to_months.value;
        console.log(this.readiness_levels.controls[index]['controls']?.to_months.value, "this is the value");
        return value;
    } else {
        if (index != 0) {
            console.log("repeat entered", index - 1);
            // Corrected the property name to use 'controls' instead of 'control'
            return this.getToMonthValue(index - 1);
        } else {
            return value;
        }
    }
}


  customFromMonthValidator(): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      const levels = this.readiness_levels
      let error: ValidationErrors | null = null;

      for (let i = 1; i < levels.length; i++) {
        const fromMonthControl = levels.at(i).get('from_month');
        const toMonthControl = levels.at(i - 1).get('to_months');

        if (fromMonthControl && toMonthControl && fromMonthControl.value && toMonthControl.value) {
          const fromMonthValue = fromMonthControl.value;
          const toMonthValue = toMonthControl.value;

          if (fromMonthValue <= toMonthValue) {
            error = { 'invalidRange': true };
            fromMonthControl.setErrors(error);
          }
        }
      }

      return error;
    };
  }

  addRowInternal() {
    const lastIndex = this.criticality_levels.length - 1;
    console.log("data length", lastIndex)
    let fromValue: any

    fromValue = lastIndex >= 0 ? this.criticality_levels.controls[lastIndex]?.get('to_percentage')?.value + 1 : 0;

    const newRow = this.fb.group({
      id:[],
      from_percentage: [fromValue, [Validators.required]],
      to_percentage: [null, [Validators.required, this.toGreaterThanFromValidator('from_percentage')]],
      label: [null, [Validators.required,this.duplicateLabelValidator.bind(this)]],
      color: [null, [Validators.required,this.duplicateColorValidator.bind(this)]],
    });

    this.criticality_levels.push(newRow);

    // Initialize the color for the newly added control
    this.controlColors.push('');

    if (this.criticality_levels.length > 1) {
      for (let i = 0; i < this.criticality_levels.length - 1; i++) {
        const ToControl = this.criticality_levels.controls[i].get('to_percentage');
        const FromControl = this.criticality_levels.controls[i + 1].get('from_percentage');

        ToControl?.valueChanges.subscribe((value) => {
          if (value !== null) {
            const fromValue = value + 1;
            FromControl?.patchValue(fromValue);
          }
        });
      }
    }

  }



  duplicateTypeValidator(control: any): { [key: string]: boolean } | null {


    console.log(control);



    const label = control.value;

    if(label == 'decrement'){
      control?._parent.controls.is_decrement.setValue('yes')
      control?._parent.controls.is_incumbent.setValue('no')
      control?._parent.controls.is_retired.setValue('no')
      console.log(control)
      control?._parent.controls.to_months.enable()
      control?._parent.controls.from_month.enable()
      return null
    }else if (label == 'incumbent'){

      const isDuplicate = this.readiness_levels.controls
      .filter(c => c !== control.parent)
      .some(c => c.get('type')?.value === label);

      let temp = isDuplicate ? { 'duplicateLabel': true } : null;
      if (temp == null){
        control?._parent.controls.is_incumbent.setValue('yes')
        control?._parent.controls.is_decrement.setValue('no')
        control?._parent.controls.is_retired.setValue('no')



      }
      control?._parent.controls.to_months.disable()
      control?._parent.controls.from_month.disable()
      return temp;
    }else if(label == 'retired'){
      const isDuplicate = this.readiness_levels.controls
      .filter(c => c !== control.parent)
      .some(c => c.get('type')?.value === label);

      let temp = isDuplicate ? { 'duplicateLabel': true } : null;
      if (temp == null){
        control?._parent.controls.is_retired.setValue('yes')
        control?._parent.controls.is_decrement.setValue('no')
        control?._parent.controls.is_incumbent.setValue('no')



      }
      control?._parent.controls.to_months.disable()
      control?._parent.controls.from_month.disable()

      return temp
    }

    // console.log(label," this is label");

    // const isDuplicate = true

    // return  null;
    // return { 'duplicateLabel': true }
  }




  duplicateLabelValidator(control: AbstractControl): { [key: string]: boolean } | null {

    const label = control.value;
    const isDuplicate = this.criticality_levels.controls
      .filter(c => c !== control.parent)
      .some(c => c.get('label')?.value === label);

    return isDuplicate ? { 'duplicateLabel': true } : null;
  }

  duplicateColorValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const color = control.value;
    const isDuplicate = this.criticality_levels.controls
      .filter(c => c !== control.parent)
      .some(c => c.get('color')?.value === color);

    return isDuplicate ? { 'duplicateColor': true } : null;
  }
  removeRowCL(k:any){
    const criticality_levels = this.criticality_levels;
    console.log("this.controlColors[k]",this.controlColors[k])
    if (this.controlColors[k]) {
      this.controlColors.splice(k, 1);
    }

    const toPercentIndex = k - 1;
    console.log("toPercentIndex", toPercentIndex)

    const fromPercentIndex = k + 1;
    console.log("fromPercentIndex", fromPercentIndex)
    this.criticality_levels.controls[fromPercentIndex]?.get('from_percentage')?.setValue(this.criticality_levels.controls[toPercentIndex]?.get('to_percentage')?.value + 1);

      criticality_levels.removeAt(k);
  }


  removeRowReadinessLevel(k:any){
    const criticality_levels = this.readiness_levels;
    console.log("this.controlColors[k]",this.RLcontrolColors[k])
    if (this.RLcontrolColors[k]) {
      this.RLcontrolColors.splice(k, 1);
    }

    // const toPercentIndex = k - 1;
    // console.log("toPercentIndex", toPercentIndex)

    // const fromPercentIndex = k + 1;
    // console.log("fromPercentIndex", fromPercentIndex)
    // this.criticality_levels.controls[fromPercentIndex]?.get('from_percentage')?.setValue(this.criticality_levels.controls[toPercentIndex]?.get('to_percentage')?.value + 1);

    criticality_levels.removeAt(k);
    this.check_id_value= this.check_id_value-1
  }

  removeRow2() {
    const criticality_levels = this.criticality_levels;

    // Make sure there's at least one row to remove
    if (criticality_levels.length > 0) {
        criticality_levels.removeAt(criticality_levels.length - 1);
    }
  }


  toGreaterThanFromValidator(type): ValidatorFn {
    // from_percentage


    return (control: AbstractControl): { [key: string]: any } | null => {
      const fromValue = control.parent?.get(type)?.value ?? null;
      const toValue = control.value;
      if (fromValue !== null && toValue !== null && toValue <= fromValue) {
        return { toLessThanFrom: true };
      }
      return null;
    };
  }



  fromMonthValidator(control: any): { [key: string]: boolean } | null {


    console.log(control as FormGroup,"this is cintreok");
    console.log(this.readiness_levels.controls,"2222222");

    // console.log(this.readiness_levels.controls.indexOf(control))


    const index = this.getIndexFromControl(control);
    console.log(index, " this i s index")
    if (index != 0 && this.readiness_levels.controls.length > 0){


      let tomonth_data = this.getToMonthValue(index-1)


      if (tomonth_data <= control.value || tomonth_data == 0){
        return null
      }else{
        return { 'duplicateLabel': true }
      }

      console.log(this.getToMonthValue(index-1));

    }

    // console.log(control.value, " this is the value");
    // console.log(control, " this is the value");
    // let max_value = 0

    // if( this.readiness_levels.controls.length > 1){
    //   if(this.readiness_levels.controls[this.readiness_levels.controls.length - 1].value.from_month == this.readiness_levels.controls[this.readiness_levels.controls.length - 2].value.to_months){
    //     return {"dd" : true}
    //   }
    // }

    // console.log( this.readiness_levels.controls[0])
    // for(let i = 0; i <  this.readiness_levels.controls.length; ++i){
    //   if(this.readiness_levels.controls[i].value.to_months && this.readiness_levels.controls[i].value.to_months > max_value){
    //     max_value = this.readiness_levels.controls[i].value.to_months
    //   }
    // }

    // let temp = isDuplicate ? { 'duplicateLabel': true } : null;
    //   if (temp == null){
    //     control?._parent.controls.is_retired.setValue('yes')
    //     control?._parent.controls.is_decrement.setValue('no')
    //     control?._parent.controls.is_incumbent.setValue('no')



    //   }


    return null


    }











  get criticality_levels(){
    return this.SuccessionManagementForm.get('criticality_levels') as FormArray
  }

  addRowRP(){

    const minGreaterThanMaxValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const minScore = control.value;
      const maxScore = control.parent?.get('max_score')?.value;

      if (minScore !== null && maxScore !== null && minScore >= maxScore) {
        return { minGreaterThanMax: true };
      }

      return null;
    };

    const maxLessThanMinValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const maxScore = control.value;
      const minScore = control.parent?.get('min_score')?.value;

      if (maxScore !== null && minScore !== null && maxScore <= minScore) {
        return { maxLessThanMin: true };
      }

      return null;
    };

    if(!this.editableRoleParameter){
      this.role_parameters.push(this.fb.group({
        id:[null],
        parameter_name:[null,[Validators.required]],
        max_score:[null,[Validators.required,maxLessThanMinValidator]],
        min_score:[null,[Validators.required,minGreaterThanMaxValidator]],
        weightage:[null,[Validators.required,this.customDecimalValidator]],
        default_value:[0]
      }))
    }


    if(this.editableRoleParameter){
      this.role_parameters.push(this.fb.group({
        id:[null],
        parameter_name:[null,[Validators.required]],
        max_score:[null,[Validators.required,maxLessThanMinValidator]],
        min_score:[null,[Validators.required,minGreaterThanMaxValidator]],
        weightage:[null,[Validators.required,this.customDecimalValidator]],
        default_value:[0]
      }))
    }
  }

  customDecimalValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      const roundedValue = Math.round(parsedValue * 100) / 100;

      // Update the control value only if it's different from the rounded value
      if (parsedValue !== roundedValue) {
        control.setValue(roundedValue, { emitEvent: false });
      }
    }

    return null;
  }

  removeRowRP(k:any){
    this.role_parameters.removeAt(k);
  }

  submitSMParameterRoles(){
    if(this.role_parameters.valid){
      let sumWeightage = 0
      for(let i = 0; i < this.role_parameters.value.length; i++){
        sumWeightage = sumWeightage + this.role_parameters.value[i].weightage
      }

      console.log('sumWeightage',sumWeightage)

      if(sumWeightage == 100){
        console.log("100!!!!!!!!")
        this.appService.updateRoleParameter(this.role_parameters.value).subscribe((res:any)=>{
          console.log('res update role parameter',res)
          if(res.hasOwnProperty('error_level_name')){
            Swal.fire({
              icon:'warning',
              title:'Warning!',
              html:`<p><span style="color:red;font-weight:bold">${res.error_level_name}</span> is already used as Role Parameter Name, <i>Please use Different Level Name<i></i></p>`
            })
            this.removeAllRoleParametersControls()
            this.loadRoleParameters()
          } else {
            Swal.fire({
              icon:'success',
              text:'Successfully updated Role Parameters',
              title:'text'
            })
          }
        },(error)=>{
          Swal.fire({
            icon:'error',
            title:'Oops!',
            text:error.error.message
          })
          this.removeAllRoleParametersControls()
          this.loadRoleParameters()
        })
      } else{
        Swal.fire({
          icon:'error',
          title:'Oops!',
          text:'The sum of Weightage should be 100'
        })
      }
    } else {
      for(let i =0; i < this.role_parameters.length; i++){
        const role_parameters = this.role_parameters.at(i) as FormGroup;
        Object.values(role_parameters.controls).forEach(control => {
          //console.log("control",control);
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }
  }

  removeAllRoleParametersControls(){
    while (this.role_parameters.length !== 0) {
      this.role_parameters.removeAt(0)
    }
  }

  get role_parameters(){
    return this.SuccessionManagementForm.get('role_parameters') as FormArray
  }

  addRowML(){
    this.managerial_levels.push(this.fb.group({
      id:[null],
      level_name:[null,Validators.required],
      // grade_ids:[null,Validators.required],
      leadership_role:['no',Validators.required],
    }))
  }

  removeRowML(k:any){
    this.managerial_levels.removeAt(k);
  }

  isMLEmployeeModal = false
  managerialLevelSelectedName:any

  manageEmployeeModal(id:any,level_name:any){
    this.isMLEmployeeModal = true
    console.log('managerial levelid on modal ml open',id,'name:',level_name)
    this.selecteManagerialLevel = id
    this.managerialLevelSelectedName = level_name
    this.loadManagerialLevelEmployees(this.selecteManagerialLevel)
  }

  loadManagerialLevelEmployees(id:any){
    this.appService.getEmployeeManagerialLevelsByID(id).subscribe((res:any)=>{
      this.managerialLevelEmployees = res
      console.log("managerial level mapped employees",res)
    })
  }

  handleCancel(){
    this.isMLEmployeeModal = false
  }

  addRowRS(){
    this.retirement_status.push(this.fb.group({
      id:[null],
      name:[null,Validators.required],
    }))
  }

  removeRowRS(k:any){
    this.retirement_status.removeAt(k)
  }

  submitSMRetirementStatus(){
    if(this.role_parameters.valid) {
      this.appService.addRetirementReasons(this.retirement_status.value).subscribe((res: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Succefully Updated Retirement Status!'
        })
      }, (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          text: error.error.message
        })
        this.removeAllRetirementStatusControls()
        this.loadRetirementStatus()
      })
    } else {
      for(let i =0; i < this.retirement_status.length; i++){
        const retirement_status = this.retirement_status.at(i) as FormGroup;
        Object.values(retirement_status.controls).forEach(control => {
          //console.log("control",control);
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }
  }

  removeAllRetirementStatusControls() {
    while (this.retirement_status.length !== 0) {
      this.retirement_status.removeAt(0)
    }
  }

  get retirement_status(){
    return this.SuccessionManagementForm.get('retirement_status') as FormArray
  }

  get managerial_levels(){
    return this.SuccessionManagementForm.get('managerial_levels') as FormArray
  }

  get readiness_levels(){
    return this.SuccessionManagementForm.get('readiness_levels') as FormArray
  }

  successionManagementSettingsUpdate(id:any,key:any,value:any){
    if(id){
    let data ={'id':id,'key':key,'value':value}
    let value2: any
    if(value =='active'){
      value2 = 'Enable'
    } else {
      value2 = 'Disable'
    }
    this.appService.updateSettings(data).subscribe((res)=>{
      Swal.fire({
        icon:'success',
        title:'Success',
        html:`<p>${key} has been updated with status: ${value2}</p>`
      })
    },(error)=>{
      Swal.fire({
        icon:"error",
        title:"Oops!",
        text:error.error.message
      })
    })
    }
  }

  submitSMManagerialLevels(){
    // console.log("check ML formarray validation", this.managerial_levels)
    if(this.managerial_levels.valid){
      this.appService.updateManagerialLevel(this.managerial_levels.value).subscribe((res:any)=>{
        // console.log("Success")
        // console.log("ML Results",res.hasOwnProperty('error_level_name'))
        if(res.hasOwnProperty('error_level_name')){
          Swal.fire({
            icon:'warning',
            title:'Warning!',
            html:`<p><span style="color:red;font-weight:bold">${res.error_level_name}</span> is already used as Level Name, <i>Please use Different Level Name<i></i></p>`
          })
          this.removeAllManagerialLevelControls()
          this.loadMangerialLevelSM()
        } else {
          Swal.fire({
            icon:'success',
            title:'Succefully updated Managerial Levels'
          })
          this.removeAllManagerialLevelControls()
          this.loadMangerialLevelSM()
        }
      },(error)=>{
        Swal.fire({
          title:'Oops!',
          text:error.error.message,
          icon:'error'
        })
        this.removeAllManagerialLevelControls()
        this.loadMangerialLevelSM()
      })
    } else {
      for(let i =0; i < this.managerial_levels.length; i++){
        const managerialLevels = this.managerial_levels.at(i) as FormGroup;
        Object.values(managerialLevels.controls).forEach(control => {
          //console.log("control",control);
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    }
  }

  removeAllManagerialLevelControls(){
    while (this.managerial_levels.length !== 0) {
      this.managerial_levels.removeAt(0)
    }
  }

  getBUs() {
    let rr = new BusinessUnitRetrieveRequest();
    rr.per_page = null;
    this.appService.getBusinessUnits(rr).subscribe((res:any) => {
      this.bUOptionList = res['items'];
      // console.log("bu list",this.bUOptionList)
      this.showSelectBusiness = true
      this.isBusinessUnitOptionListLoading = false
    });
  }

  onLocationOpened(isOpened:any) {
    if (!isOpened) {
      return;
    }
    this.getLocations(this.business_unit_id.value);
  }

  getLocations(businessUnitId:any) {
    this.isLocationOptionListLoading = true;
    let rr = new LocationRetrieveRequest();
    rr.business_unit_ids = businessUnitId;
    rr.per_page = null;
    this.appService.getLocations(rr).subscribe((res:any) => {
      this.locationOptionList = res['items'];
      this.isLocationOptionListLoading = false;
    });
  }

  onFunctionOpened(isOpened:any) {
    if (!isOpened) {
      return;
    }
    this.getFunctions(this.business_unit_id.value);
  }

  getFunctions(businessUnitId:any) {
    this.isFunctionOptionListLoading = true;
    let rr = new FunctionRetrieveRequest();
    rr.business_unit_ids = businessUnitId;
    rr.per_page = null;
    this.appService.getFunctions(rr).subscribe((res:any) => {
      this.functionOptionList = res['items'];
      this.isFunctionOptionListLoading = false;
    });
  }

  onDepartmentOpened(isOpened:any) {
    if (!isOpened) {
      return;
    }
    this.getDepartments(this.function_id.value);
  }

  getDepartments(functionId:any) {
    this.isDepartmentOptionListLoading = true;
    let rr = new DepartmentRetrieveRequest();
    rr.function_ids = functionId;
    rr.per_page = null;
    this.appService.getDepartments(rr).subscribe((res:any) => {
      this.departmentOptionList = res['items'];
      this.isDepartmentOptionListLoading = false;
    });
  }

  onGradeOpened(isOpened:any) {
    if (!isOpened) {
      return;
    }
    this.getGrades(this.business_unit_id.value);
  }

  getGrades(businessUnits:any){
    const rr = new GradeRetrieveRequest();
    rr.per_page = null;
    rr.business_unit_ids = businessUnits
    this.appService.getGrades(rr).subscribe((data:any) => {
      this.gradeListOption = data['items'];
    });
  }

  onEmployeeOpened(isOpened:any) {
    if (!isOpened) {
      return;
    }

    this.loadAllEmployees()
  }

  searchValue:any
  pageSizeMeta = 10
  isEmployeeListLoading = false
  employeeList:any[]=[]
  employeeAllTotal:any
  employeeAllTotal2:any
  searchTimer3: any;

  loadAllEmployees(){
    this.isEmployeeListLoading = true
    const empFilter = new EmployeeRetrieveRequest()

    empFilter.business_unit_ids = this.business_unit_id.value
    empFilter.function_ids = this.function_id.value
    empFilter.department_ids = this.department_id.value
    empFilter.location_ids = this.location_id.value
    empFilter.grade_ids = this.employee_grade_id.value
    empFilter.per_page = this.pageSizeMeta
    empFilter.page = 1
    empFilter.display_name = this.searchValue
    empFilter.is_tenant_admin = "yes"

    // empFilter.employee_search_id = this.employee_name.value


    this.appService.getEmployeesSearchClone(empFilter).subscribe((res:any)=> {
      console.log('empFilter',empFilter)
      console.log('res',res)
      const filteredEmployeeList = res.items.filter(item => {
        return !this.managerialLevelEmployees.some(manager => manager.employee_id === item.id);
      });

      this.employeeList = filteredEmployeeList;
      console.log("employeee list", this.employeeList)
      if(this.employeeList.length < 1){
        this.showSelectEmployeeML = false
      } else {
        this.showSelectEmployeeML = true
      }
      this.employeeAllTotal = res['_meta']['total_items']
      this.employeeAllTotal2 = res.items.length
      this.isEmployeeListLoading = false
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
      this.isEmployeeListLoading = false
    })
  }

  loadMore(){
    if(this.employeeAllTotal2 != this.employeeAllTotal){
      this.pageSizeMeta = this.pageSizeMeta + 10;
      this.loadAllEmployees()
    }
  }

  onSearchEmployee($event){

    if (this.searchTimer3) {
      clearTimeout(this.searchTimer3);
    }

    this.searchTimer3 = setTimeout(() => {
      this.searchValue = $event
      this.pageSizeMeta = 10
      this.loadAllEmployees()
    }, 1000);
  }

  managerialLevelEmployees:any[]=[]
  storageAccount:any

  selecteManagerialLevel:any

  addEmployeesToManagerialLevel(){

      Swal.fire({
        title: "Are you sure?",
        text: `You are going to Map Sub-Admin to a Managerial Level - ${this.managerialLevelSelectedName}!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Confirm!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.appService.addEmployeesToManagerialLevel({'employee_id':this.employee_id_mlctrl.value,'managerial_level_id':this.selecteManagerialLevel}).subscribe((res)=>{
            if(res){
              Swal.fire({
                icon:'success',
                title:'Success',
                text:'Employee Mapped to Managerial Level Successfully'
              })
              this.loadManagerialLevelEmployees(this.selecteManagerialLevel)
              this.employee_id_mlctrl.setValue(null)
              this.loadAllEmployees()
            }
          },(error)=>{
            Swal.fire({
              icon:'error',
              title:'Oops!',
              text:error.error.message
            })
            this.employee_id_mlctrl.setValue(null)
          })
        } else {
          this.employee_id_mlctrl.setValue(null)
        }
      });

  }

  removeEmployeeFromManagerialLevel(id:any){
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Remove!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.appService.deleteManagerialLevelMap(id).subscribe((res)=>{
          Swal.fire({
            icon:'success',
            title:'Successfull',
            text:'Successfully Removed Employee From Managerial Level'
          })
          this.loadManagerialLevelEmployees(this.selecteManagerialLevel)
          this.loadAllEmployees()
        },(error)=>{
          Swal.fire({
            icon:'error',
            title:'Oops!',
            text:error.error.message
          })
        })
      }
    });
  }


  submitSMCriticalLevels(){
    console.log("check CL formarray validation", this.criticality_levels)

    const formData: any[] = this.criticality_levels.value;

    // Get the value of the last 'to_percentage' control
    const lastToPercentageValue = formData.length > 0 ? formData[formData.length - 1].to_percentage : null;

    if (lastToPercentageValue !== 100) {
      Swal.fire({
        icon: 'error',
        title: 'The last "to" control\'s value should be 100. Please adjust the value.',
      });
      return;
    } else {
      for (let i = 0; i < this.criticality_levels.controls.length; i++) {
        const control = this.criticality_levels.controls[i];
        control.get('color')?.setValue(this.controlColors[i]);
      }
      if (this.criticality_levels.valid) {
        console.log('this.criticality_levels',this.criticality_levels.value)
        this.appService.updateCriticalLevel(this.criticality_levels.value).subscribe((res:any)=>{
          console.log("Success")

          if(res.hasOwnProperty('error_level_name')){
            Swal.fire({
              icon:'warning',
              title:'Warning!',
              html:`<p><span style="color:red;font-weight:bold">${res.error_level_name}</span> is already used as Label Name, <i>Please use Different Level Name<i></i></p>`
            })

            if(this.criticalLevelDataSettings.length > 0){
              this.removeAllCriticalityLevelsControls()
              this.loadCriticalLevelSM()
            }
          } else {
            Swal.fire({
              icon:'success',
              title:'Success',
              text:'Successfully update Critical Level!'
            })
            this.removeAllCriticalityLevelsControls()
            this.loadCriticalLevelSM()
          }

        },(error)=>{
          Swal.fire({
            icon:'error',
            title:'Oops!',
            text:error.error.message
          })
          if(this.criticalLevelDataSettings.length > 0){
            this.removeAllCriticalityLevelsControls()
            this.loadCriticalLevelSM()
          }
        })

      } else {
        for (let i = 0; i < this.criticality_levels.length; i++) {
          const criticality_levels = this.criticality_levels.at(i) as FormGroup;
          Object.values(criticality_levels.controls).forEach(control => {
            console.log("control", control);
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({onlySelf: true});
            }
          });

        }
      }
    }
  }


  submitReadinessLevel(){
    for (let i = 0; i < this.readiness_levels.controls.length; i++) {
      const control = this.readiness_levels.controls[i];
      control.get('color')?.setValue(this.RLcontrolColors[i]);
    }


    if (this.readiness_levels.valid) {
      console.log('this.readiness_level',this.readiness_levels.value)
      this.appService.updateReadinessLevel(this.readiness_levels.value).subscribe((res:any)=>{
        console.log("Success")

        if(res.hasOwnProperty('error_level_name')){
          Swal.fire({
            icon:'warning',
            title:'Warning!',
            html:`<p><span style="color:red;font-weight:bold">${res.error_level_name}</span> is already used as Label Name, <i>Please use Different Level Name<i></i></p>`
          })
          this.removeAllReadinessLevelsControls()
          this.loadRedinessLevelSM()
        } else {
          Swal.fire({
            icon:'success',
            title:'Success',
            text:'Successfully update Critical Level!'
          })
        }

      },(error)=>{
        Swal.fire({
          icon:'error',
          title:'Oops!',
          text:error.error.message
        })

        if(this.readinessLevelDataSettings.length > 0){
          this.removeAllReadinessLevelsControls()
          this.loadRedinessLevelSM()
        }
      })

    } else {
      for (let i = 0; i < this.readiness_levels.length; i++) {
        const criticality_levels = this.readiness_levels.at(i) as FormGroup;
        Object.values(criticality_levels.controls).forEach(control => {
          console.log("control", control);
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({onlySelf: true});
          }
        });

      }
    }




  }

  removeAllCriticalityLevelsControls(){
    while (this.criticality_levels.length !== 0) {
      this.criticality_levels.removeAt(0)
    }
    this.controlColors = []
  }
  removeAllReadinessLevelsControls(){
    while (this.readiness_levels.length !== 0) {
      this.readiness_levels.removeAt(0)
    }
    this.controlColors = []
    this.check_id_value = 0
  }

  updateCareerPlanInterval(){
    this.appService.updateSettings({id:this.career_plan_update_id?.value,key:'career_plan_update',value:this.career_plan_update?.value}).subscribe((res:any)=>{
      Swal.fire({
        icon:'success',
        title:'Successfull',
        text:'Successfully Updated Career Plan Interval'
      })
    },(error)=>{
      Swal.fire({
        icon:'error',
        title:'Oops!',
        text:error.error.message
      })
    })
  }

  get career_plan_update(){
    return this.SuccessionManagementForm.get('career_plan_update') as FormControl
  }

  get career_plan_update_id(){
    return this.SuccessionManagementForm.get('career_plan_update_id') as FormControl
  }

  get business_unit_id(){
    return this.validateForm.get('business_unit_id') as FormControl
  }

  get location_id(){
    return this.validateForm.get('location_id') as FormControl
  }

  get function_id(){
    return this.validateForm.get('function_id') as FormControl
  }

  get department_id(){
    return this.validateForm.get('department_id') as FormControl
  }

  get employee_grade_id(){
    return this.validateForm.get('employee_grade_id') as FormControl
  }

  get employee_id_mlctrl(){
    return this.validateForm.get('employee_id') as FormControl
  }
}
