<div id="content-page" class="content-page">
    <!-- new tabe design start -->
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <nz-alert class="mb-3" nzType="error" nzMessage="Hello Mayank Vora,"
                                    nzDescription="Your initiatives for the month of October is not set up. Setup the initiatives to proceed with activities of October.">
                                    <button nz-button nzType="primary">Setup Now</button>
                                </nz-alert> -->

                                <nz-card [nzBordered]="true">
                                    <form nz-form [nzLayout]="'inline'" [formGroup]="searchForm" class="mb-1">
                                        <nz-form-item class="w-50">
                                            <nz-form-label nzRequired nzFor="search_by_user" nzNoColon="false">Search by
                                                User</nz-form-label>
                                            <nz-form-control nzErrorTip="Please select User">
                                                <nz-input-group>
                                                    <nz-select nzSize="large" nzShowSearch nzServerSearch
                                                        formControlName="emp_ids"
                                                        (nzOnSearch)="onEmpOptionListSearch($event)">

                                                        <ng-container *ngFor="let o of empOptionList">
                                                            <nz-option *ngIf="!isEmpOptionListLoading" [nzValue]="o.id"
                                                                [nzLabel]="o.display_name"></nz-option>
                                                        </ng-container>
                                                        <nz-option *ngIf="isEmpOptionListLoading" nzDisabled
                                                            nzCustomContent>
                                                            <i nz-icon nzType="loading" class="loading-icon"></i>
                                                            Loading Data...
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <!-- <nz-form-item>
                                            <nz-form-label nzRequired nzFor="Search by User" nzNoColon="false">
                                                Search by User
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please input Search by User!">
                                                <nz-input-group nzSize="large">
                                                    <input type="text" nz-input nzSize="large"
                                                        placeholder="Enter here" />
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item> -->
                                        <!-- <nz-form-item>
                                            <nz-form-label nzRequired nzFor="Search by Project" nzNoColon="false">
                                                Search by Project
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip=" Search by Project!">
                                                <nz-input-group class="form-group">
                                                    <nz-select nzSize="large" nzPlaceHolder="Select Project"
                                                        [nzOptions]="listOfProjectOfOption">
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item> -->
                                        <!-- <nz-form-item>
                                            <nz-form-control nzErrorTip="Please input Types!">
                                                <nz-input-group class="form-group">
                                                    <label nzRequired nz-checkbox class="mt-2">Milestone</label>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item> -->
                                        <nz-form-item>
                                            <nz-form-control>
                                                <button nzSize="large" [disabled]="!searchForm.valid"
                                                    (click)="submitSearchForm()" nz-button class="mr-2"
                                                    nzType="primary">Search</button>
                                                <button nzSize="large" [disabled]="!searchForm.valid"
                                                    (click)="clearSearchForm()" nz-button
                                                    nzType="default">Clear</button>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </form>
                                </nz-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- new tabe design start -->
        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="col-sm-12">
                            <div class="row my-4">
                                <div class="col-md-6">
                                    <h3>Activity</h3>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button nz-button nzSize="large" [nzType]="'primary'" (click)="showModal()">Create
                                        Activity</button>
                                </div>
                            </div>

                            <nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleModalCancel()"
                                [nzTitle]="activityModalTitle" [nzContent]="activityModalContent"
                                [nzFooter]="activityModalFooter">
                                <ng-template #activityModalTitle>{{ action | titlecase}} Activity</ng-template>
                                <ng-template #activityModalContent>
                                    <form nz-form [nzLayout]="'vertical'" (ngSubmit)="submitForm()"
                                        [formGroup]="activityForm">

                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="Activity Title" nzNoColon="false">Activity
                                                Title
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please input Activity Title!">
                                                <nz-input-group nzSize="large">
                                                    <input type="text" nz-input nzSize="large" formControlName="title"
                                                        placeholder="Enter here" />
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please select start date!">
                                                <nz-form-label nzRequired nzFor="Start Time" nzNoColon="false">Start Time
                                                </nz-form-label>
                                                <nz-input-group>
                                                    <nz-date-picker (ngModelChange)="onChange($event, 'sd')"
                                                        formControlName="start_date" nzFormat="yyyy-MM-dd HH:mm"
                                                        [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                                                        [nzSize]="'large'" 
                                                        (nzOnOpenChange)="handleStartOpenChange($event)"
                                                        ></nz-date-picker>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please select end Time!">
                                                <nz-form-label nzRequired nzFor="To" nzNoColon="false">End Time
                                                </nz-form-label>
                                                <nz-input-group>
                                                    <!-- <nz-range-picker [nzSize]="'large'" [nzShowTime]="true"
                                                    [(ngModel)]="date">
                                                </nz-range-picker> -->
                                                    <nz-date-picker (ngModelChange)="onChange($event, 'ed')"
                                                        formControlName="end_date" nzFormat="yyyy-MM-dd HH:mm"
                                                        [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                                                        [nzSize]="'large'"
                                                        #endDatePicker
                                                        (nzOnOpenChange)="handleEndOpenChange($event)">
                                                    </nz-date-picker>
                                                </nz-input-group>
                                                <!-- <nz-form-label nzFor="will be approved by manager" nzNoColon="false">
                                                    (will
                                                    be approved by manager)</nz-form-label> -->
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label nzRequired nzFor="Initiative" nzNoColon="false">Initiative
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please Select Initiative!">
                                                <nz-input-group nzSize="large">
                                                    <nz-select [nzSize]="'large'" nzAllowClear
                                                        nzPlaceHolder="Select Initiative"
                                                        (ngModelChange)="onInitiativeChange()"
                                                        formControlName="emp_initiative_id">
                                                        <nz-option *ngFor="let i of EmployeeInitiativesList"
                                                            [nzLabel]="i.title" [nzValue]="i.id"></nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-label nzFor="Description" nzNoColon="false">Description
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please input Description!">
                                                <nz-input-group nzSize="large">
                                                    <textarea type="text" formControlName="description" nz-input
                                                        nzSize="large" placeholder="Enter here"> </textarea>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <div class="row">
                                            <div class="col-md-6"
                                                *ngIf="selectedInitiativeObj?.is_project_based == 'yes' && selectedInitiative != null">
                                                <nz-form-item>
                                                    <nz-form-label nzFor="Project (Optional)" nzNoColon="false">
                                                        Project (Optional)
                                                    </nz-form-label>
                                                    <nz-form-control nzErrorTip="Please input Project (Optional)!">
                                                        <nz-input-group nzSize="large">
                                                            <!-- <nz-select nzSize="large">
                                                            <nz-option nzValue="Project (Optional) 1"
                                                                nzLabel="Project (Optional) 1">
                                                            </nz-option>
                                                            <nz-option nzValue="Project (Optional) 2"
                                                                nzLabel="Project (Optional) 2">
                                                            </nz-option>
                                                        </nz-select> -->
                                                            <nz-select [nzSize]="'large'" nzAllowClear
                                                                nzPlaceHolder="Select Project"
                                                                formControlName="project_id"
                                                                (ngModelChange)="onProjectChange()">
                                                                <nz-option *ngFor="let i of EmployeeProjectList"
                                                                    [nzLabel]="i.project_name" [nzValue]="i.id">
                                                                </nz-option>
                                                            </nz-select>
                                                            <!-- <div class="col-md-2"  *ngIf="EmployeeProjectList?.length === 0 || EmployeeProjectList?.length === 'null'" > 
                                                        <h3><i nz-icon class="plusIcon" nzType="plus-circle"
                                                                nzTheme="fill"></i></h3>
                                                    </div> -->
                                                        </nz-input-group>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div *ngIf="(selectedInitiativeObj?.is_project_based == 'yes' && selectedInitiative != null)" class="col-md-2 custPlusIcon" >
                                                <a (click)="showProjectModal()">
                                                    <h3><i nz-icon class="plusIcon" nzType="plus-circle"
                                                            nzTheme="fill"></i></h3>
                                                </a>
                                            </div>
                                            <div class="col-md-4"></div>
                                        </div>
                                        <nz-form-item>
                                            <nz-form-label nzFor="Manager Objective" nzNoColon="false">Milestone
                                            </nz-form-label>
                                            <nz-form-control nzErrorTip="Please input Is Project Based!">
                                                <nz-input-group nzSize="large">
                                                    <div class="row">
                                                        <div class="col-md-1">
                                                            <label nz-checkbox (ngModelChange)="onMilestoneChange()"
                                                                formControlName="is_milestone" class="mt-2"></label>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="isMilestoneChecked">
                                                            <nz-form-item>
                                                                <nz-form-control>
                                                                    <nz-input-group>
                                                                        <!-- [disabled]="EmployeeProjectList?.length > 0 " [attr.disabled]="isTADisabled"-->
                                                                        <input formControlName="target_achieved"
                                                                            type="text" nz-input nzSize="large"
                                                                            placeholder="Enter here" />
                                                                    </nz-input-group>
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                        </div>
                                                        <div class="col-md-3" *ngIf="isMilestoneChecked">
                                                            <nz-form-item>
                                                                <nz-form-control>
                                                                    <nz-input-group>
                                                                        <input type="text" nz-input nzSize="large"
                                                                            formControlName="uom" placeholder="uom" />
                                                                    </nz-input-group>
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                        </div>
                                                        <div class="col-md-4"></div>
                                                    </div>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <!-- {{activityForm.value | json}} -->
                                        <!-- {{activityForm.value | json}}
                                        {{activityForm.value.project_id}} -->
                                        <!-- <button nz-button nzType="primary" (click)="remove()" >Delete</button> -->
                                    </form>
                                </ng-template>
                                <ng-template #activityModalFooter>
                                    <button nz-button nzType="default" (click)="handleModalCancel()">Cancel</button>
                                    <button *ngIf="action == 'edit'" nz-button nzType="default" (click)="remove()">Delete</button>
                                    <button nz-button nzType="primary" [disabled]="!activityForm.valid"
                                        (click)="handleModalSubmit()" [nzLoading]="isConfirmLoading">Save</button>

                                </ng-template> 
                            </nz-modal> 
                            <!-- <div class="row">
                                <div class="col-md-6 offset-md-3 text-center">
                                    <nz-range-picker [nzSize]="'large'" [(ngModel)]="date"></nz-range-picker>
                                </div>
                            </div> -->
                            <full-calendar class="mt-5" [options]="calendarOptions"></full-calendar>
                            <!-- <div *ngIf="eventlist">
                            <ng-container *ngFor="let item of activityList; let i=index">
                                <nz-card class=" mb-3" [ngClass]="{'border-green': item.isChecked }" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                    <div class="row">
                                        <div class="col-md-2">  
                                            <div><strong>{{item.start_date | date:'dd-MMM'}} - {{item.end_date | date:'dd-MMM'}}</strong></div>
                                            <div><strong>{{item.start_date | date:'HH:mm'}} - {{item.end_date | date:'HH:mm'}}</strong></div> 
                                        </div>
                                        <div  class="col-md-1">
                                            <nz-divider nzType="vertical"></nz-divider>
                                        </div>
                                        <div class="col-md-7">  
                                            <div>{{item.title | titlecase}}</div>
                                            {{item.description | titlecase}}
                                        </div>
                                        <div class="col-md-2">  <label nz-checkbox [(ngModel)]="item.isChecked" > {{item.isChecked}} {{item.uom | titlecase}}</label></div>
                                    </div>
                                </nz-card>
                            </ng-container>
                            </div> -->
                            <!-- <nz-card class="border-green mb-3" [nzBordered]="true" [nzBodyStyle]="{padding: '20px'}"> 
                                    <div class="row">
                                        <div class="col-md-2">  
                                            <div>6th Oct </div>
                                            <div>13:30 - 14:30</div> 
                                        </div>
                                        <div  class="col-md-1">
                                            <nz-divider nzType="vertical"></nz-divider>
                                        </div>
                                        <div class="col-md-7">  
                                            <div>Meeting with exlegenze</div>
                                            Meeting to discuss ui / ux and timelines
                                        </div>
                                        <div class="col-md-2">  <label nz-checkbox >Proposal-CODE</label></div>
                                    </div>
                                </nz-card> -->

                            <!-- Create Project Modal  -->
                            <nz-modal [(nzVisible)]="isVisibleProjectModal" nzTitle="Create Project"
                                (nzOnOk)="handleProjectModalSubmit()" (nzOnCancel)="handleProjectModalCancel()"
                                nzOkText="Save" nzOkType="primary" [nzOkLoading]="isProjectFormLoading"
                                [nzOkDisabled]="!projectForm.valid">
                                <form nz-form [nzLayout]="'vertical'" (ngSubmit)="submitProjectForm()"
                                    [formGroup]="projectForm">
                                    <div>
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please Enter Name!">
                                                <nz-form-label nzRequired nzFor="Name" nzNoColon="false">Name
                                                </nz-form-label>
                                                <nz-input-group [nzSize]="'large'">
                                                    <input nz-input [nzSize]="'large'" formControlName="project_name"
                                                        placeholder="Project Name" />
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please select start date!">
                                                <nz-form-label nzFor="Duration" nzNoColon="false">Start Time
                                                </nz-form-label>
                                                <nz-input-group>
                                                    <nz-date-picker formControlName="start_date"
                                                        nzFormat="yyyy-MM-dd HH:mm:ss" [nzShowTime]="true"
                                                        [nzSize]="'large'"></nz-date-picker>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please select end date!">
                                                <nz-form-label nzFor="Duration" nzNoColon="false">End Time</nz-form-label>
                                                <nz-input-group>
                                                    <nz-date-picker [nzSize]="'large'" nzFormat="yyyy-MM-dd HH:mm:ss"
                                                        [nzShowTime]="true" formControlName="expected_end_date">
                                                    </nz-date-picker>
                                                </nz-input-group>
                                                <!-- <nz-form-label nzFor="will be approved by manager" nzNoColon="false">
                                                 
                                                </nz-form-label> -->
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </form>
                                <!-- {{projectForm.value | json}}  -->
                            </nz-modal>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>