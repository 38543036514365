import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-trulead-redirection',
  templateUrl: './trulead-redirection.component.html',
  styleUrls: ['./trulead-redirection.component.css']
})
export class TruleadRedirectionComponent implements OnInit {
  apiEndPoint = environment.apiEndPoint;
  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    public router: Router,
    public ngZone: NgZone,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.appService.http.post<any>(this.apiEndPoint + '/api/rdrct_frm_hrapp', { rtkn: params.rtkn }).subscribe(result => {
        // console.log('res', result);
        if (result.status == 'success') {
          this.ngZone.run(() => {
            sessionStorage.setItem('accountAccessToken', result['token']);
            sessionStorage.setItem('tokenexpiry', result['token_expiry']);
            this.appService.getLoggedInUserDetail().subscribe(res => {
              sessionStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
              setTimeout(() => {
                this.spinner.hide();
              }, 5000);
              this.router.navigate(['/']);
            })
          })
        }
      });
    });
  }
}
