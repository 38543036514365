<div id="content-page" class="content-page">
    <div class="container">
        <!-- <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <h3 class="py-4 text-center" style="font-weight: bold;">
                            Monthly Objective Performance
                        </h3>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <nz-card [nzBordered]="true">
                                    <div class="row justify-content-center">
                                        <form nz-form [nzLayout]="'inline'" [formGroup]="form" class="mb-1"
                                            (ngSubmit)="submitForm()">
                                            <div class="col-md-4">
                                                <nz-form-item class="w-100">
                                                    <nz-form-control>
                                                        <nz-select nzSize="large" nzAllowClear nzPlaceHolder="Select FY"
                                                            formControlName="fy">
                                                            <ng-container *ngFor="let year of years">
                                                                <nz-option [nzValue]="year" [nzLabel]="year">
                                                                </nz-option>
                                                            </ng-container>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-md-3">
                                                <nz-form-item class="w-100">
                                                    <nz-form-control>
                                                        <nz-select nzSize="large" nzAllowClear
                                                            nzPlaceHolder="Select Month" formControlName="month">
                                                            <ng-container *ngFor="let month of months">
                                                                <nz-option [nzValue]="month" [nzLabel]="month">
                                                                </nz-option>
                                                            </ng-container>
                                                        </nz-select>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-md-3">
                                                <nz-form-item class="w-100">
                                                    <nz-form-control>
                                                        <input nzSize="large" nz-input placeholder="Employee Name"
                                                            formControlName='employeeName' id="employeeName" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div class="col-md-2">
                                                <nz-form-item class="w-100">
                                                    <button nzSize="large" nz-button class="mr-2" nzType="primary">
                                                        Apply
                                                    </button>
                                                </nz-form-item>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="row d-flex justify-content-center" style="margin-top: 15px;">
                                        <div class="col-md-5 col-sm-6">
                                            <label nz-checkbox style="font-weight: bold; padding-left: 20px;">
                                                Month Wise Objective Performance
                                            </label>
                                        </div>
                                        <div class="col-md-5 col-sm-6">
                                            <label nz-checkbox style="font-weight: bold; padding-left: 10px;">
                                                Initiative Wise Objective Performance
                                            </label>
                                        </div>
                                    </div>
                                </nz-card>
                            </div>
                        </div>
                        <div style="padding: 40px"></div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 border-right" style="border-color: black;">
                                <div class="card mb-4"
                                    style="max-width: 25rem; max-height: 10rem; background-color: #FEE9E9; color: black; font-weight: bold;">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <p class="card-text">
                                                    This tile shows the performance of objective O1 for the selected
                                                    month and employee. 25% vs 20% indicated that 25% target for the
                                                    month is achieved vs 20% effort.
                                                </p>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="square">
                                                    <div style="float: left; color: #FFFF;">1</div>
                                                    <div style="float: right; color: #FFFF;">
                                                        <i class="fa fa-caret-down" style="font-size:24px"></i>
                                                    </div>
                                                    <div class="circle">
                                                        25% vs 25%
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 border-right" style="padding-left:90px; font-weight: bold;">
                                The Title Color Code is
                                <span>
                                    <div class="red-square"></div>
                                    Less than 60 %
                                </span>
                                <span>
                                    <div class="yellow-square"></div>
                                    60 to 80 %
                                </span>
                                <span>
                                    <div class="green-square"></div>
                                    More than 80 %
                                </span>
                            </div>
                            <div class="col-md-3" style="padding-left:90px; font-weight: bold;">
                                The Circle Color Code is
                                <span>
                                    <div class="red-square"></div> Target Achieved >= Effort
                                </span>
                                <span>
                                    <div class="green-square"></div> Target Achieved < Effort </span>
                            </div>
                        </div>
                        <hr>
                        <div class="row justify-content-md-center">
                            <div class="progress"
                                style="width: 70%; height: 35px; border-radius: 30px; margin-bottom: 50px;">
                                <div class="progress-bar" role="progressbar"
                                    style="width:20%; background-color: #D84C4B; border-right: .3em solid black">
                                    20%
                                </div>
                                <div class="progress-bar" role="progressbar"
                                    style="width:40%; background-color: #F6B22B; border-right: .3em solid black">
                                    40%
                                </div>
                                <div class="progress-bar" role="progressbar"
                                    style="width:40%; background-color: #6AC546;">
                                    40%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="row justify-content-center">
                            <h3 class="py-2 text-center" style="font-weight: bold;">
                                *Month Wise Drilldown for the objective for {{ loggedInUserDetail.employee.display_name
                                }}
                            </h3>
                        </div>
                        <div class="text-center" style="padding: 50px;" *ngIf="isEmployeeDetailsLoading">
                            <span style="font-size: 20px;">
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading
                            </span>
                        </div>
                        <div class="text-center" style="padding: 50px;"
                            *ngIf="!employeeObjectives && !isEmployeeDetailsLoading">
                            <span style="font-size: 20px;">
                                <i nz-icon style="font-size: 25px;" nzType="frown" nzTheme="outline"></i>
                                No Data Found
                            </span>
                        </div>
                        <div *ngIf="employeeObjectives">
                            <ng-container *ngFor="let data of employeeObjectives; let i=index">
                                <div class="row justify-content-center">
                                    <!-- <h3 class="py-2 text-center">
                                {{data?.objective}}
                            </h3> -->
                                </div>
                                <div class="row justify-content-center hover"
                                    (click)="togglePanel[i] = !togglePanel[i]">
                                    <div class="col-md-10">
                                        <nz-card [nzBordered]="true" nzNoPadding
                                            style="font-weight: bold; margin-bottom: 45px;">
                                            <div style="float: right; color: #3C3C3C;">
                                                <i class="fa fa-caret-down" style="font-size:24px"></i>
                                            </div>
                                            <div class="row justify-content-center" style="margin-bottom: 20px;">
                                                <h3 class="py-2 text-center">
                                                    Objective - {{data?.objective}}
                                                </h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1"
                                                    style="font-weight: bold; font-size:12px; margin-top: 40px;">
                                                    {{data?.objective}}
                                                </div>
                                                <div class="col-md-2">
                                                    <div style="font-weight: bold; margin: 10px 0px;">Enterprise
                                                        Outcomes</div>
                                                    <div class="square" style="width: 150px; height: 100px;">
                                                        <div style="float: left; color: #FFFF;">1</div>
                                                        <div style="float: right; color: #FFFF;">
                                                            <i class="fa fa-caret-down" style="font-size:24px"></i>
                                                        </div>
                                                        <div class="circle"
                                                            style="width: 90px; height: 90px; margin: 4px 25px; padding: 15px 25px; background-color: #4bd857; text-align: center;">
                                                            {{data?.total_target}} <br> vs
                                                            <br>{{data?.total_target_achieved}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding-top: 65px;">
                                                    <div class="row">
                                                        Target - {{data?.total_target}}
                                                    </div>
                                                    <div class="row">
                                                        Achieved - {{data?.total_target_achieved}}
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding-top: 35px;">
                                                    <nz-progress
                                                        [nzPercent]="convInt((data?.total_target_achieved/data?.total_target)*100)"
                                                        nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                        [nzStrokeColor]="{ '0%': '#6AC546', '50%': '#6AC546', '100%': '#6AC546' }">
                                                    </nz-progress>
                                                </div>
                                                <div class="col-md-3" style="padding-top: 65px;">
                                                    <div class="row">
                                                        Estimated effort-{{data?.total_estimated_effort}}
                                                    </div>
                                                    <div class="row">
                                                        Actual effort-{{data?.total_actual_effort}}
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding-top: 35px;">
                                                    <nz-progress
                                                        [nzPercent]="convInt((data?.total_actual_effort/data?.total_estimated_effort)*100)"
                                                        nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                        [nzStrokeColor]="{ '0%': '#35BC6F', '50%': '#35BC6F', '100%': '#35BC6F' }">
                                                    </nz-progress>
                                                </div>
                                            </div>
                                        </nz-card>
                                    </div>
                                </div>
                                <ng-container *ngFor="let subdata of data['child']">
                                    <div class="row justify-content-center" style="margin-bottom: 45px;"
                                        *ngIf="togglePanel[i]" [@slideInOut]>
                                        <i class="fa fa-arrow-circle-down" style="font-size: 50px; color: #3C3C3C;"></i>
                                    </div>
                                    <div class="row justify-content-center" *ngIf="togglePanel[i]" [@slideInOut]>
                                        <h4 class="py-4 text-center" style="font-weight: bold;">
                                            Objective for {{subdata?.month }}
                                        </h4>
                                    </div>

                                    <div class="row justify-content-center" *ngIf="togglePanel[i]" [@slideInOut]>
                                        <div class="col-md-10">
                                            <nz-card [nzBordered]="true" nzNoPadding
                                                style="border-left: 10px solid #6AC546; font-weight: bold; margin-bottom: 30px;">

                                                <div class="row">
                                                    <div class="col-md-1" style="font-weight: bold; padding-top: 60px;">
                                                        {{subdata?.month}}
                                                    </div>
                                                    <div class="col-md-2" style="padding-top: 30px">
                                                        <div class="square" style="width: 150px; height: 100px;">
                                                            <div style="float: left; color: #FFFF;">1</div>
                                                            <div style="float: right; color: #FFFF;">
                                                                <i class="fa fa-caret-down" style="font-size:24px"></i>
                                                            </div>
                                                            <div class="circle"
                                                                style="width: 90px; height: 90px; margin: 4px 25px; padding: 15px 25px; text-align: center;">
                                                                {{subdata?.target}} <br> vs <br>
                                                                {{subdata?.target_achieved}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2" style="padding-top: 60px">
                                                        <div class="row">
                                                            Target - {{subdata?.target}}
                                                        </div>
                                                        <div class="row">
                                                            Achieved - {{subdata?.target_achieved}}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2" style="padding-top: 30px">
                                                        <nz-progress
                                                            [nzPercent]="convInt((subdata?.target_achieved/subdata?.target)*100)"
                                                            nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                            [nzStrokeColor]="{ '0%': '#6AC546', '50%': '#6AC546', '100%': '#6AC546' }">
                                                        </nz-progress>
                                                    </div>
                                                    <div class="col-md-2" style="padding-top: 60px">
                                                        <div class="row">
                                                            Estimated - {{subdata?.estimated_effort}}
                                                        </div>
                                                        <div class="row">
                                                            Spent - {{subdata?.actual_effort}}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2" style="padding-top: 30px">
                                                        <nz-progress
                                                            [nzPercent]="convInt((subdata?.actual_effort/subdata?.estimated_effort)*100)"
                                                            nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                            [nzStrokeColor]="{ '0%': '#35BC6F', '50%': '#35BC6F', '100%': '#35BC6F' }">
                                                        </nz-progress>
                                                    </div>
                                                </div>
                                            </nz-card>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="row justify-content-center">
                            <h3 class="py-2 text-center" style="font-weight: bold;">
                                *Initiative Wise Drilldown for the objective for {{
                                loggedInUserDetail.employee.display_name }}
                            </h3>
                        </div>
                        <div class="text-center" style="padding: 50px;" *ngIf="isEmployeeDetailsLoading">
                            <span style="font-size: 20px;">
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading
                            </span>
                        </div>
                        <div class="text-center" style="padding: 50px;"
                            *ngIf="!employeeInitiatives && !isEmployeeDetailsLoading">
                            <span style="font-size: 20px;">
                                <i nz-icon style="font-size: 25px;" nzType="frown" nzTheme="outline"></i>
                                No Data Found
                            </span>
                        </div>
                        <div *ngIf="employeeInitiatives && !isEmployeeDetailsLoading">
                            <ng-container *ngFor="let i of employeeInitiatives; let k=index">
                                <div class="row justify-content-center">
                                    <h3 class="py-2 text-center">
                                        Objective - {{ i['objective_name'] }}
                                    </h3>
                                </div>
                                <div class="row justify-content-center hover"
                                    (click)="togglePanel2[k] = !togglePanel2[k]">
                                    <div class="col-md-10">
                                        <nz-card [nzBordered]="true" nzNoPadding
                                            style="font-weight: bold; margin-bottom: 45px;">
                                            <div style="float: right; color: #3C3C3C;">
                                                <i class="fa fa-caret-down" style="font-size:24px"></i>
                                            </div>
                                            <div class="row justify-content-center" style="margin-bottom: 20px;">
                                                <h3 class="py-2 text-center">
                                                    Initiative - {{ i['initiative'] }}
                                                </h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1"
                                                    style="font-weight: bold; font-size:12px; padding-top: 40px;">
                                                    {{ i['initiative'] }}
                                                </div>
                                                <div class="col-md-2">
                                                    <div style="font-weight: bold; margin: 10px 0px;">Enterprise
                                                        Outcomes</div>
                                                    <div class="square" style="width: 150px; height: 100px;">
                                                        <div style="float: left; color: #FFFF;">1</div>
                                                        <div style="float: right; color: #FFFF;">
                                                            <i class="fa fa-caret-down" style="font-size:24px"></i>
                                                        </div>
                                                        <div class="circle"
                                                            style="width: 90px; height: 90px; margin: 4px 25px; padding: 15px 25px; text-align: center;">
                                                            {{ i['total_target'] }} <br>vs <br>{{
                                                            i['total_target_achieved'] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding-top: 65px;">
                                                    <div class="row">
                                                        Target - {{ i['total_target'] }}
                                                    </div>
                                                    <div class="row">
                                                        Achieved - {{ i['total_target_achieved'] }}
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding-top: 35px;">
                                                    <nz-progress
                                                        [nzPercent]="convInt((i['total_target_achieved']/i['total_target'])*100)"
                                                        nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                        [nzStrokeColor]="{ '0%': '#6AC546', '50%': '#6AC546', '100%': '#6AC546' }">
                                                    </nz-progress>
                                                </div>
                                                <div class="col-md-3" style="padding-top: 65px;">
                                                    <div class="row">
                                                        Estimated Effort - {{ i?.total_estimated_effort }}
                                                    </div>
                                                    <div class="row">
                                                        Actual Effort - {{ i?.total_actual_effort }}
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="padding-top: 35px;">
                                                    <nz-progress
                                                        [nzPercent]="convInt((i?.total_actual_effort/i?.total_estimated_effort)*100)"
                                                        nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                        [nzStrokeColor]="{ '0%': '#35BC6F', '50%': '#35BC6F', '100%': '#35BC6F' }">
                                                    </nz-progress>
                                                </div>
                                            </div>
                                        </nz-card>
                                    </div>
                                </div>

                                <div *ngFor="let j of i['child']">
                                    <div class="row justify-content-center" style="margin: 15px;"
                                        *ngIf="togglePanel2[k]">
                                        <i class="fa fa-arrow-circle-down" style="font-size: 50px; color: #3C3C3C;"></i>
                                    </div>
                                    <div class="row justify-content-center" *ngIf="togglePanel2[k]">
                                        <h4 class="py-2 text-center" style="font-weight: bold;">
                                            Initiatives for {{ j['month'] }}
                                        </h4>
                                    </div>

                                    <div class="row justify-content-center" *ngIf="togglePanel2[k]">
                                        <div class="col-md-10">
                                            <nz-card [nzBordered]="true" nzNoPadding
                                                style="border-left: 10px solid #D84C4B; padding-left: 10px; font-weight: bold;">
                                                <div style="float: right; color: #3C3C3C;">
                                                    <i class="fa fa-caret-down" style="font-size:24px"></i>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="row">
                                                            *{{ i['initiative'] }} ({{ j['target_achieved']}})%
                                                        </div>
                                                        <div class="row">
                                                            <!-- Meet 15 resellers to push them for the new anti-virus and servers -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div style="color: #25237A;">
                                                            <!-- <i class="fa fa-caret-down" style="font-size:24px"></i> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-left: 10px; margin-top: 30px;">
                                                    <div class="col-md-2" style="padding-top: 30px;">
                                                        <div class="row">
                                                            Target - {{ j['target']}}
                                                        </div>
                                                        <div class="row">
                                                            Achieved - {{ j['target_achieved']}}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2" style="padding-left:0px;">
                                                        <nz-progress
                                                            [nzPercent]="convInt((j['target_achieved']/j['target'])*100)"
                                                            nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                            [nzStrokeColor]="{ '0%': '#6AC546', '50%': '#6AC546', '100%': '#6AC546' }">
                                                        </nz-progress>
                                                    </div>
                                                    <div class="col-md-2" style="padding-top: 30px;">
                                                        <div class="row">
                                                            Estimated Effort - {{ j['estimated_effort']}}
                                                        </div>
                                                        <div class="row">
                                                            Actual Effort - {{ j['actual_effort'] }}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="col-md-2" style="padding-left:0px;">
                                                            <nz-progress
                                                                [nzPercent]="convInt((j['actual_effort']/j['estimated_effort'])*100)"
                                                                nzType="circle" [nzStrokeWidth]="12" [nzWidth]="120"
                                                                [nzStrokeColor]="{ '0%': '#35BC6F', '50%': '#35BC6F', '100%': '#35BC6F' }">
                                                            </nz-progress>
                                                        </div>
                                                    </div>
                                                </div>
                                            </nz-card>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>