import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Assessment, AssessmentRetrieveRequest, NewsFeedRetrieveRequest } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-assessment-center',
  templateUrl: './assessment-center.component.html',
  styleUrls: ['./assessment-center.component.css']
})
export class AssessmentCenterComponent implements OnInit {
  total = 1;
  loading = false;
  pageSize = 100;
  pageIndex = 1;
  assessments: Assessment[] = [];
  assessmentId: number;
  loggedInUserDetail: any;
  // assessments = [
  //   {
  //     id: 1,
  //     title: 'CEO Assessment',
  //     startDate: '21-09-202',
  //     endDate: '29-09-20'
  //   },
  //   {
  //     id: 2,
  //     title: 'Sales Head Evaluation',
  //     startDate: '16-09-202',
  //     endDate: '20-09-20'
  //   }
  // ];
  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private modal: NzModalService,
    private message: NzMessageService,
    public router: Router
  ) { }

  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    // console.log(this.loggedInUserDetail);

    // this.activatedRoute.queryParams.subscribe(queryParams => {
    //   if (queryParams && queryParams['id']) {
    //     // this.showEditModal(queryParams['id']);
    //   }
    // });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    // let rr = new AssessmentRetrieveRequest('')
    // rr.sort_order = sortOrder;
    // rr.order_by = sortField;
    // rr.per_page = pageSize;
    // rr.page = pageIndex;
    // rr.assessment_type = "assessment_center";
    // this.appService.getAssessments(rr).subscribe(data => {
    //   this.loading = false;
    //   this.total = data['_meta']["total_items"];
    //   this.assessments = data['items'];
    // });
    // if (this.loggedInUserDetail.user_type == "employee") {
    //   let rr = new NewsFeedRetrieveRequest();
    //   rr.emp_id = this.loggedInUserDetail.employee.id;
    //   rr.feed_type = "assessment_center"
    //   this.appService.getNewsFeeds(rr).subscribe(data => {
    //     let assessment_ids = [];
    //     data['items'].forEach(e => {
    //       assessment_ids.push(e.feed_parent_id)
    //     });
    //     let farr = new AssessmentRetrieveRequest();
    //     farr.assessment_type = "assessment_center";
    //     farr.sort_order = sortOrder;
    //     farr.order_by = sortField;
    //     farr.per_page = pageSize;
    //     farr.page = pageIndex;
    //     farr.ids = assessment_ids;
    //     farr.assessor_emp_id = this.loggedInUserDetail.employee.id;
    //     this.appService.getAssessments(farr).subscribe(data => {
    //       this.loading = false;
    //       this.total = data['_meta']["total_items"];
    //       this.assessments = data['items'];

    //     });
    //   });
    // } else if (this.loggedInUserDetail.user_type !== "employee") {
    let rr = new AssessmentRetrieveRequest;
    rr.assessment_type = "assessment_center";
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "created_on";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    this.appService.getAssessments(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']["total_items"];
      this.assessments = data['items'];
    });
    // }
  }

  public remove(entity: Assessment) {
    const index: number = this.assessments.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete "' + entity.title + '"?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteAssessment(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            })

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  // public startAssessment(assessment) {
  //   if (assessment.attempt_id) {
  //     this.router.navigate(['assessment-center/assessment-center-attempt/'+ assessment.attempt_id]);
  //   } else {
  //     // api call to create assessment attempt
  //     let data = {
  //       // activity_type: "learning_assessment",
  //       assessment_id: assessment.id,
  //       employee_id: this.loggedInUserDetail.employee.id
  //     }
  //     this.appService.createAssessmentAttempt(data).subscribe((res: any) =>
  //     {
  //       console.log(res);
  //       this.router.navigate(['assessment-center/assessment-center-attempt/'+ res.id]);
  //     }, error => {
  //       let errorMessage = error.error.message;
  //       this.message.create('error', errorMessage, {
  //         nzDuration: 5000
  //       });
  //     });
  //   }
  // }

  // assess(assessment) {
  //   this.router.navigate(['assessment-center/assessment-center-assess/'+ assessment.id], { queryParams: { 'assessassessmentor_id': this.loggedInUserDetail.employee.id} });
  // }

  adminView(assessment) {
    this.router.navigate(['assessment-center/assessment-center-admin-view/' + assessment.id]);
  }

  back() {
    this.router.navigate(['/']);
  }

}
