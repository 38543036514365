import { EmployeeInitiativeStatusRetrieveRequest } from './../../../app.models';
import { AppService } from './../../../app.service';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking

interface Person {
  key: string;
  name: string;
  role: string;
  status: string;
  objectivesFor: string;
  description: string;
}
@Component({
  selector: 'app-initiatives',
  templateUrl: './initiatives.component.html',
  styleUrls: ['./initiatives.component.css']
})
export class InitiativesComponent implements OnInit {
  // calendarOptions: CalendarOptions = {
  //   initialView: 'dayGridMonth'
  // }; 
  loggedInUserDetail: any;
  EmpInitiativeGroupsList: any;
  isVisible = false;  
  i= 0;
  editId: string | null = null;
  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: "Launch the products as per timeline"
    },
    {
      key: '2',
      name: 'Jim Green',
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: "Launch the products as per timeline"
    },
    {
      key: '3',
      name: 'Joe Black',
      role: 'Designer',
      status: 'Approved',
      objectivesFor: 'May-June',
      description: "Launch the products as per timeline"
    }
  ];
  dateFormat = 'dd/MM/yyyy';
  listOfNoOfOption = [
    { label: 'For 1 Apri-2020', value: 1 },
    { label: 'For 1 May-2020', value: 2 },
    { label: 'For 1 June-2020', value: 3 },
    { label: 'For 1 July-2020', value: 4 },
  ];

 
  
  listOfQuestionTypeOption = [
    { label: 'MCQ', value: 'MCQ' },
    { label: 'Subjective', value: 'Subjective' }
  ];
  listOfCompetenciesOption: Array<{ value: string; label: string }> = [];
  listOfSelectedCompetenciesValue = [];
  noOfActivity = [];
  setupCaseStudyForm!: FormGroup;
  participantsForm!: FormGroup;
  feedbackForm!: FormGroup; 
 
  constructor(public fb: FormBuilder,
    private datepipe: DatePipe,
    private appService: AppService) { }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.getEmployeeInitiativeList();
    this.setupCaseStudyForm = this.fb.group({
      idealActiveTime: [null, [Validators.required]],
      caseStudyComment: [null, [Validators.required]],
      title: [null, [Validators.required]],
      listOfSelectedCompetenciesValue: [null, Validators.compose([Validators.required])],
      startDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
      participate:[null],
      assessors_id:[null],
    });
    this.participantsForm = this.fb.group({
      questionTypeSelectedValue: [null],
      question: [null],
      choice1:[null],
      isValid:[null],
    });
    this.feedbackForm = this.fb.group({
      questionTypeSelectedValue: [null],
      question: [null],
      choice1:[null],
      isValid:[null],
    });


    const children: string[] = [];
    for (let i = 10; i < 10000; i++) {
      children.push(`${i.toString(36)}${i}`);
    }
    this.listOfCompetenciesOption = children.map(item => {
      return {
        value: item,
        label: item
      };
    });
 
  }

 

  createFormGroup = (): FormGroup => {
    const formBuilder = new FormBuilder();
    return formBuilder.group({
      listOfSelectedActivityValue: [
        null,
        Validators.compose([Validators.required])
      ]
    });
  }

  activitySubmitForm(e) {
    // console.log(e);
  }
// editable Table functions
startEdit(id: string): void {
  this.editId = id;
} 
stopEdit(): void {
  this.editId = null;
} 
 

// modal action fuctions
showModal(): void {
  this.isVisible = true;
}

handleOk(): void {
  // console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  // console.log('Button cancel clicked!');
  this.isVisible = false;
}

getEmployeeInitiativeList(){
  let rr = new EmployeeInitiativeStatusRetrieveRequest();
  rr.emp_id = this.loggedInUserDetail.employee.id,
  rr.load_subordinate = "yes" 
  this.appService.getEmployeeInitiativeGroups(rr).subscribe((res) => {
    this.EmpInitiativeGroupsList = res['items'];
    // console.log("EmpInitiativeGroupsList", res); 
  })
} 
}
 






