<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">

        <div class="iq-card">
          <div class="iq-card-body">
            <h3 class="py-4 text-center">Team's IDP Forms</h3>

            <nz-table #launchesTable nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total" [nzPageSize]="limit" [nzPageIndex]="page" (nzQueryParams)="onQueryParamsChange($event)" [nzData]="launches">
              <thead>
                <tr>
                  <th class="text-center" [nzSortFn]="sortTitleFn">Title</th>
                  <th class="text-center" [nzSortFn]="sortStartDateFn">Start Date</th>
                  <th class="text-center" [nzSortFn]="sortEndDateFn">End Date</th>
                  <th class="text-center" [nzSortFn]="sortStatusFn">Status</th>
                  <th class="text-center" width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let launch of launches; let i = index;">
                  <td>{{ launch.title }}</td>
                  <td>{{ launch.start_date | date: 'dd-MM-yyyy' }}</td>
                  <td>{{ launch.end_date | date: 'dd-MM-yyyy' }}</td>
                  <td>{{ launch.status.toUpperCase() }}</td>
                  <td class="text-center">
                    <a routerLink="/forms/submissions/{{ launch.id }}" title="View Submission">
                      <i class="ri-eye-fill grayIcon"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
