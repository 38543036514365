<div class="iq-sidebar"
  *ngIf="loggedInUserDetail?.tenant?.enable_dynamic_menu === 'no' || loggedInUserDetail?.tenant?.enable_dynamic_menu == null">
  <div id="sidebar-scrollbar">
    <nav class="iq-sidebar-menu">
      <ul id="iq-sidebar-toggle" class="iq-menu">

        <!-- Menu For Dashboard -->
        <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/dashboard" class="iq-waves-effect"><i class="ri-dashboard-fill"></i><span>Dashboard</span></a>
        </li> -->
        <!-- Menu for Dashboard ends here -->

        <!-- Menu For social Feed -->
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/" class="iq-waves-effect"><i class="las la-newspaper"></i><span>Social Feed</span></a>
        </li>
        <!-- Menu for social feed ends here -->


        <!-- Menu for Talent card -->
        <li
          *ngIf="(talentCardStatus == 'active'&& loggedInUserDetail?.user_type === 'admin') || (talentCardStatus == 'active' && loggedInUserDetail?.user_type === 'tenant_admin')">
          <a href="#talent" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="ri-open-arm-line"></i>
            <span>Talent Card</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="talent" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_learning_assessment_creation === 'yes'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/talent-card-form">
                <i class="ri-file-edit-line"></i>
                My Talent Card
              </a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/talent-card-list">
                <i class="ri-file-list-line"></i>
                Talent Card List
              </a>
            </li>
          </ul>
        </li>
        <li routerLinkActive="active"
          *ngIf=" loggedInUserDetail?.user_type === 'employee' && talentCardStatus == 'active'"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="talent-card-form" class="iq-waves-effect">
            <i class="ri-open-arm-line"></i>
            <span>Talent
              Card</span></a>
        </li>
        <!-- Menu for Talent card ends here -->


        <!-- Menu for tenant creation for admin starts here -->
        <li *ngIf="loggedInUserDetail?.user_type === 'admin'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/tenant" class="iq-waves-effect"><i class="las la-map-signs"></i><span>Tenant</span></a>
        </li>
        <!-- Menu for tenant creation for admin ends here -->

        <!-- Menu for Behaviour -->

        <li *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction == 'yes' ">
          <a href="#BehaviourTransactions" class="iq-waves-effect collapsed" data-toggle="collapse"
            aria-expanded="false">
            <i class="ri-refresh-line"></i>
            <span>Behaviour Transactions</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>

          <ul id="BehaviourTransactions" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_behavior_txn_campaign_creation === 'yes'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-transactions/list-of-campaigns">
                <i class="ri-file-list-2-line"></i>List Of Campaigns</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-transactions/list-of-transactions">
                <i class="ri-file-list-2-line"></i>List of Transactions</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-transactions/transaction-grid">
                <i class="ri-grid-fill"></i>Transaction Grid</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-transactions/organization-report">
                <img src="../../../../assets/images/icon/report.png" class="custIcon" alt="icon">Organization Report</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-transactions/function-report">
                <img src="../../../../assets/images/icon/reportf.png" class="custIcon" alt="icon">Function Report</a>
            </li>
            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-transactions/test-dashboard">
                <i class="ri-grid-fill"></i>Test Dashboard</a>
            </li> -->
          </ul>
        </li>
        <!-- Menu For Behaviour ends here -->

        <!-- Menu for learn on the go -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes' && learnGo?.length != 0"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/learn-go" class="iq-waves-effect"><i class="ri-book-read-line"></i><span>Learning On
              the Go</span></a>
        </li>
        <!-- Menu for learn on the go ends here -->

        <!-- Menu for assessment center -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes' ">
          <a href="#ac" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="ri-draft-line"></i>
            <span>Assessment Center</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="ac" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_assessment_centre_creation === 'yes' "
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/assessment-center">
                <i class="ri-file-edit-line"></i>
                Setup
              </a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/assessment-center/my-participations">
                <i class="ri-file-list-line"></i>
                My Participations
              </a>
            </li>

          </ul>
        </li>
        <!-- Menu for assessment center ends here -->

        <!-- Menu for learning assessment -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes'">
          <a href="#la" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="ri-book-read-line"></i>
            <span>Assessment</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="la" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_learning_assessment_creation === 'yes'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/learning-assessment">
                <i class="ri-file-edit-line"></i>
                Setup
              </a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/learning-assessment/my-participations">
                <i class="ri-file-list-line"></i>
                My Participations
              </a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="reportPublishList?.length>0">
              <a routerLink="/learning-assessment/assessment-report-role-view">
                <i class="ri-eye-line"></i>
                Assessment Report
              </a>
            </li>

          </ul>
        </li>
        <!-- Menu for learning assessment ends here -->

        <!-- Menu for 360 degree feedback -->
        <li
          *ngIf="loggedInUserDetail?.tenant?.enable_assessment_centre === 'yes' || loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes'"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="360-degree-feedback-lisitng" class="iq-waves-effect"><i class="las la-users"></i><span>360
              degree Feedback</span></a>
        </li>
        <!-- enu for 360 degree ends here -->


        <!-- Menu for Premium 360 degree feedback  added by Geo Thomas on 06-01-2022-->
        <li
          *ngIf="loggedInUserDetail?.tenant?.enable_premium_360 === 'yes' && loggedInUserDetail?.tenant?.enable_learning_assessment === 'yes'"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="premium-360-degree-feedback-lisitng" class="iq-waves-effect"><i
              class="ri-remixicon-line"></i><span>Premium 360
              degree Feedback</span></a>
        </li>
        <!-- enu for 360 degree ends here -->





        <!-- Menu for learning assessment -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement === 'yes'">
          <a href="#rl" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="ri-book-read-line"></i>
            <span>Learning Reinforcement</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="rl" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_learning_assessment_creation === 'yes'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/reinforcement-learning">
                <i class="ri-file-edit-line"></i>
                Setup
              </a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/reinforcement-learning/my-participations">
                <i class="ri-file-list-line"></i>
                My Participations
              </a>
            </li>
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_learning_assessment_creation === 'yes'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/reinforcement-learning/reinforcement-heatmap">
                <i class="fa fa-map-signs" aria-hidden="true"></i>
                Heat Map
              </a>
            </li>
          </ul>
        </li>
        <!-- Menu for learning assessment ends here -->



        <!-- Menu for development plans -->
        <li>
          <a  href="#idp" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="las la-cog"></i><span>Development Plans</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i> </a>
          <ul id="idp"  class="iq-submenu collapse">
            <ng-container *ngIf="['admin', 'tenant_admin'].indexOf(loggedInUserDetail.user_type) !== -1">
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a routerLink="/forms" class="iq-waves-effect"><i class="las la-tasks"></i><span>Learning Forms</span></a>
              </li>
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a routerLink="/forms/launches" class="iq-waves-effect"><i class="las la-play-circle"></i><span>Launch</span></a>
              </li>
            </ng-container>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/forms/mine" class="iq-waves-effect"><i class="las la-tasks"></i><span>My IDP Forms</span></a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="['admin', 'tenant_admin'].indexOf(loggedInUserDetail.user_type) !== -1 || isManager">

              <a routerLink="/forms/teams-submissions" class="iq-waves-effect"><i class="las la-tasks"></i><span>Team's IDP Forms</span></a>
            </li>
          </ul>
        </li>
        <!-- Menu for development plans -->

        <!-- Menu for Gap Analysys -->

        <li
          *ngIf="(publishedGapAnalysisForMe?.length>0) ||(loggedInUserDetail?.user_type === 'tenant_admin' && loggedInUserDetail?.tenant?.enable_gap_analysis === 'yes') || (gapAnalysisIds?.length>0) || (myGapIds?.length>0) || loggedInUserDetail?.employee?.role?.allow_gap_analysis_creation === 'yes' ">
          <a href="#gap" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <!-- <i class="ri-book-read-line"></i> -->
            <i class="ri-folder-chart-line"></i>
            <span>Gap Analysis</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="gap" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a *ngIf="loggedInUserDetail?.user_type === 'tenant_admin'|| loggedInUserDetail?.employee?.role?.allow_gap_analysis_creation === 'yes' "
                routerLink="/gap-analysis" class="iq-waves-effect"><i class="ri-bar-chart-2-fill"></i><span>Gap
                  Analysis Admin</span></a>
            </li>

            <!-- Menu for Gap View -->

            <li *ngIf="gapAnalysisIds?.length>0" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/gap-analysis/gap-analysis-publish-table" class="iq-waves-effect"><i
                  class="ri-bar-chart-2-fill"></i><span>Gap Analysis Report (Team)</span></a>
            </li>
            <!-- Menu for  Gap view ends here -->
            <!-- Menu for Gap View -->

            <li *ngIf="myGapIds?.length>0" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/gap-analysis/gap-analysis-my-participation" class="iq-waves-effect"><i
                  class="ri-file-chart-2-fill"></i> <span>My Gap Analysis</span></a>
            </li>



            <!-- Menu for Nine Box View -->


            <li *ngIf="publishedGapAnalysisForMe?.length>0" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/gap-analysis/published-gap-analysis-list" class="iq-waves-effect"><i
                class="ri-bar-chart-box-line "></i><span>Gap Analysis Report</span></a>
            </li>


            <!-- Menu for  Gap view ends here -->

            <!--            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_gap_analysis_creation === 'yes'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >-->
            <!--              <a routerLink="/gap-analysis/view" class="iq-waves-effect"><i class="ri-bar-chart-2-fill"></i><span>Gap-->
            <!--              Analysis View</span></a>-->
            <!--            </li>-->
          </ul>
        </li>


        <!-- Menu for Gap analysys ends here -->


        <!-- Menu for 9 Box Report -->
        <li
          *ngIf="loggedInUserDetail?.user_type === 'tenant_admin' && loggedInUserDetail?.tenant?.enable_9box === 'yes' || (nineBoxIds?.length>0) ||(myNineBoxids?.length>0) || (publishedNineBoxidsForVariableFilter?.length>0) ||  loggedInUserDetail?.employee?.role?.allow_nine_box_creation === 'yes' ">
          <a href="#report" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <!-- <i class="ri-book-read-line"></i> -->
            <i class="ri-grid-fill"></i>

            <span>9 Box </span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="report" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li
              *ngIf="(loggedInUserDetail?.user_type === 'tenant_admin' || loggedInUserDetail?.employee?.role?.allow_nine_box_creation === 'yes')"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/nine-box/perfomance-data">
                <i class="ri-file-edit-line"></i>
                Import Performance Data
              </a>
            </li>
            <li
              *ngIf="loggedInUserDetail?.user_type === 'tenant_admin' || loggedInUserDetail?.employee?.role?.allow_nine_box_creation === 'yes' "
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/nine-box">
                <i class="ri-file-list-line"></i>
                Setup
              </a>
            </li>

            <!-- Menu for Nine Box View -->
            <li *ngIf="nineBoxIds?.length>0" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/nine-box/nine-box-publish-table" class="iq-waves-effect"><i
                  class="ri-file-list-line"></i><span>Published 9 Box Report - Role</span></a>
            </li>
            <!-- Menu for  Nine box view ends here -->
            <!-- Menu for Nine Box View -->
            <li *ngIf="myNineBoxids?.length>0" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/nine-box/nine-box-my-participation" class="iq-waves-effect"><i
                  class="ri-bar-chart-box-line "></i><span>My 9 Box</span></a>
            </li>

            <li *ngIf="publishedNineBoxidsForVariableFilter?.length>0" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/nine-box/published-nine-box-list" class="iq-waves-effect"><i
                class="ri-bar-chart-box-line "></i><span>Published 9 Box Report - Filter</span></a>
            </li>
            <!-- Menu for  Nine box view ends here -->
            <!--            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">-->
            <!--              <a routerLink="/nine-box/nine-box-view">-->
            <!--                <i class="ri-file-list-line"></i>-->
            <!--                9 Box View-->
            <!--              </a>-->
            <!--            </li>-->
          </ul>
        </li>
        <!-- Menu for 9 Box Report ends here -->


        <!-- Menu for TruPerform -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes' ">
          <a href="#maximizer" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><img
              src="../../../../assets/images/icon/Group424.svg" class="custIcon" alt="icon"><span>TruPerform</span><i
              class="ri-arrow-right-s-line iq-arrow-right"></i></a>
          <ul id="maximizer" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/admin-dash"> <img
                  src="../../../../assets/images/icon/Group425.svg" class="custIcon" alt="icon">Dashboard</a></li>
            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/maximizer-dashboard"> <img src="../../../../assets/images/icon/Group425.svg"
                                                      class="custIcon" alt="icon">Dashboard</a></li> -->
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                routerLink="/maximizer-dashboard/objectives"><img src="../../../../assets/images/icon/Group426.svg"
                  class="custIcon" alt="icon">Objectives</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                routerLink="/maximizer-dashboard/initiatives"><img src="../../../../assets/images/icon/Group427.svg"
                  class="custIcon" alt="icon">Initiatives</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                routerLink="/maximizer-dashboard/projects"><img src="../../../../assets/images/icon/Group423.svg"
                  class="custIcon" alt="icon">Projects</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                routerLink="/maximizer-dashboard/activity"><img src="../../../../assets/images/icon/Group424.svg"
                  class="custIcon" alt="icon">Activity</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/maximizer-dashboard/max-organization-report">
                <img src="../../../../assets/images/icon/report.png" class="custIcon" alt="icon">Organization Report</a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/maximizer-dashboard/max-function-report">
                <img src="../../../../assets/images/icon/reportf.png" class="custIcon" alt="icon">Function Report</a>
            </li>
            <br> <br>
          </ul>
        </li>
        <!-- Menu for TruPerform ends here -->


        <!-- Menu for survey -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_survey === 'yes'">
          <a href="#survey" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="ri-book-read-line"></i>
            <span>Survey</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="survey" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_survey_creation === 'yes'" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/survey">
                <i class="ri-file-edit-line"></i>
                Setup
              </a>
            </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/survey/my-participation">
                <i class="ri-file-list-line"></i>
                My Participations
              </a>
            </li>
          </ul>
        </li>
        <!-- Menu for survey ends here -->

        <!-- Menu for poll -->

        <!-- Menu for poll ends here -->


        <!-- Menu for announcement -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes'   && announce?.length != 0"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/org-announce" class="iq-waves-effect"><i class="ri-volume-vibrate-line"></i><span>
              Announcement</span></a>
        </li>
        <!-- Menu for announcement ends here -->

        <!-- menu for leader's speak -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes'  && leaderSpeak?.length != 0"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/leader-speak" class="iq-waves-effect"><i class="ri-open-arm-line"></i><span>Leader's
              Speak</span></a>
        </li>
        <!-- Menu for leader's speak ends here -->

        <!-- Menu for success stories -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes'  && success?.length != 0"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/success-stories" class="iq-waves-effect"><i class="ri-award-fill"></i><span>Success
              Stories</span></a>
        </li>
        <!-- menu for success stories ends here -->

        <!-- Menu for organisational setup -->
        <li *ngIf="loggedInUserDetail?.employee?.role?.allow_organisation_setup === 'yes'">
          <a href="#mailbox" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <img src="../../../../assets/images/icon/Group526.svg" class="custIcon" alt="icon">
            <span>Organisation Setup</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
          <ul id="mailbox" class="iq-submenu collapse pl-3" data-parent="#iq-sidebar-toggle">

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/business-unit">
                <img src="../../../../assets/images/icon/group-2-fill.svg" class="custIcon" alt="icon">Business
                Unit</a></li>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/location">
                <img src="../../../../assets/images/icon/map-pin-line.svg" class="custIcon" alt="icon">Location</a></li>


            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/function">
                <img src="../../../../assets/images/icon/function-line.svg" class="custIcon" alt="icon">Function</a>
            </li>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/department">
                <img src="../../../../assets/images/icon/building-line.svg" class="custIcon" alt="icon">Department</a>
            </li>


            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/role">
                <img src="../../../../assets/images/icon/folder-lock-line.svg" class="custIcon" alt="icon">Role</a></li>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/grade">
                <img src="../../../../assets/images/icon/medal-line.svg" class="custIcon" alt="icon">Grade</a>
            </li>


            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/employee">
                <img src="../../../../assets/images/icon/user-line.svg" class="custIcon" alt="icon">Employees</a></li>

            <li *ngIf="loggedInUserDetail?.tenant?.enable_learnig_reinforcement === 'yes'" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/tags">
                <img src="../../../../assets/images/icon/tags.png" class="custIcon" alt="icon">
                Tags
              </a>
            </li>

            <li>
              <a href="#mailb" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                <img src="../../../../assets/images/icon/Group526.svg" class="custIcon" alt="icon">
                <span>Competency Settings</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
              <ul id="mailb" class="iq-submenu collapse pl-3" data-parent="#mailb">

                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/competency"
                    class="iq-waves-effect"> <img src="../../../../assets/images/icon/settings-6-line.svg"
                      class="custIcon" alt="icon"><span>Competencies</span></a>
                </li>

                <!-- Code Added By Althaf -->
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/desired-level">
                    <img src="../../../../assets/images/icon/reorder.svg" class="custIcon" alt="icon">Desired Score</a>
                </li>
                <!-- Ends -->



              </ul>
            </li>


            <span *ngIf="loggedInUserDetail?.tenant?.enable_profit_maximiser === 'yes' ">
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                  routerLink="/strategic-perspectives"><img src="../../../../assets/images/icon/slideshow-line.svg"
                    class="custIcon" alt="icon">Strategic Perspective</a></li>
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                  routerLink="/annual-priority"><img src="../../../../assets/images/icon/Group425.svg" class="custIcon"
                    alt="icon">Annual Priority</a></li>
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                  routerLink="/objective-master"><img src="../../../../assets/images/icon/Group427.svg" class="custIcon"
                    alt="icon">Objective Master</a></li>
            </span>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/notification-setup">
                <i class="ri-notification-2-line"></i>
                Notification Setup</a></li>

            <!-- Indication menus -->
            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/lead-indication">
                <i class="fa fa-indent" aria-hidden="true"></i>
                Lead Indicator</a></li>

              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/lag-indication">
                <i class="fa fa-ravelry" aria-hidden="true"></i>
                  Lag Indicator</a></li>
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/current-indication">
                <i class="fa fa-superpowers" aria-hidden="true"></i>
                  Current Indicator</a></li>
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/features">
                <i class="fa fa-external-link-square" aria-hidden="true"></i>
                  Features</a></li> -->

            <!-- Terms & Conditions -->

            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/terms-and-conditions">
                <i class="ri-notification-2-line"></i>
                Terms & Conditions</a></li>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/privacy-policy">
                <i class="ri-notification-2-line"></i>
                Privacy Policy</a></li> -->

            <!-- Privacy Policy -->

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/settings"><img
                  src="../../../../assets/images/icon/settings-2-line.svg" class="custIcon" alt="icon">Settings</a></li>

            <li routerLinkActive="active" *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction == 'yes' "
              [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/behaviour-structure">
                <img src="../../../../assets/images/icon/settings-2-line.svg" class="custIcon" alt="icon">
                Behaviour Structure
              </a>
            </li>

            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/LR-enhancement">
                <img src="../../../../assets/images/icon/settings-2-line.svg" class="custIcon" alt="icon">
                Learner Level
              </a>
            </li>

            <li *ngIf="loggedInUserDetail?.tenant?.enable_premium_360 === 'yes' ">
              <a href="#premium360" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                <img src="../../../../assets/images/icon/premium.png" class="custIcon" alt="icon">
                <span>Premium</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
              <ul id="premium360" class="iq-submenu collapse pl-3" data-parent="#premium360">
                <a href="#template" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                  <img src="../../../../assets/images/icon/templates.png" class="custIcon" alt="icon">
                  <span>Templates</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                <ul id="template" class="iq-submenu collapse pl-3" data-parent="#template">
                  <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a
                      routerLink="learning-assessment/premium-360">
                      <img src="../../../../assets/images/icon/setup.png" class="custIcon" alt="icon">Setup</a>
                  </li>
                </ul>
              </ul>
            </li>

            <!-- Menu for Pii Request handle here -->
            <li *ngIf="loggedInUserDetail?.employee?.role?.allow_learning_assessment_creation === 'yes'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/pii-requests" class="iq-waves-effect collapsed" aria-expanded="false">
                <i class="las la-user-slash"></i>
                <span>PII Request</span>
              </a>
            </li>
            <!-- Menu for Pii Request handle end  here -->

          </ul>
        </li>
        <!-- Menu for organisational  setup ends here -->

        <!-- Menu for Activity log -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes' " routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/activity/" class="iq-waves-effect"><i class="las la-newspaper"></i><span>Activity</span></a>
        </li>
        <!-- <li *ngIf="loggedInUserDetail?.tenant?.enable_labelled_content === 'yes' " routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/employee-report/" class="iq-waves-effect"><i
            class="la la-file-text"></i><span>Employee Report</span></a>
        </li> -->

        <!-- Menu for activity log ends here -->

        <!-- Menu for HRAPP and LMS redirection -->
        <!-- <li routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/tickets/" class="iq-waves-effect"><i
            class="las la-newspaper"></i><span>Tickets</span></a>
        </li> -->
        <!-- Menu for activity log ends here -->

        <!-- Menu for learning assessment -->
        <li>
          <a href="#tick" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <!-- <i class="ri-book-read-line"></i> -->
            <i class="ri-service-line"></i> <span>Customer Support</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="tick" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/tickets">
                <i class="ri-file-edit-line"></i>
                Tickets
              </a>
            </li>
            <li
              *ngIf="loggedInUserDetail?.user_type === 'admin' || loggedInUserDetail?.user_type === 'tenant_admin' || loggedInUserDetail?.user_type === 'espl_support'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/tickets-dashboard">
                <i class="ri-file-list-line"></i>
                Dashboard
              </a>
            </li>
          </ul>
        </li>
        <!-- Menu for learning assessment ends here -->
        <!-- Menu for Reports -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_premium_360 === 'yes'">
          <a href="#repo" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
            <span>Reports</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="repo" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li *ngIf="loggedInUserDetail?.tenant?.enable_premium_360 === 'yes'" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/report/quick-360">
                <i class="ri-file-edit-line"></i>
                Quick 360
              </a>
            </li>
            <li *ngIf="loggedInUserDetail?.tenant?.enable_behaviour_transaction == 'yes' " routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/report/continuous-360">
                <i class="ri-file-edit-line"></i>
                Continuous 360
              </a>
            </li>
          </ul>
        </li>
        <!-- Menu for Reports ends here -->

        <!-- Menu for HRAPP and LMS redirection -->
        <li *ngIf="loggedInUserDetail?.tenant?.enable_hrapp === 'yes'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/hrapp-redirection/" class="iq-waves-effect"><i
              class="las la-newspaper"></i><span>HRAPP</span></a>
        </li>

        <li *ngIf="loggedInUserDetail?.tenant?.enable_lms === 'yes'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/lms-redirection/" class="iq-waves-effect"><i class="las la-newspaper"></i><span>LMS</span></a>
        </li>
        <!-- Menu for HRAPP and LMS redirection ends here-->

        <!-- Menu for learn on the go -->
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/help-videos" class="iq-waves-effect">
            <!-- <i class="ri-book-read-line"></i> -->
            <i class="ri-play-line"></i>
            <span>Help
              Videos</span></a>
        </li>
        <!-- Menu for learn on the go ends here -->

        <!-- Menu for My Nudges -->
        <!-- <li routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="/my-nudges" class="iq-waves-effect"><i class="ri-notification-2-fill"></i><span>My Nudges</span></a>
        </li> -->
        <!-- Menu for My Nudges ends here -->

        <li *ngIf="loggedInUserDetail?.tenant?.enable_lms === 'yes'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="ajna-redirection-dashboard" class="iq-waves-effect"><i class="las la-newspaper"></i><span>AJNA
              Dashboard</span></a>
        </li>
        <li *ngIf="loggedInUserDetail?.tenant?.enable_lms === 'yes'" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a routerLink="ajna-redirection-vstream" class="iq-waves-effect"><i class="las la-newspaper"></i><span>AJNA
              Video Stream</span></a>
        </li>

        <!-- Menu for Succession Management -->
        <li *ngIf="loggedInUserDetail?.user_type === 'tenant_admin' || (loggedInUserDetail?.user_type === 'employee' && variableFIlterCheck)">
          <a href="#smgmnt" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
            <i class="ri-team-fill" aria-hidden="true"></i>
            <span>Succession Management</span>
            <i class="ri-arrow-right-s-line iq-arrow-right"></i>
          </a>
          <ul id="smgmnt" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
            <li routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/succession-management/critical-roles">
                <i class="ri-suitcase-2-fill"></i>
                Critical Roles
              </a>
            </li>
            <li routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/succession-management/employees">
                <i class="ri-user-2-fill"></i>
                Employees
              </a>
            </li>
            <li routerLinkActive="active" *ngIf="loggedInUserDetail?.user_type === 'tenant_admin'"
                [routerLinkActiveOptions]="{exact: true}">
              <a routerLink="/succession-management/reports-dashboard">
                <i class="ri-file-chart-fill"></i>
                Reports & Dashboard
              </a>
            </li>
          </ul>
        </li>
        <!-- Menu for Succession Management ends here -->


        <div class="p-3"></div>
        <div class="p-3"></div>
        <!-- <div class="p-3" style="text-align: center;">Powered by</div> -->
        <div class="p-3">
          <img src="../../../../assets/images/logos/lupin.png" class="img-fluid" alt=""
            style="height: 160px; width: 160px;">
        </div>

      </ul>
    </nav>

  </div>
</div>


<div class="iq-sidebar" *ngIf="loggedInUserDetail?.tenant?.enable_dynamic_menu === 'yes'">
  <div id="sidebar-scrollbar">
    <nav class="iq-sidebar-menu">

      <!-- level - 1 -->
      <ul id="iq-sidebar-toggle" class="iq-menu">
        <div *ngFor="let item of leftMenuItems">

          <li
            *ngIf="item?.is_parent == 'no' && item?.is_enabled == 'yes' && checkUserTypeAllowed(item) && checkUserRoleAllowed(item)"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a routerLink="{{ item?.page?.web_route }}" class="iq-waves-effect">
              <img [src]="getImageUrl(item)" alt="" class="menu-icon">
              <div class="menu-text">{{ item?.title }}</div>
            </a>
          </li>

          <li
            *ngIf="item?.is_parent == 'yes' && item?.is_enabled == 'yes' && checkUserTypeAllowed(item) && checkUserRoleAllowed(item)">
            <a href="#item-{{item?.id}}" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
              <img [src]="getImageUrl(item)" alt="" class="menu-icon">
              <div class="menu-text">{{ item?.title }}</div>
              <i class="ri-arrow-right-s-line iq-arrow-right"></i>
            </a>

            <!-- level - 2 -->
            <ul id="item-{{item?.id}}" class="iq-submenu collapse" data-parent="#item-{{item?.id}}">
              <div *ngFor="let childl1 of item?.children">

                <li
                  *ngIf="childl1?.is_parent == 'no' && childl1?.is_enabled == 'yes' && checkUserTypeAllowed(childl1) && checkUserRoleAllowed(childl1)"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <a routerLink="{{ childl1?.page?.web_route }}" class="iq-waves-effect">
                    <img [src]="getImageUrl(childl1)" alt="" class="menu-icon">
                    <div class="menu-text">{{ childl1?.title }}</div>
                  </a>
                </li>

                <li
                  *ngIf="childl1?.is_parent == 'yes' && childl1?.is_enabled == 'yes' && checkUserTypeAllowed(childl1) && checkUserRoleAllowed(childl1)">
                  <a href="#item-{{childl1?.id}}" class="iq-waves-effect collapsed" data-toggle="collapse"
                    aria-expanded="false">
                    <img [src]="getImageUrl(childl1)" alt="" class="menu-icon">
                    <div class="menu-text">{{ childl1?.title }}</div>
                    <i class="ri-arrow-right-s-line iq-arrow-right"></i>
                  </a>

                  <!-- level - 3 -->
                  <ul id="item-{{childl1?.id}}" class="iq-submenu collapse" data-parent="#item-{{childl1?.id}}">
                    <div *ngFor="let childl2 of childl1?.children">

                      <li
                        *ngIf="childl2?.is_parent == 'no' && childl2?.is_enabled == 'yes' && checkUserTypeAllowed(childl2) && checkUserRoleAllowed(childl2)"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a routerLink="{{ childl2?.page?.web_route }}" class="iq-waves-effect">
                          <img [src]="getImageUrl(childl2)" alt="" class="menu-icon">
                          <div class="menu-text">{{ childl2?.title }}</div>
                        </a>
                      </li>

                      <li
                        *ngIf="childl2?.is_parent == 'yes' && childl2?.is_enabled == 'yes' && checkUserTypeAllowed(childl2) && checkUserRoleAllowed(childl2)">
                        <a href="#item-{{childl2?.id}}" class="iq-waves-effect collapsed" data-toggle="collapse"
                          aria-expanded="false">
                          <img [src]="getImageUrl(childl2)" alt="" class="menu-icon">
                          <div class="menu-text">{{ childl2?.title }}</div>
                          <i class="ri-arrow-right-s-line iq-arrow-right"></i>
                        </a>

                        <!-- level - 4 -->
                        <ul id="item-{{childl2?.id}}" class="iq-submenu collapse" data-parent="#item-{{childl2?.id}}">
                          <div *ngFor="let childl3 of childl2?.children">
                            <li
                              *ngIf="childl3?.is_parent == 'no' && childl3?.is_enabled == 'yes' && checkUserTypeAllowed(childl3) && checkUserRoleAllowed(childl3)"
                              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              <a routerLink="{{ childl3?.page?.web_route }}" class="iq-waves-effect">
                                <img [src]="getImageUrl(childl3)" alt="" class="menu-icon">
                                <div class="menu-text">{{ childl3?.title }}</div>
                              </a>
                            </li>
                          </div>
                        </ul>

                      </li>

                    </div>
                  </ul>
                </li>

              </div>
            </ul>
          </li>
        </div>

        <div class="p-3"></div>
        <div class="p-3"></div>
        <div class="p-3"></div>
        <div class="p-3"></div>

      </ul>

    </nav>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
