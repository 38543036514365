<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="text-center mt-3">
                            <h3>Hello {{loggedInUserDetail.employee.display_name}},</h3>
                        <!-- <p>Your objectives for the year 20-21 is set up. Review the objectives, modify weightage in consultation with your manager. Once objectives are reviewed, set up the initiatives for the month and proceed with your daily activities.</p> -->
                        </div>

                        <div class="stepwizard mt-4 mb-0" >
                            <div class="stepwizard-row setup-panel text-center">
                                <div class="wizard-step tab-first"  [ngClass]="{'active' : contentIndex == 1}">
                                    <a class="btn" [routerLink]="['/maximizer-dashboard/objectives']"   
                                    [ngClass]="{'wizardDisabled' : employeeObjectives?.status == 'draft' || employeeObjectives?.status == 'accepted'}">
                                        <div class="iconBox">
                                        <img class="icon" src="../../../assets/images/icon/target.svg" alt="" sizes="" > 
                                        </div>
                                       <span>Complete Objective Setup</span> 
                                    </a>
                                </div>
                                <div class="wizard-step tab-second" >
                                    <a class="btn btn-default" [routerLink]="['/maximizer-dashboard/initiatives']"   [ngClass]="{'wizardDisabled' : employeeInitiatives?.status == 'draft' || employeeInitiatives?.status == 'initiative not defined'}">
                                        <div class="iconBox">
                                        <img class="icon" src="../../../assets/images/icon/goal.svg" alt="" sizes="" >
                                     </div>
                                        <span>Complete Initiative Setup</span>
                                        <!-- <p>(26th Aug, 2021 - 25th Sept 2021)</p> -->
                                    </a>
                                </div> 
                                 
                            </div>
                        </div>
                        <div class="col-md-12 text-center statusBox">
                            <div class="status">
                                <h6>{{employeeObjectives?.status ? ( employeeObjectives?.status | titlecase): 'Not Propogated'}}</h6>
                                
                                <span *ngIf="employeeObjectives?.status != 'approved'">
                                    There is no 'Approved' Objective Set for the current period 
                                </span>
                            </div> 

                            <div class="status">
                            <h6>{{employeeInitiatives?.status ? ( employeeInitiatives?.status | titlecase): 'Initiative Not Defined'}} </h6>
                            <span *ngIf="employeeInitiatives?.status != 'completed'">There is no 'Completed' Initiative setup for the current month</span>
                            </div>
                        </div>
                        <div class="row setup-content" *ngIf="contentIndex == 1">
                            <div  class="  col-sm-12">  
                                  <div class="col-md-4">
                                    <h3 class="py-4">Objectives1</h3>
                                  </div>
                                  <div class="col-md-4">
                                    <nz-form-item> 
                                        <nz-form-control nzErrorTip="Please input Types!">
                                            <nz-input-group class="form-group">
                                                <nz-select
                                                    nzPlaceHolder="Please select"
                                                    [nzOptions]="listOfNoOfOption"
                                                    >
                                                </nz-select>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                  </div>
                                    <nz-table #basicTable [nzData]="listOfData">
                                        <thead>
                                          <tr>
                                            <th>Employee Name</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Objectives For</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let data of basicTable.data">
                                            <td>{{ data.name }}</td>
                                            <td>{{ data.role }}</td>
                                            <td>{{ data.status }}</td>
                                            <td>{{ data.objectivesFor }}</td>
                                            <td>
                                                <a (click)="showModal()"><i nz-icon nzType="edit" nzTheme="outline"></i></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </nz-table>
                                      <nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Objectives" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                        <nz-form-item> 
                                            <nz-form-control nzErrorTip="Please input Types!">
                                                <nz-input-group class="form-group">
                                                    <nz-select
                                                        nzPlaceHolder="Please select"
                                                        [nzOptions]="listOfNoOfOption" >
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item> 
                                            <nz-form-control nzErrorTip="Please input Types!">
                                                <nz-input-group class="form-group">
                                                    <nz-select
                                                        nzPlaceHolder="Please select"
                                                        [nzOptions]="listOfNoOfOption" >
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        
                                    </nz-modal>
                                      <nz-form-item>
                                        <nz-form-control>
                                            <button nz-button nzType="primary"
                                               (click)="next()">Next</button>
                                        </nz-form-control>
                                    </nz-form-item>
                            
                            </div>
                        </div>
                        <div class="row setup-content" *ngIf="contentIndex == 2"> 
                            <div class="col-sm-12">  
                                <div class="col-md-12">
                                  <h3 class="py-2">Setup Up Objectives2</h3>
                                  <p>Review your objectives, accept them & proceed to modify weightages /define targets</p>
                                </div>
                                <div class="col-md-4">
                                  <nz-form-item> 
                                      <nz-form-control nzErrorTip="Please input Types!">
                                          <nz-input-group class="form-group">
                                              <nz-select
                                                  nzPlaceHolder="Please select"
                                                  [nzOptions]="listOfNoOfOption" >
                                              </nz-select>
                                          </nz-input-group>
                                      </nz-form-control>
                                  </nz-form-item>
                                </div>
                                  <nz-table #nzTable [nzData]="listOfData"  nzTableLayout="fixed">
                                      <thead>
                                        <tr>
                                          <th>Employee Name</th>
                                          <th>Weightage</th>
                                          <th>AP Weightage</th>
                                          <th>Obj Weightage</th>
                                          <th>UOM</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <ng-container *ngFor="let data of nzTable.data">
                                        <tr>
                                          <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)">{{ data.name }}</td>
                                          <td>{{ data.role }}</td>
                                          <td>{{ data.status }}</td>
                                          <td>{{ data.objectivesFor }}</td>
                                          <td>
                                            Cr/Hrs
                                          </td>
                                        </tr>
                                        <tr [nzExpand]="expandSet.has(data.id)">
                                            <span>{{ data.description }}</span>
                                          </tr>
                                        </ng-container>
                                      </tbody>
                                    </nz-table>
                                    <nz-form-item>
                                        <nz-form-control>
                                            <button nz-button nzType="primary"
                                               (click)="next()">Accept & Define Targets</button>
                                        </nz-form-control>
                                    </nz-form-item>

                          </div> 
                        </div>
                        <div class="row setup-content" *ngIf="contentIndex == 3"> 
                            <div class="col-sm-12">  
                                <div class="col-md-12">
                                  <h3 class="py-2">Setup Up Objectives3</h3>
                                  <p>Review your objectives, accept them & proceed to modify weightages /define targets</p>
                                </div>
                                <div class="col-md-4">
                                  <nz-form-item> 
                                      <nz-form-control nzErrorTip="Please input Types!">
                                          <nz-input-group class="form-group">
                                              <nz-select
                                                  nzPlaceHolder="Please select"
                                                  [nzOptions]="listOfNoOfOption" >
                                              </nz-select>
                                          </nz-input-group>
                                      </nz-form-control>
                                  </nz-form-item>
                                </div>
                                  <nz-table #nzTable [nzData]="listOfData"  nzTableLayout="fixed">
                                      <thead>
                                        <tr>
                                          <th>Employee Name</th>
                                          <th>Weightage</th>
                                          <th>AP Weightage</th>
                                          <th>Obj Weightage</th>
                                          <th>UOM</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <ng-container *ngFor="let data of nzTable.data">
                                        <tr>
                                          <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)">{{ data.name }}</td>
                                          <td>{{ data.role }}</td>
                                          <td>{{ data.status }}</td>
                                          <td>{{ data.objectivesFor }}</td>
                                          <td> Cr/Hrs </td>
                                          <td>
                                              <a (click)="showModal()"><i nz-icon nzType="edit" nzTheme="outline"></i></a> 
                                          </td>
                                        </tr>
                                        <tr [nzExpand]="expandSet.has(data.id)">
                                            <span>{{ data.description }}</span>
                                          </tr>
                                        </ng-container>
                                      </tbody>
                                    </nz-table>
                                    
                                    <nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Objectives" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                        <nz-form-item> 
                                            <nz-form-control nzErrorTip="Please input Types!">
                                                <nz-input-group class="form-group">
                                                    <nz-select
                                                        nzPlaceHolder="Please select"
                                                        [nzOptions]="listOfNoOfOption" >
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        <nz-form-item> 
                                            <nz-form-control nzErrorTip="Please input Types!">
                                                <nz-input-group class="form-group">
                                                    <nz-select
                                                        nzPlaceHolder="Please select"
                                                        [nzOptions]="listOfNoOfOption" >
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                        
                                    </nz-modal>
                                    <nz-form-item>
                                        <nz-form-control>
                                            <button nz-button nzType="primary"
                                               (click)="next()">Save</button>
                                        </nz-form-control>
                                    </nz-form-item>
                          </div>
                          
                        </div>  
                        <div class="row setup-content" *ngIf="contentIndex == 4"> 
                            <div  class="  col-sm-12">  
                                <div class="col-md-4">
                                  <h3 class="py-4">Initiatives 4</h3>
                                </div>
                                <div class="col-md-4">
                                  <nz-form-item> 
                                      <nz-form-control nzErrorTip="Please input Types!">
                                          <nz-input-group class="form-group">
                                              <nz-select
                                                  nzPlaceHolder="Please select Month"
                                                  [nzOptions]="listOfNoOfOption"    
                                                  >
                                              </nz-select>
                                          </nz-input-group>
                                      </nz-form-control>
                                  </nz-form-item>
                                </div>
                                <div class="col-md-4">
                                    <h3> <i nz-icon class="mb-2" (click)="addRow()"   nzType="plus-circle" nzTheme="fill"></i></h3>
                                </div>
                             <nz-table #editRowTable nzBordered [nzData]="listOfData">
                                      <thead>
                                        <tr>
                                          <th>Employee Name</th>
                                          <th>Role</th>
                                          <th>Status</th>
                                          <th>Objectives For</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let data of editRowTable.data" class="editable-row">
                                          <td>
                                            <div class="editable-cell" [hidden]="editId === data.id" (click)="startEdit(data.id)">
                                                {{ data.name }}
                                              </div>
                                              <input [hidden]="editId !== data.id" type="text" nz-input [(ngModel)]="data.name" (blur)="stopEdit()" />
                                          </td>
                                          <td>{{ data.role }}</td>
                                          <td>{{ data.status }}</td>
                                          <td>{{ data.objectivesFor }}</td>
                                          <td>
                                              <a (click)="showModal()"><i nz-icon nzType="edit" nzTheme="outline"></i></a> | 
                                              <a nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteRow(data.key)">Delete</a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </nz-table> 
                                    <nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Objectives" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                      <nz-form-item> 
                                          <nz-form-control nzErrorTip="Please input Types!">
                                              <nz-input-group class="form-group">
                                                  <nz-select
                                                      nzPlaceHolder="Please select"
                                                      [nzOptions]="listOfNoOfOption" >
                                                  </nz-select>
                                              </nz-input-group>
                                          </nz-form-control>
                                      </nz-form-item>
                                      <nz-form-item> 
                                          <nz-form-control nzErrorTip="Please input Types!">
                                              <nz-input-group class="form-group">
                                                  <nz-select
                                                      nzPlaceHolder="Please select"
                                                      [nzOptions]="listOfNoOfOption" >
                                                  </nz-select>
                                              </nz-input-group>
                                          </nz-form-control>
                                      </nz-form-item>
                                      
                                  </nz-modal>
                                    <nz-form-item>
                                      <nz-form-control>
                                          <button nz-button nzType="primary"
                                             (click)="next()">Next</button>
                                      </nz-form-control>
                                  </nz-form-item>
                          
                          </div>
                          
                        </div>
                        <div class="row setup-content" *ngIf="contentIndex == 5"> 
                            <div class="row">  
                                <div class="col-md-12 ">
                                  <h3 class="py-2">Initiatives 5</h3>
                                  <h5>Initiatives for September is not defined, setup initiatives OR copy from previous month</h5>
                                </div>
                                <div class="col-md-4">
                                  <nz-form-item> 
                                      <nz-form-control nzErrorTip="Please Select Month!"> 
                                          <nz-form-label nzFor=" Select the Month:" nzNoColon="false"> Select the Month</nz-form-label>
                                          <nz-input-group class="form-group">
                                              <nz-select
                                                  nzPlaceHolder="Please select"
                                                  [nzOptions]="listOfNoOfOption" >
                                              </nz-select>
                                          </nz-input-group>
                                      </nz-form-control>
                                  </nz-form-item>
                                </div>
                                <div class="col-md-12 text-center">
                                    <h3> <i nz-icon class="m-2" (click)="next()"  nzType="plus-circle" nzTheme="fill"></i>
                                        <i nz-icon class="m-2" nzType="copy" nzTheme="fill"></i>
                                    </h3>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row setup-content" *ngIf="contentIndex == 6"> 
                            <div class="row">
                                <div class="col-md-12 ">
                                    <h3 class="py-2">Initiatives 6</h3> 
                                  </div>
                                  <div class="col-md-4">
                                    <nz-form-item> 
                                        <nz-form-control nzErrorTip="Please Select Month!"> 
                                            <nz-form-label nzFor=" Select the Month:" nzNoColon="false"> Select the Month</nz-form-label>
                                            <nz-input-group class="form-group">
                                                <nz-select
                                                    nzPlaceHolder="Please select"
                                                    [nzOptions]="listOfNoOfOption" >
                                                </nz-select>
                                            </nz-input-group>
                                            <nz-form-label nzFor=" Select the Month:" nzNoColon="false"> 26th Aug- 25th Sep</nz-form-label>
                                        </nz-form-control>
                                    </nz-form-item>
                                  </div>

                                  <nz-table #nzTable [nzData]="listOfData"  nzTableLayout="fixed">
                                    <thead>
                                      <tr>
                                        <th>Employee Name</th>
                                        <th>Weightage</th>
                                        <th>Target</th>
                                        <th>Weightage</th>
                                        <th>Targer</th>
                                        <th>Estimate Efforts</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <ng-container *ngFor="let data of nzTable.data">
                                      <tr>
                                        <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)">{{ data.name }}</td>
                                        <td>{{ data.role }}</td>
                                        <td> 1 Cr </td>
                                        <td>{{ data.objectivesFor }}</td>
                                        <td> 3 Cr </td>
                                        <td> 30 Hrs </td>
                                        <td>
                                            <a (click)="showModal()"><i nz-icon nzType="edit" nzTheme="outline"></i></a> 
                                        </td>
                                      </tr>
                                      <tr [nzExpand]="expandSet.has(data.id)">
                                          <span>{{ data.description }}</span>
                                        </tr>
                                      </ng-container>
                                    </tbody>
                                  </nz-table>
                                  <!-- <full-calendar [options]="calendarOptions"></full-calendar> -->

                                  
                                  <nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Objectives" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
                                      <nz-form-item> 
                                          <nz-form-control nzErrorTip="Please input Types!">
                                              <nz-input-group class="form-group">
                                                  <nz-select
                                                      nzPlaceHolder="Please select"
                                                      [nzOptions]="listOfNoOfOption" >
                                                  </nz-select>
                                              </nz-input-group>
                                          </nz-form-control>
                                      </nz-form-item>
                                      <nz-form-item> 
                                          <nz-form-control nzErrorTip="Please input Types!">
                                              <nz-input-group class="form-group">
                                                  <nz-select
                                                      nzPlaceHolder="Please select"
                                                      [nzOptions]="listOfNoOfOption" >
                                                  </nz-select>
                                              </nz-input-group>
                                          </nz-form-control>
                                      </nz-form-item>
                                      
                                  </nz-modal>
                                  <nz-form-item>
                                      <nz-form-control>
                                          <button nz-button nzType="primary"
                                             (click)="next()">Save</button>
                                      </nz-form-control>
                                  </nz-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </div>
    </div>