<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <h3 class="py-4 text-center">Premium 360 Degree Feedback</h3>
              <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading"
                        [nzTotal]="total" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
                        [nzData]="feedbackInvites" (nzQueryParams)="onQueryParamsChange($event)"  [nzData]="['']">
                <thead>
                <tr>
                  <th>Assessment Title</th>
                  <th>Activity Title</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Participant</th>
                  <th>Stakeholder</th>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>View Feedback</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let invite of feedbackInvites">
                  <td>{{ invite.assessment_title }}</td>
                  <td>{{ invite.activity_title }}</td>
                  <td>{{ invite.start_date }}</td>
                  <td>{{ invite.end_date }}</td>
                  <td>{{invite.participant}}</td>
                  <td>{{invite.stakeholder}}</td>
                  <td>
                    <div class="d-flex justify-content-between">
                      <span>{{invite.status}}</span>
                    </div>
                  </td>
                  <td>
                    <button *ngIf="invite?.premium_360_multiple_attempt=='new' || invite?.premium_360_multiple_attempt=='completed'" nz-button nzType="primary" (click)="giveFeedback(invite)"  [disabled]="!isActiveDateRange(invite?.start_date,invite?.end_date)"><span>Give Feedback</span></button>
                    <button *ngIf="invite?.premium_360_multiple_attempt=='pending'" nz-button nzType="primary" (click)="giveFeedback(invite)"  [disabled]="!isActiveDateRange(invite?.start_date,invite?.end_date)"><span>Continue Feedback</span></button>
                  </td>
                  <td>
                    <button *ngIf="invite.status=='Feedback Given'; else elseBlock" nz-button nzType="primary" (click)="viewPremiumFeedback(invite)">View Feedback</button>
                    <ng-template #elseBlock>
                      N/A
                    </ng-template>

                  </td>
                </tr>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  