import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import {AppService} from '../../app.service';
import { Ticket, TicketRetrieveRequest, ReferenceDataRetrieveRequest, ReferenceData } from 'src/app/app.models';
import {NzMessageService} from 'ng-zorro-antd/message';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common'
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  ticketForm!: FormGroup;
  tickets: Ticket[] = [];
  listOfDisplayData: Ticket[] = [];
  refrence_data_status: ReferenceData[] = []
  refrence_data: ReferenceData[] = []
  constructor(private fb: FormBuilder,
    private appService: AppService,
    private message: NzMessageService,
    public datepipe: DatePipe,
    private modal: NzModalService,

    ) { }
  date=new Date();
  loading = false;
  isFormLoading = false;
  total = 10;
  totalTicket = 10;
  pageSize = 100;
  pageIndex = 1
  pageSize1 = null;
  pageIndex1 = 1
  tenantUser: boolean = true;
  filterControl = new FormControl();
  inputValue: any;
  today = new Date();
  action: string;
  ticketId:any;
  StatusList: any = [];
  loggedInUserDetail;
  ticket_user: any;
  espl_ticket: boolean = false;
  searchValue = '';
  searchValue1 = '';
  visible = false;
  visible1= false;
  isEdit=false;
  disabledDate = (current: Date): boolean =>
  // Can not select days before today and today
  differenceInCalendarDays(current,this.today) > 0;
  // tickets = [];
  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    // console.log('loggedInUserDetail',this.loggedInUserDetail);
    this.ticket_user = this.appService.getTicketUser().subscribe((attempt:any)=>{
      this.ticket_user=attempt;
      // console.log("INSIDE SUBSCRIBE", this.ticket_user)
      // console.log("TIKET USER", this.ticket_user)
      if (this.ticket_user == 0){ this.tenantUser = false}
      else{ this.tenantUser = true;}
      // console.log("tenant USER", this.tenantUser)
    });

    this.resetCreateModal();
    this.ticketForm = this.fb.group({
      ticket_id: [null],
      status_id:[null],
      statusName:[null],
      title:["", [Validators.required]],
      created_date: [this.today,[Validators.required]],
      short_description: [null],
      description: ["", [Validators.required]],
      steps_to_reproduce: [""],
      severity_id:[null],
      priority_id:[null],
      module_id:[null],
      repoened_count:[null],
      fixed_date:[null],
      fixed_within_sla:[false],
      resolution_details:[null],
      resolution_code_id:[null],
      root_cause_details:[null],
      root_cause_code_id:[null],
      first_time_fix:[false]
    });

  }
  contentIndex = 0;
  printedChart: boolean = false;
  tabChange(no) {
    this.contentIndex = no;
    if (this.contentIndex == 1) {
      this.printedChart = false;
    }
  }

  back(){

  }
  onQueryParamsChange(params: NzTableQueryParams){
    this.loading = true;
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    const pageIndex1 = 1;
    const pageSize1 = null;
    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
      this.loadReferenceDataFromServer(pageIndex1, pageSize1, sortField, sortOrder)
  }, 7000);
  }
  loadReferenceDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    let rr = new ReferenceDataRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "asc";
    rr.order_by = sortField ? sortField : "id";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    if(this.tenantUser == true){
    rr.status_type = 'tenant_user';}
    else{rr.status_type = 'espl_support'; }
    // console.log("STATUS TYPE", rr.status_type)
    this.appService.getReferenceData(rr).subscribe(data => {
      this.total = data['_meta']["total_items"];
      this.refrence_data_status = data['items'];
      // console.log("REFERENCE DATA", this.refrence_data_status)
    });
    rr.status_type = null;
    this.appService.getReferenceData(rr).subscribe(data => {
      this.total = data['_meta']["total_items"];
      this.refrence_data = data['items'];
    });


  }



  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;
    this.loadDataFromServer1(this.pageIndex, this.pageSize, null, null);

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new TicketRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "id";
    rr.per_page = pageSize;
    rr.page = pageIndex;
    if(this.tenantUser){   rr.tenant_view = 'yes';}
    else{rr.tenant_view = 'no';}


    this.appService.getTicket(rr).subscribe(data => {
      this.totalTicket = data['_meta']["total_items"];
      this.tickets = data['items'];
      this.loading = false;
    });
  }
  tickets1
  loadDataFromServer1(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new TicketRetrieveRequest()
    rr.sort_order = sortOrder ? sortOrder : "desc";
    rr.order_by = sortField ? sortField : "id";
    rr.per_page = null;
    rr.page = pageIndex;
    if(this.tenantUser){   rr.tenant_view = 'yes';}
    else{rr.tenant_view = 'no';}


    this.appService.getTicket(rr).subscribe(data => {
      // this.totalTicket = data['_meta']["total_items"];
      this.tickets1 = data['items'];
      this.loading = false;
    });
  }

  isFormVisible = false;
  showCreateModal(){
    this.action = "Create"
    this.resetCreateModal();
    this.attachmentFileData = []
    this.isFormVisible = true;
  }
  t_ID:any
  showEditModal(entity){
    this.resetCreateModal();
    this.action = "Edit";
    this.attachmentFileData = [];
    this.ticketId = entity.id;
    this.t_ID = entity.ticket_id;
    this.isEdit=false;
    let statusName = this.refrence_data.filter(e=>(e.id == entity.status_id))
    this.ticketForm.get('statusName').patchValue(statusName[0]?.value);
    this.ticketForm.get('statusName').disable();
    this.getTicketById(this.ticketId);
    this.getComments();
    this.getFileAttachments();
    this.ticketForm.get('ticket_id').disable();

    if(!this.tenantUser){

      if (this.loggedInUserDetail.id != entity.created_user_id){
        this.ticketForm.get('created_date').disable();
        this.ticketForm.get('title').disable();
        this.ticketForm.get('description').disable();
        this.ticketForm.get('steps_to_reproduce').disable();
        this.espl_ticket = false;

      }
      else{this.espl_ticket = true;}
    }

    this.isFormVisible = true;
  }
  statusChange(ev){
      let statusName = this.refrence_data.filter(e=>(e.id == ev))
      this.ticketForm.get('statusName').patchValue(statusName[0]?.value);
      this.ticketForm.get('statusName').disable();
  }
  getTicketById(id){
    this.appService.get('/api/ticket/' + id).subscribe((res: any) => {
      this.ticketForm.patchValue(res);

      this.ticketForm.get('status_id').setValue(res.status_id);
      this.ticketForm.get('priority_id').setValue(res.priority_id);
      this.ticketForm.get('module_id').setValue(res.module_id);
      this.ticketForm.get('fixed_within_sla').setValue( res.fixed_within_sla);
      this.ticketForm.get('first_time_fix').setValue( res.first_time_fix);

    })
  }
  handleModalCancel(){
    this.isFormVisible = false;
  }
  handleModalSubmit(){
    this.isFormLoading = true;
    let subscriber = null;
    let message = null;
    let data ={
      "title" : this.ticketForm.get('title').value,
      "description": this.ticketForm.get('description').value,
      "steps_to_reproduce": this.ticketForm.get('steps_to_reproduce').value,
      "created_date": this.datepipe.transform(this.ticketForm.get('created_date').value, 'yyyy-MM-dd HH:mm')
    }
    if (this.ticketForm.get('status_id').value){
      data['status_id'] = this.ticketForm.get('status_id').value;
    }

    if (this.ticketForm.get('priority_id').value){
      data['priority_id'] = this.ticketForm.get('priority_id').value;

    }
    if (this.ticketForm.get('module_id').value){
      data['module_id'] = this.ticketForm.get('module_id').value;
    }
    if (this.ticketForm.get('severity_id').value){
      data['severity_id'] = this.ticketForm.get('severity_id').value;
    }
    if (this.ticketForm.get('repoened_count').value){
      data['repoened_count'] = this.ticketForm.get('repoened_count').value
    }
    if (this.ticketForm.get('fixed_date').value){
      data['fixed_date'] = this.datepipe.transform(this.ticketForm.get('fixed_date').value, 'yyyy-MM-dd HH:mm')
    }
    if (this.ticketForm.get('fixed_within_sla').value){
      data['fixed_within_sla'] = this.ticketForm.get('fixed_within_sla').value;
    }else{
      data['fixed_within_sla'] = false
    }
     if (this.ticketForm.get('first_time_fix').value){
      data['first_time_fix'] = this.ticketForm.get('first_time_fix').value;
    }
    else{
       data['first_time_fix'] = false}

    if (this.ticketForm.get('resolution_code_id').value){
      data['resolution_code_id'] = this.ticketForm.get('resolution_code_id').value;
    }
     if (this.ticketForm.get('resolution_details').value){
      data['resolution_details'] = this.ticketForm.get('resolution_details').value;
    }
    if (this.ticketForm.get('root_cause_code_id').value){
      data['root_cause_code_id'] = this.ticketForm.get('root_cause_code_id').value;
    }
    if (this.ticketForm.get('root_cause_details').value){
      data['root_cause_details'] = this.ticketForm.get('root_cause_details').value;
    }
    if (this.action === "Create") {
      // console.log("CREAT DATA")
      subscriber = this.appService.createTicket(data);
      message = 'Ticket   "' + this.ticketForm.get('title').value + '" has been added.';
    }
    else if (this.action === 'Edit') {
      subscriber = this.appService.updateTicket(data, this.ticketId);
      message = 'Ticket   "' + this.ticketForm.get('title').value + '" has been updated.';
    }
    subscriber.subscribe(res => {
      this.isFormVisible = false;
      this.isFormLoading = false;

      this.message.create('success', message, {
        nzDuration: 5000
      });
      if (this.action === "Create" && this.attachmentFileData.length != 0) {
        this.attachmentFileData.forEach(element => {
          setTimeout(() => {
            let frmData = new FormData();
            frmData.append("title",element.attachment_title);
            frmData.append("file", element.file_Data.originFileObj);
            frmData.append("ticket_id", res.id);
            this.appService.ticketFilesUpload(frmData).subscribe(res => {
              // console.log('{ res }',res);
            });
          }, 2000);
        });
      }

      this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);
      this.loadReferenceDataFromServer(this.pageIndex1, this.pageSize1, null, null);

    }, error => {
      this.isFormVisible = false;
      this.isFormLoading = false;

      //let errorMessage = error.error.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });

  }
  isFileVisible = false;
  fileAttachment(){
    this.isFileVisible = true;
  }
  fileModalOk(){
    this.isFileVisible = false;
  }
  fileModalCancel(){
    this.isFileVisible = false;
  }
  isAttachmentVisible = false;
  attachment_title = "";
  showattachmentModal(){
    this.isAttachmentVisible = true;
  }
  attachmentModalCancel(){
    this.isAttachmentVisible = false;
  }

  isFalse = false;
  fileData = [];
  fileList;
  handleChange(file){
    // console.log('filelist',file.fileList)
    file.fileList.forEach(element => {
      element.status = "success"
    });
    this.fileData = file.fileList.slice(-1);
    return false;
  };

  attachmentFileData = [];
  fileloader = false;
  attachmentModalOk(){
    if(this.action == "Create"){
      this.attachmentFileData.push({attachment_title:this.attachment_title,file_Data:this.fileData[0]})
      // console.log('attachmentFileData',this.attachmentFileData)
      this.fileData = [];
      this.attachment_title = "";
      this.isAttachmentVisible = false;
    }else{
      this.fileloader = true;
      this.attachmentFileData.push({attachment_title:this.attachment_title,file_Data:this.fileData[0]})
      let frmData = new FormData();
      frmData.append("title",this.attachmentFileData[0].attachment_title);
      frmData.append("file", this.attachmentFileData[0].file_Data.originFileObj);
      frmData.append("ticket_id", this.ticketId);
      this.appService.ticketFilesUpload(frmData).subscribe(res => {
        this.message.create('success', 'File uploaded successfully', {
          nzDuration: 3000
        });
        this.getFileAttachments()
        this.attachmentFileData = [];
        // console.log('{ res }',res);
        this.fileloader = false;
        this.fileData = [];
        this.attachment_title = "";
        this.isAttachmentVisible = false;
      }, err => {
          this.message.create('error', err.error.message, {
            nzDuration: 3000
          });
          // console.log(err);
          this.attachmentFileData = [];
          this.fileloader = false;
      });
    }
  }
  attachmentData
  getFileAttachments(){
    this.appService.get('/api/ticket-attatchment/'+ this.ticketId).subscribe(res => {
      // console.log('resfile',res)
      this.attachmentData = res;
    })
  }
  removeAttachment(data){
    // console.log('remove',data)
    if(this.action == "Create"){
      this.modal.confirm({
        nzTitle: 'Are you sure delete '+ data.file_Data?.name+' '+'?',
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzCancelText: 'No',
        nzOnOk: () =>{
          this.attachmentFileData = this.attachmentFileData.filter(item=>(item.file_Data?.originFileObj?.uid != data.file_Data?.originFileObj?.uid))
          // console.log('remove attachmentFileData',this.attachmentFileData)
        }
      });
    }else{
      this.modal.confirm({
        nzTitle: 'Are you sure delete '+ data.file_path+' '+'?',
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzCancelText: 'No',
        nzOnOk: () =>{
          this.appService.delete('/api/ticket-attatchment/'+ data.id).subscribe(res => {
            // console.log('resfile',res)
            this.message.create('success', 'File deleted successfully', {
              nzDuration: 3000
            });
            this.getFileAttachments()
          },err=>{
            this.message.create('error', err.error.message, {
              nzDuration: 3000
            });
          })
        }
      });
    }

  }
  isAddCommentsVisible = false;
  comments=""
  addComments(){
    this.isAddCommentsVisible = true;
  }

  commentsModalCancel(){
    this.isAddCommentsVisible = false;
  }
  commentsLoading = false;
  commentsModalOk(){
    this.commentsLoading = true;
    const payload ={
      'comment': this.comments,
      'ticket_id': this.ticketId,
      'created_user': this.loggedInUserDetail.id,
      'created_date': this.datepipe.transform(this.date, 'yyyy-MM-dd HH:mm:ss')
    }
    this.appService.createComments(payload).subscribe(res => {
      // console.log('COMMENTS',res)
      this.isAddCommentsVisible = false;
      this.commentsLoading = false;
      this.message.create('success', 'Comments added successfully', {
        nzDuration: 3000
      });
      this.getComments()
      this.comments = "";
    },err=>{
      this.commentsLoading = false;
      this.message.create('error', err.error.message, {
        nzDuration: 3000
      });
    })
  }
  allComments:any
  loadingComments = false;
  getComments(){
  this.loadingComments = true;
  this.appService.getComments(this.ticketId).subscribe(res => {
      // console.log('res',res)
      this.loadingComments = false;
      this.allComments = res.reverse();
    },err=>{
      this.loadingComments = false;
      this.allComments = [];
    });
  }


  isActivityLogVisible = false;
  showActivityLog(){
    this.isActivityLogVisible = true;
  }
  activityLogOk(){
    this.isActivityLogVisible = false;
  }
  activityLogCancel(){
    this.isActivityLogVisible = false;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onInput($event: Event) {

  }
  resetCreateModal(): void {
    this.ticketForm = this.fb.group({
      ticket_id: [null],
      status_id:[null],
      statusName:[null],
      title:["", [Validators.required]],
      created_date: [this.today,[Validators.required]],
      short_description: [null],
      description: ["", [Validators.required]],
      steps_to_reproduce: [""],
      severity_id:[null],
      priority_id:[null],
      module_id:[null],
      repoened_count:[null],
      fixed_date:[null],
      fixed_within_sla :[false],
      resolution_details:[null],
      resolution_code_id:[null],
      root_cause_details:[null],
      root_cause_code_id:[null],
      first_time_fix:[false]

    });
  }

  //Download function
  downloadMeterial(file) {
    var fields = file?.file_path.split('_');
    var folder = fields.slice(-1)
    var fields = folder[0].split('.');
    var folder1 = "https://s3.ap-south-1.amazonaws.com/trulead/" + file.file_path;
    let link = document.createElement("a");
    link.download = file.file_path;
    link.href = folder1;
    link.click();
    link.remove();
  }

  reset(): void {
    this.searchValue = '';
    this.searchValue1='';
    this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);
    this.loadReferenceDataFromServer(this.pageIndex1, this.pageSize1, null, null);
  }

  search(): void {
    this.visible = false;
    // console.log("FULL LIST ", this.tickets1)

    this.tickets = this.tickets1.filter((item:Ticket) => item.title.indexOf(this.searchValue) !== -1);
    // console.log("LIST ", this.tickets)
  }
  search_status(): void {
    this.visible1 = false;
    this.tickets = this.tickets.filter((item:Ticket) => item.ticket_status.indexOf(this.searchValue1) !== -1);
    // console.log("LIST ", this.tickets)
  }
}
