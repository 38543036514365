import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.css']
})
export class BasicDetailsComponent implements OnInit {
  validateForm!: FormGroup;


  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      legalEntity: [null, [Validators.required]],
      contact: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      website: [null, [Validators.required]]
    });
  }
  resetForm(): void {
    this.validateForm.reset();
  }
}
