<div id="content-page" class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
              <h3 class="py-4 text-center">Learner Level</h3>
                  <div class="d-flex justify-content-between align-items-center">
                    <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle"
                      nzTheme="fill"></i></h3>
                  </div>
              <nz-modal nzMaskClosable="false" [(nzVisible)]="isFormVisible" nzTitle="{{action | titlecase }} Learner Level " (nzOnCancel)="handleModalCancel()"
                (nzOnOk)="handleModalSubmit()" nzOkText="Save" nzOkType="primary" [nzOkLoading]="isFormLoading"
                [nzOkDisabled]="!enhancementForm.valid">
                <form nz-form [nzLayout]="'vertical'" [formGroup]="enhancementForm">
                  <div class="row">
                      <div class="col-lg-7">
                        <!-- <nz-form-item>
                            <nz-form-label nzRequired nzFor="Percentage" nzNoColon="false">Percentage %</nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Percentage!" class="percentage">
                                <nz-input-number formControlName="percentage" [nzMax]="100"
                                  placeholder="Enter Percentage">
                                </nz-input-number>
                                <span class="p_styleLR">%</span>
                            </nz-form-control>
                          </nz-form-item> -->
                          <nz-form-item>
                            <nz-form-label nzRequired nzFor="level" nzNoColon="false">Level</nz-form-label>
                            <nz-form-control nzErrorTip="level is mandatory.">
                              <nz-input-group nzSize="large">
                                <input noWhitespace formControlName="level" nz-input nzSize="large" placeholder="level" (keypress)="alphaNumberOnly($event)"/>
                              </nz-input-group>
                            </nz-form-control>
                          </nz-form-item>
                      </div>
                      <div class="col-lg-5">
                        <nz-form-item>
                          <nz-form-label nzRequired nzFor="Rating" nzNoColon="false">Percentage</nz-form-label>
                            <nz-form-control nzErrorTip="Please Enter Percentage!" class="percentage">
                              <nz-input-number formControlName="percentage" [nzMax]="100"
                                placeholder="Enter Percentage" (keypress)="numberOnly($event)">
                              </nz-input-number>
                              <span class="p_styleLR">%</span>
                          </nz-form-control>
                            <!-- <nz-form-control nzErrorTip="Rating is mandatory.">
                              <nz-input-group nzSize="large" [nzPrefix]="suffixIconSearch">
                                <input nz-input formControlName="end_rating"
                                  placeholder="Enter Rating" (keypress)="numberOnly($event)">
                              </nz-input-group>
                              <ng-template #suffixIconSearch>
                                <i>{{icon}}</i>
                              </ng-template>
                            </nz-form-control> -->
                          </nz-form-item>
                      </div>
                      <!-- <div class="col-lg-4">
                        <nz-form-item>
                            <nz-form-label nzRequired nzFor="End Rating" nzNoColon="false"></nz-form-label>
                            <nz-form-control nzErrorTip="End Rating is mandatory." class="ml-3">
                                <nz-input-group nzSize="large" [nzPrefix]="suffixIconSearch">
                                    <input nz-input formControlName="start_rating"
                                      placeholder="Enter Rating">
                                  </nz-input-group>
                                  <ng-template #suffixIconSearch>
                                    <i>{{icon}}</i>
                                  </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                      </div> -->
                  </div>
                  <nz-form-item>
                    <nz-form-label>Explanation</nz-form-label>
                    <nz-form-control nzErrorTip="Explanation is mandatory.">
                      <nz-input-group nzSize="large">
                        <textarea formControlName="explanation" nz-input rows="3" cols="80"
                            placeholder="write some thing">
                        </textarea>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </form>
              </nz-modal>
              
              <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="tblData" [nzLoading]="loading"
              (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" [nzScroll]="{ x: '150px' }">
                <thead>
                  <tr>
                    <th>Level</th>
                    <th>Percentage %</th>
                    <!-- <th class="text-center">Corresponding Rating</th> -->
                    <!-- <th>Explanation</th> -->
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rowSelectionTable.data">
  
                    <td>{{ item.level }}</td>
                    <td>{{item.start_percentage}} - {{ item.percentage }} %</td>
                    <!-- <td class="text-center">{{ icon}} {{ item.end_rating }}</td> -->
                    <!-- <td>{{ item.explanation }}</td> -->
                    <td class="text-center">
                      <a (click)="showEditModal(item)"><i class="ri-pencil-fill grayIcon"></i></a>
                      <nz-divider nzType="vertical"> | </nz-divider>
                      <a (click)="remove(item)">
                        <i class="ri-delete-bin-6-fill grayIcon" *ngIf="!item.Loader"></i>
                        <i nz-icon nzType="loading"  *ngIf="item.Loader"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  