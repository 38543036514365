import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {NzUploadFile, NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {DatePipe} from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {
  BusinessUnit,
  BusinessUnitRetrieveRequest,
  Department,
  DepartmentRetrieveRequest,
  Employee,
  EmployeeRetrieveRequest,
  FunctionRetrieveRequest,
  LocationRetrieveRequest
} from 'src/app/app.models';
import {AppService} from 'src/app/app.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgxSpinnerService} from 'ngx-spinner';

export interface TreeNodeInterface {
  key: string;
  levelType: number;
  level?: number;
  expand?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
  campaign_id: number;
  campaign_item_name: string;
  id: number;
  image: string;
  image_id: number;
  image_url: string;
  item_type: string;
  parent_item_id: number;
}

@Component({
  selector: 'app-campaign-details-form',
  templateUrl: './campaign-details-form.component.html',
  styleUrls: ['./campaign-details-form.component.css']
})
export class CampaignDetailsFormComponent implements OnInit {
  // storage account url
  storageAccount: String;
  action = 'create';
  loading = false;
  isCampaignFormLoading = false;
  isFormLoading = false;
  dateFormat = 'dd-MM-yyyy';
  contentIndex = 0;
  loggedInUserDetail: any;
  activities = [];
  campaignForm!: FormGroup;
  editCampaignDetails: any;
  selectedOrDirectProceedEmployees = [];
  selectedEmployeesFromAll: any = null;
  // listOfOption = ['Tech Level 1', 'Tech Level 2', 'Tech Level 3', 'Tech Level 4'];
  // listOfSelectedValue: string[] = [];

  listOfOption = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
  listOfSelectedValue: string[] = [];

  isNotSelected(value: string): boolean {
    return this.listOfSelectedValue?.indexOf(value) === -1;
  }

  // campaign Dropdown related
  // campaignListForm: FormGroup;
  selectedCampaignId: number = null;

  // level tab related declaration start
  levelAction = 'create';
  campaignItemsTree: any;
  selectedCampaignDetails: any;
  campaignList: any;
  treeTblLevelList = [];
  campaignId = null;

  isVisibleLevelOneModal = false;
  levelOneForm: FormGroup;
  isFormLoadingLevelOne = false;

  isFormLoadingLevelTwo = false;
  levelTwoForm: FormGroup;
  isVisibleLevelTwoModal = false;

  isFormLoadingLevelThree = false;
  levelThreeForm: FormGroup;
  isVisibleLevelThreeModal = false;
  listOfMapData: TreeNodeInterface[];
  clickedRowDetails: any;
  unselectedParticipantsOptionList = [];
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  isShowBs = true;
  isNotSelect = true;

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({...root, level: 0, expand: true});

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({...node.children[i], level: node.level! + 1, expand: true, parent: node});
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  // upload logo related
  mockOSSData = {
    dir: 'user-dir/',
    expire: '1577811661',
    host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
    accessId: 'c2hhb2RhaG9uZw==',
    policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
    signature: 'ZGFob25nc2hhbw=='
  };
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  transformFile = (file: NzUploadFile) => {
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = this.mockOSSData.dir + filename;

    return file;
  };
  getExtraData = (file: NzUploadFile) => {
    const {accessId, policy, signature} = this.mockOSSData;

    return {
      key: file.url,
      OSSAccessKeyId: accessId,
      policy: policy,
      Signature: signature
    };
  };

  // level tab related declaration end
  // state required for the auto complete
  employeeOptionList: Employee[] = [];
  employeeOptionListSearchChange$ = new BehaviorSubject('');
  isEmployeeOptionListLoading = false;

  onEmployeeOptionListSearch(value: string): void {
    this.isEmployeeOptionListLoading = true;
    this.employeeOptionListSearchChange$.next(value);
  }

  functionOptionList: Function[] = [];
  functionOptionListSearchChange$ = new BehaviorSubject('');
  isFunctionOptionListLoading = false;

  onFunctionOptionListSearch(value: string): void {
    this.isFunctionOptionListLoading = true;
    this.functionOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  departmentOptionList: Department[] = [];
  departmentOptionListSearchChange$ = new BehaviorSubject('');
  isDepartmentOptionListLoading = false;

  onDepartmentOptionListSearch(value: string): void {
    this.isDepartmentOptionListLoading = true;
    this.departmentOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  locationOptionList: Location[] = [];
  locationOptionListSearchChange$ = new BehaviorSubject('');
  isLocationOptionListLoading = false;

  onLocationOptionListSearch(value: string): void {
    this.isLocationOptionListLoading = true;
    this.locationOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  bUOptionList: BusinessUnit[] = [];
  businessUnitOptionListSearchChange$ = new BehaviorSubject('');
  isBusinessUnitOptionListLoading = false;

  onBusinessUnitOptionListSearch(value: string): void {
    this.isBusinessUnitOptionListLoading = true;
    this.businessUnitOptionListSearchChange$.next(value);
    this.selectedOrDirectProceedEmployees = [];
  }

  onBUChange(value: any) {
    // if (value.length === 0){
    // this.campaignForm.controls.location_ids.setValue(null);
    // this.campaignForm.controls.function_ids.setValue(null);
    // this.campaignForm.controls.employee_ids.setValue(null);
    // this.campaignForm.controls.department_ids.setValue(null);
    // }
  }

  onLocationChange(value: any) {
    // if (value.length === 0){
    // this.campaignForm.controls.function_ids.setValue(null);
    // this.campaignForm.controls.employee_ids.setValue(null);
    // this.campaignForm.controls.department_ids.setValue(null);
    // }
  }

  onFunctionChange(value: any) {
    // if (value.length === 0)
    // this.campaignForm.controls.employee_ids.setValue(null);
    // this.campaignForm.controls.department_ids.setValue(null);
  }

  onDepartmentChange(value: any) {
    // if (value.length === 0)
    this.campaignForm.controls.employee_ids.setValue(null);
  }

  //date picker
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;

  disabledStartDate = (current: Date): boolean => {
    // if (!current || !this.assessmentForm.get("end_date").value) {
    //   return false;
    // }
    // return start_date.getTime() > (new Date(this.assessmentForm.get("end_date").value)).getTime();
    return differenceInCalendarDays(current, new Date()) < 0;
  };

  disabledEndDate = (end_date: Date): boolean => {
    if (!end_date || !this.campaignForm.get('start_date').value) {
      return false;
    }
    return end_date.getTime() <= (new Date(this.campaignForm.get('start_date').value)).getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
    // console.log('handleStartOpenChange', open);
  }

  handleEndOpenChange(open: boolean): void {
    // console.log('handleEndOpenChange', open);
  }

  tabChange(no) {
    if (no === 0) {
      this.getAllMaximiserSettings();
      this.contentIndex = no;
      // this.campaignForm.reset();
      // this.getCampaignByCampaignId();
    } else if (no === 1) {
      this.getCampaigns();
      if (this.action === 'edit') {
        this.selectedCampaignId = this.editCampaignDetails.id;
      }
      this.contentIndex = no;
      this.getCampaignItemsTree();
      this.getCampaignBySelectedCampaignId();
    }
  }

  MaximiserSettingsFormValues: any;
  levelNameOptionList: any;
  allowEdit: boolean;

  constructor(public fb: FormBuilder,
              public appService: AppService,
              public message: NzMessageService,
              public datepipe: DatePipe,
              private activatedRoute: ActivatedRoute,
              private modal: NzModalService,
              private spinner: NgxSpinnerService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.getAllMaximiserSettings();
    this.getCampaigns();
    this.activatedRoute.params.subscribe(params => {
      if (params['id'] && params['action']) {
        this.campaignId = params['id'];
        this.action = params['action'];
        this.getCampaignByCampaignId();
      }
    });

    // this.initCampaignListForm();
    // this.getCampaignItemsTree();
    this.initLevelOneForm();
    // this.initLevelTwoForm();
    // this.initLevelThreeForm();
    this.initCampaignForm();
    this.getBUs();
    this.getLocations();
    this.getFunctions();
    this.getDepartments();
    this.getEmployees();
    this.allowEditCampaign();
    this.storageAccount = this.loggedInUserDetail.tenant.storage_url;
  }

  // campaign Dropdown related
  // initCampaignListForm(){
  //   this.campaignListForm = this.fb.group({
  //     campaign_list: [null, [Validators.required]]
  //   });
  // }
  // campaign Dropdown change related
  // onCampaignChange(){
  //  this.selectedCampaignId= this.campaignListForm.get('campaign_list').value;
  //  this.getCampaignItemsTree();
  //  this.getCampaignBySelectedCampaignId();
  // }
  // level tab related methods start
  onChange(e: NzUploadChangeParam): void {
    // console.log('Aliyun OSS:', e.fileList);
  }

  initLevelOneForm() {
    this.levelOneForm = this.fb.group({
      campaign_item_name: [null, [Validators.required]],
      image_file: [null]
    });
  }

  createLevelOneModal(data) {
    this.isVisibleLevelOneModal = true;
    this.clickedRowDetails = data;
    this.levelAction = 'create';
    this.levelOneForm.reset();
    // console.log('clickedRowDetails', this.clickedRowDetails);
  }

  editLevelOneModal(data) {
    this.isVisibleLevelOneModal = true;
    this.clickedRowDetails = data;
    this.levelAction = 'edit';
    this.levelOneForm.reset();
    // console.log('clickedRowDetails', this.clickedRowDetails);
    this.levelOneForm.patchValue(this.clickedRowDetails);
  }

  public removeLevel(data) {
    this.modal.confirm({
      nzTitle: 'Confirm Delete',
      nzContent: 'Are you sure, you want to delete?',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          let subscriber = null;

          subscriber = this.appService.deleteCampaignItem(data.id);
          subscriber.subscribe(res => {

            this.message.create('success', 'Record Deleted', {
              nzDuration: 5000
            });
            this.getCampaignItemsTree();
            resolve(res);
          }, error => {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.error.message
            });

            reject();
          });

        }).catch(() => console.log('Oops errors!'))
    });
  }

  cancelLevelOneModal() {
    this.levelOneForm.controls['campaign_item_name'].reset();
    this.fileList = [];
    // this.fileList.length = 0;
    // this.fileList.splice(0,this.fileList.length);
    // this.beforeUpload = null;
    // console.log("beforeUpload",this.beforeUpload);
    // console.log("cancel",this.fileList);

    this.isVisibleLevelOneModal = false;
  }

  submitLevelOneModal() {
    for (const i in this.levelOneForm.controls) {
      this.levelOneForm.controls[i].markAsDirty();
      this.levelOneForm.controls[i].updateValueAndValidity();
    }
    this.isFormLoadingLevelOne = true;
    // Create the campaign on the server.
    if (this.levelOneForm.valid) {

      let formData: any = new FormData();
      let subscriber = null;
      let message = null;
      if (this.levelAction === 'create') {
        if (this.clickedRowDetails?.levelType === 1) {
          formData.append('parent_item_id', this.clickedRowDetails.id);
          formData.append('item_type', this.treeTblLevelList[this.clickedRowDetails.levelType]);
        } else if (this.clickedRowDetails?.levelType > 1) {
          formData.append('parent_item_id', this.clickedRowDetails.id);
          formData.append('item_type', this.treeTblLevelList[this.clickedRowDetails.levelType]);
          //image upload code
          //  this.fileList.forEach((file: any) => { formData.append('image_file', file); });
          //  this.fileList = [];
        } else if (this.clickedRowDetails === null) {
          // formData.append('parent_item_id', 0);
          formData.append('item_type', this.treeTblLevelList[0]);
          if (this.treeTblLevelList.length === 1) {
            //image upload code
            this.fileList.forEach((file: any) => {
              formData.append('image_file', file);
            });
            this.fileList = [];
          }
        }
        if (this.treeTblLevelList.length === this.clickedRowDetails?.levelType + 1) {
          //image upload code
          this.fileList.forEach((file: any) => {
            formData.append('image_file', file);
          });
          this.fileList = [];
        }
        formData.append('campaign_id', this.selectedCampaignDetails.id); // should be dynamic
        formData.append('campaign_item_name', this.levelOneForm.get('campaign_item_name').value);
        subscriber = this.appService.createCampaignItem(formData);
        message = 'Campaign level "' + this.levelOneForm.controls['campaign_item_name'].value + '" has been added.';
      } else if (this.levelAction === 'edit') {
        // if (this.clickedRowDetails?.levelType > 1){
        //image upload code
        this.fileList.forEach((file: any) => {
          formData.append('image_file', file);
        });
        this.fileList = [];
        // }
        formData.append('campaign_item_name', this.levelOneForm.get('campaign_item_name').value);
        subscriber = this.appService.updateCampaignItem(formData, this.clickedRowDetails.id);
        message = 'Campaign level"' + this.levelOneForm.controls['campaign_item_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormLoadingLevelOne = false;
        this.isVisibleLevelOneModal = false;
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.getCampaignItemsTree();
      }, error => {
        this.isFormLoadingLevelOne = false;
        this.isVisibleLevelOneModal = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    }
  }

  // level tab related methods end

  initCampaignForm() {
    this.campaignForm = this.fb.group({
      // campaign_name: [null, [Validators.required]],
      campaign_name: new FormControl(null || '', [Validators.required, Validators.minLength(3), this.noWhitespaceValidator]),
      start_date: [null, Validators.compose([Validators.required])],
      end_date: [null, Validators.compose([Validators.required])],
      no_of_levels: [null, [Validators.required]],
      // level_names: [null,[Validators.required]],
      level_names: this.fb.array([
        this.fb.control(null, Validators.required)
      ]),
      business_unit_ids: [null],
      location_ids: [null],
      function_ids: [null],
      department_ids: [null],
      employee_ids: [null],
    });
  }

  campaignFormSubmit() {
    for (const i in this.campaignForm.controls) {
      this.campaignForm.controls[i].markAsDirty();
      this.campaignForm.controls[i].updateValueAndValidity();
    }
    this.isFormLoading = true;
    let bu = this.campaignForm.controls['business_unit_ids'].value;
    let fun = this.campaignForm.controls['function_ids'].value;
    let loc = this.campaignForm.controls['location_ids'].value;
    let dep = this.campaignForm.controls['department_ids'].value;

    // Create the campaign on the server.
    if (this.campaignForm.valid &&
      bu != null && bu?.length > 0 ||
      fun != null && fun?.length > 0 ||
      loc != null && loc?.length > 0 ||
      dep != null && dep?.length > 0) {
      // console.log(this.campaignForm.value);

      let start_date = this.datepipe.transform(this.campaignForm.value.start_date, 'yyyy-MM-dd');
      let end_date = this.datepipe.transform(this.campaignForm.value.end_date, 'yyyy-MM-dd');

      // let formData: any = new FormData();
      // formData.append('created_by', this.loggedInUserDetail.employee.id);
      // formData.append('campaign_name',this.campaignForm.get('campaign_name').value);
      // formData.append('start_date', start_date);
      // formData.append('end_date', end_date);
      // formData.append('no_of_levels', this.campaignForm.get('no_of_levels').value);
      // formData.append('level_names', this.campaignForm.get('level_names').value);
      // formData.append('business_unit_ids', this.campaignForm.get('business_unit_ids').value);
      // formData.append('location_ids', this.campaignForm.get('location_ids').value);
      // formData.append('function_ids', this.campaignForm.get('function_ids').value);
      // formData.append('department_ids', this.campaignForm.get('department_ids').value);
      // formData.append('employee_ids', this.campaignForm.get('employee_ids').value);
      let data = {
        created_by: this.loggedInUserDetail.employee.id,
        campaign_name: this.campaignForm.value.campaign_name,
        start_date: start_date,
        end_date: end_date,
        no_of_levels: this.campaignForm.value.no_of_levels,
        level_names: this.campaignForm.value.level_names,
        business_unit_ids: this.campaignForm.value.business_unit_ids,
        location_ids: this.campaignForm.value.location_ids,
        function_ids: this.campaignForm.value.function_ids,
        department_ids: this.campaignForm.value.department_ids,
        employee_ids: this.selectedOrDirectProceedEmployees,
      };
      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createCampaign(data);
        message = 'Campaign "' + this.campaignForm.controls['campaign_name'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateCampaign(data, this.editCampaignDetails.id);
        message = 'Campaign "' + this.campaignForm.controls['campaign_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormLoading = false;
        this.selectedCampaignId = res.id;
        this.message.create('success', message, {
          nzDuration: 5000
        });
        this.tabChange(1); // tab switched to level tree table
      }, error => {
        this.isFormLoading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select Business Unit/Function/Location/Department.'
      });
    }
  }

  addLevelName(): void {
    (this.campaignForm.get('level_names') as FormArray).push(
      this.fb.control(null)
    );
  }

  getLevelNamesFormControls(): AbstractControl[] {
    return (<FormArray> this.campaignForm.get('level_names')).controls;
  }

  onLevelChange() {
    const control = <FormArray> this.campaignForm.controls['level_names'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }
    let selectedLevels;
    // console.log('list', this.getLevelNamesFormControls());
    selectedLevels = this.campaignForm.get('no_of_levels').value;
    // console.log(selectedLevels);
    for (let i = 0; i < selectedLevels; i++) {
      // console.log('selectedLevels:', i);
      (this.campaignForm.get('level_names') as FormArray).push(this.fb.control(null, Validators.required));
    }
    this.alterUnselectedParticipantsOptionList();
  }

  getAllMaximiserSettings() {
    this.appService.getMaximiserSettings().subscribe(res => {
      this.MaximiserSettingsFormValues = res['items'][0];
      this.levelNameOptionList = [
        {
          label: this.MaximiserSettingsFormValues.principle_label,
          disabled: false,
          id: 1,
          value: this.MaximiserSettingsFormValues.principle_label
        },
        {
          label: this.MaximiserSettingsFormValues.category_label,
          disabled: false,
          id: 2,
          value: this.MaximiserSettingsFormValues.category_label
        },
        {
          label: this.MaximiserSettingsFormValues.behavior_label,
          disabled: false,
          id: 3,
          value: this.MaximiserSettingsFormValues.behavior_label
        },
        {
          label: this.MaximiserSettingsFormValues.nanobehavior_label,
          disabled: false,
          id: 4,
          value: this.MaximiserSettingsFormValues.nanobehavior_label
        },
      ];
      // console.log("levelNameOptionList", this.levelNameOptionList);
      this.unselectedParticipantsOptionList = [
        {
          label: this.MaximiserSettingsFormValues.principle_label,
          disabled: false,
          id: 1,
          value: this.MaximiserSettingsFormValues.principle_label
        },
        {
          label: this.MaximiserSettingsFormValues.category_label,
          disabled: false,
          id: 2,
          value: this.MaximiserSettingsFormValues.category_label
        },
        {
          label: this.MaximiserSettingsFormValues.behavior_label,
          disabled: false,
          id: 3,
          value: this.MaximiserSettingsFormValues.behavior_label
        },
        {
          label: this.MaximiserSettingsFormValues.nanobehavior_label,
          disabled: false,
          id: 4,
          value: this.MaximiserSettingsFormValues.nanobehavior_label
        },
      ];
    });
  }

  getBUs() {
    const optionList = (functionName: string) => {
      this.isBusinessUnitOptionListLoading = true;
      let rr = new BusinessUnitRetrieveRequest(functionName);
      return this.appService.getBusinessUnits(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<BusinessUnit[]> = this.businessUnitOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.bUOptionList = data;
      this.isBusinessUnitOptionListLoading = false;
    });
  }

  getLocations() {
    const optionList = (functionName: string) => {
      this.isLocationOptionListLoading = true;
      let rr = new LocationRetrieveRequest(functionName);
      rr.business_unit_ids = this.campaignForm.controls.business_unit_ids.value;
      return this.appService.getLocations(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Location[]> = this.locationOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.locationOptionList = data;
      this.isLocationOptionListLoading = false;
    });
  }

  getFunctions() {
    const optionList = (functionName: string) => {
      this.isFunctionOptionListLoading = true;
      let rr = new FunctionRetrieveRequest(functionName);
      rr.business_unit_ids = this.campaignForm.controls.business_unit_ids.value;
      return this.appService.getFunctions(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Function[]> = this.functionOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.functionOptionList = data;
      this.isFunctionOptionListLoading = false;
    });
  }

  getDepartments() {
    const optionList = (functionName: string) => {
      this.isDepartmentOptionListLoading = true;
      let rr = new DepartmentRetrieveRequest(functionName);
      rr.function_ids = this.campaignForm.controls.function_ids.value;
      ;
      return this.appService.getDepartments(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Department[]> = this.departmentOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      this.departmentOptionList = data;
      this.isDepartmentOptionListLoading = false;
    });
  }
  allEmployeeList:any
  getEmployees() {
    // to get employees
    if(this.isEmployeeModalOpen){
      this.spinner.show()
    }
    const optionList = (functionName: any) => {
      this.isEmployeeOptionListLoading = true;
      let rr = new EmployeeRetrieveRequest(functionName);
      rr.business_unit_ids = this.campaignForm.get('business_unit_ids').value;
      rr.function_ids = this.campaignForm.get('function_ids').value;
      rr.location_ids = this.campaignForm.get('location_ids').value;
      rr.department_ids = this.campaignForm.get('department_ids').value;
      return this.appService.getEmployees(rr)
        .pipe(
          map((res: any) => {
            return res['items'];
          })
        );
    };

    const optionList$: Observable<Employee[]> = this.employeeOptionListSearchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(optionList));
    optionList$.subscribe(data => {
      data.forEach(element => {
        element.display_name = element.display_name+' ['+element.user.email+']';
      });
      this.employeeOptionList = data;
      this.allEmployeeList = data
      this.isEmployeeOptionListLoading = false;
      this.spinner.hide();
    },err=>{
      this.employeeOptionList = [];
      this.allEmployeeList = [];
      this.isEmployeeOptionListLoading = false;
      this.spinner.hide();
    });
  }

  onLevelNameChange(e, selectedOption: any, index: number) {
    // let selectedLevelName;
    // selectedLevelName = this.campaignForm.get('level_names').value;
    // this.levelNameOptionList.removeAt(index);
    // if (index !== -1) {
    // console.log("test", (<FormArray> this.campaignForm.get('level_names')).controls);
    // let tempList  = Object.assign([], this.levelNameOptionList);
    // this.levelNameOptionList= [];
    // console.log("b", this.levelNameOptionList);
    // // let selectedIndex = e;
    // console.log(e);
    // console.log(index);

    // this.levelNameOptionList[index].disabled = true;
    // console.log("data", selectedOption);

    // // selectedOption.disabled = true;
    // console.log("data", selectedOption);
    // console.log("a", this.levelNameOptionList);
    //  console.log("BeforetempList", tempList);
    //  this.levelNameOptionList= [ {label: '', disabled: false,  value: ''}];

    // this.levelNameOptionList.forEach((element,index)=>{
    //     if(element.value==data.value) delete this.levelNameOptionList[index];
    //  });
    //  console.log("AftertempList", this.levelNameOptionList);
    //  this.levelNameOptionList  = Object.assign([], tempList);
    //  this.levelNameOptionList  = Object.assign([], tempList);
    // }
    // this.levelNameOptionList = this.levelNameOptionList.filter(item => item !== data_item);
    // console.log("levelNameOptionList", this.levelNameOptionList);

  }

  alterUnselectedParticipantsOptionList() {
    let selectedList = [];
    this.unselectedParticipantsOptionList = [];
    this.levelNameOptionList.forEach(val => this.unselectedParticipantsOptionList.push(Object.assign({}, val)));
    this.campaignForm.get('level_names')['controls'].forEach(e => {
      // selectedList = [...selectedList, ...e.controls.participant_ids.value];
      if (e.value) {
        // e.value.forEach(ele => {
        //   selectedList.push(ele);
        // });
        //  this.unselectedParticipantsOptionList.findIndex(x => x.label === e.value);
        selectedList.push(e.value);
        for (let index = 0; index < this.unselectedParticipantsOptionList.length; index++) {
          if (this.unselectedParticipantsOptionList[index].value == e.value) {
            this.unselectedParticipantsOptionList.splice((this.unselectedParticipantsOptionList.map(item => item.value).indexOf(e.value)), 1);
          }
        }
      }
    });
    // selectedList.forEach(e => {
    // for (let index = 0; index < this.unselectedParticipantsOptionList.length; index++) {
    //   if (this.unselectedParticipantsOptionList[index].value == e) {
    //     this.unselectedParticipantsOptionList.splice((this.unselectedParticipantsOptionList.map(item => item.value).indexOf(e)), 1);
    //   }
    // }
    // });
    // this.isSelectedLevelNames();
  }

  //  test: boolean = false;
  //   isSelectedLevelNames(){
  //     for(let i = 1; i<= this.campaignForm.controls.no_of_levels.value; i++){
  //       console.log("test", i);
  // this.campaignForm.controls['level_names'].setValidators(null);
  // this.campaignForm.controls['level_names'].setValidators([Validators.required]);
  // this.campaignForm.controls['level_names'].updateValueAndValidity();
  // this.campaignForm.controls['level_names'].updateValueAndValidity();
  //   if( this.campaignForm.get('level_names').controls[i].value !== null){
  //      console.log(this.campaignForm.get('level_names').controls[i].value);
  //       this.test = true;
  //       console.log("this.test", this.test);
  //  }else{
  //   //  this.campaignForm.invalid;
  //   this.test = false;
  //   console.log("this.test", this.test);

  //  }
  //    }
  // }

  // getting Campaign Items Tree using selectedCampaignId
  getCampaignItemsTree() {
    // this.appService.getCampaignItemsTree()
    this.appService.get('/api/campaign-items/load-tree/' + this.selectedCampaignId).subscribe(res => {
      this.campaignItemsTree = res;
      this.getTreeTableData();
    });
  }

  getCampaignBySelectedCampaignId() {
    this.appService.getCampaign(this.selectedCampaignId).subscribe(res => {
      this.selectedCampaignDetails = res;
      // console.log('selectedCampaignDetails', this.selectedCampaignDetails);
      this.treeTblLevelList = Object.assign([], this.selectedCampaignDetails.level_names);
    });
  }

  // Get Campaign by CampaignId for edit
  async getCampaignByCampaignId() {
    if (this.action === 'edit') {
      this.isCampaignFormLoading = true;
    }

    this.appService.getCampaign(this.campaignId).subscribe(res => {
      this.editCampaignDetails = res;
      this.selectedOrDirectProceedEmployees = this.editCampaignDetails?.employee_ids;
      this.campaignForm.controls['no_of_levels'].setValue(this.editCampaignDetails.no_of_levels);
      setTimeout(() => {
        this.campaignForm.controls['campaign_name'].setValue(this.editCampaignDetails.campaign_name);
        this.campaignForm.controls['start_date'].setValue(this.editCampaignDetails.start_date);
        this.campaignForm.controls['end_date'].setValue(this.editCampaignDetails.end_date);
        this.campaignForm.controls['business_unit_ids'].setValue(this.editCampaignDetails.business_unit_ids);
        this.campaignForm.controls['location_ids'].setValue(this.editCampaignDetails.location_ids);
        this.campaignForm.controls['function_ids'].setValue(this.editCampaignDetails.function_ids);
        this.campaignForm.controls['department_ids'].setValue(this.editCampaignDetails.department_ids);
        this.campaignForm.controls['employee_ids'].setValue(this.editCampaignDetails.employee_ids);
        this.campaignForm.controls['level_names'].setValue(this.editCampaignDetails.level_names);
        this.isCampaignFormLoading = false;
      }, 1800);

    });

  }

  // Get List of Campaigns
  getCampaigns() {
    this.appService.getCampaigns().subscribe(res => {
      this.campaignList = res['items'];
      // console.log("treeTblLevelList", this.treeTblLevelList)
    });
  }

  //Converting data into tree table
  getTreeTableData() {
    this.listOfMapData = [];
    this.campaignItemsTree.forEach((p: any, i) => {
      let level_1 = (i + 1).toString();
      this.listOfMapData.push({
        key: level_1,
        levelType: 1,
        campaign_id: p.campaign_id,
        campaign_item_name: p.campaign_item_name,
        id: p.id,
        image: p.image,
        image_id: p.image_id,
        image_url: null,
        item_type: p.item_type,
        parent_item_id: p.parent_item_id,
      });
      if (p.children) {
        if (p.children.length > 0) {
          this.listOfMapData[i].children = [];
          p.children.forEach((c: any, j) => {
            let level_2 = (level_1 + '-' + (j + 1));
            this.listOfMapData[i]['children'].push({
              key: level_2,
              levelType: 2,
              campaign_id: c.campaign_id,
              campaign_item_name: c.campaign_item_name,
              id: c.id,
              image: c.image,
              image_id: c.image_id,
              item_type: c.item_type,
              image_url: c?.image?.original_image_url,
              parent_item_id: c.parent_item_id,
            });
            if (c.children) {
              if (c.children.length > 0) {
                this.listOfMapData[i]['children'][j].children = [];
                c.children.forEach((n: any, k) => {
                  let level_3 = (level_2 + '-' + (k + 1));
                  this.listOfMapData[i]['children'][j]['children'].push({
                    key: level_3,
                    levelType: 3,
                    campaign_id: n.campaign_id,
                    campaign_item_name: n.campaign_item_name,
                    id: n.id,
                    image: n.image,
                    image_id: n.image_id,
                    item_type: n.item_type,
                    image_url: c?.image?.original_image_url,
                    parent_item_id: n.parent_item_id,
                  });
                  if (n.children) {
                    if (n.children.length > 0) {
                      this.listOfMapData[i]['children'][j]['children'][k].children = [];
                      n.children.forEach((o: any, l) => {
                        let level_4 = (level_3 + '-' + (l + 1));
                        this.listOfMapData[i]['children'][j]['children'][k]['children'].push({
                          key: level_4,
                          levelType: 4,
                          campaign_id: o.campaign_id,
                          campaign_item_name: o.campaign_item_name,
                          id: o.id,
                          image: o.image,
                          image_id: o.image_id,
                          item_type: o.item_type,
                          image_url: c?.image?.original_image_url,
                          parent_item_id: o.parent_item_id,
                        });

                      });
                    }
                  }
                });
              }
            }

          });

        }
      }

    });
    this.listOfMapData.forEach(item => {
      this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
    });
    // console.log('listOfMapData', this.listOfMapData);
    // this.isShowTbl = !this.isShowTbl;

  }

  onSaveAndExit() {
    this.router.navigate(['/behaviour-transactions/list-of-campaigns']);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  allowEditCampaign() {
    if (this.action == 'edit') {
      this.appService.campaignAllowEdit(this.campaignId).subscribe((res: any) => {
        this.allowEdit = res.allow;
        if (!this.allowEdit) {
          this.campaignForm.disable();
        }
      }, error => {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });
      });
    } else {
      this.allowEdit = true;
    }
  }

    // fuction added by Althaf
    buttonDisabled = []
    setEmployeesForLA(employees) {
      this.selectedEmployeesFromAll = employees;
      this.buttonDisabled = Array.from(this.selectedEmployeesFromAll)
    }
  
    isEmployeeModalOpen = false;
    handleOk(): void {
      this.isEmployeeModalOpen = false;
      this.selectedOrDirectProceedEmployees = [];
      this.selectedOrDirectProceedEmployees = Array.from(this.selectedEmployeesFromAll);
    }
  
    handleCancel(): void {
      this.isEmployeeModalOpen = false;
    }
  
    selectEmp() {
      this.isEmployeeModalOpen = true;
      this.getEmployees();
    }
}
