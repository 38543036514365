import {Component, OnInit} from '@angular/core';
import {Role, RoleRetrieveRequest} from 'src/app/app.models';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {AppService} from 'src/app/app.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NzUploadFile} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  loggedInUserDetail: any;
  total = 1;
  isImportFormVisible = false;
  fileUploading = false;
  downloading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  // These are the Roles which are displayed in the list screen.
  roles: Role[] = [];
  roleListOption: any;
  // This is the rolle being edited.
  roleId: number;

  loading = true;
  pageSize = 100;
  pageIndex = 1;

  // The create / edit form shown inside the modal.
  roleForm!: FormGroup;
  isFormVisible = false;
  isFormLoading = false;
  action = null;

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private appService: AppService,
              private message: NzMessageService,
              private modal: NzModalService) {
  }

  showCreateModal(): void {
    this.resetCreateModal();
    this.action = 'create';
    this.isFormVisible = true;
    this.getManagerRoleList();
  }

  showEditModal(entityId: number): void {
    this.getManagerRoleList();
    this.roleId = entityId;
    this.getRoleById(entityId);
    this.action = 'edit';
    this.isFormVisible = true;
  }

  getRoleById(id) {
    this.appService.get('/api/roles/' + id).subscribe((res: any) => {
      this.roleForm.patchValue(res);
      this.roleForm.get('allow_assessment_centre_creation').setValue(res?.allow_assessment_centre_creation == 'yes');
      this.roleForm.get('allow_behavior_txn_campaign_creation').setValue(res?.allow_behavior_txn_campaign_creation == 'yes');
      this.roleForm.get('allow_labelled_content_creation').setValue(res?.allow_labelled_content_creation == 'yes');
      this.roleForm.get('allow_learning_assessment_creation').setValue(res?.allow_learning_assessment_creation == 'yes');
      this.roleForm.get('allow_organisation_setup').setValue(res?.allow_organisation_setup == 'yes');
      this.roleForm.get('allow_survey_creation').setValue(res.allow_survey_creation == 'yes');
      this.roleForm.get('allow_gap_analysis_creation').setValue(res.allow_gap_analysis_creation == 'yes');
      this.roleForm.get('allow_nine_box_creation').setValue(res.allow_nine_box_creation == 'yes');
    });
  }

  handleModalSubmit(): void {
    this.submitForm();
    this.isFormLoading = true;

    // Create the business unit on the server.
    if (this.roleForm.status === 'VALID') {
      let data = {
        'role_name': this.roleForm.value.role_name,
        'parent_role_id': this.roleForm.value.parent_role_id,
        'allow_assessment_centre_creation': this.roleForm.value.allow_assessment_centre_creation ? 'yes' : 'no',
        'allow_behavior_txn_campaign_creation': this.roleForm.value.allow_behavior_txn_campaign_creation ? 'yes' : 'no',
        'allow_labelled_content_creation': this.roleForm.value.allow_labelled_content_creation ? 'yes' : 'no',
        'allow_learning_assessment_creation': this.roleForm.value.allow_learning_assessment_creation ? 'yes' : 'no',
        'allow_organisation_setup': this.roleForm.value.allow_organisation_setup ? 'yes' : 'no',
        'allow_survey_creation': this.roleForm.value.allow_survey_creation ? 'yes' : 'no',
        'allow_gap_analysis_creation': this.roleForm.value.allow_gap_analysis_creation ? 'yes' : 'no',
        'allow_nine_box_creation':this.roleForm.value.allow_nine_box_creation? 'yes' : 'no' ,
      };

      let subscriber = null;
      let message = null;
      if (this.action === 'create') {
        subscriber = this.appService.createRole(data);
        message = 'Role "' + this.roleForm.controls['role_name'].value + '" has been added.';
      } else if (this.action === 'edit') {
        subscriber = this.appService.updateRole(data, this.roleId);
        message = 'Role "' + this.roleForm.controls['role_name'].value + '" has been updated.';
      }

      subscriber.subscribe(res => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        this.message.create('success', message, {
          nzDuration: 5000
        });

        this.loadDataFromServer(this.pageIndex, this.pageSize, null, 'desc', null, []);

      }, error => {
        this.isFormVisible = false;
        this.isFormLoading = false;

        //let errorMessage = error.error.message;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.message
        });

      });

    }
  }

  handleModalCancel(): void {
    this.isFormVisible = false;
    this.isImportFormVisible =false;
    this.fileList = [];
  }

  resetCreateModal(): void {
    this.roleForm = this.fb.group({
      role_name: new FormControl(null || '', [Validators.required, Validators.minLength(3), this.noWhitespaceValidator]),
      parent_role_id: [null],
      allow_assessment_centre_creation: [null],
      allow_behavior_txn_campaign_creation: [null],
      allow_labelled_content_creation: [null],
      allow_learning_assessment_creation: [null],
      allow_organisation_setup: [null],
      allow_survey_creation: [null],
      allow_gap_analysis_creation: [null],
      allow_nine_box_creation: [null]
    });
  }

  submitForm(): void {
    for (const i in this.roleForm.controls) {
      this.roleForm.controls[i].markAsDirty();
      this.roleForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    //get user details
    this.loggedInUserDetail = this.appService.getMe();
    // console.log(this.loggedInUserDetail);
    // if user_type: "employee"
    this.resetCreateModal();

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['id']) {
        this.showEditModal(queryParams['id']);
      }
    });

    // this.loadDataFromServer(this.pageIndex, this.pageSize, null, null, null, []);
// this.getManagerRoleList();
  }

  public remove(entity: Role) {
    const index: number = this.roles.indexOf(entity);
    if (index !== -1) {
      this.modal.confirm({
        nzTitle: 'Confirm Delete',
        nzContent: 'Are you sure, you want to delete ' + entity.role_name + '?',
        nzOnOk: () =>
          new Promise((resolve, reject) => {

            this.appService.deleteRole(entity.id).subscribe(res => {
              this.loadDataFromServer(this.pageIndex, this.pageSize, null, 'desc', null, []);

              this.message.create('success', 'Record Deleted', {
                nzDuration: 5000
              });

              resolve(res);
            }, error => {

              // let errorMessage = error.error.message;
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.error.message
              });

              reject();
            });

          }).catch(() => console.log('Oops errors!'))
      });
    }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder, null, []);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, containsName: string | '', ids: number[] | []): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    let rr = new RoleRetrieveRequest();
    rr.sort_order = sortOrder ? sortOrder : 'asc';
    rr.order_by = sortField ? sortField : 'role_name';
    rr.per_page = pageSize;
    rr.page = pageIndex;
    rr.role_name = containsName;
    rr.ids = ids;

    this.appService.getRoles(rr).subscribe(data => {
      this.loading = false;
      this.total = data['_meta']['total_items'];
      this.roles = data['items'];
      // console.log('roles', this.roles);

    });
  }

  propogateObjective(id) {
    let message;
    this.appService.getRoleObjGrpPropogate(id).subscribe(res => {
      // console.log('propogeted Obj:', res);
      message = res['message'];

      this.message.create('success', message, {
        nzDuration: 5000
      });
    });
  }

  getManagerRoleList() {
    let rr = new RoleRetrieveRequest();
    rr.sort_order = 'desc';
    // rr.order_by = 'desc';
    rr.per_page = 300;
    // rr.page = pageIndex;
    // rr.role_name = containsName;
    // rr.ids = ids;

    this.appService.getRoles(rr).subscribe(data => {
      // this.loading = false;
      this.total = data['_meta']['total_items'];
      this.roleListOption = data['items'];
      // console.log('roleListOption', this.roleListOption);

    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }


  showImportModal(): void {
    this.isImportFormVisible = true;
  }

  handleImportModalSubmit(): void{
    // console.log("here")
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    this.appService.importRole(formData).subscribe((res: any) => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      this.message.create('success', res.message, {
        nzDuration: 5000
      });
    }, error => {
      this.fileList = [];
      this.isImportFormVisible = false;
      this.fileUploading = false;

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.error.message
      });

    });
  }


  downloadSampleCSV(){
    this.downloading = true;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/document-upload-formats/Role-BulkUpload-Template.csv');
    //link.setAttribute('download', `sample_excel.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.downloading = false;
  }

  alphaNumberOnly (e: any) {  // Accept only letters and numbers
    e = e || window.event;
   var bad = /[^\sa-z\d&-]/i,
       key = String.fromCharCode(e.keyCode || e.which);
   if (e.which !== 0 && e.charCode !== 0 && bad.test(key)) {
       e.returnValue = false;
       if (e.preventDefault) {
           e.preventDefault();
       }
   }
  }
}
