import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking

interface Person {
  key: string;
  name: string;
  role: string;
  status: string;
  objectivesFor: string;
  description: string;
}
@Component({
  selector: 'app-maximizer-setup',
  templateUrl: './maximizer-setup.component.html',
  styleUrls: ['./maximizer-setup.component.css']
})
export class MaximizerSetupComponent implements OnInit {
  // calendarOptions: CalendarOptions = {
  //   initialView: 'dayGridMonth'
  // };
  isVisible = false;  
  i= 0;
  editId: string | null = null;
  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: "Launch the products as per timeline"
    },
    {
      key: '2',
      name: 'Jim Green',
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: "Launch the products as per timeline"
    },
    {
      key: '3',
      name: 'Joe Black',
      role: 'Designer',
      status: 'Approved',
      objectivesFor: 'May-June',
      description: "Launch the products as per timeline"
    }
  ];
  dateFormat = 'dd/MM/yyyy';
  current = 0;
  contentIndex = 0;
  listOfNoOfOption = [
    { label: 'For 1 Apri-2020', value: 1 },
    { label: 'For 1 May-2020', value: 2 },
    { label: 'For 1 June-2020', value: 3 },
    { label: 'For 1 July-2020', value: 4 },
  ];

 
  
  listOfQuestionTypeOption = [
    { label: 'MCQ', value: 'MCQ' },
    { label: 'Subjective', value: 'Subjective' }
  ];
  listOfCompetenciesOption: Array<{ value: string; label: string }> = [];
  listOfSelectedCompetenciesValue = [];
  noOfActivity = [];
  setupCaseStudyForm!: FormGroup;
  participantsForm!: FormGroup;
  feedbackForm!: FormGroup;
  // state required for the auto complete
  assessorsOptionList: string[] = [];
  assessorsOptionListSearchChange$ = new BehaviorSubject('');
  // selectedBuId?: number;
  isAssessorsOptionListLoading = false;

  onAssessorsOptionListSearch(value: string): void {
    // this.isAssessorsOptionListLoading = true;
    // this.assessorsOptionListSearchChange$.next(value);
  }

  onCurrentIndexChange(current: number): void {
    this.current = current;
    this.changeContent();
  }

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    this.current += 1;
    this.changeContent();
  }

  done(): void {
    // console.log('done');
  }

  changeContent(): void {
    switch (this.current) { 
      case 1: {
        this.contentIndex = 1;
        break;
      }
      case 2: {
        this.contentIndex = 2;
        break;
      }
      case 3: {
        this.contentIndex = 3;
        break;
      }
      case 4: {
        this.contentIndex = 4;
        break;
      }
      case 5: {
        this.contentIndex = 5;
        break;
      }
      case 6: {
        this.contentIndex = 6;
        break;
      }
      case 7: {
        this.contentIndex = 7;
        break;
      }
      default: {
        this.contentIndex = 0;
      }
    }
  }
  constructor(public fb: FormBuilder) { }

  ngOnInit(): void {
    this.setupCaseStudyForm = this.fb.group({
      idealActiveTime: [null, [Validators.required]],
      caseStudyComment: [null, [Validators.required]],
      title: [null, [Validators.required]],
      listOfSelectedCompetenciesValue: [null, Validators.compose([Validators.required])],
      startDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
      participate:[null],
      assessors_id:[null],
    });
    this.participantsForm = this.fb.group({
      questionTypeSelectedValue: [null],
      question: [null],
      choice1:[null],
      isValid:[null],
    });
    this.feedbackForm = this.fb.group({
      questionTypeSelectedValue: [null],
      question: [null],
      choice1:[null],
      isValid:[null],
    });


    const children: string[] = [];
    for (let i = 10; i < 10000; i++) {
      children.push(`${i.toString(36)}${i}`);
    }
    this.listOfCompetenciesOption = children.map(item => {
      return {
        value: item,
        label: item
      };
    });

    this.addRow();
    this.addRow();
    
  }

  proceedToActivities(){
    
    for (const i in this.setupCaseStudyForm.controls) {
      this.setupCaseStudyForm.controls[i].markAsDirty();
      this.setupCaseStudyForm.controls[i].updateValueAndValidity();
    }
    this.noOfActivity = [...new Array(this.setupCaseStudyForm.controls['noOfActivities'].value)].map((_,i) => i + 1);
    // this.noOfActivity = Array.from({length : this.assessmentForm.controls['noOfActivities'].value}, (_, v) => v+1);
    // for (let index = 1; index < this.noOfActivity.length; index++) {
    // }
    
    // console.log(this.noOfActivity);
    this.current += 1;
    this.changeContent();
  }

  createFormGroup = (): FormGroup => {
    const formBuilder = new FormBuilder();
    return formBuilder.group({
      listOfSelectedActivityValue: [
        null,
        Validators.compose([Validators.required])
      ]
    });
  }

  activitySubmitForm(e) {
    // console.log(e);
  }
// editable Table functions
startEdit(id: string): void {
  this.editId = id;
}

stopEdit(): void {
  this.editId = null;
}

addRow(): void {
  this.listOfData = [
    ...this.listOfData,
    {
      key: `${this.i}`,
      name: `Edward King ${this.i}`,
      role: 'Manager',
      status: 'Draft',
      objectivesFor: 'Mar-Apr',
      description: `Launch the products as per timeline. ${this.i}` 
    }
  ];
  this.i++;
} 

deleteRow(id: string): void {
  this.listOfData = this.listOfData.filter(d => d.key !== d.key);
}

// modal action fuctions
showModal(): void {
  this.isVisible = true;
}

handleOk(): void {
  // console.log('Button ok clicked!');
  this.isVisible = false;
}

handleCancel(): void {
  // console.log('Button cancel clicked!');
  this.isVisible = false;
}
}
 






