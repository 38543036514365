<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="stepwizard mt-4 ">
                            <div class="stepwizard-row setup-panel text-center">
                                <div class="wizard-step tab-first" [ngClass]="{'active' : contentIndex == 0}">
                                    <a class="btn btn-default" (click)="tabChange(0)">
                                        <!-- <i class="ri-file-search-fill text-primary"></i> -->
                                        <i class="ri-pie-chart-fill text-primary"></i>
                                        <span>Campaign Details</span>
                                    </a>
                                </div>
                                <div class="wizard-step tab-second" [ngClass]="{'active' : contentIndex == 1}">
                                    <a class="btn btn-default" [ngClass]="{'disabled' : action === 'create'}"  (click)="tabChange(1)">
                                        <!-- <i class="ri-list-settings-fill text-danger"></i> -->
                                        <i class="ri-bar-chart-fill text-danger"></i>
                                        <span>Levels</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="!allowEdit" class="text-danger text-center">Note: Campaign is not editable; as it has active transactions.</p>
                        <div class="row setup-content" *ngIf="contentIndex == 0">
                            <div class="col-sm-12">
                                <div class="col-md-12 p-0">
                                    <nz-spin [nzSpinning]="isCampaignFormLoading">
                                        <form nz-form [nzLayout]="'vertical'" [formGroup]="campaignForm"
                                            (ngSubmit)="campaignFormSubmit()">
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Campaign Title</nz-form-label>
                                                            <nz-form-control nzErrorTip="Please input Campaign Title!">
                                                                <nz-input-group [nzSize]="'large'">
                                                                    <input nz-input placeholder="Campaign Title"
                                                                        formControlName="campaign_name" />
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="12">
                                                    <div nz-col nzSpan="7">

                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>Start Date</nz-form-label>
                                                            <nz-form-control nzErrorTip="Please input Start Date!">
                                                                <nz-date-picker formControlName="start_date"
                                                                [nzDisabledDate]="disabledStartDate" (nzOnOpenChange)="handleStartOpenChange($event)"
                                                                    [nzSize]="'large'" [nzFormat]="dateFormat">
                                                                </nz-date-picker>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>End Date</nz-form-label>
                                                            <nz-form-control nzErrorTip="Please input End Date!">
                                                                <nz-date-picker formControlName="end_date"
                                                                    [nzSize]="'large'" [nzFormat]="dateFormat"
                                                                    #endDatePicker [nzDisabledDate]="disabledEndDate"
                                                                    (nzOnOpenChange)="handleEndOpenChange($event)">
                                                                </nz-date-picker>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzRequired>No. of Levels</nz-form-label>
                                                            <nz-form-control nzErrorTip="Please input No. of Levels!">
                                                                <nz-input-group>
                                                                    <nz-input-number [nzSize]="'large'"
                                                                        (ngModelChange)="onLevelChange()"
                                                                        formControlName="no_of_levels" [nzMin]="1"
                                                                        [nzMax]="4" [nzStep]="1" [nzPrecision]="0">
                                                                    </nz-input-number>
                                                                </nz-input-group>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- *ngIf="campaignForm.get('no_of_levels').value" -->
                                            <ng-container *ngIf="campaignForm.get('no_of_levels').value">
                                                <nz-input-group
                                                    *ngFor="let level_names of getLevelNamesFormControls(); let i = index">
                                                    <div nz-row [nzGutter]="8">
                                                        <div nz-col nzSpan="7">
                                                            <nz-form-item>
                                                                <nz-form-label nzRequired>Level Name {{i+1}}
                                                                </nz-form-label>
                                                                <nz-form-control nzErrorTip="Please input level names!">
                                                                    <!-- <nz-select name="level_names"
                                                                        (ngModelChange)="onLevelNameChange($event, level_names, i)"
                                                                        nzSize="large" nz-input
                                                                        [formControl]="level_names"
                                                                        [nzOptions]="levelNameOptionList"
                                                                        placeholder="Please select level names"
                                                                        [nzMaxTagCount]="4"
                                                                        [nzValue]="item?.value"
                                                                        [nzLabel]="item?.label" 
                                                                        [nzDisabled]="disabled"
                                                                        >
                                                                        <nz-option nzLabel="1" nzValue="1" [nzDisabled]="disabled"></nz-option>
                                                                    </nz-select> -->

                                                                    <nz-select name="level_names"
                                                                    (ngModelChange)="alterUnselectedParticipantsOptionList()"
                                                                    nzSize="large" nz-input nzAllowClear
                                                                    [formControl]="level_names"
                                                                    [nzOptions]="unselectedParticipantsOptionList"
                                                                    placeholder="Please select level names"
                                                                    [nzMaxTagCount]="4"
                                                                    [nzValue]="item?.value"
                                                                    [nzLabel]="item?.label" 
                                                                    [nzDisabled]="disabled"
                                                                    >
                                                                    <nz-option nzLabel="1" nzValue="1" [nzDisabled]="disabled"></nz-option>
                                                                </nz-select>
                                                                    <!-- <nz-input-group>
                                                                    <nz-select 
                                                                    (change)="alterUnselectedParticipantsOptionList()"
                                                                     
                                                                    placeholder="Select Participants"
                                                                    formArrayName="level_names"
                                                                    [nzOptions]="unselectedParticipantsOptionList"
                                                                    [nzValue]="item?.value"
                                                                    [nzLabel]="item?.label"  > -->
                                                                         
                                                                    
                                                                    <!-- <nz-option
                                                                        *ngFor="let item of unselectedParticipantsOptionList" 
                                                                        [nzLabel]="item.label"
                                                                        [nzValue]="item.value"></nz-option> 
                                                                </nz-select>
                                                                </nz-input-group>-->
                                                                    <!-- <nz-select [nzSize]="'large'" 
                                                                    #levelname
                                                                    (change)="onLevelNameChange(levelname, i)"
                                                                    nzPlaceHolder="Select level names"
                                                                    formControlName="level_names">
                                                                    <nz-option *ngFor="let item of levelNameOptionList"
                                                                        [nzLabel]="item?.label"
                                                                        [nzValue]="item?.value"></nz-option> 
                                                                </nz-select> -->
                                                                </nz-form-control>
                                                            </nz-form-item>
                                                        </div>
                                                    </div>
                                                </nz-input-group>
                                            </ng-container>
                                            <!-- <nz-input-group>
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="7">
                                                    <nz-form-item>
                                                        <nz-form-label nzRequired>Level Names In Single Dropdown list</nz-form-label>
                                                        <nz-form-control nzErrorTip="Please input level names!">
                                                            <nz-select nzMode="multiple" nzPlaceHolder="Inserted are removed"  >
                                                                <nz-option *ngFor="let option of listOfOption" [nzLabel]="option" [nzValue]="option" [nzHide]="!isNotSelected(option)"></nz-option>
                                                              </nz-select>
                                                            </nz-form-control>
                                                            </nz-form-item> 
                                                </div>
                                                </div> 
                                        </nz-input-group>  -->
                                            <!-- <div *ngFor="let phone of getLevelNamesFormControls(); let i = index">
                                            <label>Phone {{i}} </label>    
                                            <input [formControl]="phone" type="text">
                                          <button (click)="removePhone(i)">Remove Phone</button>    
                                          </div> -->
                                            <!-- <nz-input-group>
                                            <div nz-row [nzGutter]="8">
                                                <div nz-col nzSpan="7">
                                                    <nz-form-item>
                                                        <nz-form-label>Visible to</nz-form-label>
                                                        <nz-form-control>
                                                            <label nz-checkbox [nzSize]="'large'"
                                                                formControlName="Visible_to">Organization</label>
                                                        </nz-form-control>
                                                    </nz-form-item>
                                                </div>
                                            </div>
                                        </nz-input-group> -->
                                            <!-- Business Unit HTML -->
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzFor="Business Unit Id">Business Unit
                                                            </nz-form-label>
                                                            <nz-form-control nzErrorTip="Please Select Business Unit!">
                                                                <nz-select placeholder="Select Business Unit"
                                                                    nzSize="large" nzMode="multiple" nzAllowClear
                                                                    nzShowSearch nzServerSearch
                                                                    formControlName="business_unit_ids"
                                                                    (nzOnSearch)="onBusinessUnitOptionListSearch($event)"
                                                                    (ngModelChange)="onBUChange($event)"
                                                                    (nzBlur)="getLocations()">
                                                                    <ng-container *ngFor="let o of bUOptionList">
                                                                        <nz-option
                                                                            *ngIf="!isBusinessUnitOptionListLoading"
                                                                            [nzValue]="o.id" [nzLabel]="o.name">
                                                                        </nz-option>
                                                                    </ng-container>
                                                                    <nz-option *ngIf="isBusinessUnitOptionListLoading"
                                                                        nzDisabled nzCustomContent>
                                                                        <i nz-icon nzType="loading"
                                                                            class="loading-icon"></i>
                                                                        Loading Data...
                                                                    </nz-option>
                                                                </nz-select>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- Business Unit HTML -->
                                            <!-- Lcation HTML -->
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzFor="location Id">Location
                                                            </nz-form-label>
                                                            <nz-form-control nzErrorTip="Please Select Location!">
                                                                <!-- [nzDisabled]="!campaignForm.controls.business_unit_ids.value" -->
                                                                <nz-select placeholder="Select Location" nzSize="large"
                                                                    nzMode="multiple"
                                                                    nzAllowClear nzShowSearch nzServerSearch
                                                                    formControlName="location_ids"
                                                                    (ngModelChange)="onLocationChange($event)"
                                                                    (nzBlur)="getFunctions()"
                                                                    (nzOnSearch)="onLocationOptionListSearch($event)">
                                                                    <ng-container *ngFor="let o of locationOptionList">
                                                                        <nz-option *ngIf="!isLocationOptionListLoading"
                                                                            [nzValue]="o.id" [nzLabel]="o.name">
                                                                        </nz-option>
                                                                    </ng-container>
                                                                    <nz-option *ngIf="isLocationOptionListLoading"
                                                                        nzDisabled nzCustomContent>
                                                                        <i nz-icon nzType="loading"
                                                                            class="loading-icon"></i>
                                                                        Loading Data...
                                                                    </nz-option>
                                                                </nz-select>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- Lcation HTML -->
                                            <!-- Function HTML -->
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzFor="function_id">Function
                                                            </nz-form-label>
                                                            <nz-form-control nzErrorTip="Please Select Function!">
                                                                <!-- [nzDisabled]="!campaignForm.controls.location_ids.value" -->
                                                                <nz-select placeholder="Select Function" nzSize="large"
                                                                    nzAllowClear nzShowSearch nzServerSearch
                                                                    nzMode="multiple" formControlName="function_ids"
                                                                    (nzOnSearch)="onFunctionOptionListSearch($event)"
                                                                    (ngModelChange)="onFunctionChange($event)"
                                                                    (nzBlur)="getDepartments()">
                                                                    <ng-container *ngFor="let o of functionOptionList">
                                                                        <nz-option *ngIf="!isFunctionOptionListLoading"
                                                                            [nzValue]="o.id" [nzLabel]="o.name">
                                                                        </nz-option>
                                                                    </ng-container>
                                                                    <nz-option *ngIf="isFunctionOptionListLoading"
                                                                        nzDisabled nzCustomContent>
                                                                        <i nz-icon nzType="loading"
                                                                            class="loading-icon"></i>
                                                                        Loading Data...
                                                                    </nz-option>
                                                                </nz-select>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- Function HTML -->
                                            <!-- Department HTML -->
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <nz-form-label nzFor="department_id">Department
                                                            </nz-form-label>
                                                            <nz-form-control nzErrorTip="Please Select Department!">
                                                                <!-- [nzDisabled]="!campaignForm.controls.function_ids.value" -->
                                                                <nz-select placeholder="Select Department"
                                                                    nzSize="large" nzAllowClear nzShowSearch
                                                                    nzServerSearch nzMode="multiple"
                                                                    
                                                                    formControlName="department_ids"
                                                                    (nzBlur)="getEmployees()"
                                                                    (nzOnSearch)="onDepartmentOptionListSearch($event)"
                                                                    (ngModelChange)="onDepartmentChange($event)">
                                                                    <ng-container
                                                                        *ngFor="let o of departmentOptionList">
                                                                        <nz-option
                                                                            *ngIf="!isDepartmentOptionListLoading"
                                                                            [nzValue]="o.id" [nzLabel]="o.name">
                                                                        </nz-option>
                                                                    </ng-container>
                                                                    <nz-option *ngIf="isDepartmentOptionListLoading"
                                                                        nzDisabled nzCustomContent>
                                                                        <i nz-icon nzType="loading"
                                                                            class="loading-icon"></i>
                                                                        Loading Data...
                                                                    </nz-option>
                                                                </nz-select>
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- Department HTML -->

                                            <!-- EMployee HTML -->
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="7">
                                                        <nz-form-item>
                                                            <!-- <nz-form-label>Employees</nz-form-label> -->
                                                            <nz-form-control>
                                                                <!-- <nz-input-group class="form-group">
                                                                    <nz-select [nzSize]="'large'" nzMode="multiple"
                                                                        nzAllowClear nzShowSearch nzServerSearch
                                                                        formControlName="employee_ids"
                                                                        (nzOnSearch)="onEmployeeOptionListSearch($event)" >
                                                                        <ng-container
                                                                            *ngFor="let o of employeeOptionList">
                                                                            <nz-option
                                                                                *ngIf="!isEmployeeOptionListLoading"
                                                                                [nzValue]="o.id"
                                                                                [nzLabel]="o.display_name">
                                                                            </nz-option>
                                                                        </ng-container>
                                                                        <nz-option *ngIf="isEmployeeOptionListLoading"
                                                                            nzDisabled nzCustomContent>
                                                                            <i nz-icon nzType="loading"
                                                                                class="loading-icon"></i>
                                                                            Loading Data...
                                                                        </nz-option>
                                                                    </nz-select>
                                                                </nz-input-group> -->
                                                                <!-- <button type="button" class="mt-2" (click)="selectEmp()" [nzSize]="'large'" nz-button
                                                                    nzType="primary">
                                                                    Select Employees
                                                                </button> -->
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- EMployee HTML -->
                                            <nz-input-group>
                                                <div nz-row [nzGutter]="8">
                                                    <div nz-col nzSpan="24">
                                                        <nz-form-item class="text-center">
                                                            <nz-form-control>
                                                                <button type="button" class="mr-3" [disabled]="!allowEdit" (click)="selectEmp()" [nzSize]="'large'" nz-button
                                                                    nzType="primary">
                                                                    Select Employees
                                                                </button>
                                                                <button [nzSize]="'large'" nz-button nzType="primary"
                                                                    [disabled]="!campaignForm.valid || isFormLoading">
                                                                    <i nz-icon [nzType]="isFormLoading && campaignForm.valid ? 'loading' : ''"></i>
                                                                    Define Levels
                                                                </button> 
                                                                <!-- <button nz-button (click)="addLevelName()" nzType="primary">Add</button> -->
                                                            </nz-form-control>
                                                        </nz-form-item>
                                                    </div>
                                                </div>
                                            </nz-input-group>
                                            <!-- {{campaignForm.value | json}} -->
                                        </form>
                                    </nz-spin>
                                </div>
                            </div>
                            <!-- Modal for employees -->
                            <nz-modal nzWidth="900px" [(nzVisible)]="isEmployeeModalOpen" nzClosable="true" nzMaskClosable="false"
                            nzTitle="Select Employees" (nzOnCancel)="handleCancel()" [nzOkDisabled]="buttonDisabled?.length == 0"  (nzOnOk)="handleOk()" nzOkText="Ok">
                            <ng-container *ngIf="!isEmployeeOptionListLoading">
                            <app-employee-popup [employees]="employeeOptionList" [getAllemployees]="allEmployeeList" [selectedEmployee]="selectedOrDirectProceedEmployees"
                            (setEmployeesForLA)=setEmployeesForLA($event) [isShowBs]="isShowBs" [isNotSelect]="isNotSelect"></app-employee-popup>
                            </ng-container>
                            <div class="col-lg-12 text-center" style="padding: 20px;" *ngIf="isEmployeeOptionListLoading">
                                <span style="font-size: 17px;" *ngIf="isEmployeeOptionListLoading"><i nz-icon nzType="loading" class="loading-icon"></i>
                                Please Wait...</span>
                            </div>
                        </nz-modal>
                        </div>
                        <div class="setup-content text-center" *ngIf="contentIndex == 1">
                            <!-- Create Level One Modal  -->
                            <nz-modal [(nzVisible)]="isVisibleLevelOneModal" nzTitle="{{levelAction | titlecase}} Level"
                                (nzOnOk)="submitLevelOneModal()" (nzOnCancel)="cancelLevelOneModal()" nzOkText="Save"
                                nzOkType="primary" [nzOkLoading]="isFormLoadingLevelOne"
                                [nzOkDisabled]="!levelOneForm.valid">
                                <form nz-form [nzLayout]="'vertical'" [formGroup]="levelOneForm">
                                    <nz-form-item>
                                        <nz-form-control nzErrorTip="Please enter level title!">
                                            <nz-form-label nzRequired nzFor="Level title" nzNoColon="false">Level title
                                            </nz-form-label>
                                            <nz-input-group [nzSize]="'large'">
                                                <input nz-input [nzSize]="'large'" noWhitespace formControlName="campaign_item_name"
                                                    placeholder="Enter level title" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <!-- *ngIf="clickedRowDetails?.levelType === 2 && levelAction === 'create' ||
                                    clickedRowDetails?.levelType > 2" -->
                                    <!-- *ngIf="treeTblLevelList?.length === clickedRowDetails?.levelType+1" -->
                                    <nz-form-item
                                        *ngIf="treeTblLevelList?.length === clickedRowDetails?.levelType+1 || 
                                        treeTblLevelList?.length === clickedRowDetails?.levelType">
                                        <nz-form-label nzFor="Icon Image">Icon Image
                                        </nz-form-label>
                                        <nz-form-control nzErrorTip="Please upload icon image!">
                                            <nz-upload nzSize="large" formControlName="image_file" ngDefaultControl
                                                [(nzFileList)]="fileList"  [nzLimit]="1" [nzMultiple]="false" 
                                                [nzFileType]="'image/png,image/jpeg'" [nzShowButton]="fileList.length < 1" [nzBeforeUpload]="beforeUpload">
                                                <a nz-button nzType="primary"><i nz-icon nzType="upload"></i>Upload
                                                    Icon Image</a> 
                                            </nz-upload>
                                        </nz-form-control>
                                    </nz-form-item>
                                </form>
                                <!-- {{levelOneForm.value | json}} -->
                            </nz-modal>
                            <!-- Create Level two Modal  -->
                            <!-- <nz-modal [(nzVisible)]="isVisibleLevelTwoModal" nzTitle="Create Level"
                                (nzOnOk)="submitLevelTwoModal()" (nzOnCancel)="cancelLevelTwoModal()" nzOkText="Save"
                                nzOkType="primary" [nzOkLoading]="isFormLoadingLevelTwo"
                                [nzOkDisabled]="!levelTwoForm.valid">
                                <form nz-form [nzLayout]="'vertical'" [formGroup]="levelTwoForm">
                                    <nz-form-item>
                                        <nz-form-control nzErrorTip="Please Enter Name!">
                                            <nz-form-label nzRequired nzFor="Name" nzNoColon="false">Name
                                            </nz-form-label>
                                            <nz-input-group [nzSize]="'large'">
                                                <input nz-input [nzSize]="'large'" formControlName="campaign_item_name"
                                                    placeholder="Level Name" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </form> 
                            </nz-modal> -->

                            <!-- Create Level Three Modal  -->
                            <!-- <nz-modal [(nzVisible)]="isVisibleLevelThreeModal" nzTitle="Create Level"
                                (nzOnOk)="submitLevelThreeModal()" (nzOnCancel)="cancelLevelThreeModal()"
                                nzOkText="Save" nzOkType="primary" [nzOkLoading]="isFormLoadingLevelThree"
                                [nzOkDisabled]="!levelThreeForm.valid">
                                <form nz-form [nzLayout]="'vertical'" [formGroup]="levelThreeForm">
                                    <nz-form-item>
                                        <nz-form-control nzErrorTip="Please Enter Name!">
                                            <nz-form-label nzRequired nzFor="Name" nzNoColon="false">Name
                                            </nz-form-label>
                                            <nz-input-group [nzSize]="'large'">
                                                <input nz-input [nzSize]="'large'" formControlName="campaign_item_name"
                                                    placeholder="Level Name" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                </form> 
                            </nz-modal> -->


                            <!-- <div class="activity-tab-heading text-center">Configure the Learning Assessment Activites</div> -->
                            <!-- Campaign Dropdown list  -->
                            <!-- <div class="row">
                                <div class="col-md-4 offset-md-4 text-center">
                                    <form nz-form [nzLayout]="'vertical'" [formGroup]="campaignListForm">
                                        <nz-form-item>
                                            <nz-form-control nzErrorTip="Please select Campaign!">
                                                //<nz-form-label nzFor="Campaign" nzNoColon="false">Campaign</nz-form-label>
                                                <nz-input-group>
                                                    <nz-select formControlName="campaign_list" [nzSize]="'large'"
                                                        (ngModelChange)="onCampaignChange()"
                                                        nzPlaceHolder="Please select Campaign">
                                                        <nz-option *ngFor="let item of campaignList"
                                                            [nzLabel]="item.campaign_name" [nzValue]="item.id">
                                                        </nz-option>
                                                    </nz-select>
                                                </nz-input-group>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </form>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-12 text-left">
                                    <h3 class="plusIcon">
                                        <a (click)="createLevelOneModal(null)">
                                            <i nz-icon class="mb-4" nzType="plus-circle" nzTheme="fill"></i>
                                        </a>
                                    </h3>
                                </div>
                                <div class="col-md-12">
                                    <nz-table #expandTable [nzData]="listOfMapData" nzTableLayout="fixed">
                                        <thead>
                                            <tr>
                                                <th>Level Title</th>
                                                <th>Level</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let data of expandTable.data">
                                                <ng-container *ngFor="let item of mapOfExpandedData[data.key]">
                                                    <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                                                        <td [nzIndentSize]="item.level! * 20"
                                                            [nzShowExpand]="!!item.children" [(nzExpand)]="item.expand"
                                                            (nzExpandChange)="collapse(mapOfExpandedData[data.key], item, $event)">
                                                            {{ item.campaign_item_name }}
                                                            <!-- <ng-container
                                                                *ngIf="treeTblLevelList?.length === item?.levelType">
                                                                <nz-avatar *ngIf="item?.image?.thumb_image_url_1"
                                                                    [nzSize]="32"
                                                                    [nzSrc]="item?.image?.thumb_image_url_1">
                                                                </nz-avatar>
                                                                <nz-avatar *ngIf="!item?.image?.thumb_image_url_1"
                                                                    [nzSize]="32" nzIcon="exclamation">
                                                                </nz-avatar>
                                                            </ng-container> -->
                                                            <nz-avatar *ngIf="item?.image?.thumb_image_url_1"
                                                            [nzSize]="32"
                                                            [nzSrc]="storageAccount+item?.image?.thumb_image_url_1">
                                                        </nz-avatar>
                                                            <!-- treeLevel: {{treeTblLevelList?.length}} rowLevel: {{item?.levelType}} -->
                                                        </td>
                                                        <td>{{ item.item_type}}</td>
                                                        <td>
                                                            <!-- lenght: {{treeTblLevelList?.length }} levelType: {{item?.levelType}} -->
                                                            <a *ngIf="treeTblLevelList?.length != item?.levelType"
                                                            [class.disabled]="!allowEdit ? true: null" (click)="allowEdit && createLevelOneModal(item)"> <i
                                                                    class="ri-add-fill grayIcon"></i></a>
                                                            <a [class.disabled]="!allowEdit ? true: null" (click)="allowEdit && editLevelOneModal(item)"><i
                                                                    class="ri-pencil-fill grayIcon"></i></a>
                                                            <a [class.disabled]="!allowEdit ? true: null" (click)="allowEdit && removeLevel(item)"> <i
                                                                    class="ri-delete-bin-6-fill grayIcon"></i></a>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </tbody>
                                    </nz-table>
                                    <button *ngIf="listOfMapData?.length > 0" (click)="onSaveAndExit()" nz-button nzType="primary">
                                        Save & Exit
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgb(0,0,0)" size="large" color="#fff" type="ball-newton-cradle" [fullScreen]="true">
        <p style="color: white"> Please Wait... </p>
    </ngx-spinner>