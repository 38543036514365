<div id="content-page" class="content-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
            <h3 class="py-4 text-center">List of Strategic Perspectives</h3>
            <h3 class="plusIcon"> <i nz-icon class="mb-4" (click)="showCreateModal()" nzType="plus-circle"
                nzTheme="fill"></i></h3>
            <nz-modal [(nzVisible)]="isFormVisible" nzTitle="Define Strategic Perspectives"
              (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalSubmit()" nzOkText="Save" nzOkType="primary"
              [nzOkLoading]="isFormLoading" [nzOkDisabled]="!strategicPerspectiveForm.valid">
              <form nz-form [nzLayout]="'vertical'" [formGroup]="strategicPerspectiveForm" (ngSubmit)="submitForm()">
                <!-- Capture the SP title/name -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="title" nzNoColon="false">Strategic Perspective Name</nz-form-label>
                  <nz-form-control nzErrorTip="Name is mandatory.">
                    <input nzSize="large" formControlName="title" nz-input placeholder="Strategic Perspective Name" />
                  </nz-form-control>
                </nz-form-item>

                <!-- Capture the SP description -->
                <nz-form-item>
                  <nz-form-label nzRequired nzFor="description" nzNoColon="false">Description</nz-form-label>
                  <nz-form-control nzErrorTip="Description is mandatory.">
                    <input nzSize="large" formControlName="description" nz-input placeholder="Description" />
                  </nz-form-control>
                </nz-form-item>

                <!-- Capture the short code -->
                <nz-form-item>
                  <nz-form-label nzFor="shortCode" nzNoColon="false">Short Code</nz-form-label>
                  <nz-form-control>
                    <input nzSize="large" formControlName="short_code" nz-input placeholder="Short Code" />
                  </nz-form-control>
                </nz-form-item>

                <!-- Capture is Active field-->

                <nz-form-item>
                  <!-- <nz-form-label nzFor="isActive" nzNoColon="false">Is Active</nz-form-label> -->
                  <nz-form-control>
                    <nz-form-control nzErrorTip="Please Select Active!">
                      <label nz-checkbox formControlName="is_active">Active</label>
                    </nz-form-control>
                    <!-- <nz-input-group class="form-group">
                        <nz-radio-group formControlName="isActive" >
                            <label nz-radio nzValue="yes">Yes</label>
                            <label nz-radio nzValue="no">No</label>
                        </nz-radio-group>
                      </nz-input-group> -->
                  </nz-form-control>
                </nz-form-item>
              </form>
            </nz-modal>

            <nz-table nzBordered nzShowSizeChanger [nzFrontPagination]="false" [nzLoading]="loading" [nzTotal]="total"
              [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)"
              [nzData]="sps">
              <thead>
                <tr>
                  <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                  <th nzColumnKey="name" [nzSortFn]="true">Strategic Perspective Name</th>
                  <th nzColumnKey="description" [nzSortFn]="true">Description</th>
                  <th nzColumnKey="short_code" [nzSortFn]="true">Short Code</th>
                  <th nzColumnKey="is_active" [nzSortFn]="true">Is Active</th> -->
                  
                  <th>Strategic Perspective Name</th>
                  <th>Description</th>
                  <th>Short Code</th>
                  <th>Is Active</th>  
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sp of sps">
                  
                  <td>{{ sp.title }}</td>
                  <td>{{ sp.description }}</td>
                  <td>{{ sp.short_code }}</td>
                  <td>{{ sp.is_active }}</td>
                  <!--
                    <td>
                      <a [routerLink]="['/function']" [queryParams]="{id: department.function_id}">{{ department.function.name }}</a>
                    </td>-->
                  <td>
                    <a (click)="showEditModal(sp.id)"><i class="ri-pencil-fill  grayIcon" ></i></a>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a (click)="remove(sp)"><i class="ri-delete-bin-6-fill grayIcon" ></i></a>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>