<div id="content-page" class="content-page">
    <div class="container">
        <div class="row">
            <!-- new tabe design start -->
            <div class="col-sm-12 col-lg-12">
                <div class="iq-card">
                    <div class="iq-card-body">
                        <div class="  col-sm-12">
                            <h3 class="py-4 text-center">Objectives</h3>
                            <div class="row mb-2">
                                <div class="col-md-6 offset-md-3 mb-5">
                                    <!-- <nz-form-item class=""> 
        <nz-form-control nzErrorTip="Please input Types!">
            <nz-input-group >
                <nz-select
                   [nzSize]="'large'"
                    nzPlaceHolder="Please select"
                    [nzOptions]="listOfNoOfOption"
                    >
                </nz-select>
            </nz-input-group>
        </nz-form-control>
    </nz-form-item> -->
                                    <!-- <form nz-form [formGroup]="dateForm" [nzLayout]="'inline'" action="">
                                    <nz-form-item>
                                        <nz-form-control nzErrorTip="Please select start date!">
                                            <nz-form-label nzRequired nzFor="Duration" nzNoColon="false">Duration
                                            </nz-form-label>
                                            <nz-input-group>
                                                <nz-date-picker formControlName="start_date" (ngModelChange)="onChange($event, 'sd')"
                                                     nzFormat="yyyy-MM-dd"
                                                     [nzSize]="'large'"></nz-date-picker>
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-form-item>
                                    <nz-form-item>
                                        <nz-form-control nzErrorTip="Please select end date!">
                                            <nz-form-label nzRequired nzFor="To" nzNoColon="false">To</nz-form-label>
                                            <nz-input-group>
                                                <nz-date-picker formControlName="end_date" (ngModelChange)="onChange($event, 'ed')" 
                                                nzFormat="yyyy-MM-dd"  [nzSize]="'large'"></nz-date-picker>
                                            </nz-input-group> 
                                        </nz-form-control>
                                    </nz-form-item>
                                </form> -->
                                </div>
                            </div>

                            <nz-table nzBordered  [nzData]="EmpObjectiveGroupsList" [nzFrontPagination]="true">
                                <thead>
                                    <tr>
                                        <th>Employee Name</th>
                                        <th>Role</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Status</th>
                                        <th>Objectives For</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of EmpObjectiveGroupsList">
                                        <td>{{ data.employee_name }}</td>
                                        <td>{{ data.role }}</td>
                                        <td>{{data.start_date}}</td>
                                        <td>{{data.end_date}}</td>
                                        <td>{{ data.status | titlecase}}</td>
                                        <td>{{ data.objective_for }}</td>
                                        <td>
                                            <a *ngIf="(data.is_self && data.status == 'draft') || (data.is_self && data.status != 'draft')"
                                                [routerLink]='"/maximizer-dashboard/setup-objectives/"+data.employee_objective_group_id'
                                                [queryParams]="{ self: data.is_self, status: data.status}"><i
                                                    class="ri-pencil-fill grayIcon"></i></a>
                                            <!-- status draft >isSelf true> accept and define target = accepfted > edit screen> -->
                                            <!-- false draff> isself true > show edit direct -->
                                            <!-- isself false > status sent for appoval > approved  with no edit action  -->
                                            <!-- issel false > status No set for app> view button with back btn> -->
                                            <a *ngIf="(!data.is_self && data.status == 'sent for approval') || (!data.is_self && data.status != 'sent for approval')"
                                                [routerLink]='"/maximizer-dashboard/setup-objectives/"+data.employee_objective_group_id'
                                                [queryParams]="{ self: data.is_self, status: data.status}"> <i
                                                    class="ri-eye-fill grayIcon"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                            <nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Objectives" (nzOnCancel)="handleCancel()"
                                (nzOnOk)="handleOk()">
                                <nz-form-item>
                                    <nz-form-control nzErrorTip="Please input Types!">
                                        <nz-input-group class="form-group">
                                            <!-- <nz-select nzPlaceHolder="Please select" [nzOptions]="listOfNoOfOption">
                                            </nz-select> -->
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-control nzErrorTip="Please input Types!">
                                        <nz-input-group class="form-group">
                                            <nz-select nzPlaceHolder="Please select" [nzOptions]="listOfNoOfOption">
                                            </nz-select>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                            </nz-modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>